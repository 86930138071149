import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios, {CancelToken} from 'axios';
import {
    Typography,
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    IconButton,
    Tabs,
    Tab,
    AppBar
} from '@material-ui/core';
import moment from 'moment-timezone'
import DataContext from 'context/Data'
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from 'components';

import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class UserProductivity extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.requests = {
          cancelLoadUserProuctivity: null,
        };

        this.state = {
          loading: false,
          userList: [],
          active_tab: 0,
          // auto update page data
          updated_at: "",
          update_schedule: null,
          // update_interval_length: 300000
          update_interval_length: 1800000 // set to 30 mins interval for system performance
        };

        document.title = "User Productivity";
    }

    render() {
        return (
          <Grid container spacing={2}>
            {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
            {this.renderUserTable()}
          </Grid>
        );
    }

    componentWillMount() {
      this.loadUserProductivity();
    }
    componentWillUnmount() {
      clearTimeout(this.state.update_schedule);
      if (this.requests.cancelLoadUserProuctivity) this.requests.cancelLoadUserProuctivity();
    }

    // api call
    // load email table and report type options on page load
    loadUserProductivity = () => {
      let requests = this.requests;
      if (requests.cancelLoadUserProuctivity) requests.cancelLoadUserProuctivity(); // cancel prev un-finished requests
      
      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/productivityTable`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      }, {
        cancelToken: new CancelToken(function executor(c) {
          requests.cancelLoadUserProuctivity = c;
        })
      });

      this.setState({loading: true});
      req.then(this.loadUserProductivitySuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, snackDisplay: this.context.snackDisplay, errorCallback: ()=>{
        let update_schedule = setTimeout(this.loadDashboard, this.state.update_interval_length);
        this.setState({loading: false, update_schedule});
      }}));
    }
    loadUserProductivitySuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
          this.context.snackDisplay(data.Error);
          // this.context.alert(data.Error);
          return;
        }
        let user_array = [];
        for (let key in resp.data) {
          let user = resp.data[key];
          let tasks = [];
          for (let customer_id in user['task']) {
            for (let task_type in user['task'][customer_id]) {
              user['task'][customer_id][task_type]['customer_id'] = customer_id;
              tasks.push(user['task'][customer_id][task_type]);
            }
          }
          user['task'] = tasks;
          user_array.push(user);
        }

        let update_schedule = setTimeout(this.loadUserProductivity, this.state.update_interval_length);
        this.setState({userList: user_array, updated_at: moment().format('HH:mm'), update_schedule});
    }

    // render search result table
    renderUserTable = () => {
      const {userList, active_tab} = this.state;

      let userByRole = {
        inboundTeam: [],
        outBoundTeamB2C: [],
        outBoundTeamB2B: [],
        inventoryTeam: [],
        other: []
      };
      for (let singleUser of userList) {
        switch (true) {
          case singleUser.role.includes("INBOUND"):
            userByRole.inboundTeam.push(singleUser);
            break;
          case singleUser.role.includes("OUTBOUND") && singleUser.role.includes("B2C"):
            userByRole.outBoundTeamB2C.push(singleUser);
            break; 
          case singleUser.role.includes("OUTBOUND") && singleUser.role.includes("B2B"):
            userByRole.outBoundTeamB2B.push(singleUser);
            break; 
          case singleUser.role.includes("INVENTORY"):
            userByRole.inventoryTeam.push(singleUser);
            break;
          default:
            userByRole.other.push(singleUser);
        }
      }

      let colSettings = [
        {
          key: 'tasks',
          label: '',
          contentNoWrap: true,
          width: 'auto',
          collapsible: true,
          renderCollapsedContent: (task, index, user) => {
            return (
              <React.Fragment>
                <div style={{margin: "1rem 0"}}>
                  <Typography variant="h6" gutterBottom component="div" style={{textAlign: 'left', fontWeight: 'bold'}}>{user.name}</Typography>
                  <NewDataTable
                    rows={user.task}
                    noPagination
                    columns={[
                      {
                        key: 'type',
                        label: 'Task Type',
                        // render: (cellVal, key, task)=>{
                        //   return <Button onClick={this.loadSingleClientTask.bind(this, task, user)}>{cellVal}</Button>;
                        // }
                      }, 
                      {
                        key: 'customer',
                        label: 'Customer',
                      },
                      {
                        key: 'hour',
                        label: 'Total Hours',
                      },
                      {
                        key: 'unit',
                        label: 'Total Units',
                      },
                      {
                        key: 'uph',
                        label: 'UPH',
                      }, 
                    ]}
                  />
                </div>
              </React.Fragment>
            );
          },
          render: (val, key, user) =>''
        },
        {
          key: 'username',
          label: 'Username',
          contentNoWrap: true,
          width: 'auto',
          render: (val, key, user) => {
            return val + " (" + user.name +")";
          }
        },
        {
          key: 'role',
          label: 'Role',
          width: 'auto',
          contentNoWrap: true,
        },
        {
          key: 'current_task',
          label: 'Latest Task',
          width: 'auto',
          contentNoWrap: true,
        },
        {
          key: 'gap',
          label: 'Gap',
          render: (val)=>{
            return parseFloat(val).toFixed(2); 
          }
        },
        {
          key: 'work_hour',
          label: 'Productive Hours',
        },
        {
          key: 'total_unit',
          label: 'Total Unit',
          // render: (val)=>{
          //   return parseFloat(val).toFixed(2); 
          // }
        },
        {
          key: 'total_uph',
          label: 'Total UPH',
          // render: (val)=>{
          //   return parseFloat(val).toFixed(2); 
          // }
        },
      ];

      const tabs = [
        {
          key: 'INBOUND',
          label: 'INBOUND',
          render: ()=>(<NewDataTable
            noPagination
            tableTitle={
              <div style={{width: "100%"}}>
                <p style={{textAlign: "center"}}>{`Inbound Team ${userByRole.inboundTeam.length} attendance`}</p>
              </div>
            }
            titleProps={{style: {width: '100%'}}}
            rows={userByRole.inboundTeam}
            columns={colSettings}
          />)
        },
        {
          key: 'OUTBOUND B2C',
          label: 'OUTBOUND B2C',
          render: ()=>(<NewDataTable
            noPagination
            titleProps={{style: {width: '100%'}}}
            tableTitle={
              <div style={{width: "100%"}}>
                <p style={{textAlign: "center"}}>{`Outbound Team B2C ${userByRole.outBoundTeamB2C.length} attendance`}</p>
              </div>
            }
            rows={userByRole.outBoundTeamB2C}
            columns={colSettings}
          />)
        },
        {
          key: 'OUTBOUND B2B',
          label: 'OUTBOUND B2B',
          render: ()=>(<NewDataTable
            noPagination
            titleProps={{style: {width: '100%'}}}
            tableTitle={
              <div style={{width: "100%"}}>
                <p style={{textAlign: "center"}}>{`Outbound Team B2B ${userByRole.outBoundTeamB2B.length} attendance`}</p>
              </div>
            }
            rows={userByRole.outBoundTeamB2B}
            columns={colSettings}
          />)
        },
        {
          key: 'INVENTORY',
          label: 'INVENTORY',
          render: ()=>(<NewDataTable
            noPagination
            titleProps={{style: {width: '100%'}}}
            tableTitle={
              <div style={{width: "100%"}}>
                <p style={{textAlign: "center"}}>{`Inventory Team ${userByRole.inventoryTeam.length} attendance`}</p>
              </div>
            }
            rows={userByRole.inventoryTeam}
            columns={colSettings}
          />)
        },
        {
          key: 'OTHER',
          label: 'OTHER',
          render: ()=>(<NewDataTable
            noPagination
            titleProps={{style: {width: '100%'}}}
            tableTitle={
              <div style={{width: "100%"}}>
               <p style={{textAlign: "center"}}>{`Other Teams ${userByRole.other.length} attendance`}</p>
              </div>
            }
            rows={userByRole.other}
            columns={colSettings}
          />)
        },
      ];
     
      return (
        <GridItem sm={12}>
         <AppBar position="static" color="default">
            <Tabs
              value={active_tab}
              onChange={(e,val)=>this.setState({active_tab: val})}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((elem)=><Tab key={elem.key} label={elem.label}/>)}
            </Tabs>
            <CardContainer>
              {tabs[active_tab].render()}
            </CardContainer>
          </AppBar>
        </GridItem>
      );
    }
}
export default withStyles(styles)(UserProductivity)
