import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Typography, withStyles, IconButton, MenuItem, FormControl, Select } from "@material-ui/core";
import axios from 'axios';
import utils from 'utils/utils';
import _ from 'lodash'
import {
  Add,
  DeleteForever
} from '@material-ui/icons';
import {
  NewDataTable,
  CardContainer,
  CustomInput,
} from 'components';
import DataContext from 'context/Data'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class WebsiteAccess extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    website: PropTypes.array.isRequired,
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onWebsiteChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.edit_input = null;

    this.state = {
      loading: false,
      description: '',
      username: '',
      password: '',
      link: '',
      edit: {},
    };
  }

  render() {
    return (
      <div>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Website
        </Typography>

        {this.renderWebsiteTable()}
      </div>
    );
  }

  renderWebsiteTable = () => {
    const { classes, website } = this.props;
    const { description, username, password, link, edit } = this.state;

    let rows = [];
    if (website) {
      rows = Array.from(website);
    }

    rows.push({addNew: true});

    let columns = [
      {
          key: 'description',
          label: 'description',
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.id,
                          field: 'description',
                      }});
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  onChange: (e)=>{this.setState({description: e.target.value})},
                                  value: description
                              }}
                          />
                      </div>
                  );
              }

              if (edit.key === row.id && edit.field === 'description') {
                  _.delay(()=>{
                     this.edit_input.focus();
                  }, 100);

                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  defaultValue: val ? val : '',
                                  inputRef: (elem)=>{this.edit_input = elem},
                                  onBlur: ()=>{
                                      let newVal = this.edit_input.value;
                                      this.updateWebsite(newVal, 'description', row.id, index);
                                      this.setState({edit: {}});
                                  },
                              }}
                          />
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'username',
          label: 'username',
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.id,
                          field: 'username',
                      }});
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  onChange: (e)=>{this.setState({username: e.target.value})},
                                  value: username
                              }}
                          />
                      </div>
                  );
              }

              if (edit.key === row.id && edit.field === 'username') {
                  _.delay(()=>{
                     this.edit_input.focus();
                  }, 100);

                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  defaultValue: val ? val : '',
                                  inputRef: (elem)=>{this.edit_input = elem},
                                  onBlur: ()=>{
                                      let newVal = this.edit_input.value;
                                      this.updateWebsite(newVal, 'username', row.id, index);
                                      this.setState({edit: {}});
                                  },
                              }}
                          />
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'password',
          label: 'password',
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.id,
                          field: 'password',
                      }}
                  );
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  onChange: (e)=>{this.setState({password: e.target.value})},
                                  value: password
                              }}
                          />
                      </div>
                  );
              }

              if (edit.key === row.id && edit.field === 'password') {
                  _.delay(()=>{
                     this.edit_input.focus();
                  }, 100);
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  defaultValue: val ? val : '',
                                  inputRef: (elem)=>{this.edit_input = elem},
                                  onBlur: ()=>{
                                      let newVal = this.edit_input.value;
                                      this.updateWebsite(newVal, 'password', row.id, index);
                                      this.setState({edit: {}});
                                  },
                              }}
                          />
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'link',
          label: 'link',
          width: '350',
          contentNoWrap: true,
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.id,
                          field: 'link',
                      }}
                  );
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  // fullWidth: false,
                                  fullWidth: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  onChange: (e)=>{this.setState({link: e.target.value})},
                                  value: link
                              }}
                          />
                      </div>
                  );
              }

              if (edit.key === row.id && edit.field === 'link') {
                  _.delay(()=>{
                     this.edit_input.focus();
                  }, 100);
                  return (
                      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: true,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  defaultValue: val ? val : '',
                                  inputRef: (elem)=>{this.edit_input = elem},
                                  onBlur: ()=>{
                                      let newVal = this.edit_input.value;
                                      this.updateWebsite(newVal, 'link', row.id, index);
                                      this.setState({edit: {}});
                                  },
                              }}
                          />
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'action',
          label: 'Action',
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                    <IconButton size="small" onClick={this.newWebsite} variant="fab" aria-label="Add" className={classes.button}>
                      <Add />
                    </IconButton>
                  );
              } else {
                  return (
                    <IconButton size="small" onClick={()=>{
                      this.context.confirm("Are you sure to delete this website?", this.deleteWebsite.bind(this, row.id, index));
                    }} color='secondary' variant="fab" aria-label="Delete" className={classes.button}>
                      <DeleteForever />
                    </IconButton>
                  );
              }
          }
      }
    ];


    return (
      <CardContainer>
        <NewDataTable
          rows={rows}
          noPagination
          rowHeight='auto'
          maxHeight={500}
          columns={columns}
        />
    </CardContainer>
    );
  }

  newWebsite = () => {
    const {  description, username, password, link } = this.state;
    let customer_id = this.props.customer_id;
    
    let newWebsite = {
      customer_id,
      description,
      username,
      password,
      link
    };

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customer`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        customer_id,
        website: newWebsite
    },
    });

    this.setState({loading: true});
    req.then(this.newWebsiteSuccess.bind(this, newWebsite)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newWebsiteSuccess = (newWebsite, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    newWebsite.id = resp.data.id;

    const { website, onWebsiteChange } = this.props;
    let newSites = Array.from(website);
    newSites.push(newWebsite);

    // clear input
    this.setState({description: '', username: '', password: '', link: ''});
    onWebsiteChange(newSites);
  }
  deleteWebsite = (site_id,index) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/customer/website/${site_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteWebsiteSuccess.bind(this, site_id, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteWebsiteSuccess = (site_id, index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    
    let newSites = Array.from(this.props.website);
    newSites.splice(index, 1);
    this.props.onWebsiteChange(newSites);
  }
  updateWebsite = (newVal, field, site_id, index) => {
    let newSite = Object.assign({}, this.props.website[index]);
    if (newSite && newSite.id === site_id) {
        newSite[field] = newVal;
    } else {
        for (let i = 0; i < this.props.website.length;i++) {
            let site = this.props.website[i];
            if (site.id === site_id) {
                index = i;
                newSite = Object.assign({}, site);
                newSite[field] = newVal;
                break;
            }
        }
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customerdetail`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        customer_id: this.props.customer_id,
        website: newSite
      },
    });

    this.setState({loading: true});
    req.then(this.updateWebsiteSuccess.bind(this, newSite, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateWebsiteSuccess = (newSite, index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    const { website, onWebsiteChange } = this.props;
    let newSites = Array.from(website);
    newSites[index] = newSite;

    onWebsiteChange(newSites);
  }
}
export default withStyles(styles)(WebsiteAccess);
