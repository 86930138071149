import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment-timezone'
import {Divider, ButtonGroup, Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  DeleteForever
} from '@material-ui/icons'
import {
  NewDataTable,
  CardContainer,
  Button,
  ReversedIconButton,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import ItemAllocationDialog from '../Dialogs/Allocation/ItemAllocation';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  buttonPushRight: {
    marginLeft: 'auto',
  },
  cardContainer: {
    // height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    paddingBottom: '.5rem',
    width: '100%'
  },
});

class ManagePickticket extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.temp_data = {
      item_allocation: null,
      item_inventory: null,
      active_index: -1
    };

    this.state = {
      loading: false,
      dailog: '', 
      order_barcode: '',
      new_sku: '', // 
      order_items: null,
    };

    document.title = "Manage Pickticket";
  }

  orderSearch = () => {
    const {order_barcode} = this.state;
    if (!utils.formatString(order_barcode)) {
      this.context.alert("Order barcode is required!");
      return;
    }

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/orderPickticket/${utils.formatString(order_barcode)}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.temp_data = {
      item_allocation: null,
      item_inventory: null,
      active_index: -1
    };
    this.setState({loading: true});
    req.then(this.orderSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  orderSearchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({order_items: resp.data});
  }
  getItemAllocation = (index) => {
    let item = this.state.order_items[index];

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/orderPickticketItem/${item.order_id}/${item.item_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.temp_data = {
      item_allocation: null,
      item_inventory: null,
      active_index: index
    };
    this.setState({loading: true});
    req.then(this.getItemAllocationSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  getItemAllocationSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      this.context.alert(resp.data.Error);
      return;
    }

    let inventory = [];
    for (let id in resp.data.inventory) {
      let inventoryLocation = resp.data.inventory[id];
      inventoryLocation.quantity_input = 0;
      inventory.push(inventoryLocation);
    }
    this.temp_data = Object.assign(this.temp_data, {
      item_allocation: resp.data.allocation, 
      item_inventory: inventory,
      // use the allocation information from backend not from allocation array
      packslip_id: resp.data.packslip_id || '',
      line_item_id: resp.data.line_item_id || '',
      sku:  resp.data.sku || '',
    });
    this.setState({dialog: 'itemAllocation', });
  }
  checkSKUInventory = (e) => {
    e.preventDefault();
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/searchnewpicktickitem`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        sku: this.state.new_sku,
        order_id: this.state.order_items[0]['order_id'],
      }
    });
    this.temp_data = {
      item_allocation: null,
      item_inventory: null,
    };
    this.setState({loading: true});
    req.then(this.checkSKUInventorySuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  checkSKUInventorySuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      this.context.alert(resp.data.Error);
      return;
    }
    
    let inventory = [];
    for (let id in resp.data.inventory) {
      let inventoryLocation = resp.data.inventory[id];
      inventoryLocation.quantity_input = 0;
      inventory.push(inventoryLocation);
    }
    this.temp_data = Object.assign(this.temp_data, {
      item_allocation: resp.data.allocation, 
      item_inventory: inventory,
      // use the allocation information from backend not from allocation array
      packslip_id: resp.data.packslip_id || '',
      line_item_id: resp.data.line_item_id || '',
      item_id: resp.data.item_id || '',
      sku:  resp.data.sku || '',
    });
    this.setState({dialog: 'addItemAllocation', });
  }

  renderItemTable = () => {
    const { order_items, new_sku } = this.state;
    if (!order_items || order_items.length == 0) return;

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        render: (val, key, row, index) => {
          return (<Button onClick={()=>{
            this.getItemAllocation(index);
          }}>{val}</Button>);
        }
      }, {
        key: 'upc',
        label: 'UPC',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'item_name',
        label: 'Item Name',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'quantity',
        label: 'Quantity',
        render: utils.tableCellOnDisplay,
      },
    ];

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <form onSubmit={this.checkSKUInventory}>
            <div>       
              <CustomInput
                labelText='SKU'
                formControlProps={{
                  fullWidth: false,
                  className: this.props.classes.customInput,
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  value: new_sku,
                  onChange: (e)=>{this.setState({new_sku: e.target.value});},
                  style: {marginRight: '.5rem'}
                }}
              />
              <Button type='submit' style={{marginTop: '0.75rem'}}>Search</Button>
            </div>
          </form>

          <NewDataTable
            rows={order_items}
            noPagination
            rowsPerPage={100}
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderDialog = () => {
    const {dialog, order_items} = this.state;
    const {active_index, item_allocation, item_inventory} = this.temp_data;

    switch (dialog) {
      case 'itemAllocation':
        if (!item_allocation || !item_inventory || active_index < 0) return;
        return <ItemAllocationDialog
          handleClose={(newItemList)=>{
            this.setState({dialog: ''});  
            if (newItemList) this.setState({order_items: newItemList});
            this.temp_data = {
              item_allocation: null,
              item_inventory: null,
              active_index: -1
            };}}
          display={true}
          packslip_id={this.temp_data.packslip_id}
          line_item_id={this.temp_data.line_item_id}
          sku={this.temp_data.sku}
          order_id={order_items[active_index]['order_id']}
          item_id={order_items[active_index]['item_id']}
          item_allocation={item_allocation}
          item_inventory={item_inventory}
        />;
      case 'addItemAllocation':
        if (!item_inventory) return;
        return <ItemAllocationDialog
          handleClose={(newItemList)=>{
            this.setState({dialog: ''});  
            if (newItemList) this.setState({order_items: newItemList});
            this.temp_data = {
              item_allocation: null,
              item_inventory: null,
              active_index: -1
            };}}
          display={true}
          packslip_id={this.temp_data.packslip_id}
          line_item_id={this.temp_data.line_item_id}
          sku={this.temp_data.sku}
          order_id={order_items[0]['order_id']}
          item_id={this.temp_data.item_id}
          item_allocation={item_allocation}
          item_inventory={item_inventory}
        />;
    }
  }

  render() {
    const {classes} = this.props; 
    const {order_barcode } = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            <CustomInput
              labelText='Order Barcode'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                value: order_barcode,
                onChange: (e) => {this.setState({order_barcode: e.target.value})}
              }}
            />

            <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
              <Button onClick={this.orderSearch}>Search</Button>
            </div>
          </CardContainer>
        </GridItem>
        
        {this.renderItemTable()}
        {this.renderDialog()}
      </Grid>
    );
  }
}
export default withStyles(styles)(ManagePickticket);
