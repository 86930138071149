import React, { Component } from 'react';
import {
  withStyles,
  Paper,
  Stepper, Step, StepLabel, StepContent,
  Button, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash'

const stepperStyle = (theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
});

export class VerticalLinearStepper extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    steps: PropTypes.array.isRequired,
    activeStep: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
    };
  }

  render() {
    const { classes, steps } = this.props;
    let activeStep = this.props.activeStep;
    if (_.isUndefined(activeStep)) activeStep = this.state.activeStep;

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => {
            let stepActions = (<div>
              {activeStep > 0 && <Button
                disabled={activeStep === 0}
                onClick={this.handleBack}
                className={classes.button}
              >
                Back
              </Button>}

              <Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
              >
                {step.submitButtonText ? step.submitButtonText : (activeStep === steps.length - 1 ? 'Finish' : 'Next')}
              </Button>
            </div>);
            if (step.actions) {
              stepActions = step.actions;
            }
            return (
              <Step key={step.key ? step.key : step.label}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  {step.content}
                  <div className={classes.actionsContainer}>
                    {stepActions}
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }

  handleNext = () => {
    this.setState({activeStep: this.state.activeStep + 1});
  };
  handleBack = () => {
    this.setState({activeStep: this.state.activeStep - 1});
  };
}
export default withStyles(stepperStyle)(VerticalLinearStepper);
