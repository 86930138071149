import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, withStyles, Typography, Paper } from '@material-ui/core';
import cx from "classnames";

const styles = theme => ({
  root: {
    // margin: "25px 0",
    // margin: "12.5px 0",
    // margin: "8px 0",
  },
  floatingCardContainer: {
    overflow: "visible",
  },
  title: {
    fontWeight: "bold"
  }
});

class CardContainer extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    noPadding: PropTypes.bool,
  };

  render() {
    const { allowOverflow, classes, noPadding, className, contentProps, children, ...restProps } = this.props;
    let style  = {};
    let newContentProps = Object.assign({}, contentProps || {});
    if (noPadding) {
      style.padding = "0";
    }
    newContentProps.style = Object.assign(style, newContentProps.style || {});
    return (
      <Card classes={{root: allowOverflow ? classes.floatingCardContainer : ""}} className={(className ? className : "") +  " " + classes.root} {...restProps}>
        <CardContent {...newContentProps}>
          {children}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(CardContainer);
