import React, {useState, useContext } from 'react'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select,
  Table,
  TableBody,
  TableRow,
  TableCell } from '@material-ui/core'

import {CustomInput, BasicDialog, Button, CardContainer} from 'components'
import NewDataTable from '../../components/Table/NewDataTable'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function ReturnTicket(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, returnTicket} = props;

  function renderContent() {
    return (
      <Grid container className={classes.root} spacing={2}> 
        <CardContainer style={{width: '100%'}}>
          <Button style={{marginBottom: '1rem'}} onClick={()=>{ utils.exportTableToCsv('table-export', 'Return Ticket');
          }}>Export</Button>

          <NewDataTable
            rows={returnTicket}
            columns={[
              {
                key: 'sku',
                label: 'SKU',
              },
              {
                key: 'upc',
                label: 'UPC',
              },
              {
                key: 'tote',
                label: 'Tote',
              },
            ]}
            noPagination
            maxHeight={500}
            id="table-export"
          />
        </CardContainer>
      </Grid>
    );
  }

  return (
    <BasicDialog
      fullWidth
      maxWidth='md'
      open
      onClose={handleClose}
      header={props.title || "Return Ticket"}
      content={renderContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Close</Button>,
      ]}
    />
  );
}