import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Input, ListItemText, Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

const switchWarehuoseStatus = {
  UNBATCH: true,
  CANCELLED: true,
  BACKORDER: true,
};
const orderEditStatus = {
  UNBATCH: true,
  CANCELLED: true,
  // HOLDING: true,
  BACKORDER: true,
};
const orderShipped = {
  SHIPPED: true,
  RETURNED: true,
}
const vendorMap = {
  VIP: 'VIP',
  Priority: 'Priority',
  'DSCO-NORDSTROM': 'DSCO-NORDSTROM',
  NEIMAN: 'NEIMAN',
  SAKS: 'SAKS',
  DILLARDS: 'DILLARDS',
  'The Good Stuff': 'The Good Stuff',
  maisonette: 'maisonette',
  'RGG': 'RGG',
};

export default function EditOrderDetail(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {order: defaultOrder, handleClose, methodList, handleSubmit} = props;
  const [order, setOrder] = useState(Object.assign({}, defaultOrder));

  let methodOptions = methodList.map((elem)=>(<MenuItem key={elem.method} value={elem.method}>{elem.name}</MenuItem>));
  let backendMethodList = localStorage.getItem('method_list') ? JSON.parse(localStorage.getItem('method_list')) : [];
  if (backendMethodList.length == 0) backendMethodList = utils.defaultShippingMethodOptions;
  if (methodOptions.length == 0) {
    methodOptions = backendMethodList.map((elem)=>(<MenuItem key={elem.method} value={elem.method}>{elem.name}</MenuItem>));
  }

  const warehouse_id_map = JSON.parse(localStorage.getItem('warehouse_map'));
  const customer_warehouse_list = JSON.parse(localStorage.getItem('customer_warehouse_list'));
  const current_customer_w_list = customer_warehouse_list[localStorage.getItem('customer_id')].warehouse_list;

  function onOrderChange(key, newVal) {
    let newOrder = Object.assign({}, order);
    newOrder[key] = newVal;
    if (key == 'warehouse_id' && warehouse_id_map && warehouse_id_map[newVal]) 
    {
      newOrder['warehouse'] = warehouse_id_map[newVal].name;
    }
    setOrder(newOrder);
  }

  function renderFormContent() {
    let status_disabled = false;
    let status_options = [
      <MenuItem key='1' value='UNBATCH'>UNBATCH</MenuItem>,
      <MenuItem key='2' value='PICKING'>PICKING</MenuItem>,
      <MenuItem key='3' value='PICKED'>PICKED</MenuItem>,
      <MenuItem key='4' value='VERIFIED'>VERIFIED</MenuItem>,
      // {/* <MenuItem value='HOLDING'>HOLDING</MenuItem> */}
      <MenuItem key='5' value='BACKORDER'>BACKORDER</MenuItem>,
      <MenuItem key='6' value='SHIPPED'>SHIPPED</MenuItem>,
      <MenuItem key='7' value='CANCELLED'>CANCELLED</MenuItem>,
    ];
    if (dataContext.roleType.get() == 'customer') {
      status_options = [
        <MenuItem key='1' value='UNBATCH'>UNBATCH</MenuItem>,
        <MenuItem key='2' value='BACKORDER'>BACKORDER</MenuItem>,
        <MenuItem key='3' value='CANCELLED'>CANCELLED</MenuItem>,
      ];
      if (!orderEditStatus[order.order_status]) {
        // customer can't edit status
        status_disabled = true;
        status_options = [
          <MenuItem key='1' value='UNBATCH'>UNBATCH</MenuItem>,
          <MenuItem key='2' value='PICKING'>PICKING</MenuItem>,
          <MenuItem key='3' value='PICKED'>PICKED</MenuItem>,
          <MenuItem key='4' value='VERIFIED'>VERIFIED</MenuItem>,
          // {/* <MenuItem value='HOLDING'>HOLDING</MenuItem> */}
          <MenuItem key='5' value='BACKORDER'>BACKORDER</MenuItem>,
          <MenuItem key='6' value='SHIPPED'>SHIPPED</MenuItem>,
          <MenuItem key='7' value='CANCELLED'>CANCELLED</MenuItem>,
          <MenuItem key='RETURNED' value='RETURNED'>RETURNED</MenuItem>
        ];
      } else {
        // customer can edit status
        status_options = [
          <MenuItem key='1' value='UNBATCH'>UNBATCH</MenuItem>,
          <MenuItem key='2' value='BACKORDER'>BACKORDER</MenuItem>,
          <MenuItem key='3' value='CANCELLED'>CANCELLED</MenuItem>,
        ];
      }
    } else {
      if (orderShipped[order.order_status]) {
        status_disabled = true;
        status_options.push(<MenuItem key='RETURNED' value='RETURNED'>RETURNED</MenuItem>);
      }
    }

    return (
      <Grid container className={classes.root} spacing={2}>
        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Order Type</InputLabel>
          <Select
            onChange={(e)=>{onOrderChange('order_type', e.target.value)}}
            value={order.order_type}
          >
            <MenuItem value='dropship'>Dropship</MenuItem>
            <MenuItem value='edi'>EDI</MenuItem>
            <MenuItem value='gift'>Gift</MenuItem>
            <MenuItem value='monogram'>Monogram</MenuItem>
            <MenuItem value='retail'>Retail</MenuItem>
            <MenuItem value='whole-sale'>Wholesale</MenuItem>
            <MenuItem value='final-sale'>Final-sale</MenuItem>
          </Select>
        </FormControl>
          
        <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Shipping Method</InputLabel>
            <Select
              onChange={(e)=>{onOrderChange('method', e.target.value)}}
              value={order.method}
            >
              {methodOptions}
            </Select>
        </FormControl>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={parseInt(order.saturday_delivery) === 1 || false}
                onChange={(e)=>{onOrderChange('saturday_delivery', e.target.checked ? 1 : 0)}}
                color='primary'
              />
            }
            label='Saturday Delivery'
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={parseInt(order.signature_required) || false}
                onChange={(e)=>{onOrderChange('signature_required', e.target.checked ? 1 : 0)}}
                color='primary'
              />
            }
            label='Signature Required'
          />
        </FormGroup>

        {/* Todo, only for some order, status is editable
        for order under different status, the target status is different. */}
        {/* order is not shipped or returned, or carton */}
        <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Order Status</InputLabel>
            <Select
              onChange={(e)=>{onOrderChange('order_status', e.target.value)}}
              value={order.order_status}
              disabled={status_disabled}
            >
            {status_options}
            </Select>
        </FormControl>

        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Warehouse</InputLabel>
          <Select
            disabled={!switchWarehuoseStatus[defaultOrder.order_status]}
            onChange={(e)=>{onOrderChange('warehouse_id', e.target.value)}}
            value={order.warehouse_id || ''}
          >
            {current_customer_w_list.map((elem)=><MenuItem key={elem.warehouse_id} value={elem.warehouse_id}>{elem.name}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl fullWidth className={classes.customInput}>
          <InputLabel shrink>Incoterms</InputLabel>
          <Select
            onChange={(e)=>{onOrderChange('incoterms', e.target.value)}}
            value={order.incoterms || ''}
          >
            <MenuItem value='DDU'>DDU</MenuItem>
            <MenuItem value='DDP'>DDP</MenuItem>
          </Select>
        </FormControl>

        <CustomInput
          labelText='Third Party Carrier Account #'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onOrderChange('carrier_account', e.target.value)},
            value: order.carrier_account || '',
          }}
        />

        {/* <FormControl fullWidth className={classes.customInput}>
          <InputLabel shrink>Payment Term</InputLabel>
          <Select
            onChange={(e)=>{onOrderChange('payment_term', e.target.value)}}
            value={order.payment_term}
          >
            <MenuItem value='Due Upon Received'>Due Upon Received</MenuItem>
            <MenuItem value='Net 7'>Net 7</MenuItem>
            <MenuItem value='Net 14'>Net 14</MenuItem>
            <MenuItem value='Net 21'>Net 21</MenuItem>
            <MenuItem value='Net 28'>Net 28</MenuItem>
            <MenuItem value='Net 30'>Net 30</MenuItem>
          </Select>
        </FormControl> */}
          
        <CustomInput
          labelText='Slip Note'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onOrderChange('gift_message', e.target.value)},
            value: order.gift_message || '',
          }}
        />

        {/* <CustomInput
          labelText='Packing Instruction'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onOrderChange('packing_instruction', e.target.value)},
            value: order.packing_instruction,
          }}
        /> */}

        {/* <CustomInput
          labelText='Vendor'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onOrderChange('vendor', e.target.value)},
            value: order.vendor,
          }}
        /> */}

        <FormControl fullWidth className={classes.customInput}>
          <InputLabel shrink>Vendor</InputLabel>
          <Select
            onChange={(e)=>{onOrderChange('vendor', e.target.value)}}
            value={order.vendor || ''}
          >
            {(!vendorMap[defaultOrder.vendor] && order.vendor !== '' && order.vendor != null) && <MenuItem value={defaultOrder.vendor} disabled>{defaultOrder.vendor}</MenuItem>}
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='VIP'>VIP</MenuItem>
            <MenuItem value='Priority'>Priority</MenuItem>
            <MenuItem value='DSCO-NORDSTROM'>DSCO-NORDSTROM</MenuItem>
            <MenuItem value='NEIMAN'>NEIMAN</MenuItem>
            <MenuItem value='SAKS'>SAKS</MenuItem>
            <MenuItem value='DILLARDS'>DILLARDS</MenuItem>
            <MenuItem value='The Good Stuff'>The Good Stuff</MenuItem>
            <MenuItem value='maisonette'>maisonette</MenuItem>
            <MenuItem value='RGG'>RGG</MenuItem>
          </Select>
        </FormControl>

        <CustomInput
          labelText='Carrier'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onOrderChange('carrier', e.target.value)},
            value: order.carrier || '',
          }}
        />

        <CustomInput
          labelText='Start date'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            type: 'date',
            // inputProps: {
            //   min: moment().format("YYYY-MM-DD"),
            // },
            onChange: (e)=>{onOrderChange('start_date', e.target.value)},
            value: order.start_date || '',
          }}
        />

        <CustomInput
          labelText='Ship date'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            type: 'date',
            // inputProps: {
            //   min: moment().format("YYYY-MM-DD"),
            // },
            onChange: (e)=>{onOrderChange('ship_date', e.target.value)},
            value: order.ship_date || '',
          }}
        />

        <CustomInput
          labelText='Cancel date'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            type: 'date',
            // inputProps: {
            //   min: moment().format("YYYY-MM-DD"),
            // },
            onChange: (e)=>{onOrderChange('cancel_date', e.target.value)},
            value: order.cancel_date || '',
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    const {order_type, method, start_date, ship_date, cancel_date } = order;
    let err = '';
    if (!method) err += "Shipping method is required.\n";
    if (!order_type) err += "Order type is required.\n";
    // if (start_date && !utils.isDateInRange(start_date)) err += "Start Date is out of range.\n";
    // if (ship_date && !utils.isDateInRange(ship_date))  err += "Ship Date is out of range.\n";
    // if (cancel_date && !utils.isDateInRange(cancel_date)) err += "Cancel Date is out of range.\n";

    if (err) {
      dataContext.alert(err);
      return;
    }

    handleSubmit(order);
  }

  let title = "Edit Order";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}