import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import axios from 'axios';
import utils from 'utils/utils'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select,
  Table,
  TableBody,
  TableRow,
  TableCell, InputAdornment, Popover } from '@material-ui/core'
import {
  Info
} from '@material-ui/icons'

import {CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function EditTicket(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, ticket: defaultTicket, status_list } = props;

  let customer_options = JSON.parse(localStorage.getItem('customer_detail'))[localStorage.getItem('warehouse_id')]; 

  const [loading,setLoading] = useState(false);
  const [ticket,setTicket] = useState(defaultTicket || {
    subject:'',
    category: '',
    status: '',
    description: '',
    customer: localStorage.getItem('customer_name'),
    agent: '',
  });

  function handleTicketChange(val, key) {
    let new_ticket = Object.assign({}, ticket);
    new_ticket[key] = val;
    setTicket(new_ticket);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}> 
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        <CustomInput
          labelText='Subject'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{handleTicketChange(e.target.value, 'subject')},
            value: (ticket.subject || ''),
          }}
        />

        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Category</InputLabel>
          <Select
              onChange={(e)=>{handleTicketChange(e.target.value, 'category')}}
              value={ticket.category}
          >
           {(JSON.parse(localStorage.getItem('category_list')) || []).map((elem)=><MenuItem key={elem} value={elem}>{elem}</MenuItem>)} 
          </Select>
        </FormControl>

        <FormControl required fullWidth className={classes.selectInput}>
          <InputLabel shrink>Status</InputLabel>
          <Select
            value={(ticket.status || '')}
            onChange={(e)=>{handleTicketChange(e.target.value, 'status')}}
          >
            {status_list.map((elem)=><MenuItem key={elem.value} value={elem.value}>{elem.label}</MenuItem>)} 
          </Select>
        </FormControl>

        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Owner</InputLabel>
          <Select
              onChange={(e)=>{handleTicketChange(e.target.value, 'agent_id')}}
              value={ticket.agent_id}
          >
           {props.agent_list.map((elem)=><MenuItem key={elem.agent_id} value={elem.agent_id}>{elem.name}</MenuItem>)} 
          </Select>
        </FormControl>

        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Customer</InputLabel>
          <Select
              onChange={(e)=>{handleTicketChange(e.target.value, 'customer')}}
              value={ticket.customer}
          >
           {customer_options.map((elem)=><MenuItem key={elem.shortname} value={elem.shortname}>{elem.shortname}</MenuItem>)} 
          </Select>
        </FormControl>
      </Grid>
    );
  }
  function onSubmit() {
    let { subject, customer, agent, agent_id, status, category, } = ticket;
    
    let err = '';
    if (!subject) err += 'Subject is required.\n';
    if (!category) err += 'Category is required.\n';
    if (!agent_id) err += 'Owner is required.\n';
    if (!customer) err += 'Customer is required.\n';
    if (!status) err += 'Status is required.\n';

    let req_data = Object.assign(props.ticket, {
      subject, category, status, customer, agent: agent_id, agent_id
    });

    if (err) dataContext.alert(err);
    else updateTicketAjax(req_data); // todo, decide if need to pass the formatted
  }
  function updateTicketAjax(req_data) {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/updateticket`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: req_data
    });
    setLoading(true);
    req.then(updateTicketSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert}));
  }
  function updateTicketSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      dataContext.alert(resp.data.Error);
      return;
    }
    props.handleSubmit();
  }

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={props.title || "Edit Zoho Ticket"}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}