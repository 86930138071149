import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, FormGroup, FormControlLabel, Checkbox, Tooltip, InputLabel, Select, FormControl, MenuItem, IconButton, withStyles } from "@material-ui/core";
import {
  Error,
  Edit,
} from '@material-ui/icons';
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  nonSelectable: {
    userSelect: 'none'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class Movement extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // scan input ref
    this.input_refs = {
      prev_loc: null,
      sku: null,
      prev_pallet: null,
      target_loc: null,
      target_pallet: null,
      quantity: null,
      notes: null,
    };
    // verified data 
    this.temp_data = {
      barcode_map: {},
      prev_location_id: '',
      prev_location_name: '',
      prev_items: [],
      prev_skus: {},
      item_id: '',
      item_inventory: [],
      location_quantity: 0,
      allocated_quantity: 0,
      prev_pallets: {},
      target_location_id: '',
      target_location_name: '',
      target_items: [],
      target_pallets: {},
    };

    this.state = {
      loading: false,
      // lock input settings
      forceUpdate: {},
      lock_inputs: {
        prev_loc: false,
        sku: false,
        prev_plt: false,
        qty: false,
      },
    };

    document.title = "Put-away";
  }

  onPrevLocationChange = () => {
    let location = utils.formatString(this.input_refs.prev_loc.value);
    if (!location || location.length < 3) return;
    this.resetInputs('prev_loc');
    this.checkLocation('prev_loc', location);    
  }
  handlePrevScan = _.debounce(this.onPrevLocationChange, 150);
  onSkuChange = () => {
    let sku = utils.formatString(this.input_refs.sku.value);
    if (!sku) return;
    this.resetInputs('sku');
    if (this.temp_data.barcode_map[sku]) sku = this.temp_data.barcode_map[sku];
    this.checkSku(sku);
  }
  handleSkuScan = _.debounce(this.onSkuChange, 150)
  onTargetLocationChange = () =>{
    let location = utils.formatString(this.input_refs.target_loc.value);
    if (!location || location.length < 3) return;
    this.resetInputs('target_loc');
    this.checkLocation('target_loc', location);   
  }
  handleTargetScan = _.debounce(this.onTargetLocationChange, 150)
  focusInput = (inputName) => {
    if (this.input_refs[inputName]) this.input_refs[inputName].focus();
  }
  resetInputs = (inputName) => {
    switch (inputName) {
      case 'prev_loc':
        // this.input_refs['prev_loc'].value = '';
        this.temp_data.prev_location_id = '';
        this.temp_data.prev_location_name = '';
        this.temp_data.prev_items = [];
        this.temp_data.prev_skus = {};

        this.input_refs['sku'].value = '';
        this.temp_data.item_id = '';
        this.temp_data.sku = '';
        this.temp_data.location_quantity = 0;
        this.temp_data.allocated_quantity = 0;
        this.temp_data.prev_pallets = {};
        this.input_refs['prev_pallet'].value = '';
        break;
      case 'sku':
        // this.input_refs['sku'].value = '';
        this.temp_data.item_id = '';
        this.temp_data.location_quantity = 0;
        this.temp_data.allocated_quantity = 0;
        this.temp_data.prev_pallets = {};
        // this.input_refs['prev_pallet'].value = '';
        break;
      case 'target_loc':
        // this.input_refs['target_loc'].value = '';
        this.temp_data.target_location_id = '';
        this.temp_data.target_location_name = '';
        this.temp_data.target_items = [];
        this.input_refs['target_pallet'].value = '';
        this.temp_data.target_pallets = {};
    }
  }
  resetPage = () => {
    const { prev_loc, sku, prev_plt, qty } = this.state.lock_inputs

    if (!prev_loc) {
      this.input_refs['prev_loc'].value = '';
      this.temp_data.prev_location_id = '';
      this.temp_data.prev_location_name = '';
      this.temp_data.prev_items = [];
      this.temp_data.prev_skus = {};
    }
    if (!sku) {
      this.input_refs['sku'].value = '';
      this.temp_data.item_id = '';
      this.temp_data.sku = '';
      this.temp_data.item_inventory = [];
      this.temp_data.location_quantity = 0;
      this.temp_data.allocated_quantity = 0;
      this.temp_data.prev_pallets = {};
    }
    if (!prev_plt) {
      this.input_refs['prev_pallet'].value = '';
    }
    if (!qty) {
      this.input_refs['quantity'].value = '';
    }

    // switch (true) {
    //   case !prev_loc:
    //     this.input_refs['prev_loc'].value = '';
    //     this.temp_data.prev_location_id = '';
    //     this.temp_data.prev_location_name = '';
    //     this.temp_data.prev_items = [];
    //     this.temp_data.prev_skus = {};
    //   case !sku:
    //     this.input_refs['sku'].value = '';
    //     this.temp_data.item_id = '';
    //     this.temp_data.sku = '';
    //     this.temp_data.item_inventory = [];
    //     this.temp_data.location_quantity = 0;
    //     this.temp_data.allocated_quantity = 0;
    //   case !prev_plt:
    //     this.temp_data.prev_pallets = {};
    //     this.input_refs['prev_pallet'].value = '';
    //   case !qty:
    //     this.input_refs['quantity'].value = '';
    // }
    
    // default to reset these page data    
    this.input_refs['target_loc'].value = '';
    this.temp_data.target_location_id = '';
    this.temp_data.target_location_name = '';
    this.temp_data.target_items = [];
    this.input_refs['target_pallet'].value = '';
    this.temp_data.target_pallets = {};
    this.input_refs['notes'].value = '';

    this.setState({forceUpdate: {}});
  }

  // API call
  // load default inventory
  checkLocation = (check_type, location) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/movement/${location}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.checkLocationSuccess.bind(this, check_type)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  checkLocationSuccess = (check_type, resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    if (!data || data.length === 0) {
    this.context.alert("No data found");
      return;
    }

    if (check_type === 'prev_loc') {
      this.temp_data.prev_location_id = data.item_list[0].location_id;
      this.temp_data.prev_location_name = data.item_list[0].location;
      this.temp_data.prev_items = data.item_list;
      this.temp_data.prev_skus = {};
      for (let item of data.item_list) this.temp_data.prev_skus[item.sku] = item;
      let prev_pallets = {};
      for (let pallet of data.pallet_list) {
        prev_pallets[pallet.pallet_id] = true;
      }
      this.temp_data.prev_pallets = prev_pallets;
      this.setState({forceUpdate: {}});
      this.input_refs['sku'].focus();
    } else {
      this.temp_data.target_location_id = data.item_list[0].location_id;
      this.temp_data.target_location_name = data.item_list[0].location;
      this.temp_data.target_items = data.item_list;
      let target_pallets = {};
      for (let pallet of data.pallet_list) {
        target_pallets[pallet.pallet_id] = true;
      }
      this.temp_data.target_pallets = target_pallets;
      this.setState({forceUpdate: {}});
      if (_.isEmpty(target_pallets)) {
        this.input_refs['quantity'].focus();
      } else {
        this.input_refs['target_pallet'].focus();
      }
    }

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    this.setState({forceUpdate: {}});
  }
  checkSku = (sku) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/movement/${this.temp_data.prev_location_id}/${sku}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.checkSkuSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  checkSkuSuccess = (resp) => {

    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    if (!data || data.length === 0) {
    this.context.alert("No data found");
      return;
    }

    let result_sku = data[0].sku;
    if (this.temp_data.prev_skus[result_sku]) { // check if this sku is in previous location
      let move_item = this.temp_data.prev_skus[result_sku];
      this.temp_data.location_quantity = parseInt(move_item.quantity);
      this.temp_data.item_id = data.item[0].item_id;
      this.temp_data.sku = result_sku;
      this.temp_data.item_inventory = data.item;
      let prev_pallets = {};
      for (let pallet of data.pallet) {
        prev_pallets[pallet.pallet_id] = pallet;
      }
      this.temp_data.prev_pallets = prev_pallets;
      this.setState({forceUpdate: {}});
      let prev_plt = this.input_refs.prev_pallet.value.replace(/[pP]/g, '');

      if (!_.isEmpty(prev_pallets) && !this.input_refs['prev_pallet'].value) {
        this.input_refs['prev_pallet'].focus(); // if has previous plt and the input value is empty
      } else {
        this.input_refs['target_loc'].focus(); // if no prev plt or prev plt already entered (locked)
      }
      this.temp_data.allocated_quantity = data.allocated_quantity;
    } else {
      this.context.alert(`The SKU ${result_sku} is not in previous location.`);
    }
  }
  submitValidate = (e) => {
    e.preventDefault();
    // Check customer id and local storage customer id
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }

    const {
      prev_location_id, prev_location_name,
      prev_items, prev_skus, item_id, sku,
      location_quantity, allocated_quantity,
      prev_pallets,
      target_location_id, target_location_name,
      target_items, target_pallets,
    } = this.temp_data;

    let quantity = this.input_refs.quantity.value;
    let available_qty = parseInt(this.temp_data.location_quantity) - parseInt(this.temp_data.allocated_quantity);
    let notes = this.input_refs.notes.value || '';
    let prev_plt = this.input_refs.prev_pallet.value.replace(/[pP]/g, '');
    let target_plt = this.input_refs.target_pallet.value.replace(/['pP']/g, '');
    
    let err = '';
    if (!prev_location_id) err += 'Previous location is not verified.';
    if (!item_id) err += 'SKU is not verified.';
    if (!_.isEmpty(prev_pallets) && !prev_pallets[prev_plt]) err += `Previous Pallet ${this.input_refs.prev_pallet.value} doesnot exist at locaiton. \n`;
    if (!target_location_id) err += 'Target location is not verified.';   
    if (!_.isEmpty(target_pallets) && !target_pallets[target_plt]) err += `Target Pallet ${this.input_refs.target_pallet.value} doesnot exist at location. \n`;
    if (prev_location_id === target_location_id) err += `Previous location and Target location are the same.`;
    if (!quantity || quantity <= 0) err += 'Quantity should be greater than 0';

    if (err) { // alert non-quantity-to-move errors
      this.context.alert(err);
      return;
    }
    // Check if quantity to move is available
    let pallet_quantity = location_quantity; // default plt qty to location qty
    if (!_.isEmpty(prev_pallets)) {
      // item has prev palelt, check pallet quantity
      pallet_quantity = parseInt(prev_pallets[prev_plt.replace('p', '').replace('P', '')].quantity);
      if (quantity > pallet_quantity) {
          err += "You can only move " + pallet_quantity + ' of this item from this pallet. Please change the quantity.\n';
      }
    } else if (quantity > available_qty) err +=  `Only ${available_qty} of this item available to move from this location.`; // Check Location qty

    if (err) {
      this.context.alert(err);
    } else {
      this.submitMovementOnce({
        previous_location_id: prev_location_id,
        previous_location: prev_location_name,
        location_quantity,
        pallet_quantity,
        sku,
        item_id,
        target_location_id,
        target_location: target_location_name,
        quantity,
        notes,
        previous_pallet: prev_plt,
        target_pallet: target_plt
      });
    }
  }
  submitMovement = (data) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/movement`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data
    });

    this.setState({loading: true});
    req.then(this.submitMovementSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  resetSubmitOnce = () => {this.submitMovementOnce = _.once(this.submitMovement);} // reset the submit picking once after the ajax call returns
  submitMovementOnce = _.once(this.submitMovement);
  submitMovementSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    this.context.snackDisplay('Submit movment success.');

    this.temp_data.prev_items = data.previous_location;
    this.temp_data.prev_skus = {};
    for (let item of data.previous_location) this.temp_data.prev_skus[item.sku] = item;
    let newPrevPallets = {};
    for (let pallet of data.previous_pallet) {
      newPrevPallets[pallet.pallet_id] = true;
    }
    this.temp_data.item_inventory = data.item;
    this.temp_data.prev_pallets = newPrevPallets;
    this.temp_data.sku = data.item[0].sku;
    if (this.temp_data.prev_skus[this.temp_data.sku]) {
      this.temp_data.location_quantity = this.temp_data.prev_skus[this.temp_data.sku].quantity;
    } else {
      this.setState({lock_inputs: Object.assign(this.state.lock_inputs, {sku: false,})});
      this.context.alert("This item no longer exists in previous location, can't lock SKU, please scan another one!");
    }
    this.temp_data.target_items = data.target_location;
    let target_pallets = {};
    for (let pallet of data.target_pallet) {
      target_pallets[pallet.pallet_id] = true;
    }
    this.temp_data.target_pallets = target_pallets;
    this.resetPage();
  }

  itemInventoryTable  = () => {
    const { classes } = this.props;
    if (!this.temp_data.item_id) return;
    return (
      <GridItem xs={12} md={6}>
        <CardContainer>
          <div className={this.props.classes.root}>
            <div className={classes.title} style={{padding: "1rem 0"}}>
              <Typography variant="h6" style={{marginBottom: '.5rem'}}>
                {`${this.temp_data.sku} Inventory`}
              </Typography>
            </div>
            <NewDataTable
              rows={this.temp_data.item_inventory || []}
              columns={[
                {
                  key: 'location',
                  label: 'Location',
                  contentNoWrap: true,
                  render: (loc) => {
                    let primary = false;
                    let loc_parts = loc.split('-');
                    let not_pick = {
                      'DM': true, 'DK': true, 'PT': true, "SP": true, "SR": true, 
                    };
                    if (not_pick[loc_parts[0]]) primary = false;
                    else if (parseInt(loc_parts[2]) <= 1) primary = true;

                    return primary ? <span style={{fontWeight: 'bold'}}>{loc}</span> : loc;
                  }
                },
                {
                  key: 'sku',
                  label: 'SKU',
                  contentNoWrap: true,
                },
                {
                  key: 'quantity',
                  label: 'Quantity',
                },
                {
                  key: 'upc',
                  label: 'UPC',
                },
              ]}
              noPagination
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  targetLocationTable = () => {
    const { classes } = this.props;
    if (!this.temp_data.target_location_id) return;
    return (
      <GridItem xs={12} md={6}>
        <CardContainer>
          <div className={this.props.classes.root}>
            <div className={classes.title} style={{padding: "1rem 0"}}>
              <Typography variant="h6" style={{marginBottom: '.5rem'}}>
                {`Target location ${this.temp_data.target_location_name} items`}
              </Typography>
            </div>
            <NewDataTable
              rows={this.temp_data.target_items || []}
              columns={[
                {
                  key: 'location',
                  label: 'Location',
                  contentNoWrap: true,
                },
                {
                  key: 'sku',
                  label: 'SKU',
                  contentNoWrap: true,
                },
                {
                  key: 'quantity',
                  label: 'Quantity',
                },
                {
                  key: 'upc',
                  label: 'UPC',
                },
              ]}
              noPagination
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  render() {
    const { classes } = this.props;
    const { lock_inputs } = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        <GridItem xs={12} sm={8}>
          <CardContainer>
            <form onSubmit={this.submitValidate}>      
              <CustomInput
                  labelText='Previous Location'
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onKeyUp: (e)=>{
                      e.preventDefault();
                      this.handlePrevScan();
                    },
                    onPaste: (e)=>{
                      if (!utils.checkAllowCopy()) e.preventDefault();
                    },
                    defaultValue: '',
                    inputRef: elem => this.input_refs.prev_loc = elem
                  }}
              />

              <CustomInput
                labelText='SKU'
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  onKeyUp: (e)=>{
                    e.preventDefault();
                    this.handleSkuScan();
                  },
                  onPaste: (e)=>{
                    if (!utils.checkAllowCopy()) e.preventDefault();
                  },
                  disabled: !this.temp_data.prev_location_id,
                  defaultValue: '',
                  inputRef: elem => this.input_refs.sku = elem
                }}
            />

            <CustomInput
                labelText='Previous Pallet'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  onKeyUp: (e)=>{
                    // e.preventDefault();
                    this.focusInput('target_loc');
                  },
                  defaultValue: '',
                  disabled: _.isEmpty(this.temp_data.prev_pallets),
                  required: _.isEmpty(this.temp_data.prev_pallets),
                  inputRef: elem => this.input_refs.prev_pallet = elem
                }}
            />

            <CustomInput
                  labelText='Target Location'
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onKeyUp: (e)=>{
                      e.preventDefault();
                      this.handleTargetScan();
                    },
                    onPaste: (e)=>{
                      if (!utils.checkAllowCopy()) e.preventDefault();
                    },
                    defaultValue: '',
                    inputRef: elem => this.input_refs.target_loc = elem
                  }}
              />

              <CustomInput
                labelText='Target Pallet'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  onKeyUp: (e)=>{
                    // e.preventDefault();
                    this.focusInput('quantity');
                  },
                  defaultValue: '',
                  disabled: _.isEmpty(this.temp_data.target_pallets),
                  required: _.isEmpty(this.temp_data.target_pallets),
                  inputRef: elem => this.input_refs.target_pallet = elem
                }}
              />

              <CustomInput
                labelText='Quantity'
                formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                  defaultValue: '',
                  type: 'number',
                  inputProps:{
                    min: 0,
                    step: 1
                  },
                  inputRef: elem => this.input_refs.quantity = elem
                }}
              />

              <CustomInput
                labelText='Notes'
                formControlProps={{
                    fullWidth: true,
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                  defaultValue: '',
                  inputRef: elem => this.input_refs.notes = elem
                }}
              />

              <GridItem xs={12} sm={12} md={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                        <Checkbox
                            checked={lock_inputs.prev_loc}
                            onChange={(e)=>{
                                if (e.target.checked) {
                                  lock_inputs.prev_loc = true;
                                  // this.setState({lock_prev: e.target.checked,});
                                } else {
                                  lock_inputs.prev_loc = false;
                                  lock_inputs.sku = false;
                                  lock_inputs.prev_plt = false;
                                }
                                this.setState({forceUpdate: {}});
                            }}
                            color='primary'
                        />
                    }
                    label='Lock Previous Location'
                  />

                  <Tooltip title={!lock_inputs.prev_loc ? "You need to lock previous location first": ''}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lock_inputs.sku}
                          disabled={!lock_inputs.prev_loc}
                          onChange={(e)=>{lock_inputs.sku = e.target.checked; this.setState({forceUpdate: {}});}}
                          color='primary'
                        />
                      }
                      label='Lock SKU'
                    />
                  </Tooltip>

                  <Tooltip title={!lock_inputs.prev_loc ? "You need to lock previous location first": ''}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={lock_inputs.prev_plt}
                          disabled={!lock_inputs.prev_loc}
                          onChange={(e)=>{lock_inputs.prev_plt = e.target.checked; this.setState({forceUpdate: {}});}}
                          color='primary'
                        />
                      }
                      label='Lock Previous Pallet'
                    />
                  </Tooltip>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lock_inputs.qty}
                        onChange={(e)=>{lock_inputs.qty = e.target.checked; this.setState({forceUpdate: {}});}}
                        color='primary'
                      />
                    }
                    label='Lock QTY'
                  />
                </FormGroup>
              </GridItem>

              <Button type='submit' className={classes.button}>Submit</Button>
            </form>
          </CardContainer>
        </GridItem>

        {this.itemInventoryTable()}
        {this.targetLocationTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(Movement);

