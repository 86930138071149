import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Typography, withStyles, IconButton, MenuItem, FormControl, Select } from "@material-ui/core";
import axios from 'axios';
import utils from 'utils/utils';
import _ from 'lodash'
import {
  Add,
  Edit,
  DeleteForever
} from '@material-ui/icons';
import {
  NewDataTable,
  CardContainer,
  CustomInput,
} from 'components';
import DataContext from 'context/Data'
import EditCarrierDialog from 'views/Dialogs/Customer/EditCarrier'
import EditStoreDialog from 'views/Dialogs/Customer/EditStore'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

const store_types = [
  // {
  //     label: 'AMAZON',
  //     value: 'AMAZON',
  // },
  {
      label: 'SHOPIFY',
      value: 'SHOPIFY',
  },
  {
      label: 'WOOCOMMERCE',
      value: 'WOOCOMMERCE',
  },
];
const carrier_types = [
{
    label: 'UPS',
    value: 'UPS',
},
{
    label: 'FedEx',
    value: 'FedEx',
},
// {
//     label: 'EasyPost',
//     value: 'EasyPost',
// },
// On 2024-03-07 Bo changed EasyPost to USPS
{
  label: 'USPS',
  value: 'USPS',
},
];

class ClientAccount extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    account: PropTypes.array.isRequired,
    onAccountChange: PropTypes.func.isRequired,
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  constructor(props) {
    super(props);

    let type_list = {};

    for (let single of props.account) {
      type_list[single.carrier] = true;
    }

    this.state = {
      loading: false,
      dialog: '',
      type_list: type_list,
      type: 'Carrier',
      carrier: '',
      active_account: {},
      active_index: '',
    };
  }

  render() {
    return (
      <div>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        {this.renderDialog()}

        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Manage Third-Party Accounts
        </Typography>

        {this.renderAccountTable()}
      </div>
    );
  }

  renderAccountTable = () => {
    const { classes, account } = this.props;
    const { type_list, type, carrier } = this.state;

    let rows = Array.from(account);
    rows = rows.filter((elem)=>elem.type == 'Carrier');

    let options = type == 'Store' ? store_types : carrier_types;
    options = options.filter((e)=>{
      return !type_list[type_list];
    });

    rows.push({addNew: true});

    let columns = [
      {
        key: 'type',
        label: 'Type',
        render: (val, key, row) => {
          if (row.addNew) {
              return (
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{width: '120px'}}>
                      <FormControl required fullWidth className={classes.selectInput}>
                            <Select
                                value={type}
                                onChange={(e)=>{this.setState({type: e.target.value})}}
                                inputProps={{
                                    name: 'type',
                                    id: 'type',
                                }}
                            >
                              {/* <MenuItem key="STORE" value="Store">Store</MenuItem> */}
                              <MenuItem key="CARRIER" value="Carrier">Carrier</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                  </div>
              );
          } else {
              return <div>{val || ''}</div>
          }
        }
      },
      {
        key: 'carrier',
        label: 'Vendor',
        render: (val, key, row) => {
            if (row.addNew) {
                return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div style={{width: '200px'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <Select
                                  value={carrier}
                                  onChange={(e)=>{this.setState({carrier: e.target.value})}}
                                  inputProps={{
                                      name: 'carrier',
                                      id: 'carrier',
                                  }}
                              >
                                  {options.map((e)=>{
                                      return <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                                  })}
                              </Select>
                          </FormControl>
                        </div>
                        
                    </div>
                );
            } else {
                let type = row.carrier; // might change the key later
                return <div>{type ? type : ''}</div>
            }
        }
    },
    {
        key: 'account',
        label: 'Account',
        width: 'auto',
        contentNoWrap: true,
        render: (val, key, row) => {
            if (row.addNew) {
                return '';
            } else {
                return val ? val : '';
            }
        }
    },
    {
        key: 'action',
        label: 'Action',
        render: (val, key, row, index) => {
            if (row.addNew) {
                return (
                    <IconButton size="small" onClick={()=>{
                        let err = '';
                        if (!type) err += 'Please select account type.\n';
                        if (!carrier) err += 'Please select account vendor.\n';
                        if (err) {
                            this.context.alert(err);
                            return;
                        }
                        this.setState({dialog: 'add', active_account: null, active_index: null})
                    }} variant="fab" aria-label="Add" className={classes.button}>
                        <Add />
                    </IconButton>
                );
            } else {
              if (!row.carrier) return;
                let btnEdit = (
                    <IconButton size="small" onClick={()=>{this.setState({active_account: row, active_index: index, dialog: 'edit'})}} variant="fab" color="primary" aria-label="Edit" className={classes.button}>
                        <Edit />
                    </IconButton>
                );
                if (!row.type) btnEdit = null;

                return (
                  <div style={{minWidth: '150px'}}>
                    {btnEdit}
                    <IconButton size="small" onClick={()=>{
                      this.context.confirm("Are you sure you want to delete this account?", this.deleteAccount.bind(this, row.id, index));
                    }} color='secondary' variant="fab" aria-label="Delete" className={classes.button}>
                      <DeleteForever />
                    </IconButton>
                  </div>
                );
            }
        }
      }
    ];

    return (
      <CardContainer>
        <NewDataTable
          rows={rows}
          noPagination
          rowHeight='auto'
          maxHeight={500}
          columns={columns}
        />
    </CardContainer>
    );
  }
  renderDialog = () => {
    let { type, carrier, dialog, active_account } = this.state;
    if (!dialog) return null;
    active_account = active_account ? active_account : {type: type};

    if (dialog == 'add') {
      active_account.carrier = carrier;
      active_account.third_party = active_account.third_party || 'YES';
    }

    switch (active_account.type) {
      case 'Store':
        return (
          <EditStoreDialog
            account={active_account}
            handleSubmit={this.submitAccount}
            handleClose={()=>{this.setState({dialog: '', active_index: '', active_account: {}})}}
          />
        );
      case 'Carrier':
        return (
          <EditCarrierDialog
            client_view
            account={active_account}
            handleSubmit={this.submitAccount}
            handleClose={()=>{this.setState({dialog: '', active_index: '', active_account: {}})}}
          />
        );
    }
  }

  // dialog submit
  submitAccount = (new_account) => {
    // need to check required field if account is amazon or shopify
    if (this.state.dialog === 'add') {
      this.newAccount(new_account);
    } else if (this.state.dialog === 'edit') {
      this.updateAccount(new_account);
    }
  }
  // Ajax call
  newAccount = (new_account) => {
    let customer_id = this.props.customer_id;
    let err = '';

    switch (new_account.carrier) {
      case "AMAZON":
        if (!new_account.account) err += 'SellerId is required. \n';
        if (!new_account.user) err += 'AWS_ACCESS_KEY_ID is required. \n';
        if (!new_account.password) err += 'AWS_SECRET_ACCESS_KEY is required.';
        break;
      case "WOOCOMMERCE":
        if (!new_account.account) err += 'Website is required. \n';
        if (!new_account.user) err += 'Public Key is required. \n';
        if (!new_account.password) err += 'Private Key is required.';
        break;
      case "SHOPIFY":
        // if (!new_account.account) err += 'Store is required. \n';
        // if (!new_account.account) {
        //   this.context.alert('Store is required.');
        //   return;
        // } else {
        //     // for shopify we need to navigate to another page
        //     window.location.href = utils.getBaseUrl() + `/shopifyPlugin/${this.props.customer_id}/${new_account.account}`;
        //     return;
        // }
        if (!new_account.account) err += 'Account is required. \n';
        if (!new_account.token) err += 'Token is required. \n';
        break;
      default:
        if (!new_account.account) err += 'Account is required. \n';
    }
    if (new_account.third_party == 'NO') {
      err += 'To add a non-third-party carrier account, please contact customer service.';
    }

    if (err) {
      this.context.alert(err);
      return;
    }

    new_account.account = new_account.account ? new_account.account : '';
    new_account.countrycode = new_account.countrycode ? new_account.countrycode : '';
    new_account.easypost_id = new_account.easypost_id ? new_account.easypost_id : '';
    new_account.easypost_token = new_account.easypost_token ? new_account.easypost_token : '';
    new_account.password = new_account.password ? new_account.password : '';
    new_account.postcode = new_account.postcode ? new_account.postcode : '';
    new_account.third_party = new_account.third_party ? new_account.third_party : 'YES';
    new_account.token = new_account.token ? new_account.token : '';
    new_account.user = new_account.user ? new_account.user : '';

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customer`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        customer_id,
        account: new_account
      },
    });

    this.setState({loading: true});
    req.then(this.newAccountSuccess.bind(this, new_account)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newAccountSuccess = (new_account, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    new_account.id = resp.data;
    const { account, onAccountChange } = this.props;
    let new_account_list = Array.from(account);
    new_account_list.push(new_account);
    // clear input
    this.setState({carrier: '', active_account: {}, dialog: ''});
    onAccountChange(new_account_list);
  }
  deleteAccount = (account_id,index) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/customer/account/${account_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteAccountSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteAccountSuccess = (index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    
    let new_accounts = Array.from(this.props.account);
    new_accounts.splice(index, 1);
    this.props.onAccountChange(new_accounts);
  }
  updateAccount = (newAccount) => {
    let account_list = this.props.account;
    const { active_index } = this.state;
    let index = active_index;
    if (newAccount.id !== account_list[active_index].id) {
        for (let i = 0; i < account_list.length;i++) {
            if (account_list[i] === newAccount.id) {
                index = i;
                break;
            }
        }
    }

    let err = '';
    if (!newAccount.sync_inventory) newAccount.sync_inventory = '';
    switch (newAccount.carrier) {
        case "AMAZON":
            if (!newAccount.account) err += 'SellerId is required. \n';
            if (!newAccount.user) err += 'AWS_ACCESS_KEY_ID is required. \n';
            if (!newAccount.password) err += 'AWS_SECRET_ACCESS_KEY is required.';
            break;
        case "WOOCOMMERCE":
            if (!newAccount.account) err += 'Website is required. \n';
            if (!newAccount.user) err += 'Public Key is required. \n';
            if (!newAccount.password) err += 'Private Key is required.';
            break;
        case "SHOPIFY":
            if (!newAccount.account) err += 'Store is required. \n';
            break;
        default:
            if (!newAccount.account) err += 'Account is required. \n';
    }

    if (newAccount.third_party == 'NO') {
      err += 'To add a non-third-party carrier account, please contact customer service.';
    }

    if (err) {
      this.context.alert(err);
      return;
    }

    newAccount.account = newAccount.account ? newAccount.account : '';
    newAccount.countrycode = newAccount.countrycode ? newAccount.countrycode : '';
    newAccount.easypost_id = newAccount.easypost_id ? newAccount.easypost_id : '';
    newAccount.easypost_token = newAccount.easypost_token ? newAccount.easypost_token : '';
    newAccount.password = newAccount.password ? newAccount.password : '';
    newAccount.postcode = newAccount.postcode ? newAccount.postcode : '';
    newAccount.third_party = newAccount.third_party ? newAccount.third_party : 'YES';
    newAccount.token = newAccount.token ? newAccount.token : '';
    newAccount.user = newAccount.user ? newAccount.user : '';

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customerdetail`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        customer_id: this.props.customer_id,
        account: newAccount
      },
    });

    this.setState({loading: true});
    req.then(this.updateAccountSuccess.bind(this, newAccount, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateAccountSuccess = (newAccount, index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    const { account, onAccountChange } = this.props;
    let new_accounts = Array.from(account);
    new_accounts[index] = newAccount;

    onAccountChange(new_accounts);
    this.setState({active_account: {}, active_index: '', dialog: ''});
  }
}
export default withStyles(styles)(ClientAccount);
