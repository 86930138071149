import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'
import { withAlert } from '../../hoc';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class Barcode extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    alert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      type: 'product_tag',
      codes: '',
    };

    document.title = "Barcode";
  }

  render() {
    const { classes } = this.props;
    const { loading, codes, type } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container>
        {loadingBar}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.printBarcode}>
              <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="type">Template</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>{this.setState({type: e.target.value})}}
                  inputProps={{
                    name: 'type',
                    id: 'type',
                  }}
                >
                  {/*<MenuItem value='uline_4x2'>ULINE: S-11892-4x2</MenuItem>*/}
                  {/*<MenuItem value='zebra_2x1'>Zebra: 2x1</MenuItem>*/}
                  <MenuItem value='product_tag'>Product Barcode</MenuItem>
                  {
                    localStorage.getItem('customer_id') == 197 && <MenuItem value='shoe_epos_label'>Shoe Epos Label</MenuItem>
                  }
                  {
                    localStorage.getItem('customer_id') == 197 && <MenuItem value='box_epos_label'>Box Epos Label</MenuItem>
                  }
                  {
                    localStorage.getItem('customer_id') == 197 && <MenuItem value='box_epos_logo_label'>Box Epos Label with Logo</MenuItem>
                  }
                  <MenuItem value='location_tag'>Location Barcode</MenuItem>
                  <MenuItem value='order_tag'>Order Barcode</MenuItem>
                  <MenuItem value='pallet_tag'>Pallet Flag</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText='Codes(1 code/line)'
                labelProps={{shrink:true}}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  onChange: (e)=>{this.setState({codes: e.target.value})},
                  value: codes,
                  multiline: true,
                  rows: 3
                }}
              />

              <Button type='submit' className={classes.button}>Submit</Button>
            </form>
          </CardContainer>
        </GridItem>
      </Grid>
    );
  }

  printOrderBarcode = () => {
    let codes = utils.formatString(this.state.codes, {multiline: true});
    let type = this.state.type;
    if (type !== 'order_tag') return;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/barcode4by2/${codes}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.printSuccess.bind(this, true)).catch(this.printError);
  }

  printBarcode = (e) => {
    e.preventDefault();

    // let codes = this.state.codes;
    let codes = utils.formatString(this.state.codes, {multiline: true});
    let type = this.state.type;

    if (type === 'order_tag') {
      // if printing order barcode, call different API
      this.printOrderBarcode();
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/barcode`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: type,
        codes: codes,
      },
    });

    this.setState({loading: true});
    req.then(this.printSuccess.bind(this, false)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printSuccess = (same_page, resp) => {
    this.setState({loading: false});

    // alert error if any
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    if (!resp.data) {
      this.props.alert("Print barcode error");
      return;
    }

    utils.printPage(resp.data, same_page);
    window.location.reload();
  }
}
export default withAlert(withStyles(styles)(Barcode));
