import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import axios from 'axios';
import { Popover, ButtonGroup, IconButton, MenuItem, Typography, Grid, withStyles } from "@material-ui/core";
import {
  Edit, 
  AccessAlarm
} from '@material-ui/icons'
import {
  NewDataTable,
  CardContainer,
  Button,
  ReversedIconButton,
  CustomInput,
  MultiSelect,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import moment from 'moment-timezone'
import _ from 'lodash';
import NewTicketDialog from 'views/Dialogs/ZohoTicket/CreateNew'
import ZohoTicketDetail from 'views/Dialogs/ZohoTicket/CommentAttachment';
import EditZohoTicketDialog from '../Dialogs/ZohoTicket/Edit';
import CreateWorkOrderDialog from 'views/Dialogs/WorkOrder/Edit'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  buttonPushRight: {
    marginLeft: 'auto',
  },
  toolBar: {
    display: 'flex',
    paddingBottom: '.5rem',
    width: '100%'
  },
});

const statusOptions = [
  {
    value: 'Escalated',
    label: 'Escalated',
  },
  {
    value: 'Open',
    label: 'Open',
  },
  {
    value: 'Work In-Progress',
    label: 'Work In-Progress',
  },
  {
    value: 'On Hold',
    label: 'On Hold',
  },
  {
    value: 'Closed',
    label: 'Closed',
  },
];
const statusSorting = {
  'Escalated': 4,
  'Open': 3,
  'Work In-Progress': 2,
  'On Hold': 1,
  'Closed': 0,
};

class ManageZohoTicket extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.temp_data = {
      active_work_order_list: [], // the work order list of a ticket to drop down select
      active_ticket: null, // the active ticket to edit / manage
    };

    // Status Filter
    let defaultStatusFilter = {};
    for (let status of statusOptions) defaultStatusFilter[status.value] = true;
    // Category Filter
    let categoryMap = JSON.parse(localStorage.getItem('category_list') || "[]");
    let defaultCategoryFilter = {};
    for (let key of categoryMap) {
      defaultCategoryFilter[key] = true;
    }
    this.categoryMap = categoryMap;
    // Client Filter
    let defaultCustomerFilters = {};
    let customer_detail = JSON.parse(localStorage.getItem('customer_detail'));
    this.customer_list = (customer_detail && customer_detail[localStorage.getItem('warehouse_id')]) || []; 
    this.customer_list.forEach((elem)=>{defaultCustomerFilters[elem.shortname] = true;});

    this.state = {
      loading: false,
      dialog: '',
      actionsAnchor: null, // workorder dropdown
      ticketButtonAnchor: null, // ticket button, comment, edit, workorder
      displayFilters: {
        status: defaultStatusFilter,
        category: defaultCategoryFilter,
        customer: defaultCustomerFilters,
        agent: {}
      },
      ticket_list: [],
      attachments: [],
      ticket_comments: [],
      ticket_attachments: [],
      agent_list: []
    }; 

    document.title = "Zoho Ticket";
  }

  componentDidMount() {
    this.loadTickets();
  }

  // load default list
  loadTickets = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/ticket`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadTicketsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadTicketsSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.snackDisplay(resp.data.Error);
      // this.context.alert(resp.data.Error);
      return;
    }
   
    let defaultOwnerFilters = {};
    resp.data.owner.forEach((elem)=>{defaultOwnerFilters[elem.name] = true;});
    let filters = Object.assign({}, this.state.displayFilters);
    filters.agent = defaultOwnerFilters;
    if (_.isEmpty(this.state.displayFilters.agent)) {
      this.setState({ticket_list: resp.data.ticket, displayFilters: filters, agent_list: resp.data.owner});
    } else {
      this.setState({ticket_list: resp.data.ticket});
    }
  }
  // Create
  createWorkOrder = (new_work_order) => {
    new_work_order.ticket = this.temp_data.active_ticket.ticket_id;
    new_work_order.customer_id = this.temp_data.active_ticket.customer_id;

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/newworkOrder`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: new_work_order,
    });

    this.setState({loading: true});
    req.then(this.createWorkOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  createWorkOrderSuccess = (resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({dialog: ''});
    this.loadTickets();
  }
  // Load Ticket Comment and Attachment
  loadTicketComments = (activeTicket) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/ticketcomment/${activeTicket.ticket_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadTicketCommentsSuccess.bind(this, activeTicket)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadTicketCommentsSuccess = (activeTicket, resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({ticketButtonAnchor: null});
    this.temp_data.active_ticket = activeTicket;
    this.setState({ticket_comments: resp.data.comment || [], ticket_attachments: resp.data.file || [],  dialog: 'ticketDetail'});
  }

  applyFilter = (newVal, key) => {
    let newDisplayFilter = Object.assign({}, this.state.displayFilters);
    newDisplayFilter[key] = newVal;
    this.setState({displayFilters: newDisplayFilter});
  }
  getFilteredRows = (displayFilters) => {
    if (!displayFilters) displayFilters = this.state.displayFilters;
    let ticket_list = this.state.ticket_list || [];
    return ticket_list.filter((elem)=>{
      let result = true;
      for (let filterType in displayFilters) {
        // if (displayFilters[filterType][elem[filterType]] !== true) {
        if (displayFilters[filterType][elem[filterType]] === false) {
          result = false;
          break;
        }
      }
      return result;
    });
  }

  renderTable = () => {
    const {classes} = this.props;
    const {displayFilters, actionsAnchor, ticketButtonAnchor} = this.state;
    let rows = this.getFilteredRows();

    // filter options
    // Status Filter
    let statusOptions = [];
    for (let filterKey in displayFilters['status']) {
      let option = statusOptions[filterKey]; 
      statusOptions.push({selected: displayFilters['status'][filterKey], value: filterKey, label: filterKey});
    }
    // Category Filter
    let categoryOptions = [];
    for (let filterKey in displayFilters['category']) {
      categoryOptions.push({selected: displayFilters['category'][filterKey], value: filterKey, label: filterKey});
    }
    // Customer Filter
    let customerOptions = [];
    this.customer_list.forEach((elem)=>{customerOptions.push({
      selected: displayFilters['customer'][elem.shortname], 
      value: elem.shortname, 
      label: elem.shortname
    });});
    // Owner Filter
    let ownerOptions = [];
    this.state.agent_list.forEach((elem)=>{ownerOptions.push({
      selected: displayFilters['agent'][elem.name], 
      value: elem.name, 
      label: elem.name
    });});

    let columns = [
      {
        key: 'ticket',
        label: 'Ticket #',
        searchable: true,
        sortable: true,
        render: (val, key, rowData) => {
          return <Button onClick={(e)=>{
            // this.loadTicketComments(rowData);
            this.temp_data.active_ticket = rowData;
            this.setState({ticketButtonAnchor: this.state.ticketButtonAnchor ? null : e.currentTarget});
          }}>{val}</Button>;
        },
      }, {
        key: 'subject',
        label: 'Subject',
        searchable: true,
        sortable: true
      }, {
        key: 'agent',
        label: 'Owner',
        contentNoWrap: true
      }, {
        key: 'category',
        label: 'Category',
      }, {
        key: 'status',
        label: 'Status',
        contentNoWrap: true,
        sortable: true,
        sortingFunction: (key, order, a, b)=>{
          let a_val = a[key] ? statusSorting[a[key]] : 99;
          let b_val = b[key] ? statusSorting[b[key]] : 99;
          if (order == 'desc') return (b_val < a_val ? -1 : 1);
          else return (a_val < b_val ? -1 : 1);
        }
      }, {
        key: 'customer',
        label: 'Customer',
        contentNoWrap: true
      }, {
        key: 'created_dt',
        label: 'Created Date',
        contentNoWrap: true,
        sortable: true,
        render: utils.localizeDate
      }, {
        key: 'update_time',
        label: 'Modified Date',
        contentNoWrap: true,
        sortable: true,
        render: utils.localizeDate
      }, {
        key: 'work_order',
        label: 'Work Order',
        searchable: true,
        render: (val, key, rowData) => {
          let work_order_list = val.split(',').filter((elem)=>utils.formatString(elem));

          if (!val) return null;
          if (work_order_list.length == 1) return (
          <Button onClick={()=>{
            localStorage.setItem("ticketWorkOrder", val);
            localStorage.setItem("ticketWorkOrderCustomer", rowData.customer_id);
            this.props.history.push("/workOrder");
          }}>{val}</Button>
          );

          return (
            <Button className={classes.button} onClick={(e)=>{
              this.temp_data.active_work_order_list = work_order_list;
              this.temp_data.active_ticket = rowData;
              this.setState({actionsAnchor: this.state.actionsAnchor ? null : e.currentTarget});}}>
              {work_order_list.length} Work Orders
            </Button>
          );  
        },
      }, 
      // {
      //   key: 'action',
      //   label: 'Edit',
      //   render: (val, key, rowData, index) => {
      //     return (
      //     <div style={{width: '100px'}}>
      //       <ReversedIconButton color="success" size="small" onClick={()=>{
      //         this.temp_data.active_ticket = rowData;
      //         this.setState({dialog: 'editTicket'});
      //       }} variant="fab" style={{marginLeft: ".5rem", backgrondColor: 'black'}} className={classes.button}>
      //           <Edit style={{color: 'white'}}/>
      //       </ReversedIconButton>

      //       <ReversedIconButton color="primary" size="small" onClick={()=>{
      //         this.temp_data.active_ticket = rowData;
      //         this.setState({dialog: 'addWorkorder'});
      //       }} variant="fab" style={{marginLeft: ".5rem"}} className={classes.button}>
      //           <AccessAlarm style={{color: 'white'}}/>
      //       </ReversedIconButton>
      //     </div>);
      //   },
      // }
    ];
    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (ticket, displayProps) => {
        let color = ''; // default to white
        switch (ticket.status) {
          case 'Open':
            color = '#F79FAA';
            break;
          case 'On Hold':
            color = '#057ED1';
            break;
          case 'Escalated':
            color = '#EA271A';
            break;
          case 'Work In-Progress':
            color = '#F9F60C';
            break;
          case 'Closed':
            color = '#1AEA37';
            break;
        }
        if (color) {
          displayProps.style = {backgroundColor: color};
        }
        return displayProps;
      }
    };

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <Typography variant="h6" style={{marginBottom: '.5rem'}}>
            Zoho Tickets
            <Button style={{marginLeft: '1rem'}} onClick={()=>this.setState({dialog: 'newTicket'})}>Create New</Button>
          </Typography>
      
          <ButtonGroup>
            <MultiSelect
              title="Status"
              listItems={statusOptions}
              handleSubmit={(val)=>{this.applyFilter(val, 'status')}}
            />
            <MultiSelect
              title="Category"
              listItems={categoryOptions}
              handleSubmit={(val)=>{this.applyFilter(val, 'category')}}
            />
            <MultiSelect
              title="Customer"
              listItems={customerOptions}
              handleSubmit={(val)=>{this.applyFilter(val, 'customer')}}
            />
            {
              this.state.agent_list.length > 0 && <MultiSelect
                title="Owner"
                listItems={ownerOptions}
                handleSubmit={(val)=>{this.applyFilter(val, 'agent')}}
              />
            }
          </ButtonGroup>

          <br/>

          {/* Comment, Edit, Workorder button dropdown */}
          <Popover
            open={Boolean(ticketButtonAnchor)}
            anchorEl={ticketButtonAnchor}
            onClose={()=>this.setState({ticketButtonAnchor: null})}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
          }}> 
            <MenuItem onClick={()=>{this.loadTicketComments(this.temp_data.active_ticket);}}>Load Comments</MenuItem>
            <MenuItem onClick={()=>{this.setState({ticketButtonAnchor: null, dialog: 'editTicket'});}}>Edit Ticket</MenuItem>
            <MenuItem onClick={()=>{this.setState({ticketButtonAnchor: null, dialog: 'addWorkorder'});}}>Create Workorder</MenuItem>
          </Popover>

          {/* Workorder number dropdown */}
          <Popover
            open={Boolean(actionsAnchor)}
            anchorEl={actionsAnchor}
            onClose={()=>this.setState({actionsAnchor: null})}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
          }}
          >
            {this.temp_data.active_work_order_list.map((elem, index)=>{
              return (
                <MenuItem key={index} onClick={()=>{
                  localStorage.setItem("ticketWorkOrder", elem);
                  localStorage.setItem("ticketWorkOrderCustomer", this.temp_data.active_ticket.customer_id);
                  this.props.history.push("/workOrder");
                }}>{elem}</MenuItem>
              );
            })}
          </Popover>
          
          <NewDataTable
            rows={rows}
            rowHeight={'auto'} 
            columns={columns}
            exportTable={(data)=>{
              utils.exportArrayToCsv(data, [
                {
                  key: 'ticket',
                  label: 'Ticket #',
                }, {
                  key: 'subject',
                  label: 'Subject',
                }, {
                  key: 'agent',
                  label: 'Owner',
                }, {
                  key: 'category',
                  label: 'Category',
                }, {
                  key: 'status',
                  label: 'Status',
                }, {
                  key: 'customer',
                  label: 'Customer',
                }, {
                  key: 'created_dt',
                  label: 'Created Date',
                  render: utils.localizeDate
                }, {
                  key: 'update_time',
                  label: 'Modified Date',
                  render: utils.localizeDate
                }, {
                  key: 'work_order',
                  label: 'Work Order',
                  render: (val)=>{
                    if (!val) val = '';
                    let formattedVal = val.split(',').join(' | ');
                    return formattedVal;
                  }
                }, 
              ]);
            }}
            searchable
            rowSettings={rowSettings}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderDialog = () => {
    switch (this.state.dialog) {
      case 'newTicket':
        return (
          <NewTicketDialog
            handleClose={()=>{this.setState({dialog: ''})}}
            handleSubmit={()=>{
              this.setState({dialog: ''});
              this.loadTickets();
            }}
            agent_list={this.state.agent_list}
            ticket={{}}
          />
        );
      case 'editTicket': 
        return (
          <EditZohoTicketDialog
            handleClose={()=>{this.setState({dialog: ''})}}
            handleSubmit={()=>{
              this.setState({dialog: ''});
              this.loadTickets();
            }}
            agent_list={this.state.agent_list}
            status_list={statusOptions}
            ticket={this.temp_data.active_ticket}
          />
        );
      case 'addWorkorder':
        return (
          <CreateWorkOrderDialog
            handleClose={()=>{this.setState({dialog: ''})}}
            handleSubmit={this.createWorkOrder}
          />
        );
      case 'ticketDetail':
        return (
          <ZohoTicketDetail
            handleClose={()=>{this.setState({dialog: ''})}}
            handleSubmit={()=>{this.setState({dialog: ''})}}
            title={`${this.temp_data.active_ticket.ticket}: ${this.temp_data.active_ticket.subject}`}
            ticket={this.temp_data.active_ticket}
            comments={this.state.ticket_comments}
            attachments={this.state.ticket_attachments} 
          />
        );
    }
  }

  render() {
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
              
        {this.renderDialog()}
        {this.renderTable()}
      </Grid>
    );
  }
}
export default withRouter(withStyles(styles)(ManageZohoTicket));
