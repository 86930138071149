import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, FormGroup, FormControlLabel, Checkbox, Tooltip, InputLabel, Select, FormControl, MenuItem, IconButton, withStyles } from "@material-ui/core";
import {
  Error,
  Edit,
} from '@material-ui/icons';
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class MovementMultiple extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // scan input ref
    this.input_refs = {
      prev_loc: null,
      sku: null,
      target_loc: null,
    };
    // verified data 
    this.temp_data = {
      // previous location info
      prev_location_id: '',
      prev_location_name: '',
      prev_table_keys: [],
      skus: {},
      upcs: {},
      // target location info
      target_location_id: '',
      target_location_name: '',
    };

    this.state = {
      loading: false,
      prev_items: null,
      target_items: [],
      // lock input settings
      lock_prev: false,
      lock_target: false,
    };

    document.title = "Put-away Multiple";
  }

  onPrevLocationChange = () => {
    let location = utils.formatString(this.input_refs.prev_loc.value);
    if (!location || location.length < 3) return;
    this.resetInputs('prev_loc');
    this.checkLocation('prev_loc', location);    
  }
  handlePrevScan = _.debounce(this.onPrevLocationChange, 150);
  matchSku = () => {
    let scanned_val = utils.formatString(this.input_refs.sku.value);
    if (!scanned_val) return;
    // match sku
    scanned_val = scanned_val.toUpperCase();

    let matched_id = '';
    if (this.temp_data.skus[scanned_val]) {
      matched_id = this.temp_data.skus[scanned_val]; // sku matched
    } else if (this.temp_data.upcs[scanned_val]) {
      matched_id = this.temp_data.upcs[scanned_val]; // upc matched
    } else {
      this.context.alert(`Scanned SKU or UPC doesn't match any Item on this Location!`);
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }
      return;
    }

    let items = Object.assign({}, this.state.prev_items);
    let newItem = Object.assign({}, items[matched_id]);
    let oldQty = parseInt(newItem.quantity_input) || 0;
    newItem.quantity_input = 1 + oldQty;

    let available_qty = newItem.quantity ? parseInt(newItem.quantity) : 0;
    available_qty -= newItem.allocated_quantity ? parseInt(newItem.allocated_quantity) : 0;
    if (newItem.quantity_input > available_qty) {
      this.context.alert("Only " + available_qty + " available for movement.");
      return;
    }
    items[matched_id] = newItem;
    // Move the scanned row to top
    let newKeys = [];
    newKeys.push(matched_id);
    for (let id of this.temp_data.prev_table_keys) {
      if (id != matched_id) newKeys.push(id);
    }
    this.temp_data.prev_table_keys = newKeys;
    this.setState({prev_items: items});

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
  }
  handleSKUScan = _.debounce(this.matchSku, 150)
  onTargetLocationChange = () =>{
    let location = utils.formatString(this.input_refs.target_loc.value);
    if (!location || location.length < 3) return;
    this.resetInputs('target_loc');
    this.checkLocation('target_loc', location);   
  }
  handleTargetScan = _.debounce(this.onTargetLocationChange, 150)
  resetInputs = (inputName) => {
    switch (inputName) {
      case 'prev_loc':
        this.temp_data.prev_location_id = '';
        this.temp_data.prev_location_name = '';
        this.temp_data.skus = {};
        this.temp_data.upcs = {};
        this.temp_data.prev_table_keys = [];
        this.setState({prev_items: null});
        break;
      case 'target_loc':
        this.temp_data.target_location_id = '';
        this.temp_data.target_location_name = '';
        this.setState({target_items: null});
    }
  }

  // API call
  // load default inventory
  checkLocation = (check_type, location) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/movementList/${location}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.checkLocationSuccess.bind(this, check_type)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  checkLocationSuccess = (check_type, resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      if (check_type === 'prev_loc' && this.input_refs.prev_loc) {
        this.input_refs.prev_loc.value = '';
        this.input_refs.prev_loc.focus();
      }
      if (check_type === 'target_loc' && this.input_refs.target_loc) {
        this.input_refs.target_loc.value = '';
        this.input_refs.target_loc.focus();
      }
      return;
    }
    if (!data || data.length === 0) {
    this.context.alert("No data found");
      return;
    }

    if (check_type === 'prev_loc') {
      this.temp_data.prev_location_id = data.location_id;
      this.temp_data.prev_location_name = data.location_name;

      let items = {};
      let skus = {};
      let upcs = {};
      let keys = [];

      for (let item of data.item_list) {
        item.quantity_input = 0;
        let id = item.item_id;
        let sku = item.sku ? ('' + item.sku).toUpperCase() : "";
        let upc = item.upc ? ('' + item.upc).toUpperCase() : "";
        if (!skus[sku] && sku) {
            skus[sku] = id;
        }
        if (!upcs[upc] && upc) {
            upcs[upc] = id;
        }
        items[id] = item;
        keys.push(id);
      }
      this.temp_data.skus = skus;
      this.temp_data.upcs = upcs;
      this.temp_data.prev_table_keys = keys;
      this.setState({prev_items: items});
      // this.input_refs.target_loc.focus();
    } else {
      this.temp_data.target_location_id = data.location_id;
      this.temp_data.target_location_name = data.location_name;
      this.setState({target_items: data.item_list || []});
    }
  }
  submitValidate = (e) => {
    e.preventDefault();
    // Check customer id and local storage customer id
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }

    const { prev_items } = this.state;
    const {
      prev_location_id, prev_location_name,
      target_location_id, target_location_name,
      prev_table_keys
    } = this.temp_data;
    
    let err = '';
    if (!prev_location_id)  err += "Previous location id can't be empty. Please press enter to verify it.\n";
    if (!target_location_id)  err += "Target location id can't be empty. Please press enter to verify it.\n";
    if (prev_location_id === target_location_id) err += "Previous location and Target location are the same. Please change one of them.\n";

    let moved_items = [];
    for (let item_id of prev_table_keys) {
      let item = prev_items[item_id];
      if (item && !!item.quantity_input && parseInt(item.quantity_input) > 0) {
        moved_items.push(item);
      }
    }
    if (moved_items.length === 0) err += "No items to be moved, please change the Scanned Quantity.\n";

    if (err) {
      this.context.alert(err);
    } else {
      this.submitMovementOnce({
        previous_location_id: prev_location_id,
        previous_location:  prev_location_name,
        target_location_id: target_location_id,
        target_location: target_location_name,
        items: moved_items,
        notes: '', // we don't have notes input for now
      });
    }
  }
  submitMovement = (data) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/movementList`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data
    });

    this.setState({loading: true});
    req.then(this.submitMovementSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  resetSubmitOnce = () => {this.submitMovementOnce = _.once(this.submitMovement);} // reset the submit picking once after the ajax call returns
  submitMovementOnce = _.once(this.submitMovement);
  submitMovementSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    this.context.snackDisplay('Submit movment success.');

    const { lock_prev, lock_target } = this.state;
    if (lock_prev) {
      // lock previous location, update it after submit
      if (!data.previous_location || data.previous_location.length === 0) {
        // if all products are moved after submit, alert and clear
        this.resetInputs('prev_loc');
        if (this.input_refs.prev_loc) {
          this.input_refs.prev_loc.value = '';
          this.input_refs.prev_loc.focus();
        }
        this.context.alert('All items on previous location for this customer are moved, please scan another one.');
      } else {
        // previous location still have items, update after submit
        let items = {};
        let skus = {};
        let upcs = {};
        let keys = [];
        for (let item of data.previous_location) {
          item.quantity_input = 0;
          let id = item.item_id;
          let sku = item.sku ? ('' + item.sku).toUpperCase() : "";
          let upc = item.upc ? ('' + item.upc).toUpperCase() : "";
          if (!skus[sku] && sku) {
              skus[sku] = id;
          }
          if (!upcs[upc] && upc) {
              upcs[upc] = id;
          }
          items[id] = item;
          keys.push(id);
        }
        this.temp_data.skus = skus;
        this.temp_data.upcs = upcs;
        this.temp_data.prev_table_keys = keys;
        this.setState({prev_items: items});
      }
    } else {
      // don't lock previous locaiton, clear
      this.resetInputs('prev_loc');
      if (this.input_refs.prev_loc) {
        this.input_refs.prev_loc.value = '';
        this.input_refs.prev_loc.focus();
      }
    }
    if (lock_target) {
      // lock target location, update it after submit
      this.setState({target_items: data.target_location || []});
    } else {
       // don't lock target locaiton, clear
       this.resetInputs('target_loc');
       if (this.input_refs.target_loc) {
        this.input_refs.target_loc.value = '';
      }
    }
  }

  renderPrevTable  = () => {
    const { classes } = this.props;
    const { prev_items } = this.state;
    const { prev_location_id, prev_location_name } = this.temp_data;
    if (!prev_items || !prev_location_id) return;

    let rows = [];
    let location_name = prev_location_name;
    for (let item_id of this.temp_data.prev_table_keys) {
      if (prev_items[item_id]) rows.push(prev_items[item_id]);
      if (!location_name) location_name = prev_items[item_id]['location'];
    }
    
    return (
      <CardContainer>
        <div className={classes.title}>
          <Typography variant="h6" style={{marginBottom: '.5rem'}}>
            {`Previous Location: ${location_name}`}
          </Typography>
        </div>

        <CustomInput
          labelText='SKU'
          formControlProps={{
            fullWidth: false,
            required: true,
            className: this.props.classes.customInput,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            defaultValue: '',
            onKeyUp: this.handleSKUScan,
            inputRef: (elem)=> {this.input_refs.sku = elem},
          }}
        />

        <NewDataTable
          rows={rows}
          columns={[
            {
              key: 'sku',
              label: 'SKU',
              contentNoWrap: true,
            },
            {
              key: 'upc',
              label: 'UPC',
              contentNoWrap: true,
            },
            {
              key: 'quantity',
              label: 'Quantity',
            },
            {
              key: 'allocated_quantity',
              label: 'Allocated',
            },
            {
              key: 'quantity_input',
              label: 'Scanned',
              render: (val, key, row, index) => {
                return (
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: this.props.classes.customInput
                    }}
                    inputProps={{
                      onChange: (e)=>{
                          let new_val = e.target.value;
                          let new_items = Object.assign({},this.state.prev_items);
                          let new_item = new_items[row.item_id];
                          // use location qty - allocated to get movable qty
                          let available_qty = new_item.quantity ? parseInt(new_item.quantity) : 0;
                          available_qty -= new_item.allocated_quantity ? parseInt(new_item.allocated_quantity) : 0;
                          // check if scanned qty is very large
                          if (new_val > 10000) {
                            this.context.confirm('You just entered a very large quantity, are you sure this is correct?', ()=>{
                              if (new_val < 0) {
                                this.context.alert('The quantity can not be negative!');
                                return;
                              }
                              if (new_val > available_qty) {
                                  this.context.alert(`Only ${available_qty} available for movement.`);
                                  return;
                              }
                              new_item.quantity_input = new_val;
                              this.setState({prev_items: new_items});
                            });
                          }

                          if (new_val < 0) {
                            this.context.alert('The quantity can not be negative!');
                            return;
                          }
                          if (new_val > available_qty) {
                            this.context.alert(`Only ${available_qty} available for movement.`);
                            return;
                          }
                          new_item.quantity_input = new_val;
                          this.setState({prev_items: new_items}); 
                      },
                      value: row.quantity_input,
                      type: 'number',
                      inputProps: {
                        min: 0,
                        step: 1,
                      }
                    }}
                  />
                );
              }
            },
          ]}
          noPagination
        />
      </CardContainer>
    );
  }
  renderTargetTable = () => {
    const { classes } = this.props;
    const { target_items } = this.state;
    const { target_location_id, target_location_name } = this.temp_data;

    if (!target_location_id || !target_location_name) return;

    let location_name = "";
    if (this.temp_data.target_location_name) location_name = this.temp_data.target_location_name;

    return (
      <CardContainer>
        <div className={this.props.classes.root}>
          <div className={classes.title}>
            <Typography variant="h6">
              Target Location: {location_name}
            </Typography>
          </div>
          <NewDataTable
            rows={target_items || []}
            columns={[
              {
                key: 'sku',
                label: 'SKU',
                contentNoWrap: true,
              },
              {
                key: 'upc',
                label: 'UPC',
                contentNoWrap: true,
              },
              {
                key: 'quantity',
                label: 'Quantity',
              },
            ]}
            noPagination
          />
        </div>
      </CardContainer>
    );
  }
  render() {
    const { classes } = this.props;
    const { lock_prev, lock_target } = this.state;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        <GridItem xs={12} sm={8}>
          <CardContainer>
            <form onSubmit={this.submitValidate}>      
              <CustomInput
                labelText='Previous Location'
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  onKeyUp: (e)=>{
                    e.preventDefault();
                    this.handlePrevScan();
                  },
                  defaultValue: '',
                  inputRef: elem => this.input_refs.prev_loc = elem
                }}
              />

              <CustomInput
                  labelText='Target Location'
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    onKeyUp: (e)=>{
                      e.preventDefault();
                      this.handleTargetScan();
                    },
                    defaultValue: '',
                    inputRef: elem => this.input_refs.target_loc = elem
                  }}
                />

              <GridItem xs={12} sm={12} md={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lock_prev}
                        onChange={(e)=>{this.setState({lock_prev: e.target.checked});}}
                        color='primary'
                      />
                    }
                    label='Lock Previous Location'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lock_target}
                        onChange={(e)=>{this.setState({lock_target: e.target.checked});}}
                        color='primary'
                      />
                    }
                    label='Lock Previous Location'
                  />
                </FormGroup>
              </GridItem>

              <Button type='submit' className={classes.button}>Submit</Button>
            </form>
          </CardContainer>
        </GridItem>

        <GridItem xs={12} md={7}>
          {this.renderPrevTable()}
        </GridItem>

        <GridItem xs={12} md={5}>
          {this.renderTargetTable()}
        </GridItem>
      </Grid>
    );
  }
}
export default withStyles(styles)(MovementMultiple);

