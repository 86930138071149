import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'
import {withStyles, TableSortLabel, TableHead, Tooltip, TableRow, TableCell, Checkbox } from '@material-ui/core'

export default class EnhancedTableHead extends React.Component {
    static propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.string.isRequired,
        orderBy: PropTypes.string.isRequired,
        colSettings: PropTypes.array.isRequired,
        selectSettings: PropTypes.object,
    };

    static defaultProps = {
        selectSettings: {}
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { colSettings, order, orderBy, selectSettings } = this.props;
        const { handleSelectAll, numSelected, rowCount } = selectSettings;

        let btnSelectAll = null;
        if (_.isFunction(selectSettings.handleSelectAll)) {
            btnSelectAll = (
                <TableCell style={{width: "50px"}} padding="checkbox">
                    <Checkbox
                        indeterminate={selectSettings.numSelected > 0 && numSelected < rowCount}
                        checked={(numSelected === rowCount) && rowCount > 0}
                        onChange={handleSelectAll}
                        disabled={rowCount === 0}
                    />
                </TableCell>
            );
        }

        return (
            <TableHead>
                <TableRow>
                    {btnSelectAll}
                    {colSettings.map(column => {
                        let lbl = column.label;
                        if (column.sortable) {
                            lbl = (
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.key}
                                        direction={order}
                                        onClick={this.createSortHandler(column.key)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </Tooltip>
                            );
                        }

                        return (
                            <TableCell
                                key={column.key}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'normal'}
                                sortDirection={orderBy === column.key ? order : false}
                                style={column.header_style ? column.header_style : {}}
                                className={column.header_class ? column.header_class : ''}
                            >
                                {lbl}
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}
