// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import $ from 'jquery'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Grid,
    withStyles, MenuItem, Select
} from "@material-ui/core";
// BXZ render modules
import {
    NewDataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import moment from "moment-timezone";
import DataContext from 'context/Data'
// Our view components

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
     selectInput: {
        width: '100%',
        margin: '1rem 0'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    button: {
        marginTop: theme.spacing(2)
    }
});

class ImportOrder extends Component {
    static contextType = DataContext

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.file_input = null;

        this.state = {
            loading: false,
            errors: [],
        };

        document.title = "Import Order";
    }

    render() {
        const { classes } = this.props;
        const { loading, errors } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let errorsTable = null;
        if (errors && errors.length > 0) {
            let rows = errors.map((error, index)=>{
                return (
                    <TableRow hover key={index}><TableCell>
                        {error}
                    </TableCell></TableRow>
                );
            });
            errorsTable = (
                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{height: '3rem'}}>
                                <Typography variant="h6">
                                    Result In the File You Uploaded:
                                </Typography>
                            </div>

                            <Table className='with-border'>
                                <TableBody>
                                    {rows}
                                </TableBody>
                            </Table>

                        </div>
                    </CardContainer>
                </GridItem>
            );
        }

        return (
            <Grid container spacing={2}>
                {loadingBar}

                {errorsTable}
                <GridItem xs={12}>
                    <CardContainer noPadding>
                        <div className={classes.root}>
                            <div className={classes.title} style={{padding: "1rem", paddingBottom: ".5rem",}}>
                                <Typography variant="h6">
                                    Order Format Instruction:
                                </Typography>
                            </div>

                            <div style={{padding: "0 1rem", fontSize: "1.25rem", marginTop: ".5rem"}}> The yellow highlight field is required. If your order has multiple line items, please put each sku into separate line with same order id. If you want to use your own carrier account please put into Carrier Account field. <br/>Please use the method code from method table.<br/> The Vendor field is used for DropShip Vendor. Currntly supported the following values: DSCO-Nordstrom, NEIMAN, SAKS, DILLARDS, The Good Stuff, maisonette, RGG. (If adding any new vendors, please confirm with IT for the Vendor Code)</div>

                            <Grid container spacing={2}>
                              <GridItem xs={12} sm={12} md={6}>
                                  {this.renderMethodTable1()}
                              </GridItem>

                              <GridItem xs={12} sm={12} md={6}>
                                  {this.renderMethodTable2()}
                              </GridItem>
                            </Grid>  
                        </div>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{height: '3rem'}}>
                                <Typography variant="h6">
                                    Sample Table:

                                    <Button style={{float: 'right'}} onClick={()=>{
                                        let file_name = "Order_SAMPLE";

                                        let customer = localStorage.getItem('customer_id');
                                        let warehouse = localStorage.getItem('warehouse_id');

                                        file_name += '_' + warehouse + '_' + customer + '_';

                                        let now = moment();
                                        file_name += now.format('YYYY_MM_DD');
                                        utils.exportTableToCsv('table-export', file_name, true);
                                    }}>Download Order CSV Template</Button>
                                </Typography>
                            </div>

                            {this.renderSampleTable()}
                        </div>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title}>
                                <Typography variant="h6">
                                    Order Upload Instruction:
                                </Typography>
                            </div>
                            <div>
                                <form
                                  // style={{width: 'calc(100% + 16px)', margin: '-8px'}}
                                  onSubmit={this.submit}
                                  className={classes.root}
                                  ref={(elem)=>{this.input_form = elem}}
                                >

                                    <div style={{fontSize: "1.25rem", margin: ".5rem 0"}}>Use the file input below to upload the Order file you created and click "Upload Order" button to upload it. Error messages will appear on the screen if there are errors in the file you uploaded</div>
                                    <CustomInput
                                      labelText='Upload Order'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          type: 'file',
                                          name: 'order',
                                          inputRef: (elem)=>{this.file_input = elem}
                                      }}
                                    />
                                    <Button className={classes.button} type='submit'>Upload Order</Button>
                                </form>
                            </div>
                        </div>
                    </CardContainer>
                </GridItem>

            </Grid>
        );
    }

    // api call
    submit = (e) => {
      e.preventDefault();

      if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
        this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
        return;
      }

      // todo decide whether to add confirmation
      // let msg = "Are you sure to import Order for Warehouse: "+ warehouse_name +", Customer: "+ customer_name +"?";
      // let cfm = window.confirm(msg);
      // if (!cfm) return;

      let form_data = new FormData(this.input_form);
      let req = axios({
          method: 'post',
          dataType: 'json',
          processData: false,
          contentType: false,
          cache: false,
          url: `${utils.getBaseUrl('customer')}/import/order`,
          headers: {
            token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
          },
          data: form_data,
      });

      this.setState({loading: true, errors: []});
      req.then(this.submitSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: ()=>{this.file_input.value= ''}}));
    }
    submitSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;

        if (resp.Error) {
          // display error table and scroll to top
          this.file_input.value= '';
          this.setState({errors: resp.Error});
          utils.scrollToTop();
          //this.context.alert(resp.Error);
          return;
        }
    }

    // render func
    renderSampleTable = () => {
        let colSettings = [
            {
                key: 'order_id',
                label: 'OrderID',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'firstname',
                label: 'FirstName',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'lastname',
                label: 'LastName',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'company',
                label: 'Company',
                contentNoWrap: true,
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'address_line1',
                label: 'Address1',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                contentNoWrap: true,
                header_class: 'yellow'
            },
            {
                key: 'address_line2',
                label: 'Address2',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'city',
                label: 'City',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'province',
                label: 'State',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'postal_code',
                label: 'Zipcode',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'country',
                label: 'Country',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'email',
                label: 'Email',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'phone',
                label: 'PhoneNumber',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'method',
                label: 'Method',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'carrier',
                label: 'CarrierAccount',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'sku',
                label: 'SKU',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'quantity',
                label: 'Quantity',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'Vendor',
                label: 'Vendor',
                render: utils.tableCellOnDisplay,
            },
            // {
            //     key: 'custom_description',
            //     label: 'CustomsDescription',
            //     render: utils.tableCellOnDisplay,
            // },
            {
                key: 'slip_note',
                label: 'SlipNote',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'order_type',
                label: 'OrderType',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'start_date',
                label: 'StartDate',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'cancel_date',
                label: 'CancelDate',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'ship_date',
                label: 'ShipDate',
                render: utils.tableCellOnDisplay,
            },
        ];

        let rows = [
            {
                order_id: 'ABCFDD',
                firstname: 'Cary',
                lastname: 'Lee',
                company: '',
                address_line1: '123 Testing Road',
                address_line2: 'APT 201',
                city: 'Secaucus',
                province: 'NJ',
                postal_code: '07094',
                country: 'US',
                email: 'asdc@gmail.com',
                phone: '55555555555',
                method: 'BXZ.USA.1',
                carrier: '',
                sku: '12345',
                quantity: '5',
                custom_description: '',
                slip_note: '',
                order_type: 'retail',
                Vendor: '',
            },
            {
                order_id: 'Order12345',
                firstname: 'Cary',
                lastname: 'Lee',
                company: '',
                address_line1: '123 Testing Road',
                address_line2: 'APT 201',
                city: 'Secaucus',
                province: 'NJ',
                postal_code: '07094',
                country: 'US',
                email: 'asdc@gmail.com',
                phone: '55555555555',
                method: 'BXZ.USA.2',
                carrier: '',
                sku: '450982304',
                quantity: '5',
                custom_description: '',
                slip_note: '',
                order_type: 'dropship',
                start_date: "01/25/2021",
                cancel_date: "01/30/2021",
                ship_date: "01/25/2021",
                Vendor: 'SAKS',
            },
            {
                order_id: 'A1245557',
                firstname: 'Cary',
                lastname: 'Lee',
                company: 'ABC Company',
                address_line1: '123 Testing Road',
                address_line2: 'APT 201',
                city: 'Secaucus',
                province: 'NJ',
                postal_code: '07094',
                country: 'US',
                email: 'asdc@gmail.com',
                phone: '55555555555',
                method: 'BXZ.USA.3',
                carrier: '',
                sku: '928482093',
                quantity: '200',
                custom_description: '',
                slip_note: '',
                order_type: 'wholesale',
                start_date: "01/25/2021",
                cancel_date: "01/30/2021",
                ship_date: "01/25/2021",
                Vendor: '',
            },
            {
                order_id: 'NU123FASD',
                firstname: 'Cary',
                lastname: 'Lee',
                company: '',
                address_line1: '123 Testing Road',
                address_line2: 'APT 201',
                city: 'Secaucus',
                province: 'NJ',
                postal_code: '07094',
                country: 'US',
                email: 'asdc@gmail.com',
                phone: '55555555555',
                method: 'BXZ.USA.5',
                carrier: '512398123',
                sku: '123123',
                quantity: '100',
                custom_description: '',
                slip_note: '',
                order_type: 'wholesale',
                Vendor: 'RGG',
            },
        ];

        return (
          <NewDataTable
            rows={rows}
            id="table-export"
            noPagination
            columns={colSettings}
          />
        );
    }
    renderMethodTable1 = () => {
        let rows = [
            {
                method: 'BXZ.PKP',
                description : "Pack and Hold"
            },
            {
                method: 'BXZ.USA.1',
                description : "Boxzooka 1-Day"
            },
            {
                method: 'BXZ.USA.2',
                description : "Boxzooka 2-Day"
            },
            {
                method: 'BXZ.USA.3',
                description : "Boxzooka 3-Day"
            },
            {
                method: 'BXZ.USA.5',
                description : "Boxzooka 5-Day"
            },
            {
                method: 'BXZ.USA.7',
                description : "Boxzooka 7-Day"
            },
            {
                method: 'UPS.EXP.1',
                description : "UPS Next Day Air Early(Next Business Day by 10AM)"
            },
            {
                method: 'UPS.DOM.1',
                description : "UPS Next Day Air(Next Business Day by 2pm)"
            },
            {
                method: 'UPS.DOM.2',
                description : "UPS Second Day Air(Second Business Day by 2pm)"
            },
            {
                method: 'UPS.DOM.3',
                description : "UPS 3-Day Air (Third Business Day by 2pm)"
            },
            {
                method: 'UPS.GRD.RESI',
                description : "UPS Ground (1 - 5 Business Days)"
            },
            {
                method: 'SUREPOST',
                description : "UPS Surepost"
            },
            {
                method: 'USPS.PRIORITY' ,
                description : "USPS Priority"
            },
            {
                method: 'USPS.PARCEL' ,
                description : "USPS ParcelSelect"
            },
            {
                method: 'USPS.FIRST' ,
                description : "USPS First"
            },
            {
                method: 'USPS.EXPRESS' ,
                description : "USPS Express"
            },
        ];

        return (
          <NewDataTable
            rows={rows}
            tableTitle="Shipping Method Map Table"
            noPagination
            rowHeight="auto"
            columns={[
                {
                    key: 'method',
                    label: 'Method (Use method from this column when uploading your file)',
                    header_style: {
                        whiteSpace: 'normal'
                    }
                },
                {
                    key: 'description',
                    label: 'Description',
                    render: utils.tableCellOnDisplay,
                },
            ]}
          />
        );
    }
    renderMethodTable2 = () => {
        let rows = [
            {
                method: 'FDX.EXP.1',
                description : "FedEx Next Day Air Early(Next Business Day by 10AM)"
            },
            {
                method: 'FDX.DOM.1',
                description : "FedEx Next Day Air(Next Business Day by 2pm)"
            },
            {
                method: 'FDX.DOM.2',
                description : "FedEx Second Day Air(Second Business Day by 2pm)"
            },
            {
                method: 'FDX.DOM.3',
                description : "FedEx 3-Day Air (Third Business Day by 2pm)"
            },
            {
                method: 'FDX.GRD',
                description : "FedEx Ground (1 - 5 Business Days)"
            },
            {
                method: 'FDX.GRD.COLLECT',
                description : "FEDEX GROUND (COLLECT) B2B ONLY"
            },
            {
                method: 'FDX.HOME',
                description : "FedEx Home"
            },
            {
                method: 'SMARTPOST',
                description : "FedEx SmartPost"
            },
            {
                method: 'DHLEC.GRD' ,
                description : "DHL Ground"
            },
            {
                method: 'DHLEC.MAX' ,
                description : "DHL 3-Day Priority"
            },
            {
                method: 'FDX.INTL.ECO' ,
                description : "FedEx International Economy (2-5 Business Days)"
            },
            {
                method: 'FDX.CONNECT.PLUS' ,
                description : "FedEx International Connect Plus (4-10 Business Days)"
            },
            {
                method: 'UPS.INTL.EXP' ,
                description : "UPS International Expedited (2-5 Business Days)"
            },
            {
                method: 'DHLEC.PLT' ,
                description : "DHL Parcel International Direct (4-12 Business Days)"
            },
           
        ];

        return (
          <NewDataTable
            rows={rows}
            tableTitle="Shipping Method Map Table (Continued)"
            noPagination
            rowHeight="auto"
            columns={[
                {
                    key: 'method',
                    label: 'Method (Use method from this column when uploading your file)',
                    header_style: {
                        whiteSpace: 'normal'
                    },
                    render: utils.tableCellOnDisplay,
                },
                {
                    key: 'description',
                    label: 'Description',
                    render: utils.tableCellOnDisplay,
                },
            ]}
          />
        );
    }
}
export default withStyles(styles)(ImportOrder);
