// ##############################
// // // Sidebar styles
// #############################

import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
} from "variables/styles";

const sidebarStyle = theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: "#000",
      opacity: ".75"
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
    '&:hover': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    height: theme.spacing(8),
    ...theme.mixins.toolbar,
    // position: "relative",
    // padding: "15px 15px",
    // zIndex: "4",
    // "&:after": {
    //   content: '""',
    //   position: "absolute",
    //   bottom: "0",
    //
    //   height: "1px",
    //   right: "15px",
    //   width: "calc(100% - 30px)",
    //   backgroundColor: "rgba(180, 180, 180, 0.3)"
    // }
  },
  logoImg: {
    width: "100%",
    top: "10px",
    zIndex: "4",
    // padding: theme.spacing(2),
    padding: theme.spacing(0, 0.5),
    verticalAlign: "middle",
    border: "0"
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none"
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
  },
  itemLink: {
    width: 'auto',
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "block",
    padding: "10px 15px",
    backgroundColor: "transparent",
    ...defaultFont
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(255, 255, 255, 0.8)"
  },
  itemText: {
    ...defaultFont,
    margin: "0",
    lineHeight: "30px",
    fontSize: "14px",
    color: "#FFFFFF"
  },
  whiteFont: {
    color: "#FFFFFF"
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    zIndex: "4",
    overflowScrolling: 'touch'
  }
});

export default sidebarStyle;
