import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment-timezone'
import {
    Typography,
    Grid,
    Checkbox,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    IconButton
} from '@material-ui/core';
import {
  DeleteForever
} from '@material-ui/icons'
import DataContext from 'context/Data'
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from 'components';
import _ from 'lodash'

import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class HostCheckoutPayout extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
          loading: false,
          date_from: '',
          date_to: '',
          orders: null,
        };

        document.title = "Host Checkout";
    }

    // api call
    // load email table and report type options on page load
    searchByDate = () => {
      const { date_from, date_to } = this.state;

      let from = moment(date_from);
      let to = moment(date_to);
      if (to.isBefore(from)) {
          this.context.alert("Date To should be after Date From");
          return;
      }
      from = from.format('YYYY-MM-DD');
      to = to.format('YYYY-MM-DD');

      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/checkoutpayout/${from}/${to}`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });

      this.setState({loading: true});
      req.then(this.searchByDateSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    searchByDateSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }
        if (! resp.data || resp.data.length == 0) this.context.alert('No result found!');
        this.setState({orders: resp.data});
    }
    submitPayout = () => {
      // Check customer id and local storage customer id
      if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
        this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
        return;
      }

      const { orders } = this.state;
      let new_orders = [];
      for (let order of orders) {
          let paid_credit = order.paid_credit;
          let collect_return = order.collect_return;
          if (paid_credit === true || collect_return === true) {
              let new_order = {};
              new_order.invoice_id = order.invoice_id;
              new_order.paid_credit = (!!order.paid_credit) ? 1 : 0;
              new_order.collect_return = (!!order.collect_return) ? 1 : 0;
              new_orders.push(new_order);
          }
      }

      if (new_orders.length <= 0) {
          alert("No changes made!");
          return;
      }

      this.submitPayoutOnce(new_orders);
    }
    submitPayoutAjax = (reqData) => {
        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/checkoutpayout`,
            headers: {
                token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: reqData
        });
        this.setState({loading: true});
        req.then(this.submitPayoutSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    }
    submitPayoutOnce = _.once(this.submitPayoutAjax)
    resetSubmitOnce = () => {this.submitPayoutOnce = _.once(this.submitPayoutAjax);} // reset the submit picking once after the ajax call returns
    submitPayoutSuccess = (resp) => {
      this.setState({loading: false});
      this.resetSubmitOnce();
      let data = resp.data;
      if (data.Error) {
          this.context.alert(data.Error);
          return;
      }
      this.context.snackDisplay("Submit Payout success!");
      //window.location.reload();
    }

    // render search result table
    renderTable = () => {
      const { orders } = this.state;
      if (!orders || orders.length == 0) return null;

      let rows = Array.from(orders);


      let sum =  orders.reduce((accumulator, row)=>{
        let curr = row.amount ? row.amount : 0;
        return accumulator + curr;
      }, 0);

      sum = parseFloat(sum).toFixed(2);
      if (!sum) sum = '0';
      if (sum === '0.00') sum = '0';

      rows.push({
        type: 'Total',
        cost: '',
        quantity: '',
        amount: sum,
        description: '',
        total: true
      });

      let colSettings = [
        {
            key: 'OrderID',
            label: 'Order ID',
        },  {
            key: 'StatusId',
            label: 'Status',
        }, {
            key: 'ShipmentDate',
            label: 'Shipment Date',
        }, {
            key: 'paid_credit',
            label: 'Paid Credit',
            render: (val, key, data, index) => {
                return <Checkbox
                    checked={(val !== false && val !== 0)}
                    disabled={val === 1}
                    onChange={(e)=>{
                        let new_orders = Array.from(this.state.orders);
                        let new_val = e.target.checked;
                        data.paid_credit = new_val;
                        new_orders[index] = data;
                        this.setState({
                           orders: new_orders
                        });
                    }}
                    color="primary"
                />;
            },
        },{
            key: 'collect_return',
            label: 'Collect Return',
            render: (val, key, data, index) => {
                return <Checkbox
                    checked={(val !== false && val !== 0)}
                    disabled={val === 1}
                    onChange={(e)=>{
                        let new_orders = Array.from(this.state.orders);
                        let new_val = e.target.checked;
                        data.collect_return = new_val;
                        new_orders[index] = data;
                        this.setState({
                            orders: new_orders
                        });
                    }}
                    color="primary"
                />;
            },
        },
      ];

      return (
        <GridItem sm={12}>
          <CardContainer>
            <Button onClick={this.submitPayout}>Submit Payout</Button>
            <NewDataTable
              rows={rows}
              rowHeight='auto'
              noPagination
              columns={colSettings}
            />
          </CardContainer>
        </GridItem>
      );
    }
    
    render() {
      const { classes } = this.props;
      const { loading, date_from, date_to, } = this.state;

      let loadingBar = null;
      if (loading) {
          loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
      }

      return (
        <Grid container spacing={2}>
          {loadingBar}

          <GridItem xs={12} sm={6}>
            <CardContainer>
              <CustomInput
                labelText='Date From'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  type: 'date',
                  value: date_from,
                  onChange: (e)=>{this.setState({date_from: e.target.value})}
                }}
              />

              <CustomInput
                  labelText='Date To'
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: 'date',
                    value: date_to,
                    onChange: (e)=>{
                      this.setState({date_to: e.target.value})
                    }
                  }}
              />

              <Button style={{marginTop: '.5rem'}} onClick={this.searchByDate}>Search</Button>
            </CardContainer>
          </GridItem>
          {this.renderTable()}
        </Grid>
      );
  }
}
export default withStyles(styles)(HostCheckoutPayout)
