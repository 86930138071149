import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select} from '@material-ui/core'

import {BasicDialog, Button,  CustomInput,} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function EditInboundPallet(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit} = props;
  const [station, setStation] = useState('');
  const [notes, setNotes] = useState('');

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
         <CustomInput
            labelText='Station #'
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            labelProps={{
              shrink: true
            }}
            inputProps={{
              onChange: (e)=>setStation(e.target.value),
              value: station,
              placeholder: 'Enter the number of the station you want to process this pallet',
              // multiline: true,
              // rows: 3
            }}
          />

          <CustomInput
            labelText='Notes'
            formControlProps={{
              fullWidth: true,
            }}
            labelProps={{
              shrink: true
            }}
            inputProps={{
              onChange: (e)=>setNotes(e.target.value),
              value: notes,
              multiline: true,
              rows: 3
            }}
          />
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';  

    if (!station) err += "Station Number is required. \n";
    if (err) dataContext.alert(err);
    else handleSubmit({station, notes});
  }

  let title = 'Process Inbound Pallet';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}