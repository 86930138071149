import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Prompt } from 'react-router'
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  ReversedIconButton 
} from 'components';
import DataContext from 'context/Data'
import {
  ExposureNeg1,
  Add
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';
import EditReceivingItem from 'views/Dialogs/Receiving/EditReceivingItem'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class UnplannedReceiving extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.input_refs = {
      sku: null,
    };
    this.temp_data = {
      close_confirm: false,
      sku_map: {},
      upc_map: {},
      key_array: [],
      barcode_map: {},
      top_item: '',
      eidt_item: null, // the PO lineitem to be edit
    };

    this.state = {
      loading: false,
      dialog: '',
      // Return PO Select
      po_list: [],
      selected_po: null,
      // PO Item List
      item_list: null,
      // PO Input
      receiving_type: '',
      container_num: 0,
      notes: '',
    };

    document.title = "Unplanned Receiving";
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
    this.context.confirm("This is for Unplan receiving only, if you want to process unplan return, please go to Unplan Return page. \n(Esto es solo para recepción no planificada, si desea procesar la devolución no planificada, vaya a la página Devolución no planificada.)", {
      onConfirm: this.loadPOList,
      onClose: ()=>this.props.history.goBack(),
    });
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }
  handleUnload = (e) => {
    if (!this.hasUnsaved()) return '';
    var message = "\o/";
    message = "Changes you made may not be saved.";
    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  }
  hasUnsaved = () => {
    return this.temp_data.close_confirm;
  }

  resetPage = () => {
    this.temp_data = {
      close_confirm: false,
      sku_map: {},
      upc_map: {},
      key_array: [],
      top_item: '',
      eidt_item: null, // the PO lineitem to be edit
    };
    this.setState({
      // Return PO Select
      selected_po: null,
      // PO Item List
      item_list: null,
      // PO Input
      receiving_type: '',
      container_num: 0,
      notes: '',
    });
  }

  __getFieldRequired = () => {
    let current_customer = parseInt(localStorage.getItem('customer_id'));
    let lotRequired = false;
    let expirationDateRequired = false;
    // if (current_customer === 131 || current_customer === 190) lotRequired = true;
    // ON 2021-11-01, turn off lot required for 131
    if (current_customer === 190) lotRequired = true;
    if (current_customer === 158) {
        lotRequired = true;
        expirationDateRequired = true;
    }
    // for Oliver's and Bottles and Burps
    // check expiration_date
    if (current_customer === 127 || current_customer === 146) {
        expirationDateRequired = true;
    }
    return {
        lot: lotRequired,
        expiration_date: expirationDateRequired
    }
  }

  handleSkuScanned = (e) => {
    let scanned_val = utils.formatString(this.input_refs.sku?.value); 
    if (!scanned_val) return;
    if (this.temp_data.barcode_map[scanned_val]) {
      scanned_val = this.temp_data.barcode_map[scanned_val];
    } else  if (this.temp_data.barcode_map[scanned_val.toUpperCase()]) { // also check uppercase for barcode map
      scanned_val = this.temp_data.barcode_map[scanned_val.toUpperCase()];
    }
    // Use upper case to match SKU and UPC
    scanned_val = scanned_val.toUpperCase();

    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]) {
      matched_id = this.temp_data.sku_map[scanned_val]; // sku matched
      if (Array.isArray(matched_id)) {
        this.context.alert("This SKU:"+ scanned_val +" is duplicate in this PO, please tell the manager!");
        return;
      }
    } else if (this.temp_data.upc_map[scanned_val]) {
      matched_id = this.temp_data.upc_map[scanned_val]; // upc matched
      if (Array.isArray(matched_id)) {
        this.context.alert("This UPC:"+ scanned_val +" is duplicate in this PO, please tell the manager!");
        return;
      }
    } else {
      this.verifySku(scanned_val);
      return;
    }
    let items = Object.assign({}, this.state.item_list);
    let newItem = Object.assign({}, items[matched_id]);
    let newQty = 1 + parseInt(newItem.quantity_input);
    newItem['quantity_input'] = newQty;
    items[matched_id] = newItem;
    // Move the scanned row to top
    this.temp_data.top_item = matched_id;

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
    this.setState({item_list: items});
  }
  handleScan = _.debounce(this.handleSkuScanned, 150);

  loadPOList = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/unplannedReceiving`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.loadPOListSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadPOListSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    let options = [];
    for (let po of resp.data.Receiving) {
        options.push({
            po: po['PO'],
            po_id: po['id']
        });
    }
    if (!options || !Array.isArray(options)) return;
    this.setState({po_list: options});
  }
  verifySku = (scanned_val) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/unplannedReceiving/${scanned_val}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.verifySkuSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  verifySkuSuccess = (resp) => {
    this.setState({loading: false});
    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }  
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let verified_item = resp.data[0];
    let new_items = Object.assign({}, this.state.item_list);
    this.temp_data.top_item = verified_item.item_id; // put the new scanned item to top
    if (new_items[verified_item.item_id]) {
      // if item already exists 
      let new_item = new_items[verified_item.item_id];
      new_item.quantity_input = 1 + (parseInt(new_item.quantity_input) || 0);
    } else {
      // new item, insert
      verified_item.polybagged_quantity = 0;
      verified_item.hangtagged_quantity = 0;
      verified_item.barcoded_quantity = 0;
      verified_item.quantity_input = 1;
      verified_item.lot = verified_item.lot || "";
      verified_item.expiration_date = verified_item.expiration_date || "";
      verified_item.unit_case = verified_item.unit_case || "";
      verified_item.tote = this.temp_data.key_array.length + 1; // Use the number of skus to assign the tote of current item, first item of the array tote is 1, next tote is 2 etc...
      new_items[verified_item.item_id] = verified_item;   
      // Save SKU and UPC to uppercase
      let sku = verified_item.sku ? ('' + verified_item.sku).toUpperCase().trim() : "";
      let upc = verified_item.upc ? ('' + verified_item.upc).toUpperCase().trim() : "";
      if (!this.temp_data.sku_map[sku]) this.temp_data.sku_map[sku] = verified_item.item_id;
      if (!this.temp_data.upc_map[upc]) this.temp_data.upc_map[upc] = verified_item.item_id;
      this.temp_data.key_array.push(verified_item.item_id);
    }
    this.setState({item_list: new_items});
    this.temp_data.close_confirm = true;
  }
  submitReceiving = (e) => {
    e.preventDefault();
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }

    let err = '';
    let { item_list, notes, receiving_type, container_num} = this.state;
    if (!receiving_type) err += 'Receiving type is required. \n';
    let receiving_items = [];
    let scanned_total = 0;

    for (let item_id of this.temp_data.key_array) {
      let po_item = Object.assign({}, item_list[item_id]);
      if (parseInt(po_item.quantity_input) === 0 || !po_item.quantity_input) continue;  // skip item if quantity input is 0
      po_item['unit_case'] = po_item['unit_case'] || 0;
      po_item['weight'] = po_item['weight'] || 0;
      scanned_total += parseInt(po_item.quantity_input); // sum up scanned quantity

      if (this.__getFieldRequired().lot && !po_item.lot) err += 'Lot is required for this customer, but item SKU '+po_item.sku+' missing lot field.\n';
      if (this.__getFieldRequired().expiration_date && !po_item.expiration_date) err += 'Expiration Date is required for this customer, but item SKU '+po_item.sku+' missing Expiration Date field.\n';

      if (receiving_type === "case") {
        if (!po_item['unit_case']) {
          err += "SKU: " + po_item.sku + ", UnitByCase is required and should be greater than 0.\n";
        }
      }

      po_item.barcoded = po_item.barcoded_quantity;
      po_item.hangtagged_quantity = po_item.hangtagged_quantity;
      po_item.polybagged = po_item.polybagged_quantity;
      po_item.po_item_id = '';

      receiving_items.push(po_item);
    }
    if (receiving_items.length === 0 || scanned_total === 0) err += 'No items received. \n';
    if (receiving_type !== "unit" && container_num == 0) err += `The ${receiving_type} number should be greater than 0.`;

    if (err) this.context.alert(err);
    else {
      this.submitReceivingOnce({
        po_id: this.state.selected_po.po_id,
        unplan_type: 'RECEIVED',
        notes: notes,
        receiving_type: receiving_type,
        container_num: container_num,
        case_num: container_num,
        item: JSON.stringify(receiving_items)
      });
    }
  }
  submitReceivingAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/unplannedReceiving`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitReceivingSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    this.setState({loading: true})
  }
  resetSubmitOnce = () => {this.submitReceivingOnce = _.once(this.submitReceivingAJax);} // reset the submit picking once after the ajax call returns
  submitReceivingOnce = _.once(this.submitReceivingAJax) 
  submitReceivingSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;

    switch (true) {
      case typeof result == 'string':
        utils.printPage(result);
        break;
      case Array.isArray(result):
        result.forEach((elem)=>utils.printPage(elem));
        break;
      case typeof result == 'object':
        for (let key in result) {
          utils.printPage(result[key]);
        }
        break;
    }

    // this.context.snackDisplay("Submit receiving success.", {hideCallback: ()=>{
    //   this.temp_data.close_confirm = false; // after submit, allow refresh, don't confirm
    //   window.location.reload();
    // }});

    this.context.snackDisplay("Submit unplan receiving success.");
    this.temp_data.close_confirm = false; // after submit, allow refresh, don't confirm
      window.location.reload();
    // this.resetPage();  
    // this.loadPOList();
  }

  renderDialog = () => {
    switch (this.state.dialog)  {
      case 'editLineItem':
        return <EditReceivingItem
            handleClose={()=>{this.setState({dialog: ''})}}
            handleSubmit={(newItem)=>{
                let newItems = Object.assign({}, this.state.item_list);
                newItems[newItem.item_id] = newItem;
                this.setState({dialog: '', item_list: newItems});
            }}
            lineItem={this.temp_data.eidt_item}
            title='Receiving LineItem'
        />
        break;
      default:
        return null;
    }
  }
  renderReceivingItems = () => {
    const { classes } = this.props;
    const { item_list, receiving_type } = this.state;

    if (!item_list || !this.temp_data.key_array) return null;
    let rows = [];
    // top item
    if (this.temp_data.top_item) rows.push(item_list[this.temp_data.top_item]);

    for (let id of this.temp_data.key_array) {
      if (this.temp_data.top_item === id) continue;
      rows.push(item_list[id]);
    }

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
        render: (val, key, row) => {
          return (
            <Button color='default' onClick={()=>{
              this.temp_data.eidt_item = row;
              this.setState({dialog: 'editLineItem'})}}><span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span></Button>
          );
        }
      },
      {
        key: 'upc',
        label: 'UPC',
        contentNoWrap: true,
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span>
        }
      },
      {
        key: 'tote',
        label: 'Tote#',
        render: (val, key, row) => {
          return (
            <span style={{fontWeight: "bold"}}>{val}</span>
          );
        }
      },
      // {
      //   key: 'temp_location',
      //   label: 'Tote#',
      //   render: (val)=>{
      //     return (<span style={{fontWeight: "bold"}}>{val}</span>);
      //   },
      // },
      {
        key: 'image',
        label: 'Image',
        contentNoWrap: true,
        render: (val, key, row)=>{
          if (val) {
            return (<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
              this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
            }} src={val}/>);
          } else {
            return "No Image";
          }
        }
      },
      {
        key: 'item_name',
        label: 'Item Name',
        width: 250,
      },
      {
        key: 'category',
        label: 'Category',
        contentNoWrap: true,
      },
      {
        key: 'unit_case',
        label: 'UnitByCase',
        render: (val, key, row)=> {
            return (
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: false,
                }}
                inputProps={{
                  inputProps: {
                    step: 1,
                    min: 0,
                  },
                  type: 'number',
                  value: val || 0,
                  onChange: (e)=>{
                    let newVal = e.target.value;
                    newVal = parseInt(newVal);
                    if (!newVal || newVal < 0) newVal = 0;
                    let newItems = Object.assign({}, item_list);
                    let newItem = Object.assign({}, newItems[row.item_id]);
                    if (parseInt(newVal) >= 1000) {
                      this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                    }
                    newItem['unit_case'] = newVal;
                    newItems[row.item_id] = newItem;
                    this.setState({item_list: newItems});
                  },
                }}
              />
            );
        },
      },
      {
        key: 'quantity_input',
        label: 'Scanned',
        render: (val, key, row)=> {
          let current_customer = parseInt(localStorage.getItem('customer_id'));
          // if receive type is unit, and customer is not Buffy, don't allow user to manually input Scanned number
          if ((receiving_type === 'unit' || receiving_type === '') && (current_customer !== 120)) {
            return (
              <span style={{display: "inline-flex", alignItems: 'center'}}>
                {val}
                <ReversedIconButton style={{marginLeft: ".5rem"}} size="small" onClick={()=>{
                    let new_items = Object.assign({}, item_list);
                    let newItem = Object.assign({}, row);
                    let old_qty = parseInt(newItem.quantity_input);
                    let new_qty = old_qty -1;
                    if (new_qty < 0) {
                        this.context.alert("The quantity is already 0, can't minus 1.");
                        return;
                    }

                    newItem['quantity_input'] = new_qty;
                    newItem.received = parseInt(newItem.received) - 1;
                    new_items[row.item_id] = newItem;
                    this.setState({item_list: new_items});
                }} variant="fab" color="secondary" aria-label="Decrease">
                    <ExposureNeg1 style={{color: 'white'}}/>
                </ReversedIconButton>
            </span>);
          } else {
            return (
              <CustomInput
                labelText=''
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: val || '',
                  type: 'number',
                  inputProps: {
                    step: 1,
                    min: 0,
                  },
                  className: classes.customInputNoMargin,
                  onChange: (e) => {
                    let val = e.target.value;
                    let newItems = Object.assign({}, item_list);
                    let newItem = Object.assign({}, newItems[row.item_id]);
                    val = parseInt(val);
                    if (!val || val < 0) val = 0;
                    let old_qty = parseInt(newItem.quantity_input);
                    newItem.received = parseInt(newItem.received) - old_qty + parseInt(val);
                    
                    if (parseInt(val) >= (1000 + newItem.quantity)) {
                      this.context.alert("You are manually entering a very large number, please double check the quantity to make sure it is correct.");
                    }
  
                    newItem['quantity_input'] = val;
                    newItems[row.item_id] = newItem;
                    this.setState({ item_list: newItems });
                  }
                }}
              />
            );
          }
        },
      },
      {
        key: 'style',
        label: 'Style',
        contentNoWrap: true
      },
      {
        key: 'size',
        label: 'Size',
        contentNoWrap: true
      },
      {
        key: 'color',
        label: 'Color',
        contentNoWrap: true
      }
    ];

    return (
     <GridItem xs={12}>
      <CardContainer>
        <CustomInput
          labelText='SKU/UPC'
          formControlProps={{
              fullWidth: false,
              style: {marginLeft: '.25rem', marginRight: '.75rem'},
              className: classes.customInput
          }}
          labelProps={{
              shrink: true,
          }}
          inputProps={{
              onKeyUp: this.handleScan,
              onPaste: (e)=>{
                if (!utils.checkAllowCopy()) e.preventDefault();
              },
              inputRef: elem=>this.input_refs.sku = elem
          }}
        />

        <NewDataTable
          rows={rows}
          // rowHeight={50} 
          maxHeight={500}
          rowsPerPage={50}
          columns={columns}
        />
      </CardContainer>
     </GridItem>
    );
  }
  renderInputForm = () => {
    const {classes} = this.props;
    const {selected_po, item_list, receiving_type, container_num, notes } = this.state;
    if (!selected_po || !item_list) return;

    return (
      <form onSubmit={this.submitReceiving}>
        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Receiving Type</InputLabel>
          <Select
              value={receiving_type}
              disabled={receiving_type !== ''}
              onChange={(e)=>{this.setState({receiving_type: e.target.value})}}
          >
            <MenuItem value='unit'>unit</MenuItem>
            <MenuItem value='case'>case</MenuItem>
            <MenuItem value='pallet'>pallet</MenuItem>
          </Select>
        </FormControl>

        {
          (receiving_type == 'case' ||  receiving_type == 'pallet') &&
          <CustomInput
            labelText={receiving_type == 'case' ? 'Case Number' : 'Pallet Number'}
            formControlProps={{
              fullWidth: true,
              className: classes.customInput,
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              onChange: (e)=>this.setState({container_num: e.target.value}),
              value: container_num || '',
            }}
          />
        }

        <CustomInput
          labelText='Notes'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>this.setState({notes: e.target.value}),
            value: notes,
          }}
        />

        <Button type='submit' className={classes.button}>Submit Receiving</Button>
      </form>
    );
  }
  render() {
    const {po_list} = this.state;

    // https://v4.mui.com/components/autocomplete/
    let defaultProps = {
      onChange: (e, selected)=>{ 
        this.temp_data.key_array = [];
        this.temp_data.sku_map = {};
        this.temp_data.upc_map = {};
        this.temp_data.top_item = '';
        if (selected) this.setState({item_list: {}, selected_po: selected}); 
        else {
          this.setState({item_list: null, selected_po: selected}); 
        }
      },
      options: po_list || [],
      getOptionLabel: (option) => option.po,
    };

    return (
      <Grid container spacing={2}>
        <Prompt
          when={this.hasUnsaved()}
          message="You have unsaved work, are you sure to leave?"
        />

        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {this.renderDialog()}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <Autocomplete
              {...defaultProps}
              autoComplete
              includeInputInList
              renderInput={(params) => <TextField {...params} label="PO#" InputLabelProps={{shrink: true}} margin="normal" />}
            />

            {this.renderInputForm()}
          </CardContainer>
        </GridItem>

        {this.renderReceivingItems()}
      </Grid>
    );
  }
}
export default withStyles(styles)(UnplannedReceiving);
