import React, {useState, useContext } from 'react'
import DataContext from 'context/Data'
import axios from 'axios';
import utils from 'utils/utils'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core'

import {CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function CreateNewTicket(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, defaultTicket} = props;

  let customer_options = JSON.parse(localStorage.getItem('customer_detail'))[localStorage.getItem('warehouse_id')]; 

  const [loading,setLoading] = useState(false);
  const [ticket,setTicket] = useState(defaultTicket || {
    subject:'',
    subject_category:'Other',
    description: '',
    customer: localStorage.getItem('customer_name'),
    category: '',
    agent: '',
  });

  function updateTicket(val, key) {
    let new_ticket = Object.assign({}, ticket);
    new_ticket[key] = val;
    setTicket(new_ticket);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}> 
         {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
         <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Subject Category</InputLabel>
          <Select
              onChange={(e)=>{updateTicket(e.target.value, 'subject_category')}}
              value={ticket.subject_category}
          >
           <MenuItem value='Other'>Other</MenuItem>
           <MenuItem value='Kit Request'>Kit Request</MenuItem>
           <MenuItem value='Transfer Request'>Transfer Request</MenuItem>
           <MenuItem value='B2B Hospital'>B2B Hospital</MenuItem>
           <MenuItem value='B2C Hospital'>B2C Hospital</MenuItem>
           <MenuItem value='IB Issue'>IB Issue</MenuItem>
           <MenuItem value='Record Issue'>Record Issue</MenuItem>
           <MenuItem value='Return Issue'>Return Issue</MenuItem>
           <MenuItem value='Inventory Issue'>Inventory Issue</MenuItem>
           <MenuItem value='B2C Verified'>B2C Verified</MenuItem>
           <MenuItem value='Project Request'>Project Request</MenuItem>
          </Select>
        </FormControl>
         
         <CustomInput
          labelText='Subject'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{updateTicket(e.target.value, 'subject')},
            value: (ticket.subject || ''),
          }}
        />

        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Owner</InputLabel>
          <Select
              onChange={(e)=>{updateTicket(e.target.value, 'agent')}}
              value={ticket.agent}
          >
           {props.agent_list.map((elem)=><MenuItem key={elem.agent_id} value={elem.agent_id}>{elem.name}</MenuItem>)} 
          </Select>
        </FormControl>

        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Customer</InputLabel>
          <Select
              onChange={(e)=>{updateTicket(e.target.value, 'customer')}}
              value={ticket.customer}
          >
           {customer_options.map((elem)=><MenuItem key={elem.shortname} value={elem.shortname}>{elem.shortname}</MenuItem>)} 
          </Select>
        </FormControl>


        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Category</InputLabel>
          <Select
              onChange={(e)=>{updateTicket(e.target.value, 'category')}}
              value={ticket.category}
          >
           {(JSON.parse(localStorage.getItem('category_list')) || []).map((elem)=><MenuItem key={elem} value={elem}>{elem}</MenuItem>)} 
          </Select>
        </FormControl>

        <CustomInput
          labelText='Description'
          labelProps={{
            shrink: true,
          }}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{updateTicket(e.target.value, 'description')},
            value: ticket.description || '',
            multiline: true,
            rows: 3
          }}
        />    
      </Grid>
    );
  }
  function onSubmit() {
    let { subject_category, subject, category, customer, description, agent } = ticket;
    
    let err = '';
    if (!subject) err += 'Subject is required.\n';
    if (!agent) err += 'Owner is required.\n';
    if (!category) err += 'Category is required.\n';
    if (!description) err += 'Description is required.\n';
    if (!customer) err += 'Customer is required.\n';

    let req_data = {
      subject: `${subject_category} - ${subject}`, category, customer, description, agent,
      user_id: localStorage.getItem('user_id'), 
      username: localStorage.getItem('username')
    };

    if (err) dataContext.alert(err);
    else createNewTicket(req_data); // todo, decide if need to pass the formatted
  }
  function createNewTicket(req_data) {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/ticket`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: req_data
    });
    setLoading(true);
    req.then(createNewTicketSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert}));
  }
  function createNewTicketSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      dataContext.alert(resp.data.Error);
      return;
    }
    props.handleSubmit();
  }

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={props.title || "Create Zoho Ticket"}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}