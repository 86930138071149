import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  CardContainer,
  Button,
  NewDataTable,
  CustomInput,
  GridItem,
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class B2BPalletDashboard extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.temp_data = {
      customer: '',
      customer_id: '',
      status: '',
    };

    this.state = {
      loading: false,
      dashboardDetail: null,
      palletList: null,
    };

    document.title = "B2B Pallet Dashboard";
  }

  componentDidMount() {
    this.loadDashboard();
  }

  loadDashboard = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/loadb2bpalletDB`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.loadDashboardSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadDashboardSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    let dashboardData = [];
    for (let customer_id in data) {
      dashboardData.push(data[customer_id]);
    }
    this.setState({ dashboardDetail: dashboardData });
  }
  loadCustomerPalletByStatus = (customer_name, customer_id, status) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/customerb2bpallet/${localStorage.getItem('warehouse_id')}/${customer_id}/${status}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    
    this.temp_data.customer = customer_name;
    this.temp_data.customer_id = customer_id;
    this.temp_data.status = status;

    this.setState({loading: true,palletList: null});
    req.then(this.loadCustomerPalletByStatusSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadCustomerPalletByStatusSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    this.setState({ palletList: data });
  }
  printB2BPalletFlag = (b2b_pallet_id) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/printB2Bpallet/${this.temp_data.customer_id}/${b2b_pallet_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.printB2BPalletFlagSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printB2BPalletFlagSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    switch (true) {
      case typeof data == 'string':
        utils.printPage(data);
        break;
      case Array.isArray(data):
        data.forEach((elem)=>utils.printPage(elem));
        break;
      case typeof data == 'object':
        for (let key in data) {
          utils.printPage(data[key]);
        }
        break;
    }
  }

  renderDashboard = () => {
    const {dashboardDetail} = this.state;
    let rows = [];
    for (let customer_name in dashboardDetail) {
      rows.push(Object.assign({customer: customer_name}, dashboardDetail[customer_name]));
    }

    let columns = [
      {
        key: 'customer',
        label: 'Customer',
      },
      {
        key: 'NEW',
        label: 'NEW',
        cellProps: {
          style: {
            cursor: 'pointer',
          },
          title: 'Click to get details',
          onClick: (val, key, row, index) => {
            if (!parseInt(val)) return;
            this.loadCustomerPalletByStatus(row.customer, row.customer_id, key);
          }
        },
        render: (val) => val || 0
      },
      {
        key: 'PENDING LABEL',
        label: 'PENDING LABEL',
        cellProps: {
          style: {
            cursor: 'pointer',
          },
          title: 'Click to get details',
          onClick: (val, key, row, index) => {
            if (!parseInt(val)) return;
            this.loadCustomerPalletByStatus(row.customer, row.customer_id, key);
          }
        },
        render: (val) => val || 0
      },
      {
        key: 'READY',
        label: 'READY',
        cellProps: {
          style: {
            cursor: 'pointer',
          },
          title: 'Click to get details',
          onClick: (val, key, row, index) => {
            if (!parseInt(val)) return;
            this.loadCustomerPalletByStatus(row.customer, row.customer_id, key);
          }
        },
        render: (val) => val || 0
      },
      {
        key: 'RELEASED',
        label: 'RELEASED',
        cellProps: {
          style: {
            cursor: 'pointer',
          },
          title: 'Click to get details',
          onClick: (val, key, row, index) => {
            if (!parseInt(val)) return;
            this.loadCustomerPalletByStatus(row.customer, row.customer_id, key);
          }
        },
        render: (val) => val || 0
      },
    ];

    return (
      <GridItem xs={12} sm={12}>
        <CardContainer>
          <NewDataTable
            rows={rows}
            noPagination
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderPalletList = () => {
    const {palletList} = this.state;
    if (!palletList) return null;

    let columns = [
      {
        key: 'pallet_id',
        label: 'Pallet Id',
        render: (val, key, row, index)=>{
          return <Button onClick={this.printB2BPalletFlag.bind(this, val)}>{val}</Button>;
        },
      }, 
      {
        key: 'order_id',
        label: 'Order Barcode',
      },
      {
        key: 'order_key',
        label: 'Order ID',
        contentNoWrap: true,
      },
      {
        key: 'fullname',
        label: 'Fullname',
        contentNoWrap: true,
      },
      {
        key: 'location',
        label: 'Location',
        contentNoWrap: true,
      },
      {
        key: 'company',
        label: 'Company',
        contentNoWrap: true,
      },
    ];

    return (
      <GridItem xs={12}>
        <CardContainer>
          <Button onClick={()=>{utils.exportTableToCsv("b2b_pallets");}}>Export</Button>

          <NewDataTable
            rows={palletList}
            noPagination
            id="b2b_pallets"
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }
  render() {
    const { classes } = this.props;
    const { loading, keyword, type } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container spacing={2}>
        {loadingBar}

        {this.renderDashboard()}
        {this.renderPalletList()}
      </Grid>
    );
  }
}
export default withStyles(styles)(B2BPalletDashboard);
