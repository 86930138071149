import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {Checkbox, Input, ListItemText, Typography, InputLabel, Select, FormControl, MenuItem, withStyles} from "@material-ui/core";
import {
  MultiSelect,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
} from 'components';
import DataContext from 'context/Data'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1, 0),
    // minWidth: 120,
    // maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

class CustomerInfo extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    customer_info: PropTypes.object.isRequired,
    onCustomerInfoChange: PropTypes.func.isRequired,
    onCustomerStatusChange: PropTypes.func.isRequired,
    init: PropTypes.bool
  };

  static defaultProps = {
    init: false
  };

  constructor(props) {
    super(props);

    let warehouse_detail = JSON.parse(localStorage.getItem('warehouse_detail'));
    let warehouse_options = [];
    for (let w_id in warehouse_detail) {
      warehouse_options.push({selected: false, value: w_id, label: warehouse_detail[w_id]});
    }

    this.temp_data = {
      warehouse_options,
      warehouse_detail
    };

    const {
      company,
      address1,
      address2,
      city,
      province,
      postal_code,
      countrycode,
      phone,
      email,
      deposit,
      deposit_min,
      term,
      warehouse_id
    } = props.customer_info;

    let selected_warehouse = {};
    for(let id of (warehouse_id || '').split(',')) {
      if (!id) continue; // skip empty warehouse id list
      selected_warehouse[id] = true;
    }
    // how to check customer warehouse

    this.state = {
      edit: props.init,
      company: company ? company : '',
      address1: address1 ? address1 : '',
      address2: address2 ? address2 : '',
      city: city ? city : '',
      province: province ? province : '',
      postal_code: postal_code ? postal_code : '',  
      countrycode: countrycode ? countrycode : '',
      phone: phone ? phone : '',
      email: email ? email : '',
      deposit: deposit ? deposit : '0',
      deposit_min: deposit_min ? deposit_min : '',
      term: term ? term : '14',
      selected_warehouse: selected_warehouse
    };
  }

  render() {
    const { classes } = this.props;
    const {
      edit,
      company,
      address1,
      address2,
      city,
      province,
      postal_code,
      countrycode,
      phone,
      email,
      deposit,
      deposit_min,
      term,
      selected_warehouse
    } = this.state;

    let content = this.renderCustomerInfoTable();
    if (edit) {
      content = (
        <form onSubmit={this.submit} className={classes.root}>
           {/* Better multi select slolution */}
           {/* Todo Move this back to live customer warehouse select */}
           {/* <FormControl  fullWidth id="demo-mutiple-checkbox-label" className={classes.formControl}>
            <InputLabel shrink>Warehouse List</InputLabel>
            <Select
              displayEmpty
              multiple
              value={Object.keys(selected_warehouse).sort()}
              onChange={(e)=>{
                let selected = {};
                for (let id of e.target.value) selected[id] = true;
                this.setState({selected_warehouse: selected});
              }}
              input={<Input />}
              renderValue={(selected) => {
                return selected.sort().map((elem)=>this.temp_data.warehouse_detail[elem]).join(', ');
              }}
              MenuProps={MenuProps}
            >
              {this.temp_data.warehouse_options.map((warehouse) => (
                <MenuItem key={warehouse.value} value={warehouse.value}>
                  <Checkbox checked={!!selected_warehouse[warehouse.value]} />
                  <ListItemText primary={warehouse.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* Multi-select solution but not good */}
          {/* <MultiSelect
            title="Warehouse"
            listItems={this.temp_data.warehouse_options.map((elem)=>{
              if (selected_warehouse[elem.value]) elem.selected = true;
              return elem;
            })}
            handleSubmit={(new_val)=>{this.setState({selected_warehouse: new_val});}}
          /> */}



          <CustomInput
            labelText="Company"
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customINput
            }}
            labelProps={{shrink: true}}
            inputProps={{value: company, onChange: (e)=>this.setState({company: e.target.value})}}
          />

          <CustomInput
            labelText='Address Line 1'
            formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
            }}
            labelProps={{
                shrink: true,
            }}
            inputProps={{
                value: address1,
                onChange: (e)=>{this.setState({address1: e.target.value})}
            }}
          />

          <CustomInput
            labelText='Address Line 2'
            formControlProps={{
                fullWidth: true,
                className: classes.customInput,
            }}
            labelProps={{
                shrink: true,
            }}
            inputProps={{
                value: address2,
                onChange: (e)=>{this.setState({address2: e.target.value})}
            }}
          />

          <CustomInput
              labelText='City'
              formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput,
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  value: city,
                  onChange: (e)=>{this.setState({city: e.target.value})}
              }}
          />

          <CustomInput
              labelText='Province'
              formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput,
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  value: province,
                  onChange: (e)=>{this.setState({province: e.target.value})}
              }}
          />

          <CustomInput
              labelText='Postal Code'
              formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput,
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  value: postal_code,
                  onChange: (e)=>{this.setState({postal_code: e.target.value})}
              }}
          />

          <CustomInput
              labelText='Country'
              formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput,
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  value: countrycode,
                  onChange: (e)=>{this.setState({countrycode: e.target.value})}
              }}
          />

          <CustomInput
              labelText='Phone'
              formControlProps={{
                  fullWidth: true,
                  className: classes.customInput,
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  value: phone,
                  onChange: (e)=>{this.setState({phone: e.target.value})}
              }}
          />

          <CustomInput
              labelText='Email'
              formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput,
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  value: email,
                  onChange: (e)=>{this.setState({email: e.target.value})}
              }}
          />

          <FormControl required fullWidth className={classes.selectInput}>
            <InputLabel shrink htmlFor="term">Payment Term</InputLabel>
            <Select
                value={term}
                onChange={(e)=>{ this.setState({term: e.target.value});}}
                inputProps={{
                    name: 'term',
                    id: 'term',
                }}
            >
                <MenuItem value='0'>0</MenuItem>
                <MenuItem value='7'>7</MenuItem>
                <MenuItem value='14'>14</MenuItem>
                <MenuItem value='30'>30</MenuItem>
            </Select>
          </FormControl>

          <div style={{width: '100%'}}>
            <Button style={{marginTop: '1rem'}} type='submit'>Submit</Button>

            <Button style={{marginTop: '1rem', float: 'right'}} onClick={()=>this.setState({edit: false})}>Cancel</Button>
          </div>
        </form>
      );
    }

    return (
      <div>
        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Customer Information
        </Typography>

        {content}
      </div>
    );
  }

  submit = (e) => {
    e.preventDefault();
    const {
      company,
      address1,
      address2,
      city,
      province,
      postal_code,
      countrycode,
      phone,
      email,
      deposit,
      deposit_min,
      term,
      selected_warehouse
    } = this.state;

    let customer_info = {
      company,
      address1,
      address2,
      city,
      province,
      postal_code,
      countrycode,
      phone,
      email,
      deposit,
      deposit_min,
      term,
      warehouse_id: Object.keys(selected_warehouse).sort().join(',')
    };

    this.props.onCustomerInfoChange(customer_info, ()=>{this.setState({edit: false})});
  }
  deactivateCustomerAccount = () => {
    this.props.onCustomerStatusChange(0);
  }

  renderCustomerInfoTable = () => {
    const {
        edit,
        company,
        address1,
        address2,
        city,
        province,
        postal_code,
        countrycode,
        phone,
        email,
        deposit,
        deposit_min,
        term,
    } = this.state;

    return (
      <div>
        <div>
          <Button style={{margin: '1rem 0'}} onClick={()=>{this.setState({edit: true})}}>Edit</Button>

          {
            !(localStorage.getItem("role") !== '1' && localStorage.getItem("role") !== 'SystemAdmin') 
            &&
            <Button color="secondary" style={{margin: '1rem 0', float: 'right'}} onClick={()=>{
            
              this.context.confirm(`Are you sure to set account status to Inactive?`, this.deactivateCustomerAccount);
            }}>Deactivate</Button>
          }
        </div>

        <CardContainer>
          <KeyValueTable
            dataObj={{
              deposit_min,
              term,
            }}
            rowHeight='auto'
            propertyList={[
              {
                key: 'address',
                label: 'Address',
                render: () => {
                  let addr = "";
                  if (company) {
                      addr += company;
                      addr += ", ";
                  }
                  if (address1) {
                      addr += address1;
                      addr += ", ";
                  }
                  if (address2) {
                    addr += address2;
                    addr += ", ";
                }
                  if (city) {
                      addr += city;
                      addr += ", ";
                  }
                  if (province) {
                      addr += province;
                      addr += ", ";
                  }
                  if (postal_code) {
                      addr += postal_code;
                      addr += ", ";
                  }
                  if (countrycode) {
                      addr += countrycode;
                      addr += ", ";
                  }
                  if (phone) {
                      addr += phone;
                      addr += ", ";
                  }
                  if (email) {
                    addr += email;
                  }
                  if (addr[addr.length-1] === " ") addr = addr.slice(0, addr.length-2);
                  return addr;
                }
              },
              {
                key: 'deposit_min',
                label: 'Deposit Minimum',
              },
              {
                key: 'term',
                label: 'Payment Term',
              },
            ]}
          />
        </CardContainer>
      </div>
    );
  }
}
export default withStyles(styles)(CustomerInfo);
