import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core'

import { CustomInput, BasicDialog, Button } from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function EditStore(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, account: defaultAccount} = props;

  const [account,setAccount] = useState(Object.assign({}, defaultAccount));

  function updateAccount(val, key) {
    let new_account = Object.assign({}, account);
    new_account[key] = val;
    setAccount(new_account);
  }

  function renderFormContent() {
    let input_fields = [];
    switch (account.carrier) {
      case 'SHOPIFY':
        if (!account.id) {
          input_fields.push(
            <CustomInput
              key="Account"
              labelText='Account'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                value: account.account || '',
                onChange: (e)=>{updateAccount(e.target.value, 'account')},
              }}
            />
          )
          input_fields.push(
            <CustomInput
              key="Token"
              labelText='Token'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                value: account.token || '',
                onChange: (e)=>{updateAccount(e.target.value, 'token')},
              }}
            />
          )
        } else {
          input_fields.push(
            <FormControl key="Inventory Sync" required fullWidth className={classes.customInput}>
              <InputLabel shrink>Inventory Sync</InputLabel>
              <Select
                  onChange={(e)=>{updateAccount(e.target.value, 'sync_inventory')}}
                  value={account.sync_inventory || 'NO'}
              >
                <MenuItem key="YES" value="YES">YES</MenuItem>
                <MenuItem key="NO" value="NO">NO</MenuItem>
              </Select>
            </FormControl>
          )
        } 
        break;
      case 'AMAZON':
        input_fields.push(
          <CustomInput
            key="SellerId"
            labelText='SellerId'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: account.account || '',
              onChange: (e)=>{updateAccount(e.target.value, 'account')},
            }}
          />
        );
        input_fields.push(
          <CustomInput
            key="AWS_ACCESS_KEY_ID"
            labelText='AWS_ACCESS_KEY_ID'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: account.user || '',
              onChange: (e)=>{updateAccount(e.target.value, 'user')},
            }}
          />
        );
        input_fields.push(
          <CustomInput
            key="AWS_SECRET_ACCESS_KEY"
            labelText='AWS_SECRET_ACCESS_KEY'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: account.password || '',
              onChange: (e)=>{updateAccount(e.target.value, 'password')},
            }}
          />
        );
        break;
      case 'WOOCOMMERCE':
        input_fields.push(
          <CustomInput
            key="Website"
            labelText='Website'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: account.account || '',
              onChange: (e)=>{updateAccount(e.target.value, 'account')},
            }}
          />
        );
        input_fields.push(
          <CustomInput
            key="Public Key"
            labelText='Public Key'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: account.user || '',
              onChange: (e)=>{updateAccount(e.target.value, 'user')},
            }}
          />
        );
        input_fields.push(
          <CustomInput
            key="Private Key"
            labelText='Private Key'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: account.password || '',
              onChange: (e)=>{updateAccount(e.target.value, 'password')},
            }}
          />
        );
        break;
    }

    return (
      <Grid container className={classes.root} spacing={2}>
        {input_fields}
        
        <CustomInput
            labelText='Vendor'
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: account.vendor || '',
              onChange: (e)=>{updateAccount(e.target.value, 'vendor')},
            }}
          />
      </Grid>
    );
  }
  function onSubmit() {
    let new_account = Object.assign({}, account);
    let err = '';  

    switch (new_account.carrier) {
      case "AMAZON":
        if (!new_account.account) err += 'SellerId is required. \n';
        if (!new_account.user) err += 'AWS_ACCESS_KEY_ID is required. \n';
        if (!new_account.password) err += 'AWS_SECRET_ACCESS_KEY is required.';
        break;
      case "WOOCOMMERCE":
        if (!new_account.account) err += 'Website is required. \n';
        if (!new_account.user) err += 'Public Key is required. \n';
        if (!new_account.password) err += 'Private Key is required.';
        break;
      case "SHOPIFY":
        if (!new_account.account) err += 'Account is required. \n';
        if (!new_account.token) err += 'Token is required. \n';
        break;
      default:
        if (!new_account.account) err += 'Account is required. \n';
    }

    if (err) dataContext.alert(err);
    else handleSubmit(new_account); // todo, decide if need to pass the formatted
  }

  let title = defaultAccount.id ? 'Edit Store Account' : 'Add Store Account';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}