import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select} from '@material-ui/core'

import {BasicDialog, Button} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function EditB2BPalletStatus(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit} = props;
  const [status, setStatus] = useState('');

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Status</InputLabel>
          <Select
              onChange={(e)=>{setStatus(e.target.value)}}
              value={status}
          >
            <MenuItem value='NEW'>NEW</MenuItem>
            <MenuItem value='PENDING LABEL'>PENDING LABEL</MenuItem>
            <MenuItem value='READY'>READY</MenuItem>
            <MenuItem value='RELEASED'>RELEASED</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';  

    if (!status) err += "Status is required. \n";
    if (err) dataContext.alert(err);
    else handleSubmit(status);
  }

  let title = 'Edit B2B Pallet Status';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}