import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, ButtonGroup, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  CardContainer,
  Button,
  NewDataTable,
  CustomInput,
  GridItem,
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'
import moment from "moment-timezone";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class PutawayTicket extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      ticket_locations: null,
      pallet_id: '',
    };

    document.title = "Put-away Ticket";
  }

  searchPallet = (e) => {
    e.preventDefault();
    const { pallet_id } = this.state;
    let err = '';
    if (!pallet_id) err += 'Pallet Id is required. \n';
    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/putawaytkt/${pallet_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true, ticket_locations: null});
    req.then(this.searchPalletSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  searchPalletSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    if (resp.data.length === 0) {
      this.context.alert("No result found!");
      return;
    }
    this.setState({ticket_locations: resp.data});
  }

  renderTicketTable = () => {
    const {ticket_locations} = this.state;
    if (!ticket_locations || ticket_locations.length == 0) return null;
 
    // On 2023-12-14, Stephanie Wertz requested to add total to Return ticket and putaway tiecket
    let rows = Array.from(ticket_locations);
    let total = {
      sku: 'Total quantity',
      UPC: '',
      category: '',
      location: '',
      quantity: 0,
    };
    for (let row of rows) {
      total.quantity += parseInt(row.quantity) ? parseInt(row.quantity) : 0
    }
    rows.push(total);

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
      },{
        key: 'upc',
        label: 'UPC',
        contentNoWrap: true,
      }, {
        key: 'category',
        label: 'Category',
        contentNoWrap: true,
      }, {
        key: 'location',
        label: 'Location',
        contentNoWrap: true,
      }, {
        key: 'quantity',
        label: 'Quantity',
        contentNoWrap: true,
      }, {
        key: 'backorder_quantity',
        label: 'BackOrderQuantity',
        contentNoWrap: true,
      }
    ];

    return (
      <GridItem xs={12}>
        <CardContainer>
         

          <ButtonGroup>
            <Button onClick={()=>{
                let file_name = "Put-away Ticket";
                let now = moment();
                file_name += now.format('YYYY_MM_DD_HH_mm_ss');
                utils.exportTableToCsv('table-export', file_name);
            }}>Export to CSV</Button>

            <Button onClick={()=>{
               utils.printTable({colSetting: columns, data: rows, dataHeader: `<div><span style="font-weight: bold;">Pallet ID:</span> ${this.state.pallet_id}</div>`});
            }}>Print</Button>

          </ButtonGroup>


          <NewDataTable
            rows={rows}
            noPagination
            id="table-export"
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }
  render() {
    const { classes } = this.props;
    const { loading, pallet_id } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container spacing={2}>
        {loadingBar}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.searchPallet}>
              <CustomInput
                labelText='Pallet Id'
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                labelProps={{
                  shrink: true
                }}
                inputProps={{
                  onChange: (e)=>{this.setState({pallet_id: e.target.value})},
                  value: pallet_id,
                }}
              />

              <Button type='submit' className={classes.button}>Search</Button>
            </form>
          </CardContainer>
        </GridItem>

        {this.renderTicketTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(PutawayTicket);
