import React from "react";
import PropTypes from "prop-types";
import { Menu, ChevronLeft } from "@material-ui/icons";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Hidden,
  makeStyles,
  withStyles
} from "@material-ui/core";
import cx from "classnames";

import headerStyle from "variables/styles/headerStyle.jsx";

import HeaderLinks from "./HeaderLinks";

const useStyles = makeStyles(headerStyle);

export default function Header({ ...props }) {
  const classes = useStyles();

  // display current username
  let username_area = null;
  if (localStorage.getItem('username')) {
    username_area = (
      <Typography variant="h6" style={{
        fontSize: "1.75rem", overflow: 'hidden',
        whiteSpace: 'nowrap', textOverflow: 'ellipsis',
      }} className={classes.title}>
        { 'Welcome, ' + localStorage.getItem('username')}
      </Typography>
    );
  }
  if (localStorage.getItem('fullname')) {
    username_area = (
      <Typography variant="h6" style={{
        fontSize: "1.75rem", overflow: 'hidden',
        whiteSpace: 'nowrap', textOverflow: 'ellipsis',
      }} className={classes.title}>
        { 'Welcome, ' + localStorage.getItem('fullname')}
      </Typography>
    );
  }

  let pcAppBarShift = cx({[classes.appBarShift]: props.sideBarControl.pcOpen});
  return (
    <React.Fragment>
      <Hidden key="pc" smDown>
        <AppBar
          // position="absolute"
          position="absolute"
          color="default" className={classes.appBar + " " + pcAppBarShift}>
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              style={props.sideBarControl.pcOpen ? {padding: ".25rem"} : {}}
              aria-label="open drawer"
              onClick={props.handleDesktopDrawerToggle}
            >
              {props.sideBarControl.pcOpen ? <ChevronLeft style={{fontSize: "2.5rem"}}/> : <Menu />}
            </IconButton>

            {username_area}

            <HeaderLinks/>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Hidden key="mobile" mdUp>
        <AppBar
          // position="absolute"
          position="absolute"
          color="default" className={classes.appBar}>
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleMobileDrawerToggle}
            >
              <Menu/>
            </IconButton>

            {username_area}

            <HeaderLinks/>
          </Toolbar>
        </AppBar>
      </Hidden>
    </React.Fragment>
  );
}
