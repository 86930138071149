import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, InputLabel, Select, FormControl, MenuItem, ButtonGroup, withStyles } from "@material-ui/core";
import {
  CardContainer,
  Button,
  CustomInput,
  NewDataTable,
  GridItem,
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash'
import moment from "moment-timezone";
import EditInboundPalletDialog from 'views/Dialogs/InboundPallet/EditInboundPallet'
import CreateNewInboundPalletDialog from 'views/Dialogs/InboundPallet/CreateNew'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class ManageInboundPallet extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      keyword: '',
      type: 'pallet_id',
      pallets: [],
      selected_pallets: {},
      edit_index: -1,
    };

    document.title = "Manage Inbound Pallet";
  }

  createNewInboundPallet = (quantity) => {
    // pass how many new pallets to create

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/asnpallet`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        pallet: quantity,
        client_name: localStorage.getItem('customer_name')
      },
    });
    this.setState({loading: true});
    req.then(this.createNewInboundPalletSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  createNewInboundPalletSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }

    switch (true) {
      case typeof data == 'string':
        utils.printPage(data);
        break;
      case Array.isArray(data):
        data.forEach((elem)=>utils.printPage(elem));
        break;
      case typeof data == 'object':
        for (let key in data) {
          utils.printPage(data[key]);
        }
        break;
    }

    this.context.snackDisplay("Create inbound pallet success.");
    this.setState({ dialog: '' });
  }
  searchPallets = (e) => {
    e.preventDefault();
    const { type, keyword } = this.state;
    let keywordFormat = utils.formatString(keyword, {multiline: true});
    let err = '';
    if (!type) err += 'Search type is required. \n';
    if (!keywordFormat)  err += 'Keyword is required. \n';
    if (err) {
      this.context.alert(err);
      return;
    }


    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/searchasnpallet`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        keyword: keywordFormat,
        type: type,
        keywordList: keywordFormat.split(',')
      },
    });
    this.setState({loading: true, 
      pallets: [],
      selected_pallets: {},
      edit_index: -1,
    });
    req.then(this.searchPalletsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  searchPalletsSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }

    if (resp.data.length === 0) {
      this.context.alert("Pallet data not found.");
      return;
    }
    this.setState({pallets: resp.data});
  }
  processPallet = ({station, notes}) => {
    let id_list = [];
    for (let pallet_id in this.state.selected_pallets) {
      if (this.state.selected_pallets[pallet_id]) id_list.push(pallet_id);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/manageasnpallet`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        pallet_id: id_list.join(','),
        status: 'PROCESSING',
        station: station,
        notes: notes,
      },
    });
    this.setState({loading: true});
    req.then(this.processPalletSuccess.bind(this, {station, notes})).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  processPalletSuccess = ({station, notes}, resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }

    let updated_pallets = this.state.selected_pallets;
    let new_pallet_list = [];
    for (let old_pallet of this.state.pallets) {
      if (updated_pallets[old_pallet.pallet_id]) {
        old_pallet.station = station;
        old_pallet.notes = notes;
        old_pallet.pallet_status = 'PROCESSING';
      }
      new_pallet_list.push(old_pallet);
    }
    this.context.snackDisplay("Update pallet statue success.");
    this.setState({ pallets: new_pallet_list, dialog: '' });
  }
  markPalletsProcessed = () => {
    let id_list = [];
    for (let pallet_id in this.state.selected_pallets) {
      if (this.state.selected_pallets[pallet_id]) id_list.push(pallet_id);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/manageasnpallet`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        pallet_id: id_list.join(','),
        status: 'PROCESSED',
        station: ''
      },
    });
    this.setState({loading: true});
    req.then(this.markPalletsProcessedSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  markPalletsProcessedSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }

    let updated_pallets = this.state.selected_pallets;
    let new_pallet_list = [];
    for (let old_pallet of this.state.pallets) {
      if (updated_pallets[old_pallet.pallet_id]) {
        old_pallet.pallet_status = 'PROCESSED';
      }
      new_pallet_list.push(old_pallet);
    }
    this.context.snackDisplay("Update pallet statue success.");
    this.setState({ pallets: new_pallet_list, dialog: '' });
  }

  renderDialog = () => {
    const { pallets, edit_index, dialog } = this.state;
    let editPallet = pallets[edit_index];

    switch (dialog) {
      case 'change_status':
        return <EditInboundPalletDialog
          handleSubmit={this.processPallet}
          handleClose={()=>{this.setState({dialog: ''})}}
        />;
      case 'create_new':
        return <CreateNewInboundPalletDialog
          handleSubmit={this.createNewInboundPallet}
          handleClose={()=>{this.setState({dialog: ''})}}
        />;
    }
  }
  renderPalletTable = () => {
    const {pallets, selected_pallets} = this.state;
    if (!pallets || pallets.length === 0) return null;

    // todo, might add sum of dim and weight, and export table

    let columns = [
      {
        key: 'pallet_id',
        label: 'Pallet Id',
        // render: (val, key, row, index)=>{
        //   return (<Button onClick={() => { this.setState({ dialog: 'change_status', edit_index: index }); }}>{val}</Button>);
        // },
      }, 
      {
        key: 'pallet_status',
        label: 'Status',
        contentNoWrap: true,
      },
      {
        key: 'shortname',
        label: 'Customer',
      }, 
      {
        key: 'station',
        label: 'Station',
        contentNoWrap: true,
      }, 
      {
        key: 'notes',
        label: 'Notes',
        contentNoWrap: true,
      }, 
      {
        key: 'created_dt',
        label: 'Created Date',
        contentNoWrap: true,
        render: utils.localizeDate
      },
      {
        key: 'modified_dt',
        label: 'Modified Date',
        contentNoWrap: true,
        render: utils.localizeDate
      },
    ];

    return (
      <GridItem xs={12}>
        <CardContainer>
          <ButtonGroup>
            <Button color="bxzDefault" variant="contained" onClick={()=>{
              if (_.isEmpty(selected_pallets)) {
                this.context.alert('No pallet selected.');
                return;
              }
              this.setState({ dialog: 'change_status' })
            }}>Process</Button>

            <Button color="bxzDefault" variant="contained" onClick={()=>{
              if (_.isEmpty(selected_pallets)) {
                this.context.alert('No pallet selected.');
                return;
              }
              this.context.confirm("Are you sure mark selected Pallet(s) as PROCESSED?", this.markPalletsProcessed);
            }}>Close</Button>

            <Button color="bxzDefault" variant="contained" onClick={()=>{
                let file_name = "B2B Pallet";
                let now = moment();
                file_name += now.format('YYYY_MM_DD_HH_mm_ss');
                // utils.exportTableToCsv('table-export', file_name);
                utils.exportArrayToCsv(pallets, columns, file_name);
            }}>Export to CSV</Button>
          </ButtonGroup>

          {/* <Button onClick={()=>{
            if (_.isEmpty(selected_pallets)) {
              this.context.alert('No pallet selected.');
              return;
            }
            this.setState({ dialog: 'change_status' })
          }}>Change Status</Button>

          <Button color="secondary" style={{marginLeft: '1rem'}} onClick={()=>{
            if (_.isEmpty(selected_pallets)) {
              this.context.alert('No pallet selected.');
              return;
            }

            this.context.confirm('Are you sure to remove selected pallet?', this.removePallets);
          }}>Remove Pallets</Button> */}

          <NewDataTable
            rows={pallets}
            rowSelection={{
              selected: selected_pallets, // since ajax table will not have sort by, data array index will always work for selection
              handleSelectedChange: (newSelected)=>this.setState({selected_pallets: newSelected}),
              uniqueID: 'pallet_id'
            }}
            noPagination
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }
  render() {
    const { classes } = this.props;
    const { loading, keyword, type } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container spacing={2}>
        {loadingBar}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.searchPallets}>
              {/* <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="type">Search Type</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>{this.setState({type: e.target.value})}}
                >
                  <MenuItem value='pallet_id'>Pallet Id</MenuItem>
                  <MenuItem value='sku'>SKU/UPC</MenuItem>
                  <MenuItem value='po'>PO</MenuItem>
                  <MenuItem value='record_inbound_id'>Record Inbound ID</MenuItem>
                  <MenuItem value='tracking'>Tracking</MenuItem>
                </Select>
              </FormControl> */}

              <CustomInput
                labelText='Pallet ID'
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                labelProps={{
                  shrink: true
                }}
                inputProps={{
                  onChange: (e)=>{this.setState({keyword: e.target.value})},
                  value: keyword,
                  placeholder: 'Use Linebreak to separate multiple keywords',
                  multiline: true,
                  rows: 3
                }}
              />

              <Button type='submit' className={classes.button}>Search</Button>

              <Button color="primary" onClick={()=>this.setState({dialog: 'create_new'})} style={{marginTop: '.5rem', float: "right"}}>Create New</Button>
            </form>
          </CardContainer>
        </GridItem>

        {this.renderDialog()}
        {this.renderPalletTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(ManageInboundPallet);
