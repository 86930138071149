import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import {Typography, Grid, InputLabel, Select, FormControl, withStyles, MenuItem } from "@material-ui/core"
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import EditPOItem from 'views/Dialogs/EditPOItems'
import { withAlert } from 'hoc'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import moment from 'moment';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    marginTop: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
});

class NewPO extends Component {
  static contextType = DataContext;

  static propTypes = {
    alert: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // search field input refs for uncontrolled 
    this.poKeyInput = null;
    this.poTypeInput = null;
    this.carrierInput = null;
    this.estimatedDateInput = null;
    this.trackingNumberInput = null;

    this.state = {
      loading: false,
      dialog: '',
      lineItems: [],
    };

    document.title = "New PO";
  }

  createPO = () => {
    let poKey = this.poKeyInput ? utils.formatString(this.poKeyInput.value) : '';
    let estimatedDate = this.estimatedDateInput ? this.estimatedDateInput.value : '';
    let poType = this.poTypeInput ? this.poTypeInput.value : 'inbound';
    let carrier = this.carrierInput ? this.carrierInput.value : '';
    let tracking = this.trackingNumberInput ? utils.formatString(this.trackingNumberInput.value) : '';
    const {lineItems} = this.state;

    let err = '';
    if (!poKey) err += "PO number is required.\n";
    if (!estimatedDate) err += "Estimated delivery date is required.\n";
    if (!tracking) err += "Tracking number is required.\n";
    if (lineItems.length === 0) err += "PO lineitems can not be empty.\n";
    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/newpo`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        po_key: poKey,
        tracking,
        estimated_delivery: estimatedDate,
        po_type: poType,
        carrier,
        item: lineItems
      },
    });
    this.setState({loading: true});
    req.then(this.createPOSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  createPOSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
   
    this.context.snackDisplay("Create PO success.");
    window.location.reload();
  }

  onLineItemQtyChange = (newQty, updateIndex) => {
    newQty = parseInt(newQty);
    if (!newQty || newQty < 1) return;

    let newItems = this.state.lineItems.map((prod, index)=>{
      if (updateIndex === index) prod.quantity = newQty;
      return prod;
    });
    this.setState({lineItems: newItems});
  }

  renderLineItemTable = () => {
    const {classes} = this.props;
    const {lineItems} = this.state;

    return (
      <GridItem xs={12}>
        <CardContainer>
          <Typography variant="h6" style={{marginBottom: '.5rem'}}>
            Step 1: Add Lineitem
            <div className={classes.headerToolBar}>
              <Button className={classes.buttonPushRight} onClick={()=>(this.setState({dialog: 'edit_line_items'}))}>Add Line Items</Button>
            </div>
          </Typography>
          
          <NewDataTable
            rows={lineItems}
            rowHeight={80} 
            noPagination
            columns={[
              {
                key: 'sku',
                label: 'SKU',
              },
              {
                key: 'item_name',
                label: 'Item Name',
              },
              {
                key: 'image',
                label: 'Image',
                render: (val, key, row)=>{
                  if (val) {
                    return (<img className={classes.tableDetailImage} alt="wrong url" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
                      this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}}/>, {type: row.sku || 'Product Image'});
                    }} src={val}/>);
                  } else {
                    return "No Image";
                  }
                }
              },
              {
                key: 'quantity',
                label: 'Quantity',
                render: (val, key, product, index)=>{
                  return (
                    <CustomInput
                      formControlProps={{
                        className: this.props.classes.customInput
                      }}
                      inputProps={{
                        type: 'number',
                        inputProps:{
                          min: 1,
                          step: 1
                        },
                        onChange: (e) => {
                          this.onLineItemQtyChange(e.target.value, index)
                        },
                        value: val,
                      }}
                    /> 
                  );
                },
              },
            ]}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderDialogs = () => {
    if (this.state.dialog === 'edit_line_items') {
      return (
        <EditPOItem
          lineItems={this.state.lineItems}
          handleSubmit={(newLineItems)=>{this.setState({lineItems: newLineItems, dialog: ''})}}
          handleClose={()=>{this.setState({dialog: ''})}}
        />
      );
    }
  }
  renderPODetailForm = () => {
    const {classes} = this.props;

    return (
      <GridItem xs={12} sm={6} md={4}>
        <CardContainer>
          <Typography variant="h6">Step 2: Enter PO detail</Typography>
          <CustomInput
            labelText="PO #"
            // tooltip="PO number should contain only string, number, hyphen and underscore."
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              inputRef: (el)=>{this.poKeyInput = el},
              placeholder: 'PO number should contain only string, number, hyphen and underscore',
              defaultValue: "",
            }}
          />

          <CustomInput
            labelText='Estimated delivery date'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              type: 'date',
              inputProps: {
                min: moment().format("YYYY-MM-DD"),
              },
              inputRef: (el)=>{this.estimatedDateInput = el},
              defaultValue: "",
            }}
          />

          {/* <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>PO Type</InputLabel>
            <Select
              defaultValue="inbound"
              inputRef={(el)=>{this.poTypeInput = el}}
            >
              <MenuItem value='inbound'>Inbound</MenuItem>
              <MenuItem value='crossdock'>Crossdock</MenuItem>
            </Select>
          </FormControl> */}

          <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Carrier</InputLabel>
            <Select
              defaultValue="other"
              inputRef={(el)=>{this.carrierInput = el}}
            >
              <MenuItem value='other'>Other</MenuItem>
              <MenuItem value='ups'>UPS</MenuItem>
              <MenuItem value='fedex'>FedEx</MenuItem>
              <MenuItem value='usps'>USPS</MenuItem>
              <MenuItem value='truck'>Truck</MenuItem>
              <MenuItem value='ECU Worldwide'>ECU Worldwide</MenuItem>
            </Select>
          </FormControl>

          <CustomInput
            labelText='Tracking #'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              inputRef: (el)=>{this.trackingNumberInput = el},
              defaultValue: "",
            }}
          />

          <div className={classes.toolBar}>
            <Button onClick={this.createPO}>Create PO</Button>
          </div>
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const {classes} = this.props; 
    const {loading} = this.state;

    return (
      <Grid className={classes.root} container spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        {this.renderDialogs()}
        {this.renderLineItemTable()}
        {this.renderPODetailForm()}
      </Grid>
    );
  }
}
export default withAlert(withStyles(styles)(NewPO));
