import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router'
import axios, {
  CancelToken
} from 'axios';
import cx from 'classnames'
import _ from "lodash";
import {
  withStyles,
  Table, TableHead, TableCell, TableBody, TableRow,
  Grid, Typography, IconButton, Divider, Paper, Card, CardHeader, CardContent, CardActionArea, Hidden
} from "@material-ui/core";
import {
  CardContainer,
  NewDataTable,
  GridItem,
} from "components";
import {
  Search,
} from "@material-ui/icons";
import DataContext from 'context/Data'
import moment from 'moment-timezone'
import utils from 'utils/utils'

const styles = theme => ({
  statContainer: {
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statL: {
    textAlign: 'right',
    width: 'calc(50% - 5px)'
  },
  statR: {
    textAlign: 'left',
    width: 'calc(50% - 5px)'
  },
  divider: {
    width: '10px'
  },
  cell_click: {
      cursor: 'pointer',
      minWidth: '80px',
      textAlign: 'center'
  },
  tbl_header: {
      minWidth: '80px',
      textAlign: 'center'
  },
  root: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  selectInput: {
      width: '100%',
      margin: '0 0 .625rem 0',
  },
  selectEmpty: {
      marginTop: theme.spacing(2),
  },
  table: {
  },
  title: {
      padding: '1rem',
  },
  tableContainer: {
      overflow: 'auto',
      margin: "25px 0"
  },
  centered: {
      textAlign: 'center'
  }
});

class WorkOrderDashboard extends React.Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.requests = {
      cancel_loadDB: null,
    };
    this.temp_data = {
      detail_customer_id: '',
      detail_customer_name: '',
      detail_category: '',
    };

    this.state = {
      loading: false,
      dashboard_data: [],
      updated_at: "",
      update_schedule: null,
      // update_interval_length: 300000
      update_interval_length: 1800000, // set to 30 mins interval for system performance
      single_customer: null,
    };

    document.title = "Work Order Dashboard";
  }

  componentWillMount() {
    this.loadDashboard();
  }
  componentWillUnmount() {
    clearTimeout(this.state.update_schedule);
    if (this.requests.cancel_loadDB) this.requests.cancel_loadDB();
  }

  loadDashboard = () => {
    let customer = localStorage.getItem('customer_id');
    let warehouse = localStorage.getItem('warehouse_id');
    if (!customer || !warehouse) return;  // if customer of warehouse is not set, don't make ajax call

    // Todo Don't allow auto refresh yet
    let cancel_control = this.requests;
    if (cancel_control.cancel_loadDB) cancel_control.cancel_loadDB();

    let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/workorderdashboard`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        cancel_control.cancel_loadDB = c;
      })
    }
    );

    req.then(this.LoadDashboardSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, snackDisplay: this.context.snackDisplay, errorCallback: ()=>{
      let update_schedule = setTimeout(this.loadDashboard, this.state.update_interval_length);
      this.setState({loading: false, update_schedule});
    }}));
    this.setState({loading: true, customerDetail: null});
  }
  LoadDashboardSuccess = (resp) => {
      this.setState({loading: false});
      if (resp.data.Error) {
        this.context.snackDisplay(resp.data.Error);
        // this.context.alert(resp.data.Error);
        return;
      }

      let update_schedule = setTimeout(this.loadDashboard, this.state.update_interval_length);

      let db = [];
      for (let customer_id in resp.data) {
        db.push(Object.assign({customer_id}, resp.data[customer_id]));
      }

      this.setState({
        dashboard_data: db,
        updated_at: moment().format("HH:mm"),
        update_schedule
      });
  }
  loadCustoemrDetail = (cellData, key) => {
    if (cellData[key] == 0 || cellData[key] == '0' || key == 'rowTotal' || cellData.customer_id == 'total') return;
    
    let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/workorderdashboard`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
          customer_id: cellData.customer_id,
          category: key
        }
      },
    );

    this.temp_data = {
      detail_customer_id: cellData.customer_id,
      detail_customer_name: cellData.customer_name,
      detail_category: key,
    };

    this.setState({ loading: true, customerDetail: null });

    req.then(this.loadCustoemrDetailSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadCustoemrDetailSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
        this.context.alert(resp.data.Error);
        return;
    }

    if (!resp.data || _.isEmpty(resp.data)) {
      this.context.alert("No data found!");
      return;
    }

    this.setState({single_customer: resp.data});
    utils.scrollToElement('customer_detail');
  }

  renderDashboard = () => {
    const {classes} = this.props;
    const { updated_at, dashboard_data } = this.state;
    if (dashboard_data.length == 0) return null;
    let update_time = updated_at ? ("Updated at " + updated_at) : "";
    let colSettings = [
      {
        key: 'customer_name',
        label: 'Customer',
      }
    ];
    for (let key in dashboard_data[0]) {
      if (key == 'customer_name' || key == 'customer_id') continue;
      colSettings.push({
        key: key,
        label: key,
        cellProps: {onClick: (val, key, rowData)=>{this.loadCustoemrDetail(rowData, key)}},
        render: (val, key, rowData) => {
          if (rowData.customer_id == 'total') return val ? parseFloat(val).toFixed(2) : val;
          return <span style={{ cursor: (val == 0 || val == '0')? 'default' : 'pointer'}}>{val ? parseFloat(val).toFixed(2) : val}</span>;
        }
      });
    }
    colSettings.push({
      key: 'rowTotal',
      label: 'Total',
      render: (val, key, rowData) => {
        let rowTotal = 0;
        for (let dataKey in rowData) {
          switch (dataKey) {
            case 'rowTotal':
            case 'customer_name':
            case 'customer_id':
              break;
            default:
              rowTotal += parseFloat(rowData[dataKey]);
          } 
        }
        return rowTotal == 0 ? rowTotal : rowTotal.toFixed(2);
      }
    });

    let rows = Array.from(dashboard_data);
    let colTotal = {
      customer_id: 'total',
      customer_name: "Total",
    };
    rows.forEach((elem=>{
      for (let dataKey in elem) {
        switch (dataKey) {
          case 'rowTotal':
          case 'customer_name':
          case 'customer_id':
            break;
          default:
            if (!colTotal[dataKey]) colTotal[dataKey] = 0;
            colTotal[dataKey] += parseFloat(elem[dataKey]);
        } 
      }
    }));
    rows.push(colTotal);
    return (
      <GridItem sm={12}>
        <CardContainer>
          <div>
            <div className={this.props.classes.title}>
                <Typography variant="h6">
                    {update_time}
                </Typography>
            </div>

            <NewDataTable
              rows={rows}
              columns={colSettings}
              noPagination
              className={classes.table}
              // maxHeight={500}
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderCustomerDetail = () => {
    const {classes} = this.props;
    const { single_customer } = this.state;
    if (!single_customer || single_customer.length == 0) return null;

    let colSettings = [
      {
          key: 'workorder_number',
          label: 'WorkOrderNumber',
          render: (val, key, rowData)=>{
            return (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ marginRight: '.5rem' }}>{val}</span>
                <Search style={{ cursor: 'pointer' }} title='Click to see work order detail' onClick={() => {
                   localStorage.setItem("ticketWorkOrder", val);
                   localStorage.setItem("ticketWorkOrderCustomer", this.temp_data.detail_customer_id);
                   this.props.history.push("/workOrder");
                }}>sss</Search>
              </div>
            );
          },
      },
      {
          key: 'type',
          label: 'Type',
          render: utils.tableCellOnDisplay,
      },
      // {
      //   key: 'status_type',
      //   label: 'Status',
      //   render: utils.tableCellOnDisplay,
      // },
      {
          key: 'sub_type',
          label: 'Sub Category',
          render: utils.tableCellOnDisplay,
      },
      {
          key: 'user',
          label: 'User',
          render: utils.tableCellOnDisplay,
      },
      {
          key: 'hours',
          label: 'Hours',
          render: (val)=>{
              return val ? val : 0
          },
      },
      {
          key: 'fix_amount',
          label: 'Fix Amount',
          render: utils.tableCellOnDisplay,
      },
      {
          key: 'notes',
          label: 'Notes',
          render: utils.tableCellOnDisplay,
          cellProps: {
              style: {minWidth: "120px", maxWidth: "300px",wordWrap: "break-word"}
          }
      },
      {
          key: 'workorder_date',
          label: 'WorkOrderDate',
          contentNoWrap: true,
          render: utils.localizeDate,
      },
    ];

    return (
      <GridItem xs={12} id="customer_detail">
        <CardContainer>
          <div className={classes.title}>
            <Typography variant="h5" id="tableTitle">
              {`${this.temp_data.detail_customer_name} ${this.temp_data.detail_category}`}
            </Typography>
          </div>

          <NewDataTable
            rows={single_customer}
            columns={colSettings}
            noPagination
            maxHeight={500}
            className={classes.table}
          />
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid className={classes.root} container spacing={2} >
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {this.renderDashboard()}
        {this.renderCustomerDetail()}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(WorkOrderDashboard));

