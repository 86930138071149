import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0)
  },
}))

export default function CreateUnplannedReturn(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit} = props;
  const [pallet, setPallet] = useState('');

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
          <CustomInput
            labelText='Pallet'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInputContainer,
            }}
            labelProps={{
                shrink: true,
            }}
            inputProps={{
              onChange: (e)=>setPallet(e.target.value),
              value: pallet,
            }}
          />
      </Grid>
    );
  }
  function onSubmit() {
    if (!pallet) {
      dataContext.alert("Pallet is required!");
      return;
    }
    handleSubmit(pallet);
  }

  let title = "Do you want to create a new unplan return?";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={(e, reason)=>{
        if (reason == 'backdropClick') return;
        if (reason == 'escapeKeyDown') return;
        handleClose();
      }}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}