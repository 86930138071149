import React from 'react';
// import Box from '@material-ui/core/Box';
// import Collapse from '@material-ui/core/Collapse';
import _ from 'lodash'
import cx from 'classnames'
import {Box, Collapse, IconButton, TableRow, TableCell, Checkbox} from '@material-ui/core'
import {
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  Add,
  Remove
} from "@material-ui/icons";

export default function EnhancedTableRow(props) {
  const {classes, row, index, rowSelection, onRowSelectChange, rowHeight, columns, rowSettings} = props;
  const [open, setOpen] = React.useState('');

  let cells = [];
     
  // row selection change should be passed in 

  if (rowSelection) {
    // if row selection turnned on, render select checkbox for each row
    let rowID = rowSelection.uniqueID ? row[rowSelection.uniqueID] : index; // the unique mark for selected is either specified id or row index
    if (row.noSelect) {
      cells.push(
        <TableCell key={'rowSelect'+rowID} style={{width: "50px"}}></TableCell>
      );
    }
    else {
      cells.push(
        <TableCell key={'rowSelect'+rowID} style={{width: "50px"}}>
          <Checkbox color={rowSelection.checkboxColor || 'secondary'} checked={!!rowSelection.selected[rowID]} onClick={(e)=>{onRowSelectChange(index, row, e)}} />
        </TableCell>
      );
    }
  }

  columns.forEach((column, colIndex)=>{
    let cellProps = {
      key: row.id + '_' + column.key,
    };
    if (column.cellProps) cellProps = Object.assign(cellProps, column.cellProps);
    cellProps.className = cx(cellProps.className || "", classes.td);
    if (cellProps.onClick) {
      let oldOnClick = cellProps.onClick;
      let newOnClick = () => {
        oldOnClick(row[column.key], column.key, row, index);
      }
      cellProps.onClick = newOnClick;
    }
    if (cellProps.onDoubleClick) {
      let oldOnDoubleClick = cellProps.onDoubleClick;
      let newOnDoubleClick = ()=>{
          oldOnDoubleClick(row[column.key], column.key, row, index);
      };
      cellProps.onDoubleClick = newOnDoubleClick;
    }

    let cellContent = row[column.key];
    if (column.render) {
      // Render func params: cell Value, cell key, row data object
      cellContent = column.render(cellContent, column.key, row, index);
    }
    if (_.isUndefined(cellContent)) cellContent = '';
    if (_.isFunction(column.cellPropsOnDisplay)) cellProps = column.cellPropsOnDisplay(cellProps, cellContent, column.key, row, index);
    let cellDimensionStyle = {
      // if row height set to 'auto', don't set max-height
      maxHeight: rowHeight === 'auto' ? 'none' : rowHeight+'px',
      display: rowHeight === 'auto' ? 'table' : '',
    };
    if (column.width) {
      if (column.width !== 'auto') { // if width is not set to auto, use the specified width
        cellDimensionStyle.maxWidth = column.width+'px';
        cellDimensionStyle.minWidth = column.width+'px';
      }
      // if row height set to 'auto', don't set max-height
    } else {
      // default column width to 250px
      // cellDimensionStyle.maxWidth = 250+'px';
      // On 2023-01-20, People want to show col width auto
    }
    // Todo, display table would mess up max height and width, need to find a way to fix it
    // cellDimensionStyle.display = 'table'; // don't mess up table display
    
    if (column.contentNoWrap) cellDimensionStyle.whiteSpace = 'nowrap'; 
    if (column.maxWidth) cellDimensionStyle.maxWidth = column.maxWidth+'px'; // if has max width, overwrite it
    let cellClasses = cx(classes.tableCell, '');
    if (cellContent) {
      cells.push(
        <TableCell key={column.key} align="center" {...cellProps}><div style={cellDimensionStyle} className={cellClasses}>
          {
            column.collapsible && column.renderCollapsedContent && <IconButton aria-label="expand row" size="small" onClick={() =>{
              if (open !== colIndex) setOpen(colIndex);
              else setOpen('');
          }}>
            {open === colIndex ? <Remove /> : <Add />}
          </IconButton>
          }
          {cellContent}
          </div>
        </TableCell>
      );
    } else {
      cells.push(
        <TableCell key={column.key} align="center" {...cellProps}><div style={cellDimensionStyle} className={cellClasses}>
          {
            column.collapsible && column.renderCollapsedContent && <IconButton aria-label="expand row" size="small" onClick={() =>{
              if (open !== colIndex) setOpen(colIndex);
              else setOpen('');
          }}>
            {open === colIndex ? <Remove /> : <Add />}
          </IconButton>
          }
          {cellContent}&nbsp;
          </div>
        </TableCell>
      );
    }
  });

  let rowProps = {
    hover: true,
    tabIndex: -1,
    key: row.id || index
  };
  if (rowSettings.classNameOnDisplay) rowProps = rowSettings.classNameOnDisplay(row, rowProps);
  if (rowSettings.rowProps) Object.assign(rowProps, rowSettings.rowProps);
  if (rowProps.onClick) rowProps.onClick = rowProps.onClick.bind(this, row, index);

  function renderCollapsed() {
    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={cells.length}>
          <Collapse in={open !== ''} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {(open !== '') && columns[open].renderCollapsedContent(row[columns[open]['key']], index, row)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <React.Fragment>
      <TableRow {...rowProps}>
        {cells}
      </TableRow>
      {renderCollapsed()}
    </React.Fragment>
  );

} 