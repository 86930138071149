import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
	display: 'flex',
	flexWrap: 'wrap',
  },
  nonSelectable: {
	userSelect: 'none'
  },
  formControl: {
	margin: theme.spacing(1),
	minWidth: 120,
  },
  tableImage: {
	height: '40px',
	width: '40px',
  },
  selectEmpty: {
	marginTop: theme.spacing(2),
  },
  table: {
	minWidth: 700,
  },
  customInput: {
	margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
	overflowX: 'auto',
  },
  title: {
	flex: '0 0 auto',
  },
  button: {
	marginTop: theme.spacing(1)
  },
  customInputContainer: {
	margin: '0 !important',
	padding: 0
  },
  customInputNoLabel: {
	marginTop: '0 !important'
  },
  customInputNoMargin: {
	margin: '0 !important',
	padding: 0
  },
  menuItemText: {
	whiteSpace: 'break-spaces',
	wordWrap: 'break-word',
	// ['@media (min-width:780px)']:
	[theme.breakpoints.down('md')]: {
	  maxWidth: "400px",
	},
	[theme.breakpoints.between('md', 'lg')]: {
	  maxWidth: "600px",
	},
	[theme.breakpoints.up('lg')]: {
	  // maxWidth: "700px",
	},
  },
});

class B2BPalletQA extends Component {
  static contextType = DataContext;

  static propTypes = {
	classes: PropTypes.object.isRequired,
  };

  constructor(props) {
	super(props);
	
	this.input_refs = {
	  b2b_pallet_id: null,
	  carton_id: null,
	};

	this.temp_data = {
	  b2b_pallet_id: ''
	};

	this.state = {
	  loading: false,
	  carton_list: null,
	};

	document.title = "OB Pallet QA";
  }

  resetPage = () => {
	if (this.input_refs.b2b_pallet_id) {
	  this.input_refs.b2b_pallet_id.value = '';
	  this.input_refs.b2b_pallet_id.focus();
	}

	this.temp_data = {
	  b2b_pallet_id: ''
	};

	this.setState({
	  carton_list: null,
	});
  }

  matchCarton = () => {
	let scanned_val = utils.formatString(this.input_refs.carton_id?.value); 
	if (!scanned_val) return;

	let new_cartons = Object.assign({}, this.state.carton_list);
	if (new_cartons[scanned_val])
	{
		if (new_cartons[scanned_val]['scanned'])
		{
			// if carton already scanned, don't allow to scan another time
			this.context.alert(`The this carton id ${scanned_val} has already been scanned.`);
			if (this.input_refs.carton_id) {
				this.input_refs.carton_id.value = '';
				this.input_refs.carton_id.focus();
			}  
		} else {
			new_cartons[scanned_val]['scanned'] = true;
			
			let finished = true;
			for (let carton_id in new_cartons)
			{
				if (!new_cartons[carton_id]['scanned'])
				{
					finished = false;
					break;
				}
			}
			if (finished)
			{
				this.submitPalletQA();
			}
			
			this.setState({carton_list: new_cartons});
			if (this.input_refs.carton_id) {
				this.input_refs.carton_id.value = '';
				this.input_refs.carton_id.focus();
			}  
		}
	} else {
		// Carton Id not matched
		this.context.alert(`The scanned carton ${scanned_val} is not in this B2B Pallet.`);
		if (this.input_refs.carton_id) {
			this.input_refs.carton_id.value = '';
			this.input_refs.carton_id.focus();
		}  
		return;
	}
  }
  handleCartonScan = _.debounce(this.matchCarton, 150);
  palletSearch = () => {
	let keyword = utils.formatString(this.input_refs.b2b_pallet_id.value);
	if (!keyword) return;

	let req = axios({
	  method: 'get',
	  url: `${utils.getBaseUrl('customer')}/b2bPalletQA/${keyword}`,
	  headers: {
		token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	});

	this.temp_data.b2b_pallet_id = '';

	this.setState({loading: true, carton_list: null});
	req.then(this.palletSearchSuccess.bind(this, keyword)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  palletSearchSuccess = (b2b_pallet_id, resp) => {
	this.setState({loading: false});
	let data = resp.data;
	if (data.Error) {
	  this.context.alert(data.Error);
	  return;
	}

	if (!data || data.length == 0) 
	{
	  this.context.alert("No data found for this B2B Pallet Id");
	  return;
	}

	this.temp_data.b2b_pallet_id = b2b_pallet_id; // save b2b pallet id being QA  

	let cartons = {};
	for (let carton of data) {
		cartons[carton.carton_id] = {
			carton_id: carton.carton_id,
			scanned: false,
		};
	}
  
	this.setState({carton_list: cartons});
  }
  palletIdScan = _.debounce(this.palletSearch, 150);

  submitPalletQA = () => {
	const {carton_list} = this.state;

	let err = '';
	
	let scanned_cartons = [];
	for (let carton_id in carton_list)
	{
		if (carton_list[carton_id].scanned) 
		{
			scanned_cartons.push(carton_id);
		}
	}

	if (scanned_cartons.length == 0)
	{
		err += 'Nothing scanned under this B2B pallet.';
	}

	if (err) {
	  this.context.alert(err);
	  return;
	}

	let req = axios({
	  method: 'post',
	  url: `${utils.getBaseUrl('customer')}/b2bPalletQA`,
	  headers: {
		token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	  data: {
		b2b_pallet_id: this.temp_data.b2b_pallet_id,
		cartons: scanned_cartons,
	  }
	});
	this.temp_data.cartons = [];
	this.setState({loading: true});
	req.then(this.submitPalletQASuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  submitPalletQASuccess = (resp) => {
	this.setState({loading: false});
	let data = resp.data;
	if (data.Error) {
	  this.context.alert(data.Error);
	  return;
	}

	this.context.snackDisplay("B2B Pallet QA finished.");
	this.resetPage();
  }
 
  renderCartonTable = () => {
	const { classes } = this.props;
	const { carton_list } = this.state;
	if (!carton_list) return null;
	let rows = [];
	for (let carton_id in carton_list) 
	{
		rows.push(carton_list[carton_id]);
	}

	let columns = [
	  {
		key: 'carton_id',
		label: 'Carton Id',
		render: (val, key, row) => {
			return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
		}
	  },
	  {
		key: 'scanned',
		label: 'Scanned',
		render: (val, key, row) => {
		  return val ? 'Yes' : 'No';
		}
	  },
	];

	let rowSettings = {
	  rowProps: {
		hover: false
	  },
	  classNameOnDisplay: (carton, displayProps) => {
		let color = '';
		
		if (carton['scanned'])
		{
			color = 'green';
		}
		
		let classes = displayProps.className || '';
		classes = classes.split(' ');
		classes.push(color);
		displayProps.className = classes.join(' ');
		return displayProps;
	  }
	};

	this.temp_data.schedule_focus_input = setTimeout(()=>{
        if (this.input_refs.carton_id) this.input_refs.carton_id.focus();
    }, 0);

	return (
	  <GridItem xs={12}>
		<CardContainer>
		  <div style={{marginBottom: '1rem'}} className={classes.title} >
			<CustomInput
			  labelText='Carton Id'
			  formControlProps={{
				fullWidth: false,
				style: { marginRight: '1rem' },
				className: classes.customInput
			  }}
			  labelProps={{
				shrink: true,
			  }}
			  inputProps={{
				defaultValue: '',
				onKeyUp: this.handleCartonScan,
				onPaste: (e)=>{
				  if (!utils.checkAllowCopy()) e.preventDefault();
				},
				inputRef: elem => this.input_refs.carton_id = elem
			  }}
			/>
		  </div>

		  <NewDataTable
			rows={rows}
			noPagination
			rowSettings={rowSettings}
			columns={columns}
		  />
		</CardContainer>  
	  </GridItem>
	);
  }
  renderQAForm = () => {
    const {classes} = this.props;
    const { carton_list } = this.state;
    if (!carton_list || !this.temp_data.b2b_pallet_id) 
	{
		return null;
	}

    return (
      <GridItem xs={12} sm={6}>
        <CardContainer>
          {/* <CustomInput
            labelText='QA Note'
            formControlProps={{
              fullWidth: true,
              className: classes.customInput,
              required: true,
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: this.state.qa_note,
              onChange: (e)=>this.setState({qa_note: e.target.value}),
            }}
          /> */}

          <Button onClick={()=>{
            this.context.confirm("Are you sure to submit B2B Pallet QA?", this.submitPalletQA);
          }} className={classes.button}>Submit B2B Pallet QA</Button>
        </CardContainer>
      </GridItem>
    );
  }

  render() {
	const { classes, carton_list } = this.props;

	if (!carton_list || carton_list.length == 0)
	this.temp_data.schedule_focus_input = setTimeout(()=>{
		if (!carton_list || carton_list.length == 0) {
			if (this.input_refs.b2b_pallet_id) this.input_refs.b2b_pallet_id.focus();
		} else {
			if (this.input_refs.carton_id) this.input_refs.carton_id.focus();
		}
    }, 0);

	return (
	  <Grid container spacing={2}>
		{this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
	  
		<GridItem xs={12} sm={6}>
		  <CardContainer>
			<CustomInput
			  labelText='B2B Pallet Id'
			  formControlProps={{
				fullWidth: true,
				required: true,
				className: classes.customInput,
			  }}
			  labelProps={{
				shrink: true,
			  }}
			  inputProps={{
				defaultValue: '',
				onKeyUp: this.palletIdScan,
				inputRef: elem => this.input_refs.b2b_pallet_id = elem
			  }}
			/>
		  </CardContainer>
		</GridItem>
 
		{this.renderQAForm()}
		{this.renderCartonTable()}   
	  </Grid>
	);
  }
}
export default withStyles(styles)(B2BPalletQA);