import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Typography, FormControlLabel, Switch, withStyles, IconButton, MenuItem, FormControl, Select } from "@material-ui/core";
import axios from 'axios';
import utils from 'utils/utils';
import _ from 'lodash'
import {
  Add,
  DeleteForever
} from '@material-ui/icons';
import {
  NewDataTable,
  CardContainer,
  CustomInput,
} from 'components';
import DataContext from 'context/Data'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class BillingAgreement extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    agreements: PropTypes.object.isRequired,
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    keys: PropTypes.array.isRequired,
    onAgreementsChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.edit_input = null;
    let required_agreements = {};
    for (let option of props.options) {
        if (option.required && parseInt(option.required) === 1) required_agreements[option.type] = true;
    }
    this.required_agreements = required_agreements;

    this.state = {
      loading: false,
      edit: {},
      type: '',
      category: '',
      quickbooks_desc: '',
      cost: '',
      quantity: 1,
      unit: 'unit',
      notes: '',
    };
  }

  render() {
    return (
      <div>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Manage Billing Agreements
        </Typography>

        {this.renderAgreementTable()}
      </div>
    );
  }

  newAgreement = () => {
    const { type, category, cost, unit, notes, quickbooks_desc, quantity } = this.state;
    let err = '';
    if (!type) err += 'Agreement Type is required. \n';
    if (!category) err += 'Category is required. \n';
    if (!cost) err += 'Cost is required. \n';
    else if (!parseFloat(cost) || parseFloat(cost) < 0)  err += 'Cost should be a positive number.\n';
    let qty = parseInt(quantity);
    if (!qty || qty < 0) err += 'Quantity should be a positive integer.\n';
    if (err) {
      this.context.alert(err);
      return;
    }

    let customer_id = this.props.customer_id;
    let newAgreement = {
      customer_id,
      type,
      category,
      cost: parseFloat(cost),
      quantity: qty,
      notes,
      quickbooks_desc,
      unit,
      active: 1,
    };

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/agreement`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: newAgreement,
    });

    this.setState({loading: true});
    req.then(this.newAgreementSuccess.bind(this, newAgreement)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newAgreementSuccess = (newAgreement, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    if (!resp.data || resp.data.length === 0 || !resp.data[0].agreement_id) {
      this.context.alert("Add agreement error");
      return;
    }

    newAgreement.agreement_id = resp.data[0].agreement_id;
    const { agreements, keys, onAgreementsChange } = this.props;
    let new_agreements = Object.assign({}, agreements);
    let newKeys = Array.from(keys);

    // add new type to key list
    newKeys.push(newAgreement.type);
    new_agreements[newAgreement.type] = newAgreement;

    // clear input
    this.setState({type: '', category: '', cost: '', quantity: 1, unit: 'unit', notes: '', quickbooks_desc: ''});
    onAgreementsChange(new_agreements, newKeys);
  }
  deleteAgreement = (type, index) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/agreement/${this.props.agreements[type]['agreement_id']}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteAgreementSuccess.bind(this, type, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteAgreementSuccess = (type, index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    
    let new_agreements = Object.assign({}, this.props.agreements);
    let newKeys = Array.from(this.props.keys);
    // delete the agreement from the object
    delete new_agreements[type];
    // find the delete index, and update the key list
    let deleteIndex = index;
    if (newKeys[index] !== type) {
        let searching = true;
        let i = 0;
        do {
            if (newKeys[index] === type) {
                deleteIndex = i;
                searching = false;
            }
            i++;
        } while(i < newKeys.length && searching)
    }

    // delete it
    newKeys.splice(deleteIndex, 1);
    this.props.onAgreementsChange(new_agreements, newKeys);
  }
  updateAgreement = (newVal, field, type) => {
    let newAgreement = this.props.agreements[type];
    if (newAgreement) {
        newAgreement[field] = newVal;
    } else {
        console.log("update agreement failed, can't find an agreement with this type. May need to change the code to use agreement_id as the key instead of type");
        return;
    }

    let err = '';
    if (!parseFloat(newAgreement.cost) || parseFloat(newAgreement.cost) <= 0) {
        err += 'Cost should be a positive number.\n';
    }
    newAgreement.cost = parseFloat(newAgreement.cost);
    let qty = parseInt(newAgreement.quantity);
    if (!qty || qty <= 0) {
        err += 'Quantity should be a positive integer.\n';
    }

    if (err) {
        this.context.alert(err);
        return;
    }

    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/agreement`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        agreement_id: newAgreement.agreement_id,
        active: newAgreement.active,
        type: newAgreement.type,
        category: newAgreement.category,
        notes: newAgreement.notes,
        quickbooks_desc: newAgreement.quickbooks_desc,
        quantity: qty,
        cost: parseFloat(newAgreement.cost),
        unit: newAgreement.unit,
      },
    });

    this.setState({loading: true});
    req.then(this.updateAgreementSuccess.bind(this, newAgreement)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateAgreementSuccess = (newAgreement, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    if (!resp.data) {
      this.context.alert("Update agreement error");
      return;
    }
    let new_agreements = Object.assign({}, this.props.agreements);
    new_agreements[newAgreement.type] = newAgreement;

    this.props.onAgreementsChange(new_agreements);
  }

  renderAgreementTable = () => {
    const { classes, agreements, options } = this.props;
    const { edit, type, category, cost, quantity, unit, notes, quickbooks_desc } = this.state;

    let rows = [];
    let required = [];
    let optional = [];
    for (let type in agreements) {
      if (this.required_agreements[type]) required.push(agreements[type]);
      else optional.push(agreements[type]);
    }
    rows = required.concat(optional);
    rows.push({addNew: true});
   
    return (
      <CardContainer>
        <NewDataTable
          rows={rows}
          noPagination
          rowHeight='auto'
          maxHeight={500}
          columns={[
            {
              key: 'type',
              label: 'Type',
              contentNoWrap: true,
              width: 250,
              render: (val, key, row) => {
                if (row.addNew) {
                    return (
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <FormControl required fullWidth className={classes.selectInput}>
                                <Select
                                    value={type}
                                    onChange={(e)=>{this.setState({type: e.target.value})}}
                                    inputProps={{
                                        name: 'type',
                                        id: 'type',
                                    }}
                                >
                                    {options.filter((elem)=>{
                                        return !agreements[elem.type];
                                    }).map((elem)=>{
                                        // the option list contains only a type field
                                        return (<MenuItem key={elem.type} value={elem.type}>{elem.type}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    );
                } else {
                    return val ? val : '';
                }
              }
            },
            {
              key: 'category',
              label: 'Category',
              cellProps: {
                style: {
                    cursor: 'pointer',
                },
                title: 'Double click to edit',
                onDoubleClick: (val, key, row, index)=>{
                    if (row.addNew) return;
                    this.setState({
                        edit: {
                            key: row.type,
                            field: 'category',
                        }}
                    );
                }
              },
              render: (val, key, row) => {
                if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <Select
                                  value={category}
                                  onChange={(e)=>{this.setState({category: e.target.value})}}
                                  inputProps={{
                                      name: 'category',
                                      id: 'category',
                                  }}
                              >
                                  <MenuItem value='B2C'>B2C</MenuItem>
                                  <MenuItem value='B2B'>B2B (included EDI)</MenuItem>
                                  <MenuItem value='EDI'>EDI</MenuItem>
                                  <MenuItem value='ALL'>ALL</MenuItem>
                              </Select>
                          </FormControl>
                      </div>
                  );
                }

                if (edit.key === row.type && edit.field === 'category') {
                    _.delay(()=>{
                      this.edit_input.focus();
                    }, 100);
                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                            <Select
                              defaultValue={val ? val : ''}
                              native={true}
                              inputProps={{
                                name: 'category',
                                id: 'category',
                                inputRef: (elem)=>{this.edit_input = elem},
                                onBlur: ()=>{
                                  let newVal = this.edit_input.value;
                                  this.updateAgreement(newVal, 'category', row.type);
                                  this.setState({edit: {}});
                                },
                              }}
                            >
                              <option value='B2C'>B2C</option>
                              <option value='B2B'>B2B (included EDI)</option>
                              <option value='EDI'>EDI</option>
                              <option value='ALL'>ALL</option>
                            </Select>
                          </FormControl>
                        </div>
                    );
                } else {
                    return val ? val : '';
                }
              }
            },
            {
              key: 'cost',
              label: 'Cost',
              cellProps: {
                style: {
                    cursor: 'pointer',
                },
                title: 'Double click to edit',
                onDoubleClick: (val, key, row, index)=>{
                    if (row.addNew) return;
                    this.setState({
                        edit: {
                            key: row.type,
                            field: 'cost',
                        }});
                }
              },
              render: (val, key, row) => {
                if (row.addNew) {
                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <CustomInput
                                labelText=''
                                formControlProps={{
                                    fullWidth: false,
                                    className: classes.customInput
                                }}
                                labelProps={{
                                    shrink: false,
                                }}
                                inputProps={{
                                    style:{marginTop: '0'},
                                    onChange: (e)=>{this.setState({cost: e.target.value})},
                                    value: cost
                                }}
                            />
                        </div>
                    );
                }

                if (edit.key === row.type && edit.field === 'cost') {
                    _.delay(()=>{
                      this.edit_input.focus();
                    }, 100);
                    return (
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <CustomInput
                                labelText=''
                                formControlProps={{
                                    fullWidth: false,
                                    className: classes.customInput
                                }}
                                labelProps={{
                                    shrink: false,
                                }}
                                inputProps={{
                                    style:{marginTop: '0'},
                                    defaultValue: val,
                                    inputRef: (elem)=>{this.edit_input = elem},
                                    onBlur: ()=>{
                                        let newVal = this.edit_input.value;
                                        this.updateAgreement(newVal, 'cost', row.type);
                                        this.setState({edit: {}});
                                    },
                                }}
                            />
                        </div>
                    );
                } else {
                    return val ? val : 0;
                }
              }
            },
            {
                key: 'unit',
                label: 'Unit',
                cellProps: {
                  style: {
                      cursor: 'pointer',
                  },
                  title: 'Double click to edit',
                  onDoubleClick: (val, key, row, index)=>{
                      if (row.addNew) return;
                      this.setState({
                          edit: {
                              key: row.type,
                              field: 'unit',
                          }}
                      );
                  }
              },
              render: (val, key, row) => {
                  if (row.addNew) {
                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <FormControl required fullWidth className={classes.selectInput}>
                                  <Select
                                      value={unit}
                                      onChange={(e)=>{this.setState({unit: e.target.value})}}
                                      inputProps={{
                                          name: 'unit',
                                          id: 'unit',
                                      }}
                                  >
                                      <MenuItem value='order'>order</MenuItem>
                                      <MenuItem value='unit'>unit</MenuItem>
                                      <MenuItem value='week'>week</MenuItem>
                                      <MenuItem value='carton'>carton</MenuItem>
                                      <MenuItem value='sku'>sku</MenuItem>
                                  </Select>
                              </FormControl>
                          </div>
                      );
                  }

                  if (edit.key === row.type && edit.field === 'unit') {
                      _.delay(()=>{
                        this.edit_input.focus();
                      }, 100);
                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <FormControl required fullWidth className={classes.selectInput}>
                                  <Select
                                      defaultValue={val ? val : ''}
                                      native={true}
                                      inputProps={{
                                          name: 'unit',
                                          id: 'unit',
                                          inputRef: (elem)=>{this.edit_input = elem},
                                          onBlur: ()=>{
                                              let newVal = this.edit_input.value;
                                              this.updateAgreement(newVal, 'unit', row.type);
                                              this.setState({edit: {}});
                                          },
                                      }}
                                  >
                                      <option value='order'>order</option>
                                      <option value='unit'>unit</option>
                                      <option value='week'>week</option>
                                      <option value='carton'>carton</option>
                                      <option value='sku'>sku</option>
                                  </Select>
                              </FormControl>
                          </div>
                      );
                  } else {
                      return val ? val : '';
                  }
              }
            },
            {
              key: 'quantity',
              label: 'Quantity',
              cellProps: {
                style: {
                    cursor: 'pointer',
                },
                title: 'Double click to edit',
                onDoubleClick: (val, key, row, index)=>{
                    if (row.addNew) return;
                    this.setState({
                        edit: {
                            key: row.type,
                            field: 'quantity',
                        }});
                }
              },
              render: (val, key, row) => {
                  if (row.addNew) {
                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <CustomInput
                                  labelText=''
                                  formControlProps={{
                                      fullWidth: false,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: false,
                                  }}
                                  inputProps={{
                                      style:{marginTop: '0'},
                                      onChange: (e)=>{this.setState({quantity: e.target.value})},
                                      value: quantity,
                                      type: 'number',
                                      inputProps:{
                                          min: 0,
                                          step: 1
                                      }
                                  }}
                              />
                          </div>
                      );
                  }

                  if (edit.key === row.type && edit.field === 'quantity') {
                      _.delay(()=>{
                        this.edit_input.focus();
                      }, 100);
                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <CustomInput
                                  labelText=''
                                  formControlProps={{
                                      fullWidth: false,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: false,
                                  }}
                                  inputProps={{
                                      style:{marginTop: '0'},
                                      defaultValue: val,
                                      inputRef: (elem)=>{this.edit_input = elem},
                                      onBlur: ()=>{
                                          let newVal = this.edit_input.value;
                                          this.updateAgreement(newVal, 'quantity', row.type);
                                          this.setState({edit: {}});
                                      },
                                      type: 'number',
                                      inputProps:{
                                          min: 0,
                                          step: 1
                                      }
                                  }}
                              />
                          </div>
                      );
                  } else {
                      return val ? val : 0;
                  }
              }
            },
            {
              key: 'notes',
              label: 'Notes',
              cellProps: {
                style: {
                    cursor: 'pointer',
                },
                title: 'Double click to edit',
                onDoubleClick: (val, key, row, index)=>{
                    if (row.addNew) return;
                    this.setState({
                        edit: {
                            key: row.type,
                            field: 'notes',
                        }});
                }
              },
              render: (val, key, row) => {
                  if (row.addNew) {
                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <CustomInput
                                  labelText=''
                                  formControlProps={{
                                      fullWidth: false,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: false,
                                  }}
                                  inputProps={{
                                      style:{marginTop: '0'},
                                      onChange: (e)=>{this.setState({notes: e.target.value})},
                                      value: notes
                                  }}
                              />
                          </div>
                      );
                  }

                  if (edit.key === row.type && edit.field === 'notes') {
                      _.delay(()=>{
                        this.edit_input.focus();
                      }, 100);

                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <CustomInput
                                  labelText=''
                                  formControlProps={{
                                      fullWidth: false,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: false,
                                  }}
                                  inputProps={{
                                      style:{marginTop: '0'},
                                      defaultValue: val ? val : '',
                                      inputRef: (elem)=>{this.edit_input = elem},
                                      onBlur: ()=>{
                                          let newVal = this.edit_input.value;
                                          this.updateAgreement(newVal, 'notes', row.type);
                                          this.setState({edit: {}});
                                      },
                                  }}
                              />
                          </div>
                      );
                  } else {
                      return val ? val : '';
                  }
              }
            },
            {
              key: 'quickbooks_desc',
              label: 'QuickBooks Description',
              cellProps: {
                style: {
                    cursor: 'pointer',
                },
                title: 'Double click to edit',
                onDoubleClick: (val, key, row, index)=>{
                    if (row.addNew) return;
                    this.setState({
                        edit: {
                            key: row.type,
                            field: 'quickbooks_desc',
                        }});
                }
              },
              render: (val, key, row) => {
                  if (row.addNew) {
                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <CustomInput
                                  labelText=''
                                  formControlProps={{
                                      fullWidth: false,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: false,
                                  }}
                                  inputProps={{
                                      style:{marginTop: '0'},
                                      onChange: (e)=>{this.setState({quickbooks_desc: e.target.value})},
                                      value: quickbooks_desc
                                  }}
                              />
                          </div>
                      );
                  }

                  if (edit.key === row.type && edit.field === 'quickbooks_desc') {
                      _.delay(()=>{
                        this.edit_input.focus();
                      }, 100);

                      return (
                          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <CustomInput
                                  labelText=''
                                  formControlProps={{
                                      fullWidth: false,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: false,
                                  }}
                                  inputProps={{
                                      style:{marginTop: '0'},
                                      defaultValue: val ? val : '',
                                      inputRef: (elem)=>{this.edit_input = elem},
                                      onBlur: ()=>{
                                          let newVal = this.edit_input.value;
                                          this.updateAgreement(newVal, 'quickbooks_desc', row.type);
                                          this.setState({edit: {}});
                                      },
                                  }}
                              />
                          </div>
                      );
                  } else {
                      return val ? val : '';
                  }
              }
            },
            // {
            //   key: 'action',
            //   label: 'Action',
            //   render: (val, key, row, index) => {
            //     if (row.addNew) {
            //       return (
            //         <IconButton size="small" onClick={this.newAgreement} variant="fab" aria-label="Add" className={classes.button}>
            //           <Add />
            //         </IconButton>
            //       );
            //     } else if (!this.required_agreements[row.type]) { // only allow delete for not required agreements
            //       return (
            //         <IconButton size="small" onClick={()=>{
            //           this.context.confirm("Are you sure to delete this billing agreement?", this.deleteAgreement.bind(this, row.type, index));
            //         }} color='secondary' variant="fab" aria-label="Delete" className={classes.button}>
            //           <DeleteForever />
            //         </IconButton>
            //       );
            //     } else return '';
            //   }
            // }
            {
              key: 'active',
              label: 'Active',
              render: (val, key, row, index) => {
                if (row.addNew) {
                  return (
                    <IconButton size="small" onClick={this.newAgreement} variant="fab" aria-label="Add" className={classes.button}>
                      <Add />
                    </IconButton>
                  );
                } else {
                  return (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                      <FormControl required fullWidth className={classes.selectInput}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={(parseInt(val) == 1) ? true : false}
                                color="primary"
                                onChange={(e)=>{
                                  let new_val = e.target.checked;
                                  new_val = new_val ? 1 : 0;
                                  this.updateAgreement(new_val, 'active', row.type)
                                }}
                              />
                            }
                            label={(parseInt(val) == 1) ? 'Active' : 'Inactive'}
                          />
                          

                          {/* <Select
                            defaultValue={''}
                            value={val}
                            onClose={()=>{

                            }}
                            onChange={(e)=>{
                              e.preventDefault();
                              let new_val = e.target.value;
                              this.updateAgreement(new_val, 'active', row.type)
                              // this.context.confirm(`Are you sure to mark this billing agreement ${parseInt(new_val) == 1 ? 'Active' : 'In-active'}?`, this.updateAgreement.bind(this, new_val, 'active', row.type));
                              // if (new_val !== row.active) {
                              //   // if active has been changed, confirm and call backend
                              //   this.context.cofirm(`Are you sure to mark this billing agreement ${parseInt(new_val) == 1 ? 'Active' : 'In-active'}?`, this.updateAgreement.bind(this, new_val, 'active', row.type));
                              // }
                            }}
                          >
                              <MenuItem value='1'>Active</MenuItem>
                              <MenuItem value='0'>In-active</MenuItem>
                          </Select> */}
                      </FormControl>
                    </div>
                  );
                };
              }
            }
          ]}
        />
    </CardContainer>
    );
  }
}
export default withStyles(styles)(BillingAgreement);
