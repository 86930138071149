import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import {CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function EditLocationSize(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit} = props;

  const [location,setLocation] = useState({
    min: '',
    max: '',
    category: '',
    customer: ''
  });

  function updatelocation(val, key) {
    let new_location = Object.assign({}, location);
    let regex = /[^a-zA-Z]/g;
    if (key == 'category') {
      val = val.replace(regex, "").trim();
    }
    new_location[key] = val;
    setLocation(new_location);
  }

  let customer_detail = JSON.parse(localStorage.getItem('customer_detail'));
  let warehouse_id = localStorage.getItem('warehouse_id');
  let customer_list = customer_detail[warehouse_id];

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <CustomInput
          labelText='Min'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
              shrink: true,
          }}
          inputProps={{
            type: 'number', 
            inputProps:{
              min: 1,
              step: 1
            },
            onChange: (e)=>{updatelocation(e.target.value, 'min')},
            value: location.min 
          }}
        />

        <CustomInput
          labelText='Max'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
              shrink: true,
          }}
          inputProps={{
            type: 'number', 
            inputProps:{
              min: 1,
              step: 1
            },
            onChange: (e)=>{updatelocation(e.target.value, 'max')},
            value: location.max 
          }}
        />

        <CustomInput
          labelText='Category'
          formControlProps={{
            fullWidth: true,
            // required: true,
            className: classes.customInput
          }}
          labelProps={{ shrink: true }}
          inputProps={{
            placeholder: 'only letters allowed',
            value: location.category,
            onChange: (e)=>{updatelocation(e.target.value, 'category')},
          }}
        />

        <FormControl fullWidth className={classes.formControl}>
          <InputLabel shrink>Customer</InputLabel>
          <Select
            value={location.customer}
            onChange={(e)=>{updatelocation(e.target.value, 'customer')}}
          >
            <MenuItem value="">Select...</MenuItem>
            {customer_list.map((val)=><MenuItem key={val.customer_id} value={val.customer_id}>{val.shortname}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  function onSubmit() {
    let { min, max, category } = location;
    let err = '';  

    if (!min || min < 1) err += "Min is required and should be >= 1. \n";
    if (!max || max < 1) err += "Max is required and should be >= 1. \n";

    // if (!category) err += "Category is required.";

    if (err) dataContext.alert(err);
    else handleSubmit(location); // todo, decide if need to pass the formatted
  }

  let title = 'Edit Location Size';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}