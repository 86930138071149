import React from 'react';
import PropTypes from 'prop-types';
// Todo may add auto suggest later
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { MenuItem, Paper, TextField, withStyles } from '@material-ui/core';

// function to highlight the matched part
function renderInput(label, inputProps) {
  const { classes, ref, ...other } = inputProps;
  if (!label) label = '';
  return (
    <TextField
      label={label}
      fullWidth
      InputLabelProps={{shrink: true, className: classes.inputLabel}}
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 5,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    minHeight: '200px',
    maxHeight: '500px',
    overflowY: 'auto',
    listStyleType: 'none',
  },
  inputLabel: {
    fontWeight: "bold"
  }
});

class InputWithSuggestion extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    suggestions: PropTypes.array.isRequired,
    filterSuggestions: PropTypes.func,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    onSelected: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
    optionProps: PropTypes.object,
  };

  static defaultProps = {
    inputProps: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      value: (this.props.defaultValue ? this.props.defaultValue : ''),
      suggestions: [],
    };
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    let optionProps = {
      selected: isHighlighted,
      component: 'div'
    };
    if (this.props.optionProps) {
      optionProps = Object.assign(optionProps, this.props.optionProps)
    }

    return (
      <MenuItem {...optionProps}>
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 'bold' }}>
              {part.text}
            </span>
            ) : (
              <span key={String(index)}>
                            {part.text}
                        </span>
            );
          })}
        </div>
      </MenuItem>
    );
  }

  getSuggestions = (value) => {
    // if custom filter is passed in, use it
    if (this.props.filterSuggestions) {
      return this.props.filterSuggestions(value, this.props.suggestions);
    }

    // if no custom filter given, use default one
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : this.props.suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  }

  handleSelected = (e, data) => {
    this.props.onSelected(data.suggestion);
  }

  renderInput = (inputProps) => {
    return renderInput(this.props.label, inputProps);
  }

  render() {
    const { classes, inputProps, label } = this.props;

    let iProps = Object.assign({
      classes: classes,
      placeholder: '',
      value: this.state.value,
      onChange: this.handleChange,
    }, inputProps);

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSelected}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={iProps}
      />
    );
  }
}

export default withStyles(styles)(InputWithSuggestion);
