import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';
import moment from 'moment-timezone'

export default function withTimer(WrappedComponent) {
  class ComponentWithTimer extends Component {
    constructor(props) {
        super(props);
   
        this.time_count_interval = null;
   
        this.state = {
          // start_time: moment(),
          // current_time: moment(),
          time_counter: 0,
        };
    }

    startTimer = () => {
      // this.setState({start_time: moment()});
      // this.time_count_interval = setInterval(()=>{
      //   this.setState({current_time: moment()});
      // }, 1000);
      this.setState({time_counter: 0});
      if (this.time_count_interval) {
        clearInterval(this.time_count_interval);
      }
      
      this.time_count_interval = setInterval(()=>{
        this.setState({ time_counter: this.state.time_counter + 1 });
      }, 1000);
    }
    getTimer = () => {
      return this.state.time_counter;
    }
    getDuration = (diff) => {
      // Todo move this back when change to moment time


      // return this.state.current_time.diff(this.state.start_time, diff || 'seconds');
      // return new Date(timer.current_time.diff(timer.start_time, 'seconds') * 1000).toISOString().substring(11, 19);
    }

    componentWillUnmount() {
      clearInterval(this.time_count_interval);
    }

    render() {
        return <WrappedComponent timer={{start: this.startTimer, get: this.getTimer, duration: this.getDuration}} {...this.props}/>;
    }
  }
  ComponentWithTimer.displayName = `withTimer(${getDisplayName(WrappedComponent)})`;

  return ComponentWithTimer;
}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}