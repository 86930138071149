import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  CardContainer,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import { withAlert } from '../../hoc';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class EmployeeClockIn extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    alert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.input_refs = {
      user: null
    };

    this.state = {
      loading: false,
      type: '',
    };

    document.title = "Clock In/Out";
  }

  userScan = () => {
    const { type } = this.state;
    let user = '';
    if (this.input_refs.user) user = this.input_refs.user.value;
    if (!user) return;
    if (!type) {
      this.props.alert('Type is required!');
      return;
    }

    this.submitOnce({type, user});
  }
  handleScan = _.debounce(this.userScan, 250);

  submitAjax = (req_data) => {
    if (!req_data) req_data = {};
    if (!req_data.user) req_data.user = '';

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('')}/clockin`,
      data: req_data,
    });

    this.setState({loading: true});
    req.then(this.submitSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.props.alert, errorCallback: this.resetSubmitOnce}));
  }
  submitOnce = _.once(this.submitAjax);
  resetSubmitOnce = () => {this.submitOnce = _.once(this.submitAjax);} // reset the submit picking once after the ajax call returns
  submitSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (this.input_refs.user) {
      this.input_refs.user.value = '';
      this.input_refs.user.focus();
    }
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }
  }

  render() {
    const { classes } = this.props;
    const { type } = this.state;

    let action_text = '';
    switch (type) {
      case 'CLOCKIN':
        action_text = ' (Clock In)';
        break;
      case 'CLOCKOUT':
        action_text = ' (Clock Out)';
        break;
    }

    if (type) {
      _.delay(()=>{
        if (this.input_refs.user) this.input_refs.user.focus();
      }, 0);
    }

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem sm={2} md={3}>
          {/*I don't know why material grid doesn't have offset*/}
        </GridItem>

        <GridItem xs={12} sm={8} md={6}>
          <CardContainer style={{marginTop: '8rem'}}>
            <Typography variant="h6" style={{marginBottom: '.5rem'}}>
              Clock In/Out
            </Typography>

            <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink>Action Type</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>{this.setState({type: e.target.value})}}
                >
                  <MenuItem value=''>Select Action Type...</MenuItem>
                  <MenuItem value='CLOCKIN'>Clock In</MenuItem>
                  <MenuItem value='CLOCKOUT'>Clock Out</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText={`User${ action_text }`}
                formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true
                }}
                inputProps={{
                    onKeyUp: this.handleScan,
                    type: 'password',
                    // onPaste: (e)=>{e.preventDefault();},
                    inputRef: elem=>this.input_refs.user = elem
                }}
              />
          </CardContainer>
        </GridItem>
      </Grid>
    );
  }
}
export default withAlert(withStyles(styles)(EmployeeClockIn));
