import React from 'react';
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

export default class BasicDialog extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onClose: PropTypes.func.isRequired,
        actions: PropTypes.array.isRequired,
        content: PropTypes.any.isRequired,
        header: PropTypes.any.isRequired,
    };

    render() {
        const { open, onClose, content, header, actions, ...restProps } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                {...restProps}
                aria-labelledby="basic-dialog-title"
            >
                <DialogTitle id="basic-dialog-title">{header}</DialogTitle>
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    {actions}
                </DialogActions>
            </Dialog>
        );
    }
}
