// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Grid,
    CardContent,
    CardActionArea,
    withStyles
} from "@material-ui/core";
// BXZ render modules
import {
    CardIcon,
    NewDataTable,
    CardContainer,
    Button,
    GridItem,
} from "components";
import DataContext from 'context/Data'
import {
    HourglassEmpty,
    PanTool,
    Done,
    Search
} from '@material-ui/icons';
// Our view components

const styles = theme => ({
    statContainer: {
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    statL: {
        textAlign: 'right',
        width: 'calc(50% - 5px)'
    },
    statR: {
        textAlign: 'left',
        width: 'calc(50% - 5px)'
    },
    divider: {
        width: '10px'
    },
    floatingIconContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(-1)
    },
    statCard: {
        display: "flex"
    },
    statIconContainer: {
        // backgroundColor: "#29b765",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
        marginTop: theme.spacing(-2),
    },
    statIcon: {
        color: "white",
        fontSize: theme.spacing(4),
        // margin: theme.spacing(2, 5),
        margin: theme.spacing(2),
    },
    customCardIcon: {
        height: "67px",
        width: "64px",
    },
    statValueContainer: {
        flexGrow: "1",
        padding: theme.spacing(1),
        paddingBottom: ".5rem !important",
        // backgroundColor: "#2ecc71",
        // color: "white",
        // fontSize: "1rem"
        fontSize: "1.25rem"
    },
    statTable : {
        height: "100%",
        width: "100%"
    },
    clickEnabled: {
        cursor: "pointer",
    },
    clickDisabled: {
        cursor: "default",
    },
    statLabelCell: {
        // textAlign: "left",
        textAlign: "center",
        // color: "#999",
        // fontFamily: 'Raleway-Regular',
        fontFamily: 'Neuton-Regular',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // width: theme.spacing(18)
    },
    statValeCell: {
        // textAlign: "right",
        textAlign: "center",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Neuton-Bold',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        fontWeight: "bold",
        flexGrow: "1"
    },
});

class WMSReceivingDashboard extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.customer_name = '';
        this.data_type = '';

        this.state = {
            loading: false,
            dashboardData: null,
            customerDetail: null,
            poList: null,
        };

        document.title = "Receiving Dashboard";
    }

    render() {
        return (
            <Grid container spacing={2}>
                {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
                {this.renderDashboard()}
                {this.renderCustomerTable()}
            </Grid>
        );
    }

    componentWillMount() {
        this.loadDashboard();
    }

    // api call
      // load dashboard
    loadDashboard = () => {
        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/receivingdashboard`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        this.setState({loading: true, customerDetail: null});
        req.then(this.loadDashboardSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
    }
    loadDashboardSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.context.alert(resp.Error);
            return;
        }
        this.setState({dashboardData: resp});
    }
      // load single customer PO
    loadCustomer = (customer, type) => {
        let req = axios({
          method: 'post',
          url: `${utils.getBaseUrl('customer')}/receivingdashboard`,
          headers: {
            token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
          },
          data: {
            type,
            customer_name: customer.customer_name,
            customer_id: customer.customer_id
          },
        });

        this.setState({loading: true, customerDetail: null, data_type: '', customer_name: ''});
        req.then(this.loadCustomerSuccess.bind(this, customer, type)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadCustomerSuccess = (customer, type, resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
            if (resp.data.Error.message) this.context.alert(resp.data.Error.message);
            else this.context.alert(resp.data.Error);
            return;
        }
        this.setState({customerDetail: resp.data, data_type: type, customer_name: customer.customer_name});
    }
   
    // render functions
    renderDashboard = () => {
      let dbData = Object.assign({}, this.state.dashboardData);
      
      let rows = [];
      let colTotal = {
          'customer_name': 'Total',
          'movement_receiving': {
            sku: 0,
            quantity: 0
          },
          'movement_return': {
            sku: 0,
            quantity: 0
          },
          'return': {
            order: 0,
            quantity: 0
          },
          'record_inbound': {
            po: 0,
            quantity: 0
          },
          'return_processed': {
            order: 0,
            quantity: 0
          },
          'inbound_processed': {
            order: 0,
            quantity: 0
          },
      };

      for (let customer_name in dbData) {
        let cust = dbData[customer_name];
        cust['customer_name'] = customer_name;
        // movement
        colTotal['movement_receiving']['sku'] += parseInt(cust['movement_receiving']['sku']);
        colTotal['movement_receiving']['quantity'] += parseInt(cust['movement_receiving']['quantity']);
        colTotal['movement_return']['sku'] += parseInt(cust['movement_return']['sku']);
        colTotal['movement_return']['quantity'] += parseInt(cust['movement_return']['quantity']);
        // record inbound
        colTotal['return']['order'] += parseInt(cust['return']['order']);
        colTotal['return']['quantity'] += parseInt(cust['return']['quantity']);
        // record inbound
        colTotal['record_inbound']['po'] += parseInt(cust['record_inbound']['po']);
        colTotal['record_inbound']['quantity'] += parseInt(cust['record_inbound']['quantity']);
        // inbound processed
        colTotal['return_processed']['order'] += parseInt(cust['return_processed']['order']);
        colTotal['return_processed']['quantity'] += parseInt(cust['return_processed']['quantity']);
        // return processed
        colTotal['inbound_processed']['order'] += parseInt(cust['inbound_processed']['order']);
        colTotal['inbound_processed']['quantity'] += parseInt(cust['inbound_processed']['quantity']);
        rows.push(cust);
      }
      if (rows.length < 1)  return null;  // if no dashboard data, render nothing
      rows.push(colTotal);

      let columns = [
        {
            key: 'customer_name',
            label: 'Customer',
        }, {
            key: 'record_inbound',
            label: 'Inbound Recorded',
            cellProps: {
              style: {
                cursor: 'pointer',
              },
              title: 'Click to get details',
            },
            render: (val, key, row) => {
                if (parseInt(val.po) === 0 && parseInt(val.quantity) === 0) return '0 | 0';
                return this.renderContentWithDivider(val.po, val.quantity, ()=>{
                    if (row.customer_name === 'Total') return;
                    if (parseInt(val.po) === 0 && parseInt(val.quantity) === 0) return;
                    this.loadCustomer(row, 'receiving');
                });
            }
            // render: (val, key, row)=>{
            //     let content = '0 | 0';
            //     let po = parseInt(val.po) ? val.po : 0;
            //     let quantity = parseInt(val.quantity) ? val.quantity : 0;

            //     if (po || quantity) content = '' + po + ' | ' + quantity;
            //     if (content === '0 | 0' || row.customer_name === 'Total') return content;

            //     return (
            //         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            //             <span className={this.props.classes.cell_click} style={{whiteSpace: 'nowrap'}} onClick={()=>{
            //                 if (row.customer_name === 'Total') return;
            //                 this.loadCustomer(row, 'receiving');
            //             }}>{content}</span>
            //         </div>
            //     );
            // },
        },  
        {
            key: 'return',
            label: 'Returns Recorded',
            cellProps: {
              style: {
                cursor: 'pointer',
              },
              title: 'Click to get details',
            },
            render: (val, key, row) => {
                if (parseInt(val.order) === 0 && parseInt(val.quantity) === 0) return '0 | 0';
                return this.renderContentWithDivider(val.order, val.quantity, ()=>{
                    if (row.customer_name === 'Total') return;
                    if (parseInt(val.order) === 0 && parseInt(val.quantity) === 0) return;
                    this.loadCustomer(row, 'return');
                });
            }
            // render: (val, key, row)=>{
            //     let content = '0 | 0';
            //     let order = parseInt(val.order) ? val.order : 0;
            //     let quantity = parseInt(val.quantity) ? val.quantity : 0;

            //     if (order || quantity) content = '' + order + ' | ' + quantity;
            //     if (content === '0 | 0' || row.customer_name === 'Total') return content;

            //     return (
            //         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            //             <span className={this.props.classes.cell_click} style={{whiteSpace: 'nowrap'}} onClick={()=>{
            //                 if (row.customer_name === 'Total') return;
            //                 this.loadCustomer(row, 'return');
            //             }}>{content}</span>
            //         </div>
            //     );
            // },
        }, 
        {
            key: 'inbound_processed',
            label: 'Inbound Processed',
            // cellProps: {
            //   style: {
            //     cursor: 'pointer',
            //   },
            //   title: 'Click to get details',
            // },
            render: (val, key, row) => {
                if (parseInt(val.order) === 0 && parseInt(val.quantity) === 0) return '0 | 0';
                return this.renderContentWithDivider(val.order, val.quantity);
                // return this.renderContentWithDivider(val.order, val.quantity, ()=>{
                //     if (parseInt(val.order) === 0 && parseInt(val.quantity) === 0) return;
                //     this.loadCustomer(row, 'inbound_processed');
                // });
            }
        }, 
        {
            key: 'return_processed',
            label: 'Return Processed',
            // cellProps: {
            //   style: {
            //     cursor: 'pointer',
            //   },
            //   title: 'Click to get details',
            // },
            render: (val, key, row) => {
                if (parseInt(val.order) === 0 && parseInt(val.quantity) === 0) return '0 | 0';
                return this.renderContentWithDivider(val.order, val.quantity);
                // return this.renderContentWithDivider(val.order, val.quantity, ()=>{
                //     if (parseInt(val.order) === 0 && parseInt(val.quantity) === 0) return;
                //     this.loadCustomer(row, 'return_processed');
                // });
            }
        }, 
        {
          key: 'movement_receiving',
          label: 'Inbound Putaway',
          cellProps: {
            style: {
              cursor: 'pointer',
            },
            title: 'Click to get details',
          },
          render: (val, key, row) => {
            if (parseInt(val.sku) === 0 && parseInt(val.quantity) === 0) return '0 | 0';
            return this.renderContentWithDivider(val.sku, val.quantity, ()=>{
                if (row.customer_name === 'Total') return;
                if (parseInt(val.sku) === 0 && parseInt(val.quantity) === 0) return;
                this.loadCustomer(row, 'movement_receiving');
            });
          }
        //   render: (val, key, row)=>{
        //       let content = '0 | 0';
        //       let po = parseInt(val.sku) ? val.sku : 0;
        //       let quantity = parseInt(val.quantity) ? val.quantity : 0;

        //       if (po || quantity) content = '' + po + ' | ' + quantity;
        //       if (content === '0 | 0' || row.customer_name === 'Total') return content;

        //       return (
        //           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        //               <span className={this.props.classes.cell_click} style={{whiteSpace: 'nowrap'}} onClick={()=>{
        //                   if (row.customer_name === 'Total') return;
        //                   this.loadCustomer(row, 'movement_receiving');
        //               }}>{content}</span>
        //           </div>
        //       );
        //   },
        }, {
          key: 'movement_return',
          label: 'Return Putaway',
          cellProps: {
            style: {
              cursor: 'pointer',
            },
            title: 'Click to get details',
          },
          render: (val, key, row) => {
            if (parseInt(val.sku) === 0 && parseInt(val.quantity) === 0) return '0 | 0';
            return this.renderContentWithDivider(val.sku, val.quantity, ()=>{
                if (row.customer_name === 'Total') return;
                if (parseInt(val.sku) === 0 && parseInt(val.quantity) === 0) return;
                this.loadCustomer(row, 'movement_return');
            });
          }
        //   render: (val, key, row)=>{
        //       let content = '0 | 0';
        //       let po = parseInt(val.sku) ? val.sku : 0;
        //       let quantity = parseInt(val.quantity) ? val.quantity : 0;

        //       if (po || quantity) content = '' + po + ' | ' + quantity;
        //       if (content === '0 | 0' || row.customer_name === 'Total') return content;

        //       return (
        //           <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        //               <span className={this.props.classes.cell_click} style={{whiteSpace: 'nowrap'}} onClick={()=>{
        //                   if (row.customer_name === 'Total') return;
        //                   this.loadCustomer(row, 'movement_return');
        //               }}>{content}</span>
        //           </div>
        //       );
        //   },
        }
      ];

      return (
        <GridItem xs={12}>
          <CardContainer>
            <NewDataTable
              rows={rows}
              columns={columns}
              noPagination
              maxHeight={500}
            />
          </CardContainer>
        </GridItem>
      );
    }
    renderCustomerTable = () => {
        const { customerDetail, customer_name, data_type } = this.state;
        const { classes } = this.props;

        let data_type_map = {
            receiving: " Inbound Recorded",
            return: ' Returns Recorded',
            movement_receiving: " Inbound Putaway",
            movement_return: " Return Putaway",
            inbound_processed: ' Inbound Processed',
            return_processed: ' Return Processed',
        };

      if (!customerDetail) return null;

      let rows = customerDetail;
      let title = customer_name + data_type_map[data_type];
      let colSettings= [];
      switch (data_type) {
        case 'receiving':
            colSettings = [
                {
                    key: 'po',
                    label: 'PO Number',
                    contentNoWrap: true,
                }, {
                    key: 'sku',
                    label: 'SKU',
                    contentNoWrap: true,
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    contentNoWrap: true,
                    render: utils.localizeDate,
                }
            ];
            break;
        case 'inbound_processed':
            colSettings = [
                {
                    key: 'po',
                    label: 'PO Number',
                    contentNoWrap: true,
                }, {
                    key: 'sku',
                    label: 'SKU',
                    contentNoWrap: true,
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    contentNoWrap: true,
                    render: utils.localizeDate,
                }
            ];
            break;           
        case 'return':
            colSettings = [
                {
                    key: 'order_key',
                    label: 'OrderKey',
                    contentNoWrap: true,
                }, {
                    key: 'tracking',
                    label: 'Tracking',
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    render: utils.localizeDate,
                }
            ];
            break;
        case 'return_processed':
            colSettings = [
                {
                    key: 'order_key',
                    label: 'OrderKey',
                    contentNoWrap: true,
                }, {
                    key: 'tracking',
                    label: 'Tracking',
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    render: utils.localizeDate,
                }
            ];
            break;
        case 'movement_return':
          colSettings = [
              {
                  key: 'pallet_id',
                  label: 'PalletID',
              }, {
                  key: 'sku',
                  label: 'SKU',
                  contentNoWrap: true,
              }, {
                  key: 'quantity',
                  label: 'Quantity',
              }, {
                  key: 'arrival_dt',
                  label: 'ArrivalDate',
                  render: utils.localizeDate,
              }
          ];
          break;
        case 'movement_receiving':
          colSettings = [
              // {
              //     key: 'pallet_id',
              //     label: 'PalletID',
              // }, 
              {
                key: 'po',
                label: 'PO Number',
              }, 
              {
                  key: 'sku',
                  label: 'SKU',
              }, {
                  key: 'quantity',
                  label: 'Quantity',
              }, {
                  key: 'arrival_dt',
                  label: 'ArrivalDate',
                  render: utils.localizeDate,
              }
          ];
          break;
      }

        return (
          <GridItem xs={12}>
              <CardContainer>
                  <div>
                      <div className={classes.title} style={{height: '3rem'}}>
                          <Typography variant="h6">
                              {title}

                              {rows.length > 0 && <Button
                                style={{float: 'right'}}
                                onClick={this.exportTable}>Export</Button>}
                          </Typography>
                      </div>

                      <NewDataTable
                        rows={rows}
                        columns={colSettings}
                        noPagination
                        maxHeight={500}
                        id="table-export"
                      />
                  </div>
              </CardContainer>
          </GridItem>
        );
    }
    exportTable = () => {
      const { customerDetail, customer_name, data_type } = this.state;
      let data_type_map = {
          receiving: " Inbound Recorded",
          return: ' Returns Recorded',
          movement_receiving: " Inbound Putaway",
          movement_return: " Return Putaway",
          inbound_processed: ' Inbound Processed',
          return_processed: ' Return Processed',
      };
      let title = customer_name + data_type_map[data_type];
      let filename = title + '.csv';

      let headers = [];
      switch (data_type) {
        case 'receiving':
            headers = [
                {
                    key: 'po',
                    label: 'PO Number',
                }, {
                    key: 'sku',
                    label: 'SKU',
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    render: utils.localizeDate,
                }
            ];
            break;
        case 'inbound_processed':
            headers = [
                {
                    key: 'po',
                    label: 'PO Number',
                }, {
                    key: 'sku',
                    label: 'SKU',
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    render: utils.localizeDate,
                }
            ];
            break;    
        case 'return':
            headers = [
                {
                    key: 'order_key',
                    label: 'OrderKey',
                }, {
                    key: 'tracking',
                    label: 'Tracking',
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    render: utils.localizeDate,
                }
            ];
            break;
        case 'return_processed':
            headers = [
                {
                    key: 'order_key',
                    label: 'OrderKey',
                }, {
                    key: 'tracking',
                    label: 'Tracking',
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    render: utils.localizeDate,
                }
            ];
            break;
        case 'movement_return':
          headers = [
              {
                key: 'pallet_id',
                label: 'PalletID',
              }, {
                  key: 'sku',
                  label: 'SKU',
              }, {
                  key: 'quantity',
                  label: 'Quantity',
              }, {
                  key: 'arrival_dt',
                  label: 'ArrivalDate',
                  render: utils.localizeDate,
              }
          ];
          break;
        case 'movement_receiving':
            headers = [
                {
                    key: 'po',
                    label: 'PO Number',
                }, {
                    key: 'sku',
                    label: 'SKU',
                }, {
                    key: 'quantity',
                    label: 'Quantity',
                }, {
                    key: 'arrival_dt',
                    label: 'ArrivalDate',
                    render: utils.localizeDate,
                }
            ];
            break;
        }

      utils.exportArrayToCsv(customerDetail, headers, filename);
    }
    renderContentWithDivider = (content_left, content_right, onClick) => {
        const {classes} = this.props;
        content_left = '' + (parseInt(content_left) || 0);
        content_right = '' + (parseInt(content_right) || 0);
        let padding = <span style={{visibility: 'hidden'}}>{'_'.repeat(Math.abs(content_left.length - content_right.length))}</span>;
        if (onClick) {
          return (
            <div onClick={onClick} className={classes.statContainer} style={{cursor: 'pointer'}}>
              <div className={classes.statL}>{content_left.length < content_right.length && padding} {content_left} </div>
              <div className={classes.divider}>|</div>
              <div className={classes.statR}>{content_right} {content_left.length > content_right.length && padding}</div>
            </div>
          );
        } else {
          return (
            <div className={classes.statContainer}>
              <div className={classes.statL}>{content_left.length < content_right.length && padding} {content_left} </div>
              <div className={classes.divider}>|</div>
              <div className={classes.statR}>{content_right} {content_left.length > content_right.length && padding}</div>
            </div>
          );
        }
    }
}
export default withRouter(withStyles(styles)(WMSReceivingDashboard));
