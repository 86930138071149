import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment';
import { Typography, Grid, ListItemIcon, Menu, MenuItem, withStyles, Chip } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  GridItem,
  ReversedIconButton 
} from 'components';
import DataContext from 'context/Data'
import {
  Add,
  Close,
  ArrowBack,
  ArrowForward
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';
import NewPOReservation from 'views/Dialogs/Reservation/New'
import EditPOReservation from 'views/Dialogs/Reservation/Edit'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
  mediumseagreen: {
    backgroundColor: 'mediumseagreen',
    color: 'white',
    '&:hover': {
      backgroundColor: 'mediumseagreen',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: 'mediumseagreen',
      color: 'white',
    },
  },
  tomato: {
    backgroundColor: 'tomato',
    color: 'white',
    '&:hover': {
      backgroundColor: 'tomato',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: 'tomato',
      color: 'white',
    },
  }
});

class ClientPOReservation extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dialog: '',
      customer_options: [],
      customer: '',
      po_reservation: {},
      week_margin: 0,
      list_anchor: null, // dropdown reservation list anchor
      active_list: null,
      default_date_input: '',
      default_time_input: '',
      // Reservation list action
      menuTarget: null,
      active_ellipse_menu: null,
    };

    document.title = "Inbound Reservation";
  }

  componentWillMount() {
    this.loadCustomers(); // load po reservation when load customer success
  }

  loadCustomers = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/customerlist`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.loadCustomersSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadCustomersSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({customer_options: resp.data, customer: ''});
    this.loadPOReservation(); // load po reservation when load customer success
  }
  loadPOReservation = (week_margin) => {
    week_margin = week_margin == undefined ? this.state.week_margin: week_margin;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/poReservation`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true, week_margin: week_margin, po_reservation: {}});
    
    req.then(this.loadPOReservationSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadPOReservationSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    let result = resp.data;
    if (Array.isArray(result) && result.length === 0) result = {};

    this.setState({po_reservation: result});
  }
  loadPOList = (customer, type) => {
    if (type !== 'Inbound') return;
    if (!customer) {
      this.context.alert("Please select customer!");
      return;
    }

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/polist/${customer}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true, new_po: true, po_number: '', po_id: ''});
    req.then(this.loadPOListSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadPOListSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    let options = resp.data;
    if (!options || !Array.isArray(options)) return;
    this.setState({po_list: options});
  }
  newPOReservation = (newReservation) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/poReservation`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: Object.assign(newReservation, {date: `${newReservation.date} ${newReservation.time}`}),
    });

    this.setState({loading: true});
    req.then(this.newPOReservationSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newPOReservationSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({po_reservation: resp.data, dialog: ''});
  }
  updatePOReservation = (newReservation) => {
    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/poReservation/${newReservation.id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: Object.assign(newReservation, {reservation_id: newReservation.id, date: `${newReservation.date} ${newReservation.time}`}),
    });

    this.setState({loading: true});
    req.then(this.updatePOReservationSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updatePOReservationSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({po_reservation: resp.data, dialog: ''});
  }
  deletePOReservation = (po_reservation, callback) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/poReservation/${po_reservation.id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deletePOReservationSuccess.bind(this, po_reservation, callback)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deletePOReservationSuccess = (deleted_data, callback, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    let new_data = Object.assign({}, this.state.po_reservation);
    let deleted_date = moment(deleted_data.date).format("YYYY-MM-DD");
    new_data[deleted_date] = new_data[deleted_date] ? new_data[deleted_date] : [];
    let new_time_list = []
    for (let time_data of new_data[deleted_date]) {
        if (time_data.id === deleted_data.id) continue;
        new_time_list.push(time_data);
    }
    new_data[deleted_date] = new_time_list;
    if (callback) callback();
    this.setState({po_reservation: new_data, dialog: ''});
  }

  renderDialog = () => {
    const { dialog, active_reservation, default_date_input, default_time_input } = this.state;

    let customer_options = this.state.customer_options;
    if (!customer_options || customer_options.length <= 0) customer_options = [];

    switch (dialog) {
      case 'add_reservation':
          return <NewPOReservation
              handleClose={()=>{this.setState({dialog: '', default_date_input: '', default_time_input: ''})}}
              handleSubmit={this.newPOReservation}
              customer_options={customer_options}
              default_date_input={default_date_input}
              default_time_input={default_time_input}
              client_view
          />;
      case 'display_reservation':
          return <EditPOReservation
            handleClose={()=>{this.setState({dialog: '', active_reservation: null})}}
            handleSubmit={this.updatePOReservation}
            customer_options={customer_options}
            reservation={active_reservation}
            client_view
          />;
          return;
    }
  }
  renderReservationTable = () => { 
    const { classes } = this.props;
    const { po_reservation, week_margin, list_anchor, active_list, default_date_input, default_time_input, active_ellipse_menu, menuTarget } = this.state;
    let raw_data = po_reservation || {};
    let headers = [];

    let date_week = moment().add(7*week_margin, 'days');
    for (let i = 1; i <= 5; i++) {
      headers.push(date_week.day(i).format('YYYY-MM-DD'));
    }
    let rows = [];
    // init times for each day
    for (let i = 8; i <= 18; i++) {
      let single_row = {};
      single_row.time = moment(`2021-01-01 ${i}:00`).format('HH:mm');
      for (let day of headers) single_row[day] = [];
      rows.push(single_row);
    }
    for (let day in raw_data) {
      for (let single_reservation of raw_data[day]) {
          let date_info = moment(single_reservation['date']);
          let day_of_week = date_info.day();
          let hour_of_day = date_info.hour();
          hour_of_day -= 8;
          if (rows[hour_of_day][day]) rows[hour_of_day][day].push(single_reservation);
      }
    }
    
    let colSettings = [
      {
        key: 'time',
        label: '',
        cellProps: {
          style: {
            width: '4rem',
          },
        },
      }
    ];
    for (let day of headers) {
      colSettings.push({
          key: day,
          label: moment(day).format("ddd MM/DD"),
          cellProps: {
            style: {
              cursor: 'pointer',
            },
            title: 'Double click to add',
            onDoubleClick: (val, key, row, index)=>{
              if (moment().isAfter(key, "day")) return; // only allow to reserve today and after
              this.setState({dialog: 'add_reservation', default_date_input: key, default_time_input: row.time});
            }
          },
          render: (val,key,data,index) => {
            let contents = null;
            if (val) {
              contents = val.map((val,index, array)=>{
                if (array.length >= 5 && index === 3) {
                  let ellipse_list = array.slice(3);
          
                  return (
                    <div key={index}>
                      <Chip
                          style={{margin: ".25rem"}}
                          label={<div style={{width:'170px', textOverflow: "ellipsis",overflow: 'hidden',
                              whiteSpace: 'nowrap'}}>And {" "+(array.length-3)+" "} More...</div>}
                          onClick={(event) => {
                              this.setState({menuTarget: event.currentTarget, active_ellipse_menu: val.id});
                          }}
                          title="Click to See More"
                          color="primary"
                      />

                        {active_ellipse_menu === val.id &&
                            <Menu
                                id="customized-menu"
                                anchorEl={menuTarget}
                                open={Boolean(menuTarget)}
                                onClose={()=>{this.setState({menuTarget: null})}}
                            >
                                {ellipse_list.map((single_data,index)=>{
                                    if (single_data.customer !== localStorage.getItem('customer_id')) {
                                      return (
                                        <MenuItem key={index}>
                                          <span onClick={(e)=>{e.stopPropagation();}} style={{width: '55.99px'}}/>
                                          <Typography style={{minWidth: "150px",textAlign: "center"}} noWrap primary="Sent mail" >Booked</Typography>
                                        </MenuItem>
                                      );
                                    } else {
                                      return (<MenuItem onClick={(e)=>{
                                        this.setState({dialog: 'display_reservation', active_reservation: single_data});
                                      }} key={index}>
                                          <ListItemIcon>
                                              <Close onClick={(e)=>{
                                                e.stopPropagation(); 
                                                this.context.confirm("Are you sure to delete this reservation?", this.deletePOReservation.bind(this, single_data,()=>{this.setState({menuTarget: null})}));
                                              }}/>
                                          </ListItemIcon>
                                          <Typography style={{minWidth: "150px",textAlign: "center"}} noWrap primary="Sent mail" >{single_data.type_key}</Typography>
                                      </MenuItem>);
                                    }
                                })}
                            </Menu>
                        }
                    </div>
                  );
                }
                if (index > 3) return null;

                if (val.customer !== localStorage.getItem('customer_id')) return (
                  <div key={index}>
                    <Chip
                      style={{margin: ".25rem"}}
                      label={<div style={{width:'170px', textOverflow: "ellipsis",overflow: 'hidden', whiteSpace: 'nowrap'}}>Booked</div>}
                    />
                  </div>
                );
                return (
                  <div key={index}>
                      <Chip
                          style={{margin: ".25rem"}}
                          className={val.type === 'Inbound' ? this.props.classes.mediumseagreen : this.props.classes.tomato}
                          label={<div style={{width:'150px', textOverflow: "ellipsis",overflow: 'hidden',
                              whiteSpace: 'nowrap'}}>{val.type}: {val.type_key}</div>}
                          onClick={()=>{
                              this.setState({dialog: 'display_reservation', active_reservation: val});
                          }}
                          title="Click to See Detail"
                          onDelete={()=>{
                            this.context.confirm("Are you sure to delete this reservation?", this.deletePOReservation.bind(this, val));
                          }}
                      />
                  </div>
                  );
              });
            }

            return (<div>
              {contents}
            </div>);
          }
      });
    }
    rows = rows.slice(0, 7);

    return (
      <GridItem xs={12}>
         <CardContainer>
          <div className={this.props.classes.title} style={{padding: ".5rem 0"}}>
                <Typography variant='h5'>
                    Weekly Reservation
                </Typography>

                <ReversedIconButton size="small" onClick={()=>{this.setState({dialog: "add_reservation"})}} color='primary' variant="fab" aria-label="Next Week" className={classes.button}>
                    <Add style={{color: 'white'}}/>
                </ReversedIconButton>

                <ReversedIconButton size="small" style={{float: 'right', marginLeft: '1rem'}} onClick={()=>{
                    this.loadPOReservation(week_margin+1);
                }} variant="fab" aria-label="Next Week" className={classes.button}>
                    <ArrowForward style={{color: 'white'}}/>
                </ReversedIconButton>

                <ReversedIconButton size="small" style={{float: 'right', visibility: (!week_margin || week_margin < 1) ? 'hidden' : 'visible'}} disabled={!week_margin || week_margin < 1} onClick={()=>{
                    if (!week_margin || week_margin < 1) return;
                    this.loadPOReservation(week_margin-1);
                }} variant="fab" aria-label="Prev Week" className={classes.button}>
                    <ArrowBack style={{color: 'white'}}/>
                </ReversedIconButton>
            </div>

            <NewDataTable
              rows={rows}
              noPagination
              rowHeight="auto"
              columns={colSettings}
            />
         </CardContainer>
      </GridItem>
    );
  }
  render() {
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {this.renderDialog()}

        {this.renderReservationTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(ClientPOReservation);
