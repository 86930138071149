import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0)
  },
}))

export default function EditInventory(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {inventory: defaultInventory, handleClose, handleSubmit} = props;
  const [inventory, setInventory] = useState(defaultInventory);

  function onInventoryChange(key, newVal) {
    let newInventory = Object.assign({}, inventory);
    newInventory[key] = newVal;
    setInventory(newInventory);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
          <CustomInput
            labelText='Expiration Date'
            formControlProps={{
              fullWidth: true,
              className: classes.customInputContainer,
            }}
            labelProps={{
                shrink: true,
            }}
            inputProps={{
              type: 'date',
              onChange: (e)=>{onInventoryChange('expiration_date', e.target.value)},
              value: inventory.expiration_date || '',
            }}
          />

          <CustomInput
            labelText='Lot #'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              // required: true,
              className: classes.customInput
            }}
            inputProps={{
              onChange: (e)=>{onInventoryChange('lot', e.target.value)},
              value: inventory.lot || '',
            }}
          />
      </Grid>
    );
  }
  function onSubmit() {
    const {expiration_date, lot} = inventory;

    let newInventory = Object.assign({}, defaultInventory);
    newInventory.lot = lot || '';
    newInventory.expiration_date = expiration_date || '';
    handleSubmit(newInventory);
  }

  let title = "Edit Lot and Expiration Date";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}