import {
  Receipt, PermMedia, AccountCircle, AssignmentInd, WatchLater,
  CloudUpload, DonutLarge, CloudDownload, InsertChart,
  ShoppingBasket, Forum, BugReport, TrendingUp, Group, Assignment, Store, Dashboard, FlightTakeoff, ExitToApp, FlightLand, ShoppingCart
} from '@material-ui/icons'

// root
import WMSDashboard from "views/Dashboard/WMS"
import WMSReceivingDashboard from "views/Inbound/WMSReceivingDashboard";
import ClientDashboard from "views/Dashboard/Client"
import ManageWorkOrder from "views/WorkOrder/ManageWorkOrder";
import WorkOrderDashboard from 'views/WorkOrder/WorkOrderDashboard'
import ManageZohoTicket from "../views/Zoho/ManageZohoTicket";

// Proguctivity Submenu
import WMSTasks from 'views/Productivity/WMSTasks'
import TimeClock from 'views/Productivity/Timeclock'
import UserProductivity from 'views/Productivity/UserProductivity'
import UserPerformance from 'views/Productivity/UserPerformance'

// Inbound Submenu
import ClientInboundDashboard from 'views/Inbound/ClientInboundDashboard'
import WMSInboundDashboard from 'views/Inbound/WMSInboundDashboard'
import NewPO from 'views/Inbound/NewPO'
import RecordInbound from 'views/Inbound/RecordInbound'
import ClientPOReservation from 'views/Inbound/ClientPOReservation'
import Receiving from 'views/Inbound/Receiving'
import OrderReturn from 'views/Inbound/OrderReturn'
import ManageInbound from 'views/Inbound/ManagePO'
import UnplannedReceiving from 'views/Inbound/UnplannedReceiving'
import UnplannedReturn from 'views/Inbound/UnplannedReturn'
import InboundPalletDashboard from 'views/Inbound/InboundPalletDashboard'
import ManageInboundPallet from 'views/Inbound/ManageInboundPallet'
import PutawayQA from 'views/Inbound/PutawayQA'
import QuinceJunkScan from 'views/Inbound/QuinceJunkScan';
import ImportInbound from 'views/Inbound/ImportInbound'

// Outbound Submenu
import BatchOrder from 'views/Outbound/BatchOrder'
import B2CScannerPick from "views/Outbound/B2CScannerPick"
import B2BScannerPick from "views/Outbound/B2BScannerPick"
import B2CPacking from "views/Outbound/B2CPacking";
import B2BPacking from "views/Outbound/B2BPacking";
import PackingQA from 'views/Outbound/PackingQA'
import Shipping from 'views/Outbound/Shipping'
import SortingPackage from 'views/Outbound/SortingPackage'
import Waybill from 'views/Billing/Waybill'

// Product Submenu
import Inventory from 'views/Inventory/Inventory'
import ManageProduct from 'views/Product/ManageProduct'
import ManagePallet from 'views/Pallet/ManagePallet'
import Barcode from 'views/Barcode/Barcode'
import ManageLocation from 'views/Location/ManageLocation'
import Cycle from 'views/Inventory/Cycle'
import BatchCount from 'views/InventoryCount/BatchCount'
import CountByScanner from 'views/InventoryCount/CountByScanner'
import PhysicalCount from "../views/Inventory/PhysicalCount"
import PhysicalCountAdmin from "../views/Inventory/PhysicalCountAdmin"
import Movement from 'views/Inventory/Movement'
import MovementMultiple from 'views/Inventory/MovementMultiple'
import PalletPutaway from 'views/Inventory/PalletPutaway'
import PutawayTicket from 'views/Pallet/PutawayTicket'
import ReturnTicket from 'views/Pallet/ReturnTicket'
import ImportLocation from 'views/Location/ImportLocation'
import ImportMinMax from 'views/Location/ImportMinMax'
import ImportProduct from 'views/Product/ImportProduct'
import ImportInventory from "../views/Inventory/ImportInventory"

// Order Submenu
import NewOrder from 'views/Order/NewOrder'
import CreateLabel from 'views/Order/CreateLabel'
import ManageOrder from 'views/Order/ManageOrder'
import ManagePickticket from "../views/Order/ManagePickticket"
import B2BPalletDashboard from "views/Pallet/B2BPalletDashboard"
import ManageB2BPallet from "views/Pallet/ManageB2BPallet"
import B2BPalletQA from "views/Pallet/B2BPalletQA"
import ImportOrder from 'views/Order/ImportOrder'

// Analysis Submenu
import NewShipmentAnalysis from 'views/Analysis/New_Chart_ShipmentAnalysis'
import NewInboundAnalysis from 'views/Analysis/New_Chart_InboundAnalysis'
import NewOrderAnalysis from 'views/Analysis/New_Chart_OrderAnalysis'
import NewReturnAnalysis from 'views/Analysis/New_Chart_ReturnAnalysis'

// Report Submenu
import DownloadReport from 'views/Report/DownloadReport'
import EmailReport from 'views/Report/EmailReport'

// Customer Submenu
import ClientManageCustomer from 'views/Customer/ClientManageCustomer'
import ManageCustomers from 'views/Customer/ManageCustomers'

// Admin Submenu
import NewUser from 'views/User/NewWMSUser'
import ClientManageUsers from 'views/User/ClientManageUsers'
import WMSManageUsers from 'views/User/WMSManageUsers'
import ManageUserRole from 'views/User/ManageUserRole'
import Billing from 'views/Billing/Billing'
import HostCheckoutPayout from 'views/Billing/HostCheckoutPayout'

// Forecast Submenu
import ImportForecast from 'views/Forecast/ImportForecast'

// Root
import Profile from 'views/User/Profile'
import PageNotFound from 'views/General/PageNotFound'

const allRoutes = [
  {
    key: 'dashboard',
    path: "/dashboard",
    sidebarName: "OB Dashboard",
    navbarName: "OB Dashboard",
    icon: Dashboard,
    component: WMSDashboard
  },
  {
    key: 'receivingDashboard',
    path: "/dbReceiving",
    sidebarName: "IB Dashboard",
    navbarName: "IB Dashboard",
    icon: Dashboard,
    component: WMSReceivingDashboard
  },
  {
    key: 'clientDashboard',
    path: "/clientDashboard",
    sidebarName: "Client Dashboard",
    navbarName: "Client Dashboard",
    icon: Dashboard,
    component: ClientDashboard
  }, 
  {
    key: 'workOrder',
    path: "/workOrder",
    sidebarName: "Work Order",
    navbarName: "Work Order",
    icon: WatchLater,
    component: ManageWorkOrder
  },
  {
    key: 'workOrderDashboard',
    path: "/workOrderDashboard",
    sidebarName: "Work Order DB",
    navbarName: "Work Order Dashboard",
    icon: WatchLater,
    component: WorkOrderDashboard
  },
  {
    key: 'zohoTickets',
    path: "/zohoTickets",
    sidebarName: "Zoho Tickets",
    navbarName: "Zoho Tickets",
    icon: Forum,
    component: ManageZohoTicket
  },
  {
    key: 'productivity',
    path: "/productivity",
    sidebarName: "Productivity",
    navbarName: "Productivity",
    icon: BugReport,
    submenu: [
        {
          key: 'wmsTasks',
          path: '/wmsTasks',
          sidebarName: 'WMS Tasks',
          navbarName: 'WMS Tasks',
          component: WMSTasks
        },
        {
            key: 'addProductivity',
            path: '/addProductivity',
            sidebarName: 'Timeclock',
            navbarName: 'Timeclock',
            component: TimeClock
        },
        {
          key: 'viewProductivity',
          path: "/userProductivity",
          sidebarName: "User Productivity",
          navbarName: "User Productivity",
          component: UserProductivity
        },
        {
          key: 'userPerformance',
          path: "/userPerformance",
          sidebarName: "User Performance",
          navbarName: "User Performance",
          component: UserPerformance
        },
    ]
  },
  {
    key: 'inbound',
    path: "/inbound",
    sidebarName: "Inbound",
    navbarName: "Inbound",
    icon: FlightLand,
    submenu: [
      {
        key: 'clientInboundDashboard',
        path: '/clientInboundDashboard',
        sidebarName: 'Inbound Dashboard',
        navbarName: 'Inbound Dashboard',
        component: ClientInboundDashboard
      },
      {
        key: 'inboundDashboard',
        path: '/inboundDashboard',
        sidebarName: 'Inbound Dashboard',
        navbarName: 'Inbound Dashboard',
        component: WMSInboundDashboard
      },
      {
        key: 'createPO',
        path: '/createPO',
        sidebarName: 'New PO',
        navbarName: 'New PO',
        component: NewPO
      },
      {
        key: 'recordInbound',
        path: "/recordInbound",
        sidebarName: "Record",
        navbarName: "Record",
        component: RecordInbound
      },
      {
        key: 'poReservation',
        path: "/poReservation",
        sidebarName: "Reservation",
        navbarName: "Reservation",
        component: ClientPOReservation
      },
      {
        key: 'receiving',
        path: '/receiving',
        sidebarName: 'Receive',
        navbarName: 'Receive',
        component: Receiving
      },
      {
        key: 'return',
        path: '/return',
        sidebarName: 'Return',
        navbarName: 'Return',
        component: OrderReturn
      },
      {
        key: 'manageInbound',
        path: '/manageInbound',
        sidebarName: 'Manage PO',
        navbarName: 'Manage PO',
        component: ManageInbound
      },
      {
        key: "unplannedReceiving",
        path: "/unplannedReceiving",
        sidebarName: "Unplan Receiving",
        navbarName: "Unplan Receiving",
        component: UnplannedReceiving
      },
      {
        key: "unplannedReturn",
        path: "/unplannedReturn",
        sidebarName: "Unplan Return",
        navbarName: "Unplan Return",
        component: UnplannedReturn
      },
      {
        key: "inboundPalletDashboard",
        path: "/inboundPalletDashboard",
        sidebarName: "IB Pallet Dashboard",
        navbarName: "IB Pallet Dashboard",
        component: InboundPalletDashboard
      },
      {
        key: "manageInboundPallet",
        path: "/manageInboundPallet",
        sidebarName: "Manage IB Pallet",
        navbarName: "Manage IB Pallet",
        component: ManageInboundPallet
      },
      {
        key: "putawayQA",
        path: "/putawayQA",
        sidebarName: "Putaway QA",
        navbarName: "Putaway QA",
        component: PutawayQA
      },
      {
        key: 'junkScan',
        path: '/junkScan',
        sidebarName: 'Quince Junk Scan',
        navbarName: 'Quince Junk Scan',
        component: QuinceJunkScan
      },
      {
        key: 'importInbound',
        path: '/importInbound',
        sidebarName: 'Import PO',
        navbarName: 'Import PO',
        component: ImportInbound
      },
    ]
  },
  {
    key: 'outbound',
    path: "/outbound",
    sidebarName: "Outbound",
    navbarName: "Outbound",
    icon: FlightTakeoff,
    submenu: [
      {
        key: 'batch',
        path: "/batch",
        sidebarName: "Batch",
        navbarName: "Batch",
        component: BatchOrder
      },
      {
        key: 'pickBatch',
        path: "/pickBatch",
        sidebarName: "B2C Picking",
        navbarName: "B2C Picking",
        component: B2CScannerPick
      }, 
      {
        key: 'b2bPicking',
        path: "/b2bPicking",
        sidebarName: "B2B Picking",
        navbarName: "B2B Picking",
        component: B2BScannerPick
      }, 
      {
        key: 'packing',
        path: "/packing",
        sidebarName: "B2C Packing",
        navbarName: "B2C Packing",
        component: B2CPacking
      },
      {
        key: 'b2bPacking',
        path: "/businessPacking",
        sidebarName: "B2B Packing",
        navbarName: "B2B Packing",
        component: B2BPacking
      },
      {
        key: 'packing_qa',
        path: "/QApacking",
        sidebarName: "Packing QA",
        navbarName: "Packing QA",
        component: PackingQA
      },
      {
        key: 'shipment',
        path: "/shipment",
        sidebarName: "Shipping",
        navbarName: "Shipping",
        component: Shipping
      },
      {
        key: 'sortPackage',
        path: "/sortPackage",
        sidebarName: "Sort Package",
        navbarName: "Sort Package",
        component: SortingPackage
      },
      {
        key: 'dhlWaybill',
        path: "/waybill",
        sidebarName: "Waybill",
        navbarName: "Waybill",
        component: Waybill
      },
    ]
  },
  {
    key: "product",
    path: "/product",
    sidebarName: "Product",
    navbarName: "Product",
    icon: Store,
    submenu: [
      {
        key: "manageInventory",
        path: '/inventory',
        sidebarName: 'Inventory',
        navbarName: 'Inventory',
        component: Inventory
      },
      {
        key: "manageProduct",
        path: '/manageProduct',
        sidebarName: 'Manage Product',
        navbarName: 'Manage Product',
        component: ManageProduct
      },
      {
        key: 'managePallet',
        path: "/managePallet",
        sidebarName: "Manage Pallet",
        navbarName: "Manage Pallet",
        component: ManagePallet
      },
      {
        key: 'barcode',
        path: "/barcode",
        sidebarName: "Barcode",
        navbarName: "Barcode",
        component: Barcode
      },
      {
        key: 'manageLocation',
        path: "/manageLocation",
        sidebarName: "Manage Location",
        navbarName: "Manage Location",
        component: ManageLocation
      },
      {
        key: 'cycle',
        path: "/cycle",
        sidebarName: "Cycle",
        navbarName: "Cycle",
        component: Cycle
      },
      {
        key: 'batchCount',
        path: "/batchCount",
        sidebarName: "Batch Count",
        navbarName: "Batch Count",
        component: BatchCount
      },
      {
        key: 'scannerCount',
        path: "/scannerCount",
        sidebarName: "Scanner Count",
        navbarName: "Scanner Count",
        component: CountByScanner
      },
      {
        key: 'physicalCount',
        path: "/physicalCount",
        sidebarName: "Physical Count",
        navbarName: "Physical Count",
        component: PhysicalCount
      },
      {
        key: 'physicalCountAdmin',
        path: "/adminPhysical",
        sidebarName: "Physical Count Admin",
        navbarName: "Physical Count Admin",
        component: PhysicalCountAdmin
      },
      {
        key: 'tempMovement',
        path: "/movement",
        sidebarName: "Movement",
        navbarName: "Movement",
        component: Movement
      },
      {
        key: "moveMultiple",
        path: "/moveMultiple",
        sidebarName: "Multiple Movement",
        navbarName: "Multiple Movement",
        component: MovementMultiple
      },
      {
        key: 'palletPutaway',
        path: "/palletPutaway",
        sidebarName: "Pallet Put-away",
        navbarName: "Pallet Put-away",
        component: PalletPutaway
      },
      {
        key: 'putawayTicket',
        path: "/putawayTicket",
        sidebarName: "Put-away Ticket",
        navbarName: "Put-away Ticket",
        component: PutawayTicket
      },
      {
        key: 'returnTicket',
        path: "/returnTicket",
        sidebarName: "Return Ticket",
        navbarName: "Return Ticket",
        component: ReturnTicket
      },
      {
        key: 'importLocation',
        path: "/importLocation",
        sidebarName: "Import Location",
        navbarName: "Import Location",
        component: ImportLocation
      },
      {
        key: 'importMinMax',
        path: "/importMinMax",
        sidebarName: "Import MinMax",
        navbarName: "Import MinMax",
        component: ImportMinMax
      },
      {
        key: "importProduct",
        path: '/importProduct',
        sidebarName: 'Import Product',
        navbarName: 'Import Product',
        component: ImportProduct
      },
      {
        key: "importInventory",
        path: '/importInventory',
        sidebarName: 'Import Inventory',
        navbarName: 'Import Inventory',
        component: ImportInventory
      },
    ]
  },
  {
    key: "order",
    path: "/order",
    sidebarName: "Order",
    navbarName: "Order",
    icon: ShoppingCart,
    submenu: [
      {
        key: "newOrder",
        path: '/newOrder',
        sidebarName: 'New Order',
        navbarName: 'New Order',
        component: NewOrder
      },
      {
        key: "createLabel",
        path: '/createLabel',
        sidebarName: 'Create Label',
        navbarName: 'Create Label',
        component: CreateLabel
      },
      {
        key: "manageOrders",
        path: '/manageOrders',
        sidebarName: 'Manage Order',
        navbarName: 'Manage Order',
        component: ManageOrder
      },
      {
        key: 'managePickticket',
        path: "/managePickticket",
        sidebarName: "Manage Pickticket",
        navbarName: "Manage Pickticket",
        component: ManagePickticket
      },
      {
        key: 'b2bPalletDashboard',
        path: "/DBpallet",
        sidebarName: "PalletDashboard",
        navbarName: "PalletDashboard",
        component: B2BPalletDashboard
      },
      {
        key: 'manageB2BPallet',
        path: "/obpltManage",
        sidebarName: "ManageB2BPallet",
        navbarName: "ManageB2BPallet",
        component: ManageB2BPallet
      },
      {
        key: 'b2bPalletQA',
        path: "/b2bPalletQA",
        sidebarName: "OB Pallet QA",
        navbarName: "OB Pallet QA",
        component: B2BPalletQA
      },
      {
        key: "importOrder",
        path: '/importOrder',
        sidebarName: 'Import Order',
        navbarName: 'Import Order',
        component: ImportOrder
      },
    ]
  },
  {
    key: 'analysis',
    path: "/analysis",
    sidebarName: "Analysis",
    navbarName: "Analysis",
    icon: InsertChart,
    submenu: [
      {
        key: 'inboundAnalysis',
        path: "/inboundAnalysis",
        sidebarName: "Inbound",
        navbarName: "Inbound",
        component: NewInboundAnalysis
      },
      {
        key: 'shipmentAnalysis',
        path: "/shipmentAnalysis",
        sidebarName: "Shipment",
        navbarName: "Shipment",
        component: NewShipmentAnalysis
      },
      {
        key: 'returnAnalysis',
        path: "/returnAnalysis",
        sidebarName: "Return",
        navbarName: "Return",
        component: NewReturnAnalysis
      },
      {
        key: 'orderAnalysis',
        path: '/orderAnalysis',
        sidebarName: 'Order',
        navbarName: 'Order',
        component: NewOrderAnalysis,
      }
    ]
  },
  {
    key: 'report',
    path: "/report",
    sidebarName: "Report",
    navbarName: "Report",
    icon: Assignment,
    submenu: [
      {
        key: 'downloadReport',
        path: "/downloadReport",
        sidebarName: "Download Report",
        navbarName: "Download Report",
        component: DownloadReport
      },
      {
        key: 'emailReport',
        path: "/emailReport",
        sidebarName: "Email Report",
        navbarName: "Email Report",
        component: EmailReport
      }
    ]
  },
  {
    key: 'customer',
    path: "/customer",
    sidebarName: "Customer",
    navbarName: "Customer",
    icon: AssignmentInd,
    submenu: [
      {
        key: "clientManageCustomers",
        path: "/clientManageCustomers",
        sidebarName: "Manage Customers",
        navbarName: "Manage Customers",
        component: ClientManageCustomer
      },
      {
          key: "manageCustomers",
          path: "/manageCustomers",
          sidebarName: "Manage Customers",
          navbarName: "Manage Customers",
          component: ManageCustomers
      },
    ]
  },
  {
    key: 'admin',
    path: "/admin",
    sidebarName: "Admin",
    navbarName: "Admin",
    icon: Group,
    submenu: [
      {
        key: 'newUser',
        path: "/newUser",
        sidebarName: "New User",
        navbarName: "New User",
        component: NewUser
      },
      {
        key: 'clientManageUsers',
        path: "/clientManageUsers",
        sidebarName: "Manage User",
        navbarName: "Manage User",
        component: ClientManageUsers
      },
      {
        key: 'manageUsers',
        path: "/manageUsers",
        sidebarName: "Manage User",
        navbarName: "Manage User",
        component: WMSManageUsers
      },
      {
        key: 'manageRoles',
        path: "/manageRoles",
        sidebarName: "Manage Roles",
        navbarName: "Manage Roles",
        component: ManageUserRole
      },
      {
        key: "billing",
        path: "/billing",
        sidebarName: "Billing",
        navbarName: "Billing",
        component: Billing
      },
      // {
      //   key: "checkoutPayout",
      //   path: "/checkoutPayout",
      //   sidebarName: "Checkout Payout",
      //   navbarName: "Checkout Payout",
      //   component: HostCheckoutPayout
      // },
    ]
  },
  {
    key: 'forecast',
    path: "/forecast",
    sidebarName: "Forecast",
    navbarName: "Forecast",
    icon: TrendingUp,
    submenu: [
      {
        key: 'importForecast',
        path: "/forecastImport",
        sidebarName: "Import Forecast",
        navbarName: "Import Forecast",
        component: ImportForecast
      },
    ]
  },
  {
    key: "profile",
    path: "/profile",
    sidebarName: "User Profile",
    navbarName: "User Profile",
    icon: AccountCircle,
    component: Profile
  },
  {
    key: 'knowledgeBase',
    path: "/knowledgeBase",
    sidebarName: "Knowledge Base",
    navbarName: "Knowledge Base",
    icon: Dashboard,
    asButton: true,
    onClick: ()=>{
      let w = window.open('https://desk.zoho.com/portal/boxzooka/en/kb/boxzooka');
      if (!w) {
        alert("To enable print, please allow pop up window");
        return;
      }
    },
  },
  {
    key: 'logout',
    path: "/logout",
    sidebarName: "Logout",
    navbarName: "Logout",
    icon: ExitToApp,
    logout: true,
  },
  { key: 'root', hideMenu: true, path: "/", rootComponent: {
    // warehouse: WMSDashboard,
    // customer: ClientDashboard,
    warehouse: WMSDashboard,
    customer: ClientDashboard,
    // anonymous: Login
  }},
  // { key: 'redirect', redirect: true, path: "/", to: "/dashboard", toRoot: {
  //   customer: '/clientDashboard',
  //   warehouse: "/dashboard",
  // },navbarName: "Redirect" },
  {
    key: "pageNotFound",
    path: "/pageNotFound",
    sidebarName: "Not Found",
    navbarName: "Not Found",
    hideMenu: true,
    icon: AccountCircle,
    component: PageNotFound
  },
];

export default allRoutes;
