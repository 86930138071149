import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import {
    Typography,
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    ButtonGroup,
    IconButton
} from '@material-ui/core';
import {
  Edit,
  DeleteForever
} from '@material-ui/icons'
import DataContext from 'context/Data'
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from 'components';

import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class Waybill extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
          loading: false,
          waybill: [],
        };

        document.title = "Waybill";
    }

    render() {
        const { classes } = this.props;
        const { loading, email, report, report_types } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={2}>
            {loadingBar}
            {this.renderTable()}
          </Grid>
        );
    }

    componentWillMount() {
        this.loadWaybill();
    }

    // api call
    // load email table and report type options on page load
    loadWaybill = () => {
      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/waybill`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });

      this.setState({loading: true});
      req.then(this.loadWaybillSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadWaybillSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }
        this.setState({waybill: resp.data});
    }
    // add a new report email address
    addWaybill = () => {
      this.context.confirm('Are you sure to generate a new waybill?', this.addWaybillAjax);
    }
    addWaybillAjax = () => {   
      let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/waybill`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });

      this.setState({loading: true});
      req.then(this.addWaybillSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    addWaybillSuccess = (resp) => {
      this.setState({loading: false});
      let data = resp.data;
      if (data.Error) {
        this.context.alert(data.Error);
        return;
      }
      this.setState({waybill: resp.data});
    }
    printBol = ()=>{
      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/printbol/ontrac/${localStorage.getItem('warehouse_id')}`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });
  
      this.setState({loading: true});
      req.then(this.printSuccess.bind(this, false)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printSuccess = (same_page, resp) => {
    this.setState({loading: false});

    // alert error if any
    if (resp.data.Error) {
      this.props.alert(resp.data.Error);
      return;
    }

    if (!resp.data) {
      this.props.alert("Print barcode error");
      return;
    }
    utils.printPage(resp.data, same_page);
  }

    // render search result table
    renderTable = () => {
      let rows = Array.from(this.state.waybill ? this.state.waybill : []);

      let colSettings = [
          {
            key: 'waybill',
            label: 'Waybill',
            render: (val, key, row)=> {
              return val ? <Button size="small" style={{whiteSpace: "nowrap"}} onClick={()=>{
                let newTab = window.open('about:blank');
                if (newTab) newTab.location.href = val;
                else {
                  this.context.alert("Unable to open new tab, please change browser settings to allow pop-up.");
                }
              }}>View</Button> : "Waybill link not available"
            },
          }, {
            key: 'date',
            label: 'Date',
            // todo need to confirm whether need to localize time
            render: utils.localizeDate,
          }, {
            key: 'method',
            label: 'Shipping Method',
            render: utils.convertShippingMethod,
          }
      ];

      return (
        <GridItem sm={12}>
          <CardContainer>
              <Button onClick={this.addWaybill}>Generate Waybill</Button>    
              
              <Button  style={{float: 'right'}} onClick={this.printBol}>OnTrac BOL</Button>    
          
            <NewDataTable
              rows={rows}
              rowHeight='auto'
              noPagination
              columns={colSettings}
            />
          </CardContainer>
        </GridItem>
      );
    }
}
export default withStyles(styles)(Waybill)
