import React, {useState, useContext, useRef} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, Typography, InputLabel, Select, FormControl, IconButton, MenuItem } from '@material-ui/core'
import {
  Add,
  DeleteForever,
  Cancel,
  Announcement,
  ContactSupport,
  Error,
  ErrorOutline,
  Help,
  Info
} from '@material-ui/icons'
import utils from 'utils/utils'
import axios from 'axios'

import {CustomInput, BasicDialog, Button, NewDataTable, KeyValueTable, CardContainer, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    marginBottom: '.5rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
}))

export default function EditOrderItem(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);

  const {lineItems: defaultLineItems, handleClose, handleSubmit} = props;
  const [loading,setLoading] = useState(false);
  const [lineItems,setLineItems] = useState(defaultLineItems.map((item)=> Object.assign({}, item)));
  const [searchType,setSearchType] = useState('sku');
  const [productResult,setProductResult] = useState([]);
  const [singleProduct,setSingleProduct] = useState(null);
  
  // const searchByInputRef = useRef(null);
  const searchForInputRef = useRef(null);
  const qtyToAddInputRef = useRef(null);

  function searchProduct() {
    // let searchBy = searchByInputRef ? searchByInputRef.current?.value : '';
    let searchBy = searchType;
    let searchFor =searchForInputRef ? utils.formatString(searchForInputRef.current?.value, {multiline: true}) : '';
    if (searchBy == 'sku') searchFor = searchFor.split(',');

    if (!searchFor) {
      dataContext.alert('Search For input field is empty');
      return;
    }
    
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/product`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: searchBy,
        data: searchFor,
      },
    });
    // let req = axios({
    //   method: 'post',
    //   url: `${utils.getBaseUrl('customer')}/inventory`,
    //   headers: {
    //     token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
    //   },
    //   data: {
    //     type: 'sku',
    //     data: searchFor,
    //   },
    // });
    setLoading(true);
    req.then(searchProductSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert, errorCallback: function() {setLoading(false)}}));
  }
  function searchProductSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    setSingleProduct(null);
    setProductResult(resp.data);
  }
  function loadProductInventory(sku) {
     let req = axios({
       method: 'post',
       url: `${utils.getBaseUrl('customer')}/inventory`,
       headers: {
         token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
       },
       data: {
         type: 'inventory',
         keyword: [sku],
       },
     });
     setLoading(true);
     req.then(loadProductInventorySuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert, errorCallback: function() {setLoading(false)}}));
  }
  function loadProductInventorySuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    setSingleProduct(Object.assign(resp.data.item_detail[0], {in_stock: resp.data.in_stock}));
  }

  function addLineItem() {
    let qtyToAddInput = qtyToAddInputRef ? parseInt(qtyToAddInputRef.current?.value) : '';
    if (!qtyToAddInput || qtyToAddInput <= 0) {
      dataContext.alert('Nothing to add!');
      return;
    }
    // if existing, add quantity
    let matchedIndex = -1;
    let newItems = lineItems.map((lineItem, index)=>{
      if (lineItem.sku === singleProduct['sku']) {
        lineItem.quantity = parseInt(lineItem.quantity) + parseInt(qtyToAddInput);
        matchedIndex = index;
      }
      return lineItem;
    });  

    const {sku, upc, item_id, description, sale, item_weight, wholesale} = singleProduct;

    if (matchedIndex < 0) newItems.push({
      quantity: qtyToAddInput,
      sku,
      upc,
      item_id,
      item_name: description,
      lineitem_id: '',
      item_weight: item_weight,
      item_status: "UNBATCH",
      retail_value: sale || 1,
      wholesale_value: wholesale || 1
    });

    setLineItems(newItems);
  }
  function onLineItemQtyChange(newQty, update_item) {
    // let update_item_id = update_item.item_id;
    let update_item_id = update_item.sku;
    newQty = parseInt(newQty);

    if (!newQty || newQty < 1) return;

    let newItems = lineItems.map((prod)=>{
      // if (prod.item_id === update_item_id) prod.quantity = newQty;
      if (prod.sku === update_item_id) prod.quantity = newQty;
      return prod;
    });
    setLineItems(newItems);
  }
  function removeLineItem(delete_item) {
    // let delete_item_id = delete_item.item_id;
    let delete_item_id = delete_item.sku;
    let deleteIndex = -1;
    let newItems = lineItems.map((lineItem, index)=>{
      if (lineItem.sku === delete_item_id) {
        if (lineItem.lineitem_id) lineItem.quantity = 0; // if this is from backend, don't remove from array, set qty to 0
        else deleteIndex = index; // if this is new added, remove from array, record delete inex
      }
      return lineItem;
    });
    if (deleteIndex >= 0) newItems.splice(deleteIndex, 1);
    setLineItems(newItems);
  }

  function onBackToListClick() {
    setSingleProduct(null);
  }

  function renderFormContent() {
    return (
      <GridItem xs={12} sm={4} md={3}>
        <CardContainer>
        <div>
          {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

          <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Search By</InputLabel>
            <Select
              // defaultValue="sku"
              // inputProps={{ref: searchByInputRef}}
              value={searchType}
              onChange={(e)=>setSearchType(e.target.value)}
            >
              <MenuItem value='sku'>SKU/UPC</MenuItem>
              <MenuItem value='item_name'>Item Name</MenuItem>
              <MenuItem value='style'>Style</MenuItem>
              <MenuItem value='category'>Category</MenuItem>
              <MenuItem value='other'>Other Fields</MenuItem>
            </Select>
          </FormControl>
            
          {
            searchType == 'sku' ? 
            <CustomInput
              labelText='Search For'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput
              }}
              inputProps={{
                inputProps: {ref:searchForInputRef},
                defaultValue: "",
                placeholder: 'Use Linebreak to separate multiple keywords',
                multiline: true,
                rows: 3
              }}
            /> : <CustomInput
                  labelText='Search For'
                  labelProps={{shrink: true}}
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                  }}
                  inputProps={{
                    inputProps: {ref:searchForInputRef},
                    defaultValue: "",
                  }}
                />
          }
          
          <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
            <Button onClick={searchProduct}>Search</Button>
          </div>
        </div>
      </CardContainer>
      </GridItem>
    );
  }
  function renderSearchResult() {
    if (!productResult || productResult.length < 1) return null;

    let contentTable = (
      <NewDataTable
        rows={productResult}
        // rowHeight={50} 
        columns={[
          {
            key: 'sku',
            label: 'SKU',
            render: (sku, key, product, index)=>{
              return (
                <Button color='default' onClick={()=>{loadProductInventory(sku)}}>{sku}</Button>
              );
            },
          },
          {
            key: 'item_name',
            label: 'Item Name',
            width: 'auto'
          },
        ]}
        maxHeight={500}
      />
    );

    if (singleProduct) {
      let propertyList = [
        {
          key: 'sku',
          label: 'SKU',
        },
        {
          key: 'upc',
          label: 'UPC',
        },
        {
          key: 'style',
          label: 'Style',
        },
        {
          key: 'size',
          label: 'Size',
        },
        {
          key: 'color',
          label: 'color',
        },
        {
          key: 'image',
          label: 'Image',
          render: (val, key, row)=>{
            if (val) {
              return (<img className={classes.tableDetailImage} alt="wrong url" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
                dataContext.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
              }} src={val}/>);
            } else {
              return "No Image";
            }
          }
        },
        {
          key: 'brand',
          label: 'Vendor',
        },
        {
          key: 'description',
          label: 'Item Name',
        },
        {
          key: 'in_stock',
          label: 'Quantity In Stock',
        },
        {
          key: 'sale',
          label: 'Retail Value',
        },
        {
          key: 'wholesale',
          label: 'Wholesale Value',
        },
        {
          key: 'add_cart',
          label: 'Add Quantity to Order',
          render: (val, key, row) => {
            return (
              <div>
                <CustomInput
                 formControlProps={{
                   className: classes.customInput
                 }}
                 inputProps={{
                  type: 'number',
                  inputProps:{
                    min: 0,
                    step: 1
                  },
                  inputProps: {
                    ref: qtyToAddInputRef,
                  },
                  defaultValue: "",
                 }}
                />

              <IconButton onClick={addLineItem} size="small" style={{backgroundColor: "#3f51b5", marginLeft: ".5rem"}} variant="fab" aria-label="Add">
                <Add style={{color: 'white'}}/>
              </IconButton>

                {/* <IconButton style={{backgroundColor: "red"}} variant="fab" onClick={addLineItem} aria-label="Add" style={{marginLeft: ".5rem"}}>
                  <Add style={{color: 'white'}}/>
                </IconButton> */}
              </div>
            );
          }
        },
      ];

      contentTable = (
        <KeyValueTable
          maxHeight={500}
          // rowHeight={50}
          propertyList={propertyList}
          dataObj={singleProduct}
        />
      );
    }

    return (
      <GridItem xs={12} sm={8} md={9}>
        <CardContainer className={classes.itemSearchContainer}>
          {singleProduct && (
            <div className={classes.toolBar}>
              <Button color='default' variant="outlined" onClick={onBackToListClick}>Back to List</Button>
            </div>
          )}

          {contentTable}
        </CardContainer>
      </GridItem>
    );
  }
  function renderLineItemTable() {
    let rowSettings = {
      rowProps: {
          hover: false
      },
      classNameOnDisplay: (data, displayProps) =>{
          if (!displayProps) displayProps = {
              className: ""
          };

          let classes = displayProps.className ? displayProps.className : "";
          classes = classes.split(" ");

          let tr_color = '';
          // decide the row color base on the receiving status
          if (parseInt(data['quantity']) == 0) tr_color = "light-red";
          classes.push(tr_color);

          displayProps.className = classes.join(" ");
          return displayProps;
      }
  };

    return (
      <GridItem xs={12}>
        <CardContainer>
          <Typography variant="h6">Lineitems</Typography>

          <NewDataTable
            // rows={lineItems.filter((elem)=>elem.quantity > 0)}
            rows={lineItems}
            // rowHeight={40} 
            noPagination
            rowSettings={rowSettings}
            columns={[
              {
                key: 'sku',
                label: 'SKU',
              },
              {
                key: 'item_name',
                label: 'Item Name',
              },
              {
                key: 'image',
                label: 'Image',
                render: (val, key, row)=>{
                  if (val) {
                    return (<img className={classes.tableDetailImage} alt="wrong url" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
                      dataContext.alert(<img src={val} alt="wrong url" style={{width: '100%'}}/>, {type: row.sku || 'Product Image'});
                    }} src={val}/>);
                  } else {
                    return "No Image";
                  }
                }
              },
              {
                key: 'quantity',
                label: 'Quantity',
                render: (val, key, product, index)=>{
                  return (
                    <CustomInput
                      formControlProps={{
                        className: classes.customInput
                      }}
                      inputProps={{
                        type: 'number',
                        inputProps:{
                          // min: 1,
                          min: product.lineitem_id ? 0 : 1,
                          step: 1
                        },
                        onChange: (e) => {
                          onLineItemQtyChange(e.target.value, product)
                        },
                        value: val,
                      }}
                    /> 
                  );
                },
              },
              {
                key: 'item_status',
                label: 'Status',
              },
              {
                key: 'remove',
                label: 'Remove',
                width: 140,
                render: (val, key, product, index)=>{
                  if (product.quantity > 0) {
                    return (
                      <IconButton color="secondary" onClick={()=>{removeLineItem(product)}} variant="fab" aria-label="Add" size="small" style={{marginLeft: ".5rem"}}>
                        <DeleteForever/>
                      </IconButton>
                    );
                  } else {
                    return 'CANCELLED';
                  } 
                },
              },
            ]}
          />
        </CardContainer>
      </GridItem>
    );
  }
  function onSubmit () {
    handleSubmit(lineItems);
  }

  let title = "Edit Lineitems";

  return (
    <BasicDialog
      fullWidth
      maxWidth='lg'
      open
      onClose={(e, reason)=>{if (reason != 'backdropClick' && reason != 'escapeKeyDown') handleClose();}}
      header={title}
      content={<Grid container className={classes.root} spacing={2}>
        {renderFormContent()}
        {renderSearchResult()}
        {renderLineItemTable()}
      </Grid>}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}