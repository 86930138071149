// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import moment from 'moment-timezone'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Grid,
    withStyles, CardActionArea, CardContent
} from '@material-ui/core';
// BXZ render modules
import {
    CardIcon,
    NewDataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import DataContext from 'context/Data'
import {EuroSymbol, FlightLand, ShoppingBasket, Assignment} from "@material-ui/icons";
// Our view components

const styles = theme => ({
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  floatingIconContainer: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(-1)
  },
  statCard: {
      display: "flex"
  },
  statIconContainer: {
      // backgroundColor: "#29b765",
      borderRadius: "3px",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
      marginTop: theme.spacing(-2),
  },
  statIcon: {
      color: "white",
      fontSize: theme.spacing(4),
      // margin: theme.spacing(2, 5),
      margin: theme.spacing(2),
  },
  statValueContainer: {
      flexGrow: "1",
      padding: theme.spacing(1),
      paddingBottom: ".5rem !important",
      // backgroundColor: "#2ecc71",
      // color: "white",
      fontSize: "1rem"
  },
  statTable : {
      height: "100%",
      width: "100%"
  },
  clickEnabled: {
      cursor: "pointer",
  },
  clickDisabled: {
      cursor: "default",
  },
  statLabelCell: {
      // textAlign: "left",
      textAlign: "center",
      // color: "#999",
      // fontFamily: 'Raleway-Regular',
      fontFamily: 'Roboto-Regular',
      fontSize: "1.25rem",
      // whiteSpace: "nowrap",
      whiteSpace: "inherit",
      // width: theme.spacing(18)
  },
  statValeCell: {
      // textAlign: "right",
      textAlign: "center",
      // whiteSpace: "nowrap",
      whiteSpace: "inherit",
      fontSize: "1.25rem",
      // fontFamily: 'Raleway-Bold',
      fontFamily: 'Roboto-Bold',
      fontWeight: "bold",
      flexGrow: "1"
  },
});

const CHART_SETTINGS = {
    'annual_return_value': {
        type: 'line',
        title: 'Total Returned Revenue',
        datasets: [
            {value: 'order_value', label: 'Returned Revenue', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return '$' + utils.numberWithComma(value)},
    },
    'annual_return_order': {
        type: 'line',
        title: 'Total Returned Orders',
        datasets: [
            {value: 'return_number', label: 'Number of Returns', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.numberWithComma(value)},
    },
    'annual_return_unit': {
        type: 'line',
        title: 'Total Returned Units',
        datasets: [
            {value: 'return_unit', label: 'Returned Units', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.numberWithComma(value)},
    },
    'annual_return_percentage': {
        type: 'line',
        title: 'Average Return Rate',
        datasets: [
            {value: 'percentage', label: 'Returned Rate', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.formatPercentage(value, 2)},
    },
};


class ReturnAnalysis extends Component {
    static contextType = DataContext;  

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.charts_ref = {};

        this.state = {
            dashboard_stats: null,
            stats_detail: [],
            stat_type: '',
            top10_return_product: [],
            top10_return_name: [],
            date_from: '',
            date_to: '',
            loading: false,
        };

        document.title = "Return Analysis";
    }

    componentWillMount() {
        this.loadStats();
    }
    componentDidUpdate(prevProps, prevState) {
        // separate the chart data because there are multiple charts and the chart data and load from different api
        let updating = [];
        if (!utils.is_obj_equal(this.state.stats_detail, prevState.stats_detail) && this.state.stat_type) {
            updating.push(this.state.stat_type);
        }
        if (updating.length > 0) this.updateChart(updating);
    }

    updateChart(updating) {
        if (!updating || updating.length <= 0) 
        {
            return;
        }

        let Chart = utils.Chart; // All charts
		let ChartDataLabels = utils.ChartDataLabels; // All charts needs label

        let charts = this.charts_ref;

        for (let chartId of updating)
        {
            if (!document.getElementById(chartId) && !document.getElementById('stats_detail')) continue;
            // const ctx = document.getElementById(chartId).getContext('2d');
            const ctx = document.getElementById(chartId) || document.getElementById('stats_detail');
            let display_stats_detail = false;   
            let chartSettings = CHART_SETTINGS[chartId];
            let chartData = this.state[chartId];
            if (!chartData) 
            {
                chartData = this.state['stats_detail'];
                chartId = 'stats_detail'; 
            }

            if (charts[chartId])
            {
                charts[chartId].destroy();
            }

            // Regular Charts
            ctx.style.minHeight = '300px';
            charts[chartId] = new Chart(ctx, {
                type: chartSettings.type,
                data: {
                  labels: chartData.map(data_elem=>data_elem[chartSettings.xlabelKey]),
                  datasets:  
                      chartSettings.datasets.map(setting_elem=>{return {
                      label: setting_elem.label,
                      data: chartData.map(data_elem=>data_elem[setting_elem.value]),
                      backgroundColor: setting_elem.color,
                      borderColor: 'black',
                      borderWidth: 1
                  }})
                },
                plugins: [ChartDataLabels],
                options: {
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  },
                  plugins: {
                      title: {
                          display: true,
                          text: chartSettings.title
                      },
                      legend: {
                          display: this.state.showDataLabels // Toggle display of data labels
                      },
                      tooltip: {
                          mode: 'index',
                          callbacks: {
                              label: (tooltipItems) => {
                                    var label = tooltipItems.dataset.label || '';
                                    let data_value = tooltipItems.raw;
                                    if (chartSettings.formatDataValue)
                                    {
                                        data_value = chartSettings.formatDataValue(data_value);
                                    }
                                    label += ': ' + data_value; // formattedValue
                                    return label;
                              },
                              title: (tooltipItems) => {
                                    let value = tooltipItems[0].label;
                                    if (chartSettings.formatXLabel) return chartSettings.formatXLabel(value);
                                    else return value;
                              },
                          }
                      },
                      datalabels: {
                            backgroundColor: function(context) {
                                return context.dataset.backgroundColor;
                            },
                            // anchor: 'end',
                            // align: 'end',
                            anchor: function(context) {
                                // Set different anchor based on dataset index
                                return context.datasetIndex === 0 ? 'end' : 'center';
                            },
                            align: function(context) {
                                // Set different align based on dataset index
                                // return context.datasetIndex === 0 ? 'end' : 'start';
                                return 'end';
                            },
                            offset: 4,
                            // offset: function(context) {
                            //     // Set different offsets based on dataset index
                            //     return context.datasetIndex === 0 ? 0 : 5;
                            // },
                            borderRadius: 4,
                            color: 'white',
                            font: {
                                weight: 'bold'
                            },
                            formatter: chartSettings.formatDataValue || function(){},
                            padding: 6
                      }, // chart data label plugin
                  },
                  responsive: true,
                  aspectRatio: 2,
                  maintainAspectRatio: false,
                }
            });
        }
    }

    // api call
    // load initial data
    loadStats = () => {
        let customer = localStorage.getItem('customer_id');
        let warehouse = localStorage.getItem('warehouse_id');
        if (!customer || !warehouse) {
            // if customer of warehouse is not set, don't make ajax call
            this.context.alert("Customer id or Warehouse id is not set, please select warehouse or login again");
            return;
        }

        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/analysis/return`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        req.then(this.loadStatsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
        this.setState({loading: true});
    }
    loadStatsSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
            this.context.alert(resp.data.Error);
            return;
        }

        this.setState({
            dashboard_stats: resp.data,
            top10_return_name: resp.data.top10_return_name,
            top10_return_product: resp.data.top10_return_product,
        });
    }
    // load stat details
    loadDetail = (type) => {
        let date_from = '';
        let date_to = '';
        if (!type) {
            // if type is not passed in, it is loading by date range, get data from state
            type = this.state.stat_type;
            date_from = this.state.date_from;
            date_to = this.state.date_to;
        } else {
            // if type is passed in, it is loading default, reset date range
            this.setState({date_from: '', date_to: ''})
        }

        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/analysis/return`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: {
                type,
                date_from,
                date_to,
            },
        });

        this.setState({loading: true});
        req.then(this.loadDetailSuccess.bind(this, type)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadDetailSuccess = (type, resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.context.alert(resp.Error);
            return;
        }

        console.log(resp);

        if (!resp || (Array.isArray(resp) && resp.length === 0)) {
            this.context.alert("No data found.");
            return;
        }
        this.setState({stats_detail: resp, stat_type: type});
    }

    // render func
    renderStats = () => {
        let dashboard_stats = this.state.dashboard_stats ? this.state.dashboard_stats : {
            annual_return_value: "N/A",
            annual_return_order: "N/A",
            annual_return_unit: "N/A",
            annual_return_percentage: "N/A",
        };

        let revenue = (dashboard_stats.annual_return_value !== "N/A" ? '$' : "") +utils.numberWithComma(dashboard_stats.annual_return_value);
        let returns = utils.numberWithComma(dashboard_stats.annual_return_order);
        let units = utils.numberWithComma(dashboard_stats.annual_return_unit);
        let percentage = utils.formatPercentage(dashboard_stats.annual_return_percentage);

        return (
          <React.Fragment>
              {this.renderSingleStatCard(revenue, "annual_return_value")}
              {this.renderSingleStatCard(returns, "annual_return_order")}
              {this.renderSingleStatCard(units, "annual_return_unit")}
              {this.renderSingleStatCard(percentage, "annual_return_percentage")}
          </React.Fragment>
        );
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        if (!val || val === "N/A") click_disabled = true;

        let title = key;
        let icon = null;
        switch (key) {
            case "annual_return_value":
                icon = <CardIcon style={{
                    backgroundColor: "#ffa726"
                }} className={classes.statIconContainer}>
                    <EuroSymbol className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Revenue";
                break;
            case "annual_return_order":
                icon = <CardIcon style={{
                    backgroundColor: "#26c6da"
                }} className={classes.statIconContainer}>
                    <FlightLand className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Returns";
                break;
            case "annual_return_unit":
                icon = <CardIcon style={{
                    backgroundColor: "#66bb6a"
                }} className={classes.statIconContainer}>
                    <ShoppingBasket className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Units";
                break;
            case "annual_return_percentage":
                icon = <CardIcon style={{
                    backgroundColor: "#8e24aa"
                }} className={classes.statIconContainer}>
                    <Assignment className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Percentage";
                break;
        }

        let limit_width = document.getElementById('main-app-container') ? (document.getElementById('main-app-container').offsetWidth < 1200) : false;

        return (
          <GridItem xs={12} sm={6} md={limit_width ? 6 : 3}>
              <CardContainer allowOverflow noPadding>
                  <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                      <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadDetail(key);}}>
                          <div className={classes.floatingIconContainer}>
                              {icon}
                          </div>

                          <CardContent className={classes.statValueContainer}>
                              <table className={classes.statTable}>
                                  <tbody>
                                  <tr><td style={{
                                      whiteSpace: "normal"
                                  }} className={classes.statLabelCell}>{title}</td></tr>
                                  <tr><td className={classes.statValeCell}>{val}</td></tr>
                                  </tbody>
                              </table>
                          </CardContent>
                      </div>
                  </CardActionArea>
              </CardContainer>
          </GridItem>
        );
    }
    renderStatsDetail = () => {
        const { classes } = this.props;
        const { stats_detail, stat_type, date_from, date_to } = this.state;

        if (!stats_detail || stats_detail.length === 0 || !stat_type) return null;

        let detail_title = stat_type;
        let chart_value = '';
        let data = Array.from(stats_detail);

        let colSettings = [];
        switch (stat_type) {
            case 'annual_return_value':
                detail_title = "Total Returned Revenue";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'order_value',
                        label: 'Returned Revenue',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "order_value";
                break;
            case 'annual_return_order':
                detail_title = "Total Returned Orders";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'return_number',
                        label: 'Number of Returns',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "return_number";
                data = data.map((val)=>{return {
                    month: val.month,
                    return_number: parseInt(val.return_number)
                };});
                break;
            case 'annual_return_unit':
                detail_title = "Total Returned Units";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'return_unit',
                        label: 'Returned Units',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "return_unit";
                data = data.map((val)=>{return {
                    month: val.month,
                    return_unit: parseInt(val.return_unit)
                };});
                break;
            case 'annual_return_percentage':
                detail_title = "Average Fulfilled Rate";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_value',
                        label: 'Sold Value',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    },
                    {
                        key: 'return_value',
                        label: 'Returned Value',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    },
                    {
                        key: 'percentage',
                        label: 'Percentage',
                        render: (val) => {
                            if (!val) val = 0;
                            return (parseFloat(val*100).toFixed(2)+'%')
                        }
                    }
                ];
                // todo need to modify the chart setting to show percentage, may also need to format data
                chart_value = "percentage";
                data = data.map((val)=>{return {
                    month: val.month,
                    percentage: parseFloat((val.percentage ? val.percentage : '0'))
                };});
                break;
        }

        let rows = Array.from(stats_detail);

        return (
            <GridItem xs={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h6" id="tableTitle">
                                {detail_title}
                            </Typography>
                            <br/>

                            <CustomInput
                              labelText='Date From'
                              formControlProps={{
                                  required: true,
                                  style: {minWidth: '140px'},
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  style:{marginRight: '1rem'},
                                  views:['year', 'month'],
                                  type: 'date',
                                  value: date_from,
                                  onChange: (e)=>{this.setState({date_from: e.target.value})}
                              }}
                            />
                            <CustomInput
                              labelText='Date To'
                              formControlProps={{
                                  required: true,
                                  style: {minWidth: '140px', marginRight: '1rem'},
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  type: 'date',
                                  value: date_to,
                                  onChange: (e)=>{this.setState({date_to: e.target.value})}
                              }}
                            />
                            <Button style={{marginTop: "1rem"}}  onClick={()=>{
                                const { date_from, date_to } = this.state;
                                if (moment(date_to).isBefore(date_from)) this.context.alert("Date to should be later than Date from.");
                                else this.loadDetail();
                            }}>Submit</Button>
                        </div>

                        <div style={{width: '100%', minHeight: '300px', position: 'relative'}}>
                            <canvas id="stats_detail"></canvas>
                        </div>

                        <NewDataTable
                          rows={rows}
                          className={classes.table}
                          noPagination
                          columns={colSettings}
                        />

                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderTopProducts = () => {
        const { classes } = this.props;
        const { top10_return_product } = this.state;

        let rows = top10_return_product ? top10_return_product : [];

        let colSettings = [
            {
                key: 'sku',
                label: "SKU",
            },
            {
                key: 'ItemName',
                label: "ItemName",
            },
            {
                key: 'sold_unit',
                label: "Sold",
                render: utils.numberWithComma
            },
            {
                key: 'return_unit',
                label: "Returned",
                render: utils.numberWithComma
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h6" id="tableTitle">
                            Top Products
                            {rows.length > 0 && <Button style={{float: 'right'}}  onClick={()=>{utils.exportTableToCsv("top_products");}}>Export</Button>}
                        </Typography>
                    </div>

                    <NewDataTable
                      rows={rows}
                      className={classes.table}
                      id="top_products"
                      noPagination
                      maxHeight={300}
                      minHeight={300}
                      columns={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }
    renderTopCustomers = () => {
        const { classes } = this.props;
        const { top10_return_name } = this.state;

        let rows = top10_return_name ? top10_return_name : [];

        let colSettings = [
            {
                key: 'name',
                label: "Name",
            },
            {
                key: 'return_unit',
                label: "Unit",
                render: utils.numberWithComma
            },
            {
                key: 'return_value',
                label: "Value",
                render: (val) => {
                    return ('$' + utils.numberWithComma(val));
                }
            },
        ];

        return <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h6" id="tableTitle">
                            Top Customers
                            {rows.length > 0 && <Button style={{float: 'right'}}  onClick={()=>{utils.exportTableToCsv("top_customers");}}>Export</Button>}
                        </Typography>
                    </div>

                    <NewDataTable
                      rows={rows}
                      className={classes.table}
                      id="top_customers"
                      noPagination
                      maxHeight={300}
                      minHeight={300}
                      columns={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }

    render() {
        let loadingBar = null;
        if (this.state.loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={2}>
                {loadingBar}

                {this.renderStats()}
                {this.renderStatsDetail()}
                {this.renderTopProducts()}
                {this.renderTopCustomers()}
            </Grid>
        );
    }
}

export default withStyles(styles)(ReturnAnalysis)
