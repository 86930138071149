import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Slide, Snackbar} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function withSnackbar(WrappedComponent) {
  class ComponentWithSnackbar extends Component {
    static propTypes = {
      classes: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.state = {
        open: false,
        messageContent: null,
        displayProps: {},
      };
    }

    display = (messageContent, displayProps) => {this.setState({open: true, messageContent, displayProps: displayProps || {}})}
    hide = () => {
      if (this.state.displayProps.hideCallback) this.state.displayProps.hideCallback();
      this.setState({open: false, messageContent: null, snackProps: {}})
    }

    render() {
      const {open, messageContent, displayProps } = this.state;

      let content = null;
      let snackProps = {
        TransitionComponent: Slide,
        transitionDuration: 500,
        sx: { height: "100%" },
        style: {fontSize: '2rem'},
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        },
        autoHideDuration: 1500,
      };

      let defaultSeverity = 'success';
      if (displayProps.severity) defaultSeverity = displayProps.severity;

      if (displayProps) snackProps = Object.assign(snackProps, displayProps); // overwrite snackbar props if given
      if (typeof messageContent == 'object') content = messageContent;
      else {
        snackProps.autoHideDuration = String(messageContent).length/15*1500;
        content = (
          <Alert style={{fontSize: '1rem', fontWeight: 'bold'}} elevation={6} onClose={this.hide} severity={defaultSeverity}>{messageContent}</Alert>
        );
      }
      
      return (
        <React.Fragment>
          <WrappedComponent snackDisplay={this.display} snackHide={this.hide} {...this.props}/>

          <Snackbar
            {...snackProps}
            onClose={this.hide}
            open={open} 
          >
            <div>{content}</div>
          </Snackbar>
        </React.Fragment>
      );
    }
  }

  ComponentWithSnackbar.displayName = `WithSnackbar(${getDisplayName(WrappedComponent)})`;

  return ComponentWithSnackbar;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
