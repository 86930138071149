import { createTheme } from '@material-ui/core/styles'
// FONTS
import {
    raleway,
    ralewayBold,
    neuton
} from 'assets/fonts'

const theme = createTheme({
    typography: {
      h4: {
        fontSize: "2rem"
      },
      h5: {
        fontWeight: '400',
        // fontWeight: 'normal'
      },
      h6: {
        fontWeight: '400',
        // fontWeight: 'normal'
      },
      root: {
        fontFamily: 'Roboto',
        // fontWeight: '300',
        fontWeight: 'normal'
      },
      // fontFamily: ['Raleway', 'Raleway-Bold'].join(','),
      // fontFamily: 'Raleway-Regular',
      fontFamily: 'Roboto',
      // fontWeight: '300',
      fontWeight: 'normal'
    },
    overrides: {
      MuiDialogContent: {
        root: {
          fontSize: '1rem',
          // fontWeight: 'bold'
        },
      },
      MuiInputLabel: {
        root: {
          color: "#000",
          // fontSize: "1.25rem",
          fontSize: "1.25rem",
          // fontFamily: "Raleway-Bold"
          fontFamily: "Roboto",
          // fontWeight: '400'
          fontWeight: 'normal'
        },
        shrink: {
          color: "#000",
          // fontSize: "1.25rem",
          fontSize: "1.25rem",
          // fontFamily: "Raleway-Bold"
          fontFamily: "Roboto",
          // fontWeight: '400'
          fontWeight: 'normal'
        }
      },
      MuiTableCell: {
        root: {
          // fontSize: "1.25rem",
          // fontSize: "1.125rem",
          fontSize: "1rem",
          // fontWeight: '300',
          fontWeight: '400',
        },
        head: {
          // fontFamily: 'Raleway-Bold',
          fontFamily: 'Roboto',
          // fontWeight: '400',
          fontWeight: 'bold',
          // fontSize: "1.25rem"
          fontSize: "1.125rem",
        }
      },
      MuiCssBaseline: {
        '@global': {
          // '@font-face': [raleway, ralewayBold],
          // '@font-face': [raleway],
          '@font-face': ['Roboto'],
  
        },
      },
    },
});

export default theme;