import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import _ from 'lodash'
import { FormControl, FormControlLabel, Switch, Grid, Tabs, Tab, AppBar, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import CustomerInfo from 'views/Customer/CustomerTabs/CustomerInfo'
import ShippingInfo from 'views/Customer/CustomerTabs/ShippingInfo'
import BillingAgreement from 'views/Customer/CustomerTabs/BillingAgreement'
import BillingAgreementReadOnly from 'views/Customer/CustomerTabs/BillingAgreementReadOnly'
import SupplyRate from 'views/Customer/CustomerTabs/SupplyRate' 
import ShippingMap from 'views/Customer/CustomerTabs/ShippingMap'
import WebsiteAccess from 'views/Customer/CustomerTabs/WebsiteAccess'
import Account from 'views/Customer/CustomerTabs/Account'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class ManageCustomers extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.setup_process = 0;
    this.agreement_types = [];
    this.customer_id = '';

    this.state = {
      loading: false,
      add_new: false,
      active_customer: null,
      active_tab: 0,
      customers: null,
      dropdown: false,
      regular_list: [],
      supply_sku_list: [],
    };

    document.title = "Manage Customers";
  }

  render() {
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        {this.renderContent()}
      </Grid>
    );
  }

  componentWillMount() {
    this.loadCustomers();
  }

  resetPage = () => {
    this.setup_process = 0;
    this.agreement_types = [];
    this.customer_id = '';

    this.setState({
      add_new: false,
      active_customer: null,
      active_tab: 0,
      customers: null,
      dropdown: false,
      regular_list: [],
      supply_sku_list: [],
    });
  }

  // API call
  loadCustomers = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/customer`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadCustomersSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadCustomersSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
     this.context.alert(resp.data.Error);
      return;
    }

    this.setState({
      customers: resp.data.customer_list,
      regular_list: resp.data.regular_list,
      supply_sku_list: resp.data.supply_sku_list
    });
  }
  loadSingleCustomer = (customer) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/customer/${customer.customer_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.customer_id = '';
    this.setState({loading: true, active_customer: null, active_tab: 0});
    req.then(this.loadSingleCustomerSuccess.bind(this, customer.customer_id)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadSingleCustomerSuccess = (customer_id, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
     this.context.alert(resp.data.Error);
      return;
    }

    let keys = [];
    let agreements = {};
    for (let agreement of resp.data.billing_agreement) {
        keys.push(agreement.type);
        agreements[agreement.type] = agreement;
    }
    this.customer_id = customer_id;
    this.agreement_types = keys;
    resp.data.billing_agreement = agreements;

    // format address, it is array
    resp.data.shipping_information.shipping_address  = resp.data.shipping_information.shipping_address[0];
    resp.data.shipping_information.return_address  = resp.data.shipping_information.return_address[0];
    utils.scrollToTop();
    this.setState({active_customer: resp.data, active_tab: 0});
  }
  createCustomer = (data) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customer`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data
    });

    this.setState({loading: true});
    req.then(this.createCustomerSuccess.bind(this, data)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  createCustomerOnce = _.once(this.createCustomer);
  resetSubmitOnce = () => {this.createCustomerOnce = _.once(this.createCustomer);} // reset the submit picking once after the ajax call returns
  createCustomerSuccess = (reqData, resp) => {
    this.createCustomerOnce = _.once(this.createCustomer);
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    this.customer_id = resp.data;
    let new_customer = Object.assign({}, this.state.active_customer);
    new_customer.customer_information = reqData;
    this.setState({active_customer: new_customer});
    this.setup_process++;

    let new_tab = 1 + this.state.active_tab;
    this.setState({active_tab: new_tab});
  }
  updateCustomer = (data, callback) => {
    let form_data = new FormData(); 
    if (data.shipping_information) {
      delete data.shipping_information.logo;
      form_data.append('logo', data.logo);
      form_data.append('customer_id', this.customer_id);
      form_data.append('shipping_information', JSON.stringify(data.shipping_information));
    } else {
      form_data = data;
      form_data.customer_id = this.customer_id;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customerdetail`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: form_data,
    });

    this.setState({loading: true});
    req.then(this.updateCustomerSuccess.bind(this, data, callback)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  updateCustomerSuccess = (reqData, callback, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
     }

     let new_customer = Object.assign({}, this.state.active_customer);
     new_customer = Object.assign(new_customer, reqData);
     delete new_customer.logo;

     let new_tab = this.state.active_tab;
     if (this.state.add_new && new_tab < 4) {
         // if is creating new customer, move to next tab
         new_tab++;
         // if create customer information success, unlock next tab
         this.setup_process++;
     } else {
      if (callback) callback();
      // If customer status is changed, need to reload page 
      if (reqData.status !== undefined)
      {
        this.resetPage();
        this.loadCustomers();
        return;
      }
     }
     this.setState({active_customer: new_customer, active_tab: new_tab});

     utils.scrollToTop();
  }
  // updateCustomerStatus = (new_customer) => {
  //   let req = axios({
  //     method: 'post',
  //     url: `${utils.getBaseUrl('customer')}/customerdetail`,
  //     headers: {
  //       token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
  //     },
  //     data: new_customer,
  //   });

  //   this.setState({loading: true});
  //   req.then(this.updateCustomerStatusSuccess.bind(this, new_customer)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  // }
  // updateCustomerStatusSuccess = (new_customer, resp) => {
  //   this.setState({loading: false});
  //   if (resp.data.Error) {
  //     this.context.alert(resp.data.Error);
  //      return;
  //   }

  //   // refresh custome list after update
  //   this.loadCustomers();
  // }

  onCustomerStatusChange = (new_status, callback) => {
    let data = {status: new_status};
    this.updateCustomer(data, callback);
  }
  onCustomerInfoChange = (customer_info, callback) => {
    let data = {customer_information: customer_info};
    if (this.state.add_new) this.createCustomerOnce(data);
    else this.updateCustomer(data, callback);
  }
  onShippingInfoChange = (shipping_info, callback) => {
    let data = {
      shipping_information: shipping_info,
      logo: shipping_info.logo
    };

    this.updateCustomer(data, callback);
  }
  onAgreementsChange = (newAgreements, newKeys) => {
    this.agreement_types = newKeys ? newKeys : this.agreement_types;

    let newCustomer = Object.assign({}, this.state.active_customer);
    newCustomer.billing_agreement = newAgreements;
    this.setState({active_customer: newCustomer});
  }
  onSupplyRateChange = (customer_supply_rate) => {
    let new_customer = Object.assign({}, this.state.active_customer);
    new_customer.customer_supply_rate = customer_supply_rate;
    this.setState({active_customer: new_customer});
  }
  onMarketingInsertChange = (customer_marketing_insert) => {
    let new_customer = Object.assign({}, this.state.active_customer);
    new_customer.customer_marketing_insert = customer_marketing_insert;
    this.setState({active_customer: new_customer});
  }
  onShippingMapChange = (shipping_map) => {
    let new_customer = Object.assign({}, this.state.active_customer);
    new_customer.shipping_map = shipping_map;
    this.setState({active_customer: new_customer});
  }
  onWebsiteChange = (website) => {
    let new_customer = Object.assign({}, this.state.active_customer);
    new_customer.website = website;
    this.setState({active_customer: new_customer});
  }
  onAccountChange = (account) => {
    let new_customer = Object.assign({}, this.state.active_customer);
    new_customer.account = account;
    this.setState({active_customer: new_customer});
  }

  backToList = () => {
    this.customer_id = '';
    this.setup_process = 0;
    this.setState({active_customer: null, add_new: false, active_tab: 0});
  }

  renderContent = () => {
    if (this.state.add_new) {
      return this.renderNewCustomer();
    }

    if (this.state.active_customer) {
      return this.renderEditCustomer();
    } else {
      return this.renderCustomerTable();
    }
  }
  renderCustomerTable = () => {
    const { classes } = this.props;
    const { customers } = this.state;
    return (
      <GridItem xs={12} sm={12} md={6} key="allocation">
        <CardContainer>
          <Button onClick={()=>{this.setState({add_new: true})}}>New Customer</Button>

          <div className={classes.tableWrapper} style={{width: "100%"}}>
            <NewDataTable
              rows={customers || []}
              noPagination
              rowSettings={{
                rowProps: {
                    onClick: (row, index)=>this.loadSingleCustomer(row),
                    style: {
                        cursor: "pointer"
                    },
                    title: "Click to edit customer"
                },
              }}
              columns={[
                {
                  key: 'name',
                  label: 'Name',
                  render: (val, key, row) => {
                    return val;
                    // return (
                    //   <Button onClick={()=>{this.loadSingleCustomer(row)}}>{val}</Button>
                    // );
                  }
                },
                // {
                //   key: 'status',
                //   label: 'Account Status',
                //   render: (val, key, row, index) => {
                //     // Not turned on Manage Customer Status Yet
                //     // To live, remove this
                //     // Todo remove this
                //     return val == '1' ? 'Active' : 'Inactive';



                //     // only System Admin can change account status
                //     if (localStorage.getItem("role") !== '1' && localStorage.getItem("role") !== 'SystemAdmin')
                //     {
                //       return val == '1' ? 'Active' : 'Inactive';
                //     }
                //     return (
                //       <div style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center'}}>
                //          <FormControl required fullWidth className={classes.selectInput}>
                //           <FormControlLabel
                //             control={
                //               <Switch
                //                 checked={(parseInt(val) == 1) ? true : false}
                //                 color="primary"
                //                 onChange={(e)=>{
                //                   let new_val = e.target.checked;
                //                   new_val = new_val ? 1 : 0;
                //                   let new_customer = Object.assign({}, row);
                //                   new_customer.status = new_val;
                //                   this.context.confirm(`Are you sure to set ${row.name} account status to ${new_val ? 'Active' : 'Inactive'}`, this.updateCustomerStatus.bind(this, new_customer));
                //                 }}
                //               />
                //             }
                //             label={(parseInt(val) == 1) ? 'Active' : 'Inactive'}
                //           />
                //         </FormControl>
                //       </div>
                //     );
                //   }
                // }
              ]}
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderNewCustomer = () => {
    const { classes } = this.props;
    const { active_tab } = this.state;

    const tabs = [
      {
        key: 'Customer Information',
        label: 'Customer Information',
        render: this.customerInformation,
        disabled: this.setup_process < 1
      },
      {
        key: 'Shipping Information',
        label: 'Shipping Information',
        render: this.shippingInformation,
        disabled: this.setup_process < 2
      },
      {
        key: 'Billing Agreement',
        label: 'Billing Agreement',
        render: this.billingAgreement,
        disabled: this.setup_process < 2
      },
      {
        key: 'Supply',
        label: 'Supply',
        render: this.supplyRate,
        disabled: this.setup_process < 2
      },
      {
        key: 'Shipping Map',
        label: 'Shipping Map',
        render: this.shippingMap,
        disabled: this.setup_process < 2
      },
      {
        key: 'Website',
        label: 'Website',
        render: this.website,
        disabled: this.setup_process < 2
      },
      {
        key: 'Account',
        label: 'Account',
        render: this.account,
        disabled: this.setup_process < 2
      },
    ];

    return (
      <GridItem xs={12}>
        <div className={classes.tabRoot}>
          <Button style={{marginTop: '1rem', marginBottom: '1rem'}} onClick={this.backToList}>Back To List</Button>

          <AppBar position="static" color="default">
            <Tabs
              value={active_tab}
              onChange={(e,val)=>this.setState({active_tab: val})}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((elem)=><Tab key={elem.key} label={elem.label} disabled={elem.disabled}/>)}
            </Tabs>
            <CardContainer>
              {tabs[active_tab].render()}
            </CardContainer>
          </AppBar>
        </div>
      </GridItem>
    );
  }
  renderEditCustomer = () => {
    const { classes } = this.props;
    const { active_tab } = this.state;
    const tabs = [
      {
        key: 'Customer Information',
        label: 'Customer Information',
        render: this.customerInformation,
      },
      {
        key: 'Shipping Information',
        label: 'Shipping Information',
        render: this.shippingInformation,
      },
      {
        key: 'Billing Agreement',
        label: 'Billing Agreement',
        render: (localStorage.getItem("role") !== '1' && localStorage.getItem("role") !== 'SystemAdmin') ? this.billingAgreementReadOnly : this.billingAgreement,
      },
      {
        key: 'Supply',
        label: 'Supply',
        render: this.supplyRate,
      },
      {
        key: 'Shipping Map',
        label: 'Shipping Map',
        render: this.shippingMap,
      },
      {
        key: 'Website',
        label: 'Website',
        render: this.website,
      },
      {
        key: 'Account',
        label: 'Account',
        render: this.account,
      },
    ];

    return (
      <GridItem xs={12}>
        <Button style={{marginTop: '1rem', marginBottom: '1rem'}} onClick={this.backToList}>Back To List</Button>
        <div className={classes.tabRoot}>
          <AppBar position="static" color="default">
            <Tabs
              value={active_tab}
              onChange={(e,val)=>this.setState({active_tab: val})}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((elem)=><Tab key={elem.key} label={elem.label}/>)}
            </Tabs>
            <CardContainer>
              {tabs[active_tab].render()}
            </CardContainer>
          </AppBar>
        </div>
      </GridItem>
    );
  }

  customerInformation = () => {
    const { active_customer } = this.state;
    return (
      <div>
          <CustomerInfo
              customer_info={active_customer && active_customer.customer_information ? active_customer.customer_information : {}}
              onCustomerInfoChange={this.onCustomerInfoChange}
              onCustomerStatusChange={this.onCustomerStatusChange}
              init={!active_customer || !active_customer.customer_information}
          />
      </div>
    );
  }
  shippingInformation = () => {
    const { active_customer } = this.state;

    return (
      <div>
        <ShippingInfo
          shipping_info={active_customer && active_customer.shipping_information ? active_customer.shipping_information : {}}
          onShippingInfoChange={this.onShippingInfoChange}
          init={!active_customer || !active_customer.shipping_information}
        />
      </div>
    )
  }
  billingAgreement = () => {
    const { regular_list, active_customer } = this.state;

    return (
      <div>
        <BillingAgreement
          options={regular_list}
          customer_id={this.customer_id}
          agreements={active_customer && active_customer.billing_agreement ? active_customer.billing_agreement : {}}
          keys={this.agreement_types}
          onAgreementsChange={this.onAgreementsChange}
        />
      </div>
    );
  }
  billingAgreementReadOnly = () => {
    const { regular_list, active_customer } = this.state;
    return (
      <div>
        <BillingAgreementReadOnly
          options={regular_list}
          customer_id={this.customer_id}
          agreements={active_customer && active_customer.billing_agreement ? active_customer.billing_agreement : {}}
          onAgreementsChange={this.onAgreementsChange}
        />
      </div>
    );
  }
  supplyRate = () => {
    const { active_customer, supply_sku_list } = this.state;

    let customer_supply_rate = active_customer && active_customer.customer_supply_rate ? active_customer.customer_supply_rate : [];
    let marketing_insert_list = active_customer && active_customer.customer_marketing_insert ? active_customer.customer_marketing_insert : [];

    return (
      <div>
        <SupplyRate
          customer_supply_rate={customer_supply_rate}
          supply_sku_list={supply_sku_list}
          customer_id={this.customer_id}
          onSupplyRateChange={this.onSupplyRateChange}
          marketing_insert_list={marketing_insert_list}
          onMarketingInsertChange={this.onMarketingInsertChange}
        />
      </div>
    );
  }
  shippingMap = () => {
    const { active_customer } = this.state;

    let shipping_map = active_customer && active_customer.shipping_map ? active_customer.shipping_map : [];

    return (
        <div>
          <ShippingMap
            shipping_map={shipping_map}
            customer_id={this.customer_id}
            onShippingMapChange={this.onShippingMapChange}
          />
        </div>
    );
  }
  website = () => {
    const { active_customer } = this.state;
    let website = active_customer && active_customer.website ? active_customer.website : [];

    return (
        <div>
          <WebsiteAccess
            website={website}
            customer_id={this.customer_id}
            onWebsiteChange={this.onWebsiteChange}
          />
        </div>
    );
  }
  account = () => {
    const { active_customer } = this.state;
    let account = active_customer && active_customer.account ? active_customer.account : [];

    return (
      <div>
        <Account
          account={account}
          customer_id={this.customer_id}
          onAccountChange={this.onAccountChange}
        />
      </div>
    );
  }
}
export default withStyles(styles)(ManageCustomers);
