import React from "react";
import { withStyles, Grid } from "@material-ui/core";

const style = {
  grid: {
    // padding: "0 15px !important",
    // padding: "0 8px !important",
  },
};

function GridItem({ ...props }) {
  const { classes, noPadding, className, children, ...rest } = props;

  let class_name = classes.grid;
  if (noPadding) class_name = '';
  if (className) {
    class_name += ' ' + className;
  }

  return (
    <Grid item {...rest} className={class_name}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridItem);
