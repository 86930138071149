import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';

export default function withIdleCheck(options) {
  return function(WrappedComponent) {
    class ComponentWithIdleCheck extends Component {
      static propTypes = {
          alert: PropTypes.func,
      }
      constructor(props) {
          super(props);
          this.idleTime = null;
      }

      componentDidMount() {
          if (options.interval && parseInt(options.interval) === -1) return; // if interval is set to -1, don't check idle

          if (!localStorage.getItem('role')) return; // if not logged in, don't check idle

          this.resetTimer();
          let events = ['click', 'mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
          let debouncedTimer = this.debouncedTimer;
          events.forEach(function(name) {document.addEventListener(name, debouncedTimer, true);});
      }
      componentWillUnmount() {
          if (options.interval && parseInt(options.interval) === -1) return; // if interval is set to -1, don't check idle

          let events = ['click', 'mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];
          let debouncedTimer = this.debouncedTimer;
          events.forEach(function(name) {document.removeEventListener(name, debouncedTimer, true);});
      }

      logout = () => {
          if (!this.props.alert) window.alert("You have been logged out due to in-activity.");
          else {
              this.props.alert("You have been logged out due to in-activity.", {
                  type: "Logged out", 
                  disableBackdropClick: true,
                  onCloseCallback: ()=>{
                      localStorage.clear();
                      window.location.reload();
                  }
              });
          }
      }
      resetTimer = () => {
          clearTimeout(this.idleTime);
          
          let callback = options.callback || this.logout;
          let interval = options.interval || 600000; // default to 10 minutes

          this.idleTime = setTimeout(callback, interval);
      }
      debouncedTimer = _.debounce(this.resetTimer, 150)

      render() {
          return <WrappedComponent {...this.props}/>;
      }
    }
    ComponentWithIdleCheck.displayName = `withIdleCheck(${getDisplayName(WrappedComponent)})`;

    return ComponentWithIdleCheck;
  }
}

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}