// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Grid,
    CardContent,
    CardActionArea,
    withStyles,
    Hidden,
} from "@material-ui/core";
// BXZ render modules
import {
    CardIcon,
    NewDataTable,
    CardContainer,
    Button,
    GridItem,
} from "components";
import DataContext from 'context/Data'
import {
    Search,
} from '@material-ui/icons';
import {
  ProductionQuantityLimits,
  Inventory,
  Undo,
  HourglassEmpty,
  PanTool,
  Done,
} from '@mui/icons-material';
// Our view components

const styles = theme => ({
    floatingIconContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(-1)
    },
    statCard: {
        display: "flex"
    },
    statIconContainer: {
        // backgroundColor: "#29b765",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
        marginTop: theme.spacing(-2),
    },
    statIcon: {
        color: "white",
        fontSize: theme.spacing(4),
        // margin: theme.spacing(2, 5),
        margin: theme.spacing(2),
    },
    customCardIcon: {
        height: "67px",
        width: "64px",
    },
    statValueContainer: {
        flexGrow: "1",
        padding: theme.spacing(1),
        paddingBottom: ".5rem !important",
        // backgroundColor: "#2ecc71",
        // color: "white",
        // fontSize: "1rem"
        fontSize: "1.25rem"
    },
    statTable : {
        height: "100%",
        width: "100%"
    },
    clickEnabled: {
        cursor: "pointer",
    },
    clickDisabled: {
        cursor: "default",
    },
    statLabelCell: {
        // textAlign: "left",
        textAlign: "center",
        // color: "#999",
        // fontFamily: 'Raleway-Regular',
        fontFamily: 'Neuton-Regular',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // width: theme.spacing(18)
    },
    statValeCell: {
        // textAlign: "right",
        textAlign: "center",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Neuton-Bold',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        fontWeight: "bold",
        flexGrow: "1"
    },
});

class ClientInboundDashboard extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.customer = '';
        this.status_id = '';

        this.state = {
            loading: false,
            dashboardDetail: null,
            poList: null,
            po_status: '',
        };

        document.title = "Inbound Dashboard";
    }

    render() {
        return (
            <Grid container spacing={2}>
                {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
                {this.renderDashboard()}
                {this.renderPOList()}
            </Grid>
        );
    }

    componentWillMount() {
        this.loadDashboard();
    }

    // api call
        // load dashboard
    loadDashboard = () => {
        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/clientInbounddashboard`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        this.setState({loading: true});
        req.then(this.loadDashboardSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
    }
    loadDashboardSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.context.alert(resp.Error);
            return;
        }
        this.setState({dashboardDetail: resp});
    }
        // load single customer PO
    loadCustomerPO = (status_id) => {
        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/clientInbounddashboard`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: {
                status: status_id
            },
        });
        this.setState({loading: true, poList: null, po_status: status_id});
        req.then(this.loadCustomerPOSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadCustomerPOSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
            if (resp.data.Error.message) this.context.alert(resp.data.Error.message);
            else this.context.alert(resp.data.Error);
            return;
        }
        this.setState({poList: resp.data});
    }
   
    // render functions
    renderDashboard = () => {
        let dashboardDetail = this.state.dashboardDetail ? this.state.dashboardDetail : {
            2: "N/A",
            4: "N/A",
            7: "N/A",
            10: 'N/A',
            11: 'N/A',
            17: 'N/A' // returns
        };

        let pending = dashboardDetail[2] ? dashboardDetail[2] : 0;
        let receiving = dashboardDetail[7] ? dashboardDetail[7] : 0;
        let received = dashboardDetail[4] ? dashboardDetail[4] : 0;
        let pending_putaway = dashboardDetail[10] ? dashboardDetail[10] : 0;
        let returns = dashboardDetail[17] ? dashboardDetail[17] : 0;
        let putaway = dashboardDetail[11] ? dashboardDetail[11] : 0;
        
        return <React.Fragment>
            {this.renderSingleStatCard(pending, "Pending Received")}
            {this.renderSingleStatCard(receiving, "Receiving")}
            {this.renderSingleStatCard(received, "Received")}

            {/* <Hidden smDown><GridItem md={1}></GridItem></Hidden> */}
            
            {this.renderSingleStatCard(returns, "Returns")}

            {this.renderSingleStatCard(pending_putaway, "Pending Putaway")}

            {/* <Hidden smDown><GridItem md={2}></GridItem></Hidden> */}

            {this.renderSingleStatCard(putaway, "Putaway")}
        </React.Fragment>;
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        let status_code = 2;
        if (!val || val == "N/A") {
            val = 0;
            click_disabled = true
        }

        let title = key;
        let icon = null;
        switch (key) {
            case "Pending Received":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B90"
                }} className={classes.statIconContainer}>
                    <HourglassEmpty className={classes.statIcon} />
                </CardIcon>;
                status_code = 2;
                break;
            case "Receiving":
                icon = <CardIcon style={{
                    backgroundColor: "#30C4EE"
                }} className={classes.statIconContainer}>
                    <PanTool className={classes.statIcon} />
                </CardIcon>;
                status_code = 7;
                break;
            case "Received":
                icon = <CardIcon style={{
                    backgroundColor: "#71BF47"
                }} className={classes.statIconContainer}>
                    <Done className={classes.statIcon} />
                </CardIcon>;
                status_code = 4;
                break;

            case "Pending Putaway":
              icon = <CardIcon style={{
                  backgroundColor: "#F6B26B"
              }} className={classes.statIconContainer}>
                  <ProductionQuantityLimits className={classes.statIcon} />
              </CardIcon>;
              status_code = 10;
              break;
            case "Returns":
                icon = <CardIcon style={{
                    backgroundColor: "#8791C8"
                }} className={classes.statIconContainer}>
                    <Undo className={classes.statIcon} />
                </CardIcon>;
                status_code = 17;
                break;
            case "Putaway":
              icon = <CardIcon style={{
                  backgroundColor: "#FFA000"
              }} className={classes.statIconContainer}>
                  <Inventory className={classes.statIcon} />
              </CardIcon>;
              status_code = 11;
              break;
        }

        return (
          // <GridItem xs={12} md={4}>
          <GridItem xs={12} md={4}>
              <CardContainer allowOverflow noPadding>
                  <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                      <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadCustomerPO(status_code);}}>
                          <div className={classes.floatingIconContainer}>
                              {icon}
                          </div>

                          <CardContent className={classes.statValueContainer}>
                              <table className={classes.statTable}>
                                  <tbody>
                                  <tr><td style={{
                                          // color: "white"
                                      }} className={classes.statLabelCell}>{title}</td></tr>
                                  <tr><td className={classes.statValeCell}>{utils.numberWithComma(val)}</td></tr>
                                  </tbody>
                              </table>
                          </CardContent>
                      </div>
                  </CardActionArea>
              </CardContainer>
          </GridItem>
        );
    }
    renderPOList = () => {
        const { poList, po_status } = this.state;
        const { classes } = this.props;

        if (!poList) return null;

        let colSettings = [
            {
                key: 'po',
                label: 'PO',
                contentNoWrap: true,
                render: (val, key, row)=>{
                    return (
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <span style={{marginRight: '.5rem'}}>{val}</span>
                          <Search style={{cursor: 'pointer'}} title='Click to see PO detail' onClick={()=>{
                              localStorage.setItem("dbPO", row.po_id);
                              this.props.history.push("/manageInbound");
                          }}>sss</Search>
                      </div>
                    );
                },
            },
            {
                key: 'name',
                label: 'ItemName',
                width: 'auto',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'sku',
                label: 'SKU',
                contentNoWrap: true,
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'upc',
                label: 'UPC',
                contentNoWrap: true,
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'quantity',
                label: 'Quantity',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'received',
                label: 'Received',
            },
            {
                key: 'discrepancy',
                label: 'Discrepancy',
                render: (val, key, row)=>{
                    return (
                        parseInt(row['received']) - parseInt(row['quantity'])
                    );
                },
            },
            {
                key: 'estimated_date',
                label: 'EstimatedDate',
                render: utils.formatDate,
            },
            {
                key: 'received_dt',
                label: 'ReceivedDate',
                contentNoWrap: true,
                render: utils.localizeDate,
            },
            {
                key: 'status_id',
                label: 'Status',
                contentNoWrap: true,
                width: 'auto',
                render: utils.convertPOStatus,
            },
            {
                key: 'tracking',
                label: 'Tracking',
            },
        ];
        if (po_status == 10 || po_status == 11) {
          colSettings = [
            {
              key: 'po',
              label: 'PO',
              contentNoWrap: true,
              render: (val, key, row)=>{
                  return (
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{marginRight: '.5rem'}}>{val}</span>
                        <Search style={{cursor: 'pointer'}} title='Click to see PO detail' onClick={()=>{
                            localStorage.setItem("dbPO", row.po_id);
                            this.props.history.push("/manageInbound");
                        }}>sss</Search>
                    </div>
                  );
              },
            },
            {
              key: 'sku',
              label: 'SKU',
              contentNoWrap: true,
            },
            {
              key: 'quantity',
              label: 'Quantity',
            },
          ];
        }
        if (po_status == 17) {
            // returns
            colSettings = [
                {
                  key: 'order_key',
                  label: 'OrderId',
                  contentNoWrap: true,
                  render: (val, key, row)=>{
                    return (
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <span style={{marginRight: '.5rem'}}>{val}</span>
                        <Search  style={{cursor: 'pointer'}} title='Click to see order detail' onClick={()=>{
                          localStorage.setItem("dbOrder", row.order_id);
                          this.props.history.push("/manageOrders");
                        }}>sss</Search>
                      </div>
                    );
                  },
                }, 
                {
                    key: 'sku',
                    label: 'SKU',
                    contentNoWrap: true,
                },
                {
                    key: 'quantity',
                    label: 'Returned Qty',
                    contentNoWrap: true,
                },
                {
                  key: 'name',
                  label: 'Customer Name',
                  contentNoWrap: true,
                },
                {
                  key: 'created_dt',
                  label: 'Return Date',
                  contentNoWrap: true,
                  render: utils.localizeDate
                }
              ];
        }

        let title = '';
        title += utils.convertPOStatus(po_status);

        return (
          <GridItem xs={12}>
              <CardContainer>
                  <div>
                      <div className={classes.title} style={{height: '3rem'}}>
                          <Typography variant="h6">
                              {title}

                              {poList.length > 0 && <Button
                                style={{float: 'right'}}
                                onClick={()=>{
                                    utils.exportTableToCsv('table-export', title);
                                }}>Export</Button>}
                          </Typography>
                      </div>

                      <NewDataTable
                        rows={poList}
                        columns={colSettings}
                        noPagination
                        maxHeight={500}
                        id="table-export"
                      />
                  </div>
              </CardContainer>
          </GridItem>
        );
    }
}
export default withRouter(withStyles(styles)(ClientInboundDashboard));
