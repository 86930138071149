import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Prompt } from 'react-router'
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  ReversedIconButton 
} from 'components';
import DataContext from 'context/Data'
import {
  ExposureNeg1
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';
import ReturnTicketDialog from 'views/Dialogs/ReturnTicket'
import CreateUnplannedReturn from 'views/Dialogs/CreateUnplannedReturn';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class UnplannedReturn extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.input_refs = {
      sku: null
    };
    this.temp_data = {
      sku_map: {},
      upc_map: {},
      barcode_map: {},
      key_array: [],
      top_item: '',
      pallet: '',
      close_confirm: false,
    };

    this.state = {
      loading: false,
      dialog: 'create_unplan',
      // return order search
      item_list: null,
      // return submit input fields 
      rma: '',
      notes: '',
      // Data after submit
      return_ticket: []
    };

    document.title = "Return";
  }

  handleSkuScanned = (e) => {
    let scanned_val = utils.formatString(this.input_refs.sku?.value); 
    if (!scanned_val) return;

    if (this.temp_data.barcode_map[scanned_val]) {
      scanned_val = this.temp_data.barcode_map[scanned_val];
    } else if (this.temp_data.barcode_map[scanned_val.toUpperCase()]) { // also check uppercase for barcode map
      scanned_val = this.temp_data.barcode_map[scanned_val.toUpperCase()];
    }
    // Use upper case to match SKU and UPC
    scanned_val = scanned_val.toUpperCase();

    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]) matched_id = this.temp_data.sku_map[scanned_val]; // sku matched
    else if (this.temp_data.upc_map[scanned_val]) matched_id = this.temp_data.upc_map[scanned_val]; // upc matched
    else {
      this.verifySku(scanned_val);
      return;
    }
    let items = Object.assign({}, this.state.item_list);
    let newItem = Object.assign({}, items[matched_id]);
    let newQty = 1 + parseInt(newItem.quantity_input);
    if (newQty > (parseInt(newItem.quantity) - parseInt(newItem.returned))) {
      this.context.alert("You can return at most " + newItem['quantity'] + " of this item");
      newQty = newItem['quantity'];
    }
    newItem['quantity_input'] = newQty;
    // if quantity changed, set status to 213, returned before, but trying to return it again, then guess who is 213
    if (parseInt(newItem.item_status) === 13) {
      newItem.item_status = 213;
    }
    items[matched_id] = newItem;
    // Move the scanned row to top
    this.temp_data.top_item = matched_id;

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
    this.setState({item_list: items});
  }
  handleScan = _.debounce(this.handleSkuScanned, 150);
  verifySku = (scanned_val) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/unplannedReceiving/${scanned_val}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.verifySkuSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  verifySkuSuccess = (resp) => {
    this.setState({loading: false});
    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }  
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let verified_item = resp.data[0];
    let new_items = Object.assign({}, this.state.item_list);
    this.temp_data.top_item = verified_item.item_id; // put the new scanned item to top
    if (new_items[verified_item.item_id]) {
      // if item already exists 
      let new_item = new_items[verified_item.item_id];
      new_item.quantity_input = 1 + (parseInt(new_item.quantity_input) || 0);
    } else {
      // new item, insert
      verified_item.polybagged_quantity = 0;
      verified_item.barcoded_quantity = 0;
      verified_item.steam_quantity = 0;
      verified_item.hangtagged_quantity = 0;
      verified_item.quantity_input = 1;
      verified_item.reason = '';
      verified_item.condition = '';
      verified_item.tote = this.temp_data.key_array.length + 1; // Use the number of skus to assign the tote of current item, first item of the array tote is 1, next tote is 2 etc...
      new_items[verified_item.item_id] = verified_item;   
      // Save SKU and UPC to uppercase
      let sku = verified_item.sku ? ('' + verified_item.sku).toUpperCase().trim() : "";
      let upc = verified_item.upc ? ('' + verified_item.upc).toUpperCase().trim() : "";
      if (!this.temp_data.sku_map[sku]) this.temp_data.sku_map[sku] = verified_item.item_id;
      if (!this.temp_data.upc_map[upc]) this.temp_data.upc_map[upc] = verified_item.item_id;
      this.temp_data.key_array.push(verified_item.item_id);
    }
    this.setState({item_list: new_items});
    this.temp_data.close_confirm = true;
  }
  createUnplannedOrder = (pallet) => {
    let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/unplanreturn/${pallet}`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });
  
      this.setState({loading: true});
      req.then(this.createUnplannedOrderSuccess.bind(this, pallet)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  createUnplannedOrderSuccess = (pallet, resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    this.temp_data.pallet = pallet;
    this.temp_data.unplan_order_id = resp.data.unplan_order_id;
    this.setState({item_list: {}, dialog: ''});
  }
  printBarcode = (id, label) => {
    let items = Object.assign({}, this.state.item_list);
    let item = items[id];

    let content = item[label];
    let qty = 1;
    if (parseInt(item.barcoded_quantity) > 0) {
        qty = parseInt(item.barcoded_quantity);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/barcode`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'product_tag',
        codes: content,
        repeat: qty
      },
    });

    this.setState({loading: true});
    req.then(this.printSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    let data = resp.data;
    utils.printPage(data);
  }
  submitReturn = (e) => {
    e.preventDefault();
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }
    const { item_list, notes, rma } = this.state;
    let err = '';
    let req_items = [];

    for (let item_id of this.temp_data.key_array) {
      let single_item = Object.assign({}, item_list[item_id]);
      if (parseInt(single_item.quantity_input) === 0) continue;
      if (!single_item.reason) err += `Please select return reason for SKU: ${single_item.sku}. \n`;
      if (!single_item.condition) err += `Please select product condition for SKU: ${single_item.sku}. \n`;
      let submit_item = Object.assign(single_item, {
        barcoded_quantity: parseInt(single_item.barcoded_quantity) || 0,
        polybagged_quantity: parseInt(single_item.polybagged_quantity) || 0,
        steam_quantity: parseInt(single_item.steam_quantity) || 0,
        hangtagged_quantity: parseInt(single_item.hangtagged_quantity) || 0,
      });
      req_items.push(submit_item);
    }

    if (req_items.length === 0) err += `You haven't returned any items. \n`;
    if (!notes) err += 'Notes is required.\n';

    if (err) this.context.alert(err);
    else {
      this.submitReturnOnce({
        pallet: this.temp_data.pallet,
        order_id: this.temp_data.unplan_order_id,
        notes: notes,
        rma: rma,
        items: req_items
      });
    }
  }
  submitReturnAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/unplanreturn`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitReturnSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    this.setState({loading: true})
  }
  resetSubmitOnce = () => {this.submitReturnOnce = _.once(this.submitReturnAJax);} // reset the submit picking once after the ajax call returns
  submitReturnOnce = _.once(this.submitReturnAJax)
  submitReturnSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;
    this.context.snackDisplay("Return submit success!");
    if (Array.isArray(result) && result.length > 0) {
      this.setState({
        dialog: 'return_ticket',
        return_ticket: result
      });
    } else {
      this.temp_data.close_confirm = false;
      window.location.reload();
    }
  }

  renderReturnForm = () => {
    const {classes} = this.props;
    const {item_list} = this.state;
    if (!item_list || !this.temp_data.unplan_order_id) return;

    return (
      <GridItem xs={12} sm={6}>
      <CardContainer>
        <CustomInput
          labelText='Notes'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>this.setState({notes: e.target.value}),
            value: this.state.notes,
          }}
        />

        <CustomInput
          labelText='RMA'
          formControlProps={{
            fullWidth: true,
            // required: true,
            className: classes.customInput,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>this.setState({rma: e.target.value}),
            value: this.state.rma,
          }}
        />

        <Button onClick={this.submitReturn} className={classes.button}>Submit Return</Button>
      </CardContainer>
    </GridItem>
    );
  }
  renderReturnItemTable = () => {
    const { classes } = this.props;
    const { item_list } = this.state;
    if (!item_list || !this.temp_data.unplan_order_id) return;
    let rows = [];
    
    // top item
    if (this.temp_data.top_item) rows.push(item_list[this.temp_data.top_item]);

    for (let id of this.temp_data.key_array) {
      if (this.temp_data.top_item === id) continue;
      rows.push(item_list[id]);
    }

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        render: (val, key, row) => {
          return (
            <Button color='default' onClick={this.printBarcode.bind(this, row.item_id, key)}><span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span></Button>
          );
        }
      },
      {
        key: 'upc',
        label: 'Upc',
        render: (val, key, row) => {
          if (!val) return null;
          return (
            <Button color='default' onClick={this.printBarcode.bind(this, row.item_id, key)}><span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span></Button>
          );
        }
      },
      {
        key: 'tote',
        label: 'Tote#',
        render: (val, key, row) => {
          return (
            <span style={{fontWeight: "bold"}}>{val}</span>
          );
        }
      },
      {
        key: 'item_name',
        label: 'ItemName',
      },
      {
        key: 'category',
        label: 'Category',
        contentNoWrap: true,
      },
      {
        key: 'image',
        label: 'Image',
        render: (val, key, row)=>{
          if (val) {
            return (<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
              this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
            }} src={val}/>);
          } else {
            return "No Image";
          }
        }
      },
      {
        key: 'quantity_input',
        label: 'QTY Input',
        render: (val, key, row) => {
          return (
            <span style={{display: "inline-flex", alignItems: 'center'}}>
              {val}
              {
                parseInt(val) > 0 &&
                <ReversedIconButton style={{marginLeft: ".5rem"}} size="small" onClick={()=>{
                  let newItems = Object.assign({}, item_list);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  let old_qty = parseInt(newItem.quantity_input);
                  let new_qty = old_qty -1;
                  if (new_qty < 0) {
                    this.context.alert("The quantity is already 0, can't minus 1.");
                    return;
                  }
                  newItem['quantity_input'] = new_qty;
                  newItems[row.item_id] = newItem;
                  this.setState({item_list: newItems});
                }} variant="fab" color="secondary" aria-label="Decrease" className={classes.button}>
                  <ExposureNeg1 style={{color: 'white'}}/>
                </ReversedIconButton>
              }
            </span>
          );
        },
      },
      {
        key: 'condition',
        label: 'Condition',
        width: 100,
        render: (val, key, row) => {
          return (
            <FormControl fullWidth required className={classes.customInput}>
              <Select
                 value={val}
                 onChange={(e)=>{
                  let newItems = Object.assign({}, item_list);
                  let newItem = Object.assign({}, newItems[row.item_id]);
                  if (newItem['condition'] != e.target.value) {
                    // if condition changed, reset reason 
                    newItem['reason'] = '';
                  }
                  newItem['condition'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({item_list: newItems});
                 }}
              >
                <MenuItem value="GOOD">GOOD</MenuItem>
                <MenuItem value="DAMAGED">DAMAGED</MenuItem>
                <MenuItem value="CLEAN">CLEAN</MenuItem>
              </Select>
            </FormControl>
          );
        }
      },
      {
        key: 'reason',
        label: 'Reason',
        width: 250,
        render: (val, key, row) => {
          return (
            <FormControl fullWidth required className={classes.customInput}>
              <Select
                 value={val}
                 disabled={!row.condition}
                 onChange={(e)=>{
                  let newItems = Object.assign({}, item_list);
                  let newItem = Object.assign({}, newItems[row.item_id]);
                  newItem['reason'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({item_list: newItems});
                 }}
              >
                {utils.getReturnReasonOptions(row.condition).map((elem)=><MenuItem key={elem.value} value={elem.value}>{elem.label}</MenuItem>)}
              </Select>
            </FormControl>
          );
        }
      },
      {
        key: 'barcoded_quantity',
        label: 'Barcoded',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity_input
                },
                type: 'number',
                value: val,
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, item_list);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity_input) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY Input .`);
                      return;
                  }
                  newItem['barcoded_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({item_list: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'polybagged_quantity',
        label: 'Polybagged',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity_input
                },
                type: 'number',
                value: val,
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, item_list);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity_input) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY Input .`);
                    return;
                  }
                  newItem['polybagged_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({item_list: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'steam_quantity',
        label: 'Steaming',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity_input
                },
                value: val,
                type: 'number',
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, item_list);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity_input) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY Input .`);
                    return;
                  }
                  newItem['steam_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({item_list: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'hangtagged_quantity',
        label: 'Hangtagged',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity_input
                },
                value: val,
                type: 'number',
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, item_list);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity_input) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY Input .`);
                    return;
                  }
                  newItem['hangtagged_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({item_list: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'style',
        label: 'Style',
      },
      {
        key: 'size',
        label: 'Size',
      },
      {
        key: 'color',
        label: 'Color',
      },
    ];

    return (
     <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
            <CustomInput
                labelText='SKU/UPC'
                formControlProps={{
                    fullWidth: false,
                    style: {marginLeft: '.25rem', marginRight: '.75rem'},
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    onKeyUp: this.handleScan,
                    onPaste: (e)=>{
                      if (!utils.checkAllowCopy()) e.preventDefault();
                    },
                    inputRef: elem=>this.input_refs.sku = elem
                }}
            />

            <NewDataTable
                rows={rows}
                // rowHeight={50} 
                maxHeight={500}
                noPagination
                columns={columns}
            />
        </CardContainer>
     </GridItem>
    );
  }
  renderDialog = () => {
    switch (this.state.dialog)  {
      case 'return_ticket':
        return (
          <ReturnTicketDialog
            handleSubmit={()=>{
              this.setState({dialog: ''});
              window.location.reload();
            }}
            handleClose={()=>{
              this.setState({dialog: ''});
              window.location.reload();
            }}
            returnTicket={this.state.return_ticket}
          />
        );
        break;
      case 'create_unplan':
            return (
                <CreateUnplannedReturn
                  handleSubmit={this.createUnplannedOrder}
                  handleClose={()=>{
                    this.setState({dialog: ''});
                    this.props.history.goBack();
                  }}
                />
            );
      default:
        return null;
    }
  }

  render() {
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {this.renderDialog()}

        {this.renderReturnForm()}
        {this.renderReturnItemTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(UnplannedReturn);
