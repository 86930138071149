import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select} from '@material-ui/core'

import {BasicDialog, Button,  CustomInput,} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function CreateNew(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit} = props;
  const [quantity, setQuantity] = useState('');

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <CustomInput
            labelText='How many pallets do you want to create?'
            formControlProps={{
                fullWidth: true,
                className: classes.customInput,
                required: true
            }}
            labelProps={{
                shrink: true,
            }}
            inputProps={{
                value: quantity,
                type: 'number',
                inputProps: {
                    step: 1,
                    min: 0,
                },
                onChange: (e)=>{                    
                    let newVal = e.target.value;
                    newVal = parseInt(newVal);
                    if (!newVal || newVal < 0) newVal = 0;
                    setQuantity(newVal);
                },
            }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';  

    if (!quantity) err += "Quantity is required. \n";


    // confirm big qty
    if (err) dataContext.alert(err);
    else {
      dataContext.confirm(`Are you sure to create ${quantity} new pallets? ${quantity > 1000 ? quantity + ' is a very big number, please cancel if you made a mistake!' : ''}`, ()=>{handleSubmit(quantity)});
    }
  }

  let title = 'Create New Inbound Pallet';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}