import React from 'react'
import { Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    overflow: 'auto',
    // display: 'inline-flex',
    margin: 'auto',
    verticalAlign: 'middle',
    // fontSize: '1.25rem',
    fontSize: '1rem',
    // flexGrow: 1,
    // minHeight: 0,
    // alignItems: 'center',
    // justifyContent: 'center',
    wordWrap: 'break-word'
  },
});

export default function KeyValueTable(props) {
  const classes = useStyles();
  const {dataObj, propertyList, rowHeight, horizontal, noHeader, ...restProps} = props;

  function renderRows(propertySetting) {
    let valueContent = dataObj[propertySetting.key]; 
    if (valueContent === 0 || valueContent === '0') {
      valueContent = 0;
    } else valueContent = valueContent ? valueContent : '';
   
    if (propertySetting.render) valueContent = propertySetting.render(valueContent, propertySetting.key, dataObj);

    let cellDimensionStyle = {
      maxHeight: (rowHeight || 36)+'px',
    };
    if (propertySetting.colHeight) {
      cellDimensionStyle.maxHeight = propertySetting.colHeight + 'px';  
    }

    return (
        <TableRow key={propertySetting.key}>
          <TableCell>
            {
              propertySetting.label === '' ? <span>&nbsp;</span> : <div style={cellDimensionStyle} className={classes.tableCell}>{propertySetting.label}</div>
            }
          </TableCell>

          <TableCell>
            {
              valueContent === '' ? <span>&nbsp;</span> : <div style={cellDimensionStyle} className={classes.tableCell}>{valueContent}</div>
            }
          </TableCell>
        </TableRow>
    );
  }
  function renderHorizontalRows(propertySetting) {
    let valueContent = dataObj[propertySetting.key]; 
    valueContent = !!valueContent && valueContent !== 0 ? valueContent : '';

    if (propertySetting.render) valueContent = propertySetting.render(valueContent, propertySetting.key, dataObj);

    let cellDimensionStyle = {
      maxHeight: (rowHeight || 36)+'px',
    };
    if (rowHeight == 'auto') cellDimensionStyle = 'none';

    return (
      <TableCell>
        {valueContent === '' ? valueContent : <div style={cellDimensionStyle} className={classes.tableCell}>{valueContent}&nbsp;</div>}
      </TableCell>
    );
  }

  let containerStyle = {};
  if (props.maxHeight) containerStyle.maxHeight = props.maxHeight+'px';
  if (horizontal) {
    return (
      <div className={classes.tableWrapper} style={containerStyle}>
        <Table stickyHeader size={"small"}>
          <TableHead>
            <TableRow>
              {propertyList.map((propertySetting)=> (<TableCell>{propertySetting.label}&nbsp;</TableCell>))}
            </TableRow>
          </TableHead>

          <TableBody>
            {propertyList.map(renderHorizontalRows)}
          </TableBody>
        </Table>    
      </div>
    );
  } else {
    return (
      <div className={classes.tableWrapper} style={containerStyle}>
       <Table stickyHeader size={"small"}>
          {
            !noHeader && 
            <TableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
          }
  
          <TableBody>
            {propertyList.map(renderRows)}
          </TableBody>
        </Table>    
      </div>
    );
  }
}