import React from 'react';
import PropTypes from 'prop-types'
// import Button from 'material-ui/Button';
import { Button } from "components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import _ from 'lodash';

export default class PopupAlert extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    alertActions: PropTypes.array,
    content: PropTypes.any.isRequired,
    type: PropTypes.string,
    header: PropTypes.any,
  };

  static defaultProps = {
    type: "Error"
  }

  constructor(props) {
    super(props);
    
    this.input_refs = {
      enter_button: null,
    };
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("auto-focus").click();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  render() {
    const { open, onSubmit, onClose, alertActions, content, header, type, ...restProps } = this.props;

    let actions = [];
    // if Dialog Actions passed in, use it
    if (Array.isArray(alertActions)) actions = alertActions;
    else {
      // other wise use default actions for different alert type
      switch (type) {
        case "Error":
          actions.push(<Button key='ok' id="auto-focus" onClick={this.closeDialog}>OK</Button>);
          break;
        case "Confirm":
          actions.push(<Button key='no' color="default" onClick={this.closeDialog}>No</Button>);
          actions.push(<Button key='yes' id="auto-focus" onClick={()=>{
            // onClose();
            if (onSubmit) onSubmit();
          }}>Yes</Button>);
          break;
        default:
          actions.push(<Button key='ok' onClick={this.closeDialog}>OK</Button>);
      }
    }

    let format_content = content;
    if (typeof format_content === "string") {
      format_content = content.split("\n");
      format_content = format_content.map((val,index)=><div key={index}>{val}</div>);
    }

    _.delay(()=>{
      const elem = document.getElementById('auto-focus');
      if (!elem || !elem.focus) return;
      elem.focus();
    }, 0);

    return (
      <Dialog
        open={open}
        onClose={(e, reason)=>{
          // if (reason != 'backdropClick' && reason != 'escapeKeyDown') this.closeDialog();
          this.closeDialog();
        }}
        maxWidth='sm'
        fullWidth
        {...restProps}
        aria-labelledby="basic-dialog-title"
      >
        <DialogTitle id="basic-dialog-title"><span>{header}</span></DialogTitle>
        <DialogContent>
          {format_content}
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }

  closeDialog = () => {
    const { onClose } = this.props;
    onClose();
  }
}
