import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment-timezone'
import {Divider, ButtonGroup, Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  DeleteForever
} from '@material-ui/icons'
import {
  NewDataTable,
  CardContainer,
  Button,
  ReversedIconButton,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  buttonPushRight: {
    marginLeft: 'auto',
  },
  cardContainer: {
    // height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    paddingBottom: '.5rem',
    width: '100%'
  },
});

class BatchOrder extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // search field input refs for uncontrolled 
    this.categoryInput = null;
    this.ordersPerBatchInput = null;
    this.orderBarcodeInput = null;
    this.totalOrderInput = null;
    this.wholesaleOrderBarcodeInput = null;

    this.state = {
      loading: false,
      department: 'retail',
      batchMethod: 'system_sla',
      totalNumber: '', // react somehow reuse un-controlled inputs, so need to control this
      totalOrder: '', // react somehow reuse un-controlled inputs, so need to control this
      orderSkuCount: '',
      batchList: [],
      batchTypes: [],
    };

    document.title = "Batch Order";
  }

  componentDidMount() {
    this.loadBatch();
  }

  submitBatch = () => {
    const {batchMethod, department} = this.state;
  
    let category = '';  
    let totalOrder = '';  
    let totalNumber = '';
    let ordersPerBatch = '';  
    let orderSkuCount = '';
    let orderBarcode = '';  

    let err = '';

    switch (batchMethod) {
      case 'manually':
        totalNumber = this.state.totalNumber;
        category = this.categoryInput ? this.categoryInput.value : '';  
        if (!category) err += "Please select batch category.\n";
        if (!totalNumber) err += "Total orders is required.\n";
        break;
      case 'system':
        totalOrder = this.totalOrderInput ? parseInt(this.totalOrderInput.value) : '';  
        ordersPerBatch = this.ordersPerBatchInput ? parseInt(this.ordersPerBatchInput.value) : '';  
        if (!totalOrder || totalOrder <= 0) err += 'Total Order should be a positive integer.\n';
        if (!ordersPerBatch || ordersPerBatch <= 0) err += 'Orders Per Batch should be a positive integer.\n';
        break;
      case 'system_sla':
        ordersPerBatch = this.ordersPerBatchInput ? parseInt(this.ordersPerBatchInput.value) : '';  
        if (!ordersPerBatch || ordersPerBatch <= 0) err += 'Orders Per Batch should be a positive integer.\n';
        break;
      case 'system_sku':
        orderSkuCount = this.state.orderSkuCount;
        totalOrder = this.totalOrderInput ? parseInt(this.totalOrderInput.value) : '';  
        ordersPerBatch = this.ordersPerBatchInput ? parseInt(this.ordersPerBatchInput.value) : ''; 
        if (!totalOrder || totalOrder <= 0) err += 'Total Order should be a positive integer.\n';
        if (!ordersPerBatch || ordersPerBatch <= 0) err += 'Orders Per Batch should be a positive integer.\n'; 
        if (!orderSkuCount) err += 'SKU Count is required.\n';
        break;
      case 'barcode':
        orderBarcode = this.orderBarcodeInput ? utils.formatString(this.orderBarcodeInput.value, {multiline: true}) : '';
        if (!orderBarcode) err += 'Orders Barcode is required.\n';
        break;
    }
    if (!department) err += 'Department is required.\n';
    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/batch`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        department,
        batchMethod: batchMethod,
        totalNumber: totalNumber, category, // manually 
        totalOrder: totalOrder, ordersPerBatch: ordersPerBatch, // system,
        order_sku_count: orderSkuCount, // System SKU
        orderBarcode: orderBarcode // barcode
      },
    });

    this.setState({loading: true});
    req.then(this.submitBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  submitBatchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // reset page
    if (resp.data) window.location.reload();
    else {
      this.context.alert("No order get batched");
    }
  }
  loadBatch = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/batch`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadBatchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.setState({batchList: resp.data.batch_list, batchTypes: resp.data.batch_type});
  }
  printBatch = (batch_id) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/batch/${batch_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.printBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printBatchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;
    switch (true) {
      case typeof result === "string":
        utils.printPage(result);
        break;
      case Array.isArray(result):
        for (let elem of result) {
          utils.printPage(elem);
        }
        break;
    }
    // only reload batch list, don't refresh page
    this.loadBatch();
  }
  resetBatch = (batch_id) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/resetbatch/${batch_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.resetBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  resetBatchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // only reload batch list, don't refresh page
    // this.context.snackDisplay("Reset Batch success.", {hideCallback: ()=>{
    //   this.loadBatch();
    // }});
    this.context.snackDisplay("Reset Batch success.");
    this.loadBatch();
  }
  allocateRetail = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/allocation`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.allocateRetailSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  allocateRetailSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay(resp.data);
  }
  allocateWholesale = () => {
     let wholesale_barcodes = this.wholesaleOrderBarcodeInput ? utils.formatString(this.wholesaleOrderBarcodeInput.value, {multiline: true}) : '';
     if (!wholesale_barcodes) {
      this.context.alert('Wholesale order barcode is empty.');
      return;
     }
     wholesale_barcodes = wholesale_barcodes.split(',');
 
     let req = axios({
       method: 'post',
       url: `${utils.getBaseUrl('customer')}/allocation/b2b`,
       headers: {
         token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
       },
       data: {
         order_id: wholesale_barcodes
       }
     });
 
     this.setState({loading: true});
     req.then(this.allocateWholesaleSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  allocateWholesaleSuccess = (resp) => {
     this.setState({ loading: false });
     let data = resp.data;
     if (data.Error) {
       this.context.alert(data.Error);
       return;
     }
     this.context.snackDisplay("Wholesale order allocated!");
   }
  cancelAllocation = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/resetallocation`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.cancelAllocationSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  cancelAllocationSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Cancel allocation success!");
  }

  renderBatchTable = () => {
    const {classes} = this.props;
    const {batchList} = this.state;

    let rows = batchList;

    let columns = [
      {
        key: 'id',
        label: 'Batch',
        contentNoWrap: true,
        render: (val, key, row, index)=>{
          return (
            <span>
              <Button onClick={()=>{
                if (row.packingslip_url) {
                  let w = window.open(row.packingslip_url);
                  if (!w) {this.context.alert('Please allow pop up window.');}
                  // display pdf url packingslip
                } else this.printBatch(val); // call backend to print packingslip
              }}>{val}</Button>
              {/* <IconButton color="secondary" size="small" onClick={()=>{
                  this.context.confirm("Are you sure to reset this batch?", this.resetBatch.bind(this, val))
              }} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}} className={classes.button}>
                  <DeleteForever/>
              </IconButton> */}
               <ReversedIconButton color="default" size="small" onClick={()=>{
                  this.context.confirm("Are you sure to reset this batch?", this.resetBatch.bind(this, val))
              }} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}} className={classes.button}>
                  <DeleteForever style={{color: 'white'}}/>
              </ReversedIconButton>
            </span>
          );
        },
      },
      {
        key: 'notes',
        label: 'Category',
        width: 'auto'
      },
      {
        key: 'department',
        label: 'Department',
        render: (val) => val || '',
      },
      {
        key: 'picked_batch',
        label: 'Status',
        width: 'auto'
      },
      {
        key: 'open_order',
        label: 'Open Orders',
        render: (val, key, row)=>{
          return `${row.open_order} / ${row.total_order}`;
        }
      },
      {
        key: 'created_dt',
        label: 'Date/Time',
        contentNoWrap: true,
        render: utils.localizeDate
      },
      {
        key: 'picked_time',
        label: 'Picked',
        contentNoWrap: true,
        width: 'auto',
        render: (val, key, row)=>{
          return `${utils.localizeDate(row.picked_time)} ${row.user ? 'By ' + row.user : ''}`;
        }
      },
    ];

    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (batch, displayProps) => {
        let color = '';
        let older_than_2_days = moment().subtract(2, "days").isAfter(batch.created_dt, 'day'); // check if batch is older than 2 days
        let open_batch = parseInt(batch.open_order) > 0; // check if batch has open order
        let printed = parseInt(batch.printed) !== 0; // check if packingslip is printed

        if (open_batch) {
          if (older_than_2_days) color = 'light-red';
          else color = printed ? 'yellow' : '';
        } else {
          color = printed ? 'green' : ''; // for closed batch, either green of white, base on printed
        }
        
        if (!displayProps) displayProps = {className: ''};
        let classes = displayProps.className || '';
        classes = classes.split(' ');
        classes.push(color);
        displayProps.className = classes.join(' ');
        return displayProps;
      }
    };

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <NewDataTable
            rows={rows}
            // noPagination
            rowsPerPage={100}
            rowSettings={rowSettings}
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const {classes} = this.props; 
    const {loading, batchMethod, batchTypes, department, totalNumber, totalOrder, orderSkuCount } = this.state;

    let categoryList = batchTypes.map((elem)=>{
      return (<MenuItem key={elem.batch_type} value={elem.batch_type}>{elem.batch_type}</MenuItem>);
    });

    let batchInputGroup = null;
    switch (batchMethod) {
      case 'manually':
        batchInputGroup = (
          <React.Fragment>
            <CustomInput
              labelText='Total Number'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              inputProps={{
                // type: 'number',
                // inputProps: {
                //   min: 1,
                //   step: 1,
                // },
                value: totalNumber,
                onChange: (e)=>{this.setState({totalNumber: e.target.value})},
                // ref: (el)=>{this.totalNumberInput = el},
                // defaultValue: '',
              }}
            /> 

            <FormControl fullWidth required className={classes.customInput} style={{paddingBottom: '1px'}}>
              <InputLabel shrink>Category</InputLabel>
              <Select
                defaultValue=""
                inputRef={(el)=>{this.categoryInput = el}}
              >
               {categoryList}
              </Select>
            </FormControl>

          
          </React.Fragment>
        );
        break;
      case 'system':
        batchInputGroup = (
          <React.Fragment>
            <CustomInput
              labelText='Orders Per Batch'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
                style: {paddingBottom: '1px'}
              }}
              inputProps={{
                type: 'number',
                inputProps:{
                  min: 1,
                  step: 1
                },
                inputRef: (el)=>{this.ordersPerBatchInput = el},
                defaultValue: "",
              }}
            />

            <CustomInput
              labelText='Total Order'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput
              }}
              inputProps={{
                type: 'number',
                inputProps:{
                  min: 1,
                  step: 1
                },
                inputRef: (el)=>{this.totalOrderInput = el},
                defaultValue: "",
                // value: totalOrder,
                // onChange: (e)=>{this.setState({totalOrder: e.target.value})},
              }}
            />

            
          </React.Fragment>
        );
        break;
      case 'system_sla':
          batchInputGroup = (
            <React.Fragment>
              <CustomInput
                labelText='Orders Per Batch'
                labelProps={{shrink: true}}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: classes.customInput,
                  style: {paddingBottom: '1px'}
                }}
                inputProps={{
                  type: 'number',
                  inputProps:{
                    min: 1,
                    step: 1
                  },
                  inputRef: (el)=>{this.ordersPerBatchInput = el},
                  defaultValue: "",
                }}
              />
            </React.Fragment>
          );
          break;
      case 'system_sku': 
        batchInputGroup = (
          <React.Fragment>
            <FormControl fullWidth required
              className={classes.customInput}
              style={{ paddingBottom: '0' }}
            >
              <InputLabel shrink>SKU Count</InputLabel>
              <Select
                value={orderSkuCount}
                onChange={(e) => { this.setState({ orderSkuCount: e.target.value }) }}
              >
                <MenuItem value='1'>Single-Line</MenuItem>
                <MenuItem value='2'>Two-line</MenuItem>
                <MenuItem value='3'>Three-line</MenuItem>
              </Select>
            </FormControl>

            <CustomInput
              labelText='Orders Per Batch'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
                style: {paddingBottom: '1px'}
              }}
              inputProps={{
                type: 'number',
                inputProps:{
                  min: 1,
                  step: 1
                },
                inputRef: (el)=>{this.ordersPerBatchInput = el},
                defaultValue: "",
              }}
            />

            <CustomInput
              labelText='Total Order'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput
              }}
              inputProps={{
                type: 'number',
                inputProps:{
                  min: 1,
                  step: 1
                },
                inputRef: (el)=>{this.totalOrderInput = el},
                defaultValue: "",
                // value: totalOrder,
                // onChange: (e)=>{this.setState({totalOrder: e.target.value})},
              }}
            />
          </React.Fragment>
        );
        break;
      case 'barcode':
        batchInputGroup = (
          <React.Fragment>
            <CustomInput
              labelText='Barcode'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                inputRef: (el)=>{this.orderBarcodeInput = el},
                defaultValue: "",
                placeholder: 'Use Linebreak to separate multiple barcodes',
                multiline: true,
                rows: 4
              }}
            />
          </React.Fragment>
        );
        break;
    }

    return (
      <Grid container spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        <GridItem xs={12} sm={6} md={6}>
          <CardContainer style={{minHeight: '266px'}}>
            <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Department</InputLabel>
              <Select
               value={department}
               onChange={(e) => { this.setState({ department: e.target.value }) }}
              >
                <MenuItem value='retail'>Retail</MenuItem>
                <MenuItem value='wholesale'>Wholesale</MenuItem>
                <MenuItem value='edi'>EDI</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Batch Method</InputLabel>
              <Select
                value={batchMethod}
                onChange={(e)=>{this.setState({batchMethod: e.target.value})}}
              >
                <MenuItem value='system_sla'>System SLA Batch</MenuItem>
                <MenuItem value='system'>System Batch</MenuItem>
                <MenuItem value='system_sku'>System Batch By SKU</MenuItem>
                <MenuItem value='manually'>Manually Batch</MenuItem>
                <MenuItem value='barcode'>Batch By Barcode</MenuItem>
              </Select>
            </FormControl>
            
            {batchInputGroup}
            
            {
              batchMethod && 
              <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
                <Button onClick={this.submitBatch}>Submit</Button>
              </div>
            }
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            <div className={classes.headerToolBar} style={{marginBottom: '1rem'}}>
              <ButtonGroup>
                <Button color="bxzDefault" variant="contained" onClick={this.allocateRetail}>Allocate Retail</Button>

                <Button color="bxzDefault" variant="contained" onClick={()=>this.context.confirm("Are you sure to cancel allocation?", this.cancelAllocation)}>Cancel Allocation</Button>
              </ButtonGroup>
            </div>

            <Divider style={{marginBottom: '1rem'}}/>

            <CustomInput
              labelText='Wholesale Order Barcode'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                inputRef: (el)=>{this.wholesaleOrderBarcodeInput = el},
                defaultValue: "",
                placeholder: 'Use Linebreak to separate multiple barcodes',
                multiline: true,
                rows: 3
              }}
            />
            
            <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
              <Button onClick={this.allocateWholesale}>Allocate Wholesale</Button>
            </div>
          </CardContainer>
        </GridItem>

        {/* <Hidden smDown><GridItem xs={12} sm={6} md={9} /></Hidden> */}
        
        {this.renderBatchTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(BatchOrder);
