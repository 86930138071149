// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Grid,
    CardContent,
    CardActionArea,
    withStyles
} from "@material-ui/core";
// BXZ render modules
import {
    CardIcon,
    NewDataTable,
    CardContainer,
    Button,
    GridItem,
} from "components";
import DataContext from 'context/Data'
import {
    HourglassEmpty,
    PanTool,
    Done,
    Search
} from '@material-ui/icons';
// Our view components

const styles = theme => ({
    floatingIconContainer: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(-1)
    },
    statCard: {
        display: "flex"
    },
    statIconContainer: {
        // backgroundColor: "#29b765",
        borderRadius: "3px",
        boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
        marginTop: theme.spacing(-2),
    },
    statIcon: {
        color: "white",
        fontSize: theme.spacing(4),
        // margin: theme.spacing(2, 5),
        margin: theme.spacing(2),
    },
    customCardIcon: {
        height: "67px",
        width: "64px",
    },
    statValueContainer: {
        flexGrow: "1",
        padding: theme.spacing(1),
        paddingBottom: ".5rem !important",
        // backgroundColor: "#2ecc71",
        // color: "white",
        // fontSize: "1rem"
        fontSize: "1.25rem"
    },
    statTable : {
        height: "100%",
        width: "100%"
    },
    clickEnabled: {
        cursor: "pointer",
    },
    clickDisabled: {
        cursor: "default",
    },
    statLabelCell: {
        // textAlign: "left",
        textAlign: "center",
        // color: "#999",
        // fontFamily: 'Raleway-Regular',
        fontFamily: 'Neuton-Regular',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // width: theme.spacing(18)
    },
    statValeCell: {
        // textAlign: "right",
        textAlign: "center",
        // whiteSpace: "nowrap",
        whiteSpace: "inherit",
        // fontFamily: 'Raleway-Bold',
        fontFamily: 'Neuton-Bold',
        // fontSize: "1.25rem",
        fontSize: "1.5rem",
        fontWeight: "bold",
        flexGrow: "1"
    },
});

class WMSInboundDashboard extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.customer = '';
        this.status_id = '';

        this.state = {
            loading: false,
            dashboardDetail: null,
            poList: null,
            po_status: '',
        };

        document.title = "Inbound Dashboard";
    }

    render() {
        return (
            <Grid container spacing={2}>
                {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
                {this.renderDashboard()}
                {this.renderPOList()}
            </Grid>
        );
    }

    componentWillMount() {
        this.loadDashboard();
    }

    // api call
        // load dashboard
    loadDashboard = () => {
        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/inboundDashboard`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        this.setState({loading: true});
        req.then(this.loadDashboardSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
    }
    loadDashboardSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.context.alert(resp.Error);
            return;
        }
        this.setState({dashboardDetail: resp});
    }
        // load single customer PO
    loadCustomerPO = (customer, status_id) => {

        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/inboundDashboard/${customer}/${status_id}`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        this.customer = customer;
        this.status_id = status_id;

        this.setState({loading: true, poList: null, po_status: status_id});
        req.then(this.loadCustomerPOSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadCustomerPOSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
            if (resp.data.Error.message) this.context.alert(resp.data.Error.message);
            else this.context.alert(resp.data.Error);
            return;
        }
        this.setState({poList: resp.data});
    }
   
    // render functions
    renderDashboard = () => {
      const {classes} = this.props;
      const { dashboardDetail } = this.state;
      if (!dashboardDetail) return null;
      let rows = [];
      for (let customer_name in dashboardDetail) {
        rows.push(Object.assign({customer: customer_name}, dashboardDetail[customer_name]));
      }
      if (rows.length === 0) return null;
      let columns = [
        {
            key: 'customer',
            label: 'Customer',
        },
        {
            key: 2,
            label: 'Expected Arrivals',
            cellProps: {
                style: {
                  cursor: 'pointer',
                },
                title: 'Click to get details',
                onClick: (val, key, row, index)=>{
                    if (!parseInt(val)) return;
                    this.loadCustomerPO(row.customer, 2);
                }
            },
        },
        {
            key: 7,
            label: 'Partial Received',
            cellProps: {
                style: {
                  cursor: 'pointer',
                },
                title: 'Click to get details',
                onClick: (val, key, row, index)=>{
                    if (!parseInt(val)) return;
                    this.loadCustomerPO(row.customer, 7);
                }
            }
        },
        {
            key: 4,
            label: 'Closed PO',
            cellProps: {
                style: {
                    cursor: 'pointer',
                },
                title: 'Click to get details',
                onClick: (val, key, row, index)=>{
                    if (!parseInt(val)) return;
                    this.loadCustomerPO(row.customer, 4);
                }
            }
        },
        {
            key: "forecast",
            label: 'Forecast',
            render: (val, key, row)=>{
              let content = "";
              content += row["forecast_po"] ? row["forecast_po"] : 0;
              content += " | ";
              content += row["forecast_unit"] ? row["forecast_unit"] : 0;
              return <span>{content}</span>;
            }
        },
      ];

      return (
        <GridItem xs={12}>
          <CardContainer>
            <NewDataTable
              rows={rows}
              columns={columns}
              noPagination
              maxHeight={500}
            />
          </CardContainer>
        </GridItem>
      );
    }
    renderPOList = () => {
        const { poList, po_status } = this.state;
        const { classes } = this.props;

        if (!poList) return null;

        let colSettings = [
            {
                key: 'po',
                label: 'PO',
                contentNoWrap: true,
            },
            {
              key: 'arrival_dt',
              label: 'ArrivalDate',
              contentNoWrap: true,
              render: utils.formatDate,
          },
          {
              key: 'received_dt',
              label: 'ReceivedDate',
              contentNoWrap: true,
              render: utils.localizeDate,
          },
          {
              key: 'name',
              label: 'ItemName',
          },
          {
              key: 'sku',
              label: 'SKU',
              contentNoWrap: true,
          },
          {
              key: 'upc',
              label: 'UPC',
          },
          {
              key: 'quantity',
              label: 'Quantity',
          },
          {
              key: 'received',
              label: 'Received',
          },
          {
              key: 'discrepancy',
              label: 'Discrepancy',
              render: (val, key, row)=>{
                  return (
                      parseInt(row['received']) - parseInt(row['quantity'])
                  );
              },
          },
          {
              key: 'estimated_date',
              label: 'EstimatedDate',
              contentNoWrap: true,
              render: utils.tableCellOnDisplay,
          },
          {
              key: 'status_id',
              label: 'Status',
              contentNoWrap: true,
              render: utils.convertPOStatus,
          },
          {
              key: 'tracking',
              label: 'Tracking#',
          },
        ];

        let title = '';
        title += this.customer + '_' + utils.convertPOStatus(po_status);

        return (
          <GridItem xs={12}>
              <CardContainer>
                  <div>
                      <div className={classes.title} style={{height: '3rem'}}>
                          <Typography variant="h6">
                              {title}

                              {poList.length > 0 && <Button
                                style={{float: 'right'}}
                                onClick={()=>{
                                    utils.exportTableToCsv('table-export', title);
                                }}>Export</Button>}
                          </Typography>
                      </div>

                      <NewDataTable
                        rows={poList}
                        columns={colSettings}
                        noPagination
                        maxHeight={500}
                        id="table-export"
                      />
                  </div>
              </CardContainer>
          </GridItem>
        );
    }
}
export default withRouter(withStyles(styles)(WMSInboundDashboard));
