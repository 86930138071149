import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, Typography} from '@material-ui/core'
import utils from 'utils/utils'
import axios from 'axios'

import {CustomInput, BasicDialog, Button, NewDataTable, CardContainer, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0),
    marginTop: 0,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    marginBottom: '.5rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
}))

export default function EditOrderItem(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);

  const {order_id, item_id, item_allocation: defaultAllocation, item_inventory: defaultInventory, handleClose} = props;
  const [loading,setLoading] = useState(false);
  const [allocation,setAllocation] = useState(defaultAllocation.map((singleAllocation)=> Object.assign({allocated_quantity: parseInt(singleAllocation.quantity)}, singleAllocation)));
  const [inventory,setInventory] = useState(defaultInventory.filter((singleInventory)=> singleInventory.available && singleInventory.available > 0));

  function updatePickticket(reqData) {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/orderPickticket`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: reqData,
    });
    setLoading(true);
    req.then(updatePickticketSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert, errorCallback: function() {setLoading(false)}}));
  }
  function updatePickticketSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    dataContext.snackDisplay("Update pickticket success.");
    handleClose(resp.data);
  }
  function newTicketItem(reqData) {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/newpicktickitem`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: reqData,
    });
    setLoading(true);
    req.then(newTicketItemSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert, errorCallback: function() {setLoading(false)}}));
  }
  function newTicketItemSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    dataContext.snackDisplay("Update pickticket success.");
    handleClose(resp.data);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {renderItemInventory()}
        {renderAllocation()}
      </Grid>
    );
  }
  function renderAllocation() {
    if (!allocation || allocation.length == 0) return;
    return (
      <GridItem xs={12}>
        <CardContainer>
          <Typography variant="h6">Allocation</Typography>

          <NewDataTable
            rows={allocation}
            noPagination
            // rowHeight={50} 
            columns={[
              {
                key: 'sku',
                label: 'SKU',
              },  {
                key: 'upc',
                label: 'UPC',
              }, {
                key: 'item_name',
                label: 'Description',
              }, {
                key: 'location',
                label: 'Location',
              }, {
                key: 'quantity',
                label: 'Quantity',
                render: (val, key, row, index) => {
                  return (
                    <CustomInput
                        labelText=''
                        formControlProps={{
                          className: classes.customInput
                        }}
                        inputProps={{
                          value: val,
                          type: 'number',
                          // disabled: true,
                          style: {marginTop: 0},
                          inputProps: {
                            min: 0,
                            max: row.allocated_quantity,
                            step: 1
                          },
                          onChange: (e) => {
                            let val = e.target.value;
                            val = parseInt(val);
                            if (!val) val = 0;
                            if (val < 0) val = 0;
                            if (val > row.allocated_quantity) {
                              dataContext.alert("To increase quantity, please allocate from the Inventory table.");
                              return; // don't allow to increase
                            }
                            
                            let newAllocation = Array.from(allocation);
                            let newSingle = Object.assign(newAllocation[index]);
      
                            newSingle.quantity = val;
                            newAllocation[index] = newSingle;
                            setAllocation(newAllocation);
                          }
                        }}
                      />
                  );
                },
              }, 
            ]}
            maxHeight={500}
          />
        </CardContainer>
      </GridItem>
    );
  }
  function renderItemInventory() {
    return (
      <GridItem xs={12}>
        <CardContainer>
          <Typography variant="h6">Inventory</Typography>

          <NewDataTable
            rows={inventory}
            // rowHeight={40} 
            noPagination
            columns={[
              {
                key: 'sku',
                label: 'SKU',
              }, {
                key: 'upc',
                label: 'UPC',
              }, {
                key: 'description',
                label: 'Description',
              }, {
                key: 'location',
                label: 'Location',
                contentNoWrap: true,
              },  {
                key: 'available',
                label: 'Available',
              }, 
              {
                key: 'expiration_date',
                label: 'Expiration Date',
                contentNoWrap: true,
              }, 
              {
                key: 'lot',
                label: 'Lot',
              }, 
              {
                key: 'quantity_input',
                label: 'Quantity to Add',
                render: (val, key, row, index) => {   
                  return (
                    <CustomInput
                      labelText=''
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customInput
                      }}
                      inputProps={{
                        value: val,
                        type: 'number',
                        style: {marginTop: 0},
                        inputProps: {
                          min: 0,
                          max: row.available,
                          step: 1
                        },
                        onChange: (e) => {
                          let val = e.target.value;
                          val = parseInt(val);
                          if (!val) val = 0;
                          if (val < 0) val = 0;
                          if (parseInt(val) > parseInt(row.available)) {
                            alert(`Only ${parseInt(row.available)} items available at this location!`);
                            return;
                          }
                          let newInventory = Array.from(inventory);
                          let newSingle = Object.assign(newInventory[index]);
      
                          newSingle.quantity_input = val;
                          newInventory[index] = newSingle;
                          setInventory(newInventory);
                        }
                      }}
                    />
                  );
                },
              }, 
            ]}
          />
        </CardContainer>
      </GridItem>
    );
  }
  function onSubmit () {
    let reqData = {
      order_id, item_id,
      line_item_id: props.line_item_id || '',
      packslip_id: props.packslip_id || '',
      total_quantity: 0,
      line_item_quantity: 0,
      sku: props.sku || '',
    };

    let allocatedInventory = [];
    for (let singleAllocation of allocation) {
      reqData.packslip_id = singleAllocation.packslip_id || '';
      reqData.sku = singleAllocation.sku || '';
      reqData.line_item_id = singleAllocation.line_item_id || '';
      reqData.line_item_quantity = singleAllocation.line_item_quantity || 0;
      reqData.total_quantity += parseInt(singleAllocation.quantity);
    }
    for (let singleInventory of inventory) {
      if (singleInventory.quantity_input && singleInventory.quantity_input > 0) {
        reqData.total_quantity += parseInt(singleInventory.quantity_input);
        allocatedInventory.push(singleInventory);
      }
    }
    reqData.allocation = allocation;
    reqData.inventory = allocatedInventory;

    if (allocation && allocation.length > 0) {
      // if has old allocation, editing item
      if (reqData.total_quantity != reqData.line_item_quantity) {
        dataContext.confirm(`The order quantity is ${reqData.line_item_quantity}, are you sure to submit the change with discranpancy?`, 
        ()=>{updatePickticket(reqData);})
      } else {
        updatePickticket(reqData);
      }
    } else {
      // if NO old allocation, adding new item
      if (reqData.inventory && reqData.inventory.length > 0) newTicketItem(reqData);
      else {
        // if no inventory allocated
        dataContext.alert("Nothing allocated!");
      }
    } 
  }

  let title = (allocation && allocation.length > 0) ? 'Edit Pickticket Item' : 'Add New Item';

  return (
    <BasicDialog
      fullWidth
      maxWidth='lg'
      open
      onClose={(e, reason)=>{if (reason != 'backdropClick' && reason != 'escapeKeyDown') handleClose();}}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={()=>handleClose()}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}