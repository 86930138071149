import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { InputAdornment, Grid, makeStyles, MenuItem, InputLabel, FormControl, Select  } from '@material-ui/core'
import {
  Info
} from '@material-ui/icons'

import { CustomInput, BasicDialog, Button } from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function EditCarrier(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, client_view, account: defaultAccount} = props;

  const [account,setAccount] = useState(Object.assign({}, defaultAccount));

  function updateAccount(val, key) {
    let new_account = Object.assign({}, account);
    new_account[key] = val;
    setAccount(new_account);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Third Party</InputLabel>
          <Select
              onChange={(e)=>{updateAccount(e.target.value, 'third_party')}}
              value={account.third_party || 'NO'}
          >
            <MenuItem value='YES'>Yes</MenuItem>
            <MenuItem value='NO'>
              <div style={{width: '100%', display:'flex', alignItems: 'center'}}>
                { 
                // client_view && 
                // <Info
                //   style={{cursor: "pointer"}}
                //   onClick={(e)=>{ e.stopPropagation(); alert('hi')}}
                // />
                }No
              </div>
            </MenuItem>
          </Select>
        </FormControl>

        <CustomInput
          labelText='Account'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.account || '',
            onChange: (e)=>{updateAccount(e.target.value, 'account')},
          }}
        />

        <CustomInput
          labelText='Vendor'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.vendor || '',
            onChange: (e)=>{updateAccount(e.target.value, 'vendor')},
          }}
        />

        <CustomInput
          labelText='Username | Meter Number'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.user || '',
            onChange: (e)=>{updateAccount(e.target.value, 'user')},
          }}
        />

        <CustomInput
          labelText='Password'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.password || '',
            onChange: (e)=>{updateAccount(e.target.value, 'password')},
          }}
        />

        <CustomInput
          labelText='Access Key | Token'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.token || '',
            onChange: (e)=>{updateAccount(e.target.value, 'token')},
          }}
        />

        <CustomInput
          labelText='Country Code'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.countrycode || '',
            onChange: (e)=>{updateAccount(e.target.value, 'countrycode')},
          }}
        />

        <CustomInput
          labelText='Postcode'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.postcode || '',
            onChange: (e)=>{updateAccount(e.target.value, 'postcode')},
          }}
        />

        <CustomInput
          labelText='Easypost Id'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.easypost_id || '',
            onChange: (e)=>{updateAccount(e.target.value, 'easypost_id')},
          }}
        />

        <CustomInput
          labelText='Easypost Token'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: account.easypost_token || '',
            onChange: (e)=>{updateAccount(e.target.value, 'easypost_token')},
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    if (account.token && account.third_party == 'YES') {
      dataContext.alert("If access Token is not empty, cannot set Third Party field to 'YES'");
    } else {
      handleSubmit(account);
    }
  }

  let title = defaultAccount.id ? 'Edit Carrier Account' : 'Add Carrier Account';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}