import React from 'react';
import PropTypes from 'prop-types'
import utils from 'utils/utils'
import _, { orderBy } from 'lodash'
import cx from 'classnames'
import EnhancedTableRow from './EnhancedTableRow'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import {withStyles, Paper, Tooltip, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, TablePagination, Checkbox} from '@material-ui/core'

const styles = theme => ({
  root: {
      width: '100%',
      marginTop: theme.spacing(1),
  },
  tableWrapper: {
      overflowX: 'auto',
  },
  tableCell: {
    overflow: 'auto',
    // display: 'inline-flex',
    margin: 'auto',
    verticalAlign: 'middle',
    // fontSize: '1.25rem',
    fontSize: '1rem',
    // flexGrow: 1,
    // minHeight: 0,
    // alignItems: 'center',
    // justifyContent: 'center',
    wordWrap: 'break-word'
  },
  tooltip : {
    fontSize: "1rem"
  },
  checkBox: {
    padding: '0'
  }
});

class NewDataTable extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        rows: PropTypes.array.isRequired,
        withPaper: PropTypes.bool,
        titleProps: PropTypes.object,
        tableTitle: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]),
        rowHeight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        maxHeight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        minHeight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        exportTable: PropTypes.func,
        columns: PropTypes.array.isRequired,
        rowsPerPage: PropTypes.number,
        noPagination: PropTypes.bool,
        rowSelection: PropTypes.shape({
          selected: PropTypes.object.isRequired, // the selected rows
          handleSelectedChange: PropTypes.func.isRequired, // change handler of selected rows
          uniqueID: PropTypes.string, // the identifier of each row
        }),
        rowSort: PropTypes.shape({
          sortingFuncs: PropTypes.func, // change handler of selected rows
        }),
        searchable: PropTypes.bool,
        // rowSearch: PropTypes.shape({
        //   searchable: PropTypes.bool, // change handler of selected rows
        //   onSearch: PropTypes.func
        // }),
        fixedRows: PropTypes.array,
        rowSettings: PropTypes.object
    };

    static defaultProps = {
        // rowHeight: 36,
        rowHeight: 60,
        rowSelection: null,
        rowSettings : {},
        tableTitle: '',
        rowsPerPage: 100
    };

    constructor(props) {
        super(props);

        let defaultColSearchBy = {};
        for (let col of props.columns) {
          if (!col.searchable) continue;
          defaultColSearchBy[col.key] = true;
        }

        this.state = {
          // Pagination
          page: 0,
          rowsPerPage: props.rowsPerPage, 
          // Sort Row
          sortBy: props?.rowSort?.sortBy || '',
          sortOrder: props?.rowSort?.sortOrder || 'asc',
          // Row Search 
          colSearchBy: defaultColSearchBy,
          colSearchText: '',
        };
    }

    handleChangePage = (event, page) => {
      this.setState({page});
    };
    // Row Select
    onSelectAllChange = (e) => {
      const {rows, rowSelection, searchable} = this.props;
      if (!rowSelection) return;
      let newVal = e.target.checked;
      if (!newVal && rowSelection) rowSelection.handleSelectedChange({}); // de-select all
      else {
        // select all
        let newSelected = {};
        let filteredRows = Array.from(rows);
        if (searchable) filteredRows = this.getFilteredRows(rows);
        filteredRows.map((row, index)=>{
          let selectedID = index;
          if (rowSelection.uniqueID) selectedID  = row[rowSelection.uniqueID] 
          newSelected[selectedID] = row;
        });
        rowSelection.handleSelectedChange(newSelected);
      }
      
    }
    onRowSelectChange = (index, row, e) => {
      const {rows, rowSelection} = this.props;
      if (!rowSelection) return;
      let newVal = e.target.checked;
      let newSelected = Object.assign({}, rowSelection.selected);
      let selectedID = rowSelection.uniqueID ? row[rowSelection.uniqueID] : index
      if (newVal) newSelected[selectedID] = row; // select
      else delete newSelected[selectedID]; // de-select
      rowSelection.handleSelectedChange(newSelected);
    }
    // Row Search
    searchTextUpdate = (newVal) => {
      if (!newVal) newVal = '';
      if (this.state.colSearchText === newVal) return;
      this.setState({colSearchText: newVal});
      // if search changed, need to de-select all
      if (this.props.rowSelection) this.props.rowSelection.handleSelectedChange({})
    }
    getFilteredRows = (rows) => {
      const { searchable, columns } = this.props;
      const { colSearchText, colSearchBy } = this.state;
      if (!searchable || !colSearchText) return rows;
      let fields = columns.filter(col=>col.searchable);
      if (fields.length === 0) fields = columns;
      else fields = fields.filter(col=>!!colSearchBy[col.key]);
      return rows.filter(row=>this.filterData(row, colSearchText, fields));
    }
    // The function to perform row filtering
    filterData = (row, filterText, fields) => {
      const { customFilter } = this.props;
       // if custom filter is provided, use it, otherwise, use default
       let filterFunc = customFilter || this.defaultFilterFunc;
       // fixed row will always be displayed
       if (row.fixed) return true;
       for (let field of fields) {
        let val = row[field.key];
        if (field.searchOnRender && field.render) {
          val = field.render(row[field.key], field.key, row);
        }
        if (typeof val === 'number') val = '' + val;
        if (typeof val !== 'string') continue;
        if (filterFunc(filterText, val)) return true;
       }
       return false;
    }
    // Keyword matching function, can be overwriten
    defaultFilterFunc = (filter, data) => {
      // might be multiple filter separated by space
      let found = false;
      let matched_parts = 0;
      let filters = [];
      filters = filter.split(' ');
      filters = filters.map((elem)=>elem.trim().toUpperCase()).filter((elem)=>!!elem);
      if (!filter || filters.length == 0) return true; // if no filter, show all
      // Find multiple keyword, in any order
      // for (let singleFilter of filters) {
      //   if (data.toUpperCase().indexOf(singleFilter) > -1) matched_parts++;
      //   else break;
      //   if (matched_parts == filters.length) found = true;
      // }
      let searchReg = filters.join(".*");
      searchReg = new RegExp(searchReg, "g");
      found = searchReg.test(data.toUpperCase());
      return found;

      // return data.toUpperCase().indexOf(filter.toUpperCase()) > -1;
    }
    defaultSortingFunc = (orderBy, order, a, b) => {
      let a_val = a[orderBy] ? a[orderBy] : '';
      let b_val = b[orderBy] ? b[orderBy] : '';

      if (order === 'desc') {
        return (b_val < a_val ? -1 : 1);
      } else {
        return (a_val < b_val ? -1 : 1);
      }
    }
    // Row Sort
    handleRequestSort = (property) => {
      let sortOrder = 'desc';
      if (this.state.sortBy === property && this.state.sortOrder === 'desc') sortOrder = 'asc';
      this.setState({sortBy: property, sortOrder, });
    }

    renderTableRow = (row, index) => {
      const {classes, columns, rowSelection, rowHeight, rowSettings} = this.props;

      return (
        <EnhancedTableRow 
          key={index}
          classes={classes}
          columns={columns}
          index={index}
          row={row}
          rowSelection={rowSelection}
          rowHeight={rowHeight}
          rowSettings={rowSettings}
          onRowSelectChange={this.onRowSelectChange}
        />
      );
    }

    renderTableTitleArea = () => {
      const { tableTitle, columns, titleProps, searchable, exportTable, rows: dataRows } = this.props;
      const { colSearchBy, colSearchText } = this.state;
      let toolbarProps = {
        tableTitle: tableTitle,
        exportTable,
        titleProps,
        filterable: searchable || false,
        searchText: colSearchText,
        searchTextUpdate: this.searchTextUpdate,
        filteredRows: this.getFilteredRows(Array.from(dataRows)),
        columns: columns,
        colFilterBy: colSearchBy,
        handleColFilterByChange: (newVal)=>{this.setState({colSearchBy: newVal})},
      };

      return <EnhancedTableToolbar {...toolbarProps}/>;
    }
    renderTableHeader = () => {
      const {columns, rows, classes} = this.props;
      const {sortBy, sortOrder} = this.state;
      return (
        columns.map(column => {
          let lbl = column.label;
          if (column.colDesc) {
            lbl = <Tooltip title={<div className={classes.tooltip}>{column.colDesc}</div>} aria-label={column.colDesc}><span>{lbl}</span></Tooltip>;
          }
          // Todo, right now, sort will overwrite column description
          if (column.sortable) {
            lbl = (
              <Tooltip
                title="Sort"
                placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                enterDelay={300}
              >
                <TableSortLabel
                  active={sortBy === column.key}
                  direction={sortOrder}
                  onClick={()=>this.handleRequestSort(column.key)}
                >
                  {column.label}
                </TableSortLabel>
              </Tooltip>
            );
          }

          return (
              <TableCell
                  align="center"
                  key={column.key}
                  numeric={column.numeric}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  style={column.header_style ? column.header_style : {}}
                  className={column.header_class ? column.header_class : ''}
              >
                  {lbl} <br/>
                  {/* <TextField
                    labelText=''
                    formControlProps={{
                      fullWidth: true,
                      style: {marginTop: 0}
                    }}
                    inputProps={{
                      inputRef: (el)=>{this.searchDateFromInput = el},
                      defaultValue: "",
                    }}
                  /> */}
              </TableCell>
          );
      }, this)
      );
    }

    render() {
      const {classes, fixedRows, columns, rowSelection, withPaper, exportTable, noPagination, titleProps, tableTitle, maxHeight, minHeight,  rowSettings, searchable,
        rowsPerPage: defaultRowsPerPage, rowHeight, rows: dataRows,
        ...restProps} = this.props;
      const { sortBy, sortOrder } = this.state;
      let rows = Array.from(dataRows);
      const {page, rowsPerPage} = this.state;

      let tableProps = {
          className: classes.table,
          // style: {height: '100%'},
          size: 'small', // default table padding  to dense
      };
      if (restProps) tableProps = Object.assign(tableProps, restProps);

      let empty = null;
      if (rows.length === 0) {
        let colLength = columns.length;
        if (rowSelection) colLength++;
        empty = <TableRow><TableCell colSpan={colLength}><div style={{maxHeight: rowHeight+'px'}}>No Data</div></TableCell></TableRow>;
      }

      let containerStyle = {};
      if (maxHeight) containerStyle.maxHeight = maxHeight+'px';
      if (minHeight) containerStyle.minHeight = minHeight+'px';

      let filteredRows = rows;
      if (searchable) filteredRows = this.getFilteredRows(rows);
      if (sortBy) {
        let sortingFunction = this.defaultSortingFunc;
        for (let col of columns) {
          if (col.key == sortBy) {
            if (col.sortingFunction) sortingFunction = col.sortingFunction;
            break;
          }
        }
        filteredRows = filteredRows.sort((a, b)=>{
          return sortingFunction(sortBy, sortOrder, a, b);
        });

        // if (sortOrder === 'desc') {
        //   filteredRows = filteredRows.sort((a, b) => {
        //       let a_val = a[sortBy] ? a[sortBy] : '';
        //       let b_val = b[sortBy] ? b[sortBy] : '';
        //       return (b_val < a_val ? -1 : 1);
        //   });
        // } else {
        //     filteredRows = filteredRows.sort((a, b) => {
        //         let a_val = a[sortBy] ? a[sortBy] : '';
        //         let b_val = b[sortBy] ? b[sortBy] : '';
        //         return (a_val < b_val ? -1 : 1);
        //     });
        // }
      }
      let filteredCurrentPage = filteredRows;
      if (!noPagination) {
        filteredCurrentPage = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }
      
      // let tableTitleArea = null;
      // if (tableTitle) tableTitleArea = (
      //   <div style={{width: '100%', padding: "0 1rem"}}>
      //     <div className={classes.title}>
      //       <Typography variant="h6" id="tableTitle">
      //         {tableTitle}
      //       </Typography>
      //     </div>
      //   </div>
      // );

      // render with paper container
      if (withPaper) {
        return (
          <Paper className={classes.root}>
            {this.renderTableTitleArea()}
            <div className={classes.tableWrapper} style={containerStyle}>
              <Table stickyHeader {...tableProps}>
                <TableHead>
                  <TableRow>
                    {rowSelection && (
                      <TableCell
                        align="center"
                        // padding={column.disablePadding ? 'none' : 'normal'}
                      >
                        <Checkbox
                          color={rowSelection.checkboxColor || 'secondary'}
                          checked={(Object.keys(rowSelection.selected).length === filteredRows.length) && filteredRows.length > 0}
                          onChange={this.onSelectAllChange}
                          disabled={filteredRows.length === 0}
                        />
                      </TableCell>
                    )}
                    {this.renderTableHeader()}
                  </TableRow>
                </TableHead>
  
                <TableBody>
                  {fixedRows && fixedRows.map(this.renderTableRow)}
                  {filteredCurrentPage.length > 0 ? filteredCurrentPage.map(this.renderTableRow) : empty}
                </TableBody>
              </Table>
            </div>
  
            {
              !noPagination && 
              <TablePagination
                component="div"
                count={this.getFilteredRows(rows).length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                page={page}
                onPageChange={this.handleChangePage}
              />
            }
            
          </Paper>
        );
      }
      // default render without paper

      return (
        <div className={classes.root}>

          {this.renderTableTitleArea()}

          <div className={classes.tableWrapper} style={containerStyle}>
            <Table stickyHeader {...tableProps}>
              <TableHead>
                <TableRow>
                  {rowSelection && (
                    <TableCell
                      align="center"
                      // padding={column.disablePadding ? 'none' : 'normal'}
                    >
                      <Checkbox
                        color={rowSelection.checkboxColor || 'secondary'}
                        checked={(Object.keys(rowSelection.selected).length === filteredRows.length) && filteredRows.length > 0}
                        onChange={this.onSelectAllChange}
                        disabled={filteredRows.length === 0}
                      />
                    </TableCell>
                  )}
                  {this.renderTableHeader()}
                </TableRow>
              </TableHead>

              <TableBody>
                {fixedRows && fixedRows.map(this.renderTableRow)}
                {filteredCurrentPage.length > 0 ? filteredCurrentPage.map(this.renderTableRow) : empty}
              </TableBody>
            </Table>
          </div>

          {
            !noPagination && 
            <TablePagination
              component="div"
              count={this.getFilteredRows(rows).length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                  'aria-label': 'Next Page',
              }}
              page={page}
              onPageChange={this.handleChangePage}
            />
          }
          
        </div>
      );
    }
}

export default withStyles(styles)(NewDataTable);
