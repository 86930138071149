import React, {useState, useContext, useRef} from 'react'
import DataContext from 'context/Data'
import axios from 'axios';
import utils from 'utils/utils'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select,
  Paper,
  Tabs,
  Tab,
  AppBar,
} from '@material-ui/core'
import {NewDataTable, CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabRoot: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
}))

export default function CommentAttachment(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, ticket, comments: defaultComments, attachments: defaultAttachments} = props;

  const [comments, setComments] = useState(Array.from(defaultComments));
  const [attachments, setAttachments] = useState(Array.from(defaultAttachments));
  const [active_tab, setActiveTab] = useState(0);
  const [comment_input, setCommentInput] = useState('');
  const [loading,setLoading] = useState(false);

  const fileInputRef = useRef(null);

  function renderContent() {
    const ticket_tabs = [
      {
        value: 'comments',
        label: 'Comments',
        render: renderComments
      },
      {
        value: 'attachments',
        label: 'Attachments',
        style: (attachments && attachments.length > 0) ? {fontWeight: 'bold', color: 'blue'} : {},
        render: renderAttachments
      }
    ];

    return (
      <Grid container className={classes.root} spacing={2}> 
          {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

          <div className={classes.tabRoot}>
            <AppBar position="static" color="default">
              <Tabs
                value={active_tab}
                onChange={(e, val)=>{
                  setActiveTab(val);
                  // when switching tab, don't refresh data
                }}
                indicatorColor="primary"
                textColor="primary"
              >
                {ticket_tabs.map((elem)=> <Tab label={elem.label} style={elem.style || {}} key={elem.value}/>)}
              </Tabs>
            </AppBar>

            {ticket_tabs[active_tab].render()}
          </div>
      </Grid>
    );
  }
  function renderComments() {
    let columns = [
      {
        key: 'name',
        label: 'User',
        render: (val, key, rowData) => {
          return val.replace('User :' , '');
        },
      },
      {
        key: 'content',
        label: 'Content',
        render: (val, key, rowData) => {
          let display_content = val.replace('Content :' , '');
          return (
            <React.Fragment>
              {display_content.split('\n').map((elem, index)=><div key={index}>{elem}</div>)}
            </React.Fragment>
          );
        },
      },
      {
        key: 'date',
        label: 'Date/Time',
        render: utils.localizeDate
      }
    ];

    return (
      <Paper>
        <div style={{padding: '.75rem'}}>
        <Grid>
            <GridItem sm={12} md={6}>
            <form>
              <CustomInput
                labelText='Text Comment'
                formControlProps={{
                    fullWidth: true,
                    className: classes.customInput
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    onChange: (e)=>{setCommentInput(e.target.value)},
                    value: comment_input,
                    multiline: true,
                    rows: 3
                }}
              />
              <Button onClick={handleCommentSubmit}>Add Comment</Button>
            </form>
          </GridItem>
          
          <br/>

          <GridItem sm={12}>
              <NewDataTable
                rows={comments}
                noPagination
                rowHeight={'auto'}
                columns={columns}
              />
            </GridItem>
          </Grid>
        </div>
      </Paper>
    );
  }
  function renderAttachments() {
    let columns = [
      {
        key: 'name',
        label: 'File Name',
        render: (val, key, rowData) => {
          return <Button onClick={()=>{downloadFile(rowData)}}>{val}</Button>;
        },
      }, 
    ];

    return (
      <Paper>
        <div style={{padding: '.75rem'}}>
        <Grid>
            <GridItem sm={12} md={6}>
            <form>
              <CustomInput
                labelText='Upload File'
                formControlProps={{
                    fullWidth: true,
                    className: classes.customInput,
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    type: 'file',
                    name: 'document',
                    inputProps: {
                      id: "upload-pdf-file"
                    },
                    ref: fileInputRef,
                }}
              />   
              <Button onClick={handleFileSubmit}>Upload</Button>
            </form>
          </GridItem>
          
          <br/>

          <GridItem sm={12}>
              <NewDataTable
                rows={attachments}
                noPagination
                // rowHeight={50}
                columns={columns}
              />
            </GridItem>
          </Grid>
        </div>
      </Paper>
    );
  }

  function downloadFile(attachment) {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/ticketdocument`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: attachment
    });
    setLoading(true);
    req.then(downloadFileSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert}));
  }
  function downloadFileSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      dataContext.alert(resp.data.Error);
      return;
    }

    if (resp.data.url) {
      let newTab = window.open('about:blank');
      if (newTab) newTab.location.href = resp.data.url;
      else {
        dataContext.alert("Unable to open new tab, please change browser settings to allow pop-up.");
      }
    } else {
      dataContext.alert("File not found!");
    }
  }
  function handleCommentSubmit() {
    let user = localStorage.getItem('fullname');
    let err = '';
    if (!comment_input || !comment_input.trim()) err += 'Comment content is required.';
    if (err) dataContext.alert(err);
    else {
      let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/ticketcomment/${ticket.ticket_id}`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
          content: comment_input, user: user, ticket_id: ticket.ticket_id
        }
      });
      setLoading(true);
      req.then(addTicketCommentSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert}));
    }
  }
  function addTicketCommentSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      dataContext.alert(resp.data.Error);
      return;
    }

    setComments(resp.data.comment);
    if (resp.data.file) setAttachments(resp.data.file);
    dataContext.snackDisplay("Add comment success.");
  }
  function handleFileSubmit() {
    let err = '';
    let formData = new FormData();
    let file_document = document.getElementById('upload-pdf-file');
    let file_path = file_document.value;
    if (!file_document.files || file_document.files.length < 1 || !file_path) {
      err += "No file selected.";
    } else {
      formData.append('document', file_document.files[0]);
      formData.append('ticket_id', ticket.ticket_id);
    }
    if (err) {
      dataContext.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/ticketfile`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: formData
    });
    setLoading(true);
    req.then(uploadFileSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert}));
  }
  function uploadFileSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      dataContext.alert(resp.data.Error);
      return;
    }

    dataContext.snackDisplay("Add attachment success.");
    // manually update comments and files
    loadTicketComments();
    // if (resp.data.file) setAttachments(resp.data.file);
  }
  function loadTicketComments() {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/ticketcomment/${ticket.ticket_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    setLoading(true);
    req.then(loadTicketCommentsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert}));
  }
  function loadTicketCommentsSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }
    if (!resp.data || resp.data.length === 0) {
      dataContext.alert(resp.data.Error);
      return;
    }
    setComments(resp.data.comment || []);
    setAttachments(resp.data.file || []);
  }

  return (
    <BasicDialog
      fullWidth
      maxWidth='xl'
      open
      onClose={handleClose}
      header={props.title || "Comment and Attachment"}
      content={renderContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Close</Button>,
      ]}
    />
  );
}