 import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Toolbar, withStyles, Typography, IconButton } from '@material-ui/core'
import {
    Search
} from "@material-ui/icons";
import TableSearch from './TableSearch'
import {
    Button,
  } from 'components';

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
        paddingLeft: '1rem',
        display: "flex",
        alignItems: "center"
    },
    button: {
        margin: theme.spacing(1),
    },
});

class EnhancedTableToolbar extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        tableTitle: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]),
        filteredRows: PropTypes.array,
        exportTable: PropTypes.func,
        filterable: PropTypes.bool,
        searchText: PropTypes.string,
        searchTextUpdate: PropTypes.func.isRequired,
        onSearchChange: PropTypes.func,
    };

    static defaultProps = {
        tableTitle: '',
        selected: new Map()
    }

    constructor(props) {
        super(props);

        let showSearch = false;
        if (!props.tableTitle) showSearch = true;

        this.state = {
            showSearch: showSearch,
        };
    }

    render() {
        const { classes, titleProps, tableTitle, searchText, filterable, searchTextUpdate, onSearchChange, filteredRows, exportTable } = this.props;
        let restProps = titleProps || {};
        let titleArea = (
          tableTitle && <div {...restProps} className={classes.title}>
                <Typography variant="h6" style={{width: '100%'}} id="tableTitle">
                    {tableTitle ? tableTitle : ''}
                    {exportTable && <Button style={{marginLeft: '1rem'}} onClick={()=>exportTable(filteredRows)}>Export</Button>}
                </Typography>
            </div>
        );

        let searchProps = {
            onSearch: searchTextUpdate,
            onHide: this.hideSearch,
            searchText: searchText
        };

        if (onSearchChange) {
            searchProps.onSearchChange = onSearchChange;
        }

        if (filterable && this.state.showSearch) {
            titleArea = (
                <React.Fragment>
                    <TableSearch
                        {...searchProps}
                    />

                    {exportTable && <Button onClick={()=>exportTable(filteredRows)}>Export</Button>}
                </React.Fragment>
            );
        }

        if (!titleArea) {
            return exportTable ? <Button style={{margin: '1rem'}} onClick={()=>exportTable(filteredRows)}>Export</Button> : null;
        }
        return (
            <Toolbar
                style={{padding: 0}}
                className={classes.root}
            >
                <div style={{display: "flex",width: '100%'}}>
                    {titleArea}
                    <div className={classes.spacer} />
                    <div className={classes.actions} style={{display: 'inherit'}}>
                        {filterable && (this.props.tableTitle) ? (
                            <IconButton
                                color='white'
                                size="small"
                                aria-label={'Search'}
                                buttonRef={el => (this.searchButton = el)}
                                className={classes.button}
                                onClick={this.setActiveIcon}>
                                <Search/>
                            </IconButton>
                        ) : (
                            null
                        )}
                    </div>
                </div>
            </Toolbar>
        );
    }

    hideSearch = () => {
        this.props.searchTextUpdate(null);
        // if no table title passed in, always show search
        if (this.props.tableTitle) {
            this.setState(() => ({
                showSearch: false,
            }));

            this.searchButton.focus();
        }
    };

    setActiveIcon = () => {
        this.setState({showSearch: true});
    };

    getActiveIcon = (styles, iconName) => {
        return this.state.iconActive !== iconName ? styles.icon : styles.iconActive;
    };
}

export default EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
