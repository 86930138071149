import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, ButtonGroup, IconButton, Grid, Hidden, InputLabel, Select, FormControl, MenuItem, withStyles, } from "@material-ui/core";
import {
  Edit,
  DeleteForever,
  Done
} from '@material-ui/icons'
import {
  AjaxTable,
  KeyValueTable,
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import EditWorkOrderDialog from 'views/Dialogs/WorkOrder/Edit'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import moment from 'moment-timezone'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  buttonPushRight: {
    marginLeft: 'auto',
  },
  toolBar: {
    display: 'flex',
    paddingBottom: '.5rem',
    width: '100%'
  },
});

class ManageWorkOrder extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.temp_data = {
      edit_work_order: null,
    };

    this.state = {
      loading: false,
      dialog: '',
      search_type: 'number',
      search_keyword: '',
      work_order_list: [],
      single_work_order: null
    };

    document.title = "Manage Work Orders";
  }

  componentDidMount() {
    if (localStorage.getItem("ticketWorkOrder")) {
      this.loadWorkOrderFromStorage({
        workorder_number: localStorage.getItem("ticketWorkOrder"),
        customer_id: localStorage.getItem("ticketWorkOrderCustomer")
      });
    } else {
      this.loadDefaultWorkOrder();
    }
  }

  // load default list
  loadDefaultWorkOrder = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/workOrderList`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.loadDefaultWorkOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadDefaultWorkOrderSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (resp.data) {
      this.setState({work_order_list: resp.data});
    } else {
      alert("Load work orders error.");
    }
  }
  // load from localstorage
  loadWorkOrderFromStorage = ({workorder_number, customer_id}) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/workOrder`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: customer_id, warehouse: localStorage.getItem('warehouse_id')
      },
      data: { type: 'number', key_word: workorder_number },
    });

    this.setState({loading: true});
    req.then(this.searchWorkOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  // Search
  searchWorkOrder = () => {
    const { search_type, search_keyword } = this.state;

    if (!utils.formatString(search_keyword)) {
      alert("Search keyword format is wrong!");
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/workOrder`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: { type: search_type, key_word: utils.formatString(search_keyword) },
    });

    this.setState({loading: true});
    req.then(this.searchWorkOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  searchWorkOrderSuccess = (resp ) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
   
    this.setState({productList: resp.data});
    if (resp.data.length === 0) {
      this.context.alert("Already the last page.");
      return;
    }

    // clear storage data from other page to only load once
    localStorage.removeItem("ticketWorkOrder");
    localStorage.removeItem("ticketWorkOrderCustomer");

    if (!resp.data || resp.data.length === 0) {
      alert("Work order not found");
      return
    }

    this.setState({work_order_list: resp.data});
  }
  // Create
  createWorkOrder = (new_work_order) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/newworkOrder`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: new_work_order,
    });

    this.setState({loading: true});
    req.then(this.createWorkOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  createWorkOrderSuccess = (resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.setState({dialog: '', work_order_list: resp.data});
  }
  // Edit
  editWorkOrder = (new_work_order) => {
    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/workOrder`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: new_work_order,
    });

    this.setState({loading: true});
    req.then(this.editWorkOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  editWorkOrderSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // reload the workorder list
    this.loadDefaultWorkOrder();
    this.setState({dialog: ''});

    // let new_list = Array.from(this.state.work_order_list);
    // new_list.forEach((elem, index)=>{
    //   if (elem.workorder_id == resp.data.workorder_id) {
    //     new_list[index] = resp.data;
    //     return;
    //   }
    // });

    // this.temp_data.edit_work_order = null;
    // this.setState({work_order_list: new_list, dialog: ''});
  }
  // Delete
  deleteWorkOrder = (work_order_id, index) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/workOrder/${work_order_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteWorkOrderSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteWorkOrderSuccess = (delete_index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.loadDefaultWorkOrder();
    // let new_list = Array.from(this.state.work_order_list);
    // new_list.splice(delete_index);
    // this.setState({work_order_list: new_list});
  }
  // Close (mark complete)
  closeWorkOrder = (work_order_id, index) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/closeworkorder/${work_order_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.closeOrderSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  closeOrderSuccess = (close_index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.context.snackDisplay("Work order is closed.");
    this.loadDefaultWorkOrder();
    // let new_list = Array.from(this.state.work_order_list);
    // new_list.splice(close_index);
    // this.setState({work_order_list: new_list});
  }

  renderWorkOrderTable = () => {
    const {classes} = this.props;
    const {work_order_list} = this.state;

    let columns = [
      {
        key: 'action',
        label: 'Actions',
        contentNoWrap: true,
        render: (val, key, row, index)=>{        
            let workorder_date = utils.formatDateTime(row.workorder_date, 'YYYY-MM-DD');   
            if (row.status_type !== 'OPEN' && moment(workorder_date).isSameOrBefore(moment().day(0))) return '';
            return (
                <div style={{minWidth: '90px', whiteSpace: 'noWrap'}}>
                    <IconButton size="small" color='primary' onClick={
                        ()=>{
                          this.temp_data.edit_work_order = row;
                          this.setState({dialog: 'edit'});
                        }
                    } variant="fab" aria-label="Edit" className={classes.button} style={{marginRight: ".25rem"}}>
                        <Edit/>
                    </IconButton>

                    <IconButton size="small" onClick={()=>{
                      this.context.confirm("Are you sure to delete this work order?", this.deleteWorkOrder.bind(this, row.workorder_id, index));
                    }} color='secondary' variant="fab" aria-label="Delete" className={classes.button} style={{marginRight: ".25rem"}}>
                        <DeleteForever/>
                    </IconButton>

                    {
                      row.status_type === 'OPEN' && 
                      <IconButton size="small" onClick={()=>{
                        this.context.confirm("Are you sure to close this work order??", this.closeWorkOrder.bind(this, row.workorder_id, index));
                      }} variant="fab" aria-label="Delete" className={classes.button}>
                          <Done style={{color: 'green'}}/>
                      </IconButton>
                    }
                   
                </div>
            );
        },
      },
      {
        key: 'shortname',
        label: 'Customer',
      },
      {
        key: 'workorder_number',
        label: 'WorkOrderNumber',
      },
      {
        key: 'type',
        label: 'Type',
        contentNoWrap: true,
      },
      {
        key: 'status_type',
        label: 'Status',
      },
      {
          key: 'sub_type',
          label: 'Sub Category',
      },
      {
          key: 'user',
          label: 'User',
      },
      {
        key: 'hours',
        label: 'Hours',
        render: (val)=>val ? val : 0,
      },
      {
        key: 'fix_amount',
        label: 'Fix Amount',
      },
      {
        key: 'notes',
        label: 'Notes',
        // width: 200,
        // cellProps: { style: {minWidth: "120px", maxWidth: "300px",wordWrap: "break-word"} }
      },
      {
        key: 'workorder_date',
        label: 'WorkOrderDate',
        contentNoWrap: true,
        render: utils.localizeDate,
      },
    ];

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <NewDataTable
            rows={work_order_list}
            // rowHeight={50} 
            columns={columns}
            noPagination={work_order_list.length <= 30} 
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderDialog = () => {
    const { dialog } = this.state;
    switch (dialog) {
      case 'create':
        return (
          <EditWorkOrderDialog
            handleSubmit={this.createWorkOrder}
            handleClose={()=>{this.setState({dialog: ''});}}
          /> 
        )
      case 'edit': 
      return (
        <EditWorkOrderDialog
          work_order={this.temp_data.edit_work_order}
          handleSubmit={this.editWorkOrder}
          handleClose={()=>{this.setState({dialog: ''});}}
        /> 
      )
      default:
          return null;
    }
  }

  render() {
    const {classes} = this.props; 
    const {loading, search_type, search_keyword} = this.state;

    return (
      <Grid container spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
              
        {this.renderDialog()}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <div>
              <FormControl fullWidth required className={classes.customInput}>
                <InputLabel shrink>Search By</InputLabel>
                <Select
                  value={search_type}
                  onChange={(e)=>{this.setState({search_type: e.target.value})}}
                >
                  <MenuItem value='number'>Work Order Number</MenuItem>
                  <MenuItem value='notes'>Notes</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText='Search Keyword'
                labelProps={{shrink: true}}
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  value: search_keyword, 
                  onChange: (e)=>{this.setState({search_keyword: e.target.value})}
                }}
              />

              <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
                <Button onClick={this.searchWorkOrder}>Search</Button>
                <Button className={classes.buttonPushRight} color="primary" onClick={()=>{this.setState({dialog: 'create'})}}>Create New</Button>
              </div>
            </div>
          </CardContainer>
        </GridItem>

        {this.renderWorkOrderTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(ManageWorkOrder);
