import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {
  Grid, List, ListItem, ListItemIcon,
  Collapse, makeStyles, FormControlLabel, Checkbox, FormControl
} from '@material-ui/core'
import { CustomInput, BasicDialog, Button } from 'components'
import {Add, Remove} from '@material-ui/icons'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  selectEmpty: {
      marginTop: theme.spacing(2),
  },
  table: {
      minWidth: 700,
  },
  tableWrapper: {
      overflowX: 'auto',
  },
  customInput: {
      margin: '0'
  },
  listItem: {
      paddingTop: 0,
      paddingBottom: 0
  },
  nested: {
      paddingLeft: theme.spacing(6),
  },
}))

export default function EditUserRole(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleDialogClose, handleSubmit, roleID, title} = props;

  const [roleName, setRoleName] = useState(props.roleName || "");
  const [menu, setMenu] = useState(props.menu);
  const [expanded, setExpanded] = useState(null);

  function renderFormContent() {
    let menuList = [];

    for (let rootID in menu) {
      let permissionRoot = menu[rootID];
      if (!permissionRoot.sub) {
        // Menu doesn't have sub menu
        menuList.push(
          <ListItem key={`root_${permissionRoot.menu_key}`} className={classes.listItem}>
            <ListItemIcon style={{visibility: 'hidden'}}><Add /></ListItemIcon>
            
            <FormControlLabel
              control={
                <Checkbox
                  checked={permissionRoot.permission == 1}
                  onChange={(e)=>{if (permissionRoot.permission != e.target.checked) onPermissionChange(e.target.checked, permissionRoot.menu_key)}}
                  color="primary"
                />
              }
              label={permissionRoot.menu_label}
            />
          </ListItem>
        );
      } else {
        // if this is a root node, check child node first
        menuList.push(
          <ListItem key={`root_${permissionRoot.menu_key}`} className={classes.listItem}>
            <ListItemIcon>
              {expanded === permissionRoot.menu_key ? <Remove onClick={()=>{setExpanded(null)}} /> : <Add onClick={()=>{setExpanded(permissionRoot.menu_key)}} />}
            </ListItemIcon>

            <FormControlLabel
              control={
                <Checkbox
                  checked={permissionRoot.permission == 1}
                  onChange={(e)=>{if (permissionRoot.permission != e.target.checked) onPermissionChange(e.target.checked, permissionRoot.menu_key)}}
                  color="primary"
                />
              }
              label={permissionRoot.menu_label}
            />
          </ListItem>
        ); 
        let subMenus = [];
        for (let subID in permissionRoot.sub) {
          let permissionSub = permissionRoot.sub[subID];
          subMenus.push(
            <ListItem key={`sub_${subID}`} style={{marginLeft: "1.5rem"}} className={classes.listItem}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissionSub.permission == 1}
                    onChange={(e)=>{if (permissionSub.permission != e.target.value.checked) onPermissionChange(e.target.checked, permissionSub.menu_key, permissionSub.main_menu)}}
                    color="primary"
                  />
                }   
                label={permissionSub.menu_label}  
              />
            </ListItem>
          );
        }
        menuList.push(
          <Collapse key={rootID+"_sublist"} in={expanded === permissionRoot.menu_key} timeout="auto" unmountOnExit>
              <List component="div" className={classes.nested} disablePadding>
                  {subMenus}
              </List>
          </Collapse>
        );
      }
    }

    return (
      <Grid>
        <div>
          <CustomInput
            labelText="Role name"
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{shrink: true}}
            inputProps={{
              disabled: roleID != "",
              onChange: (e)=>{setRoleName(e.target.value)},
              value: roleName
            }}
          />
          <List disablePadding>{menuList}</List>
        </div>
      </Grid>
    );
  }
  function onPermissionChange(newVal, menuKey, mainMenu) {
    newVal = newVal ? 1 : 0; // use 1, 0 to represent visible or invisible on the backend database
    let newMenuList = JSON.parse(JSON.stringify(menu)); // if only primitive type in the menu object, use JSON parse to deep clone
    if (mainMenu) {
      // this is a sub menu, it is selected, root should be too
      newMenuList[mainMenu].sub[menuKey].permission = newVal;
      if (newVal === 1) newMenuList[mainMenu].permission = 1;
    } else {
      newMenuList[menuKey].permission = newVal;
      if (newMenuList[menuKey].sub) {
        if (newVal) setExpanded(menuKey);
        for (let subKey in newMenuList[menuKey].sub) {
          newMenuList[menuKey].sub[subKey].permission = newVal;
        }
      }
    }
    setMenu(newMenuList);
  }
  function onSubmit() {
    let err = "";
    if (!roleName) err += "Role name cannot be empty!";
    let regex = /[^a-zA-Z0-9-_ ]/g;
    if (regex.test(roleName)) err += "Role name only allows letters, numbers, hyphen, space and underscore.";

    if (err) {
      dataContext.alert(err);
      return;
    }

    let menu_ary = [];
    for (let root_key in menu) {
        let root_menu_item = menu[root_key];
        menu_ary.push({
            menu_key: root_menu_item.menu_key,
            menu_label: root_menu_item.menu_label,
            main_menu: "",
            permission: root_menu_item.permission
        });
        if (root_menu_item.sub) {
            for(let sub_key in root_menu_item.sub) {
                let sub_menu_item = root_menu_item.sub[sub_key];
                menu_ary.push(sub_menu_item);
            }
        }
    }

    let new_role = {
        role_id: roleID || '', role_name: roleName, menu: menu_ary
    };

    handleSubmit(new_role);
  }

  return (
    <BasicDialog
      fullWidth
      maxWidth='md'
      open={true}
      onClose={handleDialogClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleDialogClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}