import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Input, ListItemText, Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))


export default function EditWarehouseTask(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {action, task: defaultTask, handleClose, handleSubmit} = props;
  const [user_id, setUser] = useState(defaultTask.user_id || '');
  const [order_id, setOrderId] = useState(defaultTask.order_id || '');
  const [notes, setNotes] = useState(defaultTask.notes || '');

  function renderFormContent() {

    return (
      <Grid container className={classes.root} spacing={2}>
        <CustomInput
          labelText='User Id'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            value: user_id,
            disabled: !!defaultTask.user_id,
            placeholder: 'Enter the Id of the user',
            onChange: (e)=>{setUser(e.target.value)},
          }}
        /> 

        <CustomInput
          labelText='Order/PO #'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            value: order_id,
            placeholder: 'If working on an Order or PO, please enter the Order / PO number',
            onChange: (e)=>{setOrderId(e.target.value)},
          }}
        /> 
       
        <CustomInput
          labelText='Notes'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            value: notes,
            onChange: (e)=>{setNotes(e.target.value)},
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';
    if (!user_id) err += "User Id is required.\n";

    if (err) {
      dataContext.alert(err);
      return;
    }

    handleSubmit(action, {
      user_id, order_id, notes
    });
  }

  let title = action == 'start' ? "Start Task" : "Edit Task";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}