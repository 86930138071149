import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, LinearProgress, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
  BasicDialog
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import { Prompt } from 'react-router'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class Shipping extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    this.input_refs = {
      order_barcode: null,
      supply: null
    };
    this.temp_data = {
      close_confirm: false,
    };

    this.state = {
      loading: false,
      submitting_wait: false,
      order_detail: null,
      comments: null,
      boxes: [],
      saturday_delivery: false,
      signature_required: false,
      shipping_type: '', // display box table or single input form, may delete this
    };

    document.title = "Shipping";
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }
  handleUnload = (e) => {
    if (!this.hasUnsaved()) return '';
    var message = "\o/";
    message = "Changes you made may not be saved.";
    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  }
  hasUnsaved = () => {
      return this.temp_data.close_confirm;
  }

  resetPage = () => {
    this.temp_data = {
      close_confirm: false,
    };
    if (this.input_refs.order_barcode) {
      this.input_refs.order_barcode.value = '';
      this.input_refs.order_barcode.focus();
    }

    this.setState({
      submitting_wait: false,
      order_detail: null,
      comments: null,
      boxes: [],
      saturday_delivery: false,
      signature_required: false,
      shipping_type: '', // display box table or single input form, may delete this
    });
  }

  handleOrderBoxChange = ({index, field, new_val}) => {
    let newBoxes = Array.from(this.state.boxes);
    newBoxes[index][field] = new_val;
    this.setState({boxes: newBoxes});
  }

  orderSearch = () => {
    let keyword = utils.formatString(this.input_refs.order_barcode.value);
    if (!keyword) return;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/shipment/${keyword}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.orderSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  orderSearchSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      if (Array.isArray(data.Error)) this.context.alert(data.Error.join("\n"));
      else this.context.alert(data.Error);
      return;
    }
    let saturday_delivery =  parseInt(data.saturday_delivery) === 1 ? true : false;
    let signature_required = parseInt(data.signature_required) === 1 ? true : false;

    if (!data.order) { // order already shipped, print shipping label
      switch (true) {
        case typeof data == 'string':
          utils.printPage(data);
          break;
        case Array.isArray(data):
          data.forEach((elem)=>utils.printPage(elem));
          break;
        case typeof data == 'object':
          for (let key in data) {
            utils.printPage(data[key]);
          }
          break;
      }
    } else { // order not shipped, enter dimension and ship it
      let boxes = [];
      this.temp_data.close_confirm = true;
      for (let carton_id in data.box) {
        boxes.push({
          order_id: carton_id,
          weight: data.box[carton_id].weight || '',
          length: data.box[carton_id].length || '',
          width: data.box[carton_id].width || '',
          height: data.box[carton_id].height || '',
        });
      }
      this.setState({
        boxes,
        order_detail: data.order,
        comments: data.comment || null,
        saturday_delivery, signature_required
      });
    }
  }
  orderBarcodeScan = _.debounce(this.orderSearch, 150)
  deleteLabel = () => {
    const {order_detail} = this.state;

    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/shipment/${order_detail[0]['checkoutorder_id']}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.deleteLabelSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteLabelSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    let msg = data ? "Shipping label deleted" : "Delete shipping label error";
    this.context.snackDisplay(msg);
  }

  submitShipment = () => {
    const {boxes, saturday_delivery, signature_required, order_detail} = this.state;
    
     // Check customer id and local storage customer id
     if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }

    let err = '';
    for (let box of boxes) {
      let box_weight = parseFloat(box.weight);
      if (!box_weight || box_weight <= 0) err += `Weight for Barcode ${box.order_id} has error. It should be a positive number. \n`;
    }
    if (err) {
      this.context.alert(err);
      return;
    }

    let data = {
      type: 'label_shipment',
      order_id: [] ,
      shipment_detail: boxes, // cartons box
      address: {},
      order_detail: {
        saturday_delivery,
        signature_required,
      },
    };

    this.submitShipmentOnce(data);
  }
  submitShipmentAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/shipment`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitShipmentSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    // this.setState({loading: true});
    this.setState({submitting_wait: true});
  }
  resetSubmitOnce = () => {
    this.submitShipmentOnce = _.once(this.submitShipmentAJax);
    this.setState({submitting_wait: false});
  } // reset the submit picking once after the ajax call returns
  submitShipmentOnce = _.once(this.submitShipmentAJax)
  submitShipmentSuccess = (resp) => {
    this.setState({loading: false, submitting_wait: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    switch (true) {
      case typeof resp.data == 'string':
        utils.printPage(resp.data);
        break;
      case Array.isArray(resp.data):
        resp.data.forEach((elem)=>utils.printPage(elem));
        break;
      case typeof resp.data == 'object':
        for (let key in resp.data) {
          utils.printPage(resp.data[key]);
        }
        break;
    }
    this.context.snackDisplay("Submit shipment success.");
    this.resetPage();
  }

  renderOrderBoxTable = () => {
    const {classes} = this.props;
    const { boxes, order_detail } = this.state;
    if (boxes.length == 0) return null;

    let colSettings = [
      {
        key: 'order_id',
        label: 'Order Barcode',
      },
      {
        key: 'weight',
        label: 'Weight',
        render: (val, key, row, index) => {
          return (
            <TextField
              value={val || ''}
              onChange={(e)=>{
                this.handleOrderBoxChange({field: 'weight', new_val: e.target.value, index,});
              }}
            />
          );
        }
      },
      {
        key: 'length',
        label: 'Length',
        render: (val, key, row, index) => {
          return (
            <TextField
              value={val || ''}
              onChange={(e)=>{
                this.handleOrderBoxChange({field: 'length', new_val: e.target.value, index,});
              }}
            />
          );
        }
      },
      {
        key: 'width',
        label: 'Width',
        render: (val, key, row, index) => {
          return (
            <TextField
              value={val || ''}
              onChange={(e)=>{
                this.handleOrderBoxChange({field: 'width', new_val: e.target.value, index,});
              }}
            />
          );
        }
      },
      {
        key: 'height',
        label: 'Height',
        render: (val, key, row, index) => {
          return (
            <TextField
              value={val || ''}
              onChange={(e)=>{
                this.handleOrderBoxChange({field: 'height', new_val: e.target.value, index,});
              }}
            />
          );
        }
      }
    ];

    return (
      <GridItem xs={12}>
        <CardContainer>
        <div className={this.props.classes.root}>
          <div style={{marginBottom: '1rem'}} className={classes.title} >
            <Typography variant="h6">
              Order Boxes
            </Typography>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.saturday_delivery}
                    onChange={(e)=>{this.setState({saturday_delivery: e.target.checked});}}
                    color='primary'
                    value='saturday_delivery'
                  />
                }
                label='Saturday Delivery'
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.signature_required}
                    onChange={(e)=>{this.setState({signature_required: e.target.checked});}}
                    color='primary'
                    value='signature_required'
                  />
                }
                label='Signature Required'
              />
              <br/>

              <Button className={classes.button} style={{marginRight: "1rem"}} onClick={()=>{
                if (order_detail[0].method === 'BXZ.PKP') this.context.confirm("This is a BXZ.PKP order, please ask admin before shipping it. Do you still want to ship?", this.submitShipment.bind(this));
                else this.submitShipment();
              }}>Submit Shipment</Button>
              <Button className={classes.button} color="secondary" onClick={()=>{
                this.context.confirm("Are you sure to delete the shipping label?", this.deleteLabel.bind(this));
              }}>Delete label</Button>
            </div>
          </div>

          <NewDataTable
            rows={boxes}
            columns={colSettings}
            noPagination
          />
        </div>
      </CardContainer>
      </GridItem>
    );
  }
  renderCommentTable = () => {
    const { boxes, comments } = this.state;
    if (boxes.length == 0 || !comments || comments.length == 0) return null;

    let colSettings = [
      {
        key: 'username',
        label: 'Username',
      },
      {
        key: 'fullname',
        label: 'Fullname',
      },
      {
        key: 'comment',
        label: 'Comment',
        render: (val, key, row) => {
          let content = val ? val : '';
          if (content.includes("https://")) {
            let btn_text = "See PDF";
            if (content.includes(".pdf")) 
            {
              btn_text = "See PDF";
            } else 
            {
              btn_text = "See Image";
            }
            let file_name = content.split("import/");
            if (file_name.length > 1)
            {
              btn_text = utils.removeFilePrefix(file_name[1]);
            } 

            return (<Button onClick={()=>{
                let newTab = window.open(content);
                if (!newTab) alert("Unable to open new tab, please change browser settings to allow pop-up.");
            }}>{btn_text}</Button>);
          }
          return content;
        }
      },
      {
        key: 'date',
        label: 'Date/Time',
        contentNoWrap: true,
        render: utils.localizeDate,
      }
    ];

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div className={this.props.classes.root}>
            <NewDataTable
              rows={comments}
              columns={colSettings}
              noPagination
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <Prompt
          when={this.hasUnsaved()}
          message="You have unsaved work, are you sure to leave?"
        />

        {this.state.submitting_wait && 
          <BasicDialog
            open={true}
            onClose={()=>{}}
            maxWidth="md"
            fullWidth
            header={"Submitting, please wait..."}
            content={<div style={{fontWeight: 'bold', fontSize: '1.25rem', zIndex: '1000'}}>
              <p>Please wait for submiation finished do not close or refresh window.</p>
              <p>(Espere a que finalice el envío, no cierre ni actualice la ventana.)</p>
              <LinearProgress/>
              </div>}
            actions={[]}
          />
        }

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <CustomInput
              labelText='Order Barcode'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onKeyUp: this.orderBarcodeScan,
                defaultValue: '',
                inputRef: elem => this.input_refs.order_barcode = elem
              }}
            />
          </CardContainer>
        </GridItem>

        {this.renderCommentTable()}

        {this.renderOrderBoxTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(Shipping);
