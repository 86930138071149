import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Typography, FormControlLabel, Switch, withStyles, IconButton, MenuItem, FormControl, Select } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
} from 'components';
import DataContext from 'context/Data'
import axios from 'axios';
import utils from 'utils/utils';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class BillingAgreementReadOnly extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    agreements: PropTypes.object.isRequired,
    onAgreementsChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  constructor(props) {
    super(props);

    let required_agreements = {};
    for (let option of props.options) {
        if (option.required && parseInt(option.required) === 1) required_agreements[option.type] = true;
    }
    this.required_agreements = required_agreements;

    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <div>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          View Billing Agreements
        </Typography>

        {this.renderAgreementTable()}
      </div>
    );
  }

  updateAgreement = (newVal, field, type) => {
    let newAgreement = this.props.agreements[type];
    if (newAgreement) {
        newAgreement[field] = newVal;
    } else {
        console.log("update agreement failed, can't find an agreement with this type. May need to change the code to use agreement_id as the key instead of type");
        return;
    }

    let err = '';
    if (!parseFloat(newAgreement.cost) || parseFloat(newAgreement.cost) <= 0) {
        err += 'Cost should be a positive number.\n';
    }
    newAgreement.cost = parseFloat(newAgreement.cost);
    let qty = parseInt(newAgreement.quantity);
    if (!qty || qty <= 0) {
        err += 'Quantity should be a positive integer.\n';
    }

    if (err) {
        this.context.alert(err);
        return;
    }

    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/agreement`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        agreement_id: newAgreement.agreement_id,
        active: newAgreement.active,
        type: newAgreement.type,
        category: newAgreement.category,
        notes: newAgreement.notes,
        quickbooks_desc: newAgreement.quickbooks_desc,
        quantity: qty,
        cost: parseFloat(newAgreement.cost),
        unit: newAgreement.unit,
      },
    });

    this.setState({loading: true});
    req.then(this.updateAgreementSuccess.bind(this, newAgreement)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateAgreementSuccess = (newAgreement, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    if (!resp.data) {
      this.context.alert("Update agreement error");
      return;
    }
    let new_agreements = Object.assign({}, this.props.agreements);
    new_agreements[newAgreement.type] = newAgreement;

    this.props.onAgreementsChange(new_agreements);
  }

  renderAgreementTable = () => {
    const { classes, agreements } = this.props;
    let rows = [];
    let required = [];
    let optional = [];
    for (let type in agreements) {
      if (this.required_agreements[type]) required.push(agreements[type]);
      else optional.push(agreements[type]);
    }
    rows = required.concat(optional);
    
    return (
      <CardContainer>
        <NewDataTable
          rows={rows}
          noPagination
          rowHeight='auto'
          maxHeight={500}
          columns={[
            {
              key: 'type',
              label: 'Type',
              contentNoWrap: true
          },
          {
            key: 'category',
            label: 'Category',
          },
          {
              key: 'cost',
              label: 'Cost',
          },
          {
              key: 'unit',
              label: 'Unit',
          },
          {
              key: 'quantity',
              label: 'Quantity',
          },
          {
            key: 'notes',
            label: 'Notes',
          },
          {
            key: 'quickbooks_desc',
            label: 'QuickBooks Description',
          },
          // {
          //   key: 'active',
          //   label: 'Active',
          //   render: (val)=>{ return (val == 1) ? 'Active' : 'Inactive'} 
          // },
          {
            key: 'active',
            label: 'Active',
            render: (val, key, row, index) => {
              return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <FormControl required fullWidth className={classes.selectInput}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={(parseInt(val) == 1) ? true : false}
                            color="primary"
                            onChange={(e)=>{
                              let new_val = e.target.checked;
                              new_val = new_val ? 1 : 0;
                              this.updateAgreement(new_val, 'active', row.type)
                            }}
                          />
                        }
                        label={(parseInt(val) == 1) ? 'Active' : 'Inactive'}
                      />
                  </FormControl>
                </div>
              );
              }
            }
          ]}
        />
    </CardContainer>
    );
  }
}
export default withStyles(styles)(BillingAgreementReadOnly);
