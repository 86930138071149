import Login from "../views/Login/Login";
import ResetPassword from '../views/Login/ResetPassword';
import EmployeeClockIn from 'views/Productivity/EmployeeClockIn'

const anonyRoutes = [
  {
    key: 'login',
    path: "/login",
    component: Login
  },
  // {
  //   key: 'clockin',
  //   path: "/clockin",
  //   component: EmployeeClockIn
  // },
  {
    key: 'resetPassword',
    path: "/resetPassword",
    component: ResetPassword
  },
  { key: 'redirect', redirect: true, path: "*", to: "/login" },
];

export default anonyRoutes;
