import FontRalewayRegular from 'assets/fonts/Raleway-Regular.ttf'
import FontRalewayBold from 'assets/fonts/Raleway-Bold.ttf'
import ChronicleDisplayRoman from 'assets/fonts/Chronicle-Display-Roman.otf'
import ProximaNova from 'assets/fonts/Proxima-Nova-ont.otf'
import RobotoBold from 'assets/fonts/roboto/Roboto-Bold.ttf'
import RobotoRegular from 'assets/fonts/roboto/Roboto-Regular.ttf'
import NeutonBold from 'assets/fonts/neuton/Neuton-Bold.ttf'
import NeutonRegular from 'assets/fonts/neuton/Neuton-Regular.ttf'

const raleway = {
    fontFamily: 'Raleway-Regular',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
      local('Raleway'),
      local('Raleway-Regular'),
      url(${FontRalewayRegular}) format('ttf')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
  };
const ralewayBold = {
fontFamily: 'Raleway-Bold',
fontStyle: 'normal',
fontDisplay: 'swap',
fontWeight: 400,
src: `
    local('Raleway Bold'),
    local('Raleway-Bold'),
    url(${FontRalewayBold}) format('ttf')
`,
unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const neuton = {
fontFamily: 'Neuton-Regular',
fontStyle: 'normal',
fontDisplay: 'swap',
fontWeight: 400,
src: `
    local('Neuton'),
    local('NeutonRegular'),
    url(${NeutonRegular}) format('ttf')
`,
unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export {
    raleway,
    ralewayBold,
    neuton
};