import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Divider, Typography, Grid, LinearProgress, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
  BasicDialog
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import CreatePalletDialog from 'views/Dialogs/OBSortingPallet/CreateNew'

const styles = theme => ({
  root: {
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	justifyContent: 'center'
  },
  packageContainer: {
	// border
	width: '100%',
	textAlign: 'center',
	border: '1px solid black'
  },
  packageNumber: {
	fontSize: '10rem',
	color: 'white'
  },
  packageSortCode: {
	fontSize: '6rem',
	// color: 'white'
  },
  formControl: {
	margin: theme.spacing(1),
	minWidth: 120,
  },
  selectEmpty: {
	marginTop: theme.spacing(2),
  },
  table: {
	minWidth: 700,
  },
  customInput: {
	margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
	overflowX: 'auto',
  },
  title: {
	flex: '0 0 auto',
  },
  button: {
	marginTop: theme.spacing(1)
  },
  customInputContainer: {
	margin: '0 !important',
	padding: 0
  },
  customInputNoMargin: {
	margin: '0 !important',
	padding: 0
  },
  menuItemText: {
	whiteSpace: 'break-spaces',
	wordWrap: 'break-word',
	// ['@media (min-width:780px)']:
	[theme.breakpoints.down('md')]: {
	  maxWidth: "400px",
	},
	[theme.breakpoints.between('md', 'lg')]: {
	  maxWidth: "600px",
	},
	[theme.breakpoints.up('lg')]: {
	  // maxWidth: "700px",
	},
  },
});

class SortingPackage extends Component {
  static contextType = DataContext;

  static propTypes = {
	classes: PropTypes.object.isRequired,
  };

  constructor(props) {
	super(props);
	
	this.input_refs = {
		tracking_number: null,
		pallet_id: null,
	};
	this.temp_data = {
		schedule_focus_input: null,
	};

	this.state = {
	  loading: false,
	  wms_package: null,
	  sorting_type: 'sort'
	};

	document.title = "Sorting Package";
  }

  // componentDidMount() {
  //   this.temp_data.schedule_focus_input = setInterval(()=>{
  //       if (this.input_refs.tracking_number) this.input_refs.tracking_number.focus();
  //   }, 1500);
  // }
  // componentWillUnmount() {
  //   clearInterval(this.temp_data.schedule_focus_input);
  // }

  createNewSortingPallet = (quantity) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/newsortpallet`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        pallet: quantity,
        client_name: localStorage.getItem('customer_name')
      },
    });
    this.setState({loading: true});
    req.then(this.createNewSortingPalletSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  createNewSortingPalletSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }

    switch (true) {
      case typeof data == 'string':
        utils.printPage(data);
        break;
      case Array.isArray(data):
        data.forEach((elem)=>utils.printPage(elem));
        break;
      case typeof data == 'object':
        for (let key in data) {
          utils.printPage(data[key]);
        }
        break;
    }

    this.context.snackDisplay("Create pallet success.");
    this.setState({ dialog: '' });
  }

  packageSearch = () => {
	let keyword = utils.formatString(this.input_refs.tracking_number.value);
	if (!keyword) return;

	let req = axios({
	  method: 'post',
	  url: `${utils.getBaseUrl('customer')}/sortpackage`,
	  headers: {
		token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	  data: {
		tracking: keyword
	  }
	});
	this.setState({loading: true});
	req.then(this.packageSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  packageSearchSuccess = (resp) => {
	this.setState({loading: false});
	let data = resp.data;
	if (data.Error) {
	  if (Array.isArray(data.Error)) this.context.alert(data.Error.join("\n"));
	  else this.context.alert(data.Error);
	  return;
	}

	this.input_refs.tracking_number.value = '';
	this.setState({wms_package: resp.data});
  }
  trackingNumberScan = _.debounce(this.packageSearch, 150)

  palletIdSearch = () => {
	let keyword = utils.formatString(this.input_refs.pallet_id.value);
	if (!this.state.wms_package) return;
	if (!keyword) return;

	let req = axios({
	  method: 'post',
	  url: `${utils.getBaseUrl('customer')}/sortpackagepallet`,
	  headers: {
		token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	  data: {
		pallet_id: keyword,
		order_id: this.state.wms_package.order_id,
	  }
	});
	this.setState({loading: true});
	req.then(this.palletIdSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  palletIdSearchSuccess = (resp) => {
	this.setState({loading: false});
	let data = resp.data;
	if (data.Error) {
	  if (Array.isArray(data.Error)) this.context.alert(data.Error.join("\n"));
	  else this.context.alert(data.Error);
	  return;
	}

	// window.location.reload();
	this.setState({wms_package: null});
  }
  palletIdScan = _.debounce(this.palletIdSearch, 150)

  renderPackage = () => {
	const {classes} = this.props;
	const { wms_package, sorting_type } = this.state;
	if (!wms_package) return null;

	// only focus pallet id input if sorting type is manifesting
	if (sorting_type == 'sort')
	{
		this.temp_data.schedule_focus_input = setTimeout(()=>{
			if (this.input_refs.pallet_id) this.input_refs.pallet_id.focus();
		}, 0);	
	}
	
	return (
	  <GridItem xs={12}>
			<CardContainer>
				
				{
					wms_package.pallet_id &&
					<div>
						<CustomInput
							labelText='Pallet Id'
							formControlProps={{
								required: true,
								className: classes.customInput,
							}}
							labelProps={{
								shrink: true,
							}}
							inputProps={{
								onKeyUp: this.palletIdScan,
								defaultValue: '',
								onPaste: (e)=>{
									if (!utils.checkAllowCopy()) e.preventDefault();
								},
								inputRef: elem => this.input_refs.pallet_id = elem
							}}
						/>
					</div>
				}

				<br/>

				<div className={this.props.classes.root}>
					<div className={classes.packageContainer}>
						<div style={{backgroundColor: wms_package.color}} className={classes.packageNumber}>
							{wms_package.group}
						</div>

						<div className={classes.packageSortCode}>{wms_package.sort_group}</div>
						<div className={classes.packageSortCode}>{wms_package.carrier}</div>
					</div>
				</div>
			</CardContainer>
	  </GridItem>
	);
  }
  renderDialog = () => {
    const { dialog } = this.state;

    switch (dialog) {
      case 'create_new':
        return <CreatePalletDialog
          handleSubmit={this.createNewSortingPallet}
          handleClose={()=>{this.setState({dialog: ''})}}
        />;
    }
  }

  render() {
	const { classes } = this.props;

    this.temp_data.schedule_focus_input = setTimeout(()=>{
        if (this.input_refs.tracking_number) this.input_refs.tracking_number.focus();
    }, 0);

	return (
	  <Grid container spacing={2}>
		{this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

		{this.renderDialog()}

		<GridItem xs={12} sm={6}>
		  <CardContainer>
			<div className={classes.headerToolBar} style={{marginBottom: '1rem'}}>
				<Button color="bxzDefault" variant="contained" onClick={()=>this.setState({dialog: 'create_new'})}>Create Pallet</Button>
			</div>

			<Divider style={{marginBottom: '1rem'}}/>

			<FormControl fullWidth required className={classes.customInput}>
				<InputLabel shrink>Sorting Type</InputLabel>
				<Select
					value={this.state.sorting_type}
					onChange={(e)=>{this.setState({sorting_type: e.target.value})}}
				>
					<MenuItem value='sort'>Sorting Pacakge</MenuItem>
					<MenuItem value='manifest'>Manifesting</MenuItem>
				</Select>
			</FormControl>

			<CustomInput
			  labelText='Package Tracking #'
			  formControlProps={{
				fullWidth: true,
				required: true,
				className: classes.customInput,
			  }}
			  labelProps={{
				shrink: true,
			  }}
			  inputProps={{
				onKeyUp: this.trackingNumberScan,
				defaultValue: '',
				inputRef: elem => this.input_refs.tracking_number = elem
			  }}
			/>
		  </CardContainer>
		</GridItem>

		{this.renderPackage()}
	  </Grid>
	);
  }
}
export default withStyles(styles)(SortingPackage);
