import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, ButtonGroup, Grid, InputLabel, Select, FormControl, MenuItem, IconButton, withStyles } from "@material-ui/core";
import {
  Error,
  Edit,
} from '@material-ui/icons';
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import EditInventory from 'views/Dialogs/EditInventory'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class ManageInventory extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.keys = [];
    this.adjustment_key = '';

    this.searchForInput = null;

    this.dataType = '';

    this.state = {
      loading: false,
      tableData: null,
      searchOptions: [],
      active_inventory: '',
      edit_inventory_id: '',
      type: 'inventory',
      selected_inventory: {},
      dialog: '',
      keyword: '',
    };

    document.title = "Inventory";
  }

  renderDialog = () => {
    const {dialog, tableData, edit_inventory_id} = this.state;
    if (!tableData || !tableData[edit_inventory_id]) return;
    let default_inventory = tableData[edit_inventory_id];
    default_inventory.exp_date_prev = default_inventory.expiration_date;
    switch (dialog) {
      case 'editInventory':
        return (
          <EditInventory
            inventory={default_inventory}
            handleSubmit={this.updateExpirationAndLot}
            handleClose={()=>{this.setState({dialog: '', edit_inventory_id: ''});}}
          /> 
        )
      default:
          return null;
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, type, keyword, searchOptions } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    let search_for_field = (
      <CustomInput
        labelText='Search For'
        labelProps={{shrink: true}}
        formControlProps={{
          fullWidth: true,
          required: true,
          style: {marginBottom: 0},
          className: this.props.classes.customInput
        }}
        inputProps={{
          defaultValue: "",
          inputRef: (el)=>{this.searchForInput = el},
          placeholder: 'Use Linebreak to separate multiple keywords',
          multiline: true,
          rows: 3
        }}
      />
    );
    if (type == 'item_name') {
      search_for_field = (
        <CustomInput
          labelText='Search For'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            style: {marginBottom: 0},
            className: this.props.classes.customInput
          }}
          inputProps={{
            defaultValue: "",
            inputRef: (el)=>{this.searchForInput = el},
          }}
        />
      );
    }

    return (
      <Grid container spacing={2}>
        {loadingBar}
        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.searchInventory}>
              <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="search_type">Search Type</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>{this.setState({type: e.target.value})}}
                >
                  {searchOptions.map((elem)=><MenuItem key={elem.value} value={elem.value}>{elem.key}</MenuItem>)}
                </Select>
              </FormControl>

              {search_for_field}

              <Button type='submit' className={classes.button}>Search</Button>
            </form>
          </CardContainer>
        </GridItem>

        <GridItem xs={12}/>
        {this.renderDialog()}
        {this.renderTable()}
      </Grid>
    );
  }

  componentWillMount() {
    this.loadDefaultInventory();
  }

  // API call
  // download adjustment history
  downloadReport = (e) => {
    e.preventDefault();
    let keyword = this.adjustment_key;
    keyword = utils.formatString(keyword, {multiline: true}).split(',');
    
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/exportreport`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'adjustment_history',
        keyword: keyword,
        customer_id: localStorage.getItem('customer_id'), 
        warehouse_id: localStorage.getItem('warehouse_id'),
        date_from: 'empty',
        date_to: 'empty'
      },
    });

    this.setState({loading: true});
    req.then(this.exportAdjustmentHistoryReportSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  exportAdjustmentHistoryReportSuccess = (resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;
    // this.context.snackDisplay("Export report success.");
    // window.open(result.url, '_blank');
    this.downloadFile(result.url);
  }
  downloadFile = async (downloadUrl) => {
    let req = axios({
      method: 'get',
      url: downloadUrl,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      }
    });

    this.setState({loading: true});
    req.then(this.downloadFileSuccess.bind(this, downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1))).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  downloadFileSuccess = (file_name, response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name); // Name of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object and remove the link
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.setState({ loading: false });
  }

  // load default inventory
  loadDefaultInventory = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/inventory`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadDefaultInventorySuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadDefaultInventorySuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data.snapshot;
    let searchOptions = resp.data.search_type;
    let defaultType = this.state.type;
    if (searchOptions.length > 0) defaultType = searchOptions[0].value;
    if (data.Error) {
     this.context.alert(data.Error);
      return;
    }
    if (!data || data.length === 0) {
     this.context.alert("No data found");
      return;
    }

    this.dataType = 'item_name';
    this.setState({tableData: data, searchOptions: searchOptions, type: defaultType});
  }
  // search products
  searchInventory = (e) => {
    e.preventDefault();
    const { type }= this.state;
    let keyword = this.searchForInput ? utils.formatString(this.searchForInput.value, {multiline: true}) : '';

    if (!keyword) {
      this.context.alert("Search keyword is invalid");
      return;
    }
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/inventory`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type,
        keyword: keyword.split(',')
      },
    });

    this.dataType = type;

    this.keys = [];
    this.adjustment_key = '';
    req.then(this.searchInventorySuccess.bind(this, utils.formatString(keyword, {multiline: true}))).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    this.setState({loading: true, tableData: null, active_inventory: ''})
  }
  searchInventorySuccess = (keyword, resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
     this.context.alert(resp.data.Error);
      return;
    }

    let tableData = null;
    switch (resp.data.type) {
      case 'snapshot':
      case 'location':
      case "lotcode":
        tableData = resp.data.inventory;
        this.keys = resp.data.inventory_id_list;
        break;
      case 'order':
      case 'order_availability':
        tableData = resp.data.order_list;
        break;
      default:
        tableData = resp.data;
    }
    if (resp.data && resp.data.length === 0) {
     this.context.alert("No data found");
      return;
    }

    this.adjustment_key = keyword;
   
    this.setState({tableData: tableData});
  }
  updateExpirationAndLot = (newInventory) => {
    let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/explot`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
            inventory_id: newInventory.inventory_id,
            expiration_date: newInventory.expiration_date,
            lot: newInventory.lot,
        },
    });

    req.then(this.updateExpirationDateSuccess.bind(this, newInventory)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    this.setState({loading: true})
  }
  updateExpirationDateSuccess = (newInventory, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Update expiration and lot success.");
    this.state.tableData[this.state.edit_inventory_id] = newInventory;
    this.setState({dialog: '', edit_inventory_id: ''});
  }
  exportSelectedRows = () => {
    const { selected_inventory } = this.state;
    let inventory_list = [];
    for (let inventory_id in selected_inventory) {
      inventory_list.push(selected_inventory[inventory_id]);
    }
    let headers = [
      {
        key: 'location',
        label: 'Location',
      },
      {
        key: 'sku',
        label: 'SKU',
      },
      {
        key: 'upc',
        label: 'UPC',
      },
      {
        key: 'locked',
        label: 'Locked',
        render: (val) => val == 1,
      },
      {
        key: 'description',
        label: 'Description',
      },
      {
        key: 'quantity',
        label: 'Quantity In Stock',
      },
      {
        key: 'allocate',
        label: 'Allocated',
      },
      {
        key: 'shortname',
        label: 'Customer',
      },
    ];

    utils.exportArrayToCsv(inventory_list, headers, 'exported_inventory');
  }
  updateInventoryLocked = (new_locked) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/updateInventoryLocked`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        locked: new_locked,
        inventory_id_list: Array.from(Object.keys(this.state.selected_inventory))
      },
    });

    this.setState({loading: true});
    req.then(this.updateInventoryLockedSuccess.bind(this, new_locked)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateInventoryLockedSuccess = (new_locked, resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
     this.context.alert(resp.data.Error);
      return;
    }

    let new_inventory = Object.assign({}, this.state.tableData);
    for (let inventory_id in this.state.selected_inventory) {
      new_inventory[inventory_id]['locked'] = new_locked;
    }
    this.setState({tableData: new_inventory});
  }

  // render search result table
  renderTable = () => {
    if (!this.state.tableData) return null;
    switch (this.dataType) {
      // client
      case "inventory":
        return this.renderInventory();
      case "item_name":
        return this.renderInventoryByName();
      case "adjustment_history":
        return this.renderAdjustment();
      // WMS only
      case "snapshot":
        return this.renderSnapshot();
      case "order":
          return this.renderOrder();
      case "order_availability":
        return this.renderOrderAvailability();
      case "location":
      case "lotcode":
          return this.renderLocation();
      default:
          return null;
    }
  }
  // Client
  renderInventory = () => {
    const { classes } = this.props;
    const {  tableData } = this.state;

    let item = Array.isArray(tableData.item_detail) ? tableData.item_detail[0] : tableData.item_detail;

    let in_stock = parseInt(tableData.in_stock);
    let allocated = parseInt(tableData.allocation);
    let unit_shipped = parseInt(tableData.unit_shipped) || 0;
    let fast_move = item.fast_move || '';
    let days_onhand = parseInt(tableData.days_onhand) || 0;
    let open_order = parseInt(tableData.open_order);
    let onhold = parseInt(tableData.onhold);
    let orders = tableData.order_list;

    return (
      <React.Fragment>
        <GridItem xs={12} sm={12} md={6} key="detail">
          <CardContainer>
            <div className={classes.title}>
              <Typography variant="h6">
                Item Detail
              </Typography>
            </div>

            <div className={classes.tableWrapper} style={{width: "100%"}}>
              <KeyValueTable
                dataObj={item}
                rowHeight='auto'
                propertyList={[
                  { key: 'sku',label: 'SKU' },
                  { key: 'upc',label: 'UPC' },
                  { key: 'style',label: 'Style' },
                  { key: 'size',label: 'Size' },
                  { key: 'color',label: 'Color' },
                  { key: 'brand',label: 'Vendor' },
                  { key: 'description',label: 'Description' },
                  { key: 'fast_move',label: 'FastMove', render: () => fast_move },
                  { key: 'unit_shipped',label: 'UnitShippedPast3Months', render: () => unit_shipped },
                  { key: 'days_onhand',label: 'DaysofInventoryOnHand', render: () => days_onhand },
                  { key: 'in_stock',label: 'Quantity In Stock', render: () => in_stock },
                  { key: 'allocated',label: 'Allocated', render: () => allocated },
                  { key: 'open_order',label: 'Open Order', render: () => open_order },
                  { key: 'onhold',label: 'Backorder', render: () => onhold },
                  { key: 'available',label: 'Available', render: () => in_stock - allocated - onhold - open_order },
                ]}
              />
            </div>
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={6} key="allocation">
          <CardContainer>
            <div className={classes.title}>
              <Typography variant="h6">
                {"Allocation"}
              </Typography>
            </div>

            <div className={classes.tableWrapper} style={{width: "100%"}}>
              <NewDataTable
                rows={orders}
                noPagination
                columns={[
                  { key: 'order_key',label: 'Order Id' },
                  { key: 'quantity',label: 'Quantity' },
                  { key: 'status_id',label: 'Status' },
                ]}
              />
            </div>
          </CardContainer>
        </GridItem>
      </React.Fragment>
    );
  }
  renderAdjustment = () => {
    let colSettings = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true
      }, {
        key: 'item_name',
        label: 'ItemName',
      }, {
        key: 'adjustment',
        label: 'Adjustment',
      },{
        key: 'tracking',
        label: 'TrackingNumber',
      },{
        key: 'order_id',
        label: 'OrderId',
      },{
        key: 'po',
        label: 'PO',
        contentNoWrap: true
      },{
        key: 'location',
        label: 'Location',
        contentNoWrap: true,
      },{
        key: 'pallet',
        label: 'Pallet',
      },{
        key: 'type',
        label: 'Type',
      },{
        key: 'created_dt',
        label: 'AdjustmentDate',
        contentNoWrap: true,
        render: utils.localizeDate,
      }
    ];
    let product_sku = this.state.tableData[0].sku;

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <div>
            <Button onClick={this.downloadReport}>Download Full Report</Button>
            <NewDataTable
              rows={this.state.tableData}
              noPagination
              // maxHeight={500}
              id='table-export'
              columns={colSettings}
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderInventoryByName = () => {
    const {classes} = this.props;
    const { tableData } = this.state;

    if (!tableData || tableData.length === 0) return null;

    let colSettings = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
      }, {
        key: 'upc',
        label: 'UPC',
        contentNoWrap: true,
      }, {
        key: 'style',
        label: 'Style',
      }, {
        key: 'size',
        label: 'Size',
      }, {
        key: 'color',
        label: 'Color',
      }, {
        key: 'description',
        label: 'Name',
      },
      {
        key: 'quantity',
        label: 'Available',
      }
    ];
    if (utils.getRoleType() == 'warehouse') {
      colSettings = [
        {
          key: 'location',
          label: 'Location',
          contentNoWrap: true,
        }, {
          key: 'sku',
          label: 'SKU',
          contentNoWrap: true,
        }, {
          key: 'upc',
          label: 'UPC',
          contentNoWrap: true,
        }, {
          key: 'style',
          label: 'Style',
        }, {
          key: 'size',
          label: 'Size',
        }, {
          key: 'color',
          label: 'Color',
        }, {
          key: 'description',
          label: 'Name',
        },
        {
          key: 'in_stock',
          label: 'Quantity In Stock',
        }
      ];
    }
   

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <div className={classes.title} style={{height: '3rem'}}>
            <Typography variant="h6">
              Inventory
            </Typography>
          </div>
          <NewDataTable
            rows={tableData}
            noPagination
            // tableTitle={"Inventory"}
            columns={colSettings}
          />
        </CardContainer>
      </GridItem>
    );
  }
  // WMS
  renderSnapshot = () => {
    const { classes } = this.props;
    const { active_inventory, tableData } = this.state;

    let table = null;
    if (!tableData) return table;

    let title = '';
    let btnBack = null;
    if (active_inventory && tableData[active_inventory]) {
        let colSettings = [
            {
                key: 'order_key',
                label: 'Order Key',
                render: utils.tableCellOnDisplay
            },
            {
                key: 'quantity',
                label: 'Quantity',
                render: utils.tableCellOnDisplay
            },
            {
                key: 'status',
                label: 'Status',
                render: (val, key, row)=>{
                    return (val ? val : "Allocated");
                }
            }
        ];
        title = `${tableData[active_inventory]['sku']}: ${tableData[active_inventory]['location']}`;
        btnBack = (
            <Button onClick={()=>{this.setState({active_inventory: ''});}}>Back to List</Button>
        );

        table = (
            <NewDataTable
              noPagination
              rows={tableData[active_inventory]['allocation']}
              columns={colSettings}
            />
        );
    } else {
        let rows = [];
        title = '';
        for (let id of this.keys) {
            rows.push(tableData[id]);
        }

        let colSettings = [
            {
                key: 'location',
                label: 'Location',
                render: (val, key, row, index)=>{
                    return (<Button color='default' onClick={()=>{this.setState({edit_inventory_id: row.inventory_id, dialog: 'editInventory'})}}>{val}</Button>);
                }
            },
            {
                key: 'sku',
                label: 'SKU',
                contentNoWrap: true,
            },
            {
                key: 'upc',
                label: 'UPC',
                contentNoWrap: true,
            },
            {
                key: 'description',
                label: 'Description',
                contentNoWrap: true,
            },
            {
                key: 'expiration_date',
                label: 'Expiration Date',
            },
            {
              key: 'lot',
              label: 'Lot',
            },
            {
                key: 'quantity',
                label: 'Quantity In Stock',
            },
            {
                key: 'allocate',
                label: 'Allocated',
                render: (val, key, row, index)=>{
                    // render a button
                    return (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span style={{marginRight: '.5rem'}}>{val}</span>
                            {val ?  <Error onClick={()=>{this.setState({active_inventory: row.inventory_id})}} title='Click to view allocation detail' style={{cursor:'pointer'}}/> : null}
                        </div>
                    );
                }
            },
            {
                key: 'available',
                label: 'Available',
                render: (val, key, row)=>{
                    return parseInt(row.quantity) - parseInt(row.allocate);
                }
            }
        ];

        table = <Fragment>
            <div>
              <Button onClick={()=>{utils.exportTableToCsv('snapshot');}}>Export</Button>
            </div>
            
           <NewDataTable
              rows={rows}
              rowHeight="auto"
              noPagination
              id="snapshot"
              columns={colSettings}
            />
        </Fragment>;
    }

    return (
      <GridItem xs={12}>
        <CardContainer>
        {btnBack}
          <div className={this.props.classes.root}>
           
            <div className={classes.title} style={{padding: "1rem 0"}}>
              <Typography variant="h6" style={{marginBottom: '.5rem'}}>
                {title}
              </Typography>
            </div>
            {table}
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderOrder = () => {
      const { classes } = this.props;
      const {  tableData } = this.state;
      // fit red row
      // export
      if (!tableData || tableData.length === 0) {
          return null;
      }

      let colSettings = [
          {
              key: 'sku',
              label: 'SKU',
              render: utils.tableCellOnDisplay,
          }, {
              key: 'upc',
              label: 'UPC',
              render: utils.tableCellOnDisplay,
          }, {
              key: 'quantity',
              label: 'Quantity',
              render: utils.tableCellOnDisplay,
          }, {
              key: 'level1',
              label: 'Level1',
              render: (val)=>{
                  return (<span style={{whiteSpace: 'nowrap'}}>{val ? val : ''}</span>);
              },
          }, {
              key: 'level2',
              label: 'Level2',
              render: (val)=>{
                  return (<span style={{whiteSpace: 'nowrap'}}>{val ? val : ''}</span>);
              },
          }
      ];

      let rowSettings = {
          rowProps: {
              hover: false
          },
          classNameOnDisplay: (data, displayProps) =>{
              if (!displayProps) displayProps = {
                  className: ""
              };

              let classes = displayProps.className ? displayProps.className : "";
              classes = classes.split(" ");

              if (data.fit === false) {
                  classes.push("light-red");
              }
              displayProps.className = classes.join(" ");
              return displayProps;
          }
      };

      return (
          <GridItem xs={12} sm={12} md={12}>
              <CardContainer>
                <div className={this.props.classes.root}>
                  <GridItem xs={12} sm={12} md={12}>
                      {/*<div className={classes.title} style={{padding: "1rem 0"}}>*/}
                          {/*<Typography variant="h6" style={{marginBottom: '.5rem'}}>*/}
                              {/*Order Table*/}
                          {/*</Typography>*/}
                      {/*</div>*/}

                      <Button onClick={()=>{utils.exportTableToCsv("table-export", 'Order_Inventory')}}>Export</Button>

                    <NewDataTable
                        rows={tableData}
                        noPagination
                        columns={colSettings}
                        rowSettings={rowSettings}
                        id="table-export"
                    />
                  </GridItem>
                </div>
              </CardContainer>
          </GridItem>
      );
  }
  renderOrderAvailability= () => {
      const { classes } = this.props;
      const {  tableData } = this.state;
      // fit red row
      // export
      if (!tableData || tableData.length === 0) {
          return null;
      }

      let colSettings = [
          {
              key: 'sku',
              label: 'SKU',
              render: utils.tableCellOnDisplay,
          }, {
              key: 'upc',
              label: 'UPC',
              render: utils.tableCellOnDisplay,
          }, {
              key: 'quantity',
              label: 'OrderQuantity',
              render: utils.tableCellOnDisplay,
          }, {
            key: 'available',
            label: 'Available',
            render: utils.tableCellOnDisplay,
          },
      ];

      let rowSettings = {
          rowProps: {
              hover: false
          },
          classNameOnDisplay: (data, displayProps) =>{
              if (!displayProps) displayProps = {
                  className: ""
              };

              let classes = displayProps.className ? displayProps.className : "";
              classes = classes.split(" ");

              if (data.quantity > data.available) {
                  classes.push("light-red");
              }
              displayProps.className = classes.join(" ");
              return displayProps;
          }
      };

      return (
          <GridItem xs={12} sm={12} md={12}>
              <CardContainer>
                <div className={this.props.classes.root}>
                  <GridItem xs={12} sm={12} md={12}>
                      <Button onClick={()=>{utils.exportTableToCsv("table-export", 'Order_Inventory')}}>Export</Button>

                      <NewDataTable
                          rows={tableData}
                          noPagination
                          columns={colSettings}
                          rowSettings={rowSettings}
                          id="table-export"
                      />
                  </GridItem>
                </div>
              </CardContainer>
          </GridItem>
      );
  }
  renderLocation = () => {
      const { classes } = this.props;
      const { tableData, selected_inventory } = this.state;
      if (!tableData) return null;

      let rows = [];
      let title = '';
      // let title_locations = {};
      for (let id of this.keys) {
          rows.push(tableData[id]);
          // if (!title_locations[tableData[id]['location']]) title_locations[tableData[id]['location']] = tableData[id]['location'];
          // if (!title) title = tableData[id]['location'];
      }
      // title = Object.keys(title_locations).join(", ");

      let colSettings = [
          {
              key: 'location',
              label: 'Location',
              contentNoWrap: true,
          },
          {
              key: 'sku',
              label: 'SKU',
              contentNoWrap: true
          },
          {
              key: 'upc',
              label: 'UPC',
          },
          {
            key: 'locked',
            label: 'Locked',
            render: (val) => val == 1 ? 'YES' : 'NO',
          },
          {
              key: 'description',
              label: 'Description',
          },
          {
              key: 'quantity',
              label: 'Quantity In Stock',
          },
          {
              key: 'allocate',
              label: 'Allocated',
          },
          {
              key: 'shortname',
              label: 'Customer',
          },
      ];

      let action_buttons = [];
      action_buttons.push({
        label: 'Export',
        onClick: ()=>{
          if (_.isEmpty(selected_inventory)) this.context.alert('No inventory selected.');
          else this.exportSelectedRows();
        },
      }); // Export Inventory
      action_buttons.push({
        label: 'Lock Inventory',
        onClick: ()=>{
          if (_.isEmpty(selected_inventory)) this.context.alert('No inventory selected.');
          else this.context.confirm(`Are you sure you want to lock selected inventory from allocation?`, this.updateInventoryLocked.bind(this, 1));
        },
      }); // Lock inventory for allocation
      action_buttons.push({
        label: 'Un-Lock Inventory',
        onClick: ()=>{
          if (_.isEmpty(selected_inventory)) this.context.alert('No inventory selected.');
          else this.context.confirm(`Are you sure you want to un-lock selected inventory from allocation?`, this.updateInventoryLocked.bind(this, 0));
        },
      }); // Un-Lock inventory for allocation

      let table = (
        <NewDataTable
          rows={rows}
          columns={colSettings}
          id="location"
          rowsPerPage={100}
          rowSelection={{
            selected: selected_inventory, // since ajax table will not have sort by, data array index will always work for selection
            handleSelectedChange: (newSelected) => (this.setState({selected_inventory: newSelected})),
            uniqueID: 'inventory_id'
          }}
        />
      );

      return (
        <GridItem xs={12}>
          <CardContainer>
            <div className={this.props.classes.root}>
              <div className={classes.title} style={{padding: "1rem 0"}}>
                <Typography variant="h6" style={{marginBottom: '.5rem'}}>
                  {title}
                </Typography>
              </div>

              {/* <div>
                <Button onClick={()=>{utils.exportTableToCsv('location');}}>Export</Button>
              </div> */}

              <ButtonGroup>   
                {action_buttons.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
              </ButtonGroup>

              {table}
            </div>
          </CardContainer>
        </GridItem>
      );
  }
}
export default withStyles(styles)(ManageInventory);
