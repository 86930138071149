import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select,
  Table,
  TableBody,
  TableRow,
  TableCell, InputAdornment, Popover } from '@material-ui/core'
import {
  Info
} from '@material-ui/icons'

import {CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function CreateWorkOrder(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, work_order: defaultWorkOrder} = props;

  const [workOrder,setWorkOrder] = useState( Object.assign({
    hours: 0,
    type: 'Warehouse',
    sub_type: '',
    notes: '',
    fix_amount: '',
    people: 1,
  }, (defaultWorkOrder || {})));
  const [anchorEl, setAnchorEl] = useState(null);

  function updateWorkOrder(val, key) {
    let new_work_order = Object.assign({}, workOrder);
    new_work_order[key] = val;
    setWorkOrder(new_work_order);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Type of Work Order</InputLabel>
          <Select
              onChange={(e)=>{updateWorkOrder(e.target.value, 'type')}}
              value={workOrder.type}
          >
            <MenuItem value='Warehouse'>Warehouse</MenuItem>
            <MenuItem value='Warehouse Supervision'>Warehouse Supervision</MenuItem>
            <MenuItem value='IT'>IT</MenuItem>
            <MenuItem value='Customer Service'>Customer Service</MenuItem>
            <MenuItem value='Fix Amount'>Fix Amount</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Sub Category</InputLabel>
          <Select
              onChange={(e)=>{updateWorkOrder(e.target.value, 'sub_type')}}
              value={workOrder.sub_type}
          >
            <MenuItem value=''>Please Select Sub Category</MenuItem>
            <MenuItem value='Inbound'>Inbound</MenuItem>
            <MenuItem value='B2COutbound'>B2COutbound</MenuItem>
            <MenuItem value='B2BOutbound'>B2BOutbound</MenuItem>
            <MenuItem value='Warehouse OT'>Warehouse OT</MenuItem>
            <MenuItem value='Inventory'>Inventory</MenuItem>
            <MenuItem value='Project'>Project</MenuItem>
            <MenuItem value='Supply'>Supply</MenuItem>
          </Select>
        </FormControl>

        {
          workOrder.type !== 'Fix Amount' && workOrder.sub_type != 'Supply' && 
          <CustomInput
            labelText='Hours Per People'
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{ shrink: true }}
            inputProps={{
              onChange: (e)=>{updateWorkOrder(e.target.value, 'hours')},
              value: workOrder.hours
            }}
          />
        }

        {
          workOrder.type !== 'Fix Amount' && workOrder.sub_type != 'Supply' && 
          <CustomInput
            labelText='People'
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{
                shrink: true,
            }}
            inputProps={{
              type: 'number', 
              onChange: (e)=>{updateWorkOrder(e.target.value, 'people')},
              value: workOrder.people 
            }}
          />
        }

        {
          (workOrder.type === 'Fix Amount') && workOrder.sub_type != 'Supply' && <CustomInput
              labelText='Fix Amount'
              formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
              }}
              labelProps={{
                  shrink: true,
              }}
              inputProps={{
                  value: workOrder.fix_amount ? workOrder.fix_amount : '',
                  onChange: (e)=>{updateWorkOrder(e.target.value, 'fix_amount')},
              }}
          />
        }

        {
          workOrder.sub_type == 'Supply' && 
          <CustomInput
            labelText='Fix Amount'
            formControlProps={{
              fullWidth: true,
              className: classes.customInput
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              value: workOrder.fix_amount || '',
              onChange: (e)=>{updateWorkOrder(e.target.value, 'fix_amount')},
              startAdornment: (
                <InputAdornment position="start">
                  <Info
                    style={{cursor: "pointer"}}
                    onClick={(e)=>{setAnchorEl(e.currentTarget)}}
                  />
                </InputAdornment>
              ),
            }}
            />
          }

          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={(e)=>{setAnchorEl(null)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {renderSupplyTable()}
          </Popover>

          <CustomInput
            labelText='Notes'
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            labelProps={{ shrink: true }}
            inputProps={{
              value: workOrder.notes,
              onChange: (e)=>{updateWorkOrder(e.target.value, 'notes')},
            }}
          />
      </Grid>
    );
  }
  function renderSupplyTable() {
    return (
      <div style={{padding: '.5rem'}}>
        <Table>
          <TableBody>
            <TableRow  hover>
              <TableCell>Gaylord </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>$15</TableCell>
            </TableRow>

            <TableRow  hover>
              <TableCell>B pallet </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>$15</TableCell>
            </TableRow>

            <TableRow  hover>
              <TableCell>Heat Treated Pallet </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>$25</TableCell>
            </TableRow>

            <TableRow  hover>
              <TableCell>Euro Pallet </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>$25</TableCell>
            </TableRow>

            <TableRow  hover>
              <TableCell>Euro Heat Treated Pallet </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>$29</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
  function onSubmit() {
    let { hours, people, type, sub_type, notes, fix_amount } = workOrder;
    let err = '';  

    if (type === 'Fix Amount' || sub_type == 'Supply') {
      fix_amount = parseFloat(fix_amount);
      if (!fix_amount || fix_amount <= 0) {
          err += "Fix Amount should be a positive number. \n";
      }
      // if type is fix amount, set people and hours to 0
      people = 0;
      hours = 0;
    } else {
        fix_amount = 0;
        // if type is not fix amount, pass hours and people
        hours = parseFloat(hours);
        if (!hours || hours <= 0) err += "Work Hours should be a positive number. \n";
        if (!people || people <= 0 || (people % 1 !== 0)) {
            err += "People should be a positive integer. \n";
        } else if (!parseInt(people)) {
            err += "People should be a positive integer. \n";
        }
        people = parseInt(people);
        hours = hours * people;
    }
    if (!type) err += "Work Order Type is required. \n";
    if (!sub_type) err += "Work Order Sub Category is required. \n";
    if (!notes) err += "Notes is required.";

    let req_data = Object.assign((defaultWorkOrder || {}), {
      hours, people, type, sub_type, notes, fix_amount
    });

    if (err) dataContext.alert(err);
    else handleSubmit(req_data); // todo, decide if need to pass the formatted
  }

  let title = defaultWorkOrder ? 'Edit Work Order' : 'Create Work Order';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}