import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, ButtonGroup, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import utils from 'utils/utils'
import axios from 'axios';

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function EditReceivingItem(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, lineItem: defaultLineItem, handleSubmit} = props;
  const [lineItem, setLineItem] = useState(Object.assign({barcoded_input: 0, polybagged_input: 0, hangtagged_input: 0}, defaultLineItem));
  const [loading,setLoading] = useState(false);

  // Some Client Wants certain input field required
  function __getFieldRequired() {
    let current_customer = parseInt(localStorage.getItem('customer_id'));
    let lotRequired = false;
    let expirationDateRequired = false;
    // if (current_customer === 131 || current_customer === 190) lotRequired = true;
    // ON 2021-11-01, turn off lot required for 131
    if (current_customer === 190) lotRequired = true;
    if (current_customer === 158) {
        lotRequired = true;
        expirationDateRequired = true;
    }
    // for Oliver's and Bottles and Burps
    // check expiration_date
    if (current_customer === 127 || current_customer === 146) {
        expirationDateRequired = true;
    }
    return {
        lot: lotRequired,
        expiration_date: expirationDateRequired
    }
  }

  function onLineItemChange(key, newVal) {
    let newLineItem = Object.assign({}, lineItem);
    newLineItem[key] = newVal;
    setLineItem(newLineItem);
  }
  
  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        <CustomInput
          labelText='Barcoded Input'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onLineItemChange('barcoded_input', e.target.value)},
            type: 'number',
            inputProps: {
                step: 1,
                min: '0',
            },
            value: lineItem.barcoded_input || '',
          }}
        />

        <CustomInput
          labelText='Total Barcoded Quantity'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          
          inputProps={{
            disabled: true,
            onChange: (e)=>{onLineItemChange('barcoded_quantity', e.target.value)},
            value: lineItem.barcoded_quantity,
          }}
        />

        <CustomInput
          labelText='Polybagged Input'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onLineItemChange('polybagged_input', e.target.value)},
            type: 'number',
            inputProps: {
                step: 1,
                min: '0',
            },
            value: lineItem.polybagged_input || '',
          }}
        />

        <CustomInput
          labelText='Total Polybagged Quantity'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            disabled: true,
            onChange: (e)=>{onLineItemChange('polybagged_quantity', e.target.value)},
            value: lineItem.polybagged_quantity,
          }}
        />

        <CustomInput
          labelText='Hangtagged Input'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onLineItemChange('hangtagged_input', e.target.value)},
            type: 'number',
            inputProps: {
                step: 1,
                min: '0',
            },
            value: lineItem.hangtagged_input || '',
          }}
        />

        <CustomInput
          labelText='Total Hangtagged Quantity'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            disabled: true,
            onChange: (e)=>{onLineItemChange('hangtagged_quantity', e.target.value)},
            value: lineItem.hangtagged_quantity,
          }}
        />

        <CustomInput
          labelText='Lot'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: __getFieldRequired().lot,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onLineItemChange('lot', e.target.value)},
            value: lineItem.lot,
          }}
        />

        {
          (defaultLineItem.lot_map && lineItem.lot !== "")
          &&
          <CustomInput
            labelText='Quantity Needed For this Lot'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: __getFieldRequired().lot,
              className: classes.customInput
            }}
            inputProps={{
              disabled: true,
              value: defaultLineItem.lot_map[lineItem.lot] ? defaultLineItem.lot_map[lineItem.lot].quantity : "Cannot find this lot number! Please use correct lot number",
            }}
          />
        }

        <CustomInput
          labelText='Expiration Date'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: __getFieldRequired().expiration_date,
            className: classes.customInput
          }}
          inputProps={{
            type: 'date',
            onChange: (e)=>{onLineItemChange('expiration_date', e.target.value)},
            value: lineItem.expiration_date,
          }}
        />

        <ButtonGroup style={{marginTop: '1rem', marginBottom: '1rem'}}>
            <Button color="bxzDefault" variant="contained" onClick={printBarcode.bind(this, 'sku')}>Print SKU</Button>
            {lineItem.upc && <Button color="bxzDefault" variant="contained" onClick={printBarcode.bind(this, 'upc')}>Print UPC</Button>}
        </ButtonGroup>
      </Grid>
    );
  }

  function printBarcode(label) {
    let content = lineItem[label];
    let qty = 1;
    if (parseInt(lineItem.barcoded_input) > 0) {
        qty = parseInt(lineItem.barcoded_input);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/barcode`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'product_tag',
        codes: content,
        repeat: qty
      },
    });

    setLoading(true);
    req.then(printSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: dataContext.alert}));
  }
  function printSuccess(resp) {
    setLoading(false);
    if (resp.data.Error) {
      dataContext.alert(resp.data.Error);
      return;
    }

    let data = resp.data;
    utils.printPage(data);
  }

  function onSubmit() {
    const { barcoded_input, polybagged_input, barcoded_quantity, polybagged_quantity, hangtagged_input, hangtagged_quantity,  lot, expiration_date } = lineItem;
    let err = '';
    let newItem = Object.assign({}, lineItem);


    newItem.barcoded_quantity = (parseInt(barcoded_input) ? parseInt(barcoded_input) : 0) + (parseInt(barcoded_quantity) ? parseInt(barcoded_quantity) : 0);
    newItem.polybagged_quantity = (parseInt(polybagged_input) ? parseInt(polybagged_input) : 0) + (parseInt(polybagged_quantity) ? parseInt(polybagged_quantity) : 0);
    newItem.hangtagged_quantity = (parseInt(hangtagged_input) ? parseInt(hangtagged_input) : 0) + (parseInt(hangtagged_quantity) ? parseInt(hangtagged_quantity) : 0);

    if (newItem.barcoded_quantity >= 100000) {
        err += `Warning: You are trying to enter a very large Barcoded Quantity for SKU: ${newItem.sku}, please check if it is a mistake.\n`;
    }
    if (newItem.polybagged_quantity >= 100000) {
        err += `Warning: You are trying to enter a very large Polybagged Quantity for SKU: ${newItem.sku}, please check if it is a mistake.\n`;
    }
    if (newItem.hangtagged_quantity >= 100000) {
      err += `Warning: You are trying to enter a very large Hangtagged Quantity for SKU: ${newItem.sku}, please check if it is a mistake.\n`;
    }
    if (expiration_date && !utils.isDateInRange(expiration_date)) {
        err += `The value: of ${expiration_date} Expiration Date for SKU: ${newItem.sku} is invalid. Please correct it.\n`;
    }
    if (__getFieldRequired().lot && !lot) {
        err += `For current client, Lot is required.\n`;
    }
    if (__getFieldRequired().expiration_date && !expiration_date) {
        err += `For current client, Expiration Date is required.\n`;
    }

    if (err) {
      dataContext.alert(err);
      return;
    }

    newItem.barcoded_input = 0;
    newItem.polybagged_input = 0; 
    newItem.hangtagged_input = 0; 

    handleSubmit(newItem);
  }

  // let title = "Receiving LineItem";
  let title = <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{`Editing Item ${lineItem.sku}`}</span>;

  return (
    <BasicDialog
      fullWidth
      maxWidth='md'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}