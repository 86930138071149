// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Tooltip,
    InputAdornment,
    Typography,
    Grid,
    withStyles
} from "@material-ui/core";
import {
    Info
} from '@material-ui/icons'
// BXZ render modules
import {
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import DataContext from 'context/Data'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectInput: {
        marginTop: '.5rem'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    customInput: {
        margin: theme.spacing(1, 0),
    },
    button: {
        marginTop: theme.spacing(1)
    },
    tooltipText: {
        fontSize: ".75rem"
    }
});

const CustomizedTooltip = withStyles(theme => ({
    tooltip: {
        // background: grey[700],
        borderRadius: 2,
        // color: common.fullWhite,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(14),
        minHeight: theme.spacing(4),
        lineHeight: '32px',
        opacity: 0,
        padding: `0 ${theme.spacing(1)}px`,
        transform: 'scale(0)',
        transition: theme.transitions.create(['opacity', 'transform'], {
            duration: theme.transitions.duration.shortest,
        }),
        [theme.breakpoints.up('sm')]: {
            minHeight: 22,
            lineHeight: '22px',
            padding: `0 ${theme.spacing(1)}px`,
            fontSize: theme.typography.pxToRem(10),
        },
    }
}))(Tooltip);

class Profile extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            email: '',
            firstname: '',
            lastname: '',
            password: '',
            password_repeat: '',
            tooltip_open: false,
        };

        document.title = 'User Profile';
    }

    render() {
        const { classes } = this.props;
        const { loading, email, password, password_repeat, firstname, lastname } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={2}>
                {loadingBar}
                <GridItem md={4} sm={4} xs={12}>
                    <CardContainer>
                        <div className={classes.root}>
                            <form onSubmit={this.submit}>
                                <CustomInput
                                  labelText="Email"
                                  formControlProps={{
                                      fullWidth: true,
                                      required: true,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: true,
                                  }}
                                  inputProps={{
                                      inputProps: {autocomplete: "donot-touch-my-autocomplete",},
                                      onChange: (e)=>{this.setState({email: e.target.value})},
                                      value: email ? email : '',
                                  }}
                                />

                                <CustomInput
                                  labelText="First Name"
                                  formControlProps={{
                                      fullWidth: true,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: true,
                                  }}
                                  inputProps={{
                                      inputProps: {autocomplete: "donot-touch-my-autocomplete",},
                                      onChange: (e)=>{this.setState({firstname: e.target.value})},
                                      value: firstname ? firstname : '',
                                  }}
                                />

                                <CustomInput
                                  labelText="Last Name"
                                  formControlProps={{
                                      fullWidth: true,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: true,
                                  }}
                                  inputProps={{
                                      inputProps: {autocomplete: "donot-touch-my-autocomplete",},
                                      onChange: (e)=>{this.setState({lastname: e.target.value})},
                                      value: lastname ? lastname : '',
                                  }}
                                />

                                <CustomInput
                                  labelText="Password"
                                  formControlProps={{
                                      fullWidth: true,
                                      // required: true,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: true,
                                  }}
                                  inputProps={{
                                      onChange: (e)=>{this.setState({password: e.target.value})},
                                      inputProps: {autocomplete: "donot-touch-my-autocomplete",},
                                      value: password,
                                      type: 'password',
                                      startAdornment: (
                                        <InputAdornment position="start">
                                            <CustomizedTooltip
                                              title={<React.Fragment>
                                                  <Typography color="inherit">Password Requirements</Typography>
                                                  <div className={classes.tooltipText}>Length should be >= 8.</div>
                                                  <div className={classes.tooltipText}>Should <span style={{fontWeight: "bold"}}>NOT</span> contain space or quotes.</div>
                                                  <div className={classes.tooltipText}>Should contain at lease one lowercase letter.</div>
                                                  <div className={classes.tooltipText}>Should contain at least one uppercase letter.</div>
                                                  <div className={classes.tooltipText}>Should contain a number or a special symbol.</div>
                                              </React.Fragment>}
                                             >
                                                <Info
                                                  style={{cursor: "pointer"}}
                                                  // onClick={()=>{this.setState({tooltip_open: true})}}
                                                />
                                            </CustomizedTooltip>
                                        </InputAdornment>
                                      ),
                                  }}
                                />

                                <CustomInput
                                  labelText="Confirm Password"
                                  formControlProps={{
                                      fullWidth: true,
                                      // required: true,
                                      className: classes.customInput
                                  }}
                                  labelProps={{
                                      shrink: true,
                                  }}
                                  inputProps={{
                                      inputProps: {autocomplete: "donot-touch-my-autocomplete",},
                                      onChange: (e)=>{this.setState({password_repeat: e.target.value})},
                                      value: password_repeat,
                                      type: 'password'
                                  }}
                                />

                                <Button type='submit'>Submit</Button>
                            </form>
                        </div>
                    </CardContainer>
                </GridItem>
            </Grid>
        );
    }

    componentWillMount() {
        this.loadProfile();
    }

    // api call
    loadProfile = () => {
      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/profile`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });

      this.setState({loading: true});
      req.then(this.loadProfileSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadProfileSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.context.alert(resp.Error);
            return;
        }

        if (!resp || resp.length === 0) {
            this.context.alert("Load profile error");
            return;
        }
        resp = resp[0];

        this.setState({
            email: resp.email,
            firstname: resp.firstname,
            lastname: resp.lastname,
        });

    }
    submit = (e) => {
        e.preventDefault();
        const { email, firstname, lastname, password, password_repeat } = this.state;

        let err = '';
        if (password !== password_repeat) {
            err += "Password and Confirm Password don't match.\n";
        }
        if (password != '') {
          let password_error = utils.passwordValidator(password);
          if (password_error && password_error.length > 0) {
              for (let error_msg of password_error) err += error_msg + "\n";
          }
        }
        
        if (!email) {
          err += "Email address is required. \n";
        }
        if (email) {
          let email_valid = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g;
          if (!email_valid.test(email)) err += "Email address is invalid. \n";
        }

        if (err) {
            this.context.alert(err);
            return;
        }

        let req = axios({
            method: 'put',
            url: `${utils.getBaseUrl('customer')}/profile`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: {
                email: email,
                firstname: firstname,
                lastname: lastname,
                password: password,
            },
        });

        this.setState({loading: true});
        req.then(this.success).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    success = (resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.context.alert(resp.Error);
            return;
        }

        if (resp) {
            this.context.snackDisplay("Profile update success.");
            window.location.reload();
        }
    }
}
export default withStyles(styles)(Profile);
