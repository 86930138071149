import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import cx from 'classnames'
import {withStyles, Paper, Typography, Tooltip, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Checkbox} from '@material-ui/core'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableCell: {
      wordWrap: 'break-word',
      margin: 'auto',
      // verticalAlign: 'middle',
      overflow: 'auto',
      // fontSize: '1.25rem',
      fontSize: '1rem',
      // display: 'inline-flex',
      // display: 'flex',
      // flexGrow: 1,
      // minHeight: 0,
      // alignItems: 'center',
      // justifyContent: 'center',
      // '&::after': {
      //   content: "",
      //   clear: 'both',
      //   display: 'table'
      // }, 
    },
    tooltip : {
      fontSize: "1rem"
    },
    checkBox: {
      padding: '0'
    }
});

class AjaxTable extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        rows: PropTypes.array.isRequired,
        rowHeight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        maxHeight: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]), 
        tableTitle: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]),
        columns: PropTypes.array.isRequired,
        handlePageChange: PropTypes.func.isRequired, // this function is to handle page change, parent component need to use new page to load table data
        rowsPerPage: PropTypes.number,
        rowSelection: PropTypes.shape({
          selected: PropTypes.object.isRequired, // the selected rows
          handleSelectedChange: PropTypes.func.isRequired, // change handler of selected rows
          uniqueID: PropTypes.object, // the identifier of each row
        }),
    };

    static defaultProps = {
        // rowHeight: 36,
        rowHeight: 60,
        rowSelection: null,
        tableTitle: '',
        rowsPerPage: 100
    };

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: props.rowsPerPage, 
        };
    }

    handleChangePage = (event, page) => {
      let thisContext = this;  
      this.props.handlePageChange(page, (forceUpdatePage)=>{
        if (typeof forceUpdatePage !== 'undefined') {
          thisContext.setState({ page: forceUpdatePage });
        }
        else thisContext.setState({ page });
      });
    };
    onSelectAllChange = (e) => {
      const {rows, rowSelection} = this.props;
      if (!rowSelection) return;
      let newVal = e.target.checked;
      if (!newVal) rowSelection.handleSelectedChange({}); // de-select all
      else {
        // select all
        let newSelected = {};
        rows.map((row, index)=>{
          let selectedID = index;
          if (rowSelection.uniqueID) selectedID  = row[rowSelection.uniqueID] 
          newSelected[selectedID] = row;
        });
        rowSelection.handleSelectedChange(newSelected);
      }
      
    }
    onRowSelectChange = (index, row, e) => {
      const {rows, rowSelection} = this.props;
      if (!rowSelection) return;
      let newVal = e.target.checked;
      let newSelected = Object.assign({}, rowSelection.selected);
      let selectedID = rowSelection.uniqueID ? row[rowSelection.uniqueID] : index
      if (newVal) newSelected[selectedID] = row; // select
      else delete newSelected[selectedID]; // de-select
      rowSelection.handleSelectedChange(newSelected);
    }

    renderTableRow = (row, index) => {
      const {classes, columns, rowSelection, rowHeight} = this.props;
      let cells = [];
      
      if (rowSelection) {
        // if row selection turnned on, render select checkbox for each row
        let rowID = rowSelection.uniqueID ? row[rowSelection.uniqueID] : index; // the unique mark for selected is either specified id or row index
        cells.push(
          <TableCell key={rowID+'-select'} style={{width: "50px"}}>
            <Checkbox checked={!!rowSelection.selected[rowID]} onClick={this.onRowSelectChange.bind(this, index, row)} />
          </TableCell>
        );
      }

      for (let column of columns) {
        let cellProps = {
          key: row.id + '_' + column.key,
        };
        if (column.cellProps) cellProps = Object.assign(cellProps, column.cellProps);
        cellProps.className = cx(cellProps.className || "", classes.td);
        if (cellProps.onClick) {
          let oldOnClick = cellProps.onClick;
          let newOnClick = () => {
            oldOnClick(row[column.key], column.key, row, index);
          }
          cellProps.onClick = newOnClick;
        }
        if (cellProps.onDoubleClick) {
          let oldOnDoubleClick = cellProps.onDoubleClick;
          let newOnDoubleClick = ()=>{
              oldOnDoubleClick(row[column.key], column.key, row, index);
          };
          cellProps.onDoubleClick = newOnDoubleClick;
        }

        let cellContent = row[column.key];
        if (column.render) {
          // Render func params: cell Value, cell key, row data object
          cellContent = column.render(cellContent, column.key, row, index);
        }
        if (_.isUndefined(cellContent)) cellContent = '';
        if (_.isFunction(column.cellPropsOnDisplay)) cellProps = column.cellPropsOnDIsplay(cellProps, cellContent, column.key, row, index);
        let cellDimensionStyle = {
          maxHeight: rowHeight+'px',
        };
        if (column.width) {
          if (column.width !== 'auto') {
            cellDimensionStyle.maxWidth = column.width+'px';
            cellDimensionStyle.minWidth = column.width+'px';
          }
        }
        // if (column.width) cellDimensionStyle.width = column.width+'px';
        else {
          // default column width to 200px
          // cellDimensionStyle.maxWidth = 200+'px';
          // On 2023-01-20, People want to show col width auto
        }
        if (column.contentNoWrap) cellDimensionStyle.whiteSpace = 'nowrap'; 
      if (column.maxWidth) cellDimensionStyle.maxWidth = column.maxWidth+'px'; // if has max width, overwrite it
        cells.push(<TableCell align="center" {...cellProps}><div style={cellDimensionStyle} className={classes.tableCell}>{cellContent}</div></TableCell>);
      }

      let rowProps = {
        hover: true,
        tabIndex: -1,
        key: row.id || index
      };

      return (
        <TableRow {...rowProps}>
          {cells}
        </TableRow>
      );
    }

    renderTableHeader = () => {
      const {columns, rows, classes} = this.props;
      return (
        columns.map(column => {
          let lbl = column.label;
          if (column.colDesc) {
            lbl = <Tooltip title={<div className={classes.tooltip}>{column.colDesc}</div>} aria-label={column.colDesc}><span>{lbl}</span></Tooltip>;
          }

          return (
              <TableCell
                  align="center"
                  key={column.key}
                  numeric={column.numeric}
                  padding={column.disablePadding ? 'none' : 'normal'}
                  style={column.header_style ? column.header_style : {}}
                  className={column.header_class ? column.header_class : ''}
              >
                  {lbl}
              </TableCell>
          );
      }, this)
      );
    }

    render() {
      const {classes, columns, tableTitle, rowSelection, maxHeight,
        handlePageChange, rowsPerPage: defaultRowsPerPage, rowHeight,
        ...restProps} = this.props;
      let rows = Array.from(this.props.rows);
      const {page, rowsPerPage} = this.state;

      let tableProps = {
          className: classes.table,
          size: 'small', // default table padding  to dense
      };
      if (restProps) tableProps = Object.assign(tableProps, restProps);

      let empty = null;
      if (rows.length === 0) {
        empty = <TableRow><TableCell colSpan={rowSelection ?  columns.length + 1 : columns.length}><div>No Data</div></TableCell></TableRow>;
      }

      let containerStyle = {};
      if (maxHeight) containerStyle = {maxHeight: maxHeight+'px'};

      let tableTitleArea = null;
      if (tableTitle) tableTitleArea = (
        <div style={{width: '100%', padding: "0 1rem"}}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              {tableTitle}
            </Typography>
          </div>
        </div>
      );

      return (
        <div className={classes.root}>
          {tableTitleArea}

          <div className={classes.tableWrapper} style={containerStyle}>
            <Table stickyHeader {...tableProps}>
              <TableHead>
                <TableRow>
                  {rowSelection && (
                    <TableCell
                      align="center"
                      // padding={column.disablePadding ? 'none' : 'normal'}
                    >
                      <Checkbox
                        checked={(Object.keys(rowSelection.selected).length === rows.length) && rows.length > 0}
                        onChange={this.onSelectAllChange}
                        disabled={rows.length === 0}
                      />
                    </TableCell>
                  )}
                  {this.renderTableHeader()}
                </TableRow>
              </TableHead>

              <TableBody>
                {rows.length > 0 ? rows.map(this.renderTableRow) : empty}
              </TableBody>
            </Table>
          </div>

          <TablePagination
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[rowsPerPage]}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
                'aria-label': 'Next Page',
            }}
            page={page}
            onPageChange={this.handleChangePage}
          />
        </div>
      );
    }
}

export default withStyles(styles)(AjaxTable);
