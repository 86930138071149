import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Input, ListItemText, Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))


export default function EndWarehouseTask(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {task: defaultTask, handleClose, handleSubmit} = props;
  const [units, setUnits] = useState(defaultTask.units || '');

  function renderFormContent() {

    return (
      <Grid container className={classes.root} spacing={2}>	
        <CustomInput
            labelText='Finished Units'
            labelProps={{shrink: true}}
            formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
            }}
            inputProps={{
            type: 'number',
            inputProps: {
                min: 1,
                step: 1,
            },
            value: units,
            onChange: (e)=>setUnits(e.target.value),
            }}
        /> 
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';
    let formatted_num = parseInt(units);
    if (!units) 
    {
        err += "Finished Units is required.\n";
    } else if (!formatted_num || formatted_num <= 0) {
        err += "Finished Units should be a positive integer.\n";
    }

    if (err) {
      dataContext.alert(err);
      return;
    }

    let warning = '';
    if ((units !== defaultTask.units) && units > 1000) 
    {
      warning += `You enterd ${units} Finished Units, are you sure this is not a mistake and you still want to submit?\n`;
    }
    // if (Math.abs(units - defaultTask.units) > 100) 
    // {
    //   warning += `You enterd ${units} Finished Units, it has a big difference between the original task unit ${defaultTask.units}, are you sure this is not a mistake and you still want to submit?\n`;
    // }
    if (warning) {
      dataContext.confirm(warning, handleSubmit.bind(this, formatted_num));
      return;
    } else {
      handleSubmit(formatted_num);
    }
  }

  let title = "End Task";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>End Task</Button>
      ]}
    />
  );
}