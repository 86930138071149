import React, {Fragment, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Popover, Checkbox, Button as MuiButton ,Typography, MenuItem, FormControlLabel
} from '@material-ui/core'
import {
  Button,
} from 'components';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import {
  ArrowDropDown,
  FilterList,
  ExpandMore
  } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(1)
  },
  menuItem: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'start',
  },
  menuActions: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between'
  }
}))

export default function MultiSelect(props) {
  const classes = useStyles();
  const {listItems, title, handleSubmit, ...restProps} = props;
  let initialState = (function(){
    let initialState = {};
    for (let item of listItems) {
      initialState[item.value] = item.selected || false; 
    }
    return initialState;
  })();
  const [itemData, setItemData] = useState(initialState);

  function toggleItemChecked(key, val) {  
    let newItemData = Object.assign({}, itemData);
    newItemData[key] = val;
    setItemData(newItemData);
  }
  function clearAll() {
    let emptySelect = {}; 
    for (let key in itemData) {
      emptySelect[key] = false;
    }
    setItemData(emptySelect);
  }
  function renderListItem(itemSettings,index) {
    return (
      <MenuItem key={index} onClick={(e)=>{
        toggleItemChecked(itemSettings.value, !itemData[itemSettings.value])
      }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={itemData[itemSettings.value] || false}
              onChange={(e)=>{toggleItemChecked(itemSettings.value, e.target.checked)}}
            />
          }
          onClick={(e)=>{e.stopPropagation();}}
          label={itemSettings.label}
        />
      </MenuItem>
    );
  } 
  function onSubmit() {
    handleSubmit(itemData);
  }

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {
        (popupState)=>(
        <Fragment>
          {/* <Button variant="contained" color="default" {...bindTrigger(popupState)}>
            {title}<FilterList/>
          </Button> */}
          <Button variant="outlined" color="default" {...restProps} {...bindTrigger(popupState)}>
            <FilterList/>{title}
          </Button>

          <Popover
            {...bindPopover(popupState)}
            onClose={()=>{
              setItemData(initialState);
              popupState.close();
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {listItems.map(renderListItem)}
            <div className={classes.menuActions}>
              <MuiButton onClick={clearAll}>Clear</MuiButton>
              <MuiButton onClick={()=>{onSubmit(); popupState.close();}}>Apply</MuiButton>
            </div>
          </Popover>
        </Fragment>
        )
      }
    </PopupState>
  );
}