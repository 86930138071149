import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select} from '@material-ui/core'

import {BasicDialog, Button,CustomInput} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function EditB2BPalletDimension(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, palletDimension: defaultDimension} = props;
  const [dimension, setDimension] = useState(Object.assign({}, defaultDimension));

  function updateDimension(val, key) {
    let new_dimension = Object.assign({}, dimension);
    new_dimension[key] = val;
    setDimension(new_dimension);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <CustomInput
          labelText='Length'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{updateDimension(e.target.value, 'length')},
            value: dimension.length 
          }}
        />

        <CustomInput
          labelText='Width'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{updateDimension(e.target.value, 'width')},
            value: dimension.width 
          }}
        />

        <CustomInput
          labelText='Height'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{updateDimension(e.target.value, 'height')},
            value: dimension.height 
          }}
        />

        <CustomInput
          labelText='Weight'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{updateDimension(e.target.value, 'weight')},
            value: dimension.weight 
          }}
        />

        <CustomInput
          labelText='Location'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{updateDimension(e.target.value, 'location')},
            value: dimension.location 
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    const { length, width, height, weight, location } = dimension;

    let err = '';  
    if (err) dataContext.alert(err);
    else handleSubmit(dimension);
  }

  let title = 'Edit B2B Pallet Dimension';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}