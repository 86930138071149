import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DataContext from 'context/Data'
import axios from 'axios'
import utils from 'utils/utils'
import {
    IconButton,  
    Grid, 
    withStyles,
    Typography
 } from '@material-ui/core'
 import {
    Edit,
    DeleteForever
} from '@material-ui/icons'
import {
  NewDataTable,
  Button,
  CardContainer,
  GridItem,
} from "components"
import EditUserRoleDialog from '../Dialogs/EditUserRole'

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    customInput: {
        margin: '0'
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    nested: {
        paddingLeft: "2.5rem",
    }
});

class ManageUserRole extends Component {
    static contextType = DataContext

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.defaultMenuList = {};  
        this.state = {
            loading: false,
            dialog: '',
            activeIndex: null,
            roles: [],
            roleMenu: [],
        };

        document.title = 'Manage Roles';
    }

    componentWillMount() {
      this.loadRoles();
    }
     
    loadRoles = () => {
      let req = axios({
          method: 'get',
          url: `${utils.getBaseUrl('customer')}/userrole`,
          headers: {
            token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
          },
      });

      this.setState({loading: true});
      req.then(this.loadRolesSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }  // load role list
    loadRolesSuccess = (resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
          this.context.alert(resp.data.Error);
          return;
        }

        // the full default menu permission list
        let defaultMenuList = {};
        for (let singleMenu of (resp.data.menu ? resp.data.menu : [])) {
          defaultMenuList[singleMenu.menu_key] = singleMenu;
        }
        this.defaultMenuList = defaultMenuList;

        this.setState({roles: resp.data.role});
    }
    deleteRole = (role_id, index) => {
      let req = axios({
          method: 'delete',
          url: `${utils.getBaseUrl('customer')}/userrole/${role_id}`,
          headers: {
            token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
          },
      });

      this.setState({loading: true});
      req.then(this.deleteRoleSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    } // delete role
    deleteRoleSuccess = (delete_index, resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
          this.context.alert(resp.data.Error);
          return;
        }
        
        if (resp.data) {
          // window.location.reload();
          this.context.snackDisplay("Delete role success.");

          const {roles} = this.state;
          let new_roles = Array.from(roles);
          new_roles.splice(delete_index, 1);
          this.setState({roles: new_roles});
        }        
    }      
    addRole = (new_role) => {
        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/userrole`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: new_role
        });

        this.setState({loading: true});
        req.then(this.addRoleSuccess.bind(this, new_role)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    } // Create role
    addRoleSuccess = (new_role, resp) => {
      this.setState({loading: false});

      // alert error if any
      if (resp.data.Error) {
        this.context.alert(resp.data.Error);
        return;
      }

      if (resp.data) {
          // window.location.reload();
          this.context.snackDisplay("Add role success.");

          new_role.role_id = resp.data;
          let new_roles = Array.from(this.state.roles);
          new_roles.push(new_role);
          this.setState({roles: new_roles, dialog: ""});
      }
    }
    loadRolePermissions = (index) => {
        const {roles} = this.state;
        let role_id = roles[index]['role_id'];

        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/userrole/${role_id}`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        this.setState({loading: true});
        req.then(this.loadRolePermissionsSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    } // Load Single Role Permission list
    loadRolePermissionsSuccess = (index, resp) => {
        this.setState({loading: false});

        // alert error if any
        if (resp.data.Error) {
          this.context.alert(resp.data.Error);
          return;
        }

        // todo, check role menu 
        let roleMenu = resp.data;
        for (let single_menu of roleMenu) {
            single_menu.permission = 1;
        }
        this.setState({activeIndex: index, dialog: 'edit', roleMenu: roleMenu});
    }
    editRole = (new_role) => {
        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/userrole`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: new_role
        });

        this.setState({loading: true});
        req.then(this.editRoleSuccess.bind(this, new_role)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    } // Edit role
    editRoleSuccess = (new_role, resp) => {
      this.setState({loading: false});
      resp = resp.data;
      if (resp.Error) {
        this.context.alert(resp.Error);
        return;
      }

      if (resp) {
          // window.location.reload();
          this.context.snackDisplay("Edit role success.");

          const { activeIndex, roles } = this.state;
          let new_roles = Array.from(roles);
          new_roles[activeIndex] = new_role;
          this.setState({dialog: '', roles: new_roles, activeIndex: null});
      }
    }

    renderRolesTable = () => {
      const {classes} = this.props;
      const {roles} = this.state;

      if (!roles) return null;

      let colSettings = [
        {
            key: 'role_name',
            label: 'Role Name',
            width: 'auto',
            render: utils.tableCellOnDisplay
        },
        {
            key: 'action',
            label: 'Actions',
            render: (val, key, row, index)=>{
                return <React.Fragment>
                   <IconButton size="small" color="primary" onClick={()=>{
                        this.loadRolePermissions(index);}} variant="fab" aria-label="Edit">
                        <Edit />
                    </IconButton>

                    <IconButton size="small" color="secondary" onClick={()=>{
                        this.context.confirm("Are you sure to delete this role?", this.deleteRole.bind(this, row.role_id, index))
                    }} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}}>
                        <DeleteForever/>
                    </IconButton>
                </React.Fragment>;
            },
        },
      ];

      return (
        <NewDataTable
          noPagination
          rows={roles}
          columns={colSettings}
        />
      );
    }

    renderDialog = () => {
      const { roles, activeIndex, roleMenu, dialog } = this.state;
      if (!dialog) return;
      let defaultMenu = JSON.parse(JSON.stringify(this.defaultMenuList));
      if (dialog === 'edit') {
        // set permissions for edited Role
        for (let single_role_menu of roleMenu) {
            if (defaultMenu[single_role_menu.menu_key]) {
              defaultMenu[single_role_menu.menu_key].permission = single_role_menu.permission;
            }
        }
      }

      let formattedMenu = {};
      for (let menuItemKey in defaultMenu) {
        let menuItem = defaultMenu[menuItemKey];
        // store root menu first
        if (!menuItem.main_menu) {
          formattedMenu[menuItem.menu_key] = {
                menu_key: menuItem.menu_key,
                menu_label: menuItem.menu_label,
                permission: menuItem.permission ? menuItem.permission : 0,
            };
        } 
      }
      for (let menuItemKey in defaultMenu) {
          let menuItem = defaultMenu[menuItemKey];
          // store sub menu
          if (menuItem.main_menu) {
              if (!formattedMenu[menuItem.main_menu].sub) formattedMenu[menuItem.main_menu].sub = {};
              formattedMenu[menuItem.main_menu].sub[menuItem.menu_key] = {
                  menu_key: menuItem.menu_key,
                  menu_label: menuItem.menu_label,
                  permission: menuItem.permission ? menuItem.permission : 0,
                  main_menu: menuItem.main_menu
              }
          }
      }

      switch (dialog) {
        case 'add':
          return (
            <EditUserRoleDialog
              display={true}
              handleDialogClose={()=>{this.setState({dialog: ''})}}
              menu={formattedMenu}
              roleID=""
              title='Create User Role'
              handleSubmit={this.addRole}
            />
          );
        case 'edit':
          // set role_id and role_name for edit Role
          let role_id = roles[activeIndex].role_id;
          let role_name = roles[activeIndex].role_name;
          return (
            <EditUserRoleDialog
              display={true}
              handleDialogClose={()=>{this.setState({dialog: '', activeIndex: null})}}
              roleID={role_id}
              roleName={role_name}
              title='Edit User Role'
              menu={formattedMenu}
              handleSubmit={this.editRole}
            />
          );
          break;
      }
    }
    
    render() {
      return (
        <Grid container>
          {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

          {this.renderDialog()}

          <GridItem xs={12} sm={12}>
            <CardContainer>
              <div>
                {/* <div className={this.props.classes.title}>
                    <Typography variant="h5">Manage Roles</Typography>
                </div> */}

                <Button onClick={()=>{this.setState({dialog: "add"})}}>Add Role</Button>

                {this.renderRolesTable()}
              </div>
            </CardContainer>
          </GridItem>
        </Grid>
      );
  }
}
export default withStyles(styles)(ManageUserRole);
