// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import moment from 'moment-timezone'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Typography,
    Grid,
    withStyles, CardActionArea, CardContent
} from '@material-ui/core';
// BXZ render modules
import {
    CardIcon,
    NewDataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import DataContext from 'context/Data'
import {EuroSymbol, FlightTakeoff, Assignment, Place} from "@material-ui/icons";
// Our view components

const CHART_SETTINGS = {
    'zone_weight': {
        type: 'bar',
        title: 'Mongthly Zone Weight',
        datasets: [
            {value: 'zone', label: 'Zone', color: '#F17022',},
            {value: 'weight', label: 'Weight', color: '#3E5485'}
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.numberWithComma(value)},
    },
    'annual_shipped_value': {
        type: 'line',
        title: 'Total Shipped Revenue',
        datasets: [
            {value: 'shipment_value', label: 'Shipped Revenue', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return '$' + utils.numberWithComma(value)},
    },
    'annual_shipped_number': {
        type: 'line',
        title: 'Number of Shipments',
        datasets: [
            {value: 'shipment_number', label: 'Number of Shipments', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.numberWithComma(value)},
    },
    // Map
    'top10_province': {
        type: 'choropleth',
        title: 'Top 10 States',
        datasets: [
            {value: 'quantity', label: 'Quantity', color: '#8884d8',},
        ],
        // formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.numberWithComma(value)},
    },
    'fulfilled_rate': {
        type: 'line',
        title: 'Average Fulfilled Rate',
        datasets: [
            {value: 'percentage', label: 'Fulfilled Rate', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.formatPercentage(value, 2)},
    },
};

const styles = theme => ({
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  floatingIconContainer: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(-1)
  },
  statCard: {
      display: "flex"
  },
  statIconContainer: {
      // backgroundColor: "#29b765",
      borderRadius: "3px",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
      marginTop: theme.spacing(-2),
  },
  statIcon: {
      color: "white",
      fontSize: theme.spacing(4),
      // margin: theme.spacing(2, 5),
      margin: theme.spacing(2),
  },
  statValueContainer: {
      flexGrow: "1",
      padding: theme.spacing(1),
      paddingBottom: ".5rem !important",
      // backgroundColor: "#2ecc71",
      // color: "white",
      fontSize: "1rem"
  },
  statTable : {
      height: "100%",
      width: "100%"
  },
  clickEnabled: {
      cursor: "pointer",
  },
  clickDisabled: {
      cursor: "default",
  },
  statLabelCell: {
      // textAlign: "left",
      textAlign: "center",
      // color: "#999",
      // fontFamily: 'Raleway-Regular',
      fontFamily: 'Neuton-Regular',
      fontSize: "1.25rem",
      whiteSpace: "nowrap",
      // width: theme.spacing(18)
  },
  statValeCell: {
      // textAlign: "right",
      textAlign: "center",
      // whiteSpace: "nowrap",
      whiteSpace: "inherit",
      // fontFamily: 'Raleway-Bold',
      fontFamily: 'Neuton-Bold',
      fontSize: "1.25rem",
      fontWeight: "bold",
      flexGrow: "1"
  },
});

class ShipmentAnalysis extends Component {
    static contextType = DataContext;  

    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);

        this.charts_ref = {};

        this.state = {
            dashboard_data: {},
            stats_detail: [], // chart and table date, click load
            stat_type: '',
            zone_weight: [], // chart data
            shipped_day: [], // table data
            date_from: '',
            date_to: '',
            loading: false,
            showDataLabels: true,
        };

        document.title = "Shipment Analysis";
    }

    // api call
    componentWillMount() {
        this.loadStats();
    }
    componentDidUpdate(prevProps, prevState) {
        // separate the chart data because there are multiple charts and the chart data and load from different api
        let updating = [];
        if (!utils.is_obj_equal(this.state.zone_weight, prevState.zone_weight)) {
            updating.push('zone_weight');
        }
        if (!utils.is_obj_equal(this.state.stats_detail, prevState.stats_detail) && this.state.stat_type) {
            updating.push(this.state.stat_type);
        }
        if (updating.length > 0) this.updateChart(updating);
    }

    updateChart(updating) {
        if (!updating || updating.length <= 0) 
        {
            return;
        }

        let Chart = utils.Chart; // All charts
		let ChartDataLabels = utils.ChartDataLabels; // All charts needs label

        let charts = this.charts_ref;

        for (let chartId of updating)
        {
            if (!document.getElementById(chartId) && !document.getElementById('stats_detail')) continue;
            // const ctx = document.getElementById(chartId).getContext('2d');
            const ctx = document.getElementById(chartId) || document.getElementById('stats_detail');
            let display_stats_detail = false;   
            let chartSettings = CHART_SETTINGS[chartId];
            let chartData = this.state[chartId];
            if (!chartData) 
            {
                chartData = this.state['stats_detail'];
                chartId = 'stats_detail'; 
            }
            
            if (charts[chartId])
            {
                charts[chartId].destroy();
            }

            if (chartSettings.type == 'choropleth') 
            {
                chartData = this.state.top_state_map;
                console.log(chartData);
                let nation = utils.mapNation;
                let states = utils.mapStates;
                // Special Map

                let data_display = [];
                states.forEach((elem)=>{
                    let res = {feature: elem, value: 0};
                    if (chartData[utils.convertState(elem.properties.name)])
                    {
                        res.value = chartData[utils.convertState(elem.properties.name)].quantity;
                    }
                    data_display.push(res);
                })

                ctx.style.minHeight = '600px';
                
                charts[chartId] = new Chart(ctx, {
                    type: 'choropleth',
                    data: {
                        labels: states.map((d) => d.properties.name),
                        datasets:  [
                            {
                                label: 'States',
                                outline: nation,
                                // datalabels: {
                                // 	align: 'end',
                                // 	anchor: 'end'
                                // },
                                // data: states.map((d) => ({feature: d, value: Math.random() * 10})),
                                data: data_display,
                                // data: maps.states.map((d) => ({feature: d, value: (d.properties.name === 'New Jersey') ? 26 : Math.random() * 10})),
                            }
                        ]
                    },
                    plugins: [ChartDataLabels],
                    options: {
                        scales: {
                            projection: {
                                axis: 'x',
                                projection: 'albersUsa'  
                            },
                            color: {
                                axis: 'x',
                                quantize: 5,
                                legend: {
                                    position: 'bottom-right',
                                    align: 'bottom'
                                },
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: chartSettings.title
                            },
                            tooltip: {
                                mode: 'index',
                                callbacks: {
                                    label: (tooltipItems) => {
                                        let elem = tooltipItems.raw;
                                        var label = elem.feature.properties.name;
                                        let data_value = elem.value;
                                        if (data_value > 0) 
                                        {
                                            label += ': ' + data_value;
                                        }
                                        return label;
                                    },
                                }
                            },
                            datalabels: {
                                backgroundColor: function(context) {
                                    return context.dataset.backgroundColor;
                                },
                                borderRadius: 4,
                                font: {
                                    weight: 'bold',
                                    size: 13,
                                },
                                borderWidth: function(context) {
                                    var i = context.dataIndex;
                                    var value = context.dataset.data[i];
                                    if (!value.value) return 0;
                                    else return 1;
                                    // return value.value > 3 ? 'white' : 'black';
                                },
                                borderColor: 'rgba(128, 128, 128, 0.7)',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                color: 'white',
                                color: function(context) {
                                    var i = context.dataIndex;
                                    var value = context.dataset.data[i];
                                    return 'black';
                                    // return value.value > 3 ? 'white' : 'black';
                                },
                                formatter: function(value, context) {
                                    if (!value.value) return '';
                                    return `${utils.convertState(value.feature.properties.name)}: ${utils.numberWithComma(value.value)}`;
                                },
                                // padding: 6
                                padding: function(context) {
                                    var i = context.dataIndex;
                                    var value = context.dataset.data[i];
                                    if (!value.value) return 0;
                                    else return 6;
                                    // return value.value > 3 ? 'white' : 'black';
                                }
                            }, // chart data label plugin
                        },
                        responsive: true,
                        aspectRatio: 2,
                        maintainAspectRatio: false,
                    }
                });
            } else {
                // Regular Charts
                ctx.style.minHeight = '300px';
                charts[chartId] = new Chart(ctx, {
                    type: chartSettings.type,
                    data: {
                      labels: chartData.map(data_elem=>data_elem[chartSettings.xlabelKey]),
                      datasets:  
                          chartSettings.datasets.map(setting_elem=>{return {
                          label: setting_elem.label,
                          data: chartData.map(data_elem=>data_elem[setting_elem.value]),
                          backgroundColor: setting_elem.color,
                          borderColor: 'black',
                          borderWidth: 1
                      }})
                    },
                    plugins: [ChartDataLabels],
                    options: {
                      scales: {
                        y: {
                          beginAtZero: true
                        }
                      },
                      plugins: {
                          title: {
                              display: true,
                              text: chartSettings.title
                          },
                          legend: {
                              display: this.state.showDataLabels // Toggle display of data labels
                          },
                          tooltip: {
                              mode: 'index',
                              callbacks: {
                                  label: (tooltipItems) => {
                                      var label = tooltipItems.dataset.label || '';
                                      let data_value = tooltipItems.raw;
                                      if (chartSettings.formatDataValue)
                                      {
                                          data_value = chartSettings.formatDataValue(data_value);
                                      }
                                      label += ': ' + data_value; // formattedValue
                                      return label;
                                  },
                                  title: (tooltipItems) => {
                                      let value = tooltipItems[0].label;
                                      if (chartSettings.formatXLabel) return chartSettings.formatXLabel(value);
                                      else return value;
                                  },
                              }
                          },
                          datalabels: {
                              backgroundColor: function(context) {
                                return context.dataset.backgroundColor;
                              },
                              anchor: 'end',
                              align: 'end',
                              borderRadius: 4,
                              color: 'white',
                              font: {
                                weight: 'bold'
                              },
                              formatter: chartSettings.formatDataValue || function(){},
                              padding: 6
                          }, // chart data label plugin
                      },
                      responsive: true,
                      aspectRatio: 2,
                      maintainAspectRatio: false,
                    }
                });
            }
        }
    }

    toggleDataLabels = () => {
        this.setState(prevState => ({
          showDataLabels: !prevState.showDataLabels
        }), () => {
          this.chart.destroy(); // Destroy the previous chart instance
          this.updateChart(); // Re-create the chart with updated options
        });
    }

    // load initial data
    loadStats = () => {  
        let customer = localStorage.getItem('customer_id');
        let warehouse = localStorage.getItem('warehouse_id');
        if (!customer || !warehouse) {
            // if customer of warehouse is not set, don't make ajax call
            this.context.alert("Customer id or Warehouse id is not set, please select warehouse or login again");
            return;
        }

        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/analysis/shipment`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        req.then(this.loadStatsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
        this.setState({loading: true});
    }
    loadStatsSuccess = (resp) => {
        this.setState({loading: false});
        if (resp.data.Error) {
            this.context.alert(resp.data.Error);
            return;
        }
        
        this.setState({
            dashboard_data: resp.data,
            zone_weight: resp.data.zone_weight,
            shipped_day: resp.data.shipped_day
        });
    }
    // load stat details
    loadDetail = (type) => {
        if (type === 'top10_province') { // top 10 province already loaded    
            let raw_data = this.state.dashboard_data.top10_province;
            let stats_detail = {};
            for (let state of raw_data)
            {
                stats_detail[state.province] = {
                    quantity: state.quantity,
                    state: state.quantity,
                }; 
            }
            this.setState({stats_detail: raw_data, top_state_map: stats_detail,  stat_type: 'top10_province'});
            return;
        }

        let date_from = '';
        let date_to = '';
        if (!type) {
            // if type is not passed in, it is loading by date range, get data from state
            type = this.state.stat_type;
            date_from = this.state.date_from;
            date_to = this.state.date_to;
        } else {
            // if type is passed in, it is loading default, reset date range
            this.setState({date_from: '', date_to: ''})
        }

        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/analysis/shipment`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: {
                type,
                date_from,
                date_to,
            },
        });

        this.setState({loading: true});
        req.then(this.loadDetailSuccess.bind(this, type)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadDetailSuccess = (type, resp) => {
        this.setState({loading: false});
        resp = resp.data;
        if (resp.Error) {
            this.context.alert(resp.Error);
            return;
        }

        console.log(resp);

        if (!resp || (Array.isArray(resp) && resp.length === 0)) {
            this.context.alert("No data found.");
            return;
        }
        this.setState({stats_detail: resp, stat_type: type});
    }

    // render func
    renderStats = () => {
        const { dashboard_data } = this.state;

        let revenue = dashboard_data.annual_shipped_value ? ('$'+utils.numberWithComma(dashboard_data.annual_shipped_value)) : "N/A";
        let orders = dashboard_data.annual_shipped_number ? utils.numberWithComma(dashboard_data.annual_shipped_number) : "N/A";
        let top_province = dashboard_data.top10_province ? 'Click to see detail' : "N/A";
        let fulfilled_rate = dashboard_data.fulfilled_rate ? utils.formatPercentage(dashboard_data.fulfilled_rate) : "N/A";

        return (
        <React.Fragment>
            {this.renderSingleStatCard(revenue, "annual_shipped_value")}
            {this.renderSingleStatCard(orders, "annual_shipped_number")}
            {this.renderSingleStatCard(top_province, "top10_province")}
            {this.renderSingleStatCard(fulfilled_rate, "fulfilled_rate")}
        </React.Fragment>
        );
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        if (!val || val === "N/A") click_disabled = true;

        let title = key;
        let icon = null;
        switch (key) {
            case "annual_shipped_value":
                icon = <CardIcon style={{
                    backgroundColor: "#30C4EE"
                }} className={classes.statIconContainer}>
                    <EuroSymbol className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Revenue";
                break;
            case "annual_shipped_number":
                icon = <CardIcon style={{
                    backgroundColor: "#8791C8"
                }} className={classes.statIconContainer}>
                    <FlightTakeoff className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Shipments";
                break;
            case "top10_province":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B90"
                }} className={classes.statIconContainer}>
                    <Place className={classes.statIcon} />
                </CardIcon>;
                title = "Top States";
                break;
            case "fulfilled_rate":
                icon = <CardIcon style={{
                    backgroundColor: "#D24B9B"
                }} className={classes.statIconContainer}>
                    <Assignment className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Fulfilled Rate";
                break;
        }

        let limit_width = document.getElementById('main-app-container') ? (document.getElementById('main-app-container').offsetWidth < 1200) : false;

        return (
        <GridItem xs={12} sm={6} md={limit_width ? 6 : 3}>
            <CardContainer allowOverflow noPadding>
                <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                    <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadDetail(key);}}>
                        <div className={classes.floatingIconContainer}>
                            {icon}
                        </div>

                        <CardContent className={classes.statValueContainer}>
                            <table className={classes.statTable}>
                                <tbody>
                                <tr><td style={{
                                    whiteSpace: "normal"
                                }} className={classes.statLabelCell}>{title}</td></tr>
                                <tr><td className={classes.statValeCell}>{val}</td></tr>
                                </tbody>
                            </table>
                        </CardContent>
                    </div>
                </CardActionArea>
            </CardContainer>
        </GridItem>
        );
    }
    renderStatsDetail = () => {
        const { classes } = this.props;
        const { stats_detail, stat_type, date_from, date_to } = this.state;

        if (!stats_detail || stats_detail.length === 0 || !stat_type) return null;

        let detail_title = stat_type;
        let chart_value = '';
        let data = Array.from(stats_detail);

        let colSettings = [];
        switch (stat_type) {
            case 'annual_shipped_value':
                detail_title = "Total Shipped Revenue";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'shipment_value',
                        label: 'Shipped Revenue',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "shipment_value";
                break;
            case 'annual_shipped_number':
                detail_title = "Number of Shipments";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'shipment_number',
                        label: 'Number of Shipments',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "shipment_number";
                data = data.map((val)=>{return {
                    month: val.month,
                    shipment_number: parseInt(val.shipment_number)
                };});
                break;
            case 'top10_province':
                detail_title = "Top States";
                colSettings = [
                    {
                        key: 'province',
                        label: 'State'
                    },
                    {
                        key: 'quantity',
                        label: 'Quantity',
                        render: utils.numberWithComma
                    }
                ];
                break;
            case 'fulfilled_rate':
                detail_title = "Average Fulfilled Rate";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_received',
                        label: 'Unbatch',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'total_shipped',
                        label: 'Shipped',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'onhold',
                        label: 'Backorder',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'percentage',
                        label: 'Fulfilled Rate',
                        render: (val) => {
                            if (!val) val = 0;
                            return (parseFloat(val*100).toFixed(2)+'%')
                        }
                    }
                ];
                // todo need to modify the chart setting to show percentage, may also need to format data
                chart_value = "percentage";
                // data = data.map((val)=>{return {
                //     month: val.month,
                //     percentage: parseFloat(val.percentage)
                // };});
                break;
        }

        let rows = Array.from(stats_detail);

        return (
            <GridItem xs={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h6" id="tableTitle">
                                {detail_title}
                            </Typography>
                            <br/>

                            {
                                stat_type !== 'top10_province' && <div>
                                    <CustomInput
                                        labelText='Date From'
                                        formControlProps={{
                                            required: true,
                                            style: {minWidth: '140px'},
                                            className: classes.customInput
                                        }}
                                        labelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            style:{marginRight: '1rem'},
                                            views:['year', 'month'],
                                            type: 'date',
                                            value: date_from,
                                            onChange: (e)=>{this.setState({date_from: e.target.value})}
                                        }}
                                    />
                                    <CustomInput
                                        labelText='Date To'
                                        formControlProps={{
                                            required: true,
                                            style: {minWidth: '140px', marginRight: '1rem'},
                                            className: classes.customInput
                                        }}
                                        labelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            type: 'date',
                                            value: date_to,
                                            onChange: (e)=>{this.setState({date_to: e.target.value})}
                                        }}
                                    />
                                    <Button style={{marginTop: "1rem"}}  onClick={()=>{
                                        const { date_from, date_to } = this.state;
                                        if (moment(date_to).isBefore(date_from)) this.context.alert("Date to should be later than Date from.");
                                        else this.loadDetail();
                                    }}>Submit</Button>
                                </div>
                            }
                        </div>

                        <div style={{width: '100%', minHeight: '300px', position: 'relative'}}>
                            <canvas id="stats_detail"></canvas>
                        </div>

                        <NewDataTable
                            rows={rows}
                            className={classes.table}
                            noPagination
                            columns={colSettings}
                        />

                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderZoneWeight = () => {
        const { classes } = this.props;
        const { zone_weight } = this.state;

        // if (!zone_weight || zone_weight.length === 0) return null;

        return (
            <GridItem xs={12} md={6}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h6" id="tableTitle">
                                Zone Weight
                            </Typography>
                        </div>

                        <div style={{width: '100%', minHeight: '300px', position: 'relative'}}>
                            <canvas id="zone_weight"></canvas>
                        </div>
                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderShipmentTable = () => {
        const { classes } = this.props;
        const { shipped_day } = this.state;

        let rows = shipped_day ? shipped_day : [];

        let colSettings = [
            {
                key: 'method',
                label: "Method",
                width: 'auto',
                render: utils.convertShippingMethod
            },
            {
                key: 'shipment_day',
                label: "Avg. Delivery Days",
            },
            {
                key: 'rate',
                label: "Avg. Cost",
                render: (val)=>{
                    return '$'+val;
                }
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h6" id="tableTitle">
                            Shipment Data
                            {rows.length > 0 && <Button style={{float: 'right'}}  onClick={()=>{utils.exportTableToCsv("shipment_data");}}>Export</Button>}
                        </Typography>
                    </div>

                    <NewDataTable
                    rows={rows}
                    className={classes.table}
                    id="shipment_data"
                    noPagination
                    columns={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }

    render() {
        let loadingBar = null;
        if (this.state.loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={2}>
                {loadingBar}

                {this.renderStats()}
                {this.renderStatsDetail()}
                {this.renderZoneWeight()}
                {this.renderShipmentTable()}
            </Grid>
        );
    }
}

export default withStyles(styles)(ShipmentAnalysis)
