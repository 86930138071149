import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select} from '@material-ui/core'

import {CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(.5, 0)
  },
}))

export default function AddComment(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit} = props;
  const [commentType,setCommentType] = useState('text');
  const [commentText,setCommentText] = useState('');

  function renderFormContent() {
    let commetnInput = (
      <CustomInput
        labelText='Text Comment'
        formControlProps={{
            fullWidth: true,
            className: classes.customInput
        }}
        labelProps={{
            shrink: true,
        }}
        inputProps={{
            onChange: (e)=>{setCommentText(e.target.value)},
            value: commentText
        }}
      />
    );
    if (commentType === 'pdf') {
      commetnInput = (
        <CustomInput
          labelText='Upload PDF'
          formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput,
          }}
          labelProps={{
              shrink: true,
          }}
          inputProps={{
              type: 'file',
              name: 'document',
              inputProps: {
                  id: "upload-pdf-file"
              },
          }}
        />
      );
    }

    return (
      <Grid container className={classes.root} spacing={2}>
         <FormControl fullWidth className={classes.customInput}>
            <InputLabel shrink>Comment Type</InputLabel>
            <Select
               onChange={(e)=>{setCommentType(e.target.value)}}
               value={commentType}
            >
              <MenuItem value='text'>Text Comment</MenuItem>
              <MenuItem value='pdf'>PDF File</MenuItem>
            </Select>
          </FormControl>

          {commetnInput}
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';  

    let formData = new FormData();
    let data = {};
    data.type = commentType;
    if (commentType === 'text' ) {
      if (!commentText || !commentText.trim()) err += 'Comment text is required.';
      data.text = commentText;
    } else {
      let pdfComment = document.getElementById('upload-pdf-file');
      let filePath = pdfComment.value;
      let allowedExtensions = /(\.pdf)$/i;
      if (!pdfComment.files || pdfComment.files.length < 1 || !filePath) {
          err += "No file selected.";
      } else {
          if(!allowedExtensions.exec(filePath)){
              err += "Please upload file having extensions .pdf only.";
          } else {
              formData.append('document', pdfComment.files[0]);
              data.formData = formData;
          }
      }
    }

    if (err) dataContext.alert(err);
    else handleSubmit(data); // todo, decide if need to pass the formatted
  }

  let title = "Add Coment";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}