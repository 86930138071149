import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios, {CancelToken} from 'axios';
import {
    Typography,
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    IconButton,
    Tabs,
    Tab,
    AppBar
} from '@material-ui/core';
import moment from 'moment-timezone'
import DataContext from 'context/Data'
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from 'components';

import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

const performance_tabs = [
  {
    key: 'B2CPACKING',
    label: 'B2CPACKING',
  },
  {
    key: 'B2BPACKING',
    label: 'B2BPACKING',
  },
  {
    key: 'B2CPICKING',
    label: 'B2CPICKING',
  },
  {
    key: 'B2BPICKING',
    label: 'B2BPICKING',
  },
  {
    key: 'RECEIVING',
    label: 'RECEIVING',
  },
  {
    key: 'RETURN',
    label: 'RETURN',
  },
  {
    key: 'MOVEMENT',
    label: 'MOVEMENT',
  },
  {
    key: 'PUTAWAY_RECEIVING',
    label: 'PUTAWAY_RECEIVING',
  },
  {
    key: 'PUTAWAY_RETURN',
    label: 'PUTAWAY_RETURN',
  },
  {
    key: 'INBOUNDPRODUCTION',
    label: 'INBOUNDPRODUCTION',
  },
  {
    key: 'INVENTORYRODUCTION',
    label: 'INVENTORYRODUCTION',
  },
  {
    key: 'B2CPRODUCTION',
    label: 'B2CPRODUCTION',
  },
  {
    key: 'B2BPRODUCTION',
    label: 'B2BPRODUCTION',
  },
  {
    key: 'BOXZOOKAPROJECT',
    label: 'BOXZOOKAPROJECT',
  },
  {
    key: 'INVENTORYCYCLECOUNT',
    label: 'INVENTORYCYCLECOUNT',
  },
  {
    key: 'DRIVER',
    label: 'DRIVER',
  },
];

class UserPerformance extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.requests = {
          cancel_loadList: null,
          cancel_loadDetail: null,
        };
        this.state = {
          loading: false,
          performanceData: [],
          active_tab: 0,
          // auto update page data
          updated_at: "",
          update_schedule: null,
          display_schedule: null,
          // update_interval_length: 300000
          update_interval_length: 1800000 // set to 30 mins interval for system performance
        };

        document.title = "User Performance";
    }

    render() {
        return (
          <Grid container spacing={2}>
            {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
            {this.renderUserTable()}
          </Grid>
        );
    }

    componentWillMount() {
      this.loadData(0);
    }
    componentWillUnmount() {
      clearTimeout(this.state.update_schedule);
      clearTimeout(this.state.display_schedule);
      if (this.requests.cancel_loadList) this.requests.cancel_loadList();
      if (this.requests.cancel_loadDetail) this.requests.cancel_loadDetail();
    }

    switchDisplayTab = (val) => {
      const {performanceData, display_schedule} = this.state;
      if (val < 0) val = Object.keys(performanceData).length > 0 ? Object.keys(performanceData).length - 1 : 0;
      if (val >= Object.keys(performanceData).length) val = 0;
      clearTimeout(display_schedule);
      let new_display_schedule = setTimeout(this.switchDisplayTab.bind(this, val+1), 15000);
      this.setState({active_tab: val, display_schedule: new_display_schedule});
    }

    // api call
    // load email table and report type options on page load
    loadData = (active_tab) => {
      if (active_tab === undefined) active_tab = this.state.active_tab;

      let requests = this.requests;
      if (requests.cancel_loadList) requests.cancel_loadList(); // cancel prev un-finished requests
      if (requests.cancel_loadDetail) requests.cancel_loadDetail(); // cancel prev un-finished requests
      
      clearTimeout(this.state.display_schedule);

      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/productivityuph`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      }, {
        cancelToken: new CancelToken(function executor(c) {
          requests.cancel_loadList = c;
        })
      });

      this.setState({loading: true});
      req.then(this.loadDataSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, snackDisplay: this.context.snackDisplay, errorCallback: ()=>{
        let update_schedule = setTimeout(this.loadDashboard, this.state.update_interval_length);
        this.setState({loading: false, update_schedule});
      }}));
    }
    loadDataSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
          this.context.snackDisplay(data.Error);
          // this.context.alert(data.Error);
          return;
        }
        let user_array = [];
        for (let key in resp.data) {
          let user = resp.data[key];
          let tasks = [];
          for (let customer_id in user['task']) {
            for (let task_type in user['task'][customer_id]) {
              user['task'][customer_id][task_type]['customer_id'] = customer_id;
              tasks.push(user['task'][customer_id][task_type]);
            }
          }
          user['task'] = tasks;
          user_array.push(user);
        }
  
        let update_schedule = setTimeout(this.loadData, this.state.update_interval_length);
        // let display_schedule = setTimeout(this.switchDisplayTab.bind(this, 0), 15000);
        this.setState({
          performanceData: resp.data,
          updated_at: moment().format("HH:mm"),
          update_schedule,
          // display_schedule
        });
    }

    // render search result table
    renderUserTable = () => {
      const { performanceData, active_tab } = this.state;
      if (!performanceData) return;

      return (
        <GridItem sm={12}>
         <AppBar position="static" color="default">
            <Tabs
              value={active_tab}
              onChange={(e,val)=>this.setState({active_tab: val})}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {performance_tabs.map((elem)=><Tab key={elem.key} label={elem.label}/>)}
            </Tabs>
            <CardContainer>
              {this.renderTabContent()}
            </CardContainer>
          </AppBar>
        </GridItem>
      );
    }
    renderTabContent = () => {
      const { performanceData, active_tab, updated_at } = this.state;
      let department = performance_tabs[active_tab].key || '';
      let deptData = performanceData[department];
      if (!deptData) return null;

      let rows = [];
      let totalHour = deptData['total_hour'];
      for (let key in deptData['detail']) {
        rows.push(deptData['detail'][key]);
      }
      rows.sort((a,b)=>{
        return b.score -  a.score;
      });

      let update_time = updated_at ? ("  (at " + updated_at+")") : "";
      let title = `Total Hour: ${parseFloat(totalHour) ? parseFloat(totalHour).toFixed(2) : parseFloat(totalHour)} ${update_time}`;  

      let colSettings = [
        {
          key: 'username',
          label: 'Username',
        },
        {
            key: 'name',
            label: 'Fullname',
        },
        // {
        //     key: 'rating',
        //     label: 'Rating',
        // },
      ];

      let rowSettings = {
        rowProps: {
          hover: false
        },
        classNameOnDisplay: (user, displayProps) => {
          let color = ''; // default to white
          switch (true) {
            case !(user.score):
              color = '';
              break;
            case user.score < 1:
              color = '#EA271A'; // red
              break;
            case user.score <= 1.4: // 1 - 1.4
              color = '#F9F60C'; // yellow
              break;
            case user.score > 1.4: // > 1.4
              color = '#1AEA37'; // green
              break;
            // case !(user.score):
            //   color = '';
            //   break;
            // case user.score < 0.8:
            //   color = '#EA271A'; // red
            //   break;
            // case user.score < 1: // 0.8 - 1
            //   color = '#F9F60C'; // yellow
            //   break;
            // case user.score < 1.2: // 1 - 1.2
            //   color = '#F9F60C'; // yellow
            //   break;
            // case user.score < 1.4: // 1.2 - 1.4
            //   color = '#1AEA37'; // green
            //   break;
            // case user.score < 1.6: // 1.4 - 1.6
            //   color = '#1AEA37'; // green
            //   break;
            // case user.score >= 1.6: // > 1.8
            //   color = '#1AEA37'; // green
            //   break;
          }
          if (color) {
            displayProps.style = {backgroundColor: color};
          }
          return displayProps;
        }
      };

      return (
        <NewDataTable
          noPagination
          tableTitle={
            <div style={{width: "100%"}}>
              <p style={{textAlign: "center"}}>{title}</p>
            </div>
          }
          titleProps={{style: {width: '100%'}}}
          rows={rows}
          rowSettings={rowSettings}
          columns={colSettings}
        />
      );
    }
}
export default withStyles(styles)(UserPerformance)
