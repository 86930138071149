import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, ButtonGroup, withStyles } from "@material-ui/core";
import {
  CardContainer,
  Button,
  NewDataTable,
  KeyValueTable,
  CustomInput,
  GridItem,
  ReversedIconButton
} from "components";
import DataContext from 'context/Data'
import {
	ExposureNeg1,
	Add
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
	display: 'flex',
	flexWrap: 'wrap',
  },
  nonSelectable: {
	userSelect: 'none'
  },
  formControl: {
	margin: theme.spacing(1),
	minWidth: 120,
  },
  selectInput: {
	marginTop: theme.spacing(1),
  },
  selectEmpty: {
	marginTop: theme.spacing(2),
  },
  table: {
	minWidth: 700,
  },
  customInput: {
	margin: theme.spacing(1, 0),
  },
  button: {
	marginTop: theme.spacing(1)
  },
  button_add: {
	marginTop: theme.spacing(1),
	float: "right"
  }
});

class PutawayQA extends Component {
  static contextType = DataContext;

  static propTypes = {
	classes: PropTypes.object.isRequired,
  };

  constructor(props) {
	super(props);

	this.input_refs = {
		pallet_id: null,
		sku: null,
		note: null,
	};

	this.temp_data = {
		sku_map: null,
		upc_map: null,
		pallet_id: '',
		barcode_map: {},
		table_keys: []
	};

	this.state = {
	  loading: false,
	  pallet_items: null,
	  pallet_id: '',
	  note: '',
	};

	document.title = "Putaway QA";
  }

  searchPallet = (e) => {
	e.preventDefault();
	const { pallet_id } = this.state;
	let err = '';
	if (!pallet_id) err += 'Pallet Id is required. \n';
	if (err) {
	  this.context.alert(err);
	  return;
	}

	let req = axios({
	  method: 'get',
	  url: `${utils.getBaseUrl('customer')}/qapallet/${pallet_id}`,
	  headers: {
		token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
	  },
	});
	this.setState({loading: true, ticket_locations: null});
	req.then(this.searchPalletSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  searchPalletSuccess = (resp) => {
	this.setState({loading: false});
	let data = resp.data;
	if (data.Error) {
	  this.context.alert(data.Error);
	  return;
	}

	if (resp.data.length === 0) {
	  this.context.alert("No result found!");
	  return;
	}

	let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];

	if (data.length !== 0) {
		this.temp_data.pallet_id = data[0].pallet_id; // save pallet id
	}
	for (let item of data) {
		let id = item.pallet_item_id;
		if (!item.scanned) item.scanned = 0;
		items[id] = item;
  
		// let sku = item.sku;
		// let upc = item.upc;
		// Save SKU and UPC to uppercase
		let sku = item.sku ? ('' + item.sku).toUpperCase().trim() : "";
		let upc = item.upc ? ('' + item.upc).toUpperCase().trim() : "";
		if (!skus[sku]) {
			skus[sku] = id;
		}
		if (!upcs[upc]) {
			upcs[upc] = id;
		}
		keys.push(id);
	}
	this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.table_keys = keys;

	// Some clients, for now it's only 204, 
	this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

	this.setState({pallet_items: items});
  }
  submitQA = () => {
	const {pallet_items, note} = this.state;
	let err = '';
    if (!note) err += 'Please enter QA Note!\n';
    if (err) {
      this.context.alert(err);
      return;
    }

	let req = axios({
		method: 'post',
		url: `${utils.getBaseUrl('customer')}/qapallet`,
		headers: {
		  token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
		},
		data: {
		  items: pallet_items,
		  note
		}
	  });
	  this.temp_data.cartons = [];
	  this.setState({loading: true});
	  req.then(this.submitQASuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  submitQASuccess = (resp) => {
	this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
	this.context.snackDisplay("Putaway QA finished.");
    this.resetPage();
  }

  resetPage = () => {
	this.temp_data = {
		sku_map: null,
		upc_map: null,
		pallet_id: '',
		barcode_map: {},
		table_keys: []
	};

	// if (this.input_refs.pallet_id) {
	//   this.input_refs.pallet_id.value = '';
	//   this.input_refs.pallet_id.focus();
	// }

	this.setState({
		pallet_items: null,
		pallet_id: '',
		note: '',
	});
  }

  matchSKU = () => {
	let scanned_val = utils.formatString(this.input_refs.sku?.value); 
	if (!scanned_val) return;
	if (this.temp_data.barcode_map[scanned_val]) {
		scanned_val = this.temp_data.barcode_map[scanned_val];
	} else if (this.temp_data.barcode_map[scanned_val.toUpperCase()]) { // also check uppercase for barcode map
	scanned_val = this.temp_data.barcode_map[scanned_val.toUpperCase()];
	}
	// Use upper case to match SKU and UPC
	scanned_val = scanned_val.toUpperCase();


	let matched_id = '';
	if (this.temp_data.sku_map[scanned_val]){
	  matched_id = this.temp_data.sku_map[scanned_val];
	} else if (this.temp_data.upc_map[scanned_val]) {
	  matched_id = this.temp_data.upc_map[scanned_val];
	} else {
	  this.context.alert(`The scanned item ${scanned_val} is not in this Pallet.`);
	  if (this.input_refs.sku) {
		this.input_refs.sku.value = '';
		this.input_refs.sku.focus();
	  }  
	  return;
	}

	let new_items = Object.assign({}, this.state.pallet_items);
	let new_item = Object.assign({}, new_items[matched_id]);
	let new_qty = 1 + parseInt(new_item.scanned);
	new_item.scanned = new_qty;
	new_items[matched_id] = new_item;
	// move scanned to top
	let new_keys = [];
	new_keys.push(matched_id);
	for (let id of this.temp_data.table_keys) {
		if (id !== matched_id) new_keys.push(id);
	}
	this.temp_data.table_keys = new_keys;
	if (this.input_refs.sku) {
		this.input_refs.sku.value = '';
		this.input_refs.sku.focus();
	}  
	this.setState({pallet_items: new_items});
  }
  handleSKUScan = _.debounce(this.matchSKU, 150);

  renderQATotalTable = () => {
	const {pallet_items} = this.state;
	if (!pallet_items) return null;
	
	let total_quantity = 0;
	let total_scanned = 0;
	let all_matched = true;
	for (let key in pallet_items) {
		total_quantity += parseInt(pallet_items[key].quantity) ? parseInt(pallet_items[key].quantity) : 0;
		total_scanned += parseInt(pallet_items[key].scanned) ? parseInt(pallet_items[key].scanned) : 0;
		if (total_quantity != total_scanned) all_matched = false;
	}

	if (all_matched) // only if all pallet items match, focus note, otherwise won't focus
	{
		// if QA matches Putaway, focus note field
		_.delay(()=>{
			if (this.input_refs.note) 
			{
				this.input_refs.note.focus();
			}
		}, 100);
	} else {
		_.delay(()=>{
			if (this.input_refs.sku) 
			{
				this.input_refs.sku.focus();
			}
		}, 100);
	}

	return (
		<KeyValueTable
			dataObj={{
				total_quantity, total_scanned
			}}
			rowHeight='auto'
			propertyList={[
				{
					key: 'total_quantity',
					label: 'Total Pallet Quantity',
				},
				{
					key: 'total_scanned',
					label: 'Total Scanned',
				},
			]}
		/>
	);
  }
  renderTable = () => {
	const { classes } = this.props;
	const {pallet_items} = this.state;
	if (!pallet_items) return null;
	let rows = [];
	for (let pallet_item_id of this.temp_data.table_keys) rows.push(pallet_items[pallet_item_id]);

	// let total = {
	//   sku: 'Total quantity',
	//   UPC: '',
	//   tote: '',
	//   quantity: 0,
	//   scanned: 0,
	// };
	// for (let row of rows) {
	//   total.quantity += parseInt(row.quantity) ? parseInt(row.quantity) : 0;
	//   total.scanned += parseInt(row.scanned) ? parseInt(row.scanned) : 0
	// }
	// rows.push(total);
	

	let rowSettings = {
		rowProps: {
		  hover: false
		},
		classNameOnDisplay: (item, displayProps) => {
		  let color = '';
  
		  let pallet_quantity = parseInt(item.quantity);
		  let scanned = parseInt(item.scanned);
  
		  switch (true) {
			case pallet_quantity === scanned:
			  // green quantity matched
			  color = "green";
			  break;
			case scanned === 0:
			  // white haven't start
			  color = "";
			  break;
			default:
			  if (pallet_quantity < scanned) color = "yellow";
			  else color = 'light-red';
		  }
		  
		  let classes = displayProps.className || '';
		  classes = classes.split(' ');
		  classes.push(color);
		  displayProps.className = classes.join(' ');
		  return displayProps;
		}
	};

	let columns = [
	  {
		key: 'sku',
		label: 'SKU',
		render: (val, key, row) => {
			return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span>
		},
		contentNoWrap: true,
	  }, {
		key: 'upc',
		label: 'UPC',
		render: (val, key, row) => {
			return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span>
		},
		contentNoWrap: true,
	  },{
		key: 'tote',
		label: 'Tote',
	  },{
		key: 'quantity',
		label: 'Quantity',
		contentNoWrap: true,
	  }, {
		key: 'scanned',
		label: 'Scanned',
		contentNoWrap: true,
		render: (val, key, row)=> {
			return (
				<span style={{display: "inline-flex", alignItems: 'center'}}>
				  {val}
				  <ReversedIconButton style={{marginLeft: ".5rem"}} size="small" onClick={()=>{
					 if (row.scanned > 0) 
					 {
						let new_item = Object.assign({}, row);
						new_item.scanned -= 1;
						pallet_items[row.pallet_item_id] = new_item;
						this.setState({pallet_items});
					 }
				  }} variant="fab" color="secondary" aria-label="Decrease">
					  <ExposureNeg1 style={{color: 'white'}}/>
				  </ReversedIconButton>
			  </span>
			);
		  },
	  }
	];

	return (
	  <GridItem xs={12}>
		<CardContainer>
		  <div style={{marginBottom: '1rem'}} className={classes.title} >
			<CustomInput
			  labelText='SKU/UPC'
			  formControlProps={{
				fullWidth: false,
				style: { marginRight: '1rem' },
				className: classes.customInput
			  }}
			  labelProps={{
				shrink: true,
			  }}
			  inputProps={{
				defaultValue: '',
				onKeyUp: this.handleSKUScan,
				onPaste: (e)=>{
					if (!utils.checkAllowCopy()) e.preventDefault();
				},
				inputRef: elem => this.input_refs.sku = elem
			  }}
			/>
		  </div>

		  <NewDataTable
			rows={rows}
			noPagination
			id="table-export"
			// rowHeight={50} 
			rowSettings={rowSettings}
			columns={columns}
		  />
		</CardContainer>
	  </GridItem>
	);
  }
  render() {
	const { classes } = this.props;
	const { loading, pallet_id, pallet_items, note } = this.state;

	let loadingBar = null;
	if (loading) {
	  loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
	}
	// if pallet not loaded yet, focus pallet input
	if (!this.state.pallet_items) {
		_.delay(()=>{
			if (this.input_refs.pallet_id) 
			{
				this.input_refs.pallet_id.focus();
			}
		}, 100);
	}
	return (
	  <Grid container spacing={2}>
		{loadingBar}

		<GridItem xs={12} sm={6}>
		  <CardContainer>
			<form onSubmit={this.searchPallet}>
			  <CustomInput
				labelText='Pallet Id'
				formControlProps={{
				  fullWidth: true,
				  required: true,
				  className: this.props.classes.customInput
				}}
				labelProps={{
				  shrink: true
				}}
				inputProps={{
				  onChange: (e)=>{this.setState({pallet_id: e.target.value})},
				  value: pallet_id,
				  inputRef: elem => this.input_refs.pallet_id = elem
				}}
			  />

			  <Button type='submit' className={classes.button}>Search</Button>
			</form>
		  </CardContainer>
		</GridItem>

		{
			pallet_items && <GridItem xs={12} sm={6}>
			<CardContainer>
				{this.renderQATotalTable()}

				<CustomInput
					labelText='QA Note'
					formControlProps={{
						fullWidth: true,
						className: classes.customInput,
						required: true,
					}}
					labelProps={{
						shrink: true,
					}}
					inputProps={{
						value: note,
						onChange: (e)=>this.setState({note: e.target.value}),
						inputRef: elem => this.input_refs.note = elem
					}}
				/>

				<Button onClick={()=>{
					this.context.confirm("Are you sure to submit Putaway QA?", this.submitQA);
				}} className={classes.button}>
					Submit QA
				</Button>
			</CardContainer>
		</GridItem>
		}

		{this.renderTable()}
	  </Grid>
	);
  }
}
export default withStyles(styles)(PutawayQA);
