/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  customInput: {
    margin: '0'
  },
  inputLabel: {
    fontWeight: "bold"
  }
});

class AutoSuggestInput extends React.Component {
  static contextTypes = {
    data: PropTypes.object.isRequired,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    suggestions: PropTypes.array.isRequired,
    filterSuggestions: PropTypes.func,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    onSelected: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
    optionProps: PropTypes.object,
  };

  static defaultProps = {
    filterSuggestions: match,
  };


  render() {
    const { classes, suggestions, label, filterSuggestions, defaultValue, onSelected, inputProps, optionProps } = this.props;

    return (
      <Autocomplete
        // style={{ width: 300 }}
        options={suggestions}
        freeSolo
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (

          <TextField {...params} label={label} InputLabelProps={{shrink: true, className: classes.inputLabel}} margin="normal" />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = filterSuggestions(option.label, inputValue);
          const parts = parse(option.label, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
              ))}
            </div>
          );
        }}
        onChange={(e, val)=> {onSelected(val)}}
      />
    );
  }
}

export default withStyles(styles)(AutoSuggestInput);
