import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { NewDataTable, GridItem, Button, CustomInput, BasicDialog } from 'components'
import {
  InputLabel,
  Select,
  MenuItem,
  withStyles,
  FormControl, FormControlLabel, Checkbox, Grid, Typography, IconButton,
} from '@material-ui/core';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import { Add, Delete } from '@material-ui/icons';

const styles = theme => ({
  selectInput: {
    width: '100%',
    margin: theme.spacing(.5, 0),
  },
  customInputNoLabel: {
    marginTop: '0 !important'
  }
  // selectEmpty: {
  //   margin: theme.spacing(1, 0),
  // },
});

class NewLabel extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.google_map_ref = React.createRef();
    let autocomplete = null;

    this.state = {
      label_type: "shipping",
      method:'',
      saturday_delivery: false,
      signature_required: false,
      residential: false,
      carrier_account: '',
      return_weight: "",
      boxes: [],
      weight_input: "",
      address: {
        first_name: '',
        last_name:'',
        company:'',
        address1:'',
        address2:'',
        city:'',
        province:'',
        country: "US",
        zip:'',
        phone:''
      }
    };
  }

  render() {
    const { display, closeDialog, title } = this.props;

    let actions = [];
    actions.push(<Button key='cancel' onClick={closeDialog}>Cancel</Button>);
    actions.push(<Button color='primary' key='submit' onClick={this.handleSubmit}>Submit</Button>);

    return (
      <BasicDialog
        open={display}
        onClose={(e, reason)=>{if (reason != 'backdropClick' && reason != 'escapeKeyDown') closeDialog();}}
        header={title}
        fullWidth={true}
        maxWidth="lg"
        content={this.renderContent()}
        actions={actions}
      />
    );
  }

  renderContent = () => {
    const { classes } = this.props;
    const { label_type, return_weight, method, carrier_account, saturday_delivery, signature_required, residential, address } = this.state;

    // changes base on Label Type, default to return
    let input_group = <React.Fragment>
      <CustomInput
        key='weight'
        labelText='Box Weight'
        labelProps={{
          shrink: true,
        }}
        formControlProps={{
          fullWidth: true,
          required: true,
          className: classes.customInput,
        }}
        inputProps={{
          value: return_weight,
          onChange: (e)=>{this.setState({return_weight: e.target.value})}
        }}
      />
    </React.Fragment>;
    if (label_type === "shipping") {
      input_group = <React.Fragment>
        <FormControl key="method" required fullWidth className={classes.selectInput}>
          <InputLabel shrink htmlFor="method">Shipping Method</InputLabel>
          <Select
            value={method}
            onChange={(e)=>{this.setState({method: e.target.value})}}
            inputProps={{
              name: 'method',
              id: 'method',
            }}
          >
            <MenuItem value='UPS.EXP.1'>UPS Next Day Air Early(Next Business Day by 10:30AM)</MenuItem>
            <MenuItem value='UPS.DOM.1'>UPS Next Day Air(Next Business Day by 2pm)</MenuItem>
            <MenuItem value='UPS.DOM.2'>UPS Second Day Air(Second Business Day by 2pm)</MenuItem>
            <MenuItem value='UPS.DOM.3'>UPS 3-Day Air (Third Business Day by 2pm)</MenuItem>
            <MenuItem value='UPS.GRD.RESI'>UPS Ground (1 - 5 Business Day)</MenuItem>
            <MenuItem value='SUREPOST'>UPS Surepost</MenuItem>
            <MenuItem value='FDX.EXP.1'>FedEx Next Day Air Early(Next Business Day by 10:30AM)</MenuItem>
            <MenuItem value='FDX.DOM.1'>FedEx Next Day Air(Next Business Day by 2pm)</MenuItem>
            <MenuItem value='FDX.DOM.2'>FedEx Second Day Air(Second Business Day by 2pm)</MenuItem>
            <MenuItem value='FDX.DOM.3'>FedEx 3-Day Air (Third Business Day by 2pm)</MenuItem>
            <MenuItem value='FDX.GRD'>FedEx Ground (1 - 5 Business Day)</MenuItem>
            <MenuItem value='FDX.HOME'>FedEx Home</MenuItem>
            <MenuItem value='SMARTPOST'>FedEx SmartPost</MenuItem>
          </Select>
        </FormControl>
        <CustomInput
          key='carrier_account'
          labelText='Carrier Account#'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            value: carrier_account,
            onChange: (e)=>{this.setState({carrier_account: e.target.value})}
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={saturday_delivery}
              onChange={(e)=>{this.setState({saturday_delivery: e.target.checked});}}
              color='primary'
              value='saturday_delivery'
            />
          }
          key="saturday"
          label='Saturday Delivery'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={signature_required}
              onChange={(e)=>{this.setState({signature_required: e.target.checked});}}
              color='primary'
              value='signature_required'
            />
          }
          key="signature"
          label='Signature Required'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={residential}
              onChange={(e)=>{this.setState({residential: e.target.checked});}}
              color='primary'
              value='residential'
            />
          }
          key="residential"
          label='Residential'
        />
        {this.renderBoxTable()}
      </React.Fragment>;
    }

    // Turn off auto Google complete for now
    // setTimeout(()=>{
    //   if(!window.google) {
    //     utils.initGoogleMap(this.initAutoComplete);
    //   } else if (!this.autocomplete) {
    //     this.initAutoComplete();
    //   }
    // }, 0);

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={2}>
          <GridItem xs={12} sm={12} md={label_type === "return" ? 12 : 6}>
            <div className={classes.title}>
              <Typography variant="h6">
                Package Detail
              </Typography>
            </div>

            <FormControl required fullWidth className={classes.selectInput}>
              <InputLabel shrink>Label Type</InputLabel>
              <Select
                value={label_type}
                onChange={(e)=>{this.setState({label_type: e.target.value})}}
                inputProps={{
                  name: 'label_type',
                  id: 'label_type',
                }}
              >
                <MenuItem value='shipping'>Shipping</MenuItem>
                <MenuItem value='return'>Return</MenuItem>
              </Select>
            </FormControl>

            {input_group}
          </GridItem>

          <GridItem xs={12} sm={12} md={label_type === "return" ? 12 : 6}>
            <div className={classes.title}>
              <Typography variant="h6">
                {label_type === "return" ? 'Origin' : 'Destination'} Address
              </Typography>
            </div>

            <CustomInput
              labelText='First Name'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'length <= 15',
                value: address.first_name,
                onChange: this.handleAddressChange.bind(this, 'first_name')
              }}
            />

            <CustomInput
              labelText='Last Name'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'length <= 15',
                value: address.last_name,
                onChange: this.handleAddressChange.bind(this, 'last_name')
              }}
            />

            <CustomInput
              labelText='Company'
              formControlProps={{
                fullWidth: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'length <= 25',
                value: address.company,
                onChange: this.handleAddressChange.bind(this, 'company')
              }}
            />

            <CustomInput
              labelText='Address Line 1'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              id="google-map-input"
              inputProps={{
                onFocus: this.geoLocate,
                inputProps: {
                  ref: this.google_map_ref,
                  autoComplete: "donot-touch-my-autocomplete",
                  // autocomplete: "donot-touch-my-autocomplete",
                },
                placeholder: 'length <= 30',
                value: address.address1,
                onChange: this.handleAddressChange.bind(this, 'address1')
              }}
            />

            <CustomInput
              labelText='Address Line 2'
              formControlProps={{
                fullWidth: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                placeholder: 'length <= 30',
                value: address.address2,
                onChange: this.handleAddressChange.bind(this, 'address2')
              }}
            />

            <CustomInput
              labelText='City'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                value: address.city,
                inputProps: {
                  autoComplete: "donot-touch-my-autocomplete",
                  // autocomplete: "donot-touch-my-autocomplete",
                },
                onChange: this.handleAddressChange.bind(this, 'city')
              }}
            />

            <CustomInput
              labelText='Province/State'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                value: address.province,
                onChange: this.handleAddressChange.bind(this, 'province')
              }}
            />

            <CustomInput
              labelText='Postal Code'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                value: address.zip,
                onChange: this.handleAddressChange.bind(this, 'zip')
              }}
            />

            <CustomInput
              labelText='Phone'
              formControlProps={{
                fullWidth: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                value: address.phone,
                onChange: this.handleAddressChange.bind(this, 'phone')
              }}
            />
          </GridItem>
        </Grid>   
      </form> 
    );
  }
  renderBoxTable = () => {
    const { classes } = this.props;
    const { weight_input, boxes } = this.state;
    let rows = Array.from(boxes);
    rows.push({weight: "", addNew: true});

    let colSettings = [
      {
        key: 'weight',
        label: 'Weight',
        render: (val, key, row, index) => {
          if (row.addNew) {
            return (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CustomInput
                  key='weight'
                  labelText=''
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: weight_input,
                    className: classes.customInputNoLabel,
                    onChange: (e)=>{this.setState({weight_input: e.target.value})}
                  }}
                />
              </div>
            );
          } return (
            <CustomInput
              key='weight'
              labelText=''
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: val,
                className: classes.customInputNoLabel,
                onChange: (e)=>{
                  let new_val = e.target.value;
                  let new_weight_array = Array.from(this.state.boxes);
                  new_weight_array[index] = {weight: new_val};
                  this.setState({boxes: new_weight_array});
                }
              }}
            />
          );
        }
      },{
        key: 'action',
        label: 'Action',
        render: (val, key, row, index) => {
          if (row.addNew) {
            return (
              <IconButton size="small" onClick={()=>{
                if (!weight_input) {
                  this.context.alert("Weight should be a positive integer.");
                  return;
                }
                let new_weight_array = Array.from(this.state.boxes);
                new_weight_array.push({weight: weight_input});
                this.setState({boxes: new_weight_array, weight_input: ""});
              }} variant="fab" color="primary" title="add box" className={classes.button}>
                <Add/>
              </IconButton>
            );
          } else {
            return <IconButton size="small" onClick={()=>{
              this.context.confirm(
                "Are you sure to delete this Box?",
                ()=>{
                  let new_weight_array = Array.from(this.state.boxes);
                  new_weight_array.splice(index, 1);
                  this.setState({boxes: new_weight_array});
                }
              );

            }} variant="fab" color="secondary" title="delete box" className={classes.button}>
              <Delete/>
            </IconButton>;
          }
        }
      }
    ];

    return (
      <NewDataTable
        key="box_table"
        rows={rows}
        // rowHeight={'42'}
        tableTitle={"Boxes"}
        noPagination
        withPaper={true}
        columns={colSettings}
      />
    );
  }
  handleAddressChange = (key, e) => {
    let val = e.target.value;
    let newAddress = Object.assign({}, this.state.address);
    newAddress[key] = val;
    this.setState({address: newAddress});
  }

  initAutoComplete = () => {
    if (this.autocomplete) return;
    if (this.google_map_ref && window.google) {
      let autocomplete = new window.google.maps.places.Autocomplete(
        this.google_map_ref.current, {types: ['geocode']});

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      autocomplete.setFields(['address_component']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete.addListener('place_changed', this.onAddressSelected);
      this.autocomplete = autocomplete;
    }
  }
  geoLocate = () => {
    this.google_map_ref.current.setAttribute("autocomplete", "donot-touch-my-autocomplete");
    if (!window.google) {
      console.log("google map api not available!");
      // this.context.alert("google map api not available!");
      return;
    }
    let autoComplete = this.autocomplete;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        let geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        let circle = new window.google.maps.Circle(
          {center: geolocation, radius: position.coords.accuracy});
        autoComplete.setBounds(circle.getBounds());
      });
    }
  }
  onAddressSelected = () => {
    if (!this.autocomplete) return;
    let place = this.autocomplete.getPlace();

    let componentForm = {
      street_number: {
        type: 'short_name',
        key: "address1"
      },
      route: {
        type: 'long_name',
        key: "address1"
      },
      locality: {
        type: 'long_name',
        key: "city"
      },
      administrative_area_level_1: {
        type: 'short_name',
        key: "province"
      },
      // Todo the country is country code, can't set
      // country: {
      //   type: 'long_name',
      //   key: "country"
      // },
      postal_code: {
        type: 'short_name',
        key: "zip"
      }
    };

    let new_address = {};
    for (let address_component of place.address_components) {
      let address_type = address_component.types[0];
      if (componentForm[address_type]) {
        let val = address_component[componentForm[address_type]['type']];
        new_address[componentForm[address_type]['key']] = new_address[componentForm[address_type]['key']] ? (new_address[componentForm[address_type]['key']] + " " + val) : val;
      }
    }

    this.setState({address: Object.assign(this.state.address, new_address)});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      label_type,
      return_weight,
      method,
      saturday_delivery,
      signature_required,
      residential,
      carrier_account,
      address
    } = this.state;
    // if return, only one box weight
    let boxes = [{weight: return_weight}];

    let err = '';
    let weight_err = "";
    if (!label_type) err += 'Label type is required. \n';
    if (label_type === "shipping") {
      // if shipping, pass boxes
      boxes = Array.from(this.state.boxes);
      // if shipping, check method and Boxes
      if (!method) err += 'Method is required. \n';
      for (let box of boxes) {
        let box_weight = parseFloat(box.weight);
        if (!box_weight || box_weight <= 0) weight_err += "Box weight should be a positive number.\n";
      }   
      if (boxes.length <= 0) err += "Box is empty, please at lease add one box.\n";
    } else {
      if (!parseFloat(return_weight) || parseFloat(return_weight) <= 0) weight_err += "Box Weight should be a positive number. \n";
    }
    boxes = boxes.map((elem)=>{ return {weight: parseFloat(elem.weight)}});

    err += weight_err;
    err += utils.validateRequiredInput(address.first_name, "First Name");
    if (address.first_name.length > 15)  err += 'First Name is too long. \n';
    err += utils.validateRequiredInput(address.last_name, "Last Name");
    if (address.last_name.length > 15) err += 'Last Name is too long. \n';
    if (address.company.length > 25) err += 'Company is too long. \n';
    err += utils.validateRequiredInput(address.address1, "Address line 1");
    if (address.address1.length > 30) err += 'Address line 1 is too long. \n';
    if (address.address2.length > 30) err += 'Address line 2 is too long. \n';
    err += utils.validateRequiredInput(address.city, "City");
    err += utils.validateRequiredInput(address.province, "Province/State");
    err += utils.validateRequiredInput(address.zip, "Postal Code");
    if (err) {
      this.context.alert(err);
      return;
    }

    // this.props.onSubmit({
    //   type: 'label_client',
    //   shipment_detail: boxes, 
    //   address, 
    //   order_detail: {
    //     type: label_type, method, saturday_delivery, signature_required, residential, carrier_account,
    //   },
    //   order_id: [],
    // });
    this.props.onSubmit({
      type: label_type, method, saturday_delivery, signature_required, residential, carrier_account, boxes, address
    });
  }
}

export default withStyles(styles)(NewLabel);
