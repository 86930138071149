import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import {
  Drawer,
  Hidden,
  Divider
} from "@material-ui/core";
import { NestedMenu } from "components";
import sidebarStyle from "variables/styles/sidebarStyle.jsx";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

function formatMenuList(menuList, router, logout) {
  let result = [];
  for (let menu of menuList) {
    if (menu.redirect) continue;
    if (menu.hideMenu) continue;
    let new_menu = {};
    new_menu.key = menu.key;
    new_menu.content = menu.sidebarName;
    new_menu.path = menu.path;
    new_menu.icon = menu.icon;
    if (menu.submenu) {
      new_menu.sublist = formatMenuList(menu.submenu, router, logout);
    } else {
      if (menu.path === router.location.pathname || menu.path === "/" ) new_menu.active = true;

      new_menu.onClick = (e) => {
        e.preventDefault();
        if (menu.key === "logout") {
          logout();
        } else if (menu.asButton) {
          menu.onClick();
        } else {
          router.history.push(menu.path);
        }
      };
    }
    result.push(new_menu);
  }
  return result;
}

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, logo, image, routes, logout, match, location, history } = props;

  let menu_list = routes;
  menu_list = formatMenuList(menu_list, {match, location, history}, logout);
  let links = <NestedMenu menuList={menu_list}/>;

  // Todo change the brand, change the logo link to boxzooka main page
  let brand = (
      <a href="/">
        <div className={classes.logo}>
          <img src={logo} alt="logo" className={classes.logoImg} />
        </div>
      </a>
   
  );

  return (
    <React.Fragment>
      <Hidden key="pc" smDown>
        <Drawer
          anchor="left"
          variant="permanent"
          open={props.sideBarControl.pcOpen}
          onClose={props.handleDesktopDrawerToggle}
          classes={{
            paper: props.sideBarControl.pcOpen ? classes.drawerPaper : (classes.drawerPaperClose)
          }}
        >
          {brand}

          <Divider />

          <div style={{color: "white"}} className={classes.sidebarWrapper}>
            <PerfectScrollbar>
              {links}
            </PerfectScrollbar>
          </div>

          {image && <div style={{ backgroundPosition: "60% 60%", height: "100%", backgroundImage: "url(" + image + ")" }} className={classes.background}/>}
        </Drawer>
      </Hidden>
      <Hidden key="mobile" mdUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.sideBarControl.mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleMobileDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}

          <Divider />

          <div style={{color: "white"}} className={classes.sidebarWrapper}>
            <PerfectScrollbar>
              {links}
            </PerfectScrollbar>
          </div>

          {image && <div style={{ height: "100%", backgroundImage: "url(" + image + ")" }} className={classes.background}/>}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(withStyles(sidebarStyle)(Sidebar));
