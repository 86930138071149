import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import utils from 'utils/utils'
import moment from 'moment-timezone'
import {
  Typography,
  Grid,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow, Container
} from '@material-ui/core';
import DataContext from 'context/Data'
import {
  CardContainer,
  NewDataTable,
  Button,
  CustomInput,
  GridItem,
} from "components";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    width: '100%',
    margin: '0 0 .625rem 0',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    // paddingBottom: '.625rem',
  },
  button: {
    marginTop: theme.spacing(2)
  }
});

class ImportInbound extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.file_input = null;
    this.input_form = null;

    this.state = {
      loading: false,
      errors: [],
    };

    document.title = "Import PO";
  }

  render() {
    const { classes } = this.props;
    const { loading, errors } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    let errorsTable = null;
    if (errors && errors.length > 0) {
      let rows = errors.map((error, index)=>{
        return (
          <TableRow hover key={index}><TableCell>
            {error}
          </TableCell></TableRow>
        );
      });
      errorsTable = (
        <GridItem xs={12}>
          <CardContainer>
            <div>
              <Typography variant="h6">
                Result In the File You Uploaded:
              </Typography>

              <Table className='with-border'>
                <TableBody>
                  {rows}
                </TableBody>
              </Table>
            </div>
          </CardContainer>
        </GridItem>
      );
    }

    return (
      <Grid container spacing={2}>
        {loadingBar}
        {errorsTable}
        <GridItem xs={12}>
          <CardContainer>
            <div>
              <Typography variant="h6">
                PO Format Instruction:
              </Typography>
              <div style={{fontSize: "1.25rem", marginTop: ".5rem"}}>Below is the sample of a PO file. The table header with a yellow background is required. Those with white background are optional. You could click the "Download PO CSV Template" button to download the sample csv just for the header and use it import into the system. Please follow the format of each field. Sku can not be empty. Sku and UPC have to be unique value and 100% match your product catalog. The Estimated Delivery Date please use excel default format which is MM/DD/YYYY.</div>

            </div>
          </CardContainer>
        </GridItem>
        <GridItem xs={12}>
          <CardContainer>
            <div>
              <div className={classes.title} style={{height: '3rem'}}>
                <Typography variant="h6">
                  Sample Table:

                  <Button style={{float: 'right'}} onClick={()=>{
                    let file_name = "PO_SAMPLE";

                    let customer = localStorage.getItem('customer_id');
                    let warehouse = localStorage.getItem('warehouse_id');

                    file_name += '_' + warehouse + '_' + customer + '_';

                    let now = moment();
                    file_name += now.format('YYYY_MM_DD');
                    utils.exportTableToCsv('table-export', file_name, true);
                  }}>Download PO CSV Template</Button>
                </Typography>
              </div>

              {this.renderSampleTable()}
            </div>
          </CardContainer>
        </GridItem>
        <GridItem xs={12}>
          <CardContainer>
            <div>
              <div className={classes.title}>
                <Typography variant="h6">
                  PO Upload Instruction:
                </Typography>
              </div>
              <div>
                <form
                  onSubmit={this.submit}
                  className={classes.root}
                  ref={(elem)=>{this.input_form = elem}}
                >
                  <div style={{fontSize: "1.25rem", margin: ".5rem 0"}}>Use the file input below to upload the PO file you created and click "Upload PO" button to upload it. Error messages will appear on the screen if there are errors in the file you uploaded</div>

                  <CustomInput
                    labelText='Upload PO'
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                      className: classes.customInput,
                    }}
                    labelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      type: 'file',
                      name: 'inbound',
                      inputRef: (elem)=>{this.file_input = elem}
                    }}
                  />
                  <Button className={classes.button} type='submit'>Upload PO</Button>
                </form>
              </div>
            </div>
          </CardContainer>
        </GridItem>


      </Grid>
    );
  }

  // api call
  submit = (e) => {
    e.preventDefault();

    // let customer_name = localStorage.getItem('customer_name');
    // let warehouse_name = localStorage.getItem('warehouse_name');
    // let msg = "Are you sure to import PO for Warehouse: "+ warehouse_name +", Customer: "+ customer_name +"?";
    // let cfm = window.confirm(msg);
    // if (!cfm) return;

    let form_data = new FormData(this.input_form);
    let req = axios({
      method: 'post',
      dataType: 'json',
      processData: false,
      contentType: false,
      cache: false,
      data: form_data,
      url: `${utils.getBaseUrl('customer')}/import/po`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true, errors: []});
    req.then(this.submitSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: ()=>{this.file_input.value= ''}}));
  }
  submitSuccess = (resp) => {
    this.setState({loading: false});
    resp = resp.data;

    if (resp.Error) {
      // display error table and scroll to top
      this.file_input.value= '';
      this.setState({errors: resp.Error});
      utils.scrollToTop();
      //this.context.alert(resp.Error);
      return;
    }
  }
  // render func
  renderSampleTable = () => {
    let colSettings = [
      {
        key: 'po',
        label: 'PO',
        render: utils.tableCellOnDisplay,
        header_style: {backgroundColor: 'yellow'},
        header_class: 'yellow'
      }, {
        key: 'po_type',
        label: 'POType',
        render: utils.tableCellOnDisplay,
        header_style: {backgroundColor: 'yellow'},
        header_class: 'yellow'
      }, {
        key: 'sku',
        label: 'SKU',
        render: utils.tableCellOnDisplay,
        header_style: {backgroundColor: 'yellow'},
        header_class: 'yellow'
      }, {
        key: 'upc',
        label: 'UPC',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'quantity',
        label: 'Quantity',
        render: utils.tableCellOnDisplay,
        header_style: {backgroundColor: 'yellow'},
        header_class: 'yellow'
      }, {
        key: 'estimated_delivery_date',
        label: 'EstimatedDeliveryDate',
        render: utils.tableCellOnDisplay,
        header_style: {backgroundColor: 'yellow'},
        header_class: 'yellow',
      }, {
        key: 'carrier',
        label: 'Carrier',
        header_style: {backgroundColor: 'yellow'},
        header_class: 'yellow',
        render: utils.tableCellOnDisplay,
      }, {
        key: 'tracking',
        label: 'Tracking',
        header_style: {backgroundColor: 'yellow'},
        header_class: 'yellow',
        render: utils.tableCellOnDisplay,
      }
    ];

    let rows = [
      {
        po: 'PO1234567',
        sku: '000000000001',
        po_type: 'inbound',
        upc: '314159',
        quantity: '1',
        estimated_delivery_date: '12/25/2017',
        carrier: 'fedex',
        tracking: '123456789'
      },
      {
        po: 'PO3838438',
        sku: '111100000002',
        po_type: 'inbound',
        upc: '26535',
        quantity: '1',
        estimated_delivery_date: '12/25/2017',
        carrier: 'other',
        tracking: '123456789'
      },
      {
        po: 'PO2333',
        sku: '222244553667',
        upc: '897932',
        po_type: 'inbound',
        quantity: '1',
        estimated_delivery_date: '12/25/2017',
        carrier: 'ups',
        tracking: '123456789'
      },
      {
        po: 'PO78782',
        sku: '333344553667',
        po_type: 'inbound',
        upc: '384626',
        quantity: '1',
        estimated_delivery_date: '12/25/2017',
        carrier: 'usps',
        tracking: '123456789'
      },
      {
        po: 'PO65535',
        sku: '444444553667',
        po_type: 'inbound',
        upc: '834175',
        quantity: '1',
        estimated_delivery_date: '12/25/2017',
        carrier: 'truck',
        tracking: '123456789'
      },
    ];

    return (
      <NewDataTable
        rows={rows}
        id="table-export"
        noPagination
        columns={colSettings}
      />
    );
  }
}

export default withStyles(styles)(ImportInbound);
