import React, { Component } from 'react';
import PropTypes from 'prop-types'
import EditUserDialog from '../Dialogs/EditUser'
import axios from 'axios';
import { Grid, withStyles, IconButton } from '@material-ui/core';
import {
  Edit,
  DeleteForever
} from '@material-ui/icons'
import {
  NewDataTable,
  CardContainer,
  Button,
  GridItem,
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from "lodash";
import moment from 'moment';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class ClientManageUsers extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.role_name_map = {};

    this.state = {
      loading: false,
      dialog: '',
      users: null,
      active_user: null,
      role_options: [],
    };

    document.title = 'Manage User';
  }

  componentWillMount() {
    this.loadUsers();
  }

  createUser = (newUser) => {
    this.createUserOnce({
      user_role: newUser.user_role,
      username: newUser.username,
      email: newUser.email
    });
  }
  createUserAjax = (data) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/user`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data
    });

    this.setState({loading: true});
    req.then(this.createUserSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
  }
  createUserSuccess = (resp) => {
    this.createUserOnce = _.once(this.createUserAjax);
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (resp.data) {
      this.context.snackDisplay("Create user success.");
      window.location.reload();
    }
  }
  resetSubmitOnce = () => {this.createUserOnce = _.once(this.createUserAjax);} // reset the submit picking once after the ajax call returns
  createUserOnce = _.once(this.createUserAjax);
  loadUsers = () => {
    let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/user`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
    });

    this.setState({loading: true});
    req.then(this.loadUsersSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadUsersSuccess = (resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
      this.context.snackDisplay(resp.data.Error);
      // this.context.alert(resp.data.Error);
      return;
    }
    this.setState({users: resp.data});
    this.loadRoles();
  }
  loadRoles = () => {
    let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/userrole`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
    });

    this.setState({loading: true});
    req.then(this.loadRolesSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadRolesSuccess = (resp) => {
      this.setState({loading: false});

      // alert error if any
      if (resp.data.Error) {
        this.context.snackDisplay(resp.data.Error);
        // this.context.alert(resp.data.Error);
        return;
      }

      let role_name_map = {};
      for (let role of resp.data.role) {
          role_name_map[role.role_id] = role.role_name;
      }
      this.role_name_map = role_name_map;

      this.setState({role_options: resp.data.role});
  }
  deleteUser = (user_id) => {
    let req = axios({
        method: 'delete',
        url: `${utils.getBaseUrl('customer')}/user/${user_id}`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
    });

    this.setState({loading: true});
    req.then(this.deleteUserSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteUserSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (resp.data) {
      this.context.snackDisplay("Delete user success.");
      window.location.reload();
    }
  }
  editUser = (newUser) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/editbyadmin`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: newUser,
    });

    this.setState({loading: true});
    req.then(this.editUserSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  editUserSuccess = (resp) => {
    this.setState({loading: false});
    resp = resp.data;
    if (resp.Error) {
      this.context.alert(resp.Error);
      return;
    }
    
    if (resp) {
      this.context.snackDisplay("Edit user success.");
      window.location.reload();
    }
    this.setState({dialog: ''}); // close dialog
  }

  renderUserTable = () => {
    let colSettings = [
        {
            key: 'user_id',
            label: 'User Id',
            render: (val, key, row)=>{
              return (<Button onClick={()=>{
                this.setState({
                  dialog: 'edit',
                  active_user: row
                });
              }}>{val}</Button>);
            },
        },
        {
            key: 'username',
            label: 'Username',
        },
        {
          key: 'user_role',
          label: 'User Role',
          render: (role_id) => this.role_name_map[role_id] || role_id
        },
        {
            key: 'firstname',
            label: 'Fullname',
            render: (val, key, row)=>{
                let fullname = val ? val : '';
                if (fullname) fullname += ' ';

                fullname += row.lastname ? row.lastname : '';
                return fullname;
            },
        },
        {
            key: 'delete',
            label: 'DeleteUser',
            render: (val, key, rowData)=>{
              return (
                <IconButton size="small" color="secondary"  onClick={()=>{this.context.confirm(
                  "Are you sure you want to delete this user?",
                  this.deleteUser.bind(this, rowData.user_id)
                )}} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}}>
                    <DeleteForever/>
                </IconButton>
              );
            },
        }
    ];

    let rows = this.state.users || [];

    return (
        <NewDataTable
          rows={rows}
          rowHeight='auto'
          noPagination
          columns={colSettings}
        />
    );
  }
  renderDialog = () => {
    let defaultRole = '';
    if (this.state.role_options.length > 0) defaultRole = this.state.role_options[0].role_id;

    switch (this.state.dialog) {
      case 'create':
        return (
          <EditUserDialog
            display={true}
            closeDialog={()=>{this.setState({dialog: '', active_user: null})}}
            title='Create User'
            user={{user_role: '', email: '', username: ''}}
            role_options= {this.state.role_options}
            onSubmit={this.createUser}
          />
        );
      case 'edit':
        return (
          <EditUserDialog
            display={true}
            closeDialog={()=>{this.setState({dialog: '', active_user: null})}}
            title='Edit User'
            user={this.state.active_user}
            role_options= {this.state.role_options}
            onSubmit={this.editUser}
          />
        );
    }
  }

  exportUsers = () => {
    utils.exportArrayToCsv(this.state.users, [
      {
        key: 'user_id',
        label: 'User ID'
      },
      {
        key: 'username',
        label: 'Username'
      },
      {
        key: 'user_role',
        label: 'User Role',
        render: (role_id) => this.role_name_map[role_id] || role_id
      },
      {
        key: 'firstname',
        label: 'Fullname',
        render: (val,key,row) => {
          let fullname = val || '';
          if (fullname) fullname += ' ';
          fullname += row.lastname || '';
          return fullname;
        }
      },
      {
        key: 'task_status',
        label: 'Status'
      },
      {
        key: 'task',
        label: 'Task'
      }
    ], "users_"+moment().format('YYYY-MM-DD-hh_mm'));
  }

  render() {
      const { loading } = this.state;
      
      return (
        <Grid container spacing={2}>
            {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

            {this.renderDialog()}

            <GridItem xs={12} sm={12}>
              <CardContainer>
                <div>
                  <Button onClick={()=>{this.setState({dialog: 'create', active_user: null})}}>New User</Button>

                  {this.renderUserTable()}
                </div>
              </CardContainer>
            </GridItem>
          </Grid>
      );
  }
}
export default withStyles(styles)(ClientManageUsers);
