import React, {useState, useEffect, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button, KeyValueTable} from 'components'
import moment from 'moment';
import utils from 'utils/utils'
import Webcam from "react-webcam";

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  previewContainer: {
    width: '100%',
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  preview: {
    width: '90%',
  }
}))

export default function TakePicture(props) {
  const classes = useStyles();
  const {handleClose, handleSubmit} = props;

  // const [screenshot,setScreenshot] = useState(null);
  const webcamRef = React.useRef(null);
  const [facingMode, setfacingMode] = useState("environment");
  const [picture, setPicture] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  
  
  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(device => device.kind === 'videoinput');
        if (!hasCamera) {
          setErrorMessage('No camera available.');
        }
      } catch (error) {
        console.error('Error checking camera availability:', error);
        setErrorMessage('Error checking camera availability.');
      }
    };

    checkCameraAvailability();
  }, []);
  
  const capture = React.useCallback(
    () => {
      if (!webcamRef.current) return;

      const imageSrc = webcamRef.current.getScreenshot({
        // width: 1920, // Set width to desired resolution
        // height: 1080, // Set height to desired resolution
        width: 1280, // Set width to desired resolution
        height: 720, // Set height to desired resolution
      });
      // handleSubmit(imageSrc);
      setPicture(imageSrc);
    },
    [webcamRef]
  );

  const handleUserMediaError = (error) => {
    console.error('Error accessing camera:', error);
    setErrorMessage('Error accessing camera. Please check your camera settings and try again.');
  };

  function onSubmit() {
    handleSubmit(picture);
  }

  function switchCamera() {
    if (facingMode === 'user') setfacingMode("environment");
    else setfacingMode('user');
  }
  function renderCamera() {
    if (picture) 
    {
      // if picture is taken, render preview
      return (
        <div className={classes.previewContainer}>
          <img className={classes.preview} alt="Display error" src={picture}/>
        </div>
      );
    } else 
    {
      // if no picture taken, render camera
      return (
        <>
        {errorMessage && <h2>{errorMessage}</h2>}
        <Webcam
          audio={false}
          height={'90%'}
          width={'100%'}
          ref={webcamRef}
          screenshotFormat="image/png"
          videoConstraints={{
            // facingMode: "environment"
            // facingMode: { exact: "environment" }
            // width: '100%',
            // height: '100%',
            // width: 1920, // Set width to desired resolution
            // height: 1080, // Set height to desired resolution
            width: 1280, // Set width to desired resolution
            height: 720, // Set height to desired resolution
            facingMode
          }}
          onUserMediaError={handleUserMediaError}
        />
      </>
    );
    }
    
  }

  let title = picture ? "Use this picture?" : "Take Picture";
  
  // take picture and close
  let btnActions = [<Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>];
  if (picture)
  {
    btnActions.push(<Button key="retake" color="secondary" onClick={()=>{setPicture(null)}}>Retake</Button>);
    btnActions.push(<Button key="submit" onClick={onSubmit}>Use this one</Button>);
  } else 
  {
    // btnActions.push(<Button key="switchCamera" color="secondary" onClick={switchCamera}>Switch Camera</Button>);
    // only show the capture button when there is no error!
    if (!errorMessage) btnActions.push(<Button key="submit" onClick={capture}>Take picture</Button>);
  }
  

  return (
    <BasicDialog
      fullWidth
      fullScreen
      // maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderCamera()}
      actions={btnActions}
    />
  );
}