import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment-timezone'
import { Grid, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  DeleteForever
} from '@material-ui/icons'
import {
  NewDataTable,
  CardContainer,
  Button,
  ReversedIconButton,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  buttonPushRight: {
    marginLeft: 'auto',
  },
  cardContainer: {
    // height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    paddingBottom: '.5rem',
    width: '100%'
  },
});

class BatchCount extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,  
      batchList: [],
      // fast_move: 'ALL', 
      // category: 'ALL', 
      // level: 'ALL',
      // instock: 'ALL', 
      // quantity: '', 
      manual_batch_type: 'location', 
      keyword_input: ''
    };

    document.title = "Batch Count";
  }

  componentDidMount() {
    this.loadBatch();
  }

  // submitBatch = () => {
  //   const { fast_move, category, level, instock, quantity } = this.state;
  //   let err = '';
  //   if (instock != 'ALL' && (!quantity || quantity < 1)) {
  //       err += 'QTY should be an integer greater than 0';
  //   }

  //   if (err) {
  //     this.context.alert(err);
  //     return;
  //   }

  //   let req = axios({
  //     method: 'post',
  //     url: `${utils.getBaseUrl('customer')}/batchCount`,
  //     headers: {
  //       token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
  //     },
  //     data: {
  //       fast_move, category, level, instock, quantity
  //     },
  //   });

  //   this.setState({loading: true});
  //   req.then(this.submitBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  // }
  // submitBatchSuccess = (resp) => {
  //   this.setState({loading: false});
  //   if (resp.data.Error) {
  //     this.context.alert(resp.data.Error);
  //     return;
  //   }
  //   // reset page
  //   // if (resp.data) window.location.reload();
  //   // else {
  //   //   this.context.alert("No count get batched");
  //   // }
  //   if (resp.data) {
  //       this.loadBatch();
  //       this.setState({
  //           fast_move: 'ALL', 
  //           category: 'ALL', 
  //           level: 'ALL',
  //           instock: 'ALL', 
  //           quantity: '', 
  //       });
  //   }
  //   else {
  //     this.context.alert("No count get batched");
  //   }
  // }
  submitManualBatch = () => {
    const { manual_batch_type, keyword_input } = this.state;
    let err = '';
    let formatedInput = utils.formatString(keyword_input, {multiline: true}).split(',');

    if (!formatedInput || formatedInput.length == 0 || formatedInput[0] == '') err += 'Please enter valid SKU / Location list. \n';

    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/cyclecountbatch`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: manual_batch_type,
        keyword: formatedInput, // keyword array
      },
    });

    this.setState({loading: true});
    req.then(this.submitManualBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  submitManualBatchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (resp.data) {
        this.loadBatch();
        this.setState({
            manual_batch_type: 'location', 
            keyword_input: ''
        });
    }
    else {
      this.context.alert("No count get batched");
    }
  }
  loadBatch = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/cyclecountbatch`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadBatchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.setState({batchList: resp.data});
  }
  downloadBatch = (batch_id) => {
    let url = `${utils.getBaseUrl('customer')}/report/${batch_id}/${localStorage.getItem('customer_id')}/cycle_count_batch/empty/empty`;
    let w = window.open(url);
    if (!w) {
        this.context.alert('To enable download, please allow pop up window');
    } else {
      this.context.snackDisplay("Batch download success.");
    }
  }
  resetBatch = (batch_id) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/resetbatch/${batch_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.resetBatchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  resetBatchSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    // only reload batch list, don't refresh page
    // this.context.snackDisplay("Reset Batch success.", {hideCallback: ()=>{
    //   this.loadBatch();
    // }});
    this.context.snackDisplay("Reset Batch success.");
    this.loadBatch();
  }

  renderBatchTable = () => {
    const {classes} = this.props;
    const {batchList} = this.state;

    let rows = batchList;

    let columns = [
      {
        key: 'batch_id',
        label: 'Batch',
        contentNoWrap: true,
        render: (val, key, row, index)=>{
          return (
            <span>
              <Button onClick={()=>{this.downloadBatch(val); // call backend to download csv for this batch
              }}>{val}</Button>

               {/* <ReversedIconButton color="default" size="small" onClick={()=>{
                  this.context.confirm("Are you sure to reset this batch?", this.resetBatch.bind(this, val))
              }} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}} className={classes.button}>
                  <DeleteForever style={{color: 'white'}}/>
              </ReversedIconButton> */}
            </span>
          );
        },
      },
      {
        key: 'shortname',
        label: 'Client',
      },
      {
        key: 'instock',
        label: 'Instock',
      },
      {
        key: 'count',
        label: 'Count',

      },
      {
        key: 'last_count',
        label: 'Last Count',
        contentNoWrap: true,
        render: utils.localizeDate
      },
    ];

    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (batch, displayProps) => {
        let color = '';
        if (batch.count !== '' && batch.count !== null) { // if count started, display color, otherwise, white
          if (batch.count == batch.instock) color = 'green';
          else color = 'light-red';
        }
        
        if (!displayProps) displayProps = {className: ''};
        let classes = displayProps.className || '';
        classes = classes.split(' ');
        classes.push(color);
        displayProps.className = classes.join(' ');
        return displayProps;
      }
    };

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <NewDataTable
            rows={rows}
            // noPagination
            rowsPerPage={100}
            rowSettings={rowSettings}
            // rowHeight={50} 
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const {classes} = this.props; 
    const {loading, 
      // fast_move, category, level, instock, quantity, 
      manual_batch_type, keyword_input } = this.state;

    return (
      <Grid container spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {/* <GridItem xs={12} sm={6} md={6}>
          <CardContainer style={{minHeight: '266px'}}>
            <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Fast Move</InputLabel>
              <Select
               value={fast_move}
               onChange={(e) => { this.setState({ fast_move: e.target.value }) }}
              >
                <MenuItem value='ALL'>ALL</MenuItem>
                <MenuItem value='BS'>BS</MenuItem>
                <MenuItem value='MS'>MS</MenuItem>
                <MenuItem value='SS'>SS</MenuItem>
                <MenuItem value='NP'>NP</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Category</InputLabel>
              <Select
               value={category}
               onChange={(e) => { this.setState({ category: e.target.value }) }}
              >
                <MenuItem value='ALL'>ALL</MenuItem>
                <MenuItem value='PRODUCT'>Product</MenuItem>
                <MenuItem value='SUPPLY'>SUPPLY</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Location Level</InputLabel>
              <Select
               value={level}
               onChange={(e) => { this.setState({ level: e.target.value }) }}
              >
                <MenuItem value='ALL'>ALL</MenuItem>
                <MenuItem value='0'>0</MenuItem>
                <MenuItem value='1'>1</MenuItem>
                <MenuItem value='2'>2</MenuItem>
                <MenuItem value='RESERVE'>RESERVE</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Instock</InputLabel>
              <Select
               value={instock}
               onChange={(e) => { this.setState({ instock: e.target.value }) }}
              >
                <MenuItem value='ALL'>ALL</MenuItem>
                <MenuItem value='LESS THAN'>LESS THAN</MenuItem>
                <MenuItem value='GREATER THAN'>GREATER THAN</MenuItem>
              </Select>
            </FormControl>

            {
                (instock == 'LESS THAN' || instock == 'GREATER THAN') && <CustomInput
                    labelText='QTY'
                    labelProps={{shrink: true}}
                    formControlProps={{
                    fullWidth: true,
                    required: true,
                    className: classes.customInput
                    }}
                    inputProps={{
                    type: 'number',
                    inputProps: {
                      min: 1,
                      step: 1,
                    },
                    value: quantity,
                    onChange: (e)=>{this.setState({totalNumber: e.target.value})},
                    // ref: (el)=>{this.totalNumberInput = el},
                    // defaultValue: '',
                    }}
                />
            } 

            <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
                <Button onClick={this.submitBatch}>Submit</Button>
            </div>
          </CardContainer>
        </GridItem> */}

        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            {/* <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Manual Batch By</InputLabel>
              <Select
               value={manual_batch_type}
               onChange={(e) => { this.setState({ manual_batch_type: e.target.value }) }}
              >
                <MenuItem value='location'>Location</MenuItem>
                <MenuItem value='sku'>SKU</MenuItem>
              </Select>
            </FormControl> */}

            <CustomInput
              // labelText="SKU/Location List"
              labelText="Location List"
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                required: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                // inputRef: (el)=>{this.manualKeywordList = el},
                // defaultValue: "",
                value: keyword_input,
                onChange: (e) => { this.setState({ keyword_input: e.target.value }) },
                // placeholder: 'Enter SKU list or Location list based on your selection, do not mix. Use Linebreak to separate multiple input.',
                placeholder: 'Enter Location list (If want to count by sku, use exported locations from the Inventory Snapshot by SKU). Use Linebreak to separate multiple input.',
                multiline: true,
                rows: 3
              }}
            />
            
            <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
              <Button onClick={this.submitManualBatch}>Manual Batch</Button>
            </div>
          </CardContainer>
        </GridItem>
        
        {this.renderBatchTable()}
      </Grid>
    );
  }
}
export default withStyles(styles)(BatchCount);
