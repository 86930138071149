import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography,    
  Accordion,
  AccordionSummary,
  AccordionDetails, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  ExpandMore,
} from '@material-ui/icons';
import {
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class ShippingInfo extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    shipping_info: PropTypes.object.isRequired,
    onShippingInfoChange: PropTypes.func.isRequired,
    init: PropTypes.bool,
  };

  static defaultProps = {
    init: false
  };

  constructor(props) {
    super(props);

    this.input_form = null;
    this.file_input = null;

    let {
      shipping_type,
      pack_ship,
      return_type,
      return_method,
      shipping_address,
      return_address,
      short_ship,
      dm_location,
      packing_slip_type,
      return_header,
      return_instruction,
      agreement_type,
    } = props.shipping_info;
    if (!shipping_address) {
      shipping_address = {};
    }
    if (!return_address) {
        return_address = {};
    }


    this.state = {
      loading: false,
      edit: props.init,
      shipping_type: shipping_type ? shipping_type : 'warehouse',
      shipping_expand: false,
      pack_ship: (pack_ship == '1') ? '1' : '0',
      return_type: return_type ? return_type : 'warehouse',
      return_method : return_method ? return_method : '',
      return_expand: false,
      shipping_address: {
          firstname: shipping_address.firstname ? shipping_address.firstname : '',
          lastname: shipping_address.lastname ? shipping_address.lastname : '',
          company: shipping_address.company ? shipping_address.company : '',
          address1: shipping_address.address1 ? shipping_address.address1 : '',
          address2: shipping_address.address2 ? shipping_address.address2 : '',
          city: shipping_address.city ? shipping_address.city : '',
          province: shipping_address.province ? shipping_address.province : '',
          postal_code: shipping_address.postal_code ? shipping_address.postal_code : '',
          countrycode: shipping_address.countrycode ? shipping_address.countrycode : '',
          phone: shipping_address.phone ? shipping_address.phone : '',
          email: shipping_address.email ? shipping_address.email : ''
      },
      return_address: {
          firstname: return_address.firstname ? return_address.firstname : '',
          lastname: return_address.lastname ? return_address.lastname : '',
          company: return_address.company ? return_address.company : '',
          address1: return_address.address1 ? return_address.address1 : '',
          address2:return_address.address2 ? return_address.address2 : '',
          city: return_address.city ? return_address.city : '',
          province: return_address.province ? return_address.province : '',
          postal_code: return_address.postal_code ? return_address.postal_code : '',
          countrycode: return_address.countrycode ? return_address.countrycode : '',
          phone: return_address.phone ? return_address.phone : '',
          email: return_address.email ? return_address.email : ''
      },
      short_ship: short_ship ? short_ship : 'out_of_stock',
      dm_location: dm_location || '',
      packing_slip_type: packing_slip_type ? packing_slip_type : 'default',
      return_header: return_header ? return_header : '',
      return_instruction: return_instruction ? return_instruction : '',
      agreement_type: agreement_type ? agreement_type : 'regular',
    };
  }

  render() {
    const { classes, init } = this.props;
    const {
        shipping_type,
        shipping_expand,
        pack_ship,
        return_type,
        return_method,
        return_expand,
        shipping_address,
        return_address,
        short_ship,
        dm_location,
        packing_slip_type,
        return_header,
        return_instruction,
        agreement_type,
        edit
    } = this.state;

    let content = null;
    if (edit) {
      content = (
        <form ref={(elem)=>{this.input_form = elem}} onSubmit={this.submit} className={classes.root}>
            <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="shipping_type">Shipping Type</InputLabel>
                <Select
                    value={shipping_type}
                    onChange={(e)=>{
                        let val = e.target.value;
                        if (val === 'warehouse') {
                            this.setState({shipping_type: val, shipping_expand: false});
                        } else {
                            this.setState({shipping_type: val});
                        }
                    }}
                    inputProps={{
                        name: 'shipping_type',
                        id: 'shipping_type',
                    }}
                >
                    <MenuItem value='warehouse'>Warehouse</MenuItem>
                    <MenuItem value='customer'>Customer</MenuItem>
                </Select>
            </FormControl>

            <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="pack_ship">Auto Ship After Pack</InputLabel>
                <Select
                    value={pack_ship}
                    onChange={(e)=>{
                        let val = e.target.value;
                        this.setState({pack_ship: val});
                    }}
                    inputProps={{
                        name: 'pack_ship',
                        id: 'pack_ship',
                    }}
                >
                    <MenuItem value='0'>NO</MenuItem>
                    <MenuItem value='1'>YES</MenuItem>
                </Select>
            </FormControl>

            <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="return_type">Return Type</InputLabel>
                <Select
                    value={return_type}
                    onChange={(e)=>{
                        let val = e.target.value;
                        if (val === 'warehouse') {
                            this.setState({return_type: val, return_expand: false});
                        } else {
                            this.setState({return_type: val});
                        }
                    }}
                    inputProps={{
                        name: 'return_type',
                        id: 'return_type',
                    }}
                >
                    <MenuItem value='warehouse'>Warehouse</MenuItem>
                    <MenuItem value='customer'>Customer</MenuItem>
                </Select>
            </FormControl>

            <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="return_method">Return Method</InputLabel>
                <Select
                    value={return_method}
                    onChange={(e)=>{
                        let val = e.target.value;
                        this.setState({return_method: val});
                    }}
                    inputProps={{
                        name: 'return_method',
                        id: 'return_method',
                    }}
                >
                    <MenuItem value='FDX.GRD'>FedEx Ground</MenuItem>
                    <MenuItem value='SMARTPOST'>FedEx SmartPost</MenuItem>
                </Select>
            </FormControl>

            <Accordion style={{width: '100%'}} disabled={shipping_type === 'warehouse'} expanded={shipping_expand} onChange={this.handleExpand.bind(this, 'shipping')}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.heading}>Shipping Address</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <div style={{width: '100%'}}>
                        <CustomInput
                            labelText='First Name'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.firstname,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'firstname')}
                            }}
                        />

                        <CustomInput
                            labelText='Last Name'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.lastname,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'lastname')}
                            }}
                        />

                        <CustomInput
                            labelText='Company'
                            formControlProps={{
                                fullWidth: true,
                                required: shipping_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.company,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'company')}
                            }}
                        />

                        <CustomInput
                            labelText='Address Line 1'
                            formControlProps={{
                                fullWidth: true,
                                required: shipping_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.address1,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'address1')}
                            }}
                        />

                        <CustomInput
                            labelText='Address Line 2'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.address2,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'address2')}
                            }}
                        />

                        <CustomInput
                            labelText='City'
                            formControlProps={{
                                fullWidth: true,
                                required: shipping_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.city,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'city')}
                            }}
                        />

                        <CustomInput
                            labelText='Province'
                            formControlProps={{
                                fullWidth: true,
                                required: shipping_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.province,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'province')}
                            }}
                        />

                        <CustomInput
                            labelText='Postal Code'
                            formControlProps={{
                                fullWidth: true,
                                required: shipping_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.postal_code,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'postal_code')}
                            }}
                        />

                        <CustomInput
                            labelText='Country'
                            formControlProps={{
                                fullWidth: true,
                                required: shipping_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.countrycode,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'countrycode')}
                            }}
                        />

                        <CustomInput
                            labelText='Phone'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.phone,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'phone')}
                            }}
                        />

                        <CustomInput
                            labelText='Email'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: shipping_address.email,
                                onChange: (e)=>{this.handleShippingAddressChange(e.target.value, 'email')}
                            }}
                        />

                    </div>
                </AccordionDetails>
            </Accordion>

            <br/>

            <Accordion disabled={return_type === 'warehouse'} style={{marginTop: shipping_expand ? '0' : '1rem', marginBottom: '.5rem'}} expanded={return_expand} onChange={this.handleExpand.bind(this, 'return')}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography className={classes.heading}>Return Address</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <div style={{width: '100%'}}>
                        <CustomInput
                            labelText='First Name'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.firstname,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'firstname')}
                            }}
                        />

                        <CustomInput
                            labelText='Last Name'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.lastname,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'lastname')}
                            }}
                        />

                        <CustomInput
                            labelText='Company'
                            formControlProps={{
                                fullWidth: true,
                                required: return_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.company,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'company')}
                            }}
                        />

                        <CustomInput
                            labelText='Address Line 1'
                            formControlProps={{
                                fullWidth: true,
                                required: return_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.address1,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'address1')}
                            }}
                        />

                        <CustomInput
                            labelText='Address Line 2'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.address2,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'address2')}
                            }}
                        />

                        <CustomInput
                            labelText='City'
                            formControlProps={{
                                fullWidth: true,
                                required: return_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.city,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'city')}
                            }}
                        />

                        <CustomInput
                            labelText='Province'
                            formControlProps={{
                                fullWidth: true,
                                required: return_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.province,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'province')}
                            }}
                        />

                        <CustomInput
                            labelText='Postal Code'
                            formControlProps={{
                                fullWidth: true,
                                required: return_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.postal_code,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'postal_code')}
                            }}
                        />

                        <CustomInput
                            labelText='Country'
                            formControlProps={{
                                fullWidth: true,
                                required: return_type === 'customer',
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.countrycode,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'countrycode')}
                            }}
                        />

                        <CustomInput
                            labelText='Phone'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.phone,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'phone')}
                            }}
                        />

                        <CustomInput
                            labelText='Email'
                            formControlProps={{
                                fullWidth: true,
                                className: classes.customInput,
                            }}
                            labelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                value: return_address.email,
                                onChange: (e)=>{this.handleReturnAddressChange(e.target.value, 'email')}
                            }}
                        />

                    </div>
                </AccordionDetails>
            </Accordion>

            <CustomInput
                labelText='Upload Logo'
                formControlProps={{
                    fullWidth: true,
                    required: init,
                    className: classes.customInput,
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    type: 'file',
                    name: 'logo',
                    inputProps: {
                      id: "upload-logo-file"
                    },
                    inputRef: (elem)=>{this.file_input = elem}
                }}
            />

            <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="short_ship">Short Ship</InputLabel>
                <Select
                    value={short_ship}
                    onChange={(e)=>{this.setState({short_ship: e.target.value})}}
                    inputProps={{
                        name: 'short_ship',
                        id: 'short_ship',
                    }}
                >
                    <MenuItem value='out_of_stock'>Back Order</MenuItem>
                    <MenuItem value='back_order'>Short Ship</MenuItem>
                </Select>
            </FormControl>

            <CustomInput
                labelText='DM Location'
                formControlProps={{
                    fullWidth: true,
                    className: classes.customInput,
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    value: dm_location,
                    onChange:(e)=>{this.setState({dm_location: e.target.value})}
                }}
            />

            <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="packing_slip_type">Packing Slip Type</InputLabel>
                <Select
                    value={packing_slip_type}
                    onChange={(e)=>{this.setState({packing_slip_type: e.target.value})}}
                    inputProps={{
                        name: 'packing_slip_type',
                        id: 'packing_slip_type',
                    }}
                >
                    <MenuItem value='default'>Default</MenuItem>
                    <MenuItem value='returnlabel'>Return Label</MenuItem>
                    <MenuItem value='shippinglabel'>Shipping Label</MenuItem>
                    <MenuItem value='customization'>Customization</MenuItem>
                </Select>
            </FormControl>

            <CustomInput
                labelText='Return Header'
                formControlProps={{
                    fullWidth: true,
                    className: classes.customInput,
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    value: return_header,
                    onChange:(e)=>{this.setState({return_header: e.target.value})}
                }}
            />

            <CustomInput
                labelText='Return Instruction'
                formControlProps={{
                    fullWidth: true,
                    className: classes.customInput,
                }}
                labelProps={{
                    shrink: true,
                }}
                inputProps={{
                    multiline: true,
                    rows: 3,
                    value: return_instruction,
                    onChange:(e)=>{this.setState({return_instruction: e.target.value})}
                }}
            />

            <Button style={{marginTop: '1rem'}} type='submit'>Submit</Button>
        </form>
      );
    } else {
      content = this.renderShippingInfoTable();
    }

    return (
      <div>
        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Shipping Information
        </Typography>

        {content}
      </div>
    );
  }

  submit = (e) => {
    e.preventDefault();
    const { agreement_type, shipping_type, pack_ship, return_type, return_method,  shipping_address, return_address, short_ship, dm_location, packing_slip_type, return_header, return_instruction } = this.state;
    let file_document = document.getElementById('upload-logo-file');

    let shipping_info = {
        shipping_type,
        pack_ship,
        return_method,
        agreement_type,
        return_type,
        shipping_address,
        return_address,
        short_ship,
        dm_location,
        packing_slip_type,
        return_header,
        return_instruction,
        logo: file_document.files[0]
    };

    this.props.onShippingInfoChange(shipping_info, ()=>{this.setState({edit: false})});
  }

  renderShippingInfoTable = () => {
    const {
        shipping_type,
        pack_ship,
        return_type,
        return_method,
        shipping_address,
        return_address,
        short_ship,
        dm_location,
        packing_slip_type,
        return_header,
        return_instruction,
        agreement_type
    } = this.state;

    let propertyList = [
      {
        key: 'shipping_type',
        label: 'Shipping Type',
      },
      {
        key: 'pack_ship',
        label: 'Auto Ship After Pack',
        render: (val)=>{return parseInt(pack_ship) === 1  ? "YES" : 'NO'}
      },
    ];
    if (shipping_type === 'customer') propertyList.push({
      key: 'shipping_address',
      label: 'Shipping Type',
      render: ()=>this.formatAddress(shipping_address)
    });
    propertyList.push({
      key: 'return_type',
      label: 'Return Type',
      render: ()=>utils.convertShippingMethod(return_type)
    });
    propertyList.push({
      key: 'return_method',
      label: 'Return Method',
      render: ()=>utils.convertShippingMethod(return_type)
    });
    if (return_type === 'customer') {
      propertyList.push({
        key: 'return_address',
        label: 'Return Address',
        render: ()=>this.formatAddress(return_address)
      });
    }
    propertyList.push({
      key: 'short_ship',
      label: 'Short Ship',
      render: ()=>{
        return short_ship ? utils.convertShortShip(short_ship) : '';
      }
    });
    propertyList.push({
        key: 'dm_location',
        label: 'DM Location',
        render: ()=>{
          return dm_location || '';
        }
      });
    propertyList.push({
      key: 'packing_slip_type',
      label: 'Packing Slip Type',
    });
    propertyList.push({
      key: 'return_header',
      label: 'Return Header',
    });
    propertyList.push({
      key: 'return_instruction',
      label: 'Return Instruction',
    });

    return (
      <div>
        <Button style={{margin: '1rem 0'}} onClick={()=>{this.setState({edit: true})}}>Edit</Button>

        <CardContainer>
          <KeyValueTable
            dataObj={{
              shipping_type,
              pack_ship,
              return_type,
              return_method,
              shipping_address,
              return_address,
              short_ship,
              dm_location,
              packing_slip_type,
              return_header,
              return_instruction,
              agreement_type
          }}
            rowHeight='auto'
            propertyList={propertyList}
          />
        </CardContainer>
      </div>
    );
  }
  formatAddress = (address) => {
    let addr = "";

    let name = address.firstname;
    if (address.lastname) name += ' ' + address.firstname;

    if (name) {
        addr += name;
        addr += ", ";
    }
    if (address.company) {
        addr += address.company;
        addr += ", ";
    }
    if (address.address1) {
        addr += address.address1;
        addr += ", ";
    }
    if (address.address2) {
      addr += address.address2;
      addr += ", ";
  }
    if (address.city) {
        addr += address.city;
        addr += ", ";
    }
    if (address.province) {
        addr += address.province;
        addr += ", ";
    }
    if (address.postal_code) {
        addr += address.postal_code;
        addr += ", ";
    }
    if (address.countrycode) {
        addr += address.countrycode;
        addr += ", ";
    }
    if (address.phone) {
        addr += address.phone;
        addr += ", ";
    }
    if (address.email) {
      addr += address.email;
      addr += ", ";
    }
    if (addr[addr.length-1] === " ") addr = addr.slice(0, addr.length-2);
    return addr;
  }
  handleExpand = (panel) => {
    const { shipping_expand, return_expand } = this.state;
    switch (panel) {
        case 'shipping':
            this.setState({shipping_expand: !shipping_expand});
            break;
        case 'return':
            this.setState({return_expand: !return_expand});
            break;
    }
  }
  handleShippingAddressChange = (val, key) => {
    let addr = Object.assign({}, this.state.shipping_address);
    addr[key] = val;
    this.setState({shipping_address: addr});
  }
  handleReturnAddressChange = (val, key) => {
      let addr = Object.assign({}, this.state.return_address);
      addr[key] = val;
      this.setState({return_address: addr});
  }
}
export default withStyles(styles)(ShippingInfo);
