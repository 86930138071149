import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {
  PopupAlert
} from "components";
import _ from 'lodash'

export default function withAlert(WrappedComponent) {
  class ComponentWithAlert extends Component {
    static propTypes = {
      classes: PropTypes.object,
    };

    constructor(props) {
      super(props);

      this.state = {
        message_type: '',
        message_content: '',
        disableBackdropClick: false,
        disableEscapeKeyDown: false,
        confirm_callback: null,
        onCloseCallback: null,
      };
    }

    alert = (message, options) => {
      options = options || {};
      const {type = "Error", disableBackdropClick = false, disableEscapeKeyDown = false, onCloseCallback = null} = options;
      this.setState({message_content: message, message_type: type, disableBackdropClick, disableEscapeKeyDown, onCloseCallback});
    }
    confirm = (message, options) => {
      let confirm_callback = null;
      let onCloseCallback = null;
      if (_.isFunction(options)) confirm_callback = options;
      else if (options) {
        const {onConfirm = null, onClose = null} = options;
        confirm_callback = onConfirm;
        onCloseCallback = onClose;
      }
      
      this.setState({message_content: message, message_type: 'Confirm', confirm_callback, onCloseCallback});
    }

    render() {
      const {message_type, message_content, confirm_callback, disableBackdropClick, disableEscapeKeyDown, onCloseCallback} = this.state;
      return (
        <React.Fragment>
          <WrappedComponent alert={this.alert} confirm={this.confirm} {...this.props}/>
          { message_content && <PopupAlert
            open={true}
            header={message_type === 'Confirm' ? message_type : 'Message'}  // Note sometimes success message are passed back as Error, so show title as 'Message' for all Error message popup
            type={message_type}
            content={message_content}
            onSubmit={()=>{  
              this.setState({message_type: '', message_content: '', confirm_callback: null});
              if (confirm_callback) confirm_callback();
            }}
            onClose={(e, reason)=>{
              if (disableBackdropClick && reason == 'backdropClick') return;
              if (disableEscapeKeyDown && reason == 'escapeKeyDown') return;
              this.setState({message_type: '', message_content: '', confirm_callback: null});
              if (onCloseCallback) onCloseCallback();
            }}
          />}

        </React.Fragment>
      );
    }
  }

  ComponentWithAlert.displayName = `WithAlert(${getDisplayName(WrappedComponent)})`;

  return ComponentWithAlert;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
