import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import {
  DeleteForever
} from '@material-ui/icons'
import MuiAlert from '@material-ui/lab/Alert';
import {
  NewDataTable,
  KeyValueTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import {withTimer} from 'hoc'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoLabel: {
    marginTop: '0 !important'
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  formContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  submitButton: {
    marginTop: '.25rem',
    order: 2,
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class QuinceJunkScan extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    
    this.input_refs = {
      return_id: null,
    };

    this.state = {
      loading: false,
      type: '',
      package_list: [],
    };

    document.title = "Quince Junk Scan";
  }

  resetPage = () => {
    if (this.input_refs.return_id) {
      this.input_refs.return_id.value = '';
      this.input_refs.return_id.focus();
    }

    this.setState({
      loading: false,
      type: '',
      package_list: [],
    });
  }

  orderSearch = () => {
    let keyword = utils.formatString(this.input_refs.order_barcode.value);
    if (!keyword) return;

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/b2cPacking/${keyword}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.temp_data.cartons = [];
    this.temp_data.packing_finished = false;
    this.temp_data.scanning_box = false;
    this.setState({
      loading: true,
      items: null,
      orderDetail: null,
      comment: [],
      supply_list: [],
      box_list: [],
    });
    req.then(this.orderSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  orderSearchSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    let items = {};
    let skus = {};
    let upcs = {};
    let keys = [];
    for (let item of data.order) {
      let id =item.item_id;
      if (!item.quantity_input) item.quantity_input = 0;
      if (!item.packed) item.packed = 0;
      items[id] = item;

      // Remove leading zeros for Brayola issues
      // let sku = item.sku ? item.sku.replace(/^0+/, '') : '';
      let sku = item.sku;
      let upc = item.upc;
      if (!skus[sku]) {
          skus[sku] = id;
      }
      if (!upcs[upc]) {
          upcs[upc] = id;
      }
      keys.push(id);
    }

    let order_cartons = [];
    for (let carton_id in data.carton) order_cartons.push(data.carton[carton_id].item);
    this.temp_data.order_id = data.orderDetail.order_id;
    this.temp_data.cartons = order_cartons;
    this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.table_keys = keys;
    this.temp_data.un_focus = false;
    this.temp_data.show_insert = data.turnon;
    this.temp_data.supply_insert = data.supply;

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;


    this.setState({items: items, orderDetail: data.orderDetail, comment: data.comment});
  }

  addPackage = () => {
    let return_id = utils.formatString(this.input_refs.return_id.value);
    if (!return_id) return;
    let newPackageList = Array.from(this.state.package_list);
    newPackageList.push({return_id: return_id});
    this.setState({package_list: newPackageList});
    this.input_refs.return_id.value = '';
    this.input_refs.return_id.focus();
  }
  packageScan = _.debounce(this.addPackage, 150)
  submitPackage = ()=> {
    const {type, package_list} = this.state;
    let err = '';

    if (!type) err += 'Package type is required. \n';
    if (package_list.length == 0) err += 'No Package scanned. \n';

    if (err) {
        this.context.alert(err);
        return;
    }

    let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/junkscan`,
        headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
            type,
            return_id: package_list.map((elem)=>elem.return_id)
        }
    });

    this.setState({loading: true});

    req.then(this.submitPackageSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  submitPackageSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    
    this.resetPage();
  }
 
  renderPackageTable = () => {
    const {classes} = this.props;
    const {package_list} = this.state;

    let columns = [
      {
        key: 'return_id',
        label: 'Return Id',
        contentNoWrap: true,
      }, {
        key: 'delete',
        label: 'Delete',
        render: (sku, key, product, index)=>{
          return (
            <span>
             <IconButton color="secondary" size="small" onClick={()=>{
                this.context.confirm('Are you sure to delete this package?', ()=>{
                    let newList = Array.from(package_list);
                    newList.splice(index, 1);
                    this.setState({package_list: newList});
                    setTimeout(() => {
                        this.input_refs.return_id.focus();    
                    }, 0);
                });
               }} variant="fab" aria-label="Delete">
                <DeleteForever/>
              </IconButton>
            </span>
          );
        },
      }
    ];

    return (
      <GridItem xs={12} sm={12}>
        <CardContainer>
          <div className={this.props.classes.root}>
            <div style={{width: '100%'}} className={classes.title} >
                <Typography variant="h6">
                    Scan Packages
                </Typography>
            </div>

            <div className={classes.formContainer}>
                <CustomInput
                    labelText='Return Id'
                    formControlProps={{
                        className: classes.customInput
                    }}
                    labelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        placeholder: "Scan to add package",
                        onKeyUp: this.packageScan,
                        disabled: this.state.loading,
                        inputRef: elem => this.input_refs.return_id = elem
                    }}
                />


                <Button className={classes.submitButton} onClick={this.submitPackage}>Submit</Button>
            </div>

            <NewDataTable
              rows={package_list}
              columns={columns}
              noPagination
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;
    const {type} = this.state;
    
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
      
        <GridItem xs={12} sm={4}>
          <CardContainer>
            <FormControl fullWidth required className={classes.customInput}>
                <InputLabel shrink>Package Type</InputLabel>
                <Select
                    value={type}
                    disabled={type !== ''}
                    onChange={(e)=>{
                        this.setState({type: e.target.value})
                        setTimeout(() => {
                            this.input_refs.return_id.focus();    
                        }, 0);
                    }}
                >
                    <MenuItem value='NON_QUINCE_PRODUCT'>NON_QUINCE_PRODUCT</MenuItem>
                    <MenuItem value='NON_QUINCE_JUNK'>NON_QUINCE_JUNK</MenuItem>
                </Select>
            </FormControl>

          </CardContainer>
        </GridItem>

        {this.renderPackageTable()}
      </Grid>
    );
  }
}
export default withTimer(withStyles(styles)(QuinceJunkScan));
