import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import {primaryColor, warningColor, dangerColor, successColor, infoColor, roseColor, grayColor, } from 'variables/styles'
import cx from "classnames";

const useStyles = makeStyles({
  default_button: {
    // borderRadius: "1.5rem",
    // fontSize: "1.25rem",
    fontSize: "1.125rem",
    textTransform: "none",
    whiteSpace: "nowrap",
    // fontWeight: '300',
    fontWeight: 'normal',
    // Todo add this if font is Neuton
    // paddingTop: 0,
    "&:hover": {
      boxShadow: "0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)"
    },
  },
  defaultColor: {
    // backgroundColor: "#F17022",
    backgroundColor: "#8E8E8E",
    color: "white",
    "&:hover": {
      // backgroundColor: "#F17022",
      backgroundColor: "#7D7D7D",
      color: "white",
      boxShadow: "0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)"
    },
  },
  cancel: {
    backgroundColor: '#E0E0E0',
    color: "white",
    "&:hover": {
      backgroundColor: "#D0D0D0",
    },
  },
  warning: {
    backgroundColor: warningColor,
    color: "white",
    "&:hover": {
      backgroundColor: "#ef8800",
    },
  },
  danger: {
    backgroundColor: dangerColor,
    color: "white",
    "&:hover": {
      backgroundColor: "#ef8800",
    },
  },
  success: {
    backgroundColor: successColor,
    color: "white",
    "&:hover": {
      backgroundColor: "#3c9f40",
    },
  },
  info: {
    backgroundColor: infoColor,
    color: "white",
    "&:hover": {
      backgroundColor: "#009cb1",
    },
  },
});

function RegularButton({ ...props }) {
  const {
    classes,
    className,
    round,
    children,
    fullWidth,
    disabled,
    variant,
    color,
    ...rest
  } = props;
  let default_classes = useStyles();
  let button_class  = default_classes.default_button + " " + (className ? className : "");
  // no orange button color
  if (!color || color === 'bxzDefault') button_class += " " + default_classes.defaultColor;
  else {
    let colorMap = {'warning': true, 'danger': true, 'success': true, 'info': true};
    if (colorMap[color]) {
      button_class += " " + default_classes[color];
    }
  }
  return (
    <Button {...rest} classes={classes} color={color === 'bxzDefault' ? 'default' : color} className={button_class} variant={variant ? variant : 'contained'}>
      {children}
    </Button>
  );
}

RegularButton.propTypes = {
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RegularButton;
