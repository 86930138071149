import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment';
import { Prompt } from 'react-router'
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import {
  KeyValueTable,
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  ReversedIconButton 
} from 'components';
import DataContext from 'context/Data'
import {
  ExposureNeg1,
  Add
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';
import AddComment from 'views/Dialogs/AddComment'
import EditReceivingItem from 'views/Dialogs/Receiving/EditReceivingItem'
import {withTimer} from 'hoc'

// const special_clients = {120: true, 201: true};
const special_clients = {120: true};

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class Receiving extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    timer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.input_refs = {
      sku: null,
    };
    this.temp_data = {
      close_confirm: false,
      sku_map: {},
      upc_map: {},
      barcode_map: {},
      key_array: [],
      top_item: '',
      older_than_2_days: false,
      eidt_item: null, // the PO lineitem to be edit
    };

    this.state = {
      loading: false,
      dialog: '',
      // Return PO Select
      po_list: [],
      selected_po: null,
      // PO Item List
      item_list: null,
      // PO Input
      receiving_type: '', 
      container_num: 0,
      notes: '', 
      // PO Comments
      comment: null,
      po_input_value: '',
    };

    document.title = "Receiving";
  }

  componentWillMount() {
    this.loadPOList();
  }
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload);
    this.props.timer.start();
  }
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload);
  }
  handleUnload = (e) => {
    if (!this.hasUnsaved()) return '';
    var message = "\o/";
    message = "Changes you made may not be saved.";
    (e || window.event).returnValue = message; //Gecko + IE
    return message;
  }
  hasUnsaved = () => {
    return this.temp_data.close_confirm;
  }

  resetPage = () => {
    this.temp_data = {
      close_confirm: false,
      sku_map: {},
      upc_map: {},
      key_array: [],
      top_item: '',
      older_than_2_days: false,
      po_input_value: '',
      eidt_item: null, // the PO lineitem to be edit
    };

    this.setState({
      dialog: '',
      selected_po: null,
      // PO Item List
      item_list: null,
      // PO Input
      receiving_type: '', 
      container_num: 0,
      notes: '', 
      // PO Comments
      po_input_value: '',
      comment: null,
    });
  }

  __getFieldRequired = () => {
    let current_customer = parseInt(localStorage.getItem('customer_id'));
    let lotRequired = false;
    let expirationDateRequired = false;
    // if (current_customer === 131 || current_customer === 190) lotRequired = true;
    // ON 2021-11-01, turn off lot required for 131
    if (current_customer === 190) lotRequired = true;
    if (current_customer === 158) {
        lotRequired = true;
        expirationDateRequired = true;
    }
    // for Oliver's and Bottles and Burps
    // check expiration_date
    if (current_customer === 127 || current_customer === 146) {
        expirationDateRequired = true;
    }
    return {
        lot: lotRequired,
        expiration_date: expirationDateRequired
    }
  }

  handleSkuScanned = (e) => {
    let scanned_val = utils.formatString(this.input_refs.sku?.value); 
    if (!scanned_val) return;
    if (this.temp_data.barcode_map[scanned_val]) {
      scanned_val = this.temp_data.barcode_map[scanned_val];
    } else  if (this.temp_data.barcode_map[scanned_val.toUpperCase()]) { // also check uppercase for barcode map
      scanned_val = this.temp_data.barcode_map[scanned_val.toUpperCase()];
    }
    // Use upper case to match SKU and UPC
    scanned_val = scanned_val.toUpperCase();

    
    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]) {
      matched_id = this.temp_data.sku_map[scanned_val]; // sku matched
      if (Array.isArray(matched_id)) {
        // this.context.alert("This SKU:"+ scanned_val +" is duplicate in this PO, please tell the manager!");
        // return;
        matched_id = matched_id[0]; // allow duplicate
      }
    } else if (this.temp_data.upc_map[scanned_val]) {
      matched_id = this.temp_data.upc_map[scanned_val]; // upc matched
      if (Array.isArray(matched_id)) {
        // this.context.alert("This UPC:"+ scanned_val +" is duplicate in this PO, please tell the manager!");
        // return;
        matched_id = matched_id[0]; // allow duplicate
      }
    } else {
      this.context.alert(`The scanned item ${scanned_val} is not in this PO.`);
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }  
      return;
    }
    let items = Object.assign({}, this.state.item_list);
    let newItem = Object.assign({}, items[matched_id]);
    let newQty = 1 + parseInt(newItem.quantity_input);
    newItem['quantity_input'] = newQty;
    newItem['received'] = parseInt(newItem['received']) + 1;
    items[matched_id] = newItem;
    // Move the scanned row to top
    this.temp_data.top_item = matched_id;

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
    this.setState({item_list: items});
  }
  handleScan = _.debounce(this.handleSkuScanned, 150);

  loadPOList = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/receiving`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.loadPOListSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadPOListSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let options = resp.data.Receiving;
    if (!options || !Array.isArray(options)) return;
    this.setState({po_list: options});
  }
  loadPOSingle = (selected_po) => {
    if (!selected_po) {
      // if selection cleared
      // confirm before makeing the change
      this.setState({selected_po: null, item_list:  null, receiving_type: '',  container_num: 0, notes: '', comment: null, });
      this.temp_data = {
        close_confirm: false,
        sku_map: {},
        upc_map: {},
        key_array: [],
        top_item: '',
        older_than_2_days: false,
        eidt_item: null, // the PO lineitem to be edit
      };
      return;
    }

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/receiving/${selected_po.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.loadPOSingleSuccess.bind(this, selected_po)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadPOSingleSuccess = (selected_po, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    let item_list = {};
    let key_array = [];
    let skus = {};
    let upcs = {};
    let older_than_2_days = false;

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;

    for (let receiving_item of resp.data.Receiving) {
      let single_item = Object.assign({}, receiving_item);
      if (!single_item["polybagged_quantity"]) single_item["polybagged_quantity"] = 0;
      if (!single_item["hangtagged_quantity"]) single_item["hangtagged_quantity"] = 0;
      if (!single_item["lot"]) single_item["lot"] = '';
      if (!single_item["expiration_date"]) single_item["expiration_date"] = '';
      if (!single_item['barcoded_quantity']) single_item["barcoded_quantity"] = 0;
      if (!single_item['temp_location']) single_item["temp_location"] = '';
      if (!single_item['temp_quantity']) single_item["temp_quantity"] = 0;
      single_item["quantity_input"] = 0;
      if (single_item.arrival_dt && moment().subtract(2, "days").isAfter(single_item.arrival_dt, 'day')) {
        older_than_2_days = true;
      }

      // let sku = single_item.sku;
      // let upc = single_item.upc;
      // Save SKU and UPC to uppercase
      let sku = single_item.sku ? ('' + single_item.sku).toUpperCase().trim() : "";
      let upc = single_item.upc ? ('' + single_item.upc).toUpperCase().trim() : "";

      if (!skus[sku]) skus[sku] = single_item.item_id;
      else {
        // duplicate sku
        let duplicate_list = [];
        duplicate_list.push(single_item.item_id);
        if (Array.isArray(skus[sku])) duplicate_list = skus[sku].concat(duplicate_list);
        else duplicate_list.push(skus[sku]);
        skus[sku] = duplicate_list;
      } 
      if (!upcs[upc]) upcs[upc] = single_item.item_id;
      else {
        // duplicate upc
        let duplicate_list = [];
        duplicate_list.push(single_item.item_id);
        if (Array.isArray(upcs[upc])) duplicate_list = upcs[upc].concat(duplicate_list);
        else duplicate_list.push(upcs[upc]);
        upcs[upc] = duplicate_list;
      } 

      // handle same item apperare multiple times with diff lot code and po_item_id
      // if this item already exist
      if (item_list[single_item.item_id]) {
        // dupilcate item, merge quantity, push lot to array
        let exist_item = item_list[single_item.item_id];
        if (!exist_item.lot_map) exist_item.lot_map = {}; // if prev no lot, init lot map;
        exist_item.quantity += single_item.quantity; // merge quantity of different lots for same sku
        exist_item.received += single_item.received; // merge quantity of different lots for same sku
        if (single_item.lotcode) exist_item.lot_map[single_item.lotcode] = {quantity: single_item.quantity, po_item_id: single_item.po_item_id}; // add new lot number to lot lot quantity map
        item_list[single_item.item_id] = exist_item; // update the merged item, maybe not necessary to do this, object already updated
      } else {
        // new item, add key
        if (single_item.lotcode) single_item.lot_map = {[single_item.lotcode]: {quantity: single_item.quantity, po_item_id: single_item.po_item_id}}; // map lot number to quantity to be received for this lot 
        key_array.push(single_item.item_id);
        item_list[single_item.item_id] = single_item;
      }
    }

    this.temp_data.close_confirm = true;
    this.temp_data.sku_map = skus;
    this.temp_data.upc_map = upcs;
    this.temp_data.key_array = key_array;
    this.temp_data.older_than_2_days = older_than_2_days;
    this.setState({selected_po: selected_po, item_list, comment: resp.data.comment, receiving_type: '', notes: '', container_num: 0});
  }
  addComment = (newComment) => {
    if (newComment.type === "text") {
      this.addCommentText(newComment.text);
    } else {
      this.uploadPdf(newComment.formData);
    }
  }
  addCommentText = (comment_text) => {
    let po_id = this.state.selected_po.po_id;
    let req_data = {
      target_id: po_id,
      type: "po",
      comment: comment_text
    };

    let req = axios({
      method: 'POST',
      url: `${utils.getBaseUrl('customer')}/comment`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: req_data,
    });

    this.setState({ loading: true });
    req.then(this.addCommentTextSuccess.bind(this, req_data)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  addCommentTextSuccess = (req_data, resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      this.context.snackDisplay("Add order comment success.");

      let newComments = Array.from(this.state.comment);
      newComments.push({
        comment: req_data.comment,
        date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        username: localStorage.getItem("username"),
        fullname: "",
      });
      this.setState({comment: newComments});
      this.setState({ dialog: "" });
    } else {
      this.context.alert("Add order comment error!");
    }
  }
  // upload comment pdf
  uploadPdf = (form_data) => {
    let po_id = this.state.selected_po.po_id;
    form_data.append('target_id', po_id);
    form_data.append('type', "po");

    let req = axios({
      method: 'post',
      dataType: 'json',
      processData: false,
      contentType: false,
      cache: false,
      url: `${utils.getBaseUrl('customer')}/import/document`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: form_data,
    });

    this.setState({ loading: true, errors: [] });
    req.then(this.uploadPdfSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  uploadPdfSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      this.context.snackDisplay("Add order comment success.");

      this.setState({comment: this.state.comment.concat([{
        comment: resp.data,
        date: moment.utc(),
        username: localStorage.getItem("username"),
        fullname: "",
      }])});
      this.setState({ dialog: "" });
    } else {
      this.context.alert("Upload PDF comment error!");
    }
  }
  submitReceiving = (e) => {
    e.preventDefault();
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }

    let err = '';
    let warning = '';
    let { item_list, notes, receiving_type, container_num} = this.state;
    if (!receiving_type) err += 'Receiving type is required. \n';
    let receiving_items = [];
    let scanned_total = 0;

    for (let item_id in item_list) {
      let po_item = Object.assign({}, item_list[item_id]);
      if (parseInt(po_item.quantity_input) === 0 || !po_item.quantity_input) continue;  // skip item if quantity input is 0
      po_item['unit_case'] = po_item['unit_case'] || 0;
      po_item['weight'] = po_item['weight'] || 0;
      scanned_total += parseInt(po_item.quantity_input); // sum up scanned quantity

      if (this.__getFieldRequired().lot && !po_item.lot) err += 'Lot is required for this customer, but item SKU '+po_item.sku+' missing lot field.\n';
      if (this.__getFieldRequired().expiration_date && !po_item.expiration_date) err += 'Expiration Date is required for this customer, but item SKU '+po_item.sku+' missing Expiration Date field.\n';

      if (receiving_type === "case") {
        if (!po_item['unit_case']) {
          err += "SKU: " + po_item.sku + ", UnitByCase is required and should be greater than 0.\n";
        }
      }

      if (parseInt(localStorage.getItem('customer_id')) == 131 && po_item.lot_map && !_.isEmpty(po_item.lot_map)) {
        // if this po item has lot map, means needs to verify the lot code input value for received item
        if (po_item.lot_map[po_item.lot]) {
          // input lot has a match, set the po_item_id for submit
          po_item.po_item_id = po_item.lot_map[po_item.lot].po_item_id;
          if (po_item.quantity_input > po_item.lot_map[po_item.lot].quantity) {
            warning += `PO Item SKU: ${po_item.sku}, with lot: ${po_item.lot}, only need ${po_item.lot_map[po_item.lot].quantity}, but you received ${po_item.quantity_input}. Are you sure to submit? \n`;
          }
        } else {
          // input lot doesn't match any lot in the map, report error
          err += "SKU: " + po_item.sku + ", the lot number you entered does not match any lot number in the system.\n";
        }
      }

      po_item.quantity_received = po_item.received;
      po_item.barcoded = po_item.barcoded_quantity;
      po_item.polybagged = po_item.polybagged_quantity;
      po_item.hangtagged_quantity = po_item.hangtagged_quantity;

      receiving_items.push(po_item);
    }
    if (this.temp_data.older_than_2_days && (!notes || !notes.trim()) ) err += "This PO arrived more than 2 days ago, Notes is required for it.\n";
    if (receiving_items.length === 0 || scanned_total === 0) err += 'No items received. \n';
    if (receiving_type !== "unit" && container_num == 0) err += `The ${receiving_type} number should be greater than 0.`;
    if (receiving_type !== "unit" && !/^[0-9]+$/.test(container_num))
    {
      err += `The ${receiving_type} number should be integer only.`;
    }
    if (err) this.context.alert(err);
    else {
      if (warning) {
        this.context.confirm(warning, ()=> {
          this.submitReceivingOnce({
            notes: notes,
            receiving_type: receiving_type,
            container_num: container_num,
            scanned_total: scanned_total,
            item: JSON.stringify(receiving_items)
          });
        });
      } else {
        this.submitReceivingOnce({
          notes: notes,
          receiving_type: receiving_type,
          container_num: container_num,
          scanned_total: scanned_total,
          item: JSON.stringify(receiving_items)
        });
      }
    }
  }
  submitReceivingAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/receiving/${this.state.selected_po.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitReceivingSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    this.setState({loading: true})
  }
  resetSubmitOnce = () => {this.submitReceivingOnce = _.once(this.submitReceivingAJax);} // reset the submit picking once after the ajax call returns
  submitReceivingOnce = _.once(this.submitReceivingAJax) 
  submitReceivingSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;

    switch (true) {
      case typeof result == 'string':
        utils.printPage(result);
        break;
      case Array.isArray(result):
        result.forEach((elem)=>utils.printPage(elem));
        break;
      case typeof result == 'object':
        for (let key in result) {
          utils.printPage(result[key]);
        }
        break;
    }

    // this.context.snackDisplay("Submit receiving success.", {hideCallback: ()=>{
    //   this.temp_data.close_confirm = false; // after submit, allow refresh, don't confirm
    //   window.location.reload();
    // }});

    this.context.snackDisplay("Submit receiving success.");
    this.temp_data.close_confirm = false;
    // window.location.reload();
    this.resetPage();
    // this.loadPOList();
  }

  renderDialog = () => {
    switch (this.state.dialog)  {
      case 'editLineItem':
        return <EditReceivingItem
            handleClose={()=>{this.setState({dialog: ''})}}
            handleSubmit={(newItem)=>{
                let newItems = Object.assign({}, this.state.item_list);
                newItems[newItem.item_id] = newItem;
                this.setState({dialog: '', item_list: newItems});
            }}
            lineItem={this.temp_data.eidt_item}
            title='Receiving LineItem'
        />
        break;
      case 'addComment':
        return (
          <AddComment 
            handleSubmit={(newComment)=>{this.addComment(newComment)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      default:
        return null;
    }
  }
  renderReceivingDetail = () => {
    const { classes } = this.props;
    const {item_list, comment, selected_po} = this.state;
    if (!item_list || !selected_po) return null;

    let ex_total = 0;
    let re_total = 0;
    let scan_total = 0;
    let tracking = '';
    for (let id in item_list) {
        let item = item_list[id];
        // let hold = item.hold_quantity ? item.hold_quantity : 0;
        // hold_total += parseInt(hold);
        ex_total += parseInt(item.quantity);
        re_total += parseInt(item.received);
        scan_total += parseInt(item.quantity_input);
        tracking = item.tracking;
    }
    let disc_total = re_total - ex_total;

    let commentRows = comment ? Array.from(comment) : [];
    commentRows.push({
      username: localStorage.getItem('username'),
      add_new: true,
      comment: '',
    });

    return (
        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            <div className={classes.title} style={{padding: "1rem"}}>
              <Typography variant="h6">
                  Receiving Stat
              </Typography>
            </div>

            <KeyValueTable
              dataObj={{
                tracking, ex_total, re_total, scan_total, disc_total
              }}
              rowHeight='auto'
              propertyList={[
                {
                  key: 'tracking',
                  label: 'Tracking Number',
                },
                {
                  key: 'ex_total',
                  label: 'Expected total',
                },
                {
                  key: 're_total',
                  label: 'Received total',
                },
                {
                  key: 'scan_total',
                  label: 'Scanned total',
                },
                {
                  key: 'disc_total',
                  label: 'DISC total',
                },
              ]}
            />

            <div className={classes.title} style={{padding: "1rem"}}>
              <Typography variant="h6">
                  Comments
              </Typography>
            </div>


            <NewDataTable
              noPagination
              // rowHeight={38}
              rows={commentRows}
              maxHeight={400}
              columns={[
                {
                  key: 'username',
                  label: 'User',
                },{
                  key: 'comment',
                  label: 'Comment',
                  render: (val, key, row) => {
                    if (row.add_new) {
                      return (
                        <IconButton size="small" onClick={()=>{this.setState({dialog: 'addComment'})}} style={{backgroundColor: "#3f51b5", marginLeft: ".5rem"}} variant="fab" aria-label="Add">
                          <Add style={{color: 'white'}}/>
                        </IconButton>
                      );
                    } else {
                      let content = val ? val : '';
                      if (content.includes("https://")) {
                        let btn_text = "See PDF";
                        if (content.includes(".pdf")) 
                        {
                          btn_text = "See PDF";
                        } else 
                        {
                          btn_text = "See Image";
                        }
                        let file_name = content.split("import/");
                        if (file_name.length > 1)
                        {
                          btn_text = utils.removeFilePrefix(file_name[1]);
                        } 

                        return (<Button onClick={()=>{
                            let newTab = window.open(content);
                            if (!newTab) this.context.alert("Unable to open new tab, please change browser settings to allow pop-up.");
                        }}>{btn_text}</Button>);
                      }
                      return content;
                    }
                  }
                }, {
                  key: 'date',
                  label: 'Date/Time',
                  render: utils.localizeDate
                }
              ]}
            />
          </CardContainer>
        </GridItem>
    );
  }
  renderReceivingItems = () => {
    const { classes } = this.props;
    const { item_list, receiving_type } = this.state;

    if (!item_list || !this.temp_data.key_array) return null;
    let rows = [];
    
    // top item
    if (this.temp_data.top_item) rows.push(item_list[this.temp_data.top_item]);

    for (let id of this.temp_data.key_array) {
      if (this.temp_data.top_item === id) continue;
      rows.push(item_list[id]);
    }
    if (rows.length === 0) return null;

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
        render: (val, key, row) => {
          return (
            <Button color='default' onClick={()=>{
              this.temp_data.eidt_item = row;
              this.setState({dialog: 'editLineItem'})}}><span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span></Button>
          );
        }
      },
      {
        key: 'upc',
        label: 'UPC',
        contentNoWrap: true,
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val || ''}</span>
        }
      },
      {
        key: 'temp_location',
        label: 'Tote#',
        render: (val)=>{
          return (<span style={{fontWeight: "bold"}}>{val}</span>);
        },
      },
      {
        key: 'image',
        label: 'Image',
        contentNoWrap: true,
        render: (val, key, row)=>{
          if (val) {
            return (<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
              this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
            }} src={val}/>);
          } else {
            return "No Image";
          }
        }
      },
      {
        key: 'item_name',
        label: 'Item Name',
        width: 250,
      },
      {
        key: 'category',
        label: 'Category',
        contentNoWrap: true,
      },
      {
        key: 'unit_case',
        label: 'UnitByCase',
        render: (val, key, row)=> {
            return (
              <CustomInput
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: false,
                }}
                inputProps={{
                  value: val || 0,
                  type: 'number',
                  inputProps: {
                    step: 1,
                    min: 0,
                  },
                  onChange: (e)=>{
                    let newVal = e.target.value;
                    newVal = parseInt(newVal);
                    if (!newVal || newVal < 0) newVal = 0;

                    let newItems = Object.assign({}, item_list);
                    let newItem = Object.assign({}, newItems[row.item_id]);
                    if (parseInt(newVal) >= 1000) {
                      this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                    }

                    newItem['unit_case'] = newVal;
                    newItems[row.item_id] = newItem;
                    this.setState({item_list: newItems});
                  },
                }}
              />
            );
        },
      },
      {
        key: 'quantity',
        label: 'Ex-QTY',
      },
      {
        key: 'received',
        label: 'Re-QTY',
      },
      {
        key: 'disc',
        label: 'DISC',
        render: (val, key, item) =>{
          return (parseInt(item['received']) - parseInt(item['quantity']));
        }
      },
      {
        key: 'quantity_input',
        label: 'Scanned',
        render: (val, key, row)=> {
          let current_customer = parseInt(localStorage.getItem('customer_id'));
          // if receive type is unit, and customer on the special_clients list, don't allow user to manually input Scanned number
          if ((receiving_type === 'unit' || receiving_type === '') && (!special_clients[current_customer])) {
            return (
              <span style={{display: "inline-flex", alignItems: 'center'}}>
                {val}
                <ReversedIconButton style={{marginLeft: ".5rem"}} size="small" onClick={()=>{
                    let new_items = Object.assign({}, item_list);
                    let newItem = Object.assign({}, row);
                    let old_qty = parseInt(newItem.quantity_input);
                    let new_qty = old_qty -1;
                    if (new_qty < 0) {
                        this.context.alert("The quantity is already 0, can't minus 1.");
                        return;
                    }

                    newItem['quantity_input'] = new_qty;
                    newItem.received = parseInt(newItem.received) - 1;
                    new_items[row.item_id] = newItem;
                    this.setState({item_list: new_items});
                }} variant="fab" color="secondary" aria-label="Decrease">
                    <ExposureNeg1 style={{color: 'white'}}/>
                </ReversedIconButton>
            </span>);
          } else {
            return (
              <CustomInput
                labelText=''
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: val || '',
                  type: 'number',
                  inputProps: {
                    step: 1,
                    min: 0,
                  },
                  className: classes.customInputNoMargin,
                  onChange: (e) => {
                    let val = e.target.value;
                    let newItems = Object.assign({}, item_list);
                    let newItem = Object.assign({}, newItems[row.item_id]);
                    val = parseInt(val);
                    if (!val || val < 0) val = 0;

                    let old_qty = parseInt(newItem.quantity_input);
                    newItem.received = parseInt(newItem.received) - old_qty + parseInt(val);
                    
                    if (parseInt(val) >= (1000 + newItem.quantity)) {
                      this.context.alert("You are manually entering a very large number, please double check the quantity to make sure it is correct.");
                    }
  
                    newItem['quantity_input'] = val;
                    newItems[row.item_id] = newItem;
                    this.setState({ item_list: newItems });
                  }
                }}
              />
            );
          }
        },
      },
      {
        key: 'style',
        label: 'Style',
        contentNoWrap: true
      },
      {
        key: 'size',
        label: 'Size',
        contentNoWrap: true
      },
      {
        key: 'color',
        label: 'Color',
        contentNoWrap: true
      }
    ];

    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (data, displayProps) => {
        // todo, update color logic
        let color = '';
        switch (true) {
          case parseInt(data['quantity']) === parseInt(data['received']):
            // green quantity matched
            color = "green";
            break;
          case parseInt(data['received']) === 0:
              // white haven't start
              color = "";
              break;
          default:
              // yellow doesn't match
              // received too much
              if (data['quantity'] < data['received']) color = "yellow";
              else color = 'light-red'; // received not enough
        }

        if (!displayProps) displayProps = {className: ''};
        let classes = displayProps.className || '';
        classes = classes.split(' ');
        classes.push(color);
        displayProps.className = classes.join(' ');
        return displayProps;
      }
    };

    return (
     <GridItem xs={12}>
      <CardContainer>
        <CustomInput
          labelText='SKU/UPC'
          formControlProps={{
              fullWidth: false,
              style: {marginLeft: '.25rem', marginRight: '.75rem'},
              className: classes.customInput
          }}
          labelProps={{
              shrink: true,
          }}
          inputProps={{
              onKeyUp: this.handleScan,
              onPaste: (e)=>{
                if (!utils.checkAllowCopy()) e.preventDefault();
              },
              inputRef: elem=>this.input_refs.sku = elem
          }}
        />

        <NewDataTable
          rows={rows}
          // rowHeight={50} 
          maxHeight={500}
          rowSettings={rowSettings}
          rowsPerPage={50}
          columns={columns}
        />
      </CardContainer>
     </GridItem>
    );
  }
  renderInputForm = () => {
    const {classes} = this.props;
    const {selected_po, item_list, receiving_type, container_num, notes } = this.state;
    if (!selected_po || !item_list || item_list.length == 0) return;

    return (
      <form onSubmit={this.submitReceiving}>
        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Receiving Type</InputLabel>
          <Select
              value={receiving_type}
              disabled={receiving_type !== ''}
              onChange={(e)=>{this.setState({receiving_type: e.target.value})}}
          >
            <MenuItem value='unit'>unit</MenuItem>
            <MenuItem value='case'>case</MenuItem>
            <MenuItem value='pallet'>pallet</MenuItem>
          </Select>
        </FormControl>

        {
          (receiving_type == 'case' ||  receiving_type == 'pallet') &&
          <CustomInput
            labelText={receiving_type == 'case' ? 'Case Number' : 'Pallet Number'}
            formControlProps={{
              fullWidth: true,
              className: classes.customInput,
            }}
            labelProps={{
              shrink: true,
            }}
            inputProps={{
              type: 'number',
              inputProps:{
                min: 0,
                step: 1
              },
              onChange: (e)=>this.setState({container_num: e.target.value}),
              value: container_num || '',
            }}
          />
        }

        <CustomInput
          labelText='Notes'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>this.setState({notes: e.target.value}),
            value: notes,
          }}
        />

        <Button type='submit' className={classes.button}>Submit Receiving</Button>
      </form>
    );
  }
  render() {
    const {po_list, po_input_value} = this.state;

    // https://v4.mui.com/components/autocomplete/
    let defaultProps = {
      onChange: (e, selected)=>{ this.loadPOSingle(selected); },
      options: po_list || [],
      inputValue: po_input_value,
      onInputChange: (event, newInputValue, reason) => {
        this.setState({po_input_value: newInputValue});
      },
      getOptionLabel: (option) => option.po,
    };

    return (
      <Grid container spacing={2}>
        <Prompt
          when={this.hasUnsaved()}
          message="You have unsaved work, are you sure to leave?"
        />

        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {this.renderDialog()}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <MuiAlert severity="info">Total work time on this page: {new Date(this.props.timer.get() * 1000).toISOString().substring(11, 19)}</MuiAlert>

            <Autocomplete
              {...defaultProps}
              autoComplete
              includeInputInList
              renderInput={(params) => <TextField {...params} label="PO#" InputLabelProps={{shrink: true}} margin="normal" />}
            />

            {this.renderInputForm()}
          </CardContainer>
        </GridItem>

        {this.renderReceivingDetail()}
        {this.renderReceivingItems()}
      </Grid>
    );
  }
}
export default withTimer(withStyles(styles)(Receiving));
