// ##############################
// // // Nested Menu styles
// #############################

import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
} from "variables/styles";

const nestedMenuStyle = theme => ({
  root: {
    width: '100%',
    maxWidth: 300,
    // backgroundColor: theme.palette.background.paper,
    // todo, move this back after debug
    fontSize: ".5rem !important",
  },
  nested: {
    // paddingLeft: theme.spacing(4),
  },
  listItem: {
    // borderRadius: ".5rem",
  },
  activeList: {
    // borderRadius: ".5rem",
    // color: "red",
    // fontWeight: "bold !important",
    // backgroundColor: "blue",
  },
  activeSingle: {
    // The simple version for a light background menu Active Item.
    // color: "#3f51b5 !important",
    // borderLeft: ".25rem solid #3f51b5",
    // width: "calc(100% - .25rem)",
    // paddingLeft: ".75rem",

    backgroundColor: "#3AB0AC !important",
    // backgroundColor: "#26a69a !important",
  },
  ellipsis: {
    overflow:'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  listItemText: {
    // fontWeight: "bold"
    fontSize: "1.2rem",
   
  },
  activeListItemText: {
    // fontWeight: "bold"
    fontSize: "1.2rem",
  },
  placeholder: {
    display: 'none'
  }
});

export default nestedMenuStyle;
