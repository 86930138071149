import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import axios from 'axios';
import { TextField, ButtonGroup, Typography, Grid, withStyles, Hidden, MenuItem, Popover } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import _ from 'lodash';
import EditLocationSize from 'views/Dialogs/Location/EditLocationSize'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableFormControl: {
    width: '8rem'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
  customInput: {
    margin: theme.spacing(0),
  },
  filterInput: {
    width: '42px'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  buttonPushRight: {
    marginLeft: 'auto',
  },
  toolBar: {
    display: 'flex',
    paddingBottom: '.5rem',
    width: '100%'
  },
});

class ManageLocation extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      dialog: '',
      displayFilters: {
        aisle: '',
        bay: '',
        level: '',
        section: '',
        bin: '',
        min: '',
        max: '',
        category: '',
        customer: '',
        limit_sku: '',
        inventory: '',
        // status: '',
      },
      actionsAnchor: null,
      location_list: [],
      selected_locations: {},
    }; 

    document.title = "Manage Location";
  }

  componentDidMount() {
    this.loadLocations();
  }

  // load default list
  loadLocations = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/location`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.loadLocationsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadLocationsSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({location_list: resp.data});
  }
  changeStatusMultiple = (new_status) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/locationStatus`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        location_list: Array.from(Object.keys(this.state.selected_locations)),
        status: new_status
      },
    });

    this.setState({loading: true});
    req.then(this.changeStatusMultipleSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  changeStatusMultipleSuccess = (resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({selected_locations: {}, actionsAnchor: null});
    this.loadLocations();
  }
  updateLocationSize = (new_size) => {
    let selected = {};
    for (let id in this.state.selected_locations) {
      let aisle_bay = `${this.state.selected_locations[id]['aisle']}-${this.state.selected_locations[id]['bay']}`;
      if (!selected[aisle_bay]) selected[aisle_bay] = [];
      selected[aisle_bay].push(id);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/locationSize`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        location_list: JSON.stringify(selected),
        min: new_size.min,
        max: new_size.max,
        category: new_size.category,
        customer: new_size.customer,
      },
    });

    this.setState({loading: true});
    req.then(this.updateLocationSizeSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateLocationSizeSuccess = (resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({selected_locations: {}, dialog: '', actionsAnchor: null });
    this.loadLocations();
  }
  locationLimitSKU = (newValue) => {
    let selected = {};
    for (let id in this.state.selected_locations) {
      let aisle_bay = `${this.state.selected_locations[id]['aisle']}-${this.state.selected_locations[id]['bay']}`;
      if (!selected[aisle_bay]) selected[aisle_bay] = [];
      selected[aisle_bay].push(id);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/limitsku`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        location_list: Array.from(Object.keys(this.state.selected_locations)),
        limit_sku: newValue,
      },
    });

    this.setState({loading: true});
    req.then(this.locationLimitSKUSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  locationLimitSKUSuccess = (resp) => {
    this.setState({loading: false});
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({selected_locations: {}, actionsAnchor: null});
    this.loadLocations();
  }
  printBarcode = () => {
    let selected = [];
    for (let id in this.state.selected_locations) {
      selected.push(this.state.selected_locations[id]['location']);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/barcode`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'location_tag',
        codes: selected.join(','),
      },
    });

    this.setState({loading: true});
    req.then(this.printSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printSuccess = (resp) => {
    this.setState({loading: false});

    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (!resp.data) {
      this.context.alert("Print barcode error");
      return;
    }
    this.setState({actionsAnchor: null});
    utils.printPage(resp.data);
  }

  applyFilter = (key, newVal) => {
    newVal = newVal.target.value;
    let newDisplayFilter = Object.assign({}, this.state.displayFilters);
    newDisplayFilter[key] = newVal;
    this.setState({displayFilters: newDisplayFilter});
  }
  getFilteredRows = (displayFilters) => {
    if (!displayFilters) displayFilters = this.state.displayFilters;
    let location_list = this.state.location_list || [];
    
    return location_list.filter((elem)=>{
      let result = true;
      for (let filterType in displayFilters) {
        let filter_text = displayFilters[filterType]; // filter text
        if (!filter_text) continue;
        
        let compare_text = elem[filterType]; // the text of the data list to be filtered

        // if filter text and compare text are int, parsint (remove prefix 0), other wise if text, toUpper
        // if (!isNaN(parseInt(filter_text))) filter_text = parseInt(filter_text);
        // else filter_text = filter_text.toUpperCase();
        // if (!isNaN(parseInt(compare_text))) compare_text = parseInt(compare_text);
        // else if (!compare_text) compare_text = '';
        // else compare_text = compare_text.toUpperCase();

        // Match Keyword Pre-fix
        filter_text = ('' + filter_text).toUpperCase();
        compare_text = ('' + compare_text).toUpperCase();

        let match_prefix = `^${filter_text}`;
        match_prefix = new RegExp(match_prefix, "g");
     
        // match prefix
        if (!match_prefix.test(compare_text)) return false;
        // Match exactly
        // if (filter_text != compare_text) return false;
      }
      return result;
    });
  }
  onSelectedLocationssChange = (newSelected) => (this.setState({selected_locations: newSelected}))

  exportSelectedRows = () => {
    const { selected_locations } = this.state;
    let locations = [];
    for (let location_id in selected_locations) {
      locations.push(selected_locations[location_id]);
    }
    let headers = [
      {
        key: 'customer',
        label: 'Customer',
        render: (val) => val || '',
      }, {
        key: 'category',
        label: 'Category',
        render: (val) => val || '',
      },{
        key: 'location_id',
        label: 'LocationId',
      }, {
        key: 'location',
        label: 'LocationName',
      },  {
        key: 'aisle',
        label: 'Aisle',
      }, {
        key: 'bay',
        label: 'Bay',
      }, {
        key: 'level',
        label: 'Level',
      }, {
        key: 'section',
        label: 'Section',
      }, {
        key: 'bin',
        label: 'Bin',
      }, {
        key: 'min',
        label: 'Min',
      }, {
        key: 'max',
        label: 'Max',
      }, {
        key: 'limit_sku',
        label: 'Limit SKU',
      }, {
        key: 'inventory',
        label: 'Has Inventory',
      }];

    utils.exportArrayToCsv(locations, headers, 'exported_locations');
  }

  renderDialog = () => {
    switch (this.state.dialog) {
      case 'edit_location_size':
        return (
          <EditLocationSize
            handleSubmit={this.updateLocationSize}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
    }
  }
  renderTable = () => {
    const {displayFilters, selected_locations, actionsAnchor} = this.state;

    let rows = this.getFilteredRows();
    let columns = [
      {
        key: 'location',
        label: 'Location Name',
        contentNoWrap: true,
        render: (val, key, row) => {
          if (!val) {
            return <span style={{fontWeight: 'bold', fontSize: '1.5rem'}}>{`${Object.keys(selected_locations).length} rows selected`}</span>;
          }
          return val;
        },
        sortable: true
      },
      {
        key: 'aisle',
        label: 'Aisle',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'aisle')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'bay',
        label: 'Bay',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'bay')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'level',
        label: 'Level',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'level')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'section',
        label: 'Section',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'section')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'bin',
        label: 'Bin',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'bin')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'min',
        label: 'Min',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'min')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'max',
        label: 'Max',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'max')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'category',
        label: 'Category',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'category')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'customer',
        label: 'Customer',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'customer')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'limit_sku',
        label: 'Limit SKU',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'limit_sku')}
              value={val}
            />;
          } else return val;
        },
      },
      {
        key: 'inventory',
        label: 'Has Inventory',
        sortable: true,
        render: (val, key, row) => {
          if (!row.location) {
            return <TextField
              variant="outlined"
              placeholder="filter"
              size="small"
              classes={this.props.classes.filterInput}
              onChange={this.applyFilter.bind(this, 'inventory')}
              value={val}
            />;
          } else return val;
        },
      },
      // {
      //   key: 'status',
      //   label: 'Status',
      //   sortable: true,
      //   render: (val, key, row)=>{
      //     if (!row.location) {
      //       return <FormControl size="small" variant="outlined" className={classes.tableFormControl}>
      //         <Select
      //           value={val}
      //           displayEmpty
      //           onChange={this.applyFilter.bind(this, 'status')}
      //         >
      //           <MenuItem value=''>ALL</MenuItem>
      //           <MenuItem value='0'>INACTIVE</MenuItem>
      //           <MenuItem value='1'>ACTIVE</MenuItem>
      //         </Select>
      //       </FormControl>;
      //     } return val == 0 ? 'INACTIVE' : 'ACTIVE';
      //   }
      // },
    ];

    let action_buttons = [];
    action_buttons.push({
      label: 'Print Label',
      onClick: ()=>{
        if (_.isEmpty(selected_locations)) this.context.alert('No location selected.');
        else  this.printBarcode();
      },
    }); // Print Barcode
    action_buttons.push({
      label: 'Edit',
      onClick: ()=>{
        if (_.isEmpty(selected_locations)) this.context.alert('No location selected.');
        else this.setState({dialog: 'edit_location_size'});
      },
    }); // Edit Location size
    action_buttons.push({
      label: 'De-Activate',
      onClick: ()=>{
        if (_.isEmpty(selected_locations)) this.context.alert('No location selected.');
        else this.context.confirm(`Are you sure you want to mark selected location(s) INACTIVE?`, this.changeStatusMultiple.bind(this, 0));
      },
    }); // De-activate location
    action_buttons.push({
      label: 'Limit SKU',
      onClick: ()=>{
        if (_.isEmpty(selected_locations)) this.context.alert('No location selected.');
        else this.context.confirm(`Are you sure you want to set selected location(s) only allow Single SKU during movement?`, this.locationLimitSKU.bind(this, 'YES'));
      },
    }); // Limit selected locaiton only allow single SKU
    action_buttons.push({
      label: 'Un-limit',
      onClick: ()=>{
        if (_.isEmpty(selected_locations)) this.context.alert('No location selected.');
        else this.context.confirm(`Are you sure you want to set selected location(s) allow Multiple SKUs during movement?`, this.locationLimitSKU.bind(this, 'NO'));
      },
    }); // Allow selected locaitons to have multiple SKU
    action_buttons.push({
      label: 'Export Selected',
      onClick: ()=>{
        if (_.isEmpty(selected_locations)) this.context.alert('No location selected.');
        else this.exportSelectedRows();
      },
    }); // Allow selected locaitons to have multiple SKU

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <Typography variant="h6" style={{marginBottom: '.5rem'}}>
            <Hidden lgDown>
              <ButtonGroup>   
                {action_buttons.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
              </ButtonGroup>
            </Hidden>
            <Hidden xlUp>
              <Button color="bxzDefault" variant="contained" onClick={(e)=>{this.setState({actionsAnchor: this.state.actionsAnchor ? null : e.currentTarget});}}>More Actions</Button>               
              <Popover
                open={Boolean(actionsAnchor)}
                anchorEl={actionsAnchor}
                onClose={()=>this.setState({actionsAnchor: null})}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
              }}
              >     
                {action_buttons.map((btn)=> <MenuItem key={btn.label} onClick={()=>{
                  this.setState({actionsAnchor: null});
                  btn.onClick();
                }}>{btn.label}</MenuItem>)}
              </Popover>
            </Hidden>

            {/* <ButtonGroup>
              <Button color="bxzDefault" onClick={()=>{
                 if (_.isEmpty(selected_locations)) {
                  this.context.alert('No location selected.');
                  return;
                }
                this.printBarcode();
              }}>Print Label</Button>
              <Button color="bxzDefault" onClick={()=>{
                 if (_.isEmpty(selected_locations)) {
                  this.context.alert('No location selected.');
                  return;
                }
                this.setState({dialog: 'edit_location_size'});
              }}>Edit</Button>
              <Button color="bxzDefault" onClick={()=>{
                 if (_.isEmpty(selected_locations)) {
                  this.context.alert('No location selected.');
                  return;
                }
                this.context.confirm(`Are you sure you want to mark selected location(s) INACTIVE?`, this.changeStatusMultiple.bind(this, 0));
              }}>De-Activate</Button>
            </ButtonGroup> */}
          </Typography>

          <NewDataTable
            rows={rows}
            // rowHeight={50} 
            rowsPerPage={100}
            columns={columns}
            fixedRows={[Object.assign({noSelect: true}, displayFilters)]}
            rowSelection={{
              selected: selected_locations, // since ajax table will not have sort by, data array index will always work for selection
              handleSelectedChange: this.onSelectedLocationssChange,
              uniqueID: 'location_id'
            }}
          />
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        {this.renderTable()}
        {this.renderDialog()}
      </Grid>
    );
  }
}
export default withRouter(withStyles(styles)(ManageLocation));

