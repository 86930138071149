import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0)
  },
}))

export default function EditPODetail(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {po: defaultPO, handleClose, handleSubmit} = props;
  const [po, setPO] = useState(defaultPO);

  function onPOChange(key, newVal) {
    let newPO = Object.assign({}, po);
    newPO[key] = newVal;
    setPO(newPO);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        {
          dataContext.roleType.get() == 'warehouse'  && <CustomInput
            labelText="PO #"
            // tooltip="PO number should contain only string, number, hyphen and underscore."
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
            }}
            inputProps={{
              onChange: (e)=>{onPOChange('po_key', e.target.value)},
              placeholder: 'PO number should contain only string, number, hyphen and underscore',
              value: po.po_key,
            }}
          />
        }
        
          <CustomInput
            labelText='Estimated delivery date'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            inputProps={{
              type: 'date',
              inputProps: {
                min: moment().format("YYYY-MM-DD"),
              },
              onChange: (e)=>{onPOChange('estimated_delivery', e.target.value)},
              value: po.estimated_delivery,
            }}
          />

          {/* <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>PO Type</InputLabel>
            <Select
              onChange={(e)=>{onPOChange('po_type', e.target.value)}}
              value={po.po_type}
            >
              <MenuItem value='inbound'>Inbound</MenuItem>
              <MenuItem value='crossdock'>Crossdock</MenuItem>
            </Select>
          </FormControl> */}

          <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Carrier</InputLabel>
            <Select
               onChange={(e)=>{onPOChange('carrier', e.target.value)}}
               value={po.carrier}
            >
              <MenuItem value='other'>Other</MenuItem>
              <MenuItem value='ups'>UPS</MenuItem>
              <MenuItem value='fedex'>FedEx</MenuItem>
              <MenuItem value='usps'>USPS</MenuItem>
              <MenuItem value='truck'>Truck</MenuItem>
              <MenuItem value='ECU Worldwide'>ECU Worldwide</MenuItem>
            </Select>
          </FormControl>

          <CustomInput
            labelText='Tracking #'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: classes.customInput
            }}
            inputProps={{
              onChange: (e)=>{onPOChange('tracking', e.target.value)},
              value: po.tracking,
            }}
          />
      </Grid>
    );
  }
  function onSubmit() {
    const {po_id, po_key, estimated_delivery, po_type, carrier, tracking } = po;

    let err = '';
    if (!po_key) err += "PO number is required.\n";
    if (!estimated_delivery) err += 'Estimated delivery date is required.\n';
    if (!po_type) err += 'PO type is required.\n';
    if (!tracking) err += 'Tracking number is required.\n';
    if (!carrier) err += "Carrier is required.\n";

    if (err) dataContext.alert(err);
    else handleSubmit({
      po_id, po_key, estimated_delivery, po_type, carrier, tracking
    });
  }

  let title = "Edit PO";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}