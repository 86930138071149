import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function EditShipment(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {shipment: defaultShipment, handleClose, handleSubmit} = props;
  const [shipment, setShipment] = useState(defaultShipment);

  function onShipmentChange(key, newVal) {
    let newShipment = Object.assign({}, shipment);
    newShipment[key] = newVal;
    setShipment(newShipment);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <CustomInput
          labelText='Carrier'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onShipmentChange('carrier', e.target.value)},
            value: shipment.carrier,
          }}
        />

        {/* <FormControl required fullWidth className={classes.customInput}>
          <InputLabel shrink>Carrier</InputLabel>
          <Select
            onChange={(e)=>{onShipmentChange('carrier', e.target.value)}}
            value={shipment.carrier}
          >
            <MenuItem value='Boxzooka'>BOXZOOKA</MenuItem>
            <MenuItem value='FEDEX'>FEDEX</MenuItem>
            <MenuItem value='UPS'>UPS</MenuItem>
            <MenuItem value='USPS'>USPS</MenuItem>
          </Select>
        </FormControl> */}

        <CustomInput
          labelText='Tracking #'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onShipmentChange('tracking', e.target.value)},
            value: shipment.tracking,
          }}
        />

        <CustomInput
          labelText='Weight'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onShipmentChange('weight', e.target.value)},
            value: shipment.weight,
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    const {carrier, tracking, weight} = shipment;
    let err = '';
    if (!carrier) err += "Carrier is required.\n";
    if (!tracking) err += "Tracking Number is required.\n";
    if (!weight) err += "Weight is required.\n";
   
    if (err) {
      dataContext.alert(err);
      return;
    }

    handleSubmit(shipment);
  }

  let title = "Edit Shipment";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}