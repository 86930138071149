// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Grid,
    withStyles
} from "@material-ui/core";
// BXZ render modules
import {
  NewDataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import DataContext from 'context/Data'
import moment from "moment-timezone";
// Our view components

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
     selectInput: {
        width: '100%',
        margin: '1rem 0'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    button: {
        marginTop: theme.spacing(2)
    }
});

class ImportProduct extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.file_input = null;

        this.state = {
            loading: false,
            errors: [],
        };

        document.title = "Import Product";
    }

    render() {
        const { classes } = this.props;
        const { loading, errors } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let errorsTable = null;
        if (errors && errors.length > 0) {
            let rows = errors.map((error, index)=>{
                return (
                    <TableRow hover key={index}><TableCell>
                        {error}
                    </TableCell></TableRow>
                );
            });
            errorsTable = (
                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <Typography variant="h6">
                                Result In the File You Uploaded:
                            </Typography>

                            <Table className='with-border'>
                                <TableBody>
                                    {rows}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContainer>
                </GridItem>
            );
        }

        return (
            <Grid container spacing={2}>
                {loadingBar}

                {errorsTable}
                <GridItem xs={12}>
                    <CardContainer>
                        <Typography variant="h6">
                            Product Format Instruction:
                        </Typography>

                        <div style={{fontSize: "1.25rem", marginTop: ".5rem"}}>Below is the sample of a Product file. The table header with a yellow background is required. Those with white background are optional. You could click the "Download Forecast CSV Template" button to download the sample csv just for the header and use it import into the system. Please follow the format of each field. Sku can not be empty. SKU should be the unique identifier of your product. Please limit the length of SKU to be within 25 characters. Please only use numbers, letters, dash and underscore in the SKU. Value should be greater than or equal to 1. Weight is required and should be a number greater than 0 (can be decimal).</div>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{height: '3rem'}}>
                                <Typography variant="h6">
                                    Sample Table:

                                    <Button style={{float: 'right'}} onClick={()=>{
                                        let file_name = "Product_SAMPLE";

                                        let customer = localStorage.getItem('customer_id');
                                        let warehouse = localStorage.getItem('warehouse_id');

                                        file_name += '_' + warehouse + '_' + customer + '_';

                                        let now = moment();
                                        file_name += now.format('YYYY_MM_DD');
                                        utils.exportTableToCsv('table-export', file_name, true);
                                    }}>Download Product CSV Template</Button>
                                </Typography>
                            </div>

                            {this.renderSampleTable()}
                        </div>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title}>
                                <Typography variant="h6">
                                    Product Upload Instruction:
                                </Typography>
                            </div>
                            <div>
                                <form
                                  onSubmit={this.submit}
                                  className={classes.root}
                                  ref={(elem)=>{this.input_form = elem}}
                                >
                                    <div style={{fontSize: "1.25rem", margin: ".5rem 0"}}>Use the file input below to import the product file you created and click "Import Product" button to upload it. Error messages will appear on the screen if there are errors in the file you uploaded</div>

                                    <CustomInput
                                      labelText='Upload Product'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          type: 'file',
                                          name: 'product',
                                          inputRef: (elem)=>{this.file_input = elem}
                                      }}
                                    />
                                    <Button className={classes.button} type='submit'>Upload Product</Button>
                                </form>
                            </div>
                        </div>
                    </CardContainer>
                </GridItem>

            </Grid>
        );
    }

    // api call
    submit = (e) => {
        e.preventDefault();

        // todo decide whether to add confirmation
        // let msg = "Are you sure to import Order for Warehouse: "+ warehouse_name +", Customer: "+ customer_name +"?";
        // let cfm = window.confirm(msg);
        // if (!cfm) return;

        let form_data = new FormData(this.input_form);
        let req = axios({
            method: 'post',
            dataType: 'json',
            processData: false,
            contentType: false,
            cache: false,
            url: `${utils.getBaseUrl('customer')}/import/product`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: form_data,
        });

        this.setState({loading: true, errors: []});
        req.then(this.submitSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: ()=>{this.file_input.value= ''}}));
    }
    submitSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;

        if (resp.Error) {
            // display error table and scroll to top
            this.file_input.value= '';
            this.setState({errors: resp.Error});
            utils.scrollToTop();
            //this.contex.alert(resp.Error);
            return;
        }
    }

    // render func
    renderSampleTable = () => {
        let colSettings = [
            {
                key: 'sku',
                label: 'SKU',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
              key: 'upc',
              label: 'UPC',
              render: utils.tableCellOnDisplay,
            },
            {
                key: 'item_name',
                label: 'ItemName',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
              key: 'description',
              label: 'Description',
              render: utils.tableCellOnDisplay,
            },
            {
                key: 'short_description',
                label: 'ShortDescription',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'category',
                label: 'Category',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
              key: 'materials',
              label: 'Materials',
              render: utils.tableCellOnDisplay,
            },
            {
              key: 'size',
              label: 'Size',
              render: utils.tableCellOnDisplay,
            },
            {
                key: 'color',
                label: 'Color',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'style',
                label: 'Style',
                render: utils.tableCellOnDisplay,
            },
            {
              key: 'weight',
              label: 'Weight',
              render: utils.tableCellOnDisplay,
              header_style: {backgroundColor: 'yellow'},
              header_class: 'yellow',
            },
            {
              key: 'hs_code',
              label: 'HSCode',
              render: utils.tableCellOnDisplay,
            },
            {
                key: 'value',
                label: 'Value',
                render: utils.tableCellOnDisplay,
                header_style: {backgroundColor: 'yellow'},
                header_class: 'yellow'
            },
            {
                key: 'WholesaleValue',
                label: 'WholesaleValue',
                render: utils.tableCellOnDisplay,
            },
            {
              key: 'LDP_Cost',
              label: 'LDP_Cost',
              render: utils.tableCellOnDisplay,
            },
            {
                key: 'Cost',
                label: 'Cost',
                render: utils.tableCellOnDisplay,
            },     
            {
              key: 'image',
              label: 'Image',
              render: utils.tableCellOnDisplay,
            }, 
            {
                key: 'country',
                label: 'CountryOfOrigin',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'length',
                label: 'DimLength',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'width',
                label: 'DimWidth',
                render: utils.tableCellOnDisplay,
            },
            {
                key: 'height',
                label: 'DimHeight',
                render: utils.tableCellOnDisplay,
            },
        ];

        let rows = [
            {
                sku: '1234567',
                item_name: 'Bar',
                category: "Tops",
                value: '23.5',
                WholesaleValue: '18',
                Cost: '',
                LDP_Cost: '',
                hs_code: "",
                description: '',
                short_description: '',
                image: '',
                materials: '',
                color: 'Black',
                style: '',
                size: 'Red',
                upc: '1234567',
                weight: '1.0',
                country: 'US',
                length: '1',
                width: '1',
                height: '1',
            },
            {
                sku: 'ABVDASD',
                item_name: 'Bar',
                value: '23.5',
                category: "Bottoms",
                WholesaleValue: '18',
                Cost: '',
                LDP_Cost: '',
                hs_code: "",
                description: '',
                short_description: '',
                image: '',
                materials: '',
                color: 'Black',
                style: '',
                size: 'Red',
                upc: 'ABVDASD',
                weight: '1.0',
                country: 'US',
                length: '1',
                width: '1',
                height: '1',
            },
            {
                sku: 'AVA1234',
                item_name: 'Bar',
                value: '23.5',
                category: "Shoes",
                WholesaleValue: '18',
                Cost: '',
                LDP_Cost: '',
                hs_code: "",
                description: '',
                short_description: '',
                image: '',
                materials: '',
                color: 'Black',
                style: '',
                size: 'Red',
                upc: 'AVA1234',
                weight: '1.0',
                country: 'US',
                length: '1',
                width: '1',
                height: '1',
            },
        ];

        return (
          <NewDataTable
            rows={rows}
            id="table-export"
            noPagination
            columns={colSettings}
          />
        );
    }
}
export default withStyles(styles)(ImportProduct);
