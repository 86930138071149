import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import {
    Typography,
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    IconButton
} from '@material-ui/core';
import {
  Edit,
  DeleteForever
} from '@material-ui/icons'
import DataContext from 'context/Data'
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from 'components';

import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class EmailReport extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            email_list: [],
            report_types: [],
            report: '',
            email: '',
        };

        document.title = "Email Report";
    }

    render() {
        const { classes } = this.props;
        const { loading, email, report, report_types } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={2}>
                {loadingBar}
                <GridItem xs={12} sm={6}>
                    <CardContainer>
                        <form onSubmit={this.addEmail}>
                            <FormControl required fullWidth className={classes.selectInput}>
                                <InputLabel shrink>Report Type</InputLabel>
                                <Select
                                  value={report}
                                  onChange={(e)=>{this.setState({report: e.target.value})}}
                                >
                                    {report_types.map((val)=>(<MenuItem key={val.value} value={val.value}>{val.name}</MenuItem>))}
                                </Select>
                            </FormControl>

                            <CustomInput
                              labelText='Email'
                              labelProps={{shrink: true}}
                              formControlProps={{
                                  fullWidth: true,
                                  required: true,
                                  className: this.props.classes.customInput
                              }}
                              inputProps={{
                                  onChange: (e)=>{this.setState({email: e.target.value})},
                                  value: email,
                              }}
                            />

                            <Button className={classes.button} type='submit'>Add</Button>
                        </form>
                    </CardContainer>
                </GridItem>

                {this.renderTable()}
            </Grid>
        );
    }

    componentWillMount() {
        this.loadEmailList();
    }

    // api call
    // load email table and report type options on page load
    loadEmailList = () => {
        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/emailReport`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        this.setState({loading: true});
        req.then(this.loadEmailListSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadEmailListSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }

        let default_report = '';
        if (data.report_type && data.report_type.length > 0) default_report = data.report_type[0]['value'];

        this.setState({report_types: data.report_type, email_list: data.email_list, report: default_report})
    }
    // remove email
    removeEmail = (id, index) => {
        let req = axios({
            method: 'delete',
            url: `${utils.getBaseUrl('customer')}/emailReport/${id}`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });

        this.setState({loading: true});
        req.then(this.removeEmailSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    removeEmailSuccess = (index, resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }

        let new_list = Array.from(this.state.email_list);
        new_list.splice(index, 1);

        this.context.snackDisplay("Remove email report success.");
        this.setState({email_list: new_list});
    }
    // add a new report email address
    addEmail = (e) => {
        e.preventDefault();
        const { report, email } = this.state;

        let err = '';
        if (!report) {
            err += "Please select a report type. \n";
        }
        if (!email) {
            err += "Please enter the email address. \n";
        } else {
            let email_valid = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g;
            if (!email_valid.test(email)) err += "Email address is invalid. \n";
        }
        if (err) {
            this.context.alert(err);
            return;
        }
        
        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/emailReport`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: {
              email,
              report
            },
        });

        this.setState({loading: true});
        req.then(this.addEmailSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    addEmailSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }

        if (data) {
          this.context.snackDisplay("Add email report success.");
            window.location.reload();
        } else {
            this.context.alert("Internal server error");
        }

    }

    // render search result table
    renderTable = () => {
        const { classes } = this.props;
        let rows = Array.from(this.state.email_list ? this.state.email_list : []);

        let colSettings = [
            {
                key: 'email',
                label: 'Email',
            }, {
                key: 'report',
                label: 'Report Type',
            }, {
                key: 'remove',
                label: 'Remove',
                render: (val, key, row, index)=>{
                  return (<IconButton size="small" color="secondary" onClick={()=>{
                    if (row['id'] !== rows[index]['id']) {
                        for (let i = 0;i< rows.length;i++) {
                            if (row['id'] === rows[i]['id']) {
                                index = i;
                                break;
                            }
                        }
                    }
                    this.context.confirm(
                      "Are your sure to delete this email?",
                      this.removeEmail.bind(this, row.id, index)
                    );
                  }} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}}>
                      <DeleteForever/>
                  </IconButton>);  

                    return (<Button color="secondary" onClick={()=>{
                        if (row['id'] !== rows[index]['id']) {
                            for (let i = 0;i< rows.length;i++) {
                                if (row['id'] === rows[i]['id']) {
                                    index = i;
                                    break;
                                }
                            }
                        }
                        this.context.confirm(
                          "Are your sure to delete this email?",
                          this.removeEmail.bind(this, row.id, index)
                        );
                    }}>Remove</Button>);
                },
            }
        ];

        return (
            <GridItem sm={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title} style={{height: '3rem'}}>
                            <Typography variant="h6" id="tableTitle">
                                Email Report
                            </Typography>
                        </div>

                        <NewDataTable
                          rows={rows}
                          // don't set max height
                          // tableSettings={{
                          //     maxHeight: '400px',
                          // }}
                          rowHeight='auto'
                          noPagination
                          columns={colSettings}
                        />
                    </div>
                </CardContainer>
            </GridItem>
        );
    }
}
export default withStyles(styles)(EmailReport)
