import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, MenuItem, InputLabel, FormControl, Select} from '@material-ui/core'

import {CustomInput, BasicDialog, Button, GridItem} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0)
  },
}))

export default function ReturnLabel(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, createLabel, returnLabelUrl, returnTracking} = props;
  let trackingField = null;
  if (returnTracking) trackingField = <p>The return tracking number is: <span style={{fontSize: '1.5rem', fontWeight:'bold'}}>{returnTracking}</span></p>;
  function renderContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        {trackingField}
        <p>This is your new return label, please send this link to the customer: </p>
        <a target="_blank" href={returnLabelUrl}>{returnLabelUrl}</a>
        <br/>
        <p>Or, do you want to get a new one? <Button size="small"  style={{marginLeft: '1rem'}} onClick={createLabel}>Get a new one</Button></p>
      </Grid>
    );
  }
 
  let title = "Return Label";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderContent()}
      actions={[
        <Button key="submit" onClick={handleClose}>Close</Button>
      ]}
    />
  );
}