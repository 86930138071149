import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Typography, withStyles, IconButton, MenuItem, FormControl, Select } from "@material-ui/core";
import axios from 'axios';
import utils from 'utils/utils';
import _ from 'lodash'
import {
  Add,
  DeleteForever
} from '@material-ui/icons';
import {
  NewDataTable,
  CardContainer,
  CustomInput,
} from 'components';
import DataContext from 'context/Data'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class ShippingMap extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    shipping_map: PropTypes.array.isRequired,
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onShippingMapChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.edit_input = null;

    this.state = {
      loading: false,
      edit: {},
      scope: 'domestic',
      label: '',
      maps_to: ''
    };
  }

  render() {
    return (
      <div>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Shipping Map
        </Typography>

        {this.renderShippingMapTable()}
      </div>
    );
  }

  renderShippingMapTable = () => {
    const { classes, shipping_map } = this.props;
    const { edit, scope, label, maps_to } = this.state;

    let rows = [];
    if (shipping_map) {
      rows = Array.from(shipping_map);
    }
    
    let methodList = localStorage.getItem('method_list') ? JSON.parse(localStorage.getItem('method_list')) : [];
    if (methodList.length == 0) {
      methodList = utils.defaultShippingMethodOptions;
    }
    rows.push({addNew: true});

    let columns = [
      {
          key: 'scope',
          label: 'scope',
          width: 120,
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                  edit: {
                      key: row.id,
                      field: 'scope',
                  }});
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <Select
                                  value={scope}
                                  onChange={(e)=>{this.setState({scope: e.target.value})}}
                                  inputProps={{
                                      name: 'scope',
                                      id: 'scope',
                                  }}
                              >
                                  <MenuItem key='domestic' value='domestic'>Domestic</MenuItem>
                                  <MenuItem key='international' value='international'>International</MenuItem>
                              </Select>
                          </FormControl>
                      </div>
                  );
              }

              if (edit.key === row.id && edit.field === 'scope') {
                  _.delay(()=>{
                      this.edit_input.focus();
                  }, 100);

                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <Select
                                  defaultValue={val}
                                  // native={true}
                                  inputProps={{
                                      name: 'scope',
                                      id: 'scope',
                                      inputRef: (elem)=>{this.edit_input = elem},
                                      onBlur: ()=>{
                                          let newVal = this.edit_input.value;;
                                          this.updateShippingMap(newVal, 'scope', row.id, index);
                                          this.setState({edit: {}});
                                      },
                                  }}
                              >
                                <MenuItem key='domestic' value='domestic'>Domestic</MenuItem>
                                <MenuItem key='international' value='international'>International</MenuItem>
                              </Select>
                          </FormControl>
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'label',
          label: 'Label',
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.id,
                          field: 'label',
                      }});
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  onChange: (e)=>{this.setState({label: e.target.value})},
                                  value: label
                              }}
                          />
                      </div>
                  );
              }

              if (edit.key === row.id && edit.field === 'label') {
                  _.delay(()=>{
                      this.edit_input.focus();
                  }, 100);

                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  defaultValue: val ? val : '',
                                  inputRef: (elem)=>{this.edit_input = elem},
                                  onBlur: ()=>{
                                      let newVal = this.edit_input.value;;
                                      this.updateShippingMap(newVal, 'label', row.id, index);
                                      this.setState({edit: {}});
                                  },
                              }}
                          />
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'maps_to',
          label: 'Maps To',
          width: 400,
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.id,
                          field: 'maps_to',
                      }}
                  );
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <Select
                                  value={maps_to}
                                  onChange={(e)=>{this.setState({maps_to: e.target.value})}}
                                  inputProps={{
                                      name: 'maps_to',
                                      id: 'maps_to',
                                  }}
                              >
                                {methodList.map((elem)=>(<MenuItem key={elem.method} value={elem.method}>{elem.name}</MenuItem>))}
                              </Select>
                          </FormControl>
                      </div>
                  );
              }

              if (edit.key === row.id && edit.field === 'maps_to') {
                  _.delay(()=>{
                      this.edit_input.focus();
                  }, 100);
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <Select
                                  defaultValue={val ? val : ''}
                                  inputProps={{
                                      name: 'maps_to',
                                      id: 'maps_to',
                                      inputRef: (elem)=>{this.edit_input = elem},
                                      onBlur: ()=>{
                                          let newVal = this.edit_input.value;;
                                          this.updateShippingMap(newVal, 'maps_to', row.id, index);
                                          this.setState({edit: {}});
                                      },
                                  }}
                              >
                                {methodList.map((elem)=>(<MenuItem key={elem.method} value={elem.method}>{elem.name}</MenuItem>))}
                              </Select>
                          </FormControl>
                      </div>
                  );
              } else {
                  return val ? utils.convertShippingMethod(val) : '';
              }
          }
      },
      {
          key: 'action',
          label: 'Action',
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <IconButton size="small" onClick={this.newShippingMap} variant="fab" aria-label="Add" className={classes.button}>
                          <Add />
                      </IconButton>
                  );
              } else {
                  return (
                      <IconButton size="small" onClick={
                        ()=>{
                          this.context.confirm("Are you sure to delete this shipping map?", this.deleteShippingMap.bind(this, row.id, index));
                        }} color='secondary' variant="fab" aria-label="Delete" className={classes.button}>
                          <DeleteForever />
                      </IconButton>
                  );
              }
          }
      }
    ];

    return (
      <CardContainer>
        <NewDataTable
          rows={rows}
          noPagination
          rowHeight='auto'
          maxHeight={500}
          columns={columns}
        />
    </CardContainer>
    );
  }

  newShippingMap = () => {
    const {  scope, label, maps_to } = this.state;
    let customer_id = this.props.customer_id;
    let err = '';

    if (!scope) err += 'Scope is required. \n';
    if (!label) err += 'Label is required. \n';
    if (!maps_to) err += 'Maps To is required';

    if (err) {
      this.context.alert(err);
      return;
    }

    let newMap = {
      customer_id,
      scope,
      label,
      maps_to
    };

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customer`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        customer_id,
        shipping_map: newMap
      },
    });

    this.setState({loading: true});
    req.then(this.newShippingMapSuccess.bind(this, newMap)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newShippingMapSuccess = (newMap, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    if (!resp.data || !resp.data.id) {
      this.context.alert("Add shipping map error.");
      return;
    }
    newMap.id = resp.data.id;

    const { shipping_map, onShippingMapChange } = this.props;
    let newMaps = Array.from(shipping_map);
    newMaps.push(newMap);

    // clear input
    this.setState({scope: 'domestic', label: '', maps_to: ''});
    onShippingMapChange(newMaps);
  }
  deleteShippingMap = (map_id,index) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/customer/shipping_map/${map_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteShippingMapSuccess.bind(this, map_id, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteShippingMapSuccess = (map_id, index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    
    let newMaps = Array.from(this.props.shipping_map);
    newMaps.splice(index, 1);
    this.props.onShippingMapChange(newMaps);
  }
  updateShippingMap = (newVal, field, map_id, index) => {
    let newMap = Object.assign({}, this.props.shipping_map[index]);
    if (newMap && newMap.id === map_id) {
        newMap[field] = newVal;
    } else {
        for (let i = 0; i < this.props.shipping_map.length;i++) {
            let map = this.props.shipping_map[i];
            if (map.id === map_id) {
                index = i;
                newMap = Object.assign({}, map);
                newMap[field] = newVal;
                break;
            }
        }
    }

    let err = '';
    if (!newMap.label) err += 'Label is required. \n';
    if (!newMap.maps_to) err += 'Maps To is required.';
    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customerdetail`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        customer_id: this.props.customer_id,
        shipping_map: newMap
      },
    });

    this.setState({loading: true});
    req.then(this.updateShippingMapSuccess.bind(this, newMap, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateShippingMapSuccess = (newMap, index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }
    const { shipping_map, onShippingMapChange } = this.props;
    let newMaps = Array.from(shipping_map);
    newMaps[index] = newMap;

    onShippingMapChange(newMaps);
  }
}
export default withStyles(styles)(ShippingMap);
