import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import moment from 'moment-timezone'
import {
    Typography,
    Grid,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    withStyles,
    IconButton
} from '@material-ui/core';
import {
  DeleteForever
} from '@material-ui/icons'
import DataContext from 'context/Data'
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from 'components';

import utils from 'utils/utils'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  }
});

class Billing extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
          loading: false,
          billing: null,
          date_from: moment().day(-7).format('YYYY-MM-DD'),
          date_to: moment().day(0).format('YYYY-MM-DD'),
        };

        document.title = "Billing";
    }

    // api call
    // load email table and report type options on page load
    loadBilling = () => {
      const { date_from, date_to } = this.state;

      let dt_to = moment(date_to);
      if (dt_to.day() !== 0) {
          this.context.alert("The 'Date To' value you selected is not Sunday");
          return;
      }

      let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/invoice/${date_from}/${date_to}`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
      });

      this.setState({loading: true});
      req.then(this.loadBillingSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadBillingSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }
        this.setState({billing: resp.data});
    }
    sendInvoice = () => {
      // Check customer id and local storage customer id
      if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
        this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
        return;
      }

      let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/invoice`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
          invoice: this.state.billing,
        },
      });

      this.setState({loading: true});
      req.then(this.sendInvoiceSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    sendInvoiceSuccess = (resp) => {
      this.setState({loading: false});
      let data = resp.data;
      if (data.Error) {
          this.context.alert(data.Error);
          return;
      }
      this.context.snackDisplay("Update invoice success.");
      window.location.reload();
    }

    // render search result table
    renderTable = () => {
      const {classes} = this.props;
      const { billing } = this.state;
      if (!billing) return null;

      let rows = Array.from(billing);

      let sum =  billing.reduce((accumulator, row)=>{
        let curr = row.amount ? row.amount : 0;
        return accumulator + curr;
      }, 0);

      sum = parseFloat(sum).toFixed(2);
      if (!sum) sum = '0';
      if (sum === '0.00') sum = '0';

      rows.push({
        type: 'Total',
        cost: '',
        quantity: '',
        amount: sum,
        description: '',
        total: true
      });

      let colSettings = [
          {
            key: 'type',
            label: 'Type',
          }, {
            key: 'cost',
            label: 'Cost',
          }, {
            key: 'quantity',
            label: 'Quantity',
          }, {
            key: 'amount',
            label: 'Amount',
          }, {
            key: 'description',
            label: 'Description',
          }, {
            key: 'action',
            label: 'Action',
            render: (val, key, row, index) => {
              // still allow delete
              if (row.total) return '';
              return (
                <IconButton color="secondary" size="small" onClick={()=>{
                  this.context.confirm("Are you sure to delete this invoice?", ()=>{
                    const { billing } = this.state;
                    let newBilling = Array.from(billing);
                    newBilling.splice(index, 1);
                    this.setState({billing: newBilling})  
                  })
                }} variant="fab" aria-label="Delete" style={{marginLeft: ".5rem"}} className={classes.button}>
                    <DeleteForever/>
                </IconButton>
              );
          },
          }
      ];

      return (
        <GridItem sm={12}>
          <CardContainer>
            <Button onClick={this.sendInvoice}>Send Invoice</Button>
            <NewDataTable
              rows={rows}
              rowHeight='auto'
              noPagination
              columns={colSettings}
            />
          </CardContainer>
        </GridItem>
      );
    }
    
    render() {
      const { classes } = this.props;
      const { loading, date_from, date_to, } = this.state;

      let loadingBar = null;
      if (loading) {
          loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
      }

      return (
        <Grid container spacing={2}>
          {loadingBar}

          <GridItem xs={12} sm={6}>
            <CardContainer>
              <CustomInput
                labelText='Date From'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  type: 'date',
                  value: date_from,
                  onChange: (e)=>{this.setState({date_from: e.target.value})}
                }}
              />

              <CustomInput
                  labelText='Date To'
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    type: 'date',
                    value: date_to,
                    onChange: (e)=>{
                      this.setState({date_to: e.target.value})
                    }
                  }}
              />

              <Button style={{marginTop: '.5rem'}} onClick={this.loadBilling}>Search</Button>
            </CardContainer>
          </GridItem>
          {this.renderTable()}
        </Grid>
      );
  }
}
export default withStyles(styles)(Billing)
