import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Grid, InputLabel, Select, FormControl, MenuItem, withStyles, ButtonGroup } from "@material-ui/core";
import {
  CardContainer,
  Button,
  NewDataTable,
  CustomInput,
  GridItem,
} from "components";
import DataContext from 'context/Data'
import utils from 'utils/utils'
import { withAlert } from '../../hoc';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class ManagePallet extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    alert: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.temp_data = {
      data_type: '',
    };

    this.state = {
      loading: false,
      keyword: '',
      type: 'pallet_id',
      pallets: [],
    };

    document.title = "Manage Pallet";
  }

  resetPage = () => {
    this.temp_data = {
      data_type: '',
    };
    this.setState({
      keyword: '',
      pallets: [],
    });
  }
  searchPallets = (e) => {
    e.preventDefault();
    const { type, keyword } = this.state;
    let err = '';
    if (!type) err += 'Search type is required. \n';
    if (!keyword)  err += 'Keyword is required. \n';
    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/pallet`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: type,
        keyword: keyword
      },
    });
    this.temp_data.data_type = type;
    this.setState({loading: true, pallets: null});
    req.then(this.searchPalletsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  searchPalletsSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    if (resp.data.length === 0) {
      this.context.alert("Pallet data not found.");
      return;
    }
    this.setState({pallets: resp.data});
  }
  newPalletId = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/pallet`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.newPalletIdSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newPalletIdSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    if (data && data.pallet_id) {
      this.printPallet(data.pallet_id);
    }
  }
  printPallet = (pallet_id) => {
    let url = utils.getBaseUrl('') + '/returnpaper?pallet=' + pallet_id;
    let w = window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    if (!w) {
        alert('To enable print, please allow pop up window');
    }
  }
  emptyPallet = (pallet_id) => {
    if (!pallet_id) pallet_id = this.state.pallets[0]['pallet_id'];

    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/pallet/${pallet_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.emptyPalletSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  emptyPalletSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    this.context.snackDisplay('Empty pallet success.');
    this.resetPage();
  }
  emptyRecordInboundPallet = (pallet_id) => {
    if (!pallet_id) pallet_id = this.state.pallets[0]['pallet_id'];
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/recordpallet/${pallet_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.emptyRecordInboundPalletSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  emptyRecordInboundPalletSuccess = (resp) => {
    this.setState({loading: false});
    let data = resp.data;
    if (data.Error) {
      this.context.alert(data.Error);
      return;
    }
    this.context.snackDisplay('Empty pallet success.');
    this.resetPage();
  }

  renderPalletTable = () => {
    const {pallets} = this.state;
    const {data_type} = this.temp_data;
    if (!pallets || pallets.length == 0) return null;
    let special_header = {po: true, tracking: true, record_inbound_id: true};

    let columns = [
      {
        key: 'pallet_id',
        label: 'Pallet Id',
        render: (val)=>{
          return (<Button onClick={()=>this.printPallet(val)}>{val}</Button>);
        },
      }, {
        key: 'location',
        label: special_header[data_type] ? 'Type' :  'Location',
        contentNoWrap: true,
      }, {
        key: 'sku',
        label: special_header[data_type] ? 'PO' : 'SKU',
        contentNoWrap: true,
      },{
        key: 'upc',
        label: special_header[data_type] ? 'Tracking' : 'UPC',
        render: (val, key, row, index) => {
          val  = val ? val : '';
          return (
              <div style={{maxWidth: '15rem', wordBreak:'break-all'}}>{val}</div>
          );
        }
      },{
        key: 'quantity',
        label: 'Quantity',
      },{
        key: 'created_dt',
        label: 'Created Date',
        contentNoWrap: true,
        render: utils.localizeDate
      },{
        key: 'update_dt',
        label: 'Update Date',
        contentNoWrap: true,
        render: utils.localizeDate
      }
    ];

    return (
      <GridItem xs={12}>
        <CardContainer>
         

          <ButtonGroup>
            <Button color="bxzDefault" variant="contained" onClick={()=>{ utils.exportTableToCsv('table-export', 'Pallet');}}>Export</Button>

            {data_type == 'pallet_id' && <Button color="bxzDefault" variant="contained" onClick={()=>{
              this.context.confirm('Are you sure to empty this pallet?', this.emptyPallet);
            }}>Empty Pallet</Button>}

            {data_type == 'record_inbound_id' && <Button color="bxzDefault" variant="contained" onClick={()=>{
              this.context.confirm('Are you sure to empty this pallet?', this.emptyRecordInboundPallet);
            }}>Empty Pallet</Button>}
          </ButtonGroup>

          
          <NewDataTable
            rows={pallets}
            noPagination
            id="table-export"
            columns={columns}
          />
        </CardContainer>
      </GridItem>
    );
  }
  render() {
    const { classes } = this.props;
    const { loading, keyword, type } = this.state;

    let loadingBar = null;
    if (loading) {
      loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
    }

    return (
      <Grid container spacing={2}>
        {loadingBar}

        <GridItem xs={12} sm={6}>
          <CardContainer>
            <form onSubmit={this.searchPallets}>
              <FormControl required fullWidth className={classes.selectInput}>
                <InputLabel shrink htmlFor="type">Search Type</InputLabel>
                <Select
                  value={type}
                  onChange={(e)=>{this.setState({type: e.target.value})}}
                >
                  <MenuItem value='pallet_id'>Pallet Id</MenuItem>
                  <MenuItem value='sku'>SKU/UPC</MenuItem>
                  <MenuItem value='po'>PO</MenuItem>
                  <MenuItem value='record_inbound_id'>Record Inbound ID</MenuItem>
                  <MenuItem value='tracking'>Tracking</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText='Keyword'
                formControlProps={{
                  fullWidth: true,
                  required: true,
                  className: this.props.classes.customInput
                }}
                labelProps={{
                  shrink: true
                }}
                inputProps={{
                  onChange: (e)=>{this.setState({keyword: e.target.value})},
                  value: keyword,
                }}
              />

              <Button type='submit' className={classes.button}>Search</Button>
              <Button color="primary" onClick={this.newPalletId} style={{marginTop: '.5rem', float: "right"}}>New Pallet Id</Button>
            </form>
          </CardContainer>
        </GridItem>

        {this.renderPalletTable()}
      </Grid>
    );
  }
}
export default withAlert(withStyles(styles)(ManagePallet));
