import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import _ from 'lodash'
import { Grid, Tabs, Tab, AppBar, withStyles } from "@material-ui/core";
import {
  NewDataTable,
  CardContainer,
  Button,
  GridItem, 
} from 'components';
import DataContext from 'context/Data'
import utils from 'utils/utils'
import ShippingMap from 'views/Customer/CustomerTabs/ShippingMap'
import ClientAccount from 'views/Customer/CustomerTabs/ClientAccount'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class ClientManageCustomers extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.customer_id = '';

    this.state = {
      loading: false,
      add_new: false,
      active_customer: null,
      active_tab: 0,
    };

    document.title = "Manage Customer Information";
  }

  render() {
    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        {this.renderEditCustomer()}
      </Grid>
    );
  }

  componentWillMount() {
    this.loadSingleCustomer(localStorage.getItem('customer_id'));
  }

  // API call
  loadSingleCustomer = (customer_id) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/customer/${customer_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.customer_id = customer_id;
    this.setState({loading: true});
    req.then(this.loadSingleCustomerSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadSingleCustomerSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
     this.context.alert(resp.data.Error);
      return;
    }
    this.setState({active_customer: resp.data});
  }
  updateCustomer = (data, callback) => {
    let form_data = new FormData(); 
    if (data.shipping_information) {
      delete data.shipping_information.logo;
      form_data.append('logo', data.logo);
      form_data.append('customer_id', this.customer_id);
      form_data.append('shipping_information', JSON.stringify(data.shipping_information));
    } else {
      form_data = data;
      form_data.customer_id = this.customer_id;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/customerdetail`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: form_data,
    });

    this.setState({loading: true});
    req.then(this.updateCustomerSuccess.bind(this, data, callback)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateCustomerSuccess = (reqData, callback, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
     }

     let new_customer = Object.assign({}, this.state.active_customer);
     new_customer = Object.assign(new_customer, reqData);
     delete new_customer.logo;

     let new_tab = this.state.active_tab;
     if (this.state.add_new && new_tab < 4) {
         // if is creating new customer, move to next tab
         new_tab++;
         // if create customer information success, unlock next tab
         this.setup_process++;
     } else {
      callback();
     }
     this.setState({active_customer: new_customer, active_tab: new_tab});

     utils.scrollToTop();
  }

  onShippingMapChange = (shipping_map) => {
    this.loadSingleCustomer(localStorage.getItem('customer_id'));
    // let new_customer = Object.assign({}, this.state.active_customer);
    // new_customer.shipping_map = shipping_map;
    // this.setState({active_customer: new_customer});
  }
  onAccountChange = (account) => {
    this.loadSingleCustomer(localStorage.getItem('customer_id'));
    // let new_customer = Object.assign({}, this.state.active_customer);
    // new_customer.account = account;
    // this.setState({active_customer: new_customer});
  }

  renderEditCustomer = () => {
    const { classes } = this.props;
    const { active_tab } = this.state;
    const tabs = [
      {
        key: 'Shipping Map',
        label: 'Shipping Map',
        render: this.shippingMap,
      },
      {
        key: 'Account',
        label: 'Account',
        render: this.account,
      },
    ];

    return (
      <GridItem xs={12}>
        <div className={classes.tabRoot}>
          <AppBar position="static" color="default">
            <Tabs
              value={active_tab}
              onChange={(e,val)=>this.setState({active_tab: val})}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((elem)=><Tab key={elem.key} label={elem.label}/>)}
            </Tabs>
            <CardContainer>
              {tabs[active_tab].render()}
            </CardContainer>
          </AppBar>
        </div>
      </GridItem>
    );
  }

  shippingMap = () => {
    const { active_customer } = this.state;

    let shipping_map = active_customer && active_customer.shipping_map ? active_customer.shipping_map : [];

    return (
        <div>
          <ShippingMap
            shipping_map={shipping_map}
            customer_id={this.customer_id}
            onShippingMapChange={this.onShippingMapChange}
          />
        </div>
    );
  }
  account = () => {
    const { active_customer } = this.state;
    let account = active_customer && active_customer.account ? active_customer.account : [];

    return (
      <div>
        <ClientAccount
          account={account}
          customer_id={this.customer_id}
          onAccountChange={this.onAccountChange}
        />
      </div>
    );
  }
}
export default withStyles(styles)(ClientManageCustomers);
