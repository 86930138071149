import React, {useState, useEffect, useContext, useCallback} from "react"
import PropTypes from "prop-types"
import _ from 'lodash'
import { withRouter } from 'react-router'
import {Switch, Route, Redirect} from "react-router-dom"
import wmsRoutes from 'routes/menu.jsx'
import anonymousRoutes from "routes/anonymous.jsx";
import {ThemeProvider, withStyles} from "@material-ui/core/styles"
import {Container, CssBaseline} from "@material-ui/core"
import appTheme from 'styles/appTheme'
import appStyle from "variables/styles/appStyle.jsx";
import image from "assets/img/sidebar-background.jpg";
import logo from "assets/img/logo.png";
import DataContext from 'context/Data'
import SelectCustWareDialog from 'views/Dialogs/SwitchWarehouse';
import NoPermission from 'views/General/NoPermission'

import {withIdleCheck, withAlert, withSnackbar} from 'hoc'
import { Header, Sidebar } from "components";

// Process Page Routes
function generateRoutes(inputRoutes) {
  let routes = [];
  
  for (let route of inputRoutes) {
    if (route.logout || route.asButton) continue;
    // if (route.redirect) routes.push(<Redirect from={route.path} to={route.to} key={route.path} />);
    if (route.redirect) {
      let role_type  = localStorage.getItem('role_type');
      routes.push(<Redirect from={route.path} to={route.toRoot ? route.toRoot[role_type] : route.to} key={route.path} />);
    }
    else if (route.submenu) routes = routes.concat(generateRoutes(route.submenu));
    else {
      switch (route.key) {
        case 'pageNotFound':
          routes.push(<Route component={route.component} key={route.path} />);
          break;
        case 'root':
          let role_type  = localStorage.getItem('role_type');
          routes.push(<Route exact path={route.path} component={route.rootComponent[role_type] || route.rootComponent['anonymous']} key={route.path} />);
          break;
        default:
          routes.push(<Route exact path={route.path} component={route.component} key={route.path} />);
      }
      if (route.path === '/pageNotFound') routes.push(<Route component={route.component} key={route.path} />);
      else routes.push(<Route exact path={route.path} component={route.component} key={route.path} />);
    }
  }

  return routes;
}
function filterRoutes(inputRoutes) { 
  const defaultRoute = {
    login: true,
    root: true,
    // dashboard: true,
    pageNotFound: true,
    logout: true,
    redirect: true,
    debug: true,
  };
  // The page permissions returned from backedn after log in
  const specializedRoute = JSON.parse(localStorage.getItem('menu_list')) || {};
  // filter out invisible page for current user
  // only filtering outer routes
  // Old Code Filter out no permission
  // let filteredResult = inputRoutes.filter((elem)=>{
  //   return defaultRoute[elem.key] || specializedRoute[elem.key]
  // });
  
  // New Code to Replace component with no permission
  // with No Permission page alert
  let filteredResult = inputRoutes.map((e)=>{
    if (defaultRoute[e.key] || specializedRoute[e.key]) return e;
    else {
      let no_permission = Object.assign({}, e);
      no_permission.component = NoPermission;
      no_permission.hideMenu = true; // hide this no permission from menu bar
      return no_permission;
    }
  });

  // filtering inner sub routes
  filteredResult = filteredResult.map((elem)=>{
    if (elem.submenu) {
      // Old code ----- filter out page that don't have permission
      // elem.submenu = elem.submenu.filter((e)=>defaultRoute[e.key] || specializedRoute[e.key]);

      // New Code 2023-01-20 Bo wants to separate stupid typo URL and no permission
      elem.submenu = elem.submenu.map((e)=>{
        if (defaultRoute[e.key] || specializedRoute[e.key]) return e;
        else {
          let no_permission = Object.assign({}, e);
          no_permission.component = NoPermission;
          no_permission.hideMenu = true; // hide this no permission from menu bar
          return no_permission;
        }
      });
    }
    return elem;
  });
  
  return filteredResult.map((route)=>{
    if (specializedRoute[route.key]) {
      route.sidebarName = specializedRoute[route.key]; 
      route.navbarName = specializedRoute[route.key];
    }
    if (route.submenu) {
      route.submenu = route.submenu.map((subRoute)=>{
        if (specializedRoute[subRoute.key]) {
          subRoute.sidebarName = specializedRoute[subRoute.key]; 
          subRoute.navbarName = specializedRoute[subRoute.key];
        }
        return subRoute;
      })
    }
    return route;
  });
}

// The page content component exclude header and sidebar
class PageContent extends React.Component {
  render() {
    const {
      alert,confirm,
      snackDisplay, snackHide,
      classes,
      data,
      children,
    } = this.props;

    let contextValue = Object.assign({alert, confirm, snackDisplay, snackHide,}, data);

    return (
      <DataContext.Provider value={contextValue}>
        <Container id="main-app-container" maxWidth={false} className={classes.container}>
          <Switch>
            {children}
          </Switch>
        </Container>
      </DataContext.Provider>
    );
  }
}

// Wrap some global used HOC to the App main content area
// const WrappedPageContent = withAlert(withIdleCheck({interval: 900000})(PageContent));
const WrappedPageContent = withSnackbar(withAlert(withIdleCheck({interval: -1})(PageContent))); // turn off idle check

class App extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    }
    static childContextTypes = {
        data: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
          sideBarControl: {
            mobileOpen: false,
            pcOpen: true,
          },
          role: localStorage.getItem('role'),
          roleType: localStorage.getItem('role_type'),
          warehouse: localStorage.getItem('warehouse_id'),
          customer: localStorage.getItem('customer_id'),
        };

        this.data = {
          role: {
            get: ()=>{return this.state.role},
            set: (val)=>{this.setState({role: val})}
          },
          roleType: {
            get: ()=>{return this.state.roleType},
            set: (val)=>{this.setState({roleType: val})}
          },
          warehouse: {
            get: ()=>{return this.state.warehouse},
            set: (val)=>{this.setState({warehouse: val})}
          },
          customer: {
            get: ()=>{return this.state.customer},
            set: (val)=>{this.setState({customer: val})}
          },
          logout: this.logout,
        };
    }

    getChildContext() {
      return {
        data: this.data
      }
    }

    handleMobileDrawerToggle = () => {
      let sideBarControl  = Object.assign({}, this.state.sideBarControl);
      sideBarControl.mobileOpen = !sideBarControl.mobileOpen;
      this.setState({sideBarControl: sideBarControl});
    }
    handleDesktopDrawerToggle = () => {
      let sideBarControl  = Object.assign({}, this.state.sideBarControl);
      sideBarControl.pcOpen = !sideBarControl.pcOpen;
      this.setState({sideBarControl: sideBarControl});
    }
    logout = () => {
      localStorage.clear();
      window.location.reload();
    }

    render() {
      const { classes, ...rest } = this.props;

      let role = this.state.role;
      let content = null;
  
      let mainStyle = {};
      if (!this.state.pcOpen) {
        mainStyle.width = '100%';
      }
      let routeMenus = wmsRoutes;
      // if (!_.isEmpty(JSON.parse(localStorage.getItem('menu_list')))) {
      //   routeMenus = filterRoutes(wmsRoutes);
      // }
      routeMenus = filterRoutes(wmsRoutes);

      if (!role) {
        content = (
          <Switch>
              {generateRoutes(anonymousRoutes)}
          </Switch>
        );
      } else {
        content = (
          <div className={classes.root}>
            {/* <CssBaseline /> */}
            <Header
              routes={routeMenus}
              sideBarControl={this.state.sideBarControl}
              handleMobileDrawerToggle={this.handleMobileDrawerToggle}
              handleDesktopDrawerToggle={this.handleDesktopDrawerToggle}
              {...rest}
            />
  
            <Sidebar
              routes={routeMenus}
              logout={this.logout}
              logoText={"Boxzooka"}
              logo={logo}
              image={image}
              sideBarControl={this.state.sideBarControl}
              handleMobileDrawerToggle={this.handleMobileDrawerToggle}
              handleDesktopDrawerToggle={this.handleDesktopDrawerToggle}
              color="blue"
              {...rest}
            />

            <main id="page-content-container" className={classes.content}>
              <WrappedPageContent data={this.data} classes={classes}>
                {generateRoutes(routeMenus)}
              </WrappedPageContent>
            </main>
          </div>
        );
      }
  
      return (
        <DataContext.Provider value={this.data}>
          <ThemeProvider theme={appTheme}>
            <CssBaseline />

            {(!localStorage.getItem('warehouse_id') || !localStorage.getItem('customer_id')) && localStorage.getItem('token') &&
              <SelectCustWareDialog
                display={true}
                closeDialog={()=>{this.setState({dialog: ''});}}
              />
            }
            {content}
          </ThemeProvider>
        </DataContext.Provider>
      );
    }
}
export default withRouter(withStyles(appStyle)(App));