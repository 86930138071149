import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import {Grid, makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import {BasicDialog, Button} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default function TransferUnplan(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit, po_list, po } = props;
  
  const [selected_po,setSelectedPO] = useState(null);

  let defaultProps = {
    value: selected_po,
    onChange: (e, selected)=>{ 
      setSelectedPO(selected);
    },
    style: {width: '100%'},
    options: po_list || [],
    getOptionLabel: (option) => option.label,
  };

  function renderFormContent() {
    
    return (
      <Grid container className={classes.root} spacing={2}> 
        <Autocomplete
          {...defaultProps}
          autoComplete
          includeInputInList
          renderInput={(params) => <TextField {...params} label="PO#" InputLabelProps={{shrink: true}} margin="normal" />}
        />
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';
    if (!selected_po) err += 'No PO selected! \n';

    if (err) dataContext.alert(err);
    else {
      dataContext.confirm(`Are you sure to transfer this unplanned PO: ${selected_po.label} to the PO: ${po}`, ()=>handleSubmit({po_key: selected_po.label, po_id: selected_po.value}));
    }
  }

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={props.title || "Transfer Unplanned PO"}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}