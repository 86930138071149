import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function EditOrderAddress(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {address: defaultAddress, handleClose, countryList, handleSubmit} = props;
  const [address, setAddress] = useState(defaultAddress);

  let countryOptions = countryList.map((elem)=>(<MenuItem key={elem.country_code} value={elem.country_code}>{elem.name}</MenuItem>));

  function onAddressChange(key, newVal) {
    let newAddress = Object.assign({}, address);
    newAddress[key] = newVal;
    setAddress(newAddress);
  }

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <CustomInput
          labelText='Full Name'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('name', e.target.value)},
            value: address.name,
          }}
        />

        <CustomInput
          labelText='Company'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('company', e.target.value)},
            value: address.company,
          }}
        />

        <CustomInput
          labelText='Address Line 1'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('address1', e.target.value)},
            value: address.address1,
          }}
        />
        
        <CustomInput
          labelText='Address Line 2'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('address2', e.target.value)},
            value: address.address2,
          }}
        />

        <CustomInput
          labelText='City'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('city', e.target.value)},
            value: address.city,
          }}
        />

        <CustomInput
          labelText='Province/State'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('state', e.target.value)},
            value: address.state,
          }}
        />

        <CustomInput
          labelText='Postal Code'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('zipcode', e.target.value)},
            value: address.zipcode,
          }}
        />

        <FormControl fullWidth required className={classes.customInput}>
          <InputLabel shrink>Country</InputLabel>
          <Select
            onChange={(e)=>{onAddressChange('country', e.target.value)}}
            value={address.country}
          >
            {countryOptions}
          </Select>
        </FormControl>

        <CustomInput
          labelText='Email'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('email', e.target.value)},
            value: address.email,
          }}
        />

        <CustomInput
          labelText='Phone'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{onAddressChange('phone', e.target.value)},
            value: address.phone,
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    const {name, address1, city, zipcode, country} = address;
    let err = '';
    if (!name) err += "Full Name is required.\n";
    if (!address1) err += "Address Line 1 is required.\n";
    if (!city) err += "City is required.\n";
    if (!zipcode) err += "Postal Code is required.\n";
    if (!country) err += "Country is required.\n";

    if (err) {
      dataContext.alert(err);
      return;
    }

    handleSubmit(address);
  }

  let title = "Edit Order Address";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}