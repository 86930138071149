import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import {Typography, Grid, InputLabel, Select, FormControl, IconButton, withStyles, MenuItem, } from "@material-ui/core"
import {
  DeleteForever,
} from '@material-ui/icons'
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
} from 'components';
import EditOrderItem from 'views/Dialogs/EditOrderItem'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import moment from 'moment';
import { Prompt } from 'react-router'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    marginTop: '.5rem'
  },
  headerToolBar: {
    display: 'inline-flex',
    marginLeft: '1rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  },
  tableDetailImage: {
    height: '80px',
    width: '80px',
  },
});

class NewOrder extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    let methodList = localStorage.getItem('method_list') ? JSON.parse(localStorage.getItem('method_list')) : [];
    if (methodList.length == 0) {
      methodList = utils.defaultShippingMethodOptions;
    }

    this.methodList = methodList;
    this.countryList = localStorage.getItem('country_list') ? JSON.parse(localStorage.getItem('country_list')) : [];

    this.state = {
      loading: false,
      dialog: '',
      orderDetail: {
        order_key:'',
        order_type: 'retail',
        shipping_type: 'domestic',
        // method: methodList[0]['method'], // 'BXZ.PKP',
        method: 'BXZ.PKP', // 'BXZ.PKP',
        carrier_account: '',
        start_date: '',
        ship_date: '',
        cancel_date: '',
        payment_term: '',
        gift_message: '',
        packing_instruction: '',
      },
      address: {
        name: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        email: '',
        phone: ''
      },
      totalValue: {
        retail: 0,
        wholesale: 0
      },
      lineItems: [],
      forceUpdate: {}
    };

    document.title = "New Order";
  }

  // componentDidMount() {
  //   window.addEventListener('beforeunload', this.handleUnload);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('beforeunload', this.handleUnload);
  // }

  // handleUnload = (e) => {
  //   if (!this.hasUnsaved()) return '';
   
  //   if (this.state.lineItems.length == 0) e.preventDefault();
  //   var message = "\o/";

  //   message = "You have unsaved work, are you sure to leave?";

  //   (e || window.event).returnValue = message; //Gecko + IE
  //   return message;
  // }
  // hasUnsaved = () => {
  //   return this.state.lineItems.length != 0;
  // }

  resetPage = () => {
    this.setState({
      orderDetail: {
        order_key:'',
        order_type: 'retail',
        shipping_type: 'domestic',
        // method: methodList[0]['method'], // 'BXZ.PKP',
        method: 'BXZ.PKP', // 'BXZ.PKP',
        carrier_account: '',
        start_date: '',
        ship_date: '',
        cancel_date: '',
        payment_term: '',
        gift_message: '',
        packing_instruction: '',
      },
      address: {
        name: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        email: '',
        phone: ''
      },
      totalValue: {
        retail: 0,
        wholesale: 0
      },
      lineItems: [],
      forceUpdate: {}
    });
  }

  createOrder = () => {
    const {lineItems, orderDetail, address, totalValue} = this.state;

    let err = '';
  
    if (lineItems.length === 0) err += 'Please add lineitem.\n';

    // order value.

    if (!orderDetail.method) err += 'Shipping method is required. \n';
    if (!address.name) err += 'Full Name is required. \n';
    if (!address.address1) err += 'Address Line 1 is required. \n';
    if (!address.city) err += 'City is required. \n';
    if (!address.zipcode) err += 'Postal Code is required. \n';
    if (orderDetail.shipping_type === 'international') {
      // if (!orderDetail.customInput) err += 'Customs description is required for international order. \n';
      if (!address.country) err += 'Country is required for international order. \n';
      else if (address.country == 'US') {
        err += 'You have selected shipping international, please select a country other than US. \n';;
      }
    }
    
    let data = Object.assign({}, orderDetail);

    if (orderDetail.order_type !== 'edi' && orderDetail.order_type !== 'wholesale' && orderDetail.order_type !== 'whole-sale') {
      // default wholesale fields to empty string if order is not wholesale or edi
      data.start_date = '';
      data.ship_date = '';
      data.cancel_date = '';
      data.payment_term = '';
    }
    if (orderDetail.shipping_type === 'domestic') {
      address.country = 'US'; // for domestic order, country is US
    }
    
    let gift_total_value = 0;

    data.address = address;
    data.item = lineItems.map((elem)=>{
      let item_value = 0;
      switch (orderDetail.order_type) {
        case 'edi':
        case 'wholesale':
        case 'whole-sale':
          item_value = elem.wholesale_value;
          break;
        case 'gift':
          item_value = 1;
          gift_total_value += parseInt(elem.quantity);
          break;
        default: 
          item_value = elem.retail_value;
      }

      if (!item_value) item_value = 1; // if item_value is 0 or empty string, default to 1

      return {
        item_id: elem.item_id,
        sku: elem.sku,
        item_name: elem.item_name,
        quantity: elem.quantity,
        item_weight: elem.item_weight,
        item_price: item_value,
      }
    });

    data.order_value = (orderDetail.order_type !== 'edi' && orderDetail.order_type !== 'wholesale' && orderDetail.order_type !== 'whole-sale') ? totalValue.retail : totalValue.wholesale;
    if (gift_total_value > 0) data.order_value = gift_total_value;

    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/order`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });
    this.setState({loading: true});
    req.then(this.createOrderSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  createOrderSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
  
    // this.context.snackDisplay("Order create success.", {hideCallback: ()=>{
    //   window.location.reload();
    // }});
    this.context.snackDisplay("Order create success.");
    this.resetPage();
  }

  updateLineItems = (newLineItems) => {
    let newTotalValue = {retail: 0, wholesale: 0};
    for (let newItem of newLineItems) {
      newTotalValue.retail += (newItem.retail_value || 0) * newItem.quantity;
      newTotalValue.wholesale += (newItem.wholesale_value || 0) * newItem.quantity;
    }
    this.setState({totalValue: newTotalValue, lineItems: newLineItems});
  }
  removeLineItem = (deleteIndex) => {
    let newTotalValue = Object.assign({}, this.state.totalValue);
    let deletedItem = this.state.lineItems[deleteIndex];
    // if item is deleted, remove the total value accordingly
    newTotalValue.retail -= (deletedItem.retail_value || 0) * deletedItem.quantity;
    newTotalValue.wholesale -= (deletedItem.wholesale_value || 0) * deletedItem.quantity;
    this.setState({totalValue: newTotalValue, lineItems: this.state.lineItems.filter((item, index)=>(index !== deleteIndex))});
  }
  onLineItemQtyChange = (newQty, updateIndex) => {
    newQty = parseInt(newQty);
    if (!newQty || newQty < 1) return;

    let newTotalValue = Object.assign({}, this.state.totalValue);
    let updateItem = this.state.lineItems[updateIndex];

    newTotalValue.retail += (updateItem.retail_value || 0) * (newQty - updateItem.quantity);
    newTotalValue.wholesale += (updateItem.wholesale_value || 0) * (newQty - updateItem.quantity);

    let newItems = this.state.lineItems.map((prod, index)=>{
      if (updateIndex === index) prod.quantity = newQty;
      return prod;
    });
    this.setState({totalValue: newTotalValue, lineItems: newItems});
  }
  onAddressChange = (key, e) => {
    let newAddress = Object.assign({}, this.state.address);
    newAddress[key] = e.target.value;
    this.setState({address: newAddress});
  }
  onOrderDetailChange = (key, e) => {
    let newVal = e.target.value;
    let newOrderDetail = Object.assign({}, this.state.orderDetail);
    // if switching between domestic and intl, clear shipping method
    if (key === 'shipping_type' && (newVal !== newOrderDetail.shipping_type)) newOrderDetail.method = 'BXZ.PKP';
    newOrderDetail[key] = e.target.value;
    this.setState({orderDetail: newOrderDetail});
  }

  renderLineItemTable = () => {
    const {classes} = this.props;
    const {lineItems, totalValue} = this.state;

    return (
      <GridItem xs={12}>
        <CardContainer>

          <Typography variant="h6" style={{marginBottom: '.5rem'}}>
            Step 1: Add Lineitem
            <div className={classes.headerToolBar}>
            <Button className={classes.buttonPushRight} onClick={()=>(this.setState({dialog: 'edit_line_items'}))}>Add Line Items</Button>
            </div>
          </Typography>

          <NewDataTable
            rows={lineItems}
            rowHeight={80} 
            noPagination
            columns={[
              {
                key: 'sku',
                label: 'SKU',
              },
              {
                key: 'item_name',
                label: 'Item Name',
              },
              {
                key: 'image',
                label: 'Image',
                render: (val, key, row)=>{
                  if (val) {
                    return (<img className={classes.tableDetailImage} alt="wrong url" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
                      this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}}/>, {type: row.sku || 'Product Image'});
                    }} src={val}/>);
                  } else {
                    return "No Image";
                  }
                }
              },
              {
                key: 'quantity',
                label: 'Quantity',
                render: (val, key, product, index)=>{
                  return (
                    <CustomInput
                      formControlProps={{
                        className: this.props.classes.customInput
                      }}
                      inputProps={{
                        type: 'number',
                        inputProps:{
                          min: 1,
                          step: 1
                        },
                        onChange: (e) => {
                          this.onLineItemQtyChange(e.target.value, index)
                        },
                        value: val,
                      }}
                    /> 
                  );
                },
              },
              {
                key: 'remove',
                label: 'Remove',
                render: (val, key, product, index)=>{
                  return (
                    <IconButton color="secondary" onClick={()=>{this.removeLineItem(index)}} variant="fab" aria-label="Add" style={{marginLeft: ".5rem"}}>
                      <DeleteForever/>
                    </IconButton>
                  );
                },
              },
            ]}
          />

          <br/>

          <NewDataTable
            rows={[{retailTotal: totalValue.retail, wholesaleTotal: totalValue.wholesale}]}
            noPagination
            columns={[
              {
                key: 'retailTotal',
                label: 'Retail Total',
              },
              {
                key: 'wholesaleTotal',
                label: 'Wholesale Total',
              },
            ]}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderDialogs = () => {
    if (this.state.dialog === 'edit_line_items') {
      return (
        <EditOrderItem
          lineItems={this.state.lineItems}
          handleSubmit={(newLineItems)=>{
            this.updateLineItems(newLineItems);
            this.setState({dialog: ''})
          }}
          handleClose={()=>{this.setState({dialog: ''})}}
        />
      );
    }
  }
  renderOrderDetail = () => {
    const {classes} = this.props;
    const {orderDetail} = this.state;

    let methodOptions = [];
    if (orderDetail.shipping_type === 'domestic') {
      methodOptions = this.methodList.filter((elem)=>(!/^(.*?(International)[^$]*)$/g.test(elem.name))).map((elem)=>(<MenuItem key={elem.method} value={elem.method}>{elem.name}</MenuItem>));
    } else {
      methodOptions = this.methodList.filter((elem)=>(elem.method === 'BXZ.PKP' || /^(.*?(International)[^$]*)$/g.test(elem.name))).map((elem)=>(<MenuItem key={elem.method} value={elem.method}>{elem.name}</MenuItem>));
    }

    return (
      <GridItem xs={12} sm={6} md={6}>
        <CardContainer>
          <Typography variant="h6">Step 2: Enter Order Detail</Typography>

          <CustomInput
            labelText="Order ID"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              placeholder: 'length <= 18, allows only string, number, hyphen and underscore',
              onChange: this.onOrderDetailChange.bind(this, 'order_key'),
              value: orderDetail.order_key,
            }}
          />

          <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Order Type</InputLabel>
            <Select
              onChange={this.onOrderDetailChange.bind(this, 'order_type')}
              value={orderDetail.order_type}
            >
              <MenuItem value='dropship'>Dropship</MenuItem>
              <MenuItem value='edi'>EDI</MenuItem>
              <MenuItem value='gift'>Gift</MenuItem>
              <MenuItem value='monogram'>Monogram</MenuItem>
              <MenuItem value='retail'>Retail</MenuItem>
              <MenuItem value='whole-sale'>Wholesale</MenuItem>
              <MenuItem value='final-sale'>Final-sale</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Shipping Type</InputLabel>
            <Select
             onChange={this.onOrderDetailChange.bind(this, 'shipping_type')}
             value={orderDetail.shipping_type}
            >
              <MenuItem value='domestic'>Domestic</MenuItem>
              <MenuItem value='international'>International</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth required className={classes.customInput}>
            <InputLabel shrink>Method</InputLabel>
            <Select
              onChange={this.onOrderDetailChange.bind(this, 'method')}
              value={orderDetail.method}
            >
              {methodOptions}
            </Select>
          </FormControl>

          <CustomInput
            labelText='Third Party Carrier Account #'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onOrderDetailChange.bind(this, 'carrier_account'),
              value: orderDetail.carrier_account,
            }}
          />

          { (orderDetail.order_type === 'wholesale' || orderDetail.order_type === 'whole-sale' || orderDetail.order_type === 'edi') &&
            <React.Fragment>
              <FormControl fullWidth className={classes.customInput}>
                <InputLabel shrink>Payment Terms</InputLabel>
                <Select
                  onChange={this.onOrderDetailChange.bind(this, 'payment_term')}
                  value={orderDetail.payment_term}
                >
                  <MenuItem value='Due Upon Received'>Due Upon Received</MenuItem>
                  <MenuItem value='Net 7'>Net 7</MenuItem>
                  <MenuItem value='Net 14'>Net 14</MenuItem>
                  <MenuItem value='Net 21'>Net 21</MenuItem>
                  <MenuItem value='Net 28'>Net 28</MenuItem>
                  <MenuItem value='Net 30'>Net 30</MenuItem>
                </Select>
              </FormControl>

              <CustomInput
                labelText='Start date'
                labelProps={{shrink: true}}
                formControlProps={{
                  fullWidth: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  type: 'date',
                  inputProps: {
                    min: moment().format("YYYY-MM-DD"),
                  },
                  onChange: this.onOrderDetailChange.bind(this, 'start_date'),
                  value: orderDetail.start_date,
                }}
              />

              <CustomInput
                labelText='Ship date'
                labelProps={{shrink: true}}
                formControlProps={{
                  fullWidth: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  type: 'date',
                  inputProps: {
                    min: moment().format("YYYY-MM-DD"),
                  },
                  onChange: this.onOrderDetailChange.bind(this, 'ship_date'),
                  value: orderDetail.ship_date,
                }}
              />

              <CustomInput
                labelText='Cancel date'
                labelProps={{shrink: true}}
                formControlProps={{
                  fullWidth: true,
                  className: this.props.classes.customInput
                }}
                inputProps={{
                  type: 'date',
                  inputProps: {
                    min: moment().format("YYYY-MM-DD"),
                  },
                  onChange: this.onOrderDetailChange.bind(this, 'cancel_date'),
                  value: orderDetail.cancel_date,
                }}
              />
            </React.Fragment>
          }

          {/* {
            orderDetail.order_type === 'gift' && 
            <CustomInput
              labelText='Gift Message'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                onChange: this.onOrderDetailChange.bind(this, 'gift_message'),
                value: orderDetail.gift_message,
              }}
            />
          } */}

          <CustomInput
              labelText='Slip Note'
              labelProps={{shrink: true}}
              formControlProps={{
                fullWidth: true,
                className: this.props.classes.customInput
              }}
              inputProps={{
                onChange: this.onOrderDetailChange.bind(this, 'gift_message'),
                value: orderDetail.gift_message,
              }}
            />

          {/* <CustomInput
            labelText='Packing Instruction'
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onOrderDetailChange.bind(this, 'packing_instruction'),
              value: orderDetail.packing_instruction,
            }}
          /> */}
        </CardContainer>
      </GridItem>
    );
  }
  renderShippingAddress = () => {
    const {classes} = this.props;
    const {address, orderDetail} = this.state;

    return (
      <GridItem xs={12} sm={6} md={6}>
        <CardContainer>
          <Typography variant="h6">Step 3: Enter Shipping Address</Typography>

          <CustomInput
            labelText="Full Name"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onAddressChange.bind(this, 'name'),
              value: address.name,
            }}
          />

          <CustomInput
            labelText="Company"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onAddressChange.bind(this, 'company'),
              value: address.company,
            }}
          />

          <CustomInput
            labelText="Address Line 1"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              placeholder: 'length <= 18, allows only string, number, hyphen and underscore',
              onChange: this.onAddressChange.bind(this, 'address1'),
              value: address.address1,
            }}
          />

          <CustomInput
            labelText="Address Line 2"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onAddressChange.bind(this, 'address2'),
              value: address.address2,
            }}
          />


          <CustomInput
            labelText="City"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onAddressChange.bind(this, 'city'),
              value: address.city,
            }}
          />

          <CustomInput
            labelText="Province/State"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              placeholder: 'length <= 18, allows only string, number, hyphen and underscore',
              onChange: this.onAddressChange.bind(this, 'state'),
              value: address.state,
            }}
          />

          <CustomInput
            labelText="Postal Code"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              required: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              placeholder: 'length <= 18, allows only string, number, hyphen and underscore',
              onChange: this.onAddressChange.bind(this, 'zipcode'),
              value: address.zipcode,
            }}
          />

          {orderDetail.shipping_type === 'international' &&
            <FormControl fullWidth required className={classes.customInput}>
              <InputLabel shrink>Country</InputLabel>
              <Select
                value={address.country}
                onChange={this.onAddressChange.bind(this, 'country')}
              >
                {this.countryList.map((val)=>(<MenuItem key={val.country_code} value={val.country_code}>{val.name}</MenuItem>))}
              </Select>
            </FormControl>
          }
         

          <CustomInput
            labelText="Email"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onAddressChange.bind(this, 'email'),
              value: address.email,
            }}
          />

          <CustomInput
            labelText="Phone"
            labelProps={{shrink: true}}
            formControlProps={{
              fullWidth: true,
              className: this.props.classes.customInput
            }}
            inputProps={{
              onChange: this.onAddressChange.bind(this, 'phone'),
              value: address.phone,
            }}
          />

          <div className={classes.toolBar}>
            <Button onClick={this.createOrder}>Create Order</Button>
          </div>
        </CardContainer>
      </GridItem>
    );
  }

  render() {
    const {classes} = this.props; 
    const {loading} = this.state;

    return (
      <Grid className={classes.root} container spacing={2}>
        {/* <Prompt
          when={this.hasUnsaved()}
          message="You have unsaved work, are you sure to leave?"
        /> */}
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        {this.renderDialogs()}
        {this.renderLineItemTable()}
        {this.renderOrderDetail()}
        {this.renderShippingAddress()}
      </Grid>
    );
  }
}
export default withStyles(styles)(NewOrder);
