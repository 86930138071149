import React from "react";
import PropTypes from "prop-types";
import { withRouter } from 'react-router'
import axios, {
  CancelToken
} from 'axios';
import cx from 'classnames'
import SelectCustWareDialog from 'views/Dialogs/SwitchWarehouse';
import {
  withStyles,
  Table, TableHead, TableCell, TableBody, TableRow,
  Grid, Typography, IconButton, Divider, Paper, Card, CardHeader, CardContent, CardActionArea, Hidden
} from "@material-ui/core";
import {
  CardContainer,
  CardIcon,
  Button,
  NewDataTable,
  EnhancedTable,
  GridItem,
} from "components";
import {
  Search,
  AssignmentTurnedIn,
  ShoppingBasket,
  HourglassEmpty,
  FlightTakeoff,
  FlightLand,
  Undo,
  DeleteForever
} from "@material-ui/icons";
import DataContext from 'context/Data'
import moment from 'moment-timezone'
import utils from 'utils/utils'

const styles = theme => ({
  statContainer: {
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statL: {
    textAlign: 'right',
    width: 'calc(50% - 5px)'
  },
  statR: {
    textAlign: 'left',
    width: 'calc(50% - 5px)'
  },
  divider: {
    width: '10px'
  },
  cell_click: {
      cursor: 'pointer',
      minWidth: '80px',
      textAlign: 'center'
  },
  tbl_header: {
      minWidth: '80px',
      textAlign: 'center'
  },
  root: {
      display: 'flex',
      flexWrap: 'wrap',
  },
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
  },
  selectInput: {
      width: '100%',
      margin: '0 0 .625rem 0',
  },
  selectEmpty: {
      marginTop: theme.spacing(2),
  },
  table: {
  },
  title: {
      padding: '1rem',
  },
  tableContainer: {
      overflow: 'auto',
      margin: "25px 0"
  },
  centered: {
      textAlign: 'center'
  }
});

class WMS extends React.Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.requests = {
      cancel_loadDB: null,
      cancel_loadCustomer: null,
    };

    this.state = {
      loading: false,
      b2cDashboard: {},
      b2bDashboard: {},
      b2c_uph: '',
      orders: [],
      status_code: "",
      order_type: "",
      b2c_data: {},
      b2b_data: {},
      inbound_data: {},
      return_data: {},
      // auto update page data
      updated_at: "",
      update_schedule: null,
      // update_interval_length: 30000,
      update_interval_length: 1800000 // set to 30 mins interval for system performance
    };

    document.title = "WMS Dashboard";
  }

  componentWillMount() {
    this.loadDashboard();
  }
  componentWillUnmount() {
    clearTimeout(this.state.update_schedule);
    if (this.requests.cancel_loadDB) this.requests.cancel_loadDB();
    if (this.requests.cancel_loadCustomer) this.requests.cancel_loadCustomer();
  }

  loadDashboard = () => {
    let customer = localStorage.getItem('customer_id');
    let warehouse = localStorage.getItem('warehouse_id');
    if (!customer || !warehouse) return;  // if customer of warehouse is not set, don't make ajax call

    // Todo Don't allow auto refresh yet
    let cancel_control = this.requests;
    if (cancel_control.cancel_loadDB) cancel_control.cancel_loadDB();
    if (cancel_control.cancel_loadCustomer) cancel_control.cancel_loadCustomer();

    let req = axios({
        method: 'get',
        url: `${utils.getBaseUrl('customer')}/dashboard/${warehouse}`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        cancel_control.cancel_loadDB = c;
      })
    }
    );

    req.then(this.LoadDashboardSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, snackDisplay: this.context.snackDisplay, errorCallback: ()=>{
      let update_schedule = setTimeout(this.loadDashboard, this.state.update_interval_length);
      this.setState({loading: false, update_schedule});
    }}));
    this.setState({loading: true, customerDetail: null});
  }
  LoadDashboardSuccess = (resp) => {
      this.setState({loading: false});
      if (resp.data.Error) {
          this.context.snackDisplay(resp.data.Error);
          // this.context.alert(resp.data.Error);
          return;
      }
      
      let update_schedule = setTimeout(this.loadDashboard, this.state.update_interval_length);
      this.setState({
          b2cDashboard: resp.data.b2c ? resp.data.b2c : {},
          b2c_uph: resp.data.b2c_uph,
          b2bDashboard: resp.data.b2b ? resp.data.b2b : {},
          updated_at: moment().format("HH:mm"),
          update_schedule
      });
  }
  loadSingleCustomerOrders = (args) => {
    const {dataType, customer, dataKey} = args;

    if (customer == 'Total') return;
    if (dataKey == 14) return;

    let cancel_control = this.requests;
    
    let req = axios({
        method: 'post',
        url: `${utils.getBaseUrl('customer')}/dashboard`,
        headers: {
          token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
        },
        data: {
          order_type: dataType, // b2b or b2c
          status: dataKey, // order status
          customer
        }
      },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel_control.cancel_loadCustomer = c;
        })
      }
    );

    this.customerName = customer;

    this.setState({ loading: true, customerDetail: null, status_code: dataKey });

    req.then(this.loadCustomerSuccess.bind(this, dataType)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadCustomerSuccess = (dataType, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
        this.context.alert(resp.data.Error);
        return;
    }

    this.setState({customerDetail: resp.data.customer_detail, order_type: dataType});
    utils.scrollToElement('db_order');
  }

  renderDashboard = (type) => {
    const {classes} = this.props;
    let dataSource = {};
    let tableTitle = '';
    let updatedTime = this.state.updated_at ? ' at ' + this.state.updated_at : '';
    let title_b2c_uph = '';
    switch (type) {
        case 'b2c':
          tableTitle = 'B2C Dashboard' + updatedTime;
          title_b2c_uph = this.state.b2c_uph;
          dataSource = this.state.b2cDashboard;
          break;
        case 'b2b':
          tableTitle = 'B2B Dashboard' + updatedTime;
          dataSource = this.state.b2bDashboard;
          break;
    }
    let rows = [];
    let colTotal = {
      2: {
        order_num: 0,
        order_quantity: 0,
      },
      7: {
        order_num: 0,
        order_quantity: 0,
      },
      8: {
        order_num: 0,
        order_quantity: 0,
      },
      12: {
        order_num: 0,
        order_quantity: 0,
      },
      5: {
        order_num: 0,
        order_quantity: 0,
      },
      9: {
        order_num: 0,
        order_quantity: 0,
      },
      13: {
        order_num: 0,
        order_quantity: 0,
      },
      14: {
        order_num: 0,
        order_quantity: 0,
      },
      forecast: {
        order_num: 0,
        order_quantity: 0
      },
      rowTotal: {
        order_num: 0,
        order_quantity: 0,
      },
      goal: {
        order_num: 0,
        order_quantity: 0
      },
      sla: {
        order_num: 0,
        order_quantity: 0
      }
    };

    for (let custName in dataSource) {
      let cust = dataSource[custName];
      if (!cust.forecast) cust.forecast = {};
      let rowData = {
        customer: custName
      };
      for (let status in colTotal) {
        switch (status) {
          case 'rowTotal':
            break;
          default:
            colTotal[status]['order_num'] += parseInt(cust[status]['order_num']);
            colTotal[status]['order_quantity'] += parseInt(cust[status]['order_quantity']);
            rowData[status] = cust[status];
            if (status == 'goal'){
              let goal_percentage = parseInt(cust[9]['order_quantity']) ? '100%' : '0%';
              if (parseInt(cust['goal']['order_quantity'])) {
                goal_percentage = utils.formatPercentage(cust[9]['order_quantity'] / cust['goal']['order_quantity'], 0);
              }
              rowData[status]['percentage'] = goal_percentage;
            }
        }
      }
      rows.push(rowData);
    }
    colTotal.goal.percentage = colTotal[9]['order_quantity'] ? '100%' : '0%';

    if (parseInt(colTotal.goal.order_quantity)) {
      colTotal.goal.percentage = utils.formatPercentage(colTotal[9]['order_quantity'] / colTotal['goal']['order_quantity'], 0);
    }
    colTotal['customer'] = 'Total';
    rows.push(colTotal);

    let columns = [
      {
        key: 'customer',
        label: 'Customer',
      }, {
        key: 2,
        label: 'UnBatch',
        width: 'auto',
        contentNoWrap: true,
        render: (val, key, row) => {
          if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return '0 | 0';
          return this.renderContentWithDivider(val.order_num, val.order_quantity, ()=>{
            if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return;
            this.loadSingleCustomerOrders({dataType: type, customer: row.customer, dataKey: key});
          });
        }
      }, {
        key: 7,
        label: 'Picking/Printed',
        width: 'auto',
        render: (val, key, row) => {
          if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return '0 | 0';
          return this.renderContentWithDivider(val.order_num, val.order_quantity, ()=>{
            if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return;
            this.loadSingleCustomerOrders({dataType: type, customer: row.customer, dataKey: key});
          });
        }
      }, {
        key: 8,
        label: 'Picked',
        width: 'auto',
        render: (val, key, row) => {
          if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return '0 | 0';
          return this.renderContentWithDivider(val.order_num, val.order_quantity, ()=>{
            if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return;
            this.loadSingleCustomerOrders({dataType: type, customer: row.customer, dataKey: key});
          });
        }
      }, {
        key: 12,
        label: 'Verified',
        width: 'auto',
        render: (val, key, row) => {
          if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return '0 | 0';
          return this.renderContentWithDivider(val.order_num, val.order_quantity, ()=>{
            if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return;
            this.loadSingleCustomerOrders({dataType: type, customer: row.customer, dataKey: key});
          });
        }
      }, {
        key: 5,
        label: 'BackOrder',
        width: 'auto',
        render: (val, key, row) => {
          if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return '0 | 0';
          return this.renderContentWithDivider(val.order_num, val.order_quantity, ()=>{
            if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return;
            this.loadSingleCustomerOrders({dataType: type, customer: row.customer, dataKey: key});
          });
        }
      }, {
        key: 9,
        label: 'Shipped',
        width: 'auto',
        render: (val, key, row) => {
          if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return '0 | 0';
          return this.renderContentWithDivider(val.order_num, val.order_quantity, ()=>{
            if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return;
            this.loadSingleCustomerOrders({dataType: type, customer: row.customer, dataKey: key});
          });
        }
      }, 
      // {
      //   key: 13,
      //   label: 'Return',
      //   width: 'auto',
      //   render: (val, key, row) => {
      //     if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return '0 | 0';
      //     return this.renderContentWithDivider(val.order_num, val.order_quantity, ()=>{
      //       if (parseInt(val.order_num) === 0 && parseInt(val.order_quantity) === 0) return;
      //       this.loadSingleCustomerOrders({dataType: type, customer: row.customer, dataKey: key});
      //     });
      //   }
      // }, 
      // {
      //   key: 14,
      //   label: 'Inbound',
      //   width: 'auto',
      //   render: (val, key, row) => {
      //     return this.renderContentWithDivider(val.order_num, val.order_quantity);
      //   }
      // }, 
      {
        key: 'goal',
        label: 'Goal',
        width: 'auto',
        render: (val, key, row) => {
          return this.renderContentWithDivider(val.order_quantity, val.percentage);
        }
      }, {
        key: 'sla',
        label: 'SLA',
        width: 'auto',
        render: (val, key, row) => val.order_quantity
      }, 
      // {
      //   key: 'forecast',
      //   label: 'Forecast',
      //   render: (val, key, row) => (
      //     <div className={classes.statContainer}>
      //       <div className={classes.statL}>{val.order_num}</div>
      //       <div className={classes.divider}>|</div>
      //       <div className={classes.statR}>{val.order_quantity}</div>
      //     </div>
      //   )
      // }
    ];

    return (
      <GridItem xs={12}>
        {/* <EnhancedTable
          data={rows}
          defaultRowsPerPage={20}
          rppOptions={[10, 20, 50, 100]}
          tableTitle={tableTitle}
          colSettings={columns}
        /> */}


        <CardContainer>
          <div>
            <Typography variant="h6" id="tableTitle">
             <div style={{display: 'flex', flexWrap: 'wrap '}}>
              <span style={{flexGrow: '9'}}>{tableTitle}</span>
              <span style={{whiteSpace: 'no-wrap'}}>{title_b2c_uph}</span> 
             </div>  
            </Typography>
          </div>
          <NewDataTable
            rows={rows}
            columns={columns}
            noPagination
            rowHeight="auto"
            maxHeight={750}
            className={classes.table}
          />
        </CardContainer>
      </GridItem>
    );
  }
  renderDetailOrders = () => {
    const { classes } = this.props;
    const { customerDetail, status_code, order_type } = this.state;
    if (!customerDetail || customerDetail.length === 0 || !order_type || !status_code) return null;
    
    let colSettings = [
      {
        key: 'order_key',
        label: 'OrderId',
        contentNoWrap: true,
        render: (val, key, row)=>{
          return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span style={{marginRight: '.5rem'}}>{val}</span>
              <Search  style={{cursor: 'pointer'}} title='Click to see order detail' onClick={()=>{
                let customer_id = parseInt(localStorage.getItem("customer_id"));
                if (customer_id !== parseInt(row.customer_id)) {
                  this.context.alert("To check this order detail, please switch customer to " + this.customerName);
                  return;
                }
                
                localStorage.setItem("dbOrder", row.order_id);
                this.props.history.push("/manageOrders");
              }}>sss</Search>
            </div>
          );
        },
      }, {
        key: 'order_id',
        label: 'Barcode',
      }, {
        key: 'method',
        label: 'ShippingMethod',
        render: utils.convertShippingMethod
      }, 
      {
        key: 'package_type',
        label: 'OrderType',
        contentNoWrap: true,
      },
      {
        key: 'notes',
        label: 'SlipNote',
      }, {
        key: 'batch_id',
        label: 'BatchId',
      }, {
        key: 'name',
        label: 'Name',
      }, {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
      },
      // {
      //   key: 'unit_value',
      //   label: 'UnitValue',
      // },
      {
        key: 'quantity',
        label: 'Quantity',
      }
    ];

    if (status_code == 5) {
      colSettings.push({
        key: 'item_status',
        label: 'SkuStatus',
        contentNoWrap: true,
      });
    }

    if (this.state.order_type === 'b2b') {
      // for only b2b, show start, cancel, and ship date
      colSettings.push({
        key: 'start_date',
        label: 'StartDate',
        contentNoWrap: true,
      });
      colSettings.push({
        key: 'cancel_date',
        label: 'CancelDate',
        contentNoWrap: true,
      });
      colSettings.push({
        key: 'ship_date',
        label: 'ShipDate',
        contentNoWrap: true,
      });
    }

    // For both b2b and b2c, show system date
    colSettings.push({
      key: 'system_dt',
      label: 'System Date',
      contentNoWrap: true,
      render: utils.localizeDate
    });

    // utils.convertStatus(status_code) might need to change code
    let order_status = utils.convertOrderStatus(status_code);

    return (
      <GridItem xs={12}>
        <CardContainer>
          <div className={classes.title}>
            <Typography variant="h5" id="tableTitle">
              {`${this.customerName} ${order_type.toUpperCase()} ${order_status} Orders`}
              <Button style={{marginLeft: '1rem'}} onClick={()=>{utils.exportTableToCsv('db_order', `dashboard_${this.customerName}_${order_type}_${order_status}_order`);}}>Export</Button>
            </Typography>
          </div>

          <NewDataTable
            rows={customerDetail}
            columns={colSettings}
            noPagination
            id="db_order"
            rowHeight={80}
            maxHeight={500}
            className={classes.table}
          />
        </CardContainer>
      </GridItem>
    );
  }

  renderContentWithDivider = (content_left, content_right, onClick) => {
    const {classes} = this.props;
    content_left = '' + content_left;
    content_right = '' + content_right;
    let padding = <span style={{visibility: 'hidden'}}>{'_'.repeat(Math.abs(content_left.length - content_right.length))}</span>;
    if (onClick) {
      return (
        <div onClick={onClick} className={classes.statContainer} style={{cursor: 'pointer'}}>
          <div className={classes.statL}>{content_left.length < content_right.length && padding} {content_left} </div>
          <div className={classes.divider}>|</div>
          <div className={classes.statR}>{content_right} {content_left.length > content_right.length && padding}</div>
        </div>
      );
    } else {
      return (
        <div className={classes.statContainer}>
          <div className={classes.statL}>{content_left.length < content_right.length && padding} {content_left} </div>
          <div className={classes.divider}>|</div>
          <div className={classes.statR}>{content_right} {content_left.length > content_right.length && padding}</div>
        </div>
      );
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid className={classes.root} container spacing={2} >
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {this.renderDashboard('b2c')}
        {this.renderDashboard('b2b')}
        {this.renderDetailOrders()}
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(WMS));

