import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Typography, withStyles, IconButton, MenuItem, FormControl, Select } from "@material-ui/core";
import axios from 'axios';
import utils from 'utils/utils';
import _ from 'lodash'
import {
  Add,
  DeleteForever
} from '@material-ui/icons';
import {
  NewDataTable,
  CardContainer,
  CustomInput,
} from 'components';
import DataContext from 'context/Data'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  }
});

class SupplyRate extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    customer_supply_rate: PropTypes.array.isRequired,
    supply_sku_list: PropTypes.array.isRequired,
    customer_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSupplyRateChange: PropTypes.func.isRequired,
    marketing_insert_list: PropTypes.array.isRequired,
    onMarketingInsertChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.edit_input = null;

    this.state = {
        loading: false,
        // supply input
        supply_sku: '',
        price: '',
        notes: '',
        // marketing insert input
        marketing_sku: '',
        marketing_quantity: '',
        marketing_order_type: '',
        marketing_notes: '',
        edit: {},
    };
  }

  render() {
    return (
      <div>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
        {/* <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Supply Rate
        </Typography> */}

        {/* {this.renderSupplyTable()} */}

        <Typography variant="h6" style={{marginBottom: '.5rem'}}>
          Marketing Insert
        </Typography>

        {this.renderMarketInsertTable()}
      </div>
    );
  }

  // Supply
  newSupply = () => {
    const { supply_sku, price, notes } = this.state;
    let customer_id = this.props.customer_id;
    let err = '';

    if (!supply_sku) {
        err += 'Supply SKU is required. \n';
    }
    if (!price) {
        err += 'Price is required. \n';
    } else if (!parseFloat(price)){
        err += 'Price is not a number. \n';
    } else if (parseFloat(price) < 0){
        err += 'Price is negative. \n';
    }

    if (err) {
      this.context.alert(err);
      return;
    }

    let new_supply = {
      customer_id,
      price,
      notes,
      supply_sku,
    };

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/supplyprice`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: new_supply,
    });

    this.setState({loading: true});
    req.then(this.newSupplySuccess.bind(this, new_supply)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newSupplySuccess = (new_supply, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    if (!resp.data || resp.data.length <= 0) {
      this.context.alert("Add supply error");
      return;
    }

    new_supply.supply_sku_id = resp.data[0].supply_sku_id;

    const { customer_supply_rate, onSupplyRateChange } = this.props;
    let new_customer_supply_rate = Array.from(customer_supply_rate);
    new_customer_supply_rate.push(new_supply);

    // clear input
    this.setState({ price: '', supply_sku: '', notes: '' });
    onSupplyRateChange(new_customer_supply_rate);
  }
  deleteSupply = (supply_sku_id, index) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/supplyprice/${supply_sku_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteSupplySuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteSupplySuccess = (index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    let new_supply_rate = Array.from(this.props.customer_supply_rate);
    new_supply_rate.splice(index, 1);
    this.props.onSupplyRateChange(new_supply_rate);
  }
  updateSupply = (newVal, field, supply_sku_id, index) => {
    let new_supply = Object.assign({}, this.props.customer_supply_rate[index]);
    if (new_supply && new_supply.supply_sku_id === supply_sku_id) {
        new_supply[field] = newVal;
    } else {
        for (let i = 0; i < this.props.supply_rate.length;i++) {
            let current_supply = this.props.supply_rate[i];
            if (current_supply.supply_sku_id === supply_sku_id) {
                index = i;
                new_supply = Object.assign({}, current_supply);
                new_supply[field] = newVal;
                break;
            }
        }
    }
    let err = '';
    if (!new_supply.price) {
        err += 'Price is required. \n';
    } else if (!parseFloat(new_supply.price)){
        err += 'Price is not a number. \n';
    } else if (parseFloat(new_supply.price) < 0){
        err += 'Price is negative. \n';
    }

    if (err) {
        this.context.alert(err);
        return;
    }

    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/supplyprice`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        customer_id: this.props.customer_id,
        supply_sku_id: new_supply.supply_sku_id,
        supply_sku: new_supply.supply_sku,
        price: new_supply.price,
        notes: new_supply.notes,
      }
    });

    this.setState({loading: true});
    req.then(this.updateSupplySuccess.bind(this, new_supply, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updateSupplySuccess = (new_supply, index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    const { customer_supply_rate, onSupplyRateChange } = this.props;
    let new_supply_rate = Array.from(customer_supply_rate);
    new_supply_rate[index] = new_supply;

    onSupplyRateChange(new_supply_rate);
  }
  // Marketing Insert
  newMarketingInsert = () => {
    const {marketing_sku, marketing_quantity, marketing_order_type, marketing_notes} = this.state;
    let err = '';
    if (!marketing_sku) err += 'SKU is required. \n';
    if (!marketing_notes) err += 'Notes is required. \n';
    if (!marketing_order_type) err += 'Order type is required. \n';
    if (!marketing_quantity) err += 'Quantity is required. \n';
    if (parseInt(marketing_quantity) <= 0) err += 'Quantity should be greater than 0. \n';
    if (err) {
      this.context.alert(err);
      return;
    }

    let customer_id = this.props.customer_id;
    let new_marketing_insert = {
      customer_id,
      supply_sku: marketing_sku,
      notes: marketing_notes,
      order_type: marketing_order_type,
      quantity: marketing_quantity
    };

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/marketingInsert`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: new_marketing_insert
    });

    this.setState({loading: true});
    req.then(this.newMarketingInsertSuccess.bind(this, new_marketing_insert)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  newMarketingInsertSuccess = (new_marketing_insert, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    if (!resp.data || resp.data.length <= 0) {
      this.context.alert("Add marketing insert error.");
      return;
    }

    new_marketing_insert.id = resp.data[0].id;
    const {marketing_insert_list, onMarketingInsertChange} = this.props;
    let new_marketing_insert_list = Array.from(marketing_insert_list);
    new_marketing_insert_list.push(new_marketing_insert);
    // clear input
    this.setState({marketing_sku: '', marketing_quantity: '', marketing_notes: ''});
    onMarketingInsertChange(new_marketing_insert_list);
  }
  deleteMarketingInsert = (marketing_insert_id, index) => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/supplyprice/${marketing_insert_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    req.then(this.deleteMarketingInsertSuccess.bind(this, index)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  deleteMarketingInsertSuccess = (index, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
       return;
    }

    let new_marketing_insert_list = Array.from(this.props.marketing_insert_list);
    new_marketing_insert_list.splice(index, 1);
    this.props.onMarketingInsertChange(new_marketing_insert_list);
  }

  renderSupplyTable = () => {
    const { classes, customer_supply_rate, supply_sku_list } = this.props;
    const { supply_sku, price, notes, edit } = this.state;

    let rows = [];
    if (customer_supply_rate) {
      rows = Array.from(customer_supply_rate);
    }

    let existing_sku = {};
    for (let single_supply of customer_supply_rate) {
      existing_sku[single_supply.supply_sku] = true;
    }

    let available_options = supply_sku_list.filter((elem)=>{
      return !existing_sku[elem.supply_sku];
    });
    // only if new supply available, show add new row
    if (available_options.length > 0)  rows.push({addNew: true});

    let columns = [
      {
          key: 'supply_sku',
          label: 'Supply SKU',
          width: 200,
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <FormControl required fullWidth className={classes.selectInput}>
                              <Select
                                  value={supply_sku}
                                  onChange={(e)=>{this.setState({supply_sku: e.target.value})}}
                                  inputProps={{
                                      name: 'supply_sku',
                                      id: 'supply_sku',
                                  }}
                              >
                                  {available_options.map((elem)=>{
                                      // the option list contains only a type field
                                      return (<MenuItem key={elem.supply_sku} value={elem.supply_sku}>{elem.supply_sku}</MenuItem>);
                                  })}
                              </Select>
                          </FormControl>
                      </div>
                  );
              }

              return val ? val : '';
          }
      },
      {
          key: 'price',
          label: 'Price',
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.supply_sku,
                          field: 'price',
                      }}
                  );
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  onChange: (e)=>{this.setState({price: e.target.value})},
                                  value: price
                              }}
                          />
                      </div>
                  );
              }

              if (edit.key === row.supply_sku && edit.field === 'price') {
                  _.delay(()=>{
                      this.edit_input.focus();
                  }, 100);
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  defaultValue: val ? val : '',
                                  inputRef: (elem)=>{this.edit_input = elem},
                                  onBlur: ()=>{
                                      let newVal = this.edit_input.value;
                                      this.updateSupply(newVal, 'price', row.supply_sku_id, index);
                                      this.setState({edit: {}});
                                  },
                              }}
                          />
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'notes',
          label: 'Notes',
          cellProps: {
              style: {
                  cursor: 'pointer',
              },
              title: 'Double click to edit',
              onDoubleClick: (val, key, row, index)=>{
                  if (row.addNew) return;
                  this.setState({
                      edit: {
                          key: row.supply_sku,
                          field: 'notes',
                      }}
                  );
              }
          },
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  onChange: (e)=>{this.setState({notes: e.target.value})},
                                  value: notes
                              }}
                          />
                      </div>
                  );
              }

              if (edit.key === row.supply_sku && edit.field === 'notes') {
                  _.delay(()=>{
                      this.edit_input.focus();
                  }, 100);
                  return (
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <CustomInput
                              labelText=''
                              formControlProps={{
                                  fullWidth: false,
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: false,
                              }}
                              inputProps={{
                                  style:{marginTop: '0'},
                                  defaultValue: val ? val : '',
                                  inputRef: (elem)=>{this.edit_input = elem},
                                  onBlur: ()=>{
                                      let newVal = this.edit_input.value;
                                      this.updateSupply(newVal, 'notes', row.supply_sku_id, index);
                                      this.setState({edit: {}});
                                  },
                              }}
                          />
                      </div>
                  );
              } else {
                  return val ? val : '';
              }
          }
      },
      {
          key: 'action',
          label: 'Action',
          render: (val, key, row, index) => {
              if (row.addNew) {
                  return (
                      <IconButton size="small" onClick={this.newSupply} variant="fab"  aria-label="Add" className={classes.button}>
                          <Add />
                      </IconButton>
                  );
              } else {
                  return (
                      <IconButton size="small" onClick={()=>{
                        this.context.confirm("Are you sure to delete this supply rate?", this.deleteSupply.bind(this, row.supply_sku_id, index));
                      }} color='secondary' variant="fab" aria-label="Delete" className={classes.button}>
                          <DeleteForever />
                      </IconButton>
                  );
              }
          }
      }
    ];
    
    return (
      <CardContainer>
        <NewDataTable
          rows={rows}
          noPagination
          rowHeight='auto'
          maxHeight={500}
          columns={columns}
        />
      </CardContainer>
    );
  }
  renderMarketInsertTable = () => {
    const {classes, marketing_insert_list} = this.props;
    const {marketing_sku, marketing_quantity, marketing_order_type, marketing_notes, edit} = this.state;
    let rows = [];
    if (marketing_insert_list) rows = Array.from(marketing_insert_list);
    rows.push({addNew: true});

    let columns = [
      {
        key: 'supply_sku',
        label: 'SKU',
        render: (val, key, row, index) => {
          if (row.addNew) {
            return (
              <div style={{display: 'flex', alignItems: 'center',  justifyContent: 'center'}}>
                <CustomInput
                  labelText=''
                  formControlProps={{
                    fullWidth: false,
                    className: classes.skuInput
                  }}
                  labelProps={{
                    shrink: false,
                  }}
                  inputProps={{
                    style: {marginTop: '0'},
                    onChange: (e)=>{this.setState({marketing_sku: e.target.value})},
                    value: marketing_sku
                  }}
                />
              </div>
            );
          }
          return val ? val : '';
        }
      },
      {
        key: 'quantity',
        label: 'Quantity',
        render: (val, key, row, index) => {
          if (row.addNew) {
            return (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CustomInput
                  labelText=''
                  formControlProps={{
                    fullWidth: true,
                    className: this.props.classes.quantityInput
                  }}
                  inputProps={{
                    style: {marginTop: '0'},
                    onChange: (e)=>{this.setState({marketing_quantity: e.target.value})},
                    value: marketing_quantity,
                    inputProps: {
                      min: "0",
                      step: '1',
                    },
                    type: 'number'
                  }}
                />
              </div>
            );
          }

          if (edit.key === row.quantity && edit.field === 'marketing_quantity') {
            _.delay(()=>{
              this.edit_input.focus();
            }, 100);
            return (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CustomInput
                  labelText=''
                  formControlProps={{
                    fullWidth: false,
                    className: classes.quantityInput
                  }}
                  labelProps={{
                    shrink: false,
                  }}
                  inputProps={{
                    style: {marginTop: '0'},
                    defaultValue: val ? val : '',
                    inputRef: (elem)=>{this.edit_input = elem},
                    onBlur: ()=>{
                      let newVal = this.edit_input.value;
                      this.updateMarketingInsert(newVal, 'marketing_quantity', row.supply_sku_id, index);
                      this.setState({edit: {}});
                    },
                  }}
                />
              </div>
            );
          } else {
            return val ? val : '';
          }
        }
      },
      {
        key: 'order_type',
        label: 'Order Type',
        render: (val, key, row, index) => {
          if (row.addNew) {
            return (
              <div style={{display: 'flex', marginTop: '.5rem', minWidth: '6rem', alignItems: 'center', justifyContent: 'center'}}>
                <FormControl required fullWidth className={classes.selectInput}>
                  <Select
                      value={marketing_order_type}
                      onChange={(e)=>{this.setState({marketing_order_type: e.target.value})}}
                  >
                    <MenuItem value='dropship'>Dropship</MenuItem>
                    <MenuItem value='edi'>EDI</MenuItem>
                    <MenuItem value='gift'>Gift</MenuItem>
                    <MenuItem value='monogram'>Monogram</MenuItem>
                    <MenuItem value='retail'>Retail</MenuItem>
                    <MenuItem value='whole-sale'>Wholesale</MenuItem>
                    <MenuItem value='final-sale'>Final-sale</MenuItem>
                  </Select>
                </FormControl>
              </div>
            );
          }

          if (edit.key === row.quantity && edit.field === 'marketing_order_type') {
            _.delay(()=>{
              this.edit_input.focus();
            }, 100);
            return (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <FormControl required fullWidth className={classes.selectInput}>
                  <Select
                      defaultValue={val ? val : ''}
                      inputRef={(elem)=>{this.edit_input = elem}}
                      onBlur={()=>{
                        let newVal = this.edit_input.value;
                        this.updateMarketingInsert(newVal, 'marketing_order_type', row.supply_sku_id, index);
                        this.setState({edit: {}});
                      }}
                  >
                    <MenuItem value='dropship'>Dropship</MenuItem>
                    <MenuItem value='edi'>EDI</MenuItem>
                    <MenuItem value='gift'>Gift</MenuItem>
                    <MenuItem value='monogram'>Monogram</MenuItem>
                    <MenuItem value='retail'>Retail</MenuItem>
                    <MenuItem value='whole-sale'>Wholesale</MenuItem>
                    <MenuItem value='final-sale'>Final-sale</MenuItem>
                  </Select>
                </FormControl>
              </div>
            );
          } else {
            return val ? val : '';
          }
        }
      },
      {
        key: 'notes',
        label: 'Notes',
        render: (val, key, row, index) => {
          if (row.addNew) {
            return (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CustomInput
                  labelText=''
                  formControlProps={{
                    fullWidth: false,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: false,
                  }}
                  inputProps={{
                    style:{marginTop: '0'},
                    onChange: (e)=>{this.setState({marketing_notes: e.target.value})},
                    value: marketing_notes
                  }}
                />
              </div>
            );
          }

          if (edit.key === row.notes && edit.field === 'marketing_notes') {
            _.delay(()=>{
              this.edit_input.focus();
            }, 100);
            return (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CustomInput
                  labelText=''
                  formControlProps={{
                    fullWidth: false,
                    className: classes.customInput
                  }}
                  labelProps={{
                    shrink: false,
                  }}
                  inputProps={{
                    style:{marginTop: '0'},
                    defaultValue: val ? val : '',
                    inputRef: (elem)=>{this.edit_input = elem},
                    onBlur: ()=>{
                      let newVal = this.edit_input.value;
                      this.updateMarketingInsert(newVal, 'marketing_notes', row.supply_sku_id, index);
                      this.setState({edit: {}});
                    },
                  }}
                />
                </div>
            );
          } else {
            return val ? val : '';
          }
        }
      },
      {
        key: 'action',
        label: 'Action',
        render: (val, key, row, index) => {
          if (row.addNew) {
            return (
              <IconButton size="small" onClick={this.newMarketingInsert} variant="fab" aria-label="Add" className={classes.button}>
                <Add />
              </IconButton>
            );
          } else {
            return (
              <IconButton size="small" onClick={()=>{
                this.context.confirm("Are you sure to delete this marketing insert?", this.deleteMarketingInsert.bind(this, row.supply_sku_id, index));
              }} color='secondary' variant="fab" aria-label="Delete" className={classes.button}>
                <DeleteForever />
              </IconButton>
            );
          }
        }
      }
    ];

    return (
      <CardContainer>
        <NewDataTable
          rows={rows}
          noPagination
          rowHeight='auto'
          maxHeight={500}
          columns={columns}
        />
      </CardContainer>
    );
  }
}
export default withStyles(styles)(SupplyRate);
