// Basic React Modules
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// Util modules, including 3rd party library
import utils from 'utils/utils'
import axios from 'axios'
import _ from 'lodash'
// 3rd party render modules
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Grid,
    withStyles
} from "@material-ui/core";
// BXZ render modules
import {
  NewDataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import DataContext from 'context/Data'
import moment from "moment-timezone";
// Our view components

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
     selectInput: {
        width: '100%',
        margin: '1rem 0'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 700,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    button: {
        marginTop: theme.spacing(2)
    }
});

class ImportLocation extends Component {
    static contextType = DataContext;

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.file_input = null;

        this.state = {
            loading: false,
            errors: [],
        };

        document.title = "Import Location";
    }

    render() {
        const { classes } = this.props;
        const { loading, errors } = this.state;

        let loadingBar = null;
        if (loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        let errorsTable = null;
        if (errors && errors.length > 0) {
            let rows = errors.map((error, index)=>{
                return (
                    <TableRow hover key={index}><TableCell>
                        {error}
                    </TableCell></TableRow>
                );
            });
            errorsTable = (
                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <Typography variant="h6">
                                Result In the File You Uploaded:
                            </Typography>

                            <Table className='with-border'>
                                <TableBody>
                                    {rows}
                                </TableBody>
                            </Table>
                        </div>
                    </CardContainer>
                </GridItem>
            );
        }

        return (
            <Grid container spacing={2}>
                {loadingBar}

                {errorsTable}
                <GridItem xs={12}>
                    <CardContainer>
                        <Typography variant="h6">
                        Location Format Instruction:
                        </Typography>

                        <div style={{fontSize: "1.25rem", marginTop: ".5rem"}}>Below is the sample table for location import. All yellow fields are required.</div>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title} style={{height: '3rem'}}>
                                <Typography variant="h6">
                                    Sample Table:

                                    <Button style={{float: 'right'}} onClick={()=>{
                                        let file_name = "Location_SAMPLE";

                                        let customer = localStorage.getItem('customer_id');
                                        let warehouse = localStorage.getItem('warehouse_id');

                                        file_name += '_' + warehouse + '_';

                                        let now = moment();
                                        file_name += now.format('YYYY_MM_DD');
                                        utils.exportTableToCsv('table-export', file_name, true);
                                    }}>Download Location CSV Template</Button>
                                </Typography>
                            </div>

                            {this.renderSampleTable()}
                        </div>
                    </CardContainer>
                </GridItem>

                <GridItem xs={12}>
                    <CardContainer>
                        <div>
                            <div className={classes.title}>
                                <Typography variant="h6">
                                    Location Upload Instruction:
                                </Typography>
                            </div>
                            <div>
                                <form
                                  onSubmit={this.submit}
                                  className={classes.root}
                                  ref={(elem)=>{this.input_form = elem}}
                                >
                                    <div style={{fontSize: "1.25rem", margin: ".5rem 0"}}>Use the file input below to upload the Location file you created and click "Upload Location" button to upload it. Error messages will appear on the screen if there are errors in the file you uploaded</div>

                                    <CustomInput
                                      labelText='Upload Location'
                                      formControlProps={{
                                          fullWidth: true,
                                          required: true,
                                          className: classes.customInput,
                                      }}
                                      labelProps={{
                                          shrink: true,
                                      }}
                                      inputProps={{
                                          type: 'file',
                                          name: 'location',
                                          inputRef: (elem)=>{this.file_input = elem}
                                      }}
                                    />
                                    <Button className={classes.button} type='submit'>Upload Location</Button>
                                </form>
                            </div>
                        </div>
                    </CardContainer>
                </GridItem>

            </Grid>
        );
    }

    // api call
    submit = (e) => {
        e.preventDefault();

        if (parseInt(this.context.warehouse.get()) !== parseInt(localStorage.getItem('warehouse_id'))) {
          this.context.alert("Customer has been changed to " + localStorage.getItem('warehouse') + ", please refresh the page and try again!");
          return;
        }

        let form_data = new FormData(this.input_form);
        let req = axios({
            method: 'post',
            dataType: 'json',
            processData: false,
            contentType: false,
            cache: false,
            url: `${utils.getBaseUrl('customer')}/import/location`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: form_data,
        });

        this.setState({loading: true, errors: []});
        req.then(this.submitSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: ()=>{this.file_input.value= ''}}));
    }
    submitSuccess = (resp) => {
        this.setState({loading: false});
        resp = resp.data;

        if (resp.Error) {
            // display error table and scroll to top
            this.file_input.value= '';
            this.setState({errors: resp.Error});
            utils.scrollToTop();
            //this.contex.alert(resp.Error);
            return;
        } else {
          this.file_input.value= '';
        }
    }

    // render func
    renderSampleTable = () => {
        let colSettings = [
            {
              key: 'aisle',
              label: 'Aisle',
              header_style: {backgroundColor: 'yellow'},
              header_class: 'yellow'
            },
            {
              key: 'bay',
              label: 'Bay',
              header_style: {backgroundColor: 'yellow'},
              header_class: 'yellow'
            },
            {
              key: 'level',
              label: 'Level',
              header_style: {backgroundColor: 'yellow'},
              header_class: 'yellow'
            },
            {
              key: 'section',
              label: 'Section',
              header_style: {backgroundColor: 'yellow'},
              header_class: 'yellow'
            },
            {
              key: 'bin',
                label: 'Bin',
              header_style: {backgroundColor: 'yellow'},
              header_class: 'yellow'
            },
        ];

        let rows = [
          {
              aisle: '00',
              bay: '00',
              level: '00',
              section: '00',
              bin: 'A'
          },
          {
              aisle: '01',
              bay: '06',
              level: '05',
              section: '04',
              bin: 'B'
          },
          {
              aisle: '01',
              bay: '02',
              level: '03',
              section: '04',
              bin: 'A'
          },
          {
              aisle: '01',
              bay: '03',
              level: '04',
              section: '05',
              bin: 'B'
          },
          {
              aisle: '01',
              bay: '05',
              level: '06',
              section: '07',
              bin: 'A'
          },
      ];

        return (
          <NewDataTable
            rows={rows}
            id="table-export"
            noPagination
            columns={colSettings}
          />
        );
    }
}
export default withStyles(styles)(ImportLocation);
