import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import { Grid, makeStyles, InputLabel, FormGroup, FormControlLabel, Checkbox, Select, FormControl, MenuItem } from "@material-ui/core"
import {CustomInput, BasicDialog, Button} from 'components'
import moment from 'moment';
import utils from 'utils/utils'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

export default function EditPalletTracking(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {handleClose, handleSubmit} = props;
  const [tracking, setTracking] = useState('');
  const [carrier, setCarrier] = useState('');

  function renderFormContent() {
    return (
      <Grid container className={classes.root} spacing={2}>
        <CustomInput
          labelText='Carrier'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{setCarrier(e.target.value)},
            value: carrier,
          }}
        />

        <CustomInput
          labelText='Tracking #'
          labelProps={{shrink: true}}
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          inputProps={{
            onChange: (e)=>{setTracking(e.target.value)},
            value: tracking,
          }}
        />
      </Grid>
    );
  }
  function onSubmit() {
    let err = '';
    if (!carrier) err += "Carrier is required.\n";
    if (!tracking) err += "Tracking Number is required.\n";
   
    if (err) {
      dataContext.alert(err);
      return;
    }

    handleSubmit({tracking, carrier});
  }

  let title = "Edit Tracking";

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={handleClose}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={handleClose}>Cancel</Button>,
        <Button key="submit" onClick={onSubmit}>Submit</Button>
      ]}
    />
  );
}