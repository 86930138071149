import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { Grid, withStyles } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {
  CardContainer,
  Button,
  CustomInput,
  GridItem,
} from "components";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectInput: {
    marginTop: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginTop: theme.spacing(1)
  },
  button_add: {
    marginTop: theme.spacing(1),
    float: "right"
  }
});

class PageNotFound extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    document.title = "Page Not Found";
  }

  render() {
    return (
      <Grid container>
        <GridItem xs={12} sm={12}>
          <MuiAlert severity="warning" style={{fontSize: '2.5rem'}}>
            Page Not found. Please verify the URL you enter and make sure it is correct.
            <br/>
            <span style={{color: 'blue'}}>{window.location.host}<span style={{fontWeight: 'bold'}}>{this.props.history.location.pathname}</span></span>
          </MuiAlert>
        </GridItem>
      </Grid>
    );
  }
}
export default withStyles(styles)(PageNotFound);
