import React, { Component } from 'react'
import PropTypes from 'prop-types'
import utils from 'utils/utils'
import _ from 'lodash'
import {
    Typography,
    Grid,
    withStyles, CardActionArea, CardContent
} from '@material-ui/core';
import {
    CardIcon,
    NewDataTable,
    CardContainer,
    Button,
    CustomInput,
    GridItem,
} from "components";
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, Cell
} from 'recharts';
import {
    Receipt,
    EuroSymbol,
    ShoppingBasket,
    ShoppingCart,
} from '@material-ui/icons';
import DataContext from 'context/Data'
import moment from "moment-timezone";
import axios from 'axios';

const styles = theme => ({
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  floatingIconContainer: {
      padding: theme.spacing(2),
      marginTop: theme.spacing(-1)
  },
  statCard: {
      display: "flex"
  },
  statIconContainer: {
      // backgroundColor: "#29b765",
      borderRadius: "3px",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
      marginTop: theme.spacing(-2),
  },
  statIcon: {
      color: "white",
      fontSize: theme.spacing(4),
      // margin: theme.spacing(2, 5),
      margin: theme.spacing(2),
  },
  statValueContainer: {
      flexGrow: "1",
      padding: theme.spacing(1),
      paddingBottom: ".5rem !important",
      // backgroundColor: "#2ecc71",
      // color: "white",
      fontSize: "1rem"
  },
  statTable : {
      height: "100%",
      width: "100%"
  },
  clickEnabled: {
      cursor: "pointer",
  },
  clickDisabled: {
      cursor: "default",
  },
  statLabelCell: {
      // textAlign: "left",
      textAlign: "center",
      // color: "#999",
      // fontFamily: 'Raleway-Regular',
      fontFamily: 'Roboto-Regular',
      fontSize: "1.25rem",
      // whiteSpace: "nowrap",
      whiteSpace: "inherit",
      // width: theme.spacing(18)
  },
  statValeCell: {
      // textAlign: "right",
      textAlign: "center",
      // whiteSpace: "nowrap",
      whiteSpace: "inherit",
      // fontFamily: 'Raleway-Bold',
      fontFamily: 'Roboto-Bold',
      fontSize: "1.25rem",
      fontWeight: "bold",
      flexGrow: "1"
  },
});

const CHART_SETTINGS = {
    'annual_order_value': {
        type: 'line',
        title: 'Total Orders Revenue',
        datasets: [
            {value: 'order_value', label: 'Orders Revenue', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return '$' + utils.numberWithComma(value)},
    },
    'annual_order_number': {
        type: 'line',
        title: 'Average Order Value',
        datasets: [
            {value: 'total_shipped', label: 'Number of Orders', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.numberWithComma(value)},
    },
    'average_order_value': {
        type: 'line',
        title: '12 Mo. Units',
        datasets: [
            {value: 'avg_order_value', label: 'Avg. Order Value', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return '$' + utils.numberWithComma(value, 2)},
    },
    'average_order_unit': {
        type: 'line',
        title: 'Average Order Units',
        datasets: [
            {value: 'avg_order_unit', label: 'Avg. Order Unit', color: '#8884d8',},
        ],
        xlabelKey: 'month',
        formatXLabel: (value) => {return utils.convertMonth(value)},
        formatDataValue: (value) => {return utils.numberWithComma(value)},
    },
};

class OrderAnalysis extends Component {
    static contextType = DataContext;  
    
    static propTypes = {
        classes:PropTypes.object.isRequired,
    };
   
    constructor(props) {
        super(props);

        this.charts_ref = {};

        this.state = {
            date_from: '',
            date_to: '',
            loading: false,
            dashboard_stats: null,
            stat_type: '',
            stats_detail: null,
            top10_product: [],
            top10_customer: []
        };
        document.title = 'Order Analysis';
    }

    componentDidMount() {
        this.loadDashboard();
    }
    componentDidUpdate(prevProps, prevState) {
        // separate the chart data because there are multiple charts and the chart data and load from different api
        let updating = [];
        if (!utils.is_obj_equal(this.state.stats_detail, prevState.stats_detail) && this.state.stat_type) {
            updating.push(this.state.stat_type);
        }
        if (updating.length > 0) this.updateChart(updating);
    }

    updateChart(updating) {
        if (!updating || updating.length <= 0) 
        {
            return;
        }

        let Chart = utils.Chart; // All charts
		let ChartDataLabels = utils.ChartDataLabels; // All charts needs label

        let charts = this.charts_ref;

        for (let chartId of updating)
        {
            if (!document.getElementById(chartId) && !document.getElementById('stats_detail')) continue;
            // const ctx = document.getElementById(chartId).getContext('2d');
            const ctx = document.getElementById(chartId) || document.getElementById('stats_detail');
            let display_stats_detail = false;   
            let chartSettings = CHART_SETTINGS[chartId];
            let chartData = this.state[chartId];
            if (!chartData) 
            {
                chartData = this.state['stats_detail'];
                chartId = 'stats_detail'; 
            }

            if (charts[chartId])
            {
                charts[chartId].destroy();
            }

            // Regular Charts
            ctx.style.minHeight = '300px';
            charts[chartId] = new Chart(ctx, {
                type: chartSettings.type,
                data: {
                  labels: chartData.map(data_elem=>data_elem[chartSettings.xlabelKey]),
                  datasets:  
                      chartSettings.datasets.map(setting_elem=>{return {
                      label: setting_elem.label,
                      data: chartData.map(data_elem=>data_elem[setting_elem.value]),
                      backgroundColor: setting_elem.color,
                      borderColor: 'black',
                      borderWidth: 1
                  }})
                },
                plugins: [ChartDataLabels],
                options: {
                  scales: {
                    y: {
                      beginAtZero: true
                    }
                  },
                  plugins: {
                      title: {
                          display: true,
                          text: chartSettings.title
                      },
                      legend: {
                          display: this.state.showDataLabels // Toggle display of data labels
                      },
                      tooltip: {
                          mode: 'index',
                          callbacks: {
                              label: (tooltipItems) => {
                                    var label = tooltipItems.dataset.label || '';
                                    let data_value = tooltipItems.raw;
                                    if (chartSettings.formatDataValue)
                                    {
                                        data_value = chartSettings.formatDataValue(data_value);
                                    }
                                    label += ': ' + data_value; // formattedValue
                                    return label;
                              },
                              title: (tooltipItems) => {
                                    let value = tooltipItems[0].label;
                                    if (chartSettings.formatXLabel) return chartSettings.formatXLabel(value);
                                    else return value;
                              },
                          }
                      },
                      datalabels: {
                            backgroundColor: function(context) {
                                return context.dataset.backgroundColor;
                            },
                            // anchor: 'end',
                            // align: 'end',
                            anchor: function(context) {
                                // Set different anchor based on dataset index
                                return context.datasetIndex === 0 ? 'end' : 'center';
                            },
                            align: function(context) {
                                // Set different align based on dataset index
                                // return context.datasetIndex === 0 ? 'end' : 'start';
                                return 'end';
                            },
                            offset: 4,
                            // offset: function(context) {
                            //     // Set different offsets based on dataset index
                            //     return context.datasetIndex === 0 ? 0 : 5;
                            // },
                            borderRadius: 4,
                            color: 'white',
                            font: {
                                weight: 'bold'
                            },
                            formatter: chartSettings.formatDataValue || function(){},
                            padding: 6
                      }, // chart data label plugin
                  },
                  responsive: true,
                  aspectRatio: 2,
                  maintainAspectRatio: false,
                }
            });
        }
    }

    // load data api called when page is load
    loadDashboard = () => {
        let req = axios({
            method: 'get',
            url: `${utils.getBaseUrl('customer')}/analysis/order`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
        });
        this.setState({loading: true});
        req.then(this.loadDashboardSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadDashboardSuccess = (resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }

        console.log(resp);
        this.setState({
            dashboard_stats: data,
            top10_product: data.top10_product,
            top10_customer: data.top10_customer
        });
    }
    // load detail api called when each panel is clicked
    loadDetail = (type, date_from, date_to) => {
        type = type ? type : this.state.stat_type;
        date_from = date_from ? date_from : '';
        date_to = date_to ? date_to : '';

        let req = axios({
            method: 'post',
            url: `${utils.getBaseUrl('customer')}/analysis/order`,
            headers: {
              token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
            },
            data: {
                type,
                date_from,
                date_to
            },
        });

        this.setState({loading: true});
        req.then(this.loadDetailSuccess.bind(this, type)).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
    }
    loadDetailSuccess = (type, resp) => {
        this.setState({loading: false});
        let data = resp.data;
        if (data.Error) {
            this.context.alert(data.Error);
            return;
        }

        console.log(resp);

        if (!data || (Array.isArray(data) && data.length === 0)) {
            this.context.alert("No data found.");
            return;
        }

        this.setState({stats_detail: data, stat_type: type})
    }

    renderDashboard = () => {
        let dashboard_stats = this.state.dashboard_stats ? this.state.dashboard_stats : {
            annual_order_value: "N/A",
            annual_order_number: "N/A",
            average_order_value: "N/A",
            average_order_unit: "N/A",
        };
        let revenue = (dashboard_stats.annual_order_value !== "N/A" ? '$' : "")+utils.numberWithComma(dashboard_stats.annual_order_value);
        let order = utils.numberWithComma(dashboard_stats.annual_order_number);
        let average_value = (dashboard_stats.average_order_value !== "N/A" ? '$' : "")+utils.numberWithComma(dashboard_stats.average_order_value);
        let average_unit = utils.numberWithComma(dashboard_stats.average_order_unit);

        return (
          <React.Fragment>
              {this.renderSingleStatCard(revenue, "annual_order_value")}
              {this.renderSingleStatCard(order, "annual_order_number")}
              {this.renderSingleStatCard(average_value, "average_order_value")}
              {this.renderSingleStatCard(average_unit, "average_order_unit")}
          </React.Fragment>
        );
    }
    renderSingleStatCard = (val, key) => {
        const { classes } = this.props;
        let click_disabled = false;

        if (!val || val === "N/A") click_disabled = true;

        let title = key;
        let icon = null;
        switch (key) {
            case "annual_order_value":
                icon = <CardIcon style={{
                    backgroundColor: "#ffa726"
                }} className={classes.statIconContainer}>
                    <EuroSymbol className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Revenue";
                break;
            case "annual_order_number":
                icon = <CardIcon style={{
                    backgroundColor: "#26c6da"
                }} className={classes.statIconContainer}>
                    <ShoppingCart className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Orders";
                break;
            case "average_order_value":
                icon = <CardIcon style={{
                    backgroundColor: "#66bb6a"
                }} className={classes.statIconContainer}>
                    <Receipt className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Avg. Value";
                break;
            case "average_order_unit":
                icon = <CardIcon style={{
                    backgroundColor: "#8e24aa"
                }} className={classes.statIconContainer}>
                    <ShoppingBasket className={classes.statIcon} />
                </CardIcon>;
                title = "12 Mo. Avg. Unit";
                break;
        }

        let limit_width = document.getElementById('main-app-container') ? (document.getElementById('main-app-container').offsetWidth < 1200) : false;

        return (
          <GridItem xs={12} sm={6} md={limit_width ? 6 : 3}>
              <CardContainer allowOverflow noPadding>
                  <CardActionArea className={(click_disabled ? classes.clickDisabled : classes.clickEnabled)}>
                      <div className={classes.statCard}  onClick={()=>{if (click_disabled) return; this.loadDetail(key);}}>
                          <div className={classes.floatingIconContainer}>
                              {icon}
                          </div>

                          <CardContent className={classes.statValueContainer}>
                              <table className={classes.statTable}>
                                  <tbody>
                                  <tr><td style={{
                                      whiteSpace: "normal"
                                  }} className={classes.statLabelCell}>{title}</td></tr>
                                  <tr><td className={classes.statValeCell}>{val}</td></tr>
                                  </tbody>
                              </table>
                          </CardContent>
                      </div>
                  </CardActionArea>
              </CardContainer>
          </GridItem>
        );
    }
    renderDetail = () => {
        const { classes } = this.props;
        const { date_from, date_to, stat_type, stats_detail } = this.state;

        if (_.isEmpty(stats_detail) || !stats_detail || stats_detail.length === 0 || !stat_type) return null;

        let detail_title = stat_type;
        let chart_value = '';
        let data = Array.from(stats_detail);

        let colSettings = [];
        switch (stat_type) {
            case 'annual_order_value':
                detail_title = "Total Orders Revenue";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'order_value',
                        label: 'Orders Revenue',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "order_value";
                break;
            case 'annual_order_number':
                detail_title = "Total Orders";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_shipped',
                        label: 'Number of Orders',
                        render: utils.numberWithComma
                    }
                ];
                chart_value = "total_shipped";
                break;
            case 'average_order_value':
                detail_title = "Average Order Value";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_shipped',
                        label: 'Orders',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'order_value',
                        label: 'Value',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    },
                    {
                        key: 'avg_order_value',
                        label: 'Avg. Order Value',
                        render: (val) => {
                            return ('$' + utils.numberWithComma(val));
                        }
                    }
                ];
                chart_value = "avg_order_value";
                break;
            case 'average_order_unit':
                detail_title = "Average Order Units";
                colSettings = [
                    {
                        key: 'month',
                        label: 'Month'
                    },
                    {
                        key: 'total_shipped',
                        label: 'Orders',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'order_unit',
                        label: 'Units',
                        render: utils.numberWithComma
                    },
                    {
                        key: 'avg_order_unit',
                        label: 'Avg. Order Unit',
                        // render: utils.numberWithComma
                        render: (val)=>utils.numberWithComma(val, 2)
                    }
                ];
                chart_value = "avg_order_unit";
                data = data.map((val)=>{
                    return {
                        month: val.month,
                        avg_order_unit: parseFloat(val.avg_order_unit)
                    };
                });
                break;
        }

        let rows = Array.from(stats_detail);

        return (
            <GridItem xs={12}>
                <CardContainer>
                    <div>
                        <div className={classes.title}>
                            <Typography variant="h6" id="tableTitle">
                                {detail_title}
                            </Typography>
                            <br/>
                            <CustomInput
                              labelText='Date From'
                              formControlProps={{
                                  required: true,
                                  style: {minWidth: '140px'},
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  style:{marginRight: '1rem'},
                                  type: 'date',
                                  value: date_from,
                                  onChange: (e)=>{this.setState({date_from: e.target.value})}
                              }}
                            />
                            <CustomInput
                              labelText='Date To'
                              formControlProps={{
                                  required: true,
                                  style: {minWidth: '140px', marginRight: '1rem'},
                                  className: classes.customInput
                              }}
                              labelProps={{
                                  shrink: true,
                              }}
                              inputProps={{
                                  type: 'date',
                                  value: date_to,
                                  onChange: (e)=>{this.setState({date_to: e.target.value})}
                              }}
                            />
                            <Button style={{marginTop: "1rem"}} onClick={()=>{
                                const { date_from, date_to } = this.state;
                                if (moment(date_to).isBefore(date_from)) this.context.alert("Date to should be later than Date from.");
                                else this.loadDetail(null, date_from, date_to);
                            }}>Submit</Button>
                        </div>

                        <div style={{width: '100%', minHeight: '300px', position: 'relative'}}>
                            <canvas id="stats_detail"></canvas>
                        </div>

                        <NewDataTable
                          rows={rows}
                          className={classes.table}
                          noPagination 
                          columns={colSettings}
                        />

                    </div>
                </CardContainer>
            </GridItem>
        );
    }
    renderTopProducts = () => {
        const { classes } = this.props;
        const { top10_product } = this.state;
        let rows = top10_product ? top10_product : [];

        let colSettings = [
            {
                key: 'sku',
                label: "SKU",
            },
            {
                key: 'ItemName',
                label: "ItemName",
            },
            {
                key: 'item_unit',
                label: "Quantity",
                render: utils.numberWithComma
            },
            {
                key: 'item_value',
                label: "Product Revenue",
                render: (val)=>{
                    return ('$' + utils.numberWithComma(val));
                }
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h6" id="tableTitle">
                            Top Products
                            {rows.length > 0 && <Button style={{float: 'right'}}  onClick={()=>{utils.exportTableToCsv("top_products", "top_products");}}>Export</Button>}
                        </Typography>
                    </div>

                    <NewDataTable
                      rows={rows}
                      className={classes.table}
                      id="top_products"
                      noPagination
                      maxHeight={300}
                      minHeight={300}
                      columns={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }
    renderTopCustomers = () => {
        const { classes } = this.props;
        const { top10_customer } = this.state;
        let rows = top10_customer ? top10_customer : [];

        let colSettings = [
            {
                key: 'name',
                label: "Name",
            },
            {
                key: 'order_number',
                label: "Orders",
                render: utils.numberWithComma
            },
            {
                key: 'order_unit',
                label: "Units",
                render: utils.numberWithComma
            },
            {
                key: 'order_value',
                label: "Orders Revenue",
                render: (val)=>{
                    return ('$' + utils.numberWithComma(val));
                }
            },
        ];

        return   <GridItem xs={12} md={6}>
            <CardContainer>
                <div>
                    <div className={classes.title} style={{height: '3rem'}}>
                        <Typography variant="h6" id="tableTitle">
                            Top Customers
                            {rows.length > 0 && <Button style={{float: 'right'}}  onClick={()=>{utils.exportTableToCsv("top_customers", "top_customers");}}>Export</Button>}
                        </Typography>
                    </div>

                    <NewDataTable
                      rows={rows}
                      className={classes.table}
                      id="top_customers"
                      noPagination
                      maxHeight={300}
                      minHeight={300}
                      columns={colSettings}
                    />
                </div>
            </CardContainer>
        </GridItem>;
    }

    render() {
        let loadingBar = null;
        if (this.state.loading) {
            loadingBar = <div className='bxz-loading-bar'>Loading&#8230;</div>;
        }

        return (
          <Grid container spacing={2}>
                {loadingBar}

                {this.renderDashboard()}
                {this.renderDetail()}
                {this.renderTopProducts()}
                {this.renderTopCustomers()}

            </Grid>
        );
    }
}
export default withStyles(styles)(OrderAnalysis);
